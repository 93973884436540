import { app } from "@/main.js"

// Vue2
// import Router from "vue-router"
// Vue3
import { createWebHistory, createRouter } from "vue-router"

// Components
import ErrorView from "@/views/ErrorView.vue"
import Login from "@/views/Login.vue"

import Register from "@/views/Register.vue"
import RegisterMain from "@/components/Register/RegisterMain.vue"
import RegisterVerify from "@/components/Register/RegisterVerify.vue"
import RegisterDemo from "@/components/Register/RegisterDemo.vue"

import ResetPassword from "@/views/ResetPassword.vue"

import Account from "@/views/Account.vue"
import AccountProfile from "@/components/Account/AccountProfile.vue"
import AccountNextSteps from "@/components/Account/AccountNextSteps.vue"
import AccountNextStepsProfile from "@/components/Account/AccountNextStepsProfile.vue"

import Pigeon from "@/views/Pigeon.vue"
import PigeonSettings from "@/components/Pigeon/PigeonSettings.vue"

import Landing from "@/views/Landing.vue"
import Academia from "@/views/Academia.vue"
import MENA from "@/views/MENA.vue"
import CitizenFirstAnalytics from "@/views/CitizenFirstAnalytics.vue"
import Library from "@/views/Library.vue"
import Projects from "@/views/Projects.vue"
import Project from "@/views/Project.vue"
import ProjectData from "@/components/Project/ProjectData/ProjectData.vue"
import ProjectAnalysis from "@/components/Project/ProjectAnalysis/ProjectAnalysis.vue"
import ProjectAnalysisPerformance from "@/components/Project/ProjectAnalysis/ProjectAnalysisPerformance/ProjectAnalysisPerformance.vue"
import ProjectAnalysisDrivers from "@/components/Project/ProjectAnalysis/ProjectAnalysisDrivers/ProjectAnalysisDrivers.vue"
import ProjectAnalysisBenchmarking from "@/components/Project/ProjectAnalysis/ProjectAnalysisBenchmarking/ProjectAnalysisBenchmarking.vue"
import ProjectAnalysisOutliers from "@/components/Project/ProjectAnalysis/ProjectAnalysisOutliers/ProjectAnalysisOutliers.vue"
import ProjectAnalysisText from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/ProjectAnalysisText.vue"
import ProjectAnalysisTools from "@/components/Project/ProjectAnalysis/ProjectAnalysisTools/ProjectAnalysisTools.vue"
import ProjectReport from "@/components/Project/ProjectReport/ProjectReport.vue"

import Datasets from "@/views/Datasets.vue"
import Dataset from "@/views/Dataset.vue"

import TranslationManager from "@/views/TranslationManager.vue"
import UserAnalytics from "@/views/UserAnalytics.vue"

import BenchmarkManager from "@/views/BenchmarkManager.vue"
import BenchmarkManagerGroups from "@/components/BenchmarkManager/BenchmarkManagerGroups.vue"
import BenchmarkManagerEditor from "@/components/BenchmarkManager/BenchmarkManagerEditor/BenchmarkManagerEditor.vue"

import CustomTopicModel from "@/views/CustomTopicModel.vue"
import CustomTopicModelGroups from "@/components/CustomTopicModel/CustomTopicModelGroups/CustomTopicModelGroups.vue"
import CustomTopicModelEditor from "@/components/CustomTopicModel/CustomTopicModelEditor/CustomTopicModelEditor.vue"

// Pigeonline modules
import { PigeonDoc } from "@pigeonline/pigeondoc"

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Landing,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/academia",
      name: "academia",
      component: Academia,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/MENA",
      name: "mena",
      component: MENA,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/citizenfirst-analytics",
      name: "citizenFirstAnalytics",
      component: CitizenFirstAnalytics,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/citizenfirstanalytics",
      name: "citizenFirstAnalytics",
      component: CitizenFirstAnalytics,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/library:pathMatch(.*)*",
      name: "library",
      component: Library,
      meta: {
        noRedirect: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      props: true,
      meta: {
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/register",
      component: Register,
      meta: {
        redirectIfLoggedIn: true
      },
      children: [
        {
          path: "",
          name: "register",
          component: RegisterMain
        },
        {
          path: "verify",
          name: "registerVerify",
          component: RegisterVerify
        },
        {
          path: "demo",
          name: "registerDemo",
          component: RegisterDemo
        }
      ]
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      meta: {
        redirectIfLoggedIn: true
      }
    },
    {
      path: "/pigeon",
      component: Pigeon,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "pigeon",
          beforeEnter: (to, from, next) => next({ name: "pigeonSettings" })
        },
        {
          path: "/pigeon-settings",
          name: "pigeonSettings",
          component: PigeonSettings
        }
      ]
    },
    {
      path: "/account",
      component: Account,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "account",
          beforeEnter: (to, from, next) => next({ name: "accountProfile" })
        },
        {
          path: "profile",
          name: "accountProfile",
          component: AccountProfile
        },
        {
          path: "next-steps",
          component: AccountNextSteps,
          children: [
            {
              path: "",
              name: "accountNextSteps",
              component: AccountNextStepsProfile
            },
            {
              path: "profile",
              name: "accountNextStepsProfile",
              component: AccountNextStepsProfile
            }
          ]
        }
      ]
    },
    {
      path: "/projects",
      name: "projects",
      props: true,
      component: Projects,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/project/:projid",
      component: Project,
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "data",
          props: true,
          component: ProjectData
        },
        {
          path: "analysis",
          component: ProjectAnalysis,
          children: [
            {
              path: "",
              name: "projectAnalysis"
            },
            {
              path: "drivers",
              name: "projectAnalysisDrivers",
              component: ProjectAnalysisDrivers
            },
            {
              path: "performance",
              name: "projectAnalysisPerformance",
              component: ProjectAnalysisPerformance
            },
            {
              path: "outliers",
              name: "projectAnalysisOutliers",
              component: ProjectAnalysisOutliers
            },
            {
              path: "benchmarking",
              name: "projectAnalysisBenchmarking",
              component: ProjectAnalysisBenchmarking
            },
            {
              path: "text",
              name: "projectAnalysisText",
              component: ProjectAnalysisText
            },
            {
              path: "tools",
              name: "projectAnalysisTools",
              component: ProjectAnalysisTools
            }
          ]
        },
        {
          path: "report/empty",
          name: "report",
          props: true,
          component: ProjectReport
        },
        {
          path: "report/new",
          name: "report-new",
          component: PigeonDoc,
          props: (route) => {
            return {
              savedHandler: (document) => {
                router.push(
                  `/project/${route.params.projid}/report/${document.id}`
                )
              },
              value: "value" in route.params ? route.params.value : null
            }
          }
        },
        {
          path: "report/:id",
          name: "report-edit",
          component: PigeonDoc,
          props: (route) => {
            return {
              id: "id" in route.params ? route.params.id : null,
              pdfDownload: false,
              editable: true
            }
          }
        }
      ]
    },
    {
      path: "/datasets",
      name: "datasets",
      component: Datasets,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dataset/:id?",
      name: "dataset",
      component: Dataset,
      props: true,
      meta: {
        requiresAuth: true
      }
      // beforeEnter: (to, form, next) => {
      //   if (!to.params.id) {
      //     next({ path: "/datasets" })
      //   } else next()
      // }
    },
    {
      path: "/benchmark-manager",
      component: BenchmarkManager,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: ":id",
          name: "benchmarkGroupEdit",
          component: BenchmarkManagerEditor
        },
        {
          path: "",
          name: "benchmarkManager",
          component: BenchmarkManagerGroups
        }
      ]
    },
    {
      path: "/custom-text-model",
      component: CustomTopicModel,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: ":id",
          name: "customTopicModelEdit",
          component: CustomTopicModelEditor
        },
        {
          path: "",
          name: "customTopicModel",
          component: CustomTopicModelGroups
        }
      ]
    },
    {
      path: "/translation-manager",
      component: TranslationManager,
      name: "translationManager",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user-analytics",
      component: UserAnalytics,
      name: "userAnalytics",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        requiresAuth: true
      },
      beforeEnter: async function (to, from, next) {
        if (app.config.globalProperties.$pigeonline.userIsAuthenticated()) {
          await app.config.globalProperties.$pigeonline.logout()
          next({ name: "home" })
        } else {
          next()
        }
      }
    },
    {
      path: "/500",
      name: "serverError",
      component: ErrorView
    },
    {
      path: "/:catchAll(.*)",
      name: "notFound",
      component: ErrorView
    }
  ]
})

router.beforeEach((to, from, next) => {
  // auth required
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // not logged in
    if (!app.config.globalProperties.$pigeonline.userIsAuthenticated()) {
      if (app.config.globalProperties.$theme.theme.name == "iccs") {
        next({ name: "login", query: { redirect: to.path } })
      } else {
        next({ name: "home", query: { redirect: to.path } })
      }
    } else {
      next()
    }
  }
  // auth not required but direct to projects if already logged in
  if (to.matched.some((record) => record.meta.redirectIfLoggedIn)) {
    if (app.config.globalProperties.$pigeonline.userIsAuthenticated()) {
      next({ name: "projects" })
    } else {
      next()
    }
  }
  // No redirect needed, except for white-labels
  if (to.matched.some((record) => record.meta.noRedirect)) {
    if (
      to.name === "home" &&
      app.config.globalProperties.$theme.theme.name == "iccs"
    ) {
      next({ name: "login" })
    } else {
      next()
    }
  }
})

export default router
