/***
 * Handle keyboard trap for modals or dialogs that overlay main content
 * and have an open/close function
 ***/

import trapFocus from "../trapFocus"

const activeIsDialog = (activeDomElement) => {
  return Boolean(
    activeDomElement.attributes &&
      activeDomElement.attributes.role &&
      activeDomElement.attributes.role.value === "dialog"
  )
}

export default {
  data() {
    return {
      observer: null,
      lastFocused: null,
      setInert: true //hide rest of page from screen readers when opened
    }
  },
  mounted() {
    // IMPORTANT: assume unique id (uid) set for container

    /** re-login modal, intercept and handle in its own mounted func **/
    if (!document.getElementById(`${this.uuid}-container`)) return

    document
      .getElementById(`${this.uuid}-container`)
      .addEventListener("keydown", this.handleKeydown, true)

    const onObserveElement = (mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "hidden"
        ) {
          this.handleOpenAndClose(mutation.oldValue)
        }
      })
    }
    const target = document.getElementById(`${this.uuid}-container`)
    const config = {
      attributes: true,
      attributeFilter: ["hidden"],
      attributeOldValue: true
    }
    this.observer = new MutationObserver(onObserveElement)
    this.observer.observe(target, config)

    // immediately add focus if initial state is show (not hidden)
    if (this.show) document.getElementById(`${this.uuid}-container`).focus()
  },
  beforeUnmout() {
    this.$refs["trap-container"].removeEventListener(
      "keydown",
      this.handleKeydown
    )
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    handleKeydown($e) {
      trapFocus($e, document.getElementById(`${this.uuid}-container`))
    },
    handleOpenAndClose(oldValue) {
      if (oldValue == "hidden") {
        this.lastFocused = document.activeElement
        document.getElementById(`${this.uuid}-container`).focus()
        if (this.setInert) this.$store.dispatch("setModalOpen", true)
      } else if (oldValue == null) {
        // if no last focused, set to main element
        if (!this.lastFocused) {
          this.lastFocused = document.getElementById("main-content")
        }
        // dont set focus if we are focusing on another dialog
        if (!activeIsDialog(document.activeElement) && this.lastFocused)
          this.lastFocused.focus()
        if (this.setInert) this.$store.dispatch("setModalOpen", false)
        this.close()
      }
    },
    resetKeydownListener() {
      if (this.uuid && document.getElementById(`${this.uuid}-container`)) {
        document
          .getElementById(`${this.uuid}-container`)
          .removeEventListener("keydown", this.handleKeydown)
        document
          .getElementById(`${this.uuid}-container`)
          .addEventListener("keydown", this.handleKeydown, true)
      }
    }
  }
}
