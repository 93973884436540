<template>
  <div class="library__article">
    <h1 class="library__h1">
      <VisibleText>Research AI Ethics Policy</VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        PigeonLine’s goal is to leverage new approaches to make data science
        more accessible for researchers. This involves experimenting with
        cutting-edge techniques. We are keenly aware this creates risks, and we
        make managing and mitigating these risks key to all of our activities.
        This ethics policy underpins all of our work across:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText> the development of new technologies </VisibleText>
      </li>
      <li>
        <VisibleText>
          the nature of the research carried out by users of our tools
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          the research services we provide for partners
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2">
      <VisibleText>Ethics framework</VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        We strive to adopt the most stringent, forward-thinking, and risk-averse
        regulatory frameworks from which to design our technologies and
        organizational processes. For privacy this has meant being
        <a href="https://gdpr-info.eu/">GDPR</a>-complaint by design. For
        security we rely heavily on SOC2, ISO 27002 and the
        <a
          href="https://www.gov.uk/government/publications/cyber-essentials-scheme-overview"
          >UK Cyber Essentials</a
        >
        requirements. However, ethics around the use and adoption of Artificial
        Intelligence (AI) lacks these tested, expansive, multi jurisdictional
        frameworks.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The research industry, on the other hand, is a highly coordinated,
        quasi-regulated environment that has over a century of experience in
        dealing with balancing benefits of research with its harms. Given our
        technology is first and foremost a research toolkit, we think its
        development, its use, and its governance needs to make central these
        research ethics standards.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The
        <a href="https://cihr-irsc.gc.ca/e/193.html"
          >Canadian Institute of Health Research</a
        >, <a href="https://www.nserc-crsng.gc.ca/index_eng.asp">NSERC</a>, and
        the
        <a href="http://sshrc-crsh.gc.ca/home-accueil-eng.aspx">SSHRC</a> have
        published a statement titled, ‘<a
          href="https://ethics.gc.ca/eng/documents/tcps2-2022-en.pdf"
          >Ethical Conduct for Research Involving Humans’ (2022)</a
        >. We have based most of our policies and governance on this document
        and its frameworks, although we believe it does not go far enough for
        environmental or Artificial Intelligence-related risks. We expand
        established research ethics practices by identifying unique risks
        related to algorithms such as those identified by the Canadian
        Government’s
        <a
          href="https://www.canada.ca/en/government/system/digital-government/digital-government-innovations/responsible-use-ai/guide-use-generative-ai.html"
          >Guide on the use of Generative AI</a
        >
        (accessed December 2023).
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        From these, our policy outlines that all feature development for our
        ResearchAI toolkit, or all research services we conduct for partners
        will strive to:
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Respect, uphold, and make central to our planning:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText> human dignity </VisibleText>
      </li>
      <li>
        <VisibleText> free and informed consent </VisibleText>
      </li>
      <li>
        <VisibleText> issues acute to vulnerable persons </VisibleText>
      </li>
      <li>
        <VisibleText> privacy and confidentiality </VisibleText>
      </li>
      <li>
        <VisibleText> justice and inclusiveness </VisibleText>
      </li>
      <li>
        <VisibleText> minimizing harms, discrimination, and bias </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        With respect to our research development and services:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          monitor and evaluate how the ethics framework is being applied
        </VisibleText>
      </li>
      <li>
        <VisibleText> govern through a Research Ethics Board </VisibleText>
      </li>
      <li>
        <VisibleText>
          report on all risk and mitigation strategies
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText> Make systematic our understanding of: </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          the direct impact of our tools to users and their users
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          the social and environmental impact of our tools and any third-party
          infrastructure we may rely on, including but not limited to our
          servers, pre-trained data, or specific algorithmic techniques employed
        </VisibleText>
      </li>
      <li>
        <VisibleText> the importance of human checks and balances </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        With respect to algorithms, how they differ in terms of risk around:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText> vulnerability to bias and discrimination </VisibleText>
      </li>
      <li>
        <VisibleText> lack of explainability / interpretability </VisibleText>
      </li>
      <li>
        <VisibleText> lack of replicability </VisibleText>
      </li>
      <li>
        <VisibleText> proprietary limits instead of open access </VisibleText>
      </li>
      <li>
        <VisibleText> lack of human interactivity </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2">
      <VisibleText>Scope</VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >In accordance with the structure recommended by the Ethical Conduct for
        Research Involving Humans (2022), we established and maintain a Research
        Ethics Board consisting of at least one ethics-trained researcher, one
        community ambassador, and one legal expert. This Board meets yearly to
        review all activity, and reacts to any high-risk projects that get
        escalated.
      </VisibleText>
    </p>
    <h3>
      <VisibleText
        >Escalation and Research Ethics Board Board Review</VisibleText
      >
      :
    </h3>
    <p>
      <VisibleText>
        Prior to a new feature of our toolkit being published and made available
        to users and the public, features are reviewed on the basis of the
        following dimensions of conduct:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          <a
            href="javascript:void(0)"
            @click="goto('goto--consent')"
            onclick="this.style.color = '#3d0071'"
          >
            <VisibleText>Consent</VisibleText>
          </a>
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          <a
            href="javascript:void(0)"
            @click="goto('goto--fairness')"
            onclick="this.style.color = '#3d0071'"
          >
            <VisibleText>Fairness and equity</VisibleText>
          </a>
        </VisibleText>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--transparency')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Transparency and dissemination</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--privacy')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Privacy and confidentiality </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--conflict-of-interest')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Conflicts of interest </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--multijurisdictional')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText
            >Multi-jurisdictional, multi-institutional research
          </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--firstnations')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText
            >First Nations, Inuit, and Metis Peoples of Canada, and Indigenous
            peoples globally
          </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--qualitative')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Qualitative research </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--environmental')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Environmental and social impact </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--algoritmic-risk')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Algorithmic impact and risk </VisibleText>
        </a>
      </li>
    </ul>
    <p>
      <VisibleText>
        If any of these dimensions are determined to be of high risks, an
        escalation is made to the Board from which it can consult the Ethical
        Conduct for Research Involving Humans (2022), the Guide on the use of
        Generative AI (2023), our Privacy Policy, and our Cyber Security Policy.
        If these are not resolved then a report or statement is made.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        As of the moment of publishing this version of our Research and
        Development Ethics Policy, no such escalated issues have been
        unresolved. In all cases of risk, alternative or mitigating
        approaches/techniques have been explored and implemented.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--consent">
      <VisibleText>Consent</VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        All individuals who participate in research should be reasonably
        informed. Consent shall be voluntary, and can be withdrawn at any time.
        With respect to the development of our toolkits, all of our user
        research involves consent-based user-testing with partners in an open
        and transparent manner. We do not collect data without user consent,
        neither for the purpose of development, nor the purpose of research.
      </VisibleText>
    </p>
    <p>
      <VisibleText style="font-weight: 800">
        Example: Encryption and no third-party access, to protect your consent
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        All individuals who participate in research should be reasonably
        informed. Consent shall be voluntary, and can be withdrawn at any time.
        With respect to the development of our toolkits, all of our user
        research involves consent-based user-testing with partners in an open
        and transparent manner. We do not collect data without user consent,
        neither for the purpose of development, nor the purpose of research.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To further protect this, no third parties have access to any data
        uploaded in our system, and all client files are highly encrypted, as
        described in our Cyber Security Policy. This means third-parties cannot
        make use of client data without consent either.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--fairness">
      <VisibleText>Fairness and equity </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        The principle of Justice, according to our Framework and the Ethical
        Conduct for Research Involving Humans, holds that particular
        individuals, groups or communities should neither bear an unfair share
        of the direct burdens from research and in our case research using our
        toolkit. Nor should be unfair excluded from the potential benefits of
        research participation.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        All development and research activities must addresses the fair
        inclusion and equitable treatment of individuals, groups, and
        communities whose situation or circumstances make them vulnerable in the
        context of a specific research project.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Taking into account the scope and objectives of their research,
        researchers and their use of our toolkits should be inclusive in
        selecting participants. Researchers nor their use of our toolkits shall
        exclude individuals from the opportunity to participate in research on
        the basis of attributes such as culture, language, religion, race,
        disability, sexual orientation, ethnicity, linguistic proficiency,
        gender or age, unless there is a valid reason for the exclusion. The
        benefits of the research through the use of our toolkits shall equitably
        distribute research benefits.
      </VisibleText>
    </p>
    <p>
      <VisibleText style="font-weight: 800">
        Example: How we used lemmatization to enhance representation
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        We are well aware researchers as individuals can have biases, and so can
        algorithms. When a user searches for a set of patterns in text data, for
        example, they may inadvertently make biased or discriminatory searches.
        Algorithms that find matches to these searches may have bias for certain
        culturally relevant words over others, or certain genders over others.
        We use lemmatization, a process of breaking words up into their gender
        neutral, neutral tense state to allow for broad matching across these
        states. A researcher or algorithm innately biased towards male suffixes
        like “he” will also be shown female or neutral suffixes. To learn more,
        <router-link
          to="/library/feature/ai-powered-text-analysis-our-recent-enhancements"
          exact
          aria-label="AI-powered text analysis - our latest enhancements"
          >read here.</router-link
        >
      </VisibleText>
    </p>
    <h3>
      <VisibleText
        >A critical lens across all our research: Gender Based Analysis Plus
        (GBA+)
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        All research conducted by us as a service, through the use of our
        toolkit, undergoes an explicit review through the lens of ‘unique risks
        relevant to vulnerable peoples’. For example, after every iteration of
        research we deliver to partners, we ensure we have included an analysis
        of at-risk populations, and look for clues and evidence of inequality,
        discrimination, or bias against these groups. We do this by expanding
        our thematic or quantitative research across segments, and guided by
        existing research which provides sample keywords and phrases that may
        help indicate issues are present. This way we ensure all of our research
        includes these perspectives. We partnered with a Canadian government
        department to support
        <a
          href="https://women-gender-equality.canada.ca/en/gender-based-analysis-plus.html"
          >Gender Based Analysis Plus</a
        >
        through the use of finding service problems unique to clients across
        male, female and other gender categories.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--transparency">
      <VisibleText>Transparency and dissemination </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Researchers shall disseminate, through publication or otherwise, the
        analysis of data and interpretation of research results, including those
        that do not support the research hypotheses. The dissemination shall
        take place in a timely manner without undue restriction. We aim to
        support these practices not only as practitioners but through User
        Interface reminders, tips, and continue to develop new features to help
        researchers report these issues.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        We make explicit our review of the following risks that may result from
        failing to disseminate all research findings, including those that do
        not support the research hypotheses (if any), or lead to useful or
        favourable results:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText
          >making misinformed decisions based on incomplete or skewed
          data</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >developing inappropriate and potentially harmful policy, or practices
        </VisibleText>
      </li>
      <li>
        <VisibleText>negatively affecting participant welfare</VisibleText>
      </li>
      <li>
        <VisibleText
          >needlessly duplicating research with associated risks to participants
          and waste of resources</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >introducing fraud or deception in the research processa</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >eroding participant and/or public trust and accountability in
          research</VisibleText
        >
      </li>
    </ul>
    <h3>
      <VisibleText>How: Data replicability and availability </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        We strive to uphold the principle of replicability. Unlike many
        generative tools that have dimensions of randomness when they make
        inferences about your data, we ensure all final results are repeatable
        and quantifiable, including text-based analysis. Users can always
        replicate the steps taken to conduct their analysis. Users can also
        always reverse, modify, and change results in the spirit of researcher
        autonomy and to enable human-centric methodologies like interpretive
        analysis. We also strive to make it easy for researchers to make
        available their insights to their stakeholders, such as by sharing
        datasets and projects and allowing for easier project management and
        governance. We do not sacrifice user privacy when making data available
        . We help you make your research available in the sense you can share it
        with colleagues in a protected and encrypted environment. We also inform
        users to remove personal information and to anonymize data where
        necessary. But if you need to delete your data, this is more important
        than availability and your data will be fully and permanently removed,
        and any access you have given to others will be revoked.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--privacy">
      <VisibleText>Privacy and confidentiality </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Central to our founding was the protection and encryption of user
        information. Research should not sacrifice privacy in order to access
        new technologies. Rather than repeat our policies here, we recommend
        consulting our
        <router-link
          to="/library/privacy-policy"
          exact
          aria-label="privacy policy"
          >Privacy Policy</router-link
        >

        for more information.</VisibleText
      >
    </p>
    <h2 class="library__h2" ref="goto--conflict-of-interest">
      <VisibleText>Conflict of interest </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        As guided by the Ethical Conduct for Research Involving Humans (2022),
        "A conflict of interest may arise when activities or situations place an
        individual or institution in a real, potential or perceived conflict
        between the duties or responsibilities related to research, and
        personal, institutional or other interests. Conflicts of interest must
        be assessed when conducting research, as they may jeopardize the
        integrity of the research and the protection offered to participants.
        Conflicts that create divided loyalties may distract researchers,
        research ethics boards (REBs), and institutions from concern for the
        welfare of participants and are contrary to the core principles on which
        this Policy is based."
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        For the purposes of the development of our toolkit, we ensure all active
        designers, testers and other stakeholders declare any potential conflict
        of interest that may put them in direct conflict with one of our
        principles, like if one of our Research Ethics Board members was also a
        brand ambassador for a company that attacks user privacy.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--multijurisdictional">
      <VisibleText
        >Multi-jurisdictional, multi-institutional research
      </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        PigeonLine partners with academic, government, and non-governmental
        organizations in the pursuit of the development of innovative research
        tools. This creates conditions whereby multiple institutions or even
        multiple Research Ethics Boards overlap:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText
          >a research project conducted by a team of researchers affiliated with
          different institutions</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >several research projects independently conducted by researchers
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >affiliated with different institutions, with data combined at some
          point to form one overall research project
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >a research project conducted by a researcher affiliated with one
          institution, but that involves collecting data or recruiting
          participants at different institutions
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >a research project conducted by a researcher who has multiple
          institutional affiliations, like two universities, a university and a
          college, or a university and a hospital</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >a research project conducted by a researcher at one institution that
          requires the limited collaboration of individuals affiliated with
          different institutions or organizations (like a statisticians, lab or
          X-ray technicians, social workers and school teachers)</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >a research project that researcher(s) working under the auspices of a
          Canadian research institution conduct in another province, territory
          or country</VisibleText
        >
      </li>
    </ul>
    <p>
      <VisibleText> Where this leads to risk, we focus on: </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText
          >establishing across equivalent organizations a cross-institutional
          agreement
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >or, delegate to the REB of the highest institutional level in the
          partnership
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2" ref="goto--firstnations">
      <VisibleText
        >First Nations, Inuit, and Métis Peoples of Canada, and Indigenous
        peoples globally
      </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <h3>
      <VisibleText
        >Enabling Indigenous world-views and epistemologies
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        Having been founded by qualitative researchers whose work involves
        ethnography and interpretation, one of PigeonLine’s core missions is to
        create a sharing platform for diverse epistemologies. To date, most
        research analytics tools discount and downplay indigenous thought
        systems or epistemologies. For example, tools that promote top-down,
        deductive logic, and that make difficult the tasks of finding and
        expressing inductive findings or interpretative analysis, means
        excluding indigenous world views and world truths. We hope new toolkits
        that afford inductive world-views and protect the ability to define
        unique narratives will help build deeper reciprocity and trust across
        the research space.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >Another way PigeonLine hopes to support and enable Indigenous research
        is to invest in meaningful collaboration with indigenous communities to
        find knowledge and learning that these communities find enriching and
        complimentary to their knowledge-seeking and knowledge-sharing
        practices.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Community engagement </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >As guided by the the Ethical Conduct for Research Involving Humans
        (2022), where the research is likely to affect the welfare of an
        Indigenous community, or communities, to which prospective participants
        belong, researchers shall seek engagement with the relevant community.
        The conditions under which engagement is required include, but are not
        limited to:
      </VisibleText>
    </p>

    <ul class="library__list">
      <li>
        <VisibleText
          >research conducted on First Nations, Inuit or Métis lands
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >recruitment criteria that include Indigenous identity as a factor for
          the entire study or for a subgroup in the study
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >research that seeks input from participants regarding a community’s
          cultural heritage, artefacts, traditional knowledge or unique
          characteristics
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >research in which Indigenous identity or membership in an Indigenous
          community is used as a variable for the purpose of analysis of the
          research data
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >interpretation of research results that will refer to Indigenous
          communities, peoples, language, history or culture.
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText
        >Where a proposed research project is to be conducted on lands under the
        jurisdiction of a First Nations, Inuit or Métis authority, researchers
        shall seek the engagement of leaders of the community. Research ethics
        review by the institutional REB and any responsible community body
        recognized by the First Nations, Inuit or Métis authority is required in
        advance of recruiting and seeking and obtaining consent of individuals.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--qualitative">
      <VisibleText>Qualitative research </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >Social science involves the common belief that human action can be
        understood through systemic study and analysis. Some of PigeonLine’s
        toolkits, especially ResearchAI, are helping researchers by making
        qualitative research more accessible. Critically however, we do not aim
        to invent new methodologies. We instead aim to build upon well validated
        methodologies like thematic analysis, interpretative analysis,
        discursive analysis, and more.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >This has meant building central into ResearchAI’s functionality the
        support of these important dimensions of research:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>inductive understanding </VisibleText>
      </li>
      <li>
        <VisibleText>diversity of approaches </VisibleText>
      </li>
      <li>
        <VisibleText
          >dynamic, reflective, and continuous research processes
        </VisibleText>
      </li>
      <li>
        <VisibleText>diverse, multiple, evolving contexts </VisibleText>
      </li>
      <li>
        <VisibleText>data collection and sample realities </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText
        >Researchers shall explain in their research design the proposed
        procedures, from consent to their ‘coding logic’ when operating the
        tools. If a researcher intends to use toolkits for speed and volume,
        they may rely more on a topic modelling design where the accuracy of
        keyword matches is less important. Alternatively, a researcher may use
        the toolkits with accuracy and validity in mind, through researcher
        interpretations and notes instead of the mathematical presence of words.
        Our research or any users of our toolkits should be able to accommodate
        either of these methods, and we will strive to help users understand the
        differences between them.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >Connected to other dimensions in this policy we make clear and explicit
        our recognition that Natural Language Processing (NLP) necessarily
        involves using human text data, and these data are necessarily
        intersectional with culture, bias, prejudice, discrimination and more.
        All uses of NLP within our toolkits must necessarily recognize these
        risks and either be suggestive with full warning, must measurably
        improve inclusivity, or should not be used.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--environmental">
      <VisibleText>Environmental and social impact </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >Not present in the Ethical Conduct for Research Involving Humans
        (2022), but deeply important to us at PigeonLine is that all tools and
        research should have a critical and systematic understanding of its
        overall environmental and social impacts. In the era of big data, this
        is becoming more relevant than ever.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Low carbon footprint </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >In the very design of our architecture, we aimed for as low a carbon
        footprint as possible. We use Green Business Bureau-approved
        infrastructure, including being 100% hydro-powered. We also make
        explicit the goal to minimize the amount of data that needs to be sent
        across systems or to support calculations.
      </VisibleText>
    </p>
    <h4>
      <VisibleText>The high footprint of GPTs </VisibleText>
      :
    </h4>
    <p>
      <VisibleText
        >Certain technologies labeled as ‘artificial intelligence’ have gained a
        warranted reputation for their environmental damage. Technologies like
        Generative Pre-trained Transformers (GPTs) suffer from an inability to
        correct for new forms of discrimination or necessarily reinforce bias as
        they require patterns to operate. As such, many operators of these
        technologies need to constantly retrain data, which consumes
        considerable electricity.
      </VisibleText>
    </p>
    <h4>
      <VisibleText>Our smaller, leaner stack </VisibleText>
      :
    </h4>
    <p>
      <VisibleText
        >We wanted to ensure any technology we bring in only needs static
        trained data, meaning we are not constantly rerunning data. That means
        our carbon footprint is limited to, at the moment of publishing this
        policy, no more than 5 physical servers running 24 hours a day. Our
        servers are 100% hydro-powered, in
        <a href="https://greenbusinessbureau.com/">Green Business Bureau</a>
        facilities operating at 60% lower energy utilization of the industry
        standard.
      </VisibleText>
    </p>
    <h3>
      <VisibleText
        >The darkside of GPTs: bias and labour exploitation
      </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >For the same reason GPTs consume large amounts of data, they are also
        potentially a source of labour exploitation. By configuring a tool in
        such a way that we let a chatbot tell us answers to complex questions,
        we end up with situations where it is ‘guessing’ based on vast human
        data. The problem is, human text data is necessarily biased, as it
        reflects the bias we carry into our text. Algorithms trained on all of
        human literature will assume more men should be doctors, more women
        should be teachers, etc. Many companies are currently tackling this is
        by hiring large labour forces at very low wages to constantly re-train
        data. They are inundated with graphic, harmful, and disturbing material,
        as well as expected to find and tag under-representation issues. We
        believe this is an unsustainable way to use these technologies.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >For this reason and environmental reasons, we use pre-trained datasets
        only as static datasets that do not require constant exploitative
        retraining . And we can do this because we are not using
        bias-reinforcing approaches like GPTs to make guesses against the
        autonomy of the researcher. We put the researcher in the center of all
        decision-making. Where we use pre-trained datasets, we only use those
        that have been trained and maintained by the open-source and academic
        communities.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Repurposing technologies to do good </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >While we recognize many applications of technologies introduce vast
        risks to equity, fairness, and researcher autonomy, we also understand
        that some novel applications of certain technologies can help
        researchers be more inclusive. See the
        <router-link
          to="/library/feature/ai-powered-text-analysis-our-recent-enhancements"
          exact
          aria-label="AI-powered text analysis - our latest enhancements"
          >How we used lemmatization to enhance representation in Fairness and
          equity.</router-link
        >
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--algoritmic-risk">
      <VisibleText>Algorithmic impact and risk </VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >Also not present in the Ethical Conduct for Research Involving Humans
        (2022), but deeply important to us at PigeonLine are unique
        considerations that emerge through the use of advanced Information
        Technologies, especially those labeled as ‘artificial intelligence’. We
        expand our ethics policy by incorporating principles from founder
        researcher and from the Canadian Government’s
        <a
          href="https://www.canada.ca/en/government/system/digital-government/digital-government-innovations/responsible-use-ai/guide-use-generative-ai.html"
          >Guide on the use of Generative AI</a
        >
        (accessed December 2023)</VisibleText
      >
    </p>
    <h3>
      <VisibleText>Mitigating lack of explainability </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >Some algorithms do not reveal the logic behind their decision-making,
        they simply return a result. This is a unique risk that must be
        considered, especially of the algorithm itself is not fully understood
        by designers or operators.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >In their dissertation research titled
        <a href="http://etheses.lse.ac.uk/4328/"
          >"Negotiating with social algorithms in the design of service
          personalization" (2021)</a
        >, one of ResearchAI’s founders, Dr. Cody Dodd, investigated the
        challenges that emerge when developing technologies perceived as ‘black
        boxes’, and the unique strategies that emerge for dealing with them.
        This has helped underpin one of our core theses:
      </VisibleText>
    </p>
    <ol class="library__list">
      <li>
        <VisibleText
          >Because social algorithms are powerful (for finding patterns from
          social data), we gravitate towards them.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >They are often opaque (mathematically inscrutable, using
          ever-changing data, or reinforcing bias present in social data)
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >The more we interact with them and make them interactable, the more
          opaque they become.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >In place of explainability we are offered more interpretablity and
          interactability, and interestingly: this better satisfies our
          governance over them
        </VisibleText>
      </li>
    </ol>
    <p>
      <VisibleText
        >In light of these perspectives, we strive to minimize the use of opaque
        algorithms, for they introduce challenges to research autonomy and risk
        unseen biases going ungoverned. Where opaqueness is not avoidable, we
        relegate the feature/function to be as suggestive as possible, and force
        users to ‘click’ to make confirmations.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >“Our algorithms make suggestions or help find possible insights, but do
        not make judgments or insights that presume to be complete. All
        suggestions require being checked by researchers. We are replacing
        trusted albeit antiquated research tools, not replacing humans”, Dr Cody
        Dodd.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Generative algorithms </VisibleText>
      :
    </h3>
    <p>
      <VisibleText
        >Some algorithms have an architecture that introduces randomness into
        final results as a way of better managing human expectations around
        natural language. For example, while most traditional Natural Language
        Processing tools return the same result every time the same inputs are
        given, provided the training set does not change, new generative tools
        like Generative Pre-trained Transformers (GPTs) deliberately introduce
        randomness, often called ‘temperature’, which can lead to different
        results when the same inputs are given. This has serious implications
        for research.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >As the Canadian Government’s Guide on the use of Generative AI (2023)
        describes: "Generative AI technologies can produce content that appears
        to be well developed, credible and reasonable but that is in fact
        inaccurate, nonsensical or inconsistent with source data. This content
        is sometimes referred to as a 'hallucination.'"
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >From this, we ensure ResearchAI tools and our research using these
        tools make central the following practices:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText
          >clearly indicate that you have used generative AI to develop content.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >don’t consider generated content as authoritative. Review it for
          factual and contextual accuracy by, for example, checking it against
          information from trusted sources.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >review personal information created using generative AI to ensure it
          is accurate, up-to-date and complete.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >assess the impact of inaccurate outputs. Don’t use generative AI when
          factual accuracy or data integrity is needed.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >strive to understand the quality and source of training data.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >consider your ability to identify inaccurate content before you use
          generative AI. Don’t use it if you can’t confirm the content quality.
        </VisibleText>
      </li>
      <li>
        <VisibleText
          >learn how to create effective prompts and provide feedback to refine
          outputs to minimize the generation of inaccurate content.
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText
        >Of critical importance to our work is that generative AI not be used
        for producing insights that claim to be authoritative, replicable, and
        authentic. We use generative AI for suggestive labelling, but they do
        not appear in results until and unless a human interprets the suggestion
        as accurate and confirms the tag. This human action is tracked, ensuring
        governance and transparency around the source of insights. Generative AI
        alone cannot be used to generate insights in our work, given the risks
        that come from its inability to be replicable.
      </VisibleText>
    </p>
    <p>
      <VisibleText>Last changed: 2023-12-05 </VisibleText>
    </p>
    <h2 class="library__h2"><VisibleText>Contacting Us</VisibleText>:</h2>
    <p>
      <VisibleText>
        A User may contact us to request any of the actions described above, or
        for any other reason, at hello@researchai.io
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Changes To This Policy</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Pigeonline may, if circumstances require it, change this ethics policy
        from time to time. Users and Customers will be duly informed of any such
        changes and be given an opportunity to accept or reject such changes. If
        the changes are a necessity for continued use of the products, Users and
        Customers will have an opportunity to review their use of the products
        and associated platform.
      </VisibleText>
    </p>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "ethics_policy",
  setup() {
    const siteData = reactive({
      title: "PigeonLine's ResearchAI | Ethics policy",
      description: "PigeonLine's Ethics Policy"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
