<template>
  <div>
    <span
      style="display: flex; margin: 0 auto"
      v-if="isLoadingSimilarSentences"
      class="also-found__loading-spinner spinner"
    ></span>
    <div
      v-if="similarSentences.length"
      style="overflow-y: auto; max-height: 30rem"
    >
      <hr v-if="source == 'NotesPopup'" style="margin-bottom: 1rem" />
      <VisibleText>Sentences similar to: </VisibleText>
      <span style="background-color: lightgreen">{{ sentenceToMatch }}</span>
      <div
        v-for="(sentence, index) in similarSentences"
        :key="index"
        style="
          border: 1px solid var(--primary-brand-ui-colour);
          border-radius: 4px;
          margin: 1.5rem 3rem;
        "
      >
        <div style="display: flex; flex-direction: row; padding: 0.75rem">
          <div style="display: flex">
            <ButtonIconOnly
              style="
                border: 1px solid var(--outline-colour);
                background: var(--secondary-ui-colour);
                border-radius: 4px;
                margin: 0.5rem;
              "
              icon="search"
              :smaller="true"
              @click-handler="$emit('selectThisResponse', sentence.response_id)"
            >
              <VisibleText style="font-weight: 800"
                >filter to this response</VisibleText
              >
            </ButtonIconOnly>
          </div>
          <div style="display: flex; padding-right: 1rem">
            <ButtonIconOnly
              style="
                border: 1px solid var(--outline-colour);
                background: var(--secondary-ui-colour);
                border-radius: 4px;
                margin: 0.5rem;
              "
              icon="add"
              :smaller="true"
              @click-handler="addNewNote(sentence)"
            >
              <VisibleText style="font-weight: 800">add to theme</VisibleText>
            </ButtonIconOnly>
          </div>
          <div style="display: flex; padding-right: 1rem">
            Row {{ sentence.row_index }}: {{ sentence.text }}
          </div>
          <div style="margin-left: auto">
            <SvgIconMeaningful
              class="dataset-table__type-icon"
              style="height: 2rem; width: 2rem"
              :icon="signalStrength(sentence.similarity)"
              :title="'Similarity: ' + roundToTwo(sentence.similarity)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

export default {
  name: "SimilarSentences",
  components: {
    ButtonIconOnly,
    SvgIconMeaningful
  },
  mixins: [textAnalysisMixin],
  props: {
    clientQuestionId: {
      default: () => "",
      type: String
    },
    theme: {
      default: () => {},
      type: Object
    },
    source: {
      default: () => "NotesPopup",
      type: String
    }
  },
  data() {
    return {
      similarSentences: [],
      isLoadingSimilarSentences: false,
      sentenceToMatch: ""
    }
  },
  created() {},
  computed: {},
  methods: {
    addNewNote(sentence) {
      let noteDetails = {
        sentence: sentence,
        theme: this.theme
      }
      this.$emit("addNewNote", noteDetails)
      this.similarSentences = this.similarSentences.filter(
        (similar_sentence) => similar_sentence !== sentence
      )
    },
    signalStrength(similarity) {
      if (similarity >= 0.6) {
        return "signalStrong"
      } else if (similarity > 0.4 && similarity < 0.6) {
        return "signalGood"
      } else {
        return "signalFair"
      }
    },
    roundToTwo(num) {
      return Math.round(num * 100) / 100
    },
    async clearSimilar() {
      this.similarSentences = []
    },
    async findSimilar(note) {
      if (note.content && this.clientQuestionId) {
        this.sentenceToMatch = note.content
        this.isLoadingSimilarSentences = true
        let payload = {
          client_question_id: this.clientQuestionId,
          user_string: note.content,
          sim_thresh: 0.4
        }
        const thisThemeResponses = await this.TEXT_SERVICE.findSimilarSentences(
          payload
        ).then((response) => {
          return response
        })
        const otherSentences = thisThemeResponses.filter(
          (sentence) => sentence.response_id != note.responseId
        )
        this.similarSentences = otherSentences.sort(
          (a, b) => b.similarity - a.similarity
        )
        this.isLoadingSimilarSentences = false
      }
    }
  },
  watch: {}
}
</script>

<style scoped></style>
