import { SECTION_TRANSLATIONS } from "../../../translations"
import { methodologyTable } from "./benchmarkingMethodologyNodes"

/*******************************
 *
 * Generate Survey Methodology section
 * @param {Object} methodologyData data_set_meta_data field from
 * benchmarking/detailed_results API
 * @param {Array} orgs list of unique organization ids
 * @param {String} lang current report language
 * @returns methodology pigeondoc section
 *
 *******************************/
const benchmarkingMethodologySection = (
  methodologyData,
  orgs,
  lang = "en",
  uuid
) => {
  const heading = {
    type: "heading",
    content: `${SECTION_TRANSLATIONS.methodologyAppendix[lang]} <span class='report__subtitle'>${SECTION_TRANSLATIONS.methodology[lang]}</span>`,
    id: "section-title-appendix",
    meta: {
      headingLevel: "h1",
      pdfConfig: {
        pageBreak: "before",
        style: "heading-h1"
      }
    }
  }

  const description = {
    type: "text",
    content: SECTION_TRANSLATIONS.methodologyDesc[lang]
  }

  return {
    type: "section",
    id: "methodology-section",
    content: [
      heading,
      description,
      methodologyTable(methodologyData, orgs, lang)
    ],
    uuid: uuid,
    meta: {
      subTitle: "BM METHODS"
    }
  }
}

export { benchmarkingMethodologySection }
