<template>
  <div class="inline-edit-text__container">
    <!--------------------------------------
    | not editing state
    ---------------------------------------->
    <span
      :class="['inline-edit-text__text', { 'disable-edit': disableEditing }]"
      v-show="!isEditing"
    >
      <slot></slot>
      <ButtonIconOnly
        class="inline-edit-text__edit-btn"
        icon="edit"
        id="inline-edit-edit"
        @click-handler="isEditing = true"
        v-if="!disableEditing"
      >
        <VisibleText>edit</VisibleText>
      </ButtonIconOnly>
    </span>
    <!--------------------------------------
    | editing state
    ---------------------------------------->
    <span class="inline-edit-text__input-wrapper" v-show="isEditing">
      <label class="inline-edit-text__label" :for="inputId">
        {{ label }}
      </label>
      <input
        type="text"
        class="inline-edit-text__input"
        :id="inputId"
        :name="inputName"
        v-model="text"
        @keyup.enter="save"
      />
      <ButtonIconOnly
        class="inline-edit-text__action-btn"
        icon="check"
        id="inline-edit-save"
        @click-handler="save"
      >
        <VisibleText>save</VisibleText>
      </ButtonIconOnly>
      <ButtonIconOnly
        class="inline-edit-text__action-btn"
        icon="remove"
        id="inline-edit-cancel"
        @click-handler="isEditing = false"
      >
        <VisibleText>cancel</VisibleText>
      </ButtonIconOnly>
    </span>
  </div>
</template>

<script>
// Components
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "InlineEditTextInput",
  props: {
    disableEditing: {
      default: () => false,
      type: Boolean
    },
    label: {
      default: () => "edit",
      type: String
    },
    inputId: {
      default: () => "inline-edit",
      type: [String, Number]
    },
    inputName: {
      default: () => "inline edit name",
      type: String
    },
    inputValue: {
      default: () => "",
      type: String
    }
  },
  components: {
    ButtonIconOnly
  },
  data() {
    return {
      isEditing: false,
      text: ""
    }
  },
  methods: {
    save() {
      this.$emit("save", this.text)
      this.isEditing = false
    }
  },
  watch: {
    inputValue: {
      handler(val) {
        if (val.length > 0) this.text = val
      },
      immediate: true
    }
  }
}
</script>
