<template>
  <DialogPopup
    class="theme__popup-dialog"
    header-tag="h4"
    :show="showDialog"
    @close="showDialog = false"
    v-if="showDialog"
  >
    <template #header> Finding similar: </template>
    <template #body>
      <SimilarSentences
        ref="NotesSimilarSentencesResponses"
        :clientQuestionId="textAnalysisObject.selectedTextQuestion._id"
        :theme="matchingTheme"
        @addNewNote="addNewNote($event)"
        @selectThisResponse="$emit('selectThisResponse', $event)"
        :source="source"
      >
      </SimilarSentences>
    </template>
  </DialogPopup>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"
import SimilarSentences from "./SimilarSentences.vue"

export default {
  name: "SimilarSentencesPopup",
  components: {
    DialogPopup,
    SimilarSentences
  },
  mixins: [textAnalysisMixin],
  props: {
    textAnalysisObject: {
      default: () => {},
      type: Object
    },
    source: {
      default: () => "NotesPopup",
      type: String
    },
    sortedThemes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDialog: false,
      matchingTheme: {}
    }
  },
  created() {},
  methods: {
    forceOpen(note) {
      if (note && note.themeId) {
        const foundTheme = this.sortedThemes.filter(
          (theme) => theme._id == note.themeId
        )
        this.matchingTheme = foundTheme[0]

        setTimeout(() => {
          if (this.$refs["NotesSimilarSentencesResponses"]) {
            this.$refs["NotesSimilarSentencesResponses"].findSimilar(note)
          }
        }, 100)

        this.showDialog = true
      }
    },
    forceClose() {
      this.showDialog = false
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    async addNewNote(note_details) {
      if (this.sortedThemes) {
        let theseThemes = this.deepCloneObj(this.sortedThemes)
        for (let i = 0; i < theseThemes.length; i++) {
          if (theseThemes[i]._id == note_details.theme._id) {
            let note = {
              content: note_details.sentence.text,
              length:
                note_details.sentence.end_idx - note_details.sentence.start_idx,
              responseId: note_details.sentence.response_id,
              row_index: note_details.sentence.row_index,
              source: {
                questionId: this.textAnalysisObject.selectedTextQuestion._id,
                responseText: ""
              },
              startIndex: note_details.sentence.start_idx,
              text: ""
            }
            theseThemes[i].notes.push(note)
            let themes = []

            if (theseThemes[i]._id) {
              if (theseThemes[i]._id.$oid) {
                theseThemes[i]._id = theseThemes[i]._id.$oid
              }
            }

            themes.push(theseThemes[i])
            let payload = {
              themes: themes,
              text_analysis_uuid: this.textAnalysisObject.uuid
            }
            const savedThemes = await this.TEXT_SERVICE.saveThemes(
              payload
            ).then((response) => {
              return response
            })
            if (savedThemes.length) {
              let updatedThemeInfo = {
                themes: savedThemes,
                source: "CreateNote"
              }
              this.$emit("updateTheseThemes", updatedThemeInfo)
            }
          }
        }
      }
    }
  },
  watch: {}
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 60vw;
  width: calc(100% * 3);
  left: 10%;
}
</style>
