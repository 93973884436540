<template>
  <div class="tagging">
    <label class="tagging__label" :for="uuid">
      <slot></slot>
      <slot name="desc"></slot>
    </label>
    <div class="tagging__input">
      <input
        type="text"
        class="input"
        :id="uuid"
        :readOnly="readOnly"
        @keydown.enter.prevent="add"
        v-model="tag"
      />
      <ButtonIconOnly
        class="button"
        icon="add"
        :id="`btn-${uuid}`"
        @click-handler="add"
        :data-parent="$options.name"
      >
        {{ translate(buttonTitle, $options.name) }}
      </ButtonIconOnly>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "Tagging",
  components: {
    ButtonIconOnly
  },
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    buttonTitle: {
      default: "Add keyword",
      type: String
    }
  },
  data() {
    return {
      tag: "",
      uuid: this.$pigeonline.createUUID()
    }
  },
  methods: {
    add() {
      this.$emit("add", this.tag)
      this.tag = ""
    }
  }
}
</script>
