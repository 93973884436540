<template>
  <div
    class="project-analysis-item__analysis-settings"
    :class="[hasBeenSavedStyle, { expanded: isExpanded }]"
  >
    <h3 class="project-analysis-item__header-wrapper-title">
      {{ thisAnalysisName }}
    </h3>
    <!-- ****
    * Section that shows meta info and labels
    *** -->
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
      v-if="isWeighted"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <SvgIconDecorative icon="filter" />
          <VisibleText>Is weighted</VisibleText>
        </div>
      </div>
    </div>
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
      v-if="filterParams && filterParams.global_combine_action"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <SvgIconDecorative icon="filter" />
          <VisibleText>Filtered</VisibleText>
        </div>
        <button
          class="project-analysis-item__header-wrapper-btn"
          type="button"
          :aria-expanded="isExpanded ? 'true' : 'false'"
          @click.stop="showFilter = !showFilter"
          style="display: flex; flex-direction: row"
        >
          <span v-if="showFilter"><VisibleText>Hide</VisibleText></span>
          <span v-else><VisibleText>Show</VisibleText></span>
        </button>
      </div>
      <div v-if="showFilter" style="display: flex; margin: 0 0 0 1em">
        <FilterPills
          v-if="isFiltered"
          :isInToolBar="true"
          :filterParamFromSelectedQuestions="filterParams"
        >
        </FilterPills>
      </div>
    </div>
    <!-- ****
    * Section that holds options/buttons
    *** -->
    <div
      class="project-analysis-item__header-wrapper"
      style="display: flex; flex-direction: column"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <!-- ****
        * Saving comes first, relevant for all analysis
        *** -->
        <SaveAnalysis
          ref="SaveAnalysis"
          :propReadOnly="propReadOnly"
          :statusDisableActionBtns="statusDisableActionBtns"
          :index="index"
          :isDriversAnalysis="isDriversAnalysis"
          :isBenchmarkingAnalysis="isBenchmarkingAnalysis"
          :isTextAnalysis="isTextAnalysis"
          :isOutliersAnalysis="isOutliersAnalysis"
          :savedNodes="this.savedNodes"
          :contentChanged="this.contentChanged"
          @changeIsLoadingChartData="$emit('changeIsLoadingChartData')"
          @changeIsLoadingChartDataOff="$emit('changeIsLoadingChartDataOff')"
          @save-to-report="sendNodesToReport"
          @saved-slide="updateSavedSlide"
        ></SaveAnalysis>
        <!-- ****
        * Section relevant for performance analysis
        *** -->
        <div
          v-if="isPerformanceAnalysis && !isSegmented"
          class="project-analysis-item__stacked-wrapper"
        >
          <div class="project-analysis-item__label-container">
            <div class="project-analysis-item__header-wrapper-label">
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon"
                icon="report"
              />
              <VisibleText>Sort by</VisibleText>
            </div>
            <label class="project-analysis-item__main_label">
              <VisibleText>Sort rows by highest or lowest</VisibleText>
            </label>
          </div>

          <div>
            <div class="select-wrapper" style="width: 100%">
              <select
                class="select"
                style="font-size: 0.7rem; width: 100%"
                v-model="chosenSortOption"
                @click="$emit('change-sort-option', chosenSortOption)"
              >
                <option value="" selected disabled>Choose</option>
                <option
                  v-for="(sortOption, index) in sortOptions"
                  :value="sortOption"
                  :key="index"
                  style="font-size: 0.8rem"
                >
                  {{ sortOption }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div
          v-if="isPerformanceAnalysis"
          class="project-analysis-item__stacked-wrapper"
        >
          <div class="project-analysis-item__label-container">
            <div class="project-analysis-item__header-wrapper-label">
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon"
                icon="report"
              />
              <VisibleText>Top-box mode</VisibleText>
            </div>
            <label class="project-analysis-item__main_label">
              <VisibleText>Counts % above this point. -1 to reset</VisibleText>
            </label>
          </div>
          <div
            class="project-analysis-item__stacked-wrapper__bottom"
            style="display: flex; justify-content: center"
          >
            <input
              style="
                dispaly: flex;
                width: 30%;
                margin-top: 0;
                margin-left: auto;
                margin-right: auto;
                background-color: white;
              "
              type="number"
              v-model="threshold"
              @change="updateThreshold"
            />
          </div>
        </div>
        <!-- ****
        * Section relevant for drivers analysis
        *** -->
        <div
          v-if="isDriversAnalysis"
          class="project-analysis-item__stacked-wrapper"
        >
          <div class="project-analysis-item__label-container">
            <div class="project-analysis-item__header-wrapper-label">
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon"
                icon="report"
              />
              <VisibleText>Top drivers</VisibleText>
            </div>
            <label class="project-analysis-item__main_label">
              <VisibleText>Select number of drivers to show</VisibleText>
            </label>
          </div>
          <div
            class="project-analysis-item__stacked-wrapper__bottom"
            style="display: flex; justify-content: center"
          >
            <input
              type="number"
              v-model="modifiedFilterTop"
              @change="updateFilterTop"
              style="margin-top: 0; display: flex; width: 50%"
            />
          </div>
        </div>
        <div
          v-if="isDriversAnalysis"
          class="project-analysis-item__stacked-wrapper"
        >
          <div class="project-analysis-item__label-container">
            <div class="project-analysis-item__header-wrapper-label">
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon"
                icon="report"
              />
              <VisibleText>Modify model</VisibleText>
            </div>
            <label class="project-analysis-item__main_label">
              <VisibleText>Select prediction model</VisibleText>
            </label>
          </div>

          <div class="project-analysis-item__stacked-wrapper__bottom">
            <div>
              <div class="select-wrapper">
                <select
                  class="select"
                  id="saved-slide-filter"
                  style="font-size: 1em; width: 100%"
                  v-model="selectedDriversModel"
                  @change="
                    $emit('on-change-drivers-model', selectedDriversModel)
                  "
                  :disabled="propReadOnly"
                >
                  <option
                    v-for="(driverModel, index) in viableDriversModel"
                    :key="index"
                  >
                    {{ driverModel }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Decide if to keep this option. It works but takes up space and seems redundant -->

        <!-- <button
          v-if="isDriversAnalysis"
          class="project-analysis-item__header-wrapper-btn"
          style="width: 13%"
          @click="$emit('is-selected-var-visible', true)"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="editAlt"
          />
          <VisibleText>Modify Variables</VisibleText>
            <label
                class="value project-analysis-item__input-label"
                style="font-size: 0.6rem; font-weight: 400; text-align: center; margin-top: 0.5rem"
              >
                <VisibleText>Click to select new questions</VisibleText>
              </label>
        </button> -->

        <div
          class="project-analysis-item__stacked-wrapper"
          v-if="isBenchmarkingAnalysis"
        >
          <div class="project-analysis-item__label-container">
            <div class="project-analysis-item__header-wrapper-label">
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon"
                icon="report"
              />
              <VisibleText>Remove older than</VisibleText>
            </div>
            <label class="project-analysis-item__main_label">
              <VisibleText>Select a date</VisibleText>
            </label>
          </div>
          <input
            style="
              cursor: pointer;
              padding: 0;
              margin-top: 0;
              font-size: 0.9rem;
            "
            ref="suggested-date"
            type="date"
            id="form-suggested-date"
            v-model="filterByDate"
            @change="createFilterByDateParam()"
          />
        </div>

        <!-- Keeping below for when we add lickert, time-series, or bring back vertical -->
        <!-- <div
          v-if="isPerformanceAnalysis"
          class="project-analysis-item__stacked-wrapper"
          style="width: 14%"
        >
          <div class="project-analysis-item__header-wrapper-label">
            <SvgIconDecorative
              class="project-analysis-item__header-wrapper-icon"
              icon="report"
            />
            <VisibleText>Chart type</VisibleText>
          </div>
          <div class="project-analysis-item__stacked-wrapper__bottom">
            <div class="project-analysis-item__horizontal">
              <div class="select-wrapper">
                <select
                  class="form-control"
                  v-model="chosenChart"
                  @click="$emit('change-chart', chosenChart)"
                >
                  <option value="" selected disabled>Choose</option>
                  <option
                    v-for="(graphOption, index) in graphOptions"
                    :value="graphOption"
                    :key="index"
                  >
                    {{ graphOption }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div> -->

        <!-- ****
        * Section relevant for all analysis types
        *** -->

        <div class="project-analysis-item__stacked-wrapper">
          <div style="display: flex; flex-direction: row">
            <button
              class="project-analysis-item__header-wrapper-btn delete"
              @click="$emit('on-click-delete')"
              :disabled="propReadOnly"
            >
              <SvgIconDecorative
                class="project-analysis-item__header-wrapper-icon-delete"
                icon="deleteIcon"
              />
              <VisibleText>Delete</VisibleText>
            </button>
            <button
              class="project-analysis-item__header-wrapper-btn"
              type="button"
              :aria-expanded="isExpanded ? 'true' : 'false'"
              @click.stop="toggleContent"
              style="display: flex; flex-direction: row"
            >
              <span v-if="isExpanded"><VisibleText>Close</VisibleText></span>
              <span v-else><VisibleText>Open</VisibleText></span>
              <div style="display: flex">
                <SvgIconDecorative
                  :icon="isExpanded ? 'arrowUp' : 'arrowDown'"
                  :class="['right', 'icon']"
                  style="margin-top: 0.2em"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          font-size: 0.9em;
          padding-bottom: 0.6em;
          margin-top: -1.2em;
        "
        v-if="lastModified"
      >
        <span>
          <VisibleText>Last modified: </VisibleText> {{ lastModified }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SaveAnalysis from "@/components/Project/ProjectAnalysis/Components/SaveAnalysis.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

export default {
  name: "AnalysisSettings",
  components: {
    SaveAnalysis,
    SvgIconDecorative,
    FilterPills
  },
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    statusDisableActionBtns: {
      default: () => null,
      type: Boolean
    },
    index: {
      type: Number,
      required: true
    },
    thisAnalysisName: {
      default: () => null,
      type: String
    },
    isDriversAnalysis: {
      default: () => null,
      type: Boolean
    },
    filterParams: {
      default: () => null,
      type: Object
    },
    isBenchmarkingAnalysis: {
      default: () => null,
      type: Boolean
    },
    isTextAnalysis: {
      default: () => null,
      type: Boolean
    },
    isOutliersAnalysis: {
      default: () => null,
      type: Boolean
    },
    isPerformanceAnalysis: {
      default: () => null,
      type: Boolean
    },
    isSegmented: {
      default: () => "",
      type: String
    },
    sortOption: {
      default: () => "",
      type: String
    },
    savedThreshold: {
      default: () => -1,
      type: Number
    },
    viableDriversModel: {
      default: () => null,
      type: Array
    },
    savedNodes: {
      default: () => null,
      type: Array
    },
    contentChanged: {
      default: () => false,
      type: Boolean
    },
    filterTop: {
      type: Number
    },
    lastModified: {
      type: String,
      default: () => ""
    },
    hideSave: {
      default: () => false,
      type: Boolean
    },
    isWeighted: {
      default: () => false,
      type: Boolean
    },
    unfilteredDate: {
      default: () => "",
      type: String
    }
  },
  data() {
    return {
      selectedDriversModel: "",
      savedSlide: "",
      isExpanded: false,
      modifiedFilterTop: -1,
      threshold: -1, // apply automated max and min setter later
      showFilter: false,
      filterByDate: null,
      sortOptions: ["Original", "Lowest to Highest", "Highest to Lowest"],
      graphOptions: ["Horizontal Bar", "Vertical Bar"], // not being used
      chosenSortOption: "",
      chosenChart: ""
    }
  },
  methods: {
    toggleContent() {
      this.isExpanded = !this.isExpanded
      this.$emit("toggled", this.isExpanded)
    },
    updateCollections() {
      this.$refs.SaveAnalysis.updateCollections()
    },
    sendNodesToReport(selectedSlide, newSlideName) {
      this.$emit("save-to-report", selectedSlide, newSlideName)
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
      this.$emit("saved-slide", this.savedSlide)
    },
    createFilterByDateParam() {
      if (this.filterByDate) {
        let newParam = {
          global_combine_action: "and",
          operands: []
        }
        newParam.operands.push({
          operator: "created_after",
          client_question_id: null,
          values: this.filterByDate
        })
        this.$emit("on-change-benchmark-filters", newParam)
      } else {
        this.$emit("on-change-benchmark-filters")
      }
    },
    updateThreshold() {
      if (this.threshold >= -1) {
        this.$emit("applyChartThreshold", this.threshold)
      }
      if (this.threshold < -1) {
        this.threshold = -1
      }
    },
    updateFilterTop() {
      if (this.modifiedFilterTop >= 1) {
        this.$emit("filterTop", this.modifiedFilterTop)
      }
      if (this.modifiedFilterTop < 1) {
        this.modifiedFilterTop = 1
        this.$emit("filterTop", this.modifiedFilterTop)
      }
    }
  },
  created() {
    if (this.filterTop) {
      this.modifiedFilterTop = this.filterTop
    }
    if (this.unfilteredDate) {
      this.filterByDate = this.unfilteredDate
    }
    if (this.sortOption) {
      this.chosenSortOption = this.sortOption
    }
    if (this.savedThreshold) {
      this.threshold = this.savedThreshold
    }
  },
  watch: {
    expand: {
      immediate: true,
      handler: function (val) {
        this.isExpanded = val
      }
    },
    filterTop: async function (newVal, oldVal) {
      if (oldVal && newVal) {
        if (oldVal !== newVal) {
          this.modifiedFilterTop = newVal
        }
      }
    }
  },
  computed: {
    hasBeenSavedStyle() {
      if (this.savedNodes.length) {
        return "project-analysis-item__analysis-settings--saved"
      }
      return ""
    },
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>
