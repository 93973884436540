/**
 * When deleting a benchmark match, update the global question matches count
 *
 * Global question object:
 * {
 *  "_id" : <objectid>,
 *  "question_title" : <string>,
 *  "question_text" : <string>,
 *  "tags" : <string>,
 *  "benchmark_group" : <objectid>,
 *  "matches" : {       <--- update this field
 *     "approved" : 0,
 *     "pending" : 0
 *   }
 * }
 *
 * @param {Object} globalQ to update
 * @param {Object} benchmark object to be deleted
 * @returns {Array} benchmark object if update failed
 */
const onDeleteUpdateGlobalQuestionBenchmarkMatchCount = async (
  DATASETS_SERVICE,
  globalQ,
  benchmark
) => {
  let failed = []
  // if benchmark was approved or pending, reduce global question count
  const approvedCount = benchmark.approved
    ? Math.max(0, globalQ.matches.approved - 1)
    : globalQ.matches.approved
  const pendingCount =
    !benchmark.approved && !benchmark.rejected
      ? Math.max(0, globalQ.matches.pending - 1)
      : globalQ.matches.pending

  // counts were updated
  if (
    approvedCount != globalQ.matches.approved ||
    pendingCount != globalQ.matches.pending
  ) {
    globalQ.matches.approved = approvedCount
    globalQ.matches.pending = pendingCount
    await DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
      matches: globalQ.matches
    }).catch((e) => {
      /**
       * 304 error because there are multiple matches to a global question
       * so counts were not updated or updates are happening in parallel
       * keep track of any failed benchmark updates and return
       **/
      failed.push(benchmark)
      console.error(e)
    })
  }
}

export { onDeleteUpdateGlobalQuestionBenchmarkMatchCount }
