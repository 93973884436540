<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText
        >Revolutionizing airport operations with ResearchAI:</VisibleText
      >
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>2023-12-15</VisibleText>
    </p>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%"
      src="/assets/landing/revolutionizing-airport-cx.jpg"
      alt=""
    />
    <p>
      <VisibleText>
        Airports operate in a complex environment that requires a seamless flow
        of information to ensure operations run smoothly and passenger
        experiences are positive.
        <a
          href="https://www.oliverwymanforum.com/mobility/2023/jun/airport-evolution-travel-trends-by-2050.html"
          target="_blank"
          >Oliver Wyman's 2023 report</a
        >
        on the airline industry predicted an upcoming passenger experience
        revolution thanks to growing data availability and analysis.
        Unfortunately, traditional methods of managing customer experience (CX)
        and operational data are becoming obsolete as they fail to provide the
        holistic and real-time insights necessary for effective decision-making.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        ResearchAI's platform is designed to address this challenge head-on.
      </VisibleText>
    </p>

    <h2 class="library__h2">
      <VisibleText>Challenges in contemporary airport management</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Airports have long struggled with the separation of CX and operational
        data, leading to delays in identifying and addressing service delivery
        issues. Reports that take weeks to generate are not adequate to
        understand the full scope of the passenger journey, much less to enhance
        it.
      </VisibleText>
    </p>
    <h3><VisibleText>ResearchAI is the solution</VisibleText></h3>
    <p>
      <VisibleText>
        ResearchAI offers a sophisticated and easy-to-use AI-driven SaaS
        platform that synthesizes disparate data streams, offering a
        comprehensive view of the customer journey. Airport leadership can
        integrate qualitative and quantitative data and then flag opportunities
        for service improvement dynamically.
      </VisibleText>
    </p>

    <h2 class="library__h2">
      <VisibleText
        >Case Study: Large U.S. Airport's Service Improvement
        Journey</VisibleText
      >
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        A U.S. airport's collaboration with ResearchAI illustrates the power of
        the platform. The site management leveraged six months of
        cross-sectional data identified specific passenger concerns, and enacted
        strategies to address them.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        This proactive approach transformed passenger experiences on a granular
        level – for example, it was quickly identified that late arrivals from
        NYC faced a dearth of dining options. These insights led to targeted
        service enhancements and a subsequent increase in passenger
        satisfaction.
      </VisibleText>
    </p>
    <div class="library__heading-line"></div>
    <div
      style="
        height: 0.2rem;
        width: 5rem;
        background-color: var(--primary-lightest);
      "
    ></div>
    <p>
      <VisibleText>
        Airports have long struggled with the separation of CX and operational
        data, leading to delays in identifying and addressing service delivery
        issues. Reports that take weeks to generate are not adequate to
        understand the full scope of the passenger journey, much less to enhance
        it.
      </VisibleText>
    </p>
    <ol class="library__list">
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Customer journey insights </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            Through the thematic analysis of CSAT scores and behaviors, the
            platform identifies dissatisfied passenger segments and the
            underlying service issues, informing targeted interventions.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Automated data synthesis </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            ResearchAI facilitates the seamless automation of data collection,
            organizing disparate data sets, including passenger feedback and
            operational metrics, into actionable insights.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Passenger experience customization </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            Leveraging AI, airport services can be tailored to match the
            preferences and behaviors identified through passenger feedback
            analysis.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText>
            Time savings in sentiment and social media analysis
          </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            The platform's sentiment analysis tools provide a real-time gauge of
            passenger mood, while its social media monitoring capabilities
            ensure airport management can quickly respond to emerging issues.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Behavioral and operational analysis </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            The platform assists in optimizing airport layouts and operations by
            analyzing video surveillance and operational data, leading to
            enhanced efficiency.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Safety and security theme identification </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            The AI-driven analysis of incident reports and security feeds allows
            for improved safety measures and threat identification, ensuring a
            secure airport environment.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Simplicity in strategic decision-making </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            ResearchAI facilitates informed decision-making, ensuring that
            airport strategies are aligned with passenger needs, thereby
            refining the overall travel experience.
          </VisibleText>
        </p>
      </li>
    </ol>
    <h2 class="library__h2">
      <VisibleText>Conclusive impact and ethical consideration</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p></p>
    <p>
      <VisibleText>
        AI-powered data collection and analysis is unequivocally a new era for
        airport operations, one where efficiency, passenger satisfaction, and
        safety are not just goals but achievable standards. Of course, there
        needs to be a high degree of responsibility with such technology – which
        is why ResearchAI remains committed to the ethical use of data,
        respecting privacy, and ensuring responsible AI deployment.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Elevate Your Airport Operations</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        ResearchAI's platform offers a transformative approach to airport
        operations by integrating and analyzing data across the entire customer
        journey. This platform provides real-time insights, enabling airport
        management to enhance passenger experience and operational efficiency
        with unprecedented speed and effectiveness.
      </VisibleText>
    </p>
    <h3>
      <VisibleText
        >At the core of our offering are our five key principles:</VisibleText
      >
    </h3>
    <ul class="library__list">
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Actionable insights </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            We provide insights that drive action. Our analytics are designed to
            be directly applicable, allowing leaders to make informed decisions
            swiftly and confidently.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Ease of use </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            Our platform is built with user-friendliness at its core. We believe
            in making sophisticated AI technology accessible and understandable,
            ensuring that all users can leverage the full potential without the
            need for extensive training.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Same-day results </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            Time is of the essence, that's why we deliver results within the
            same day. Our commitment to efficiency ensures that you can respond
            to operational challenges and opportunities as they arise.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Ethical use of AI </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            We hold ourselves to the highest ethical standards in the use of AI.
            Our technology is developed with a commitment to responsible and
            fair use, ensuring that every decision, recommendation, and action
            taken as a result of our insights upholds the highest ethical
            considerations.
          </VisibleText>
        </p>
      </li>
      <li style="margin-bottom: 1.5rem">
        <h4>
          <VisibleText> Privacy Protection </VisibleText>
        </h4>
        <p style="margin-top: -0.5rem">
          <VisibleText>
            At the heart of our operations is a steadfast commitment to privacy.
            We deploy advanced safeguards to protect the data we handle,
            ensuring that personal information is treated with the utmost
            respect and confidentiality.
          </VisibleText>
        </p>
      </li>
    </ul>
    <p>
      <VisibleText>
        We invite airports of all sizes to embark on a data-driven journey
        towards optimized operations and heightened passenger satisfaction.
        Harnessing the power of AI for better decision-making can fundamentally
        redefine airport experiences for the benefit of all stakeholders.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Contact us to discover how our AI solutions can revolutionize your data
        analysis and operational strategies.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Innovative Real-Time Solutions at the Airport:</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        In a striking example of the transformative power of real-time data
        analytics, a large airport leveraged advanced technology to enhance its
        customer experience significantly. Over six months, the airport analyzed
        data from 30,000 passengers, tracking their journey from airline gates
        to restaurants and gathering vital insights on demographics and customer
        sentiments.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        What sets this case apart is the airport management's newfound ability
        to swiftly identify and address passenger concerns. Traditional methods
        that would have taken weeks to collate and analyze feedback were
        replaced by a system that expedited the process to mere minutes. This
        innovative approach enabled the airport managers to pinpoint exactly
        what was upsetting passengers, identify the specific demographic groups
        affected, and devise prompt, effective strategies to address these
        issues.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The success story of this airport exemplifies the remarkable
        capabilities of modern data analytics in transforming customer service.
        It stands as a testament to how businesses, by adopting similar
        technologies, can gain a competitive edge through the ability to rapidly
        identify and react to customer feedback, leading to improved
        satisfaction and loyalty.
      </VisibleText>
    </p>

    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image library__image--nonresponsive"
          style="max-width: 4rem"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new.
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              Or reach out to us at:
              <a href="mailto:hello@researchai.io">hello@researchai.io</a>
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "revolutionizing_airport_operations",
  components: {
    //SvgIconDecorative
  },
  setup() {
    const siteData = reactive({
      title: "Revolutionizing airport operations with Research AI",
      description:
        "ResearchAI's platform is designed to address airport CX challenges head-on"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
