// Helpers
import {
  PigeonDocModel,
  PigeonDocNodeModel,
  PigeonDocCollectionModel
} from "@pigeonline/pigeondoc"
import EmptyReport from "../../Reports/Models/EmptyReport"
import ICCSReport from "../../Reports/Models/ICCSReport"

import UserMixin from "@/utils/mixins/userMixin.js"

// Benchmarking
import { benchmarkingSummarySection } from "../../Reports/Utils/Library/Benchmarking/benchmarkingSummarySection"
import { benchmarkingQbyQSection } from "../../Reports/Utils/Library/Benchmarking/benchmarkingQbyQSection"
import { benchmarkingMethodologySection } from "../../Reports/Utils/Library/Benchmarking/benchmarkingMethodologySection"

// Priorities for service improvement
import {
  driversSection,
  matrixSection,
  prioritiesOfServiceImprovementHeader
} from "../../Reports/Utils/Library/Drivers/serviceImprovementsSections"

// Text
import { textThemesTableSection } from "../../Reports/Utils/Library/Text/defaultTextSections"

// Outliers
import { outliersSection } from "../../Reports/Utils/Library/Outliers/defaultOutliersSections"

// Performance
import { performanceSection } from "../../Reports/Utils/Library/Performance/defaultPerformanceSections"
import { performanceSimpleScoreChart as performanceChartNode } from "@/components/Reports/Utils/Library/Performance/performanceNodes.js"
// Tools
import { titlePageToolNode } from "../../Reports/Utils/Library/Tools/titlePageNode.js"
import { bannerToolNode } from "../../Reports/Utils/Library/Tools/bannerNode.js"
import { textToolNode } from "../../Reports/Utils/Library/Tools/textNode.js"
import { headerToolNode } from "../../Reports/Utils/Library/Tools/headerNode.js"

// Header
import { date } from "../../Reports/Utils/Library/Header/headerNodes.js"

// import sections from "@/components/Reports/sections/sections.js"

export default {
  mixins: [UserMixin],
  methods: {
    async cleanAndSaveProject() {
      if (this.propReadOnly) {
        return
      }
      let cleanedProject = this.deepCloneObj(this.project)
      cleanedProject.id = this.project.id
      if (cleanedProject._id) {
        delete cleanedProject._id
      }
      if (
        (cleanedProject.textAnalysisObjects &&
          cleanedProject.textAnalysisObjects.length) ||
        (cleanedProject.driversAnalysis &&
          cleanedProject.driversAnalysis.length) ||
        (cleanedProject.outliersAnalysis &&
          cleanedProject.outliersAnalysis.length) ||
        (cleanedProject.benchmarkAnalysis &&
          cleanedProject.benchmarkAnalysis.length) ||
        (cleanedProject.performanceAnalysis &&
          cleanedProject.performanceAnalysis.length)
      ) {
        cleanedProject.status.analysisCompleted = true
      } else {
        cleanedProject.status.analysisCompleted = false
      }
      this.saveProject(cleanedProject)
    },
    addNodesStartingAtIndex(report, nodes, startIndex) {
      for (let i = 0; i < nodes.length; i++) {
        report.insertNodeAtIndex(nodes[i], startIndex + i)
      }
    },
    lastIndexOfNode(nodes, key, value) {
      for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i][key] === value) return i
      }
      return -1
    },
    updateDate(report) {
      if (this.propReadOnly) {
        return
      }
      const headerSection = report.nodes.find(
        (node) => node.id === "header-section"
      )
      const dateNodeIndx = headerSection.content.findIndex(
        (node) => node.id === "header-date"
      )
      const newDateNode = date()
      headerSection.content.splice(dateNodeIndx, 1, newDateNode)
    },
    async updateReportWithDriversHeader(reportId) {
      const lang = "en"
      if (this.propReadOnly) {
        return
      }
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let sectionHeaderNode = report.nodes.find(
        (node) => node.id === "section-title-drivers"
      )

      if (!sectionHeaderNode) {
        // create section header
        const header = prioritiesOfServiceImprovementHeader(
          lang,
          this.$pigeonline.createUUID()
        )

        report.nodes.push(header)

        sectionHeaderNode = report.nodes.find(
          (node) => node.id === "section-title-drivers"
        )
      }
      await this.updateReport(report)
      return report.nodes
    },
    async updateReportWithDrivers(
      driversUUID,
      reportId,
      chartData,
      driversChartTitle,
      skipMatrix
    ) {
      if (this.propReadOnly) {
        return
      }
      /******************************
       * Setup variables
       ******************************/
      const lang = "en"
      const uuid = driversUUID
        ? driversUUID
        : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let newNodes = []

      // index to determine where we need to insert the nodes
      let sectionHeaderNode = report.nodes.find(
        (node) => node.id === "section-title-drivers"
      )
      const currIndex = report.nodes.findIndex(
        (node) => node.meta.driversUUID === uuid
      )

      /******************************
       * Create section nodes
       ******************************/

      // create drivers section
      const driversSectionNode = new PigeonDocNodeModel(
        driversSection(
          {
            driversTitle: driversChartTitle,
            driversChartData: chartData,
            driversUUID: uuid
          },
          lang
        )
      )
      // Matrix should only be produced if IVs are all the same scale
      let matrixSectionNode
      if (!skipMatrix) {
        // create priority matrix section
        matrixSectionNode = new PigeonDocNodeModel(
          matrixSection(
            {
              matrixChartData: chartData,
              matrixTitle: driversChartTitle
            },
            lang
          )
        )
      }

      /******************************
       * Insert nodes
       ******************************/
      // sections exists update them
      if (currIndex >= 0) {
        report.replaceNodeAtIndex(driversSectionNode, currIndex)
        if (!skipMatrix) {
          report.replaceNodeAtIndex(matrixSectionNode, currIndex + 1)
        }
      }
      // sections dont exist append after section header
      else {
        report.insertAfter({
          insert: driversSectionNode,
          after: sectionHeaderNode
        })
        if (!skipMatrix) {
          report.insertAfter({
            insert: matrixSectionNode,
            after: driversSectionNode
          })
        }
      }
      newNodes.push(driversSectionNode)
      if (!skipMatrix) {
        newNodes.push(matrixSectionNode)
      }
      /******************************
       * Update report
       ******************************/
      // this.updateDate(report) // being moved to its own place
      await this.updateReport(report)
      let returnObject = {
        uuid: uuid,
        newNodes: newNodes
      }
      return returnObject
    },
    async updateReportWithBenchmarks(
      benchmarkUUID,
      createdReportID,
      summarySectionNodeParams,
      benchmarkingQbyQSectionNodeParams,
      benchmarkingMethodologySectionNodeParams,
      filterParam
    ) {
      if (this.propReadOnly) {
        return
      }
      /******************************
       * Setup variables
       ******************************/
      const lang = "en"
      const uuid = benchmarkUUID
        ? benchmarkUUID
        : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        createdReportID
      )
      let newNodes = []
      let parsedDate = null
      let otherFilters = []
      if (filterParam && filterParam.operands) {
        for (let i = 0; i < filterParam.operands.length; i++) {
          if (filterParam.operands[i].operator === "created_after") {
            parsedDate = filterParam.operands[i].values
          } else {
            const thisQuestion = this.$store.getters[
              "datasetDetails/getClientQuestions"
            ].find(
              (q) => q._id.$oid === filterParam.operands[i].client_question_id
            )
            if (thisQuestion) {
              let thisObject = {
                question_text: thisQuestion.question_text,
                question_title: thisQuestion.question_title,
                valuesForComparison: filterParam.operands[i].values
              }
              otherFilters.push(thisObject)
            }
          }
        }
      }
      /******************************
       * Create section nodes
       ******************************/
      const summarySectionNode = new PigeonDocNodeModel(
        benchmarkingSummarySection(
          {
            summaryTable: summarySectionNodeParams.summaryTable,
            summaryTableColNames: summarySectionNodeParams.summaryTableColNames,
            overallDynamicSummary:
              summarySectionNodeParams.overallDynamicSummary,
            uuid: summarySectionNodeParams.uuid,
            dateParams: parsedDate,
            otherFilters: otherFilters
          },
          lang
        )
      )
      const questionByQuestionSection = benchmarkingQbyQSection(
        {
          clientQuestions: benchmarkingQbyQSectionNodeParams.clientQuestions,
          clientQuestionAnalysis:
            benchmarkingQbyQSectionNodeParams.clientQuestionAnalysis,
          clientBenchmarkGroups:
            benchmarkingQbyQSectionNodeParams.clientBenchmarkGroups,
          detailedResults: benchmarkingQbyQSectionNodeParams.detailedResults,
          globalMatchesDetails:
            benchmarkingQbyQSectionNodeParams.globalMatchesDetails,
          uuid: benchmarkingQbyQSectionNodeParams.uuid
        },
        lang
      )
      const methodologySectionNode = new PigeonDocNodeModel(
        benchmarkingMethodologySection(
          benchmarkingMethodologySectionNodeParams.data_set_meta_data,
          questionByQuestionSection.orgs,
          lang,
          benchmarkingMethodologySectionNodeParams.uuid
        )
      )
      /******************************
       * Insert nodes
       ******************************/

      //const benchmarkingSummaryIndex = report.nodes.findIndex(
      //(node) => node.id == "benchmarking-summary-section"
      //)
      // report.nodes = []
      // benchmarks already exists
      //if (benchmarkingSummaryIndex >= 0) {
      // replace summary table
      //report.replaceNodeAtIndex(summarySectionNode, benchmarkingSummaryIndex)
      // delete everything after the benchmark question by question header section
      // because we are assuming that this is the last section we can replace it
      // with the new nodes

      // const qByqSectionHeader = report.nodes.findIndex(
      //   node => node.id == "benchmarking-qbyq-header-section"
      // )
      // report.nodes.length = qByqSectionHeader + 1
      //}
      // benchmark doesnt exist
      //else {
      // add summary table after header
      // const headerIndex = report.nodes.findIndex(
      //   node => node.id == "header-section"
      // )
      // if (headerIndex) {
      //   report.insertAfter({
      //     insert: summarySectionNode,
      //     after: report.nodes[headerIndex]
      //   })
      // } else {
      //   report.nodes.push(summarySectionNode)
      // }
      //report.nodes.push(summarySectionNode)
      //}
      // append question by question section and methodology sections
      report.nodes.push(summarySectionNode)
      report.nodes.push(...questionByQuestionSection.sections)
      report.nodes.push(methodologySectionNode)
      newNodes.push(summarySectionNode)
      newNodes.push(...questionByQuestionSection.sections)
      newNodes.push(methodologySectionNode)
      /******************************
       * Update report
       ******************************/
      // this.updateDate(report)
      await this.updateReport(report)
      let returnObject = {
        newNodes: newNodes,
        uuid: uuid
      }
      return returnObject
    },
    async updateReportWithText(
      textUUID,
      reportId,
      textTableData,
      textTitle,
      chartData,
      hasSentiment
    ) {
      if (this.propReadOnly) {
        return
      }
      let reportHasSentiment = false
      if (hasSentiment) {
        reportHasSentiment = true
      }
      /******************************
       * Setup variables
       ******************************/
      const lang = "en"
      const uuid = textUUID ? textUUID : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let newNodes = []
      const currIndex = report.nodes.findIndex(
        (node) => node.meta.textUUID === uuid
      )
      /******************************
       * Create section nodes
       ******************************/
      // create main text table
      const textThemesTableNode = new PigeonDocNodeModel(
        textThemesTableSection(
          {
            textTitle: textTitle,
            textTableData: textTableData,
            textUUID: uuid,
            hasSentiment: reportHasSentiment,
            chartData: chartData
          },
          lang
        )
      )
      /******************************
       * Insert nodes
       ******************************/
      // sections exists update them
      if (currIndex >= 0) {
        report.replaceNodeAtIndex(textThemesTableNode, currIndex)
      }
      // sections dont exist append after section header
      else {
        // report.insertAfter({
        //   insert: textThemesTableNode,
        //   after: sectionHeaderNode
        // })
        report.nodes.push(textThemesTableNode)
      }
      newNodes.push(textThemesTableNode)

      /******************************
       * Update report
       ******************************/
      // this.updateDate(report) // being moved to its own place

      await this.updateReport(report)
      let returnObject = {
        uuid: uuid,
        newNodes: newNodes
      }
      return returnObject
    },
    async updateReportWithOutliers(
      outlierUUID,
      reportId,
      outliersAnalysisObject
    ) {
      if (this.propReadOnly) {
        return
      }
      /******************************
       * Setup variables
       ******************************/
      const lang = "en"
      const uuid = outlierUUID
        ? outlierUUID
        : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let newNodes = []
      const currIndex = report.nodes.findIndex(
        (node) => node.meta.outlierUUID === uuid
      )
      /******************************
       * Create section nodes
       ******************************/
      const OutliersNode = new PigeonDocNodeModel(
        outliersSection(
          {
            outliersTitle: "testTitle",
            outliersData: outliersAnalysisObject,
            outliersUUID: uuid
          },
          lang
        )
      )
      /******************************
       * Insert nodes
       ******************************/
      // sections exists update them
      if (currIndex >= 0) {
        report.replaceNodeAtIndex(OutliersNode, currIndex)
      }
      // sections dont exist append after section header
      else {
        // report.insertAfter({
        //   insert: textThemesTableNode,
        //   after: sectionHeaderNode
        // })
        report.nodes.push(OutliersNode)
      }
      newNodes.push(OutliersNode)
      /******************************
       * Update report
       ******************************/
      await this.updateReport(report)
      let returnObject = {
        uuid: uuid,
        newNodes: newNodes
      }
      return returnObject
    },
    async updateReportWithPerformance(
      performanceUUID,
      reportId,
      performanceAnalysisObject
    ) {
      if (this.propReadOnly) {
        return
      }
      /******************************
       * Setup variables
       ******************************/
      const lang = "en"
      const uuid = performanceUUID
        ? performanceUUID
        : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let newNodes = []
      const currIndex = report.nodes.findIndex(
        (node) => node.meta.performanceUUID === uuid
      )
      /******************************
       * Create section nodes
       ******************************/
      const PerformanceNode = new PigeonDocNodeModel(
        performanceSection(
          {
            performanceTitle: "testTitle",
            performanceData: performanceAnalysisObject.summary_table,
            performanceChartType: performanceAnalysisObject.chartType,
            performanceThreshold: performanceAnalysisObject.threshold,
            performanceUUID: uuid
          },
          lang
        )
      )
      /******************************
       * Insert nodes
       ******************************/
      // sections exists update them
      if (currIndex >= 0) {
        report.replaceNodeAtIndex(PerformanceNode, currIndex)
      }
      // sections dont exist append after section header
      else {
        report.nodes.push(PerformanceNode)
      }
      newNodes.push(PerformanceNode)
      /******************************
       * Update report
       ******************************/
      await this.updateReport(report)
      let returnObject = {
        uuid: uuid,
        newNodes: newNodes
      }
      return returnObject
    },

    async updateReportWithTool(toolUUID, reportId, toolData, index, type) {
      if (this.propReadOnly) {
        return
      }
      /******************************
       * Setup variables
       ******************************/
      const uuid = toolUUID ? toolUUID : (Math.random() * Date.now()).toFixed(0)
      const report = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        reportId
      )
      let newNodes = []
      let foundExistingBanner = false
      let foundExistingTitlePage = false
      if (report.nodes) {
        for (let i = 0; i < report.nodes.length; i++) {
          if (report.nodes[i].type == "banner") {
            foundExistingBanner = true
          }
          if (report.nodes[i].type == "titlePage") {
            foundExistingTitlePage = true
          }
        }
      }
      let toolNode = {}
      if (type === "text") {
        toolNode = new PigeonDocNodeModel(
          textToolNode({
            toolData: toolData
          })
        )
      } else if (type === "header") {
        toolNode = new PigeonDocNodeModel(
          headerToolNode({
            toolData: toolData
          })
        )
      } else if (type === "titlePage") {
        toolNode = new PigeonDocNodeModel(
          titlePageToolNode({
            toolData: toolData
          })
        )
      } else if (type === "banner") {
        toolNode = new PigeonDocNodeModel(
          bannerToolNode({
            toolData: toolData
          })
        )
      }
      if (toolNode) {
        let replaceAtIndex = false
        if (foundExistingBanner && type == "titlePage") {
          index = 1
        }
        if (foundExistingBanner && type == "banner") {
          replaceAtIndex = true
          report.nodes[0] = toolNode
        }
        if (foundExistingTitlePage && type == "titlePage") {
          replaceAtIndex = true
          report.nodes[1] = toolNode
        }
        if (!replaceAtIndex) {
          if (index > -1) {
            report.nodes.splice(index, 0, toolNode)
          } else {
            report.nodes.push(toolNode)
          }
        }
        newNodes.push(toolNode)
        await this.updateReport(report)
        let returnObject = {
          uuid: uuid,
          newNodes: newNodes
        }
        return returnObject
      } else {
        return {}
      }
    },

    async updateReport(report) {
      try {
        if (this.propReadOnly) {
          return
        }
        if (this.project.shared && this.project.shared.length) {
          report.shared = this.project.shared
        }
        if (this.project.access_type) {
          report.access_type = this.project.access_type
          if (this.project.owning_team_id) {
            report.owning_team_id = this.project.owning_team_id
          } else {
            report.owning_team_id = ""
          }
        }
        await this.$pigeonline.projects.update(report)
      } catch (e) {
        throw new Error("projectMixin:updateReport " + e.message)
      }
    },
    // date, datasetName, org = "",
    async createReportIfEmpty(selectedSlide, newSlideName) {
      if (this.propReadOnly) {
        return
      }
      let createdReportID
      let newDatasetName = ""
      if (this.dataset) {
        if (this.dataset.name) {
          newDatasetName = this.dataset.name
        }
      }
      let newOrganizationName = ""
      // As of 1.8.0
      // This used to be filled by a 'getter', but now we can let them select from .teams if they want to associate a report with a team.
      // Therefore, for now, the below will never be filled in.

      if (this.organization) {
        if (this.organization.organization_name) {
          newOrganizationName = this.organization.organization_name
        }
      }
      if (newSlideName.length > 0) {
        // SlideName information has been sent (make sure to empty this in the parent)
        if (!selectedSlide) {
          // No slides detected, run checks that assume first time use
          if (!this.project.collection_id) {
            // This should happen anyway with no slides but double checking
            if (!this.project.report) {
              // legacy way of storing this, but maintaining until removal. The above will matter more

              let emptyReport
              if (this.$theme.theme.name == "iccs") {
                emptyReport = new ICCSReport({
                  name: newSlideName,
                  dataset_name: newDatasetName,
                  organization: newOrganizationName
                })
              } else {
                emptyReport = new EmptyReport({ name: newSlideName })
              }
              emptyReport.initializeReport()

              // Always ensure these are owned by the original project owner
              const owner = this.project.owner
              emptyReport.owner = owner

              if (this.project.shared && this.project.shared.length) {
                emptyReport.shared = this.project.shared
              }
              if (this.project.access_type) {
                emptyReport.access_type = this.project.access_type
                if (this.project.access_type == "public__team") {
                  emptyReport.owning_team_id = this.project.owning_team_id
                } else {
                  emptyReport.owning_team_id = ""
                }
              }

              const reportProject =
                await this.$pigeonline.projects.create(emptyReport)
              const id = reportProject.id
              this.project.report = id // does this essentiall just become slide 1?
              createdReportID = id

              this.project.collection_id = ""
              let docIds = []
              docIds.push(id)

              let emptyCollection = new PigeonDocCollectionModel({
                docs: docIds
              })
              emptyCollection.owner = owner

              if (this.project.shared && this.project.shared.length) {
                emptyCollection.shared = this.project.shared
              }
              if (this.project.access_type) {
                emptyCollection.access_type = this.project.access_type
                if (this.project.access_type == "public__team") {
                  emptyCollection.owning_team_id = this.project.owning_team_id
                } else {
                  emptyCollection.owning_team_id = ""
                }
              }

              const newCollection =
                await this.$pigeonline.projects.create(emptyCollection)

              if (this.project.shared && this.project.shared.length) {
                newCollection.shared = this.project.shared
                await this.saveCollection(newCollection)

                reportProject.shared = this.project.shared
                await this.$pigeonline.projects.update(reportProject)
              }
              if (this.project.access_type) {
                newCollection.access_type = this.project.access_type
                reportProject.access_type = this.project.access_type
                if (this.project.access_type == "public__team") {
                  newCollection.owning_team_id = this.project.owning_team_id
                  reportProject.owning_team_id = this.project.owning_team_id
                } else {
                  newCollection.owning_team_id = ""
                  reportProject.owning_team_id = ""
                }
                await this.saveCollection(newCollection)
                await this.$pigeonline.projects.update(reportProject)
              }

              newCollection.add(docIds) // this isn't actually saving?
              this.project.collection_id = newCollection.id

              // Save
              // this.project.updateStatus("reportCreated")

              this.project.status.reportCreated = true
              await this.cleanAndSaveProject()
              // await this.saveProject(this.project)
              this.$emit("updateProject", this.project)
            }
          } else {
            let emptyReport
            if (
              this.parsedLicenses.includes("iccs") ||
              this.parsedLicenses.includes("iccs-admin")
            ) {
              emptyReport = new ICCSReport({
                name: newSlideName,
                dataset_name: newDatasetName,
                organization: newOrganizationName
              })
            } else {
              emptyReport = new EmptyReport({ name: newSlideName })
            }
            emptyReport.initializeReport()

            // Always ensure these are owned by the original project owner
            const owner = this.project.owner
            emptyReport.owner = owner

            const reportProject =
              await this.$pigeonline.projects.create(emptyReport)
            const id = reportProject.id
            createdReportID = id

            let collection
            collection = await this.$pigeonline.projects.getByID(
              PigeonDocCollectionModel,
              this.project.collection_id
            )
            if (collection) {
              collection.owner = owner
              collection.docIds.push(id)
              if (this.project.shared && this.project.shared.length) {
                collection.shared = this.project.shared
                reportProject.shared = this.project.shared
                await this.saveCollection(collection)
                await this.$pigeonline.projects.update(reportProject)
              }
              if (this.project.access_type) {
                collection.access_type = this.project.access_type
                reportProject.access_type = this.project.access_type
                if (this.project.owning_team_id) {
                  collection.owning_team_id = this.project.owning_team_id
                  reportProject.owning_team_id = this.project.owning_team_id
                }
                await this.saveCollection(collection)
                await this.$pigeonline.projects.update(reportProject)
              }
            }
          }
        }
      }
      return createdReportID
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  }
}
