<template>
  <MessageBlock
    :message-type="messages[statusName].type"
    :title="messages[statusName].title"
    :data-parent="$options.name"
    v-if="Object.keys(messages).includes(statusName)"
  >
    {{ translate(messages[statusName].message, $options.name) }}
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// NOTE - these are likely in need of cleaning up, some not being used
const MESSAGES = {
  hasNoScale: {
    title: "Not enough scale questions",
    message:
      "We could not find enough scale questions. You need at least one to show how they perform."
  }
}

export default {
  name: "PerformanceMessages",
  props: {
    statusName: {
      default: () => "",
      type: String
    }
  },
  components: {
    MessageBlock
  },
  data() {
    return {
      messages: MESSAGES
    }
  }
}
</script>
