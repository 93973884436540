<template>
  <Modal
    header-tag="h1"
    id="projects-create-modal"
    :show="show"
    @close="$emit('close')"
  >
    <template #header><VisibleText>Create New Project</VisibleText></template>
    <div
      style="
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      "
      v-if="isLoading"
    >
      <span
        class="also-found__loading-spinner spinner"
        style="display: flex; height: 2rem; width: 2rem"
      ></span>
    </div>

    <!--------------------------------------------
    |
    | NO DATASETS
    |
    --------------------------------------------->
    <ProjectsCreateModalEmpty
      v-if="datasets.length == 0 && !isLoading"
      :parsedLicenses="parsedLicenses"
    />

    <!--------------------------------------------
    |
    | DATASETS AVAILABLE
    |
    --------------------------------------------->
    <form @submit.prevent novalidate v-else>
      <p
        class="error-msg__inline form-style-group-err"
        id="title-err"
        v-show="err.title"
      >
        {{ err.title }}
      </p>
      <label for="project-name" class="form-style-group-label">
        <span class="form-style-group-text">
          <VisibleText>Title</VisibleText>
        </span>
        <div
          :class="[
            'form-style-group-input-wrapper',
            { 'input-err': err.title }
          ]"
        >
          <SvgIconDecorative icon="editAlt" id="create-modal-title-icon" />
          <input
            aria-describedby="title-err"
            required
            id="project-name"
            type="text"
            ref="title"
            :aria-invalid="err.title && !project.name ? 'true' : 'false'"
            v-model="project.name"
          />
        </div>
      </label>
      <fieldset
        class="form-fieldset-group form-style-group-fieldset"
        ref="dataset"
      >
        <legend
          class="form-fieldset-group__legend form-style-group-fieldset-legend"
        >
          <span
            class="form-style-group form-fieldset-err"
            v-show="err.datasets"
          >
            {{ err.datasets }}
          </span>
          <strong><VisibleText>Select Dataset</VisibleText></strong>
        </legend>
        <div class="create-modal__details">
          <VisibleText>Choose a dataset for this project.</VisibleText>
          <div
            v-if="
              permissions.includes('create-dataset') &&
              (!this.parsedLicenses ||
                !(
                  this.parsedLicenses.length == 1 &&
                  this.parsedLicenses.includes('demo')
                ))
            "
          >
            <LinkIconOnly
              class="create-modal__details-upload"
              href="/dataset"
              id="create-modal-upload-dataset"
              icon="upload"
            >
              <VisibleText>Upload dataset</VisibleText>
            </LinkIconOnly>
          </div>
        </div>
        <ProjectsCreateModalDatasets
          :datasets="datasets"
          :pre-selected="preSelected"
          @selectedDataset="selectedDataset"
        />
      </fieldset>
    </form>
    <template #footer>
      <button
        class="form-btn"
        type="submit"
        @click="createProject"
        v-if="datasets.length > 0"
      >
        <VisibleText>Create Project</VisibleText>
      </button>
      <button class="form-btn-secondary" @click="$emit('close')">
        <VisibleText>Close</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import LinkIconOnly from "@/components/UI/Link/LinkIconOnly.vue"
import Modal from "@/components/UI/Modal.vue"
import ProjectsCreateModalDatasets from "./ProjectsCreateModalDatasets.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

// Mixins
import UserMixin from "@/utils/mixins/userMixin.js"

// Does the create model empty work? If so, the datasetuploaderreupload todo may be fine
export default {
  name: "ProjectsCreateModal",
  mixins: [UserMixin],
  components: {
    LinkIconOnly,
    Modal,
    ProjectsCreateModalDatasets,
    ProjectsCreateModalEmpty: () => import("./ProjectsCreateModalEmpty"),
    SvgIconDecorative
  },
  props: {
    show: {
      defult: () => false,
      type: Boolean
    },
    preSelected: {
      default: () => null,
      type: String
    },
    refresh: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      project: new BenchmarkingProject({ name: "" }),
      err: {
        title: null,
        datasets: null
      },
      datasets: [],
      isLoading: false
    }
  },
  created() {
    this.refreshDatasetProjects()
  },
  methods: {
    async createProject() {
      this.clearErrs()
      if (!this.validate()) return
      this.$emit("close")
      const newProject = await this.$pigeonline.projects.create(this.project)
      this.$router.push({
        path: `/project/${newProject.id}/analysis/tools`
      })
    },
    async refreshDatasetProjects() {
      this.isLoading = true
      // TODO modern JS axios is converting this into non-JSON format which our BE isn't picking up. We are not using it and sorting on the FE anyway.
      // const data = await this.$pigeonline.projects.get(BenchmarkingDataset, {
      //   sort: {
      //     last_modified_on: "desc"
      //   }
      // })
      const data = await this.$pigeonline.projects.get(BenchmarkingDataset, {})
      if (data.length === 0) return
      // sort by completed
      this.datasets = data.sort((a, b) => {
        if (
          a.currStatusText() === "complete" &&
          b.currStatusText() !== "complete"
        )
          return -1
        if (
          b.currStatusText() === "complete" &&
          a.currStatusText() !== "complete"
        )
          return 1
        return 0
      })
      this.$emit("foundDatasets", this.datasets)
      this.isLoading = false
    },
    clearErrs() {
      this.err.title = null
      this.err.datasets = null
    },
    selectedDataset(datasetId) {
      this.project.addDataset(datasetId)
      this.project.updateStatus("datasetSelected")
    },
    validate() {
      let firstErr = null
      if (this.project.name.length == 0) {
        this.err.title = "Error: project title is required"
        if (!firstErr) firstErr = this.$refs["title"]
      }
      if (!this.project.datasets || this.project.datasets.length === 0) {
        this.err.datasets = "Error: please select at least one dataset"
        if (!firstErr)
          firstErr = document.getElementsByClassName(
            "create-modal__dataset-input"
          )[0]
      }
      if (firstErr) firstErr.focus()
      return !firstErr
    }
  },
  watch: {
    refresh: function (val) {
      if (val) this.loadData()
    }
  }
}
</script>
