<template>
  <Tooltip
    id="global-match-tooltip"
    v-if="!isTooltipClosed && !isDatasetReadOnly"
  >
    <VisibleText>Let us find global matches for you!</VisibleText>
    <button
      class="dataset-benchmarks__get-matches-btn"
      @click="onClickGetMatches"
    >
      <VisibleText>Get suggested matches</VisibleText>
    </button>
  </Tooltip>
</template>

<script>
// Cmponents
import Tooltip from "@/components/UI/Tooltip.vue"

export default {
  name: "DatasetBenchmarksTooltip",
  components: {
    Tooltip
  },
  data() {
    return {
      isTooltipClosed: false
    }
  },
  props: {
    isDatasetReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  mounted() {
    // setup onscroll listener
    if (document.getElementById("dataset-table")) {
      document
        .getElementById("dataset-table")
        .addEventListener("scroll", this.handleScroll)
    }
  },
  unmounted() {
    // destroy on scroll
    if (document.getElementById("dataset-table")) {
      document
        .getElementById("dataset-table")
        .removeEventListener("scroll", this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      if (document.getElementById("global-match-tooltip")) {
        let rect = document
          .getElementById("dataset-table")
          .querySelector(".global-match")
          .getBoundingClientRect()
        let isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

        if (!isVisible) {
          document.getElementById("global-match-tooltip").style.opacity = 0
        } else {
          document.getElementById("global-match-tooltip").style.opacity = 1
        }
      }
    },
    onClickGetMatches() {
      this.$store.dispatch("datasetWizard/setToFetchSuggestedMatches", true)
      this.isTooltipClosed = true
      this.$emit("update-counter")
    }
  }
}
</script>
