<template>
  <DialogPopup
    class="theme__popup-dialog"
    :style="
      avoidLeftOffset
        ? 'position: absolute; width: 40vw; max-width: 40vw; left: 0;'
        : 'position: absolute; width: 40vw; max-width: 40vw'
    "
    header-tag="h4"
    :show="localShowLemmasInfo"
    @close="closeGuide"
  >
    <template #header>
      <div
        style="
          display: flex;
          top: 0;
          flex-direction: row;
          text-align: left;
          font-size: 1rem;
          text-transform: none;
        "
      >
        Learn more about lemmatization and smart coverage
      </div>
    </template>
    <template #body>
      <div>
        <div v-if="lemmasPagination.page == 1">
          <p>
            <VisibleText
              >To make coverage flexible and 'smart', we find additional
              information from each response, called lemmas.
            </VisibleText>
          </p>

          <p>
            <VisibleText
              ><strong>Lemmatization:</strong> Group together inflected forms of
              words (like across tenses, gender, etc). For example, 'feeling' or
              'feelings' or 'felt' all share the lemma: 'feel'</VisibleText
            >
          </p>
          <ul>
            <li>We use a Spacy package called en_core_web_md</li>
            <li>
              Warning: These are trained by humans and are incomplete. Not all
              lemmas that you expect to find will be found.
            </li>
            <li>
              <a href="https://en.wikipedia.org/wiki/Lemmatisation"
                >Learn more</a
              >
            </li>
          </ul>
        </div>
        <div v-if="lemmasPagination.page == 2">
          <p>
            <VisibleText
              >For the 6th word for the sentence "The staff kinda left me
              <strong>feeling</strong> abandoned", we generate the
              following:</VisibleText
            >
          </p>
          <ul>
            <li><strong>.word: "feeling"</strong></li>
            <li><strong>.lemma: "feel"</strong></li>
          </ul>
        </div>
        <div v-if="lemmasPagination.page == 3">
          <p>
            <VisibleText
              >The first word for the sentence "<strong>The</strong> staff kinda
              left me feeling abandoned" has no lemma:</VisibleText
            >
          </p>
          <ul>
            <li><strong>.word: "The"</strong></li>
            <li><strong>.lemma: ""</strong> - This means no lemma was found</li>
          </ul>
        </div>
        <div v-if="lemmasPagination.page == 4">
          <p>
            <VisibleText
              >When you make a search, or enter a new keyword into a theme, the
              "match" can include not only to direct words, but to all lemmas.
              We call this smart coverage.</VisibleText
            >
          </p>
          <VisibleText
            >Imagine you search for <strong>"she has considered"</strong>. It
            will successfully match with:</VisibleText
          >
          <ul>
            <li>"He had considered"</li>
            <li>"They are considering"</li>
          </ul>
        </div>
        <div v-if="lemmasPagination.page == 5">
          <MessageBlock class="banner-msg" message-type="warning">
            <template #default>
              Warning. This exercise can be very taxing and time consuming if
              your text is over 5,000,000 characters long.
            </template>
          </MessageBlock>

          <p>
            <VisibleText
              >If responses are taking too long, consider turning smart coverage
              off by pressing the following button in the saved themes
              view:</VisibleText
            >
          </p>
          <button
            :disabled="true"
            style="
              color: var(--primary);
              background: #fff;
              width: -webkit-max-content;
              width: -moz-max-content;
              width: max-content;
              border: 1px solid var(--outline-colour);
              border-radius: 4px;
              padding: 0.5rem 1rem;
              font-family: Core-Sans;
              font-size: 0.9em;
              font-weight: bold;
              min-height: 3rem;
              padding: 0.5rem 1rem;
              height: 2em;
              margin-left: auto;
            "
          >
            <VisibleText>Including smart coverage</VisibleText>
          </button>
          <p>
            This will "hide" smart coverage. You can turn it back on by pressing
            the same button.
          </p>
        </div>

        <div
          class="theme__popup-pagination"
          v-show="lemmasPagination.totalPages > 1"
        >
          <span class="details">
            {{ translate("Page", $options.name) }}
            {{ lemmasPagination.page }}
            {{ translate("of", $options.name) }}
            {{ lemmasPagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="lemmasPagination.page === 1"
              @click="onClickPrevPageLemmas"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="lemmasPagination.page === lemmasPagination.totalPages"
              @click="onClickNextPageLemmas"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
      </div>
    </template>
  </DialogPopup>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "LemmasMiniGuide",
  components: {
    DialogPopup,
    MessageBlock
  },
  props: {
    showLemmasInfo: {
      type: Boolean,
      default: () => false
    },
    avoidLeftOffset: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      localShowLemmasInfo: false,
      lemmasPagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 5,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  methods: {
    onClickNextPageLemmas() {
      if (this.lemmasPagination.page === this.lemmasPagination.totalPages)
        return
      this.lemmasPagination.page = this.lemmasPagination.page + 1
      this.setPaginationDetailsLemmas(
        this.lemmasPagination.startIndx + this.lemmasPagination.limit,
        this.lemmasPagination.endIndx + this.lemmasPagination.limit
      )
    },
    onClickPrevPageLemmas() {
      if (this.lemmasPagination.page === 1) return
      this.lemmasPagination.page = this.lemmasPagination.page - 1
      this.setPaginationDetailsLemmas(
        this.lemmasPagination.startIndx - this.lemmasPagination.limit,
        this.lemmasPagination.endIndx - this.lemmasPagination.limit
      )
    },
    setPaginationDetailsLemmas(start, end) {
      this.lemmasPagination.startIndx = start
      this.lemmasPagination.endIndx = end
    },
    closeGuide() {
      this.$emit("closingGuide")
      this.localShowLemmasInfo = false
    }
  },
  created() {
    if (this.showLemmasInfo) {
      this.localShowLemmasInfo = true
    }
  },
  watch: {
    showLemmasInfo(newVal) {
      if (newVal) {
        this.localShowLemmasInfo = true
      } else {
        this.localShowLemmasInfo = false
      }
    }
  }
}
</script>
