<template>
  <SvgIcon :aria-hidden="true" :focusable="false" :icon="icon">
    <title v-if="title.length > 0">{{ title }}</title>
  </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "SvgIconDecorative",
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      default: () => "",
      type: String,
      required: false
    }
  }
}
</script>
