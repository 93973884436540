<template>
  <div
    class="project-analysis-item__stacked-wrapper"
    style="margin-bottom: 1rem"
  >
    <div class="project-analysis-item__label-container">
      <div
        v-if="!savedNodes.length"
        class="project-analysis-item__header-wrapper-label"
      >
        <button
          @click="save"
          class="project-analysis-item__button"
          :disabled="
            statusDisableActionBtns ||
            (isAddingNewSlide && !newSlideName) ||
            (!isAddingNewSlide && !selectedSlide) ||
            propReadOnly
          "
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="report"
          />
          <VisibleText>Save To Report</VisibleText>
        </button>
      </div>
      <div
        v-else-if="savedNodes.length && !contentChanged"
        class="project-analysis-item__header-wrapper-label"
      >
        <button class="project-analysis-item__header-wrapper-btn" @click="save">
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="report"
          />
          <VisibleText>Saved to:</VisibleText>
        </button>
        <div style="width: 100%; font-size: 0.8rem">
          {{ savedSlide.name }}
        </div>
      </div>
      <div v-else-if="savedNodes.length && contentChanged">
        <button class="project-analysis-item__header-wrapper-btn" @click="save">
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="report"
          />
          <VisibleText>Save changes to</VisibleText>
        </button>
      </div>
      <label v-if="!savedNodes.length && !newSlideName" style="width: 100%">
        <span
          style="
            font-size: 0.8rem;
            text-align: center;
            width: 100%;
            display: block;
            margin: auto;
          "
          ><VisibleText>Please name this slide</VisibleText></span
        >
      </label>
    </div>

    <div class="project-analysis-item__stacked-wrapper__bottom">
      <div v-if="!savedNodes.length">
        <div
          v-if="!isAddingNewSlide && projectsAsSlides.length"
          class="project-analysis-item__horizontal"
          style="padding: 0"
        >
          <div class="select-wrapper">
            <select
              class="select"
              id="saved-slide-filter"
              v-model="selectedSlide"
              style="font-size: 0.8rem; padding: 0"
            >
              <option
                v-for="(slide, index) in projectsAsSlides"
                :value="slide"
                :key="index"
                style="width: 100%; font-size: 0.8rem; padding: 0.25rem 0 0 0"
              >
                {{ slide.name }}
              </option>
            </select>
          </div>
          <button
            @click="AddNewSlide"
            class="project-analysis-item__horizontal__item"
            style="
              background-color: transparent;
              border: 1px solid var(--outline-colour);
              margin-left: 0.25rem;
              border-radius: 4px;
              padding: 0.4rem;
              font-size: 0.8rem;
              color: black;
            "
            :disabled="propReadOnly"
          >
            +
          </button>
        </div>
        <div
          v-else
          class="project-analysis-item__horizontal"
          style="padding: 0"
        >
          <div class="project-analysis-item__input">
            <div style="display: flex">
              <input
                style="
                  width: 100%;
                  font-size: 0.8rem;
                  margin-top: 0;
                  color: var(--base-colour);
                "
                class="input project-analysis-item__input-input"
                name="slide-name"
                type="text"
                :placeholder="visiblePlaceholder"
                :id="uuid"
                v-model="newSlideName"
              />

              <button
                v-if="projectsAsSlides.length"
                @click="AddNewSlide"
                class="project-analysis-item__horizontal__item"
                style="
                  background-color: transparent;
                  border: 1px solid var(--outline-colour);
                  margin-left: 0.25rem;
                  border-radius: 4px;
                  padding: 0.4rem;
                  font-size: 0.8rem;
                "
              >
                x
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import { PigeonDocModel, PigeonDocCollectionModel } from "@pigeonline/pigeondoc"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

export default {
  name: "SaveAnalysis",
  mixins: [ProjectMixin, ProjectReportMixin],
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    statusDisableActionBtns: {
      default: () => null,
      type: Boolean
    },
    index: {
      type: Number,
      required: true
    },
    isDriversAnalysis: {
      default: () => null,
      type: Boolean
    },
    isBenchmarkingAnalysis: {
      default: () => null,
      type: Boolean
    },
    isTextAnalysis: {
      default: () => null,
      type: Boolean
    },
    isOutliersAnalysis: {
      default: () => null,
      type: Boolean
    },
    savedNodes: {
      default: () => [],
      type: Array
    },
    contentChanged: {
      default: () => false,
      type: Boolean
    }
  },
  components: {
    SvgIconDecorative
  },
  data() {
    return {
      newSlideName: "",
      selectedSlide: "",
      savedSlide: "",
      theseProjects: [],
      isAddingNewSlide: false,
      uuid: this.$pigeonline.createUUID(),
      thisCollection: null
    }
  },
  mounted() {
    if (!this.project.collection_id) {
      this.isAddingNewSlide = true
    }
    if (this.projectsAsSlides.length) {
      this.isAddingNewSlide = true
    }
    this.updateCollections()
  },
  computed: {
    reportSaves() {
      let saves = 0
      if (this.project.reportSaves) {
        saves = this.project.reportSaves
      }
      return saves
    },
    projectsAsSlides() {
      return this.theseProjects
    },
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "Slide name",
        "slide-name",
        this.$store.getters.getLanguage
      )
    }
  },
  methods: {
    async updateCollections() {
      // This method checks if a collection id exists (such as after a new save)
      // This loads a local 'theseProjects' to be used by ProjectAsSlide array
      // and savedSlide used by the 'save to slide' feature.
      let collection
      if (this.project.collection_id) {
        collection = await this.$pigeonline.projects.getByID(
          PigeonDocCollectionModel,
          this.project.collection_id
        )
        if (collection) {
          this.thisCollection = collection
          if (collection.docIds) {
            let project
            for (let i = 0; i < collection.docIds.length; i++) {
              project = await this.$pigeonline.projects.getByID(
                PigeonDocModel,
                collection.docIds[i]
              )
              if (project) {
                let found = false
                for (let x = 0; x < this.theseProjects.length; x++) {
                  if (this.theseProjects[x].id === project.id) {
                    this.theseProjects[x] = project
                    found = true
                  }
                }
                if (!found) {
                  this.theseProjects.push(project)
                }
              }
            }
            // check if one savednodes uuid is found in theseprojects, if so get
            if (this.savedNodes.length) {
              let node = this.savedNodes[0].uuid
              for (let x = 0; x < this.theseProjects.length; x++) {
                for (let y = 0; y < this.theseProjects[x].nodes.length; y++) {
                  if (this.theseProjects[x].nodes[y].uuid == node) {
                    this.savedSlide = this.theseProjects[x] // is this too redundant?
                    this.$emit("saved-slide", this.savedSlide)
                  }
                }
              }
            }
            this.selectedSlide = this.theseProjects[0]
          }
        }
      }
    },
    async save() {
      if (this.propReadOnly) return // still needed?
      if (this.isAddingNewSlide) {
        this.$emit("save-to-report", null, this.newSlideName)
      } else {
        this.$emit("save-to-report", this.selectedSlide, this.newSlideName)
      }
      this.isAddingNewSlide = false
    },
    AddNewSlide() {
      this.isAddingNewSlide = !this.isAddingNewSlide
    }
  },
  watch: {
    savedNodes: async function (val) {
      if (val.length) {
        this.updateCollections()
      }
    },
    reportSaves: async function (val, oldVal) {
      if (val > oldVal) {
        this.updateCollections()
      }
    }
  }
}
</script>
