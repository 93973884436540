<template>
  <div id="project-analysis-text-theme">
    <section class="theme__container" style="position: static">
      <h3 class="theme__title"><VisibleText>Saved outliers</VisibleText></h3>
      <MessageBlock
        style="display: inline-block"
        message-type="information"
        title="No outliers saved"
        v-if="!outliersAnalysisObject.outliers.length"
      >
        Use the below filters to look for interesting outliers.
      </MessageBlock>

      <div class="theme__list">
        <ul
          v-if="outliersAnalysisObject.outliers.length"
          style="width: 90%; list-style-type: none"
        >
          <li
            v-for="(outlier, index) in outliersAnalysisObject.outliers"
            :key="`top-outlier-${index}`"
            class="response-list-item"
            style="
              padding-left: 2em;
              padding-right: 2em;
              padding-bottom: 3em;
              margin-bottom: 2em;
              background-color: white;
              border: 1px solid var(--outline-colour);
            "
          >
            <div v-if="outlier.viewType === 'unit'">
              <div style="display: flex; flex-direction: row">
                <div
                  class="response-list-item__header-title"
                  style="width: 75%; display: flex"
                >
                  <VisibleText>Question:&nbsp;</VisibleText>
                  <span>{{
                    outlier.responses.questionDetails.question_text
                  }}</span>
                </div>
                <div style="display: flex; width: 25%">
                  <VisibleText>
                    {{ zscoreLabel(outlier.responses.outlierObject.z_score) }}
                  </VisibleText>
                </div>
              </div>
              <div>
                <VisibleText>Those who answered:</VisibleText>
                <div
                  style="
                    background-color: var(--theme-keyword-light);
                    display: inline;
                  "
                >
                  {{ outlier.responses.outlierObject.cat_value }}
                  <VisibleText>to the question:</VisibleText>
                  {{ outlier.responses.categoryTitle }}
                </div>
              </div>
              <div
                style="
                  background-color: white;
                  border: 1px solid var(--outline-colour);
                  padding: 0.5em;
                "
              >
                <div>
                  <VisibleText>Average:</VisibleText>
                  <span
                    style="font-weight: 800"
                    v-bind:class="{
                      isNegativeOutlier:
                        outlier.responses.outlierObject.mean <
                        outlier.responses.questionDetails.overall_mean,
                      isPositiveOutlier:
                        outlier.responses.outlierObject.mean >
                        outlier.responses.questionDetails.overall_mean
                    }"
                  >
                    {{ outlier.responses.outlierObject.mean }}
                  </span>
                  <VisibleText> compared to </VisibleText>
                  {{ outlier.responses.questionDetails.overall_mean }}
                </div>
                <div>
                  <VisibleText>Responses in this group:</VisibleText>
                  {{ outlier.responses.outlierObject.sample_size }}
                </div>
              </div>
              <div
                class="response-list-item__options sr-show-on-focus"
                tabindex="0"
                :aria-label="`Delete this outlier`"
              >
                <button
                  type="button"
                  class="response-list-item__options-btn"
                  style="
                    display: flex;
                    flex-direction: row;
                    background-color: transparent;
                    color: var(--primary);
                  "
                  @click="$emit('delete-outlier', index)"
                  :disabled="propReadOnly"
                >
                  <SvgIconDecorative icon="minus" />
                  <div style="display: flex; flex-direction: column">
                    <VisibleText>Delete this outlier</VisibleText>
                  </div>
                </button>
              </div>
            </div>
            <div v-if="outlier.viewType === 'question'">
              <div style="display: flex; flex-direction: row">
                <div
                  class="response-list-item__header-title"
                  style="width: 100%; display: flex"
                >
                  <VisibleText>Question:&nbsp;</VisibleText>
                  <span>{{
                    outlier.responses.questionDetails.question_text
                  }}</span>
                </div>
              </div>
              <div>
                <VisibleText>Total number of outliers:</VisibleText>
                {{ outlier.responses.questionsOutliersTotalSample }}
              </div>
              <div>
                <VisibleText>Question average:</VisibleText>
                {{ roundToTwo(outlier.responses.questionDetails.overall_mean) }}
              </div>
              <div>
                <VisibleText>Outliers average: </VisibleText>
                {{ roundToTwo(outlier.responses.questionOutliersAverage) }}
                <VisibleText>(a difference of: </VisibleText>
                {{
                  roundToTwo(outlier.responses.questionOutliersMeanDifference)
                }}
                )
              </div>
              <ul
                v-for="(unit, index) in outlier.responses.questionOutliers"
                :key="`top-outlier-unit-${index}`"
                style="
                  background-color: white;
                  border: 1px solid var(--outline-colour);
                  margin: 0.5em;
                  padding: 0.5em;
                  list-style-type: none;
                "
              >
                <li>
                  <div style="display: flex; flex-direction: row">
                    <div
                      style="display: flex; width: 75%; flex-direction: column"
                    >
                      <div>
                        <VisibleText>Those who answered:</VisibleText>
                        <div
                          style="
                            background-color: var(--theme-keyword-light);
                            display: inline;
                          "
                        >
                          {{ unit.outlierObject.cat_value }}
                          <VisibleText>to the question:</VisibleText>
                          {{ unit.categoryTitle }}
                        </div>
                      </div>
                      <div>
                        <VisibleText>Average:</VisibleText>
                        <span
                          style="font-weight: 800"
                          v-bind:class="{
                            isNegativeOutlier:
                              unit.outlierObject.mean <
                              outlier.responses.questionDetails.overall_mean,
                            isPositiveOutlier:
                              unit.outlierObject.mean >
                              outlier.responses.questionDetails.overall_mean
                          }"
                        >
                          {{ unit.outlierObject.mean }}
                        </span>
                        <VisibleText>(a difference of </VisibleText>
                        {{ roundToTwo(unit.outlierMeanDifference) }}
                        )
                      </div>
                      <div>
                        <VisibleText>Responses in this group:</VisibleText>
                        {{ unit.outlierObject.sample_size }}
                      </div>
                    </div>
                    <div style="display: flex; width: 25%">
                      <VisibleText>
                        {{ zscoreLabel(unit.outlierObject.z_score) }}
                      </VisibleText>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                class="response-list-item__options sr-show-on-focus"
                tabindex="0"
                :aria-label="`Delete this outlier`"
              >
                <button
                  type="button"
                  class="response-list-item__options-btn"
                  style="
                    display: flex;
                    flex-direction: row;
                    background-color: transparent;
                    color: var(--primary);
                  "
                  @click="$emit('delete-outlier', index)"
                  :disabled="propReadOnly"
                >
                  <SvgIconDecorative icon="minus" />
                  <div style="display: flex; flex-direction: column">
                    <VisibleText>Delete this outlier</VisibleText>
                  </div>
                </button>
              </div>
            </div>
            <div v-if="outlier.viewType === 'segment'">
              <div style="display: flex; flex-direction: row">
                <div
                  class="response-list-item__header-title"
                  style="width: 100%; display: flex"
                >
                  <VisibleText> Segment:&nbsp; </VisibleText>
                  <VisibleText> Those who answered </VisibleText> &nbsp;
                  <div
                    style="
                      background-color: var(--theme-keyword-light);
                      display: inline;
                    "
                  >
                    {{ outlier.responses.respondentValue }}
                    <VisibleText>to the question </VisibleText>
                    {{ outlier.responses.respondentQuestion }}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <VisibleText>Total number of outliers:</VisibleText>
                  {{ outlier.responses.segmentOutliersTotalSample }}
                </div>
                <div>
                  <VisibleText>Average difference from mean:</VisibleText>
                  {{
                    roundToTwo(outlier.responses.segmentOutliersMeanDifference)
                  }}
                </div>
                <ul
                  v-for="(unit, index) in outlier.responses.segmentUnits"
                  :key="`top-outlier-unit-${index}`"
                  style="
                    background-color: white;
                    border: 1px solid var(--outline-colour);
                    margin: 0.5em;
                    padding: 0.5em;
                    list-style-type: none;
                  "
                >
                  <li>
                    <div style="display: flex; flex-direction: row">
                      <div
                        style="
                          display: flex;
                          width: 75%;
                          flex-direction: column;
                        "
                      >
                        <div>
                          <VisibleText>To the question: </VisibleText>
                          {{ unit.questionTitle }}
                        </div>
                        <div>
                          <VisibleText>Average: </VisibleText>
                          <span
                            style="font-weight: 800"
                            v-bind:class="{
                              isNegativeOutlier:
                                unit.outlierObject.mean < unit.questionMean,
                              isPositiveOutlier:
                                unit.outlierObject.mean > unit.questionMean
                            }"
                          >
                            {{ unit.outlierObject.mean }}
                          </span>
                          <VisibleText>compared to </VisibleText>
                          {{ unit.questionMean }}
                          <VisibleText>(a difference of </VisibleText>
                          {{ roundToTwo(unit.outlierMeanDifference) }}
                          )
                        </div>
                        <div>
                          <VisibleText>Sample size: </VisibleText>
                          {{ unit.outlierObject.sample_size }}
                        </div>
                      </div>
                      <div style="display: flex; width: 25%">
                        <VisibleText>
                          {{ zscoreLabel(unit.outlierObject.z_score) }}
                        </VisibleText>
                      </div>
                    </div>
                  </li>
                  <div
                    class="response-list-item__options sr-show-on-focus"
                    tabindex="0"
                    :aria-label="`Delete this outlier`"
                  >
                    <button
                      type="button"
                      class="response-list-item__options-btn"
                      style="
                        display: flex;
                        flex-direction: row;
                        background-color: transparent;
                        color: var(--primary);
                      "
                      @click="$emit('delete-outlier', index)"
                      :disabled="propReadOnly"
                    >
                      <SvgIconDecorative icon="minus" />
                      <div style="display: flex; flex-direction: column">
                        <VisibleText>Delete this outlier</VisibleText>
                      </div>
                    </button>
                  </div>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "SavedOutliers",
  components: {
    MessageBlock,
    SvgIconDecorative
  },
  props: {
    outliersAnalysisObject: {
      type: Object,
      default: () => {}
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    zscoreLabel(zscore) {
      if (!zscore) return
      if (zscore <= -5) {
        return "Extreme negative outlier"
      } else if (zscore > -5 && zscore <= -4) {
        return "Strong negative outlier"
      } else if (zscore > -4 && zscore <= -3) {
        return "Negative outlier"
      } else if (zscore > -3 && zscore <= -2) {
        return "Somewhat negative outlier"
      } else if (zscore > -2 && zscore <= 2) {
        return "Not an outlier"
      } else if (zscore > 2 && zscore <= 3) {
        return "Somewhat positive outlier"
      } else if (zscore > 3 && zscore <= 4) {
        return "Positive outlier"
      } else if (zscore > 4 && zscore < 5) {
        return "Strong positive outlier"
      } else if (zscore >= 5) {
        return "Extreme positive outlier"
      }
    },
    roundToTwo(num) {
      return Math.round(num * 100) / 100
    }
  }
}
</script>
