<template>
  <div>
    <div
      v-for="(input, index) in questionWording"
      :key="`wording-${index}`"
      class="benchmark-manager__wording"
    >
      <input
        aria-label="question wording"
        class="form-input--margin--none input"
        type="text"
        :aria-describedby="ariaDescribedBy"
        :aria-invalid="err"
        :required="required"
        :ref="`input-${index}`"
        @input="onInputWording(input.lang, $event)"
      />
      <div class="select-wrapper">
        <select aria-label="language" class="select" v-model="input.lang">
          <option disabled value="" :data-parent="$options.name">
            {{ translate("language", $options.name) }}
          </option>
          <option
            v-for="lang in Object.keys(LANG_NAME)"
            :key="lang"
            :value="lang"
            :data-parent="$options.name"
            :disabled="question[LANG_CODE_TO_FIELD[lang]]"
          >
            {{ translate(LANG_NAME[lang], $options.name) }}
          </option>
        </select>
      </div>
      <ButtonIconOnly
        icon="remove"
        :id="`wording-remove-${index}`"
        @click-handler="removeWording(index)"
      >
        remove
      </ButtonIconOnly>
      <ButtonIconOnly
        icon="check"
        :id="`wording-approve-${index}`"
        @click-handler="approveWording(index)"
      >
        approve
      </ButtonIconOnly>
    </div>
    <button
      v-if="!disableAddBtn"
      class="btn-secondary"
      style="padding-left: 0"
      @click="addWording"
    >
      <slot name="btn-text">
        <SvgIconDecorative icon="add" title="add language" />
        <VisibleText> Add language </VisibleText>
      </slot>
    </button>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import _ from "lodash"
import { LANG_NAME } from "../../Utils/consts.js"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "QuestionWording",
  components: {
    ButtonIconOnly,
    SvgIconDecorative
  },
  props: {
    ariaDescribedBy: {
      type: String
    },
    err: {
      default: "false",
      type: String
    },
    // initialize questionWording array
    initArray: {
      default: true,
      type: Boolean
    },
    question: {
      type: Object,
      required: true
    },
    required: {
      default: false,
      type: Boolean
    },
    hasOtherFrench: {
      default: false,
      type: Boolean
    },
    hasOtherEnglish: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      LANG_NAME: LANG_NAME,
      LANG_CODE_TO_FIELD: {
        en: "question_text",
        fr: "question_text_fr"
      },
      initValues: {},
      questionWording: [],
      localQuestion: {},
      closeEdit: false
    }
  },
  created() {
    this.localQuestion = this.question
  },
  computed: {
    disableAddBtn() {
      let isDisabled = false
      if (
        this.questionWording.length === 2 ||
        (this.localQuestion.question_text &&
          this.localQuestion.question_text_fr) ||
        (this.localQuestion.question_text && this.questionWording.length > 0) ||
        (this.localQuestion.question_text_fr &&
          this.questionWording.length > 0) ||
        (this.hasOtherEnglish && this.questionWording.length > 0) ||
        (this.hasOtherFrench && this.questionWording.length > 0) ||
        (this.hasOtherFrench && this.hasOtherEnglish)
      ) {
        isDisabled = true
      }
      return isDisabled
    }
  },
  methods: {
    addWording() {
      if (!this.questionWording.length) {
        if (this.localQuestion.question_text) {
          this.questionWording.push({ lang: "fr" })
        } else if (this.localQuestion.question_text_fr) {
          this.questionWording.push({ lang: "en" })
        } else {
          this.questionWording.push({ lang: "en" })
        }
      } else if (
        this.questionWording.filter((word) => word.lang == "en").length
      ) {
        this.questionWording.push({ lang: "fr" })
      } else if (
        this.questionWording.filter((word) => word.lang == "fr").length
      ) {
        this.questionWording.push({ lang: "en" })
      }
    },
    removeWording(index) {
      this.localQuestion[
        this.LANG_CODE_TO_FIELD[this.questionWording[index].lang]
      ] = null
      this.questionWording.splice(index, 1)
    },
    approveWording(index) {
      this.questionWording.splice(index, 1)
      this.$emit("updateWordingButNoBackend")
    },
    onInputWording(lang, $e) {
      if (!lang) return
      this.localQuestion[this.LANG_CODE_TO_FIELD[lang]] = $e.target.value
      this.$emit("update-question")
    }
  },
  watch: {
    initArray: {
      immediate: true,
      handler: function (init) {
        if (init) this.addWording()
      }
    }
  }
}
</script>
