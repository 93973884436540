<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText
        >Emerging best practices in UX and CX research: A case study of
        ResearchAI-fuelled insights and service improvements using web data
      </VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>2024-06-14</VisibleText>
    </p>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%"
      src="/assets/landing/ux-cx-fire-use-case.jpeg"
      alt=""
    />

    <p>
      <VisibleText>
        How do analysts successfully negotiate with AI tools such as ResearchAI
        to deliver insights and impact organizational results? What are we
        learning about emerging best practices of thematic analysis? What do
        specific case studies tell us about the clear limitations of GenAI? With
        these constraints in mind, what may be circumstances where the use of
        GenAI may be useful and appropriate? This article is an attempt to
        discuss best practices emerging from our work with clients of
        ResearchAI, and in particular, follows a use case involving citizen
        perspectives about natural disasters and related services from web data.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        <span style="font-weight: 800">The Challenge for Researchers: </span
        >Researchers today encounter a series of obstacles that limit their
        ability to deliver impactful insights swiftly and at scale:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText> Limited time amidst a demanding schedule. </VisibleText>
      </li>
      <li>
        <VisibleText>
          The inability to efficiently manage large datasets for analysis.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          The need for insights that carry weight and authority.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          A reluctance or inability to engage with complex coding for data
          analysis.
        </VisibleText>
      </li>
    </ul>

    <h2 class="library__h2">
      <VisibleText>
        The case: A government agency making use of web data that was otherwise
        unused
      </VisibleText>
    </h2>

    <p>
      <VisibleText>
        In the case of a small Canadian government agency charged with
        evaluating and improving websites and online services, a single analyst
        was faced with all of these challenges. While text data captured from
        online forms existed there was seldom time or energy to manually code,
        categorize, and analyze what users had to say or how they felt. In the
        wake of a natural emergency affecting thousands of residents, the
        analyst was tasked with reviewing thousands of comments to identify
        service improvement opportunities. Not surprisingly this effort took the
        analyst over 150 hours of manual effort employing only brute force and
        Excel spreadsheets. In the following year, this effort was outsourced to
        a contractor who produced a similar basic analysis in six weeks at a
        cost of over $10,000.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Dissatisfied with these two options this organization turned to
        ResearchAI to save time, effort, and cost. More importantly, the
        researcher recognized their need for a partner to help her develop AI
        Insights as a competency.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>
        Using ResearchAI to automate topic, thematic, and sentiment analysis.
      </VisibleText>
    </h3>
    <p>
      <VisibleText>
        In evaluating AI tools and techniques to simplify the production of
        actionable insights the analyst told us they were looking to meet four
        criteria:
      </VisibleText>
    </p>

    <ol class="library__list">
      <li>
        <VisibleText> A tool that is intuitive and easy to use </VisibleText>
      </li>
      <li>
        <VisibleText>
          A tool that leaves the analyst in charge and accountable
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          A tool that produces results that are authoritative and defensible
          (i.e. replicable and explainable)
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          A tool supported by a team of subject matter experts who can help
          build organizational competency and deliver ongoing value.
        </VisibleText>
      </li>
    </ol>
    <h3>
      <VisibleText>
        AI and the practice of Topic, Thematic, and Sentiment Analysis
      </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Faced with life-threatening natural emergencies residents demand online
        services that are easy to find, easy to understand, and easy to use. In
        light of the political sensitivity of this issue, our client recognized
        it is critically important to authoritatively identify not only what
        needs to be fixed but also whether changes made as a result have worked.
        In some critical circumstances, this assessment may need to be made in
        real-time. After engaging ResearchAI, our client outlined requirements
        for analysis and reporting as follows:
      </VisibleText>
    </p>

    <ul class="library__list">
      <li>
        <VisibleText>
          Identification and calculation (frequency and percentage) of key
          themes of service/user-experience issues residents cited in their
          comments.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Measurement and description of positive, neutral, and negative
          sentiment.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Assessment of comments through the lens of established
          industry-leading explanatory frameworks and models (CSAT/ UX).
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Reporting that compared results over time (2018-2021) so that the
          impact of interventions could be assessed by subject matter experts.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          No more than 20 hours to initially assess 5 years of data and develop
          reporting and no more than 2 hours a month of effort to maintain and
          run reports.
        </VisibleText>
      </li>
    </ul>

    <h2 class="library__h2">
      <VisibleText> Our approach to AI and Thematic analysis </VisibleText>
    </h2>
    <h3>
      <VisibleText>
        Build a working hypothesis or model to understand feedback and guide
        improvements.
      </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Research analysts seek to describe and understand the world around them.
        In some cases, they use existing theories and models to deduce or fit
        insights from their data. In other cases they let the data speak and
        induce or evolve a theory. Often analysts are required to use their
        subject matter expertise and a combination of both techniques to evolve
        a theory or model to explain the topic in question.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In the case of quality and effectiveness of critical online information
        and services we worked together to build and use proven industry models
        and a custom model developed with input of SME’s and stakeholders.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The custom model includes three themes (ease of navigation, timeliness
        of information, and information quality) from an industry-leading public
        sector CSAT instrument (the Common Measurements Tool) and two themes
        specific to the natural emergency context (online maps and
        functionality).
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        A critical advantage of ResearchAI is that these models are reusable
        with subsequent analysis fully automated. This feature enables the
        analyst to confidently run new data sets in minutes.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        A critical advantage of ResearchAI is that these models are reusable
        with subsequent analysis fully automated. This feature enables the
        analyst to confidently run new data sets in minutes.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>
        How did we build and validate the model and its insights?
      </VisibleText>
    </h3>
    <p>
      <VisibleText>
        As research analysts know producing credible, impactful, insights is an
        iterative and collaborative process. ResearchAI is designed to assist
        and empower researchers, using automation and AI-generated suggestions
        to supercharge the work of the research team.
      </VisibleText>
    </p>
    <h3>
      <VisibleText> Here is how we used ResearchAI </VisibleText>
    </h3>

    <ul class="library__list">
      <li>
        <VisibleText> Cleaning of data sets </VisibleText>
      </li>
      <li>
        <VisibleText> Building an initial set of themes </VisibleText>
      </li>
      <li>
        <VisibleText> Reviewing results and coding of results </VisibleText>
      </li>
      <li>
        <VisibleText> Writing initial reports </VisibleText>
      </li>
      <li>
        <VisibleText> Collaborating with partners </VisibleText>
      </li>
      <li>
        <VisibleText> Validating Model </VisibleText>
      </li>
      <li>
        <VisibleText> Writing polished reports </VisibleText>
      </li>
      <li>
        <VisibleText> Presenting reports to decision-makers </VisibleText>
      </li>
    </ul>

    <table>
      <tr>
        <th>Step</th>
        <th>Description</th>
        <th>Effort (Hrs)</th>
      </tr>
      <tr>
        <td>Clean data</td>
        <td>
          Upload data and run the data-cleaning wizard for first data set (2018)
        </td>
        <td>.25</td>
      </tr>
      <tr>
        <td>Build an initial set of themes</td>
        <td>
          This was a multi-step process
          <ul class="library__list">
            <li>
              <VisibleText>
                Scanned comments to get a sense of range of topics and themes (1
                hr)
              </VisibleText>
            </li>
            <li>
              <VisibleText>
                Utilized “Generate themes” feature to determine what existing
                models may be useful. (.25 hr)
              </VisibleText>
            </li>
            <li>
              <VisibleText>
                Reviewed comments to determine what additional themes may be
                needed. (1 hr)
              </VisibleText>
            </li>
          </ul>
        </td>
        <td>2.25</td>
      </tr>
      <tr>
        <td>Review results and verify coding</td>
        <td>
          <p>
            For each theme, ResearchAI automatically counts matching keywords
            and phrases contained in the dataset. To be accurate these need to
            be reviewed and confirmed by the analyst as the context, and meaning
            implied by the respondent may not be accurate.
          </p>
          <p>
            This initial verification process is the most time-consuming but
            adds to the validity and reliability of results and insights.
          </p>
          <p>
            As the model/codebook is built, refined, and made more accurate the
            time required to verify subsequent data sets is reduced.
          </p>
        </td>
        <td>3</td>
      </tr>
      <tr>
        <td>Reporting</td>
        <td>
          <p>
            An initial automated report was generated by ResearchAI using the
            themes saved. This report was then shared and discussed with client.
          </p>
        </td>
        <td>0.5</td>
      </tr>
      <tr>
        <td>Collaboration with Clients and Stakeholders</td>
        <td>
          <p>
            Upon review, the client confirmed the themes made sense and would
            provide valuable insights.
          </p>
          <p>
            However it was determined that a custom report would be required to
            be developed for presentation to decision-makers.
          </p>
        </td>
        <td>1</td>
      </tr>
      <tr>
        <td>Development of Custom Reporting Template</td>
        <td>
          <p>
            Worked with client to develop a template and produce draft report
            that included
          </p>
          <ul class="library__list">
            <li>
              <VisibleText>
                Time series of results (Themes, Topics, Sentiments)
              </VisibleText>
            </li>
            <li>
              <VisibleText> Key changes by year </VisibleText>
            </li>
            <li>
              <VisibleText>
                Table listing Negative, Neutral and Positive sentiments
              </VisibleText>
            </li>
          </ul>
        </td>
        <td>2</td>
      </tr>

      <tr>
        <td>Completed Thematic Analysis of 2019 Data Set</td>
        <td>
          <p>Repeated key steps</p>
          <ul class="library__list">
            <li>
              <VisibleText> Uploaded and cleaned data </VisibleText>
            </li>
            <li>
              <VisibleText> Scanned results </VisibleText>
            </li>
            <li>
              <VisibleText>
                Utilized auto generate themes – custom model
              </VisibleText>
            </li>
            <li>
              <VisibleText> Reviewed results and verified coding </VisibleText>
            </li>
            <li>
              <VisibleText> Produced custom report using template </VisibleText>
            </li>
            <li>
              <VisibleText> - Consulted with Client </VisibleText>
            </li>
          </ul>
        </td>
        <td>3</td>
      </tr>
      <tr>
        <td>Completed Thematic Analysis of 2020-21 Data Sets</td>
        <td>
          <p>Repeated key steps</p>
          <ul class="library__list">
            <li>
              <VisibleText> Uploaded and cleaned data </VisibleText>
            </li>
            <li>
              <VisibleText> Scanned results </VisibleText>
            </li>
            <li>
              <VisibleText>
                Utilized auto generate themes – custom model
              </VisibleText>
            </li>
            <li>
              <VisibleText> Reviewed results and verified coding </VisibleText>
            </li>
            <li>
              <VisibleText> Produced custom report using template </VisibleText>
            </li>
            <li>
              <VisibleText> - Consulted with Client </VisibleText>
            </li>
          </ul>
        </td>
        <td>3</td>
      </tr>
      <tr>
        <td>Validated Results with stakeholders</td>
        <td>
          <p>Met and presented findings to stakeholders and SME</p>
        </td>
        <td>1</td>
      </tr>
      <tr>
        <td>Co-presented Custom Reports and Results to Decision Makers</td>
        <td>
          <p>Co-presented Custom Reports and Results to Decision Makers</p>
        </td>
        <td>1</td>
      </tr>
    </table>

    <h3>
      <VisibleText> Results to date </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Working closely with our client over the last several months we have
        succeeded in meeting and exceeding our client’s original requirements:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          Developing and producing a report summarizing themes, identifying
          service improvement priorities, and tracking performance over five
          years and 7000 comments.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Building, testing and refining a custom explanatory model that
          identifies validated actionable drivers of user experience and
          satisfaction for natural emergency information and online services.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Providing the client with capability and capacity to run analysis and
          reports on regular or ad hoc schedule within the 2 hours per month
          requirement.
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2">
      <VisibleText> So why not just use Chat GPT? </VisibleText>
    </h2>
    <p>
      <VisibleText>
        One of the questions we were asked by the client and various
        stakeholders is why not just use ChatGPT? Wouldn’t it be easier, faster
        and cheaper? Well. the short answer is no you can’t because generative
        AI does not produce results that are authoritative (deterministic) they
        produce results that are probabilistic (i.e. they may or not be
        accurate). This is the rub – you can't in any meaningful way rely on the
        results-they must be verified and adjusted. For researchers, analysts
        and decision makers validity and reliability are the essential
        ingredients of their credibility and the building of trust.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In this case, one of the requirements is the ability to accurately and
        measure performance year over year. Were issues being addressed, did
        users' needs and expectations change over the five years in question?
        This implies replicability; that the data could measured consistently
        year over year. This is not possible using GenAI tools such as chat GPT,
        MS CoPilot or Google. Each attempt to count and calculate content and
        themes generated a different answer.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Is there a role for GenAI? – Social researchers, experts in AI, and
        commentators have noted that there are roles GenAI can play in the
        practice of thematic analysis. One of these is ideation or brainstorming
        concepts, themes, and topics. This brainstorming it is argued can be a
        helpful aid to the researcher or research team. In this case, the
        ResearchAI team and client used ChatGPT to develop and refine a list of
        information that residents typically prioritize in times of natural
        emergencies. A further list of information on the most important online
        services was developed using results from ChatGPT. In both cases, the
        lists were reviewed assessed, and validated by the client and subject
        matter experts. The core benefit was that this process was simpler,
        faster, and more inclusive than traditional in-person or online
        brainstorming exercises.
      </VisibleText>
    </p>
    <h2>
      <VisibleText> Next Steps </VisibleText>
    </h2>
    <p>
      <VisibleText>
        Based on the positive experience focusing on identifying service and
        user improvement opportunities in the context of natural emergencies our
        client is actively expanding their application of ResearchAI to other
        use cases. This includes the measurement and evaluation of citizen
        satisfaction with all information contained on the jurisdiction website
        and specific online services offered by various government departments
        and agencies. Having quickly mastered the effective use of the tool the
        analyst is finding they can build, test, and deploy a valid, insightful,
        and actionable measurement and reporting process in less than 15 hours.
        With this in place, the analyst can engage and influence business owners
        and stakeholders to understand, accept, and act on findings. The
        medium-term goal of the analyst is to encourage stakeholders to go
        further and begin to experiment with service improvements as the impact
        of these changes can now be quickly and easily measured in near
        real-time.
      </VisibleText>
    </p>

    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image library__image--nonresponsive"
          style="max-width: 4rem"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new. Or reach out to us at:
              <a href="mailto:hello@researchai.io">hello@researchai.io</a>
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
              We will be in touch to set you up for success.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import VisibleText from "../../Translation/VisibleText.vue"

export default {
  name: "emerging_best_practices_in_UX_and_CX_research",
  setup() {
    const siteData = reactive({
      title: "Emerging best practices in UX and CX research",
      description:
        "How we use ResearchAI to deliver insights and drive service improvements"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
