<template>
  <div :class="`${className}__wrapper`" @click="onCardClick">
    <div :class="`${className}__img`">
      <slot name="image"></slot>
    </div>
    <div :class="`${className}__text`">
      <Component :is="titleTag" :class="`${className}__text-title`">
        <slot name="title"></slot>
      </Component>
      <Component :is="subTitleTag" :class="`${className}__text-subtitle`">
        <slot name="subtitle"></slot>
      </Component>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    titleTag: {
      type: String,
      default: "h2"
    },
    subTitleTag: {
      type: String,
      default: "h3"
    },
    className: {
      type: String,
      default: "card"
    }
  },
  methods: {
    onCardClick() {
      this.$emit("onCardClick")
    }
  }
}
</script>
