<template>
  <div id="dataset-progress-wrapper">
    <DatasetProgressList
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :items="list"
      @removeStep="removeStep"
    />
    <button
      class="dataset-progress__connect-btn"
      @click="$emit('connect-dataset')"
      :disabled="!isDatasetCleaningComplete"
    >
      <VisibleText>Connect dataset to project</VisibleText>
    </button>
    <div v-for="(step, index) in suggestedSteps" :key="index">
      <div v-if="step.suggesting && !readOnly">
        <button
          style="display: flex; flex-direction: column; width: 80%; padding: 0"
          class="message__block message__block--information"
          @click="addStep(index)"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="
                display: flex;
                font-weight: 200;
                flex-direction: column;
                line-height: 1.5em;
              "
            >
              <div>
                <SvgIconDecorative icon="info" />
                <VisibleText style="font-weight: 400; font-size: 0.7rem"
                  >Tip: Add {{ step.name }}</VisibleText
                >
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import DatasetProgressList from "./DatasetProgressList"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Mixins
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "DatasetProgress",
  mixins: [DatasetMixin, UserMixin],
  components: {
    DatasetProgressList,
    SvgIconDecorative
  },
  data() {
    return {
      isProjectsCreateModalVisible: false
    }
  },
  props: {
    suggestedSteps: {
      default: () => [],
      type: Array
    },
    list: {
      default: () => [],
      type: Array
    },
    isICCSBenchmarkGroup: {
      default: () => false,
      type: Boolean
    },
    isDatasetCleaningComplete: {
      default: () => false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  created() {
    if (this.$theme.theme.name == "iccs") {
      for (let i = 0; i < this.suggestedSteps.length; i++) {
        if (
          this.suggestedSteps[i].name === "benchmarking analysis" ||
          this.suggestedSteps[i].name === "additional dataset details"
        ) {
          this.$emit("updateSuggestions", i, false)
        }
      }
    }
  },
  methods: {
    addStep(step) {
      if (!this.readOnly) {
        if (step > -1) {
          for (let i = 0; i < this.list.length; i++) {
            if (i === step + 2) {
              this.$emit("updateList", i, true)
              this.$emit("updateSuggestions", step, false)
            }
          }
        }
      }
    },
    removeStep(step) {
      if (step) {
        for (let i = 0; i < this.list.length; i++) {
          if (i === step) {
            this.$emit("updateList", i, false)
            this.$emit("updateSuggestions", step - 2, true)
          }
        }
      }
    }
  },
  computed: {
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  }
}
</script>
