<template>
  <main id="user-analytics">
    <section class="translation-manager" v-if="isPigeon">
      <h1 class="translation-manager__content--padded translation-manager--h1">
        <VisibleText>User analytics</VisibleText>
      </h1>
      <button
        class="translation-manager__content--padded"
        @click="fetchAnalytics"
      >
        <VisibleText>Fetch all analytics</VisibleText>
      </button>
      <div v-for="(session, index) in individualSessions" :key="index">
        <UserSession :userSession="session"></UserSession>
      </div>
      <router-view></router-view>
    </section>
    <section class="translation-manager" v-else>
      <VisibleText
        >Warning: You do not have permission to view this.
      </VisibleText>
    </section>
  </main>
</template>

<script>
import UserAnalyticsService from "@/services/userAnalyticsService.js"
import UserSession from "@/components/UserAnalytics/UserSession.vue"
import { mapGetters } from "vuex"

//mixins
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "UserAnalytics",
  components: {
    UserSession
  },
  mixins: [UserMixin],
  data() {
    return {
      ANALYTICS_SERVICE: new UserAnalyticsService(this.$pigeonline),
      fetchedSessions: []
    }
  },
  methods: {
    async fetchAnalytics() {
      try {
        const response = await this.ANALYTICS_SERVICE.analytics()
        this.fetchedSessions = response
        return response
      } catch (e) {
        throw new Error("UserAnalytics " + e.message)
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    individualSessions() {
      return this.fetchedSessions
    }
  }
}
</script>
