<template>
  <ul class="dataset-segments__list">
    <li
      v-for="segmentName in segmentKeys"
      :key="segmentName"
      class="dataset-segments__list-segment"
    >
      <DatasetSegmentsItemReadOnly
        :allow-remove="allowRemove"
        :segment-name="segmentName"
        @remove="$emit('remove', segment)"
      />
    </li>
  </ul>
</template>

<script>
import _ from "lodash"
import DatasetSegmentsItemReadOnly from "./DatasetSegmentsItem/DatasetSegmentsItemReadOnly.vue"

export default {
  name: "DatasetSegmentsList",
  components: {
    DatasetSegmentsItemReadOnly
  },
  props: {
    allowRemove: {
      type: Boolean,
      default: () => false
    },
    // segments object of a dataset
    segments: {
      type: [Object, String],
      required: true
    }
  },
  computed: {
    segmentKeys() {
      if (_.isString(this.segments)) return this.segments.split(",")
      else return Object.keys(this.segments).filter((k) => !!this.segments[k])
    }
  }
}
</script>
