import { Model } from "@pigeonline/core"

export default class DriversAnalysisRequest extends Model {
  constructor(data = {}) {
    super(data)

    this.dependentIDs = null
    this.independentIDs = null
    this.projectID = null
    this.datasetID = null
    this.algorithm = null
    this.filter_params = null

    this.setProps(data)
  }

  /**
   * Generate request body for drivers analysis with one dependent variable.
   * The expect body is:
   * "project_id": <hash id>,
   * "data_set_id": <str>
   * "dependent_variables_ids": <list of hash ids>
   * "independent_variables_ids" <list of hash ids>,
   * "is_composite_driver": False
   * @returns {Object} formatted request body for drivers analysis
   */
  getSingleDriversBody() {
    const body = {
      project_id: this.projectID,
      data_set_id: this.datasetID,
      dependent_variables_ids: this.dependentIDs,
      independent_variables_ids: this.independentIDs,
      is_composite_driver: false,
      algorithm: this.algorithm,
      filter_params: this.filter_params
    }
    return body
  }

  /**
   * Generate request body for drivers analysis with composite index (multiple
   * dependent variables).
   * The expected body is:
   * "project_id": <hash id>,
   * "data_set_id": <str>
   * "dependent_variables_ids": <list of hash ids>
   * "independent_variables_ids" <list of hash ids>,
   * "is_composite_driver": True
   * @returns {Object} formatted request body for composite index drivers analysis
   */
  getCompositeIndexDriversBody() {
    const body = {
      project_id: this.projectID,
      data_set_id: this.datasetID,
      dependent_variables_ids: this.dependentIDs,
      independent_variables_ids: this.independentIDs,
      is_composite_driver: true,
      algorithm: this.algorithm,
      filter_params: this.filter_params
    }
    return body
  }
}
