<template>
  <span
    class="icon-only-btn-wrapper tooltip"
    :style="smaller ? 'width: 1.5rem; height: 1.5rem;' : ''"
  >
    <!-- primary label only -->
    <template v-if="!auxiliary">
      <button
        class="tooltip__hoverable icon-only-btn center-align-parent"
        :aria-labelledby="`${uuid}-label`"
        :type="buttonType"
        @click.stop="$emit('click-handler', $event)"
        :disabled="propReadOnly"
      >
        <SvgIcon
          class="center-align-child"
          :aria-hidden="true"
          :focusable="false"
          :icon="icon"
          :style="smaller ? 'margin-left: -0.25rem; margin-top: -0.25rem;' : ''"
        />
      </button>
      <span
        class="tooltip__text tooltip__text--compact dark bottom no-arrow hoverable"
        role="tooltip"
        :id="`${uuid}-label`"
      >
        <slot></slot>
      </span>
    </template>

    <!-- with auxiliary desc -->
    <template v-if="auxiliary">
      <button
        class="tooltip__hoverable icon-only-btn center-align-parent"
        :aria-labelledby="`${uuid}-desc`"
        :type="buttonType"
        @click.stop="$emit('click-handler', $event)"
        :disabled="propReadOnly"
      >
        <SvgIcon
          class="center-align-child"
          :aria-hidden="true"
          :focusable="false"
          :icon="icon"
        />
        <span class="sr-only-nohover">
          <slot></slot>
        </span>
      </button>
      <span
        class="tooltip__text tooltip__text--compact dark bottom no-arrow hoverable"
        role="tooltip"
        :id="`${uuid}-desc`"
      >
        <slot name="desc"></slot>
      </span>
    </template>
  </span>
</template>

<script>
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "ButtonIconOnly",
  components: {
    SvgIcon
  },
  props: {
    auxiliary: {
      default: () => false,
      type: Boolean
    },
    buttonType: {
      default: () => "button",
      type: String
    },
    // file name of an icon in /assets/sprites
    icon: {
      type: String,
      required: true
    },
    smaller: {
      default: () => false,
      type: Boolean
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      uuid: this.$pigeonline.createUUID()
    }
  }
}
</script>
