<template>
  <div>
    {{ fetchMd() }}
    <div v-html="markdownToHtml"></div>
  </div>
</template>

<script>
import { marked } from "marked"

export default {
  name: "InsertMarkdown",
  props: {
    mdFilePath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mdText: ""
    }
  },
  methods: {
    fetchMd() {
      fetch(this.mdFilePath)
        .then((res) => res.text())
        .then((text) => (this.mdText = text))
    }
  },
  computed: {
    markdownToHtml() {
      return marked(this.mdText)
    }
  }
}
</script>
