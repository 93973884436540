<template>
  <Tooltip id="data-type-tooltip" v-if="!isTooltipClosed && !readOnly">
    <VisibleText>Let us find data types for you!</VisibleText>
    <button
      class="dataset-benchmarks__get-matches-btn"
      @click="onClickGetTypes"
    >
      <VisibleText>Get suggested data types</VisibleText>
    </button>
  </Tooltip>
</template>

<script>
// Cmponents
import Tooltip from "@/components/UI/Tooltip.vue"

export default {
  name: "DatasetCleanerTooltip",
  components: {
    Tooltip
  },
  props: {
    readOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isTooltipClosed: false
    }
  },
  mounted() {
    // setup onscroll listener
    if (document.getElementById("dataset-table")) {
      document
        .getElementById("dataset-table")
        .addEventListener("scroll", this.handleScroll)
    }
  },
  unmounted() {
    // destroy on scroll
    if (document.getElementById("dataset-table")) {
      document
        .getElementById("dataset-table")
        .removeEventListener("scroll", this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      if (
        document.getElementById("data-type-tooltip") &&
        document.getElementById("dataset-table") &&
        document.querySelector(".data-type")
      ) {
        let rect = document
          .getElementById("dataset-table")
          .querySelector(".data-type")
          .getBoundingClientRect()
        let isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

        if (!isVisible) {
          document.getElementById("data-type-tooltip").style.opacity = 0
        } else {
          document.getElementById("data-type-tooltip").style.opacity = 1
        }
      }
    },
    onClickGetTypes() {
      this.$store.dispatch("datasetWizard/setToFetchSuggestedDataType", true)
      this.isTooltipClosed = true
      this.$emit("on-change-data-type")
    }
  }
}
</script>
