// Components
import Modal from "@/components/UI/Modal.vue"
import ThemeList from "../Components/ThemeList.vue"

// Mixins
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"

// Library
// import _ from "lodash"
import moment from "moment"

export default {
  data() {
    return {
      themeList: [],
      thisThemeListParams: { uuid: "" },
      unsavedChanges: false,
      unwatchThemeListListener: null,
      updateKey: Date.now(),
      resetListener: false,
      shouldFocusFirstTheme: false
    }
  },
  mixins: [textAnalysisMixin, themeColorsMixin],
  components: {
    Modal,
    ThemeList
  },
  computed: {
    themeListRef: function () {
      return `theme-list-${this.$.uid}`
    },
    totalKeywordsLength: function () {
      let count = 0
      if (this.themeList) {
        if (this.themeList.length) {
          for (let i = 0; i < this.themeList.length; i++) {
            count = count + this.themeList[i].keywords.length
          }
        }
      }
      if (this.suggestedThemes) {
        if (this.suggestedThemes.length) {
          for (let i = 0; i < this.suggestedThemes.length; i++) {
            count = count + this.suggestedThemes[i].keywords.length
          }
        }
      }
      return count
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    closeModal() {
      this.unsavedChanges = false
      if (this.showSentenceWarning) {
        this.showSentenceWarning = false
      }
      this.$emit("closeModal")
    },
    /* Theme methods */
    getValidatedThemeTitle(newTitle, oldTitle, themeTitles) {
      if (typeof newTitle !== "string" || newTitle.trim() === "") {
        newTitle = "unnamed theme"
      }
      themeTitles.splice(themeTitles.indexOf(oldTitle.trim().toLowerCase()), 1)

      let count = 1
      let loop = themeTitles.includes(newTitle.trim().toLowerCase())
      while (loop) {
        if (themeTitles.includes(newTitle.trim().toLowerCase() + count)) {
          count += 1
          continue
        }
        newTitle = newTitle + count
        break
      }
      return newTitle
    },
    async createTheme(title, keywords = [], notes = {}) {
      let newTitle = "new theme"
      if (typeof title === "string" || title instanceof String) {
        newTitle = title
      }
      for (let i = 0; i < this.themeList.length; i++) {
        if (
          this.themeList[i].name.toLowerCase().includes(newTitle.toLowerCase())
        ) {
          let matches = this.themeList[i].name.match(/\d+$/)
          if (matches) {
            newTitle = newTitle + (parseInt(matches[0], 10) + 1)
          } else {
            newTitle = newTitle + 0
          }
        }
      }
      let notesForSaving = []
      if (Object.keys(notes).length > 0) {
        notesForSaving.push(notes)
      }
      this.shouldFocusFirstTheme = true

      let found_colors = this.themeList.map((theme) => theme.theme_color)
      let newColor = "#6a88aa"
      let assignedColor = await this.assignColor(found_colors)
      if (assignedColor) {
        newColor = assignedColor
      }
      let newTheme = {
        text_analysis_uuid: this.textAnalysisObject.uuid,
        name: newTitle,
        keywords: keywords,
        keyword_matches: [],
        filtered_keyword_matches: [],
        dropped: [],
        added: [],
        coverage_queue: 0,
        coverage: {},
        notes: notesForSaving,
        is_proposed: true, // this gets deleted, is only used locally for styles
        selected: false,
        _id: this.$pigeonline.createUUID(), // this is a temporary ID for the purpose of selecting, will be deleted before saving
        created_on: moment().valueOf(),
        theme_color: newColor
      }
      this.themeList.unshift(newTheme)

      return newTheme
    },
    reset() {
      if (this.unwatchThemeListListener) {
        this.unwatchThemeListListener()
      }

      // must get the right themes here:
      this.unsavedChanges = false
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.shouldFocusFirstTheme = false

      this.unwatchThemeListListener = this.$watch(
        "themeList",
        function (val) {
          if (!val) return
          this.unsavedChanges = true
        }.bind(this),
        {
          deep: true
        }
      )
    }
  },
  watch: {
    themes: function (val) {
      if (val) this.reset()
    },
    show: function (val) {
      if (val) this.reset()
    },
    unsavedChanges: function (val) {
      if (val) {
        // remove inline message block
        this.getModalRef() && this.getModalRef().removeMessage()
      }
    }
  }
}
