import language_cache from "@/components/Translation/LanguageCache.json"
const lang = window.localStorage.getItem("apiLanguage") || "en"
export default (english) => {
  if (!lang || lang === "en") return english
  const translation = language_cache.find(
    (translation) =>
      translation.text_en.trim().toLowerCase() === english.trim().toLowerCase()
  )
  return translation ? translation.text_fr : english
}
