// consts
import {
  TYPE_TO_READABLE,
  SCALE_TYPE_TO_READABLE
} from "@/utils/consts/constsDataTypes.js"

export default {
  data() {
    return {
      // readable / UI friendly version of data types
      TYPE_TO_READABLE: Object.freeze(TYPE_TO_READABLE),
      SCALE_TYPE_TO_READABLE: Object.freeze(SCALE_TYPE_TO_READABLE)
    }
  },
  methods: {
    cleanAllValues(uniqueValues) {
      uniqueValues.forEach((val) => (val.is_valid = true))
    },
    cleanScaleValues(uniqueValues, dataType) {
      uniqueValues.forEach((val) => {
        // if we can parse an int from the value, save it as modified value
        if (isNaN(Number(val.value)) && !Number.isNaN(parseInt(val.value))) {
          val.modified_value = parseInt(val.value)
        }
        val.is_valid = this.getValidScales(dataType).includes(
          parseInt(val.value)
        )
      })
      return uniqueValues
    },
    getValidScales(dataType) {
      let valid = []
      switch (dataType) {
        case "SCALE_1_TO_3":
          valid = [1, 2, 3]
          break
        case "SCALE_1_TO_4":
          valid = [1, 2, 3, 4]
          break
        case "SCALE_1_TO_5":
          valid = [1, 2, 3, 4, 5]
          break
        case "SCALE_1_TO_6":
          valid = [1, 2, 3, 4, 5, 6]
          break
        case "SCALE_1_TO_7":
          valid = [1, 2, 3, 4, 5, 6, 7]
          break
        case "SCALE_1_TO_8":
          valid = [1, 2, 3, 4, 5, 6, 7, 8]
          break
        case "SCALE_1_TO_9":
          valid = [1, 2, 3, 4, 5, 6, 7, 8, 9]
          break
        case "SCALE_1_TO_10":
          valid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          break
        case "SCALE_0_TO_10":
          valid = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          break
        case "SCALE_1_TO_11":
          valid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          break
      }
      return valid
    },
    /**
     * Clean values if it is scale, cat, or open ended
     * other types are not cleaned.
     */
    async $_dataTypeMixin_saveDataType(clientQuestion, dataType, updateStore) {
      // the same data type, no change
      if (clientQuestion.approved_data_type === dataType) return
      let scaleRange = null

      // cleaning scale values
      if (Object.keys(this.SCALE_TYPE_TO_READABLE).includes(dataType)) {
        scaleRange = this.SCALE_TYPE_TO_READABLE[dataType]
        this.cleanScaleValues(clientQuestion.unique_values, dataType)
      }
      // cleaning all values
      else if (Object.keys(this.TYPE_TO_READABLE).includes(dataType)) {
        scaleRange = null
        this.cleanAllValues(clientQuestion.unique_values)
      }

      clientQuestion.approved_data_type = dataType
      clientQuestion.scale_range = scaleRange

      if (dataType == "OPEN_ENDED" && clientQuestion.unique_values.length) {
        clientQuestion.unique_values = clientQuestion.unique_values.slice(0, 5)
      }

      if (updateStore)
        this.$store.dispatch(
          "datasetDetails/updateClientQuestion",
          clientQuestion
        )

      if (dataType !== "OPEN_ENDED") {
        try {
          await this.$services.DATASETS_SERVICE.updateClientQuestion(
            clientQuestion._id.$oid,
            {
              approved_data_type: dataType,
              suggested_data_type: clientQuestion.suggested_data_type,
              scale_range: scaleRange,
              unique_values: clientQuestion.unique_values
            }
          )
        } catch (e) {
          throw new Error("DataTypeMixin " + e.message)
        }
      }

      if (dataType == "OPEN_ENDED") {
        this.askUserToConfirmText = true
      }
    }
  }
}
