<template>
  <div
    id="top-outliers"
    class="performance-analysis__select-chart"
    style="width: 100%; margin-top: 0"
  >
    <div style="padding: 2em">
      <VisibleText>
        Use these filters to search and discover outliers:
      </VisibleText>
      <div style="display: flex; flex-direction: row">
        <div v-if="sorted.by === 'sample_size' || sorted.by === 'this_average'">
          <RoundedPill
            :icon="sorted.order"
            :hideDelete="true"
            :inputModel="sorted.byOptions"
            :selected="sorted.by"
            :inputId="'sorted-by'"
            instructionLabel="Sorted by:"
            :css-styles="{
              backgroundColor: 'var(--secondary-ui-colour)',
              color: 'var(--base-colour)'
            }"
            @edit-selection="setSorted"
            @edit-selection-order="setSortedOrder"
          />
        </div>
        <div
          v-if="
            view.mode === 'unit' ||
            view.mode === 'question' ||
            view.mode === 'segment'
          "
        >
          <RoundedPill
            :inputModel="view.modeOptions"
            :selected="view.mode"
            :inputId="'group-by'"
            :hideDelete="true"
            instructionLabel="Grouped by:"
            :css-styles="{
              backgroundColor: 'var(--secondary-ui-colour)',
              color: 'var(--base-colour)'
            }"
            @edit-selection="setViewMode"
          />
        </div>
        <div
          v-if="
            posNeg.mode === 'pos' ||
            posNeg.mode === 'neg' ||
            posNeg.mode === 'both'
          "
        >
          <RoundedPill
            :inputModel="posNeg.modeOptions"
            :selected="posNeg.mode"
            :inputId="'show-pos-neg'"
            instructionLabel="Show:"
            :hideDelete="true"
            :css-styles="{
              backgroundColor: 'var(--secondary-ui-colour)',
              color: 'var(--base-colour)'
            }"
            @edit-selection="setPosNegMode"
          />
        </div>
      </div>
      <div style="display: flex">
        <form onsubmit="return false;">
          <label for="topOutliers-threshold"> Threshold </label>
          <input
            v-model="zscoreThreshold"
            type="range"
            min="1"
            max="4"
            class="slider"
            id="myRange"
          />
          <input
            v-model="zscoreThreshold"
            type="text"
            id="topOutliers-threshold"
            name="topOutliers-threshold"
            style="width: 3em"
          />
        </form>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <div class="topOutliers__header" style="display: flex; margin-left: 1rem">
        <div class="page-details">
          <VisibleText>Showing </VisibleText>
          <span v-if="view.mode === 'unit'">
            {{ paginatedTopOutliers.length }}
          </span>
          <span v-else-if="view.mode === 'question'">
            {{ paginatedTopOutliersbyQuestion.length }}
          </span>
          <span v-else-if="view.mode === 'segment'">
            {{ paginatedTopOutliersbySegment.length }}
          </span>
          <VisibleText> out of </VisibleText>
          <span>{{ totalQuestions }}</span>
          <VisibleText> outliers found in the data.</VisibleText>
        </div>
      </div>
      <div style="display: flex; line-height: 1.2em">
        <ul
          v-if="view.mode === 'unit'"
          style="width: 90%; list-style-type: none"
        >
          <li
            v-for="(outlier, index) in this.paginatedTopOutliers"
            :key="`top-outlier-${index}`"
            class="response-list-item response-list__item"
            style="padding-left: 2em; padding-right: 2em; padding-bottom: 3em"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="response-list-item__header-title"
                style="width: 75%; display: flex"
              >
                <VisibleText>Question:&nbsp;</VisibleText>
                <span
                  >{{ outlier.questionDetails.question_title }}
                  {{ outlier.questionDetails.question_text }}
                </span>
              </div>
              <div style="display: flex; width: 25%">
                <VisibleText>
                  {{ zscoreLabel(outlier.outlierObject.z_score) }}
                </VisibleText>
              </div>
            </div>
            <div>
              <VisibleText>Those who answered:</VisibleText>
              <div
                style="
                  background-color: var(--theme-keyword-light);
                  display: inline;
                "
              >
                {{ outlier.outlierObject.cat_value }}
                <VisibleText>to the question:</VisibleText>
                {{ outlier.categoryTitle }}
              </div>
            </div>
            <div
              style="
                background-color: white;
                border: 1px solid var(--outline-colour);
                padding: 0.5em;
              "
            >
              <div>
                <VisibleText>Average:</VisibleText>
                <span
                  style="font-weight: 800"
                  v-bind:class="{
                    isNegativeOutlier:
                      outlier.outlierObject.mean <
                      outlier.questionDetails.overall_mean,
                    isPositiveOutlier:
                      outlier.outlierObject.mean >
                      outlier.questionDetails.overall_mean
                  }"
                >
                  {{ outlier.outlierObject.mean }}
                </span>
                <VisibleText> compared to </VisibleText>
                {{ outlier.questionDetails.overall_mean }}
              </div>
              <div>
                <VisibleText>Responses in this group:</VisibleText>
                {{ outlier.outlierObject.sample_size }}
              </div>
            </div>
            <div
              class="response-list-item__options sr-show-on-focus"
              tabindex="0"
              :aria-label="`Save this outlier`"
            >
              <button
                type="button"
                class="response-list-item__options-btn"
                style="
                  display: flex;
                  flex-direction: row;
                  background-color: transparent;
                  color: var(--primary);
                "
                @click="$emit('saveThisOutlier', outlier, view.mode)"
                :disabled="propReadOnly"
              >
                <SvgIconDecorative icon="add" />
                <div style="display: flex; flex-direction: column">
                  <VisibleText>Save this outlier</VisibleText>
                </div>
              </button>
            </div>
          </li>
        </ul>
        <ul
          v-if="view.mode === 'question'"
          style="width: 90%; list-style-type: none"
        >
          <li
            v-for="(outlier, index) in this.paginatedTopOutliersbyQuestion"
            :key="`top-outlier-${index}`"
            class="response-list-item response-list__item"
            style="padding-left: 2em; padding-right: 2em; padding-bottom: 3em"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="response-list-item__header-title"
                style="width: 100%; display: flex"
              >
                <VisibleText>Question:&nbsp;</VisibleText>
                <span
                  >{{ outlier.questionDetails.question_title }}
                  {{ outlier.questionDetails.question_text }}</span
                >
              </div>
            </div>
            <div>
              <VisibleText>Total number of outliers:</VisibleText>
              {{ outlier.questionsOutliersTotalSample }}
            </div>
            <div>
              <VisibleText>Question average:</VisibleText>
              {{ roundToTwo(outlier.questionDetails.overall_mean) }}
            </div>
            <div>
              <VisibleText>Outliers average: </VisibleText>
              {{ roundToTwo(outlier.questionOutliersAverage) }}
              <VisibleText>(a difference of: </VisibleText>
              {{ roundToTwo(outlier.questionOutliersMeanDifference) }}
              )
            </div>
            <ul
              v-for="(unit, index) in outlier.questionOutliers"
              :key="`top-outlier-unit-${index}`"
              style="
                background-color: white;
                border: 1px solid var(--outline-colour);
                margin: 0.5em;
                padding: 0.5em;
                list-style-type: none;
              "
            >
              <li>
                <div style="display: flex; flex-direction: row">
                  <div
                    style="display: flex; width: 75%; flex-direction: column"
                  >
                    <div>
                      <VisibleText>Those who answered:</VisibleText>
                      <div
                        style="
                          background-color: var(--theme-keyword-light);
                          display: inline;
                        "
                      >
                        {{ unit.outlierObject.cat_value }}
                        <VisibleText>to the question:</VisibleText>
                        {{ unit.categoryTitle }}
                      </div>
                    </div>
                    <div>
                      <VisibleText>Average:</VisibleText>
                      <span
                        style="font-weight: 800"
                        v-bind:class="{
                          isNegativeOutlier:
                            unit.outlierObject.mean <
                            outlier.questionDetails.overall_mean,
                          isPositiveOutlier:
                            unit.outlierObject.mean >
                            outlier.questionDetails.overall_mean
                        }"
                      >
                        {{ unit.outlierObject.mean }}
                      </span>
                      <VisibleText>(a difference of </VisibleText>
                      {{ roundToTwo(unit.outlierMeanDifference) }}
                      )
                    </div>
                    <div>
                      <VisibleText>Responses in this group:</VisibleText>
                      {{ unit.outlierObject.sample_size }}
                    </div>
                  </div>
                  <div style="display: flex; width: 25%">
                    <VisibleText
                      >{{ zscoreLabel(unit.outlierObject.z_score) }}
                    </VisibleText>
                  </div>
                </div>
              </li>
            </ul>
            <div
              class="response-list-item__options sr-show-on-focus"
              tabindex="0"
              :aria-label="`Save this outlier`"
            >
              <button
                type="button"
                class="response-list-item__options-btn"
                style="
                  display: flex;
                  flex-direction: row;
                  background-color: transparent;
                  color: var(--primary);
                "
                @click="$emit('saveThisOutlier', outlier, view.mode)"
                :disabled="propReadOnly"
              >
                <SvgIconDecorative icon="add" />
                <div style="display: flex; flex-direction: column">
                  <VisibleText>Save this outlier</VisibleText>
                </div>
              </button>
            </div>
          </li>
        </ul>
        <ul
          v-if="view.mode === 'segment'"
          style="width: 90%; list-style-type: none"
        >
          <li
            v-for="(outlier, index) in this.paginatedTopOutliersbySegment"
            :key="`top-outlier-${index}`"
            class="response-list-item response-list__item"
            style="padding-left: 2em; padding-right: 2em; padding-bottom: 3em"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="response-list-item__header-title"
                style="width: 100%; display: flex"
              >
                <VisibleText> Segment:&nbsp; </VisibleText>
                <VisibleText>Those who answered</VisibleText>&nbsp;
                <div
                  style="
                    background-color: var(--theme-keyword-light);
                    display: inline;
                  "
                >
                  {{ outlier.respondentValue }}
                  <VisibleText>to the question </VisibleText>
                  {{ outlier.respondentQuestion }}
                </div>
              </div>
            </div>
            <div>
              <div>
                <VisibleText>Total number of outliers:</VisibleText>
                {{ outlier.segmentOutliersTotalSample }}
              </div>
              <div>
                <VisibleText>Average difference from mean:</VisibleText>
                {{ roundToTwo(outlier.segmentOutliersMeanDifference) }}
              </div>
              <ul
                v-for="(unit, index) in outlier.segmentUnits"
                :key="`top-outlier-unit-${index}`"
                style="
                  background-color: white;
                  border: 1px solid var(--outline-colour);
                  margin: 0.5em;
                  padding: 0.5em;
                  list-style-type: none;
                "
              >
                <li>
                  <div style="display: flex; flex-direction: row">
                    <div
                      style="display: flex; width: 75%; flex-direction: column"
                    >
                      <div>
                        <VisibleText>To the question: </VisibleText>
                        {{ unit.questionTitle }}
                      </div>
                      <div>
                        <VisibleText>Average: </VisibleText>
                        <span
                          style="font-weight: 800"
                          v-bind:class="{
                            isNegativeOutlier:
                              unit.outlierObject.mean < unit.questionMean,
                            isPositiveOutlier:
                              unit.outlierObject.mean > unit.questionMean
                          }"
                        >
                          {{ unit.outlierObject.mean }}
                        </span>
                        <VisibleText>compared to </VisibleText>
                        {{ unit.questionMean }}
                        <VisibleText>(a difference of </VisibleText>
                        {{ roundToTwo(unit.outlierMeanDifference) }}
                        )
                      </div>
                      <div>
                        <VisibleText>Sample size: </VisibleText>
                        {{ unit.outlierObject.sample_size }}
                      </div>
                    </div>
                    <div style="display: flex; width: 25%">
                      <VisibleText>
                        {{ zscoreLabel(unit.outlierObject.z_score) }}
                      </VisibleText>
                    </div>
                  </div>
                </li>
                <div
                  class="response-list-item__options sr-show-on-focus"
                  tabindex="0"
                  :aria-label="`Save this outlier`"
                >
                  <button
                    type="button"
                    class="response-list-item__options-btn"
                    style="
                      display: flex;
                      flex-direction: row;
                      background-color: transparent;
                      color: var(--primary);
                    "
                    @click="$emit('saveThisOutlier', outlier, view.mode)"
                    :disabled="propReadOnly"
                  >
                    <SvgIconDecorative icon="add" />
                    <div style="display: flex; flex-direction: column">
                      <VisibleText>Save this outlier</VisibleText>
                    </div>
                  </button>
                </div>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="topOutliers__footer">
        <button
          v-if="canLoadMoreQuestions"
          class="btn-nobg--secondary"
          type="button"
          @click="onClickLoadMoreQuestions"
        >
          <VisibleText>load more questions</VisibleText>
        </button>
        <span v-if="view.mode === 'unit'" class="page-details">
          {{ paginatedTopOutliers.length }} / {{ totalQuestions }}
        </span>
        <span v-if="view.mode === 'question'" class="page-details">
          {{ paginatedTopOutliersbySegment.length }} / {{ totalQuestions }}
        </span>
        <span v-if="view.mode === 'segment'" class="page-details">
          {{ paginatedTopOutliersbySegment.length }} / {{ totalQuestions }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import RoundedPill from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/ProjectAnalysisTextMain/UI/RoundedPill.vue"

export default {
  name: "TopOutliers",
  components: { SvgIconDecorative, RoundedPill },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      sorted: {
        by: "sample_size",
        byOptions: [
          { value: "sample_size", label: "sample size" },
          { value: "this_average", label: "average" }
        ],
        order: "descending",
        orderOptions: ["descending", "ascending"]
      },
      view: {
        mode: "segment",
        modeOptions: [
          { value: "unit", label: "unit" },
          { value: "question", label: "question" },
          { value: "segment", label: "segment" }
        ]
      },
      posNeg: {
        mode: "both",
        modeOptions: [
          { value: "pos", label: "positive" },
          { value: "neg", label: "negative" },
          { value: "both", label: "both" }
        ]
      },
      zscoreThreshold: 2,
      // pagination
      pagination: {
        endIndx: 10,
        limit: 10, //limit of how many are shown in a page
        startIndx: 0
      }
    }
  },
  methods: {
    setSorted(by) {
      this.sorted.by = by
    },
    setSortedOrder(order) {
      if (order === "ascending") {
        this.sorted.order = "descending"
      } else if (order === "descending") {
        this.sorted.order = "ascending"
      }
    },
    setViewMode(mode) {
      this.view.mode = mode
    },
    setPosNegMode(mode) {
      this.posNeg.mode = mode
    },
    sortOutliers: function (prop, arr, dir) {
      let direction = "ascending"
      if (dir) {
        direction = dir
      }
      prop = prop.split(".")
      let len = prop.length
      arr.sort(function (a, b) {
        var i = 0
        while (i < len) {
          a = a[prop[i]]
          b = b[prop[i]]
          i++
        }
        if (direction === "ascending") {
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        } else {
          if (a > b) {
            return -1
          } else if (a < b) {
            return 1
          } else {
            return 0
          }
        }
      })
      return arr
    },
    onClickLoadMoreQuestions() {
      this.pagination.endIndx = this.pagination.endIndx + this.pagination.limit
    },
    roundToTwo(num) {
      return Math.round(num * 100) / 100
    },
    zscoreLabel(zscore) {
      if (!zscore) return
      if (zscore <= -5) {
        return "Extreme negative outlier"
      } else if (zscore > -5 && zscore <= -4) {
        return "Strong negative outlier"
      } else if (zscore > -4 && zscore <= -3) {
        return "Negative outlier"
      } else if (zscore > -3 && zscore <= -2) {
        return "Somewhat negative outlier"
      } else if (zscore > -2 && zscore <= 2) {
        return "Not an outlier"
      } else if (zscore > 2 && zscore <= 3) {
        return "Somewhat positive outlier"
      } else if (zscore > 3 && zscore <= 4) {
        return "Positive outlier"
      } else if (zscore > 4 && zscore < 5) {
        return "Strong positive outlier"
      } else if (zscore >= 5) {
        return "Extreme positive outlier"
      }
    }
  },
  computed: {
    modifiedZscoreThreshold: function () {
      if (this.zscoreThreshold === 4) {
        return 1000
      } else {
        return this.zscoreThreshold
      }
    },
    topOutliers: function () {
      let filteredTopOutliers = []
      let outlier = {}

      for (let x = 0; x < this.data.summary_table.length; x++) {
        Object.values(
          this.data.summary_table[x][1].analysis_by_categories
        ).forEach((value) => {
          for (let y = 0; y < value.mean_values.length; y++) {
            if (
              (this.posNeg.mode === "pos" &&
                value.mean_values[y].z_score > 0) ||
              (this.posNeg.mode === "neg" &&
                value.mean_values[y].z_score < 0) ||
              this.posNeg.mode === "both"
            ) {
              if (
                Math.abs(value.mean_values[y].z_score) >
                this.modifiedZscoreThreshold
              ) {
                outlier = {
                  outlierObject: value.mean_values[y],
                  categoryTitle: value.question_title,
                  questionDetails:
                    this.data.summary_table[x][1].client_question_analysis
                }
                filteredTopOutliers.push(outlier)
              }
            }
          }
        })
      }
      let sort_type
      if (this.sorted.by === "sample_size") {
        sort_type = "outlierObject.sample_size"
      } else if (this.sorted.by === "this_average") {
        sort_type = "outlierObject.mean"
      }
      return this.sortOutliers(
        sort_type,
        filteredTopOutliers,
        this.sorted.order
      )
    },
    paginatedTopOutliers: function () {
      return this.topOutliers.slice(
        this.pagination.startIndx,
        this.pagination.endIndx
      )
    },
    topOutliersbyQuestion: function () {
      let filteredTopOutliers = []
      let outlier
      let unit
      let averages
      let sampleTotal
      for (let x = 0; x < this.data.summary_table.length; x++) {
        outlier = {
          questionDetails:
            this.data.summary_table[x][1].client_question_analysis,
          questionOutliers: [],
          questionOutliersMeanDifference: "",
          questionsOutliersTotalSample: 0
        }
        averages = []
        sampleTotal = 0
        Object.values(
          this.data.summary_table[x][1].analysis_by_categories
        ).forEach((value) => {
          for (let y = 0; y < value.mean_values.length; y++) {
            if (
              (this.posNeg.mode === "pos" &&
                value.mean_values[y].z_score > 0) ||
              (this.posNeg.mode === "neg" &&
                value.mean_values[y].z_score < 0) ||
              this.posNeg.mode === "both"
            ) {
              if (
                Math.abs(value.mean_values[y].z_score) >
                this.modifiedZscoreThreshold
              ) {
                unit = {
                  outlierObject: value.mean_values[y],
                  categoryTitle: value.question_title,
                  outlierMeanDifference:
                    value.mean_values[y].mean -
                    this.data.summary_table[x][1].client_question_analysis
                      .overall_mean
                }
                outlier.questionOutliers.push(unit)
                averages.push(value.mean_values[y].mean)
                sampleTotal = sampleTotal + value.mean_values[y].sample_size
              }
              if (y === value.mean_values.length - 1 && averages.length) {
                outlier.questionOutliersAverage = averages.reduce(
                  (p, c, _, a) => p + c / a.length,
                  0
                )
                outlier.questionOutliersMeanDifference =
                  averages.reduce((p, c, _, a) => p + c / a.length, 0) -
                  this.data.summary_table[x][1].client_question_analysis
                    .overall_mean
                outlier.questionsOutliersTotalSample = sampleTotal
                let sort_type
                if (this.sorted.by === "sample_size") {
                  sort_type = "outlierObject.sample_size"
                } else if (this.sorted.by === "this_average") {
                  sort_type = "outlierMeanDifference"
                }
                outlier.questionOutliers = this.sortOutliers(
                  sort_type,
                  outlier.questionOutliers,
                  this.sorted.order
                )
              }
            }
          }
        })
        if (outlier.questionOutliers.length) {
          filteredTopOutliers.push(outlier)
        }
      }
      let sort_type
      if (this.sorted.by === "sample_size") {
        sort_type = "questionsOutliersTotalSample"
      } else if (this.sorted.by === "this_average") {
        sort_type = "questionOutliersMeanDifference"
      }
      return this.sortOutliers(
        sort_type,
        filteredTopOutliers,
        this.sorted.order
      )
    },
    paginatedTopOutliersbyQuestion: function () {
      return this.topOutliersbyQuestion.slice(
        this.pagination.startIndx,
        this.pagination.endIndx
      )
    },
    topOutliersbySegment: function () {
      let filteredTopOutliers = []
      for (let x = 0; x < this.data.summary_table.length; x++) {
        Object.values(
          this.data.summary_table[x][1].analysis_by_categories
        ).forEach((value) => {
          for (let y = 0; y < value.mean_values.length; y++) {
            if (
              (this.posNeg.mode === "pos" &&
                value.mean_values[y].z_score > 0) ||
              (this.posNeg.mode === "neg" &&
                value.mean_values[y].z_score < 0) ||
              this.posNeg.mode === "both"
            ) {
              if (
                Math.abs(value.mean_values[y].z_score) >
                this.modifiedZscoreThreshold
              ) {
                let unit = {
                  outlierObject: value.mean_values[y],
                  categoryTitle: value.question_title,
                  questionTitle:
                    this.data.summary_table[x][1].client_question_analysis
                      .question_title,
                  questionText:
                    this.data.summary_table[x][1].client_question_analysis
                      .question_text,
                  questionMean:
                    this.data.summary_table[x][1].client_question_analysis
                      .overall_mean,
                  outlierMeanDifference:
                    value.mean_values[y].mean -
                    this.data.summary_table[x][1].client_question_analysis
                      .overall_mean
                }
                let segment = {
                  respondentValue: value.mean_values[y].cat_value,
                  respondentQuestion: value.question_title,
                  segmentUnits: [],
                  segmentOutliersTotalSample: 0
                }
                if (
                  filteredTopOutliers.filter(
                    (e) =>
                      e.respondentValue === segment.respondentValue &&
                      e.respondentQuestion === segment.respondentQuestion
                  ).length === 0
                ) {
                  segment.segmentUnits.push(unit)
                  segment.segmentOutliersTotalSample =
                    unit.outlierObject.sample_size
                  filteredTopOutliers.push(segment)
                } else {
                  filteredTopOutliers.forEach(function (obj) {
                    if (
                      obj.respondentValue === segment.respondentValue &&
                      obj.respondentQuestion === segment.respondentQuestion
                    ) {
                      obj.segmentUnits.push(unit)
                      obj.segmentOutliersTotalSample =
                        obj.segmentOutliersTotalSample +
                        unit.outlierObject.sample_size
                    }
                  })
                }
              }
            }
          }
        })
        if (x === this.data.summary_table.length - 1) {
          for (let y = 0; y < filteredTopOutliers.length; y++) {
            let averages = []
            for (
              let z = 0;
              z < filteredTopOutliers[y].segmentUnits.length;
              z++
            ) {
              averages.push(
                filteredTopOutliers[y].segmentUnits[z].outlierMeanDifference
              )
              let sort_type
              if (this.sorted.by === "sample_size") {
                sort_type = "outlierObject.sample_size"
              } else if (this.sorted.by === "this_average") {
                sort_type = "outlierMeanDifference"
              }
              filteredTopOutliers[y].segmentUnits = this.sortOutliers(
                sort_type,
                filteredTopOutliers[y].segmentUnits,
                this.sorted.order
              )
            }
            filteredTopOutliers[y].segmentOutliersMeanDifference =
              averages.reduce((p, c, _, a) => p + c / a.length, 0)
          }
        }
      }
      // return filteredTopOutliers
      let sort_type
      if (this.sorted.by === "sample_size") {
        sort_type = "segmentOutliersTotalSample"
      } else if (this.sorted.by === "this_average") {
        sort_type = "segmentOutliersMeanDifference"
      }
      return this.sortOutliers(
        sort_type,
        filteredTopOutliers,
        this.sorted.order
      )
    },
    paginatedTopOutliersbySegment: function () {
      return this.topOutliersbySegment.slice(
        this.pagination.startIndx,
        this.pagination.endIndx
      )
    },
    totalQuestions: function () {
      if (this.view.mode === "unit") {
        return this.topOutliers.length
      } else if (this.view.mode === "question") {
        return this.topOutliersbyQuestion.length
      } else if (this.view.mode === "segment") {
        return this.topOutliersbySegment.length
      } else return 0
    },
    canLoadMoreQuestions: function () {
      let canLoadMore = false
      if (this.view.mode === "unit") {
        if (this.topOutliers.length > this.paginatedTopOutliers.length) {
          canLoadMore = true
        }
      } else if (this.view.mode === "question") {
        if (
          this.topOutliersbyQuestion.length >
          this.paginatedTopOutliersbyQuestion.length
        ) {
          canLoadMore = true
        }
      } else if (this.view.mode === "segment") {
        if (
          this.topOutliersbySegment.length >
          this.paginatedTopOutliersbySegment.length
        ) {
          canLoadMore = true
        }
      }
      return canLoadMore
    },
    viewMode: function () {
      if (this.view) {
        if (this.view.mode) {
          return this.view.mode
        }
      }
      return "question"
    }
  },
  watch: {
    topOutliers: function () {
      if (this.view.mode === "unit") {
        this.pagination.totalQuestions = this.topOutliers.length
      }
    },
    topOutliersbyQuestion: function () {
      if (this.view.mode === "question") {
        this.pagination.totalQuestions = this.topOutliersbyQuestion.length
      }
    },
    topOutliersbySegment: function () {
      if (this.view.mode === "segment") {
        this.pagination.totalQuestions = this.topOutliersbySegment.length
      }
    },
    viewMode: function () {
      if (this.view.mode === "unit") {
        this.pagination.totalQuestions = this.topOutliers.length
      }
      if (this.view.mode === "question") {
        this.pagination.totalQuestions = this.topOutliersbyQuestion.length
      }
      if (this.view.mode === "segment") {
        this.pagination.totalQuestions = this.topOutliersbySegment.length
      }
    }
  }
}
</script>
