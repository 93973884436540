/**
 * Create performance chart data for pigeondoc chart
 * @param {Object} performanceChartData performance analysis chart data
 * @returns performance chart data
 */

const formatPerformanceChartData = (performanceChartData, threshold) => {
  // explore handling threshold here instead of in each individual chart, then fix Vertical

  let segmentedData = []
  let formatedData = []
  let finalData = []

  // Before continuing, check for segments and reformat the data to include their data
  // TO DO, make question text and title interchangable
  for (let i = 0; i < performanceChartData.length; i++) {
    // First time receiving, if segment exists, reformat
    let found_segments = []

    // Original response (from the API) requires [1]
    if (
      performanceChartData[i][1] &&
      performanceChartData[i][1]["client_question_analysis"]
    ) {
      found_segments = deepCloneObj(
        performanceChartData[i][1]["client_question_analysis"]
          .unique_segments_count_dict
      )
    }
    // Otherwise it is coming from a saved object
    if (
      performanceChartData[i] &&
      performanceChartData[i]["client_question_analysis"]
    ) {
      found_segments = deepCloneObj(
        performanceChartData[i]["client_question_analysis"]
          .unique_segments_count_dict
      )
    }
    if (found_segments && found_segments.length) {
      let lastUsedSegment = ""
      for (let x = 0; x < found_segments.length; x++) {
        let newValue
        if (threshold > -1) {
          newValue = replaceWithThresold(
            threshold,
            found_segments[x].Unique_counts_dict
          )
        } else {
          newValue = found_segments[x].overall_mean
        }

        let segmentValueAsString = ""
        if (
          typeof found_segments[x].segment_value === "string" ||
          found_segments[x].segment_value instanceof String
        ) {
          segmentValueAsString = found_segments[x].segment_value
        } else {
          segmentValueAsString = found_segments[x].segment_value.toString()
        }

        let thisSegment = ""
        if (lastUsedSegment == "") {
          thisSegment = found_segments[x].client_question_title
          lastUsedSegment = thisSegment
        } else if (
          lastUsedSegment !== found_segments[x].client_question_title
        ) {
          thisSegment = found_segments[x].client_question_title
        } else if (lastUsedSegment == found_segments[x].client_question_title) {
          thisSegment = ""
        }

        let chartObject = {
          value: newValue,
          label:
            found_segments[x].client_question_title +
            " - " +
            found_segments[x].question_title +
            " - " +
            segmentValueAsString,
          segment_value: segmentValueAsString,
          segment_question: found_segments[x].question_title,
          ques_label_when_segment: thisSegment,
          unique_counts: found_segments[x].Unique_counts_dict
        }
        segmentedData.push(chartObject)
      }
    }
  }
  if (segmentedData.length) {
    formatedData = segmentedData
  } else {
    formatedData = performanceChartData
  }

  for (let i = 0; i < formatedData.length; i++) {
    // the default format from the backend nestles extra information
    let chartObject = {}
    if (formatedData[i][1]) {
      let unique_counts = deepCloneObj(
        formatedData[i][1]["client_question_analysis"].unique_numbers_count_dict
      )
      chartObject = {
        value: formatedData[i][1]["client_question_analysis"].overall_mean,
        label: formatedData[i][1]["client_question_analysis"].question_title,
        unique_counts: unique_counts
      }

      // If has already been stripped from the backend into useable format
    } else {
      let unique_counts = deepCloneObj(
        formatedData[i]["unique_numbers_count_dict"]
      )

      chartObject = {
        value: formatedData[i]["value"],
        label: formatedData[i]["label"],
        unique_counts: unique_counts
      }
      if (formatedData[i]["ques_label_when_segment"]) {
        chartObject["ques_label_when_segment"] =
          formatedData[i]["ques_label_when_segment"]
      }
      if (formatedData[i]["segment_value"]) {
        chartObject["segment_value"] = formatedData[i]["segment_value"]
      }
      if (formatedData[i]["segment_question"]) {
        chartObject["segment_question"] = formatedData[i]["segment_question"]
      }
      if (formatedData[i]["theme_color"]) {
        chartObject["theme_color"] = formatedData[i]["theme_color"]
      }
    }
    if (chartObject) {
      finalData.push(chartObject)
    }
  }

  return finalData
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}

function deepCloneObj(obj) {
  // deep clones an object using JSON stringify (data loss might occur)
  if (Array.isArray(obj)) {
    return obj.map((item) => JSON.parse(JSON.stringify(item)))
  } else if (typeof obj == "object") {
    return JSON.parse(JSON.stringify(obj))
  }
}

function replaceWithThresold(threshold, unique_counts) {
  let keys = Object.keys(unique_counts)
  if (threshold > -1 && keys.length) {
    let countPositive = 0
    let countNegative = 0
    for (let x = 0; x < keys.length; x++) {
      let keyName = keys[x]
      if (parseFloat(keyName) > threshold) {
        countPositive = countPositive + parseFloat(unique_counts[keyName])
      } else {
        countNegative = countNegative + parseFloat(unique_counts[keyName])
      }
    }
    let newValue = (countPositive / (countPositive + countNegative)) * 100
    return roundToTwo(newValue)
    // suffix = "%"
  } else {
    return ""
  }
}

export { formatPerformanceChartData }
