<template>
  <SvgIcon
    role="img"
    :aria-hidden-use="true"
    :focusable="false"
    :icon="icon"
    :style="propReadOnly ? 'cursor: not-allowed; color: black' : ''"
  >
    <title>{{ title }}</title>
  </SvgIcon>
</template>

<script>
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "SvgIconMeaningful",
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
