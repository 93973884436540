<template>
  <DatasetLayout section-name="dataset-benchmarks" title="Add benchmarking">
    <div v-if="isViewingTerms">
      <h2><VisibleText>Permission required</VisibleText></h2>
      <p>
        <VisibleText
          >You are requesting to match your questions to the following
          benchmarking database:
        </VisibleText>
      </p>
      <div style="display: flex; justify-content: flex-end; margin-top: -1rem">
        <button
          style="
            border: none;
            background-color: transparent;
            font-size: 0.8em;
            margin-bottom: -1em;
            color: var(--error-text-colour);
          "
          @click="cancelTheseTerms"
        >
          <SvgIconDecorative icon="deleteIcon" />
          Cancel matching to this database
        </button>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 95%;
          margin-bottom: 2rem;
        "
        class="message__block message__block--information"
      >
        <div style="display: flex; flex-direction: row">
          <div
            class="project-analysis-item__header-wrapper-btn"
            style="
              display: flex;
              font-weight: 200;
              flex-direction: column;
              line-height: 1.5em;
            "
            v-if="selectedGroupFull"
          >
            <div>
              <VisibleText style="font-weight: 800"
                >Benchmark database name: &nbsp;</VisibleText
              >
              <span>
                <VisibleText>{{ selectedGroupFull.name }}</VisibleText>
              </span>
            </div>
            <div
              v-if="
                selectedGroupFull.created_by &&
                ((selectedGroupFull.created_by.name && !selectedGroupIsICCS) ||
                  selectedGroupFull.created_by.organization)
              "
            >
              <VisibleText style="font-weight: 800">Owner:&nbsp;</VisibleText>
              <span
                v-if="selectedGroupFull.created_by.name && !selectedGroupIsICCS"
              >
                <VisibleText>{{
                  selectedGroupFull.created_by.name
                }}</VisibleText>
              </span>
              <span v-else>
                <VisibleText>
                  {{ selectedGroupFull.created_by.organization }}
                </VisibleText>
              </span>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'benchmarkGroupEdit',
                  params: { id: selectedGroupFull.id }
                }"
                class="benchmark-manager__groups-item-name"
              >
                View benchmark questions
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <p>
        <VisibleText>
          Your data is fully encrypted and cannot be viewed by anyone without
          your permission. By connecting a question to a benchmarking database,
          you agree to share the following information with the administrator to
          ensure data quality:
        </VisibleText>
      </p>
      <ul>
        <li>
          <VisibleText>Your scores for only the questions shared</VisibleText>
        </li>
        <li>
          <VisibleText
            >The name of your file and the date you uploaded it</VisibleText
          >
        </li>
        <li>
          <VisibleText>Your username and organization name</VisibleText>
        </li>
      </ul>
      <p>
        <VisibleText
          >This information is only shared with benchmark database
          administrators, not other participants. Your scores will be fully
          anonymous to other users</VisibleText
        >
      </p>
      <label
        for="confirmBenchmarkSharing"
        class="form-label form-checkbox-label"
      >
        <input
          type="checkbox"
          id="confirmBenchmarkSharing"
          v-model="hasAgreedToTerms"
        />
        <VisibleText
          >I agree to share this information with the benchmark group
          admin</VisibleText
        >
      </label>

      <div style="display: flex; flex-direction: row; align-items: center">
        <div style="display: flex">
          <button
            type="submit"
            class="dataset-benchmarks__select-next-btn"
            @click="selectBenchmarkGroup"
            style="height: 2em"
            :disabled="!hasAgreedToTerms"
          >
            <VisibleText>Continue</VisibleText>
          </button>
        </div>

        <div
          style="display: flex; flex-direction: column; margin: 0 1em"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <p>
                <SvgIconDecorative icon="information" />
                <VisibleText
                  >Incomplete. Please review the above terms and agree to
                  proceed.</VisibleText
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!isViewingTerms">
      <DatasetBenchmarksDesc />
      <form class="dataset-benchmarks__select" @submit.prevent>
        <fieldset class="form-fieldset-group" id="dataset-benchmark-group">
          <legend class="form-fieldset-group__legend">
            <VisibleText>Choose a benchmark database</VisibleText>
          </legend>
          <p>
            <VisibleText>
              Below is a list of benchmarking databases available to you. Some
              are free for practice, while others are official and managed by
              admins. Benchmarking analysis allows you to compare your
              performance on certain measures to peers. Many institutes,
              governments and universities produce benchmarking data that they
              make available to the international community.
            </VisibleText>
            <a
              href="https://research-ai.io/library/guide/what-is-benchmarking-in-researchai"
              target="_blank"
            >
              <SvgIconDecorative icon="externalLink" />
              <VisibleText> Learn more about benchmarking. </VisibleText>
            </a>
          </p>
          <div
            v-if="this.permissions.includes('benchmarking-group-iccs')"
            style="display: flex; flex-direction: column; padding: 0"
            class="message__block message__block--information"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="project-analysis-item__header-wrapper-btn"
                style="
                  display: flex;
                  flex-direction: column;
                  line-height: 1.5em;
                "
              >
                <div>
                  <SvgIconDecorative icon="info" />
                  <VisibleText style="font-weight: 400">
                    As a subscriber to the Institute for Citizen-Centred Service
                    you have access to the official CitizenFirst benchmarking
                    group.
                  </VisibleText>
                </div>
              </div>
            </div>
          </div>
          <label
            v-for="group in benchmarkGroups"
            :key="group.id"
            :for="group.id"
            class="form-radio-label dataset-benchmarks__select-label"
            style="padding: 0 1em; margin: 0 1em"
          >
            <input
              class="form-radio-input"
              type="radio"
              :name="group.name"
              :id="group.id"
              :value="group.id"
              v-model="selectedGroup"
            />
            <span
              class="form-radio-selector"
              style="display: flex; flex-direction: row"
            >
              <div style="font-weight: 800">&nbsp;{{ group.name }}</div>
              <div
                style="
                  display: flex;
                  border-radius: 999px;
                  font-size: 0.85rem;
                  padding: 0 1em;
                  margin: 0 1em;
                  background-color: var(--match-keyword-light);
                  color: var(--match-keyword);
                "
              >
                <div v-if="group.access_type === 'private'">
                  <SvgIconDecorative icon="shared" style="width: 1em" />
                  <VisibleText> Private </VisibleText>
                </div>
                <div v-if="group.access_type === 'public__all'">
                  <div
                    v-if="
                      group.official_group &&
                      group.official_group.includes('iccs')
                    "
                  >
                    <SvgIconDecorative icon="shared" style="width: 1em" />
                    <VisibleText> ICCS </VisibleText>
                  </div>
                  <div v-else>
                    <SvgIconDecorative icon="shared" style="width: 1em" />
                    <VisibleText> Public </VisibleText>
                  </div>
                </div>
                <div v-if="group.access_type === 'public__team'">
                  <SvgIconDecorative icon="shared" style="width: 1em" />
                  <VisibleText> Your organization </VisibleText>
                </div>
              </div>
              <div
                v-if="group.created_by && group.access_type !== 'private'"
                style="
                  display: flex;
                  flex-direction: row;
                  font-size: 0.85rem;
                  margin: 0 0 0 -1em;
                "
              >
                <div style="display: flex">
                  &nbsp;(<VisibleText>Managed by</VisibleText>:&nbsp;
                </div>
                <div
                  style="display: flex"
                  v-if="group.access_type === 'public__all'"
                >
                  <div
                    v-if="
                      group.official_group &&
                      group.official_group.includes('iccs')
                    "
                  >
                    Institute for Citizen-Centred Services
                  </div>
                  <div v-else>
                    <div style="display: flex" v-if="group.created_by.name">
                      {{ group.created_by.name }}
                    </div>
                    <div
                      style="display: flex"
                      v-else-if="group.created_by.organization"
                    >
                      {{ group.created_by.organization }}
                    </div>
                    <div style="display: flex" v-else>
                      <VisibleText>Anonymous</VisibleText>
                    </div>
                  </div>
                </div>
                <div
                  style="display: flex"
                  v-else-if="group.access_type === 'public__team'"
                >
                  <div style="display: flex" v-if="group.created_by.name">
                    {{ group.created_by.name }}
                  </div>
                  <div
                    style="display: flex"
                    v-else-if="group.created_by.organization"
                  >
                    {{ group.created_by.organization }}
                  </div>
                  <div style="display: flex" v-else>
                    <VisibleText>Anonymous</VisibleText>
                  </div>
                </div>
                <div>)</div>
              </div>
            </span>
          </label>
        </fieldset>
        <div style="display: flex; flex-direction: row; align-items: center">
          <div style="display: flex">
            <button
              type="submit"
              class="dataset-benchmarks__select-next-btn"
              @click="isViewingTerms = true"
              style="height: 2em"
              :disabled="!selectedGroup || readOnly"
            >
              <VisibleText>Continue</VisibleText>
            </button>
          </div>

          <div
            style="display: flex; flex-direction: column; margin: 0 1em"
            class="message__block message__block--information"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="project-analysis-item__header-wrapper-btn"
                style="display: flex; font-weight: 200"
              >
                <p>
                  <SvgIconDecorative icon="information" />
                  <VisibleText
                    >Incomplete. Please select a benchmarking database and then
                    click continue to proceed.</VisibleText
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </DatasetLayout>
</template>

<script>
// Components
import DatasetBenchmarksDesc from "./DatasetBenchmarksDesc.vue"
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Mixins
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import UserMixin from "@/utils/mixins/userMixin"

export default {
  name: "DatasetBenchmarkSelectGroup",
  mixins: [DatasetMixin, DatasetDetailsMixin, UserMixin],
  components: {
    DatasetBenchmarksDesc,
    DatasetLayout,
    SvgIconDecorative
  },
  data() {
    return {
      benchmarkGroups: [],
      selectedGroup: null,
      isViewingTerms: false,
      hasAgreedToTerms: false
    }
  },
  async created() {
    this.benchmarkGroups = await this.fetchBenchmarkGroups()
  },
  computed: {
    selectedGroupFull() {
      if (!this.selectedGroup) {
        return {}
      }
      if (!this.benchmarkGroups || !this.benchmarkGroups.length) {
        return {}
      }
      return this.benchmarkGroups.filter(
        (group) => group.id == this.selectedGroup
      )[0]
    },
    selectedGroupIsICCS() {
      if (
        this.selectedGroupFull &&
        this.selectedGroupFull.official_group &&
        this.selectedGroupFull.official_group.includes("iccs")
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    async fetchBenchmarkGroups() {
      let groups = await this.$pigeonline.projects.get(BenchmarkGroup)
      // do not have permission to access iccs benchmark group
      if (!this.permissions.includes("benchmarking-group-iccs")) {
        groups = groups.filter(
          (group) =>
            !group.official_group || !group.official_group.includes("iccs")
        )
      }
      return groups
    },
    cancelTheseTerms() {
      this.selectedGroup = {}
      this.isViewingTerms = false
    },
    async selectBenchmarkGroup() {
      this.$_datasetWizardMixin_setBenchmarkingGroup(this.selectedGroup, true)
      this.saveDatasetProject(this.datasetProject)
      this.setGlobalQuestions(
        await this.$services.DATASETS_SERVICE.globalQuestions(null, {
          benchmark_group: this.selectedGroup
        })
      )
      // The below appears no longer connected
      this.$emit("benchmark-group-selected")
    },
    cancelTerms() {
      this.isViewingTerms = false
      this.hasAgreedToTerms = false
    }
  }
}
</script>
