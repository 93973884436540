<template>
  <Card subTitleTag="span">
    <template #title>
      {{ project.name }}

      <span class="project-status" v-if="projectStatus"
        >({{ projectStatus }})</span
      >

      <div
        style="
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          v-if="project.benchmarkAnalysis && project.benchmarkAnalysis > 0"
          style="margin-left: 1rem"
        >
          <img
            style="width: 1.25rem; opacity: 0.75"
            src="/assets/benchmarking_chart.png"
            alt="has benchmarking analysis"
            title="has benchmarking analysis"
          />
        </div>
        <div
          v-if="project.textAnalysisObjects && project.textAnalysisObjects > 0"
          style="margin-left: 1rem"
        >
          <img
            style="width: 1.35rem; opacity: 0.75"
            src="/assets/sentiment_chart.png"
            alt="has text analysis"
            title="has text analysis"
          />
        </div>
        <div
          v-if="project.performanceAnalysis && project.performanceAnalysis > 0"
          style="margin-left: 1rem"
        >
          <img
            style="width: 1.25rem; opacity: 0.75"
            src="/assets/performance_chart.png"
            alt="has bar charts"
            title="has bar charts"
          />
        </div>
        <div
          v-if="project.outliersAnalysis && project.outliersAnalysis > 0"
          style="margin-left: 1rem"
        >
          <img
            style="width: 1.25rem; opacity: 0.75"
            src="/assets/outliers_analysis.png"
            alt="has outliers analysis"
            title="has outliers analysis"
          />
        </div>
        <div
          v-if="project.driversAnalysis && project.driversAnalysis > 0"
          style="margin-left: 1rem"
        >
          <img
            style="width: 1.25rem; opacity: 0.75"
            src="/assets/matrix_chart.png"
            alt="has drivers/regression analysis"
            title="has drivers/regression analysis"
          />
        </div>
      </div>
    </template>
    <template #subtitle>
      {{ formatDate(project.last_modified_on.$date, setLanguage) }}
    </template>

    <div class="card__text-content">
      <ProjectsItemNav :project="project" />
      <ProjectsItemDatasetDeleted v-if="!datasetNotDeleted" />
    </div>
  </Card>
</template>

<script>
// Components
import Card from "@/components/UI/Card.vue"
import ProjectsItemDatasetDeleted from "./ProjectsItemDatasetDeleted.vue"
import ProjectsItemNav from "./ProjectsItemNav.vue"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel.js"

import moment from "moment"

export default {
  name: "ProjectsGrid",
  components: {
    Card,
    ProjectsItemDatasetDeleted,
    ProjectsItemNav
  },
  props: {
    project: { type: BenchmarkingProject },
    datasetProjects: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    datasetNotDeleted() {
      const datasetIds = this.datasetProjects.map(
        (dataset) => dataset.dataset_id
      )
      return this.project.datasets.every((dataset) =>
        datasetIds.includes(dataset)
      )
    },
    projectStatus() {
      const id = this.$store.getters["user/getProfile"].django_ref_id
      const email = this.$pigeonline.getUserEmail()

      const access = this.project.access_type
      if (access && access == "public__all") return "public"
      if (access && access == "public__team") return "team"

      const shared =
        this.project.shared &&
        Array.isArray(this.project.shared) &&
        this.project.shared.map((r) => this.bkCompatiblePk(r))
      if (shared && (shared.includes(id) || shared.includes(email))) {
        return "shared"
      }

      return null
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    }
  },
  methods: {
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r)
        ? r
        : typeof r.pk !== "undefined"
          ? r.pk
          : typeof r.id !== "undefined"
            ? r.id
            : -1
    },
    formatDate: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value).locale("fr").format("MMMM D, YYYY")
        } else {
          return moment(value).format("MMMM D, YYYY")
        }
      }
    }
  }
}
</script>
