<template>
  <section
    class="language-debugger"
    v-if="languageDebugger === 'on'"
    style="z-index: 99999"
  >
    <h2 class="language-debugger--h2">Translation checker</h2>
    <div class="language-debugger__container">
      <div v-if="setLanguage === 'en'">
        <div v-if="hideOnManager">
          <button class="language-debugger__button--disabled">
            Can't run on this page
          </button>
        </div>
        <div v-else>
          <button @click="runChecker">Run check</button>
        </div>
      </div>
      <div v-else>
        <button class="language-debugger__button--disabled">
          Set to english to run checker
        </button>
      </div>
      <router-link
        v-if="!hideOnManager"
        class="language-debugger__content"
        to="/translation-manager"
      >
        Manager
      </router-link>
    </div>
    <div v-if="!hideOnManager" class="language-debugger__content">
      Response:
    </div>
    <div v-if="!hideOnManager" class="language-debugger__content">
      {{ summary }}
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "TranslationChecker",
  data() {
    return {
      allText: [],
      summary: ""
    }
  },
  computed: {
    languageDebugger() {
      return this.$store.getters.getLanguageDebug
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    },
    hideOnManager() {
      if (this.$route.name === "translationManager") {
        return true
      } else {
        return false
      }
    },
    translations() {
      return this.$store.getters.getTranslations
    }
  },
  methods: {
    ...mapActions(["setTranslations", "resetTranslations"]),
    runChecker() {
      if (this.translations) {
        this.allText = this.translations
      }
      let grabText = document.getElementsByClassName("visible-text__content")
      let textLength = grabText.length
      for (var textCounter = 0; textCounter < textLength; textCounter++) {
        this.allText.push({
          text_en: grabText[textCounter].innerHTML,
          uri: grabText[textCounter].baseURI,
          parentComponent: grabText[textCounter].className.split(" ")[1]
        })
      }

      let grabPlaceholder = document.getElementsByClassName(
        "visible-text__placeholder"
      )
      let placeholderLength = grabPlaceholder.length
      for (
        var placeholderCounter = 0;
        placeholderCounter < placeholderLength;
        placeholderCounter++
      ) {
        this.allText.push({
          text_en: grabPlaceholder[placeholderCounter].placeholder,
          uri: grabPlaceholder[placeholderCounter].baseURI,
          parentComponent:
            "Input name=" + grabPlaceholder[placeholderCounter].name
        })
      }

      // get translations that used the global translate function
      let grabFromDataAttr = document.querySelectorAll("[data-parent]")
      if (grabFromDataAttr) {
        textCounter += grabFromDataAttr.length
        grabFromDataAttr.forEach((element) => {
          this.allText.push({
            text_en: element.textContent,
            uri: element.baseURI,
            parentComponent: element.dataset.parent
          })
        })
      }

      let jsonObject = this.allText.map(JSON.stringify)
      let uniqueSet = new Set(jsonObject)
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse)
      this.setTranslations(uniqueArray)
      if (uniqueArray.length > 0) {
        this.summary =
          "Text found:" + textCounter + " Placeholders:" + placeholderCounter
      }
    }
  },
  watch: {
    setLanguage: function (val) {
      // set bg to red of elements that uses global translate function
      let grabFromDataAttr = document.querySelectorAll("[data-parent]")
      if (grabFromDataAttr && val === "fr" && this.languageDebugger === "on") {
        grabFromDataAttr.forEach((element) => {
          element.style.background = this.checkLocalCache(
            element.textContent.trim(),
            element.dataset.parent
          )
            ? ""
            : "red"
        })
      }
    },
    languageDebugger: function (val) {
      if (val === "off") {
        document.querySelectorAll("[data-parent]").forEach((element) => {
          element.style.background = ""
        })
      }
    }
  }
}
</script>
