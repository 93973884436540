<template>
  <DialogPopup
    class="theme__popup-dialog"
    :style="
      avoidLeftOffset
        ? 'position: absolute; width: 40vw; max-width: 40vw; left: 0;'
        : 'position: absolute; width: 40vw; max-width: 40vw'
    "
    header-tag="h4"
    :show="showSentencesInfo"
    @close="closeGuide"
  >
    <template #header>
      <div
        style="
          display: flex;
          top: 0;
          flex-direction: row;
          text-align: left;
          font-size: 1rem;
          text-transform: none;
        "
      >
        Learn more about sentence matching
      </div>
    </template>
    <template #body>
      <div>
        <div v-if="sentencesPagination.page == 1">
          <p>
            <VisibleText
              >We support features like finding similar sentences to strings of
              text.
            </VisibleText>
          </p>

          <p>
            <VisibleText></VisibleText>
          </p>
          <ul>
            <li>
              We use a BERT and python-based package called SentenceTransformers
              that has been trained to detect sentences from text.
            </li>
            <li>
              We then vectorize these data, that is, convert these data into a
              format that supports mathematical similarity analysis.
            </li>
            <li>
              <a href="https://www.sbert.net/">Learn more</a>
            </li>
          </ul>
        </div>
        <div v-if="sentencesPagination.page == 2">
          <p>
            <VisibleText
              >A response like "nice site!" can find not only direct matches but
              'similar' sentences such as:</VisibleText
            >
          </p>
          <ul>
            <li>Lovely website!</li>
            <li>Pretty cool site</li>
            <li>Pretty website</li>
            <li>The website is pretty</li>
          </ul>
          <p>
            <VisibleText
              >This is made possible by finding 'sentence embeddings' within the
              sentences that are trained using technology similar to GPTs
              (Generative Pretrained Transformers)</VisibleText
            >
          </p>
        </div>
        <div v-if="sentencesPagination.page == 3">
          <p>
            <MessageBlock
              message-type="warning"
              title="Warning: GPTs can hallucinate."
            >
              <VisibleText
                >General Pretrained Transformer-based technologies introduce a
                sort of randomness, often called 'temperature' that deviates
                results from a standard, repeatable answer. This means these
                tools are not perfectly accurate, as we want this for more
                colorful language and for exercises like 'finding similar but
                not the same'. Because of this randomness we keep humans in the
                decision-making process when interpretting results from these
                tools</VisibleText
              >
            </MessageBlock>
          </p>
          <ul>
            <li>
              We give you a signal icon to give you a sense of how strong the
              match is.
            </li>
            <li>
              We also give you the ability to manually select which matches make
              sense to you. This human interpretation over transformer-based
              matching helps ensure higher quality results.
            </li>
          </ul>
        </div>

        <div
          class="theme__popup-pagination"
          v-show="sentencesPagination.totalPages > 1"
        >
          <span class="details">
            {{ translate("Page", $options.name) }}
            {{ sentencesPagination.page }}
            {{ translate("of", $options.name) }}
            {{ sentencesPagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="sentencesPagination.page === 1"
              @click="onClickPrevPageSentiments"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="
                sentencesPagination.page === sentencesPagination.totalPages
              "
              @click="onClickNextPageSentiments"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
      </div>
    </template>
  </DialogPopup>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import textAnalysisMixin from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/Mixins/textAnalysisMixin.js"

export default {
  name: "SentencesMiniGuide",
  components: {
    DialogPopup,
    MessageBlock
  },
  mixins: [textAnalysisMixin],
  props: {
    showSentencesInfo: {
      type: Boolean,
      default: () => false
    },
    avoidLeftOffset: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      localShowSentencesInfo: false,
      sentencesPagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 3,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  methods: {
    onClickNextPageSentiments() {
      if (this.sentencesPagination.page === this.sentencesPagination.totalPages)
        return
      this.sentencesPagination.page = this.sentencesPagination.page + 1
      this.setPaginationDetailsSentiments(
        this.sentencesPagination.startIndx + this.sentencesPagination.limit,
        this.sentencesPagination.endIndx + this.sentencesPagination.limit
      )
    },
    onClickPrevPageSentiments() {
      if (this.sentencesPagination.page === 1) return
      this.sentencesPagination.page = this.sentencesPagination.page - 1
      this.setPaginationDetailsSentiments(
        this.sentencesPagination.startIndx - this.sentencesPagination.limit,
        this.sentencesPagination.endIndx - this.sentencesPagination.limit
      )
    },
    setPaginationDetailsSentiments(start, end) {
      this.sentencesPagination.startIndx = start
      this.sentencesPagination.endIndx = end
    },
    closeGuide() {
      this.$emit("closingGuide")
      this.localShowSentencesInfo = false
    }
  },
  created() {
    if (this.showSentencesInfo) {
      this.localShowSentencesInfo = true
    }
  },
  watch: {
    showSentencesInfo(newVal) {
      if (newVal) {
        this.localShowSentencesInfo = true
      } else {
        this.localShowSentencesInfo = false
      }
    }
  }
}
</script>
