import moment from "moment"

export default {
  formatDate: (value, language) => {
    let currentLang = ""
    if (language) {
      currentLang = language
    }
    if (value) {
      if (currentLang === "fr") {
        return moment(value).locale("fr").format("MMMM D, YYYY")
      } else {
        return moment(value).format("MMMM D, YYYY")
      }
    }
  }
}
