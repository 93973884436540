<template>
  <article id="project-analysis-benchmarking" style="position: relative">
    <div class="project-analysis__header">
      <h2 class="project-analysis__header-title">
        <VisibleText>Benchmark your data to peers</VisibleText>
      </h2>
      <Spinner
        :is-loading="findingSuggestedVariables"
        message="Searching for suggested analysis"
        complete-message=""
      />
      <button
        class="project-analysis__header-add-btn"
        v-if="!statusBenchmarksNotAvailable"
        :disabled="propReadOnly"
        @click="addBenchmarkingAnalysis"
      >
        <VisibleText>Add New Benchmarking Analysis</VisibleText>
      </button>
    </div>

    <p class="benchmarking__desc">
      <VisibleText
        >How does your organization compare to your peers? Benchmarks summarize
        averages or outliers based on segments of your dataset. For each
        question in your dataset that matches with our global database, we
        calculate the average and compare your score to each group. This
        indicates how organizations are performing in that group of service
        delivery.
      </VisibleText>
    </p>
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Guide: </VisibleText>
          <SvgIconDecorative icon="externalLink" />
          <a
            href="javascript:void(0)"
            @click="
              $emit('user-guide-expanded'),
                $emit('open-article', 'BenchmarkingAnalysis')
            "
          >
            Our guide to benchmarking analysis, including how to build your own
            benchmarking databases.
          </a>
        </div>
      </div>
    </div>

    <BenchmarkingMessages
      :status-benchmarks-pending-approval="statusBenchmarksPendingApproval"
      :status-benchmarks-not-available="statusBenchmarksNotAvailable"
      :status-org-not-verified="statusOrgNotVerified"
      :num-not-approved="numNotApprovedBenchmarks"
      :num-total="numTotalBenchmarks"
    />

    <SortProjects
      :sortBy="sortBy"
      @toggleSort="toggleSort"
      v-if="sortedItems.length > 1"
    />

    <template v-if="sortedItems && sortedItems.length > 0">
      <ProjectAnalysisBenchmarkingItem
        v-for="(benchmark, index) in sortedItems"
        :isReadOnly="propReadOnly"
        :key="benchmark.uuid"
        :index="index"
        :statusBenchmarksAvailable="statusBenchmarksAvailable"
        @refresh="$emit('refresh')"
        @updateSlides="$emit('updateSlides')"
      />
    </template>
    <NewBenchmarkingAnalysisModal
      v-if="availableBMs.length"
      :bm-questions="availableBMs"
      bm-analysis-title="Create Benchmarking Analysis"
      :isNewBMModalVisible="isNewBMModalVisible"
      @create-analysis="createNewBMs"
      @close-new-BM-analysis="isNewBMModalVisible = false"
    />
  </article>
</template>

<script>
// Components
import BenchmarkingMessages from "./ProjectAnalysisBenchmarkingMessages.vue"
import ProjectAnalysisBenchmarkingItem from "@/components/Project/ProjectAnalysis/ProjectAnalysisBenchmarking/ProjectAnalysisBenchmarkingItem"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SortProjects from "../Components/SortProjects.vue"
import NewBenchmarkingAnalysisModal from "./Components/NewBenchmarkingAnalysisModal.vue"

//Utils
import moment from "moment"

// Mixins
import BenchmarkAnalysisMixin from "./Mixins/benchmarkAnalysisMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

export default {
  name: "ProjectAnalysisBenchmarking",
  mixins: [
    BenchmarkAnalysisMixin,
    DatasetDetailsMixin,
    ProjectMixin,
    ProjectReportMixin
  ],
  components: {
    BenchmarkingMessages,
    ProjectAnalysisBenchmarkingItem,
    SvgIconDecorative,
    Spinner,
    NewBenchmarkingAnalysisModal,
    SortProjects
  },
  props: {
    hasBMQuestions: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      benchmarksApproved: true,
      numNotApprovedBenchmarks: 0,
      numTotalBenchmarks: 0,
      // prevent saving to report until we know dataset status
      isDatasetStatusLoading: false,
      statusNoScaleMatches: false, //no 5point questions matched with CMT
      savedPerformanceType: "",
      noResults: false,
      findingSuggestedVariables: false,
      isNewBMModalVisible: false,
      sortBy: "new"
    }
  },
  async created() {
    this.findingSuggestedVariables = true
    let autoGenerate = true
    if (this.project) {
      if (this.project.settings) {
        if (this.project.settings.autogeneratedBenchmarks === true) {
          autoGenerate = false
        }
      }
      if (this.project.benchmarkAnalysis) {
        if (this.project.benchmarkAnalysis.length) {
          autoGenerate = false
        }
      }
    }
    if (autoGenerate === true) {
      await this.getbenchmarkAnalysisObjects()
    }
    this.findingSuggestedVariables = false
    this.getDatasetStatus()
  },
  computed: {
    statusBenchmarksPendingApproval() {
      return !this.benchmarksApproved
    },
    statusBenchmarksNotAvailable() {
      return (
        !this.benchmarksApproved || this.statusNoScaleMatches || this.noResults
      )
    },
    statusBenchmarksAvailable() {
      return (
        (this.benchmarksApproved || this.statusBenchmarksPendingApproval) &&
        !this.statusNoScaleMatches
      )
    },
    title() {
      return `Overall Averages (${
        this.projectPerformanceType || "scale average"
      })`
    },
    availableBMs() {
      let availableBMs = []
      for (let i = 0; i < this.benchmarks.length; i++) {
        let localID = ""
        if (this.benchmarks[i].client_question_id) {
          localID = this.benchmarks[i].client_question_id
        }
        if (localID) {
          let foundQuestion = {}
          for (let x = 0; x < this.clientQuestions.length; x++) {
            let thisID = ""
            if (this.clientQuestions[x]._id) {
              if (this.clientQuestions[x]._id.$oid) {
                thisID = this.clientQuestions[x]._id.$oid
              } else {
                thisID = this.clientQuestions[x]._id
              }
            }
            if (thisID == localID) {
              foundQuestion = this.clientQuestions[x]
            }
          }
          if (foundQuestion) {
            availableBMs.push(foundQuestion)
          }
        }
      }

      return availableBMs
    },
    sortedItems: function () {
      if (this.project) {
        if (this.project.benchmarkAnalysis) {
          if (this.project.benchmarkAnalysis.length > 1) {
            if (this.sortBy === "old") {
              let theseObjects = this.project.benchmarkAnalysis
              theseObjects.sort((a, b) => {
                return a.last_modified_on - b.last_modified_on
              })
              return theseObjects
            } else {
              let theseObjects = this.project.benchmarkAnalysis
              theseObjects.sort((a, b) => {
                return b.last_modified_on - a.last_modified_on
              })
              return theseObjects
            }
          } else {
            return this.project.benchmarkAnalysis
          }
        }
      }
      return []
    }
  },
  methods: {
    toggleSort() {
      if (this.sortBy === "new") {
        this.sortBy = "old"
      } else {
        this.sortBy = "new"
      }
    },
    async createNewBMs(selectedQuestions, filterParam) {
      this.getbenchmarkAnalysisObjects(filterParam, selectedQuestions)
    },
    async saveResultsToProject(summaryResults) {
      summaryResults.last_modified_on = moment().valueOf()
      this.project.benchmarkAnalysis.push(summaryResults)
      this.project.settings.autogeneratedBenchmarks = true
      await this.cleanAndSaveProject()
      // await this.saveProject(this.project)
    },
    async getDatasetStatus() {
      try {
        this.isDatasetStatusLoading = true
        const response = await this.$pigeonline.projects.get(
          BenchmarkingDataset,
          {
            dataset_id: this.dataset._id.$oid
          }
        )
        this.benchmarksApproved = response[0].status.benchmarksApproved
        this.isDatasetStatusLoading = false
      } catch (e) {
        throw new Error(
          "ProjectAnalysisBenchmarking:getDatasetStatus " + e.message
        )
      }

      if (!this.benchmarksApproved) {
        this.numNotApprovedBenchmarks = this.benchmarks.filter(
          (b) => !b.approved
        ).length
        this.numTotalBenchmarks = this.benchmarks.length
      }
    },
    addBenchmarkingAnalysis() {
      this.isNewBMModalVisible = true
    },
    async getbenchmarkAnalysisObjects(filter_param, subsetOfQuestions) {
      this.findingSuggestedVariables = true
      this.savedPerformanceType = this.projectPerformanceType || "scale-average"
      const summaryResults = await this.fetchSummaryResults(
        this.project.id,
        filter_param,
        subsetOfQuestions
      )
      if (summaryResults) {
        summaryResults.uuid = this.$pigeonline.createUUID()
        // no summary results because there are no 5point scales that are matched to benchmark quesitons
        if (!summaryResults) this.statusNoScaleMatches = true
        // Are we still using this store function?
        this.$store.dispatch(
          "project/setBenchmarkSummaryResults",
          summaryResults
        )
        if (filter_param) {
          summaryResults.filter_param = filter_param
        } else {
          summaryResults.filter_param = {}
        }
        await this.saveResultsToProject(summaryResults)
        this.findingSuggestedVariables = false
      } else {
        this.noResults = true
      }
    }
  }
}
</script>
