<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <AnalysisSettings
        :propReadOnly="propReadOnly"
        ref="AnalysisSettings"
        :statusDisableActionBtns="statusDisableActionBtns"
        :index="index"
        :thisAnalysisName="'Scale performance'"
        :isPerformanceAnalysis="true"
        :isSegmented="isSegmented"
        :sortOption="this.sortSetting"
        :filterParams="performanceAnalysisObject.filter_params"
        @change-sort-option="changeSortOptions"
        @applyChartThreshold="applyChartThreshold"
        @save-to-report="saveToReport"
        @change-chart="changeChart"
        @on-click-delete="onClickDelete"
        @saved-slide="updateSavedSlide"
        :savedThreshold="this.performanceAnalysisObject.threshold"
        :savedNodes="this.savedNodes"
        :contentChanged="this.contentChanged"
        :hideSave="true"
        :lastModified="
          formatDateTime(
            performanceAnalysisObject.last_modified_on,
            $store.getters.getLanguage
          )
        "
        @toggled="isExpanded = !isExpanded"
      ></AnalysisSettings>
      <PerformanceCharts
        v-if="isExpanded"
        :chart-data="filteredChartData"
        :chartType="this.performanceAnalysisObject.chartType"
        :savedThreshold="this.performanceAnalysisObject.threshold"
        :isLoading="isLoadingChartData"
        :isSegmented="isSegmented"
      />
    </article>
  </div>
</template>

<script>
import PerformanceCharts from "./Components/PerformanceCharts.vue"
import AnalysisSettings from "@/components/Project/ProjectAnalysis/Components/AnalysisSettings.vue"

// Helpers
import moment from "moment"
import _ from "lodash"

import { PigeonDocModel } from "@pigeonline/pigeondoc"

import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "ProjectAnalysisPerformanceItem",
  components: {
    AnalysisSettings,
    PerformanceCharts
  },
  mixins: [ProjectMixin, ProjectReportMixin, FiltersMixin],
  props: {
    index: {
      type: Number,
      required: true
    },
    retrievedSummaryTable: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      savedSlide: "",
      savedNodes: [],
      isExpanded: false,
      contentChanged: false,
      performanceAnalysisObject: {},
      filteredChartData: [],
      isLoadingChartData: false
    }
  },
  computed: {
    statusDisableActionBtns() {
      return this.isLoadingChartData
    },
    isSegmented() {
      let segmented = ""
      if (this.performanceAnalysisObject.summary_table) {
        for (
          let i = 0;
          i < this.performanceAnalysisObject.summary_table.length;
          i++
        ) {
          if (
            this.performanceAnalysisObject.summary_table[i][1][
              "client_question_analysis"
            ] &&
            this.performanceAnalysisObject.summary_table[i][1][
              "client_question_analysis"
            ].unique_segments_count_dict
          ) {
            segmented =
              this.performanceAnalysisObject.summary_table[i][1][
                "client_question_analysis"
              ].unique_segments_count_dict[0].question_title
          } else if (
            this.performanceAnalysisObject.summary_table[i][
              "client_question_analysis"
            ] &&
            this.performanceAnalysisObject.summary_table[i][
              "client_question_analysis"
            ].unique_segments_count_dict
          ) {
            segmented =
              this.performanceAnalysisObject.summary_table[i][
                "client_question_analysis"
              ].unique_segments_count_dict[0].question_title
          }
        }
      }
      return segmented
    },
    sortSetting() {
      if (
        this.performanceAnalysisObject &&
        this.performanceAnalysisObject.sortedBy
      ) {
        return this.performanceAnalysisObject.sortedBy
      } else {
        return ""
      }
    },
    setThreshold() {
      if (
        this.performanceAnalysisObject &&
        this.performanceAnalysisObject.threshold > -1
      ) {
        return this.performanceAnalysisObject.threshold
      } else {
        return -1
      }
    }
  },
  async created() {
    this.cloneSummaryTable()
  },
  methods: {
    cloneSummaryTable() {
      this.performanceAnalysisObject = _.cloneDeep(
        this.project.performanceAnalysis[this.index]
      )
      if (
        this.performanceAnalysisObject &&
        this.performanceAnalysisObject.summary_table
      ) {
        this.filteredChartData = this.performanceAnalysisObject.summary_table
      }
      if (this.performanceAnalysisObject.saved_node_ids) {
        this.savedNodes = this.performanceAnalysisObject.saved_node_ids
      }
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
    },
    async changeSortOptions(newSortOption) {
      if (!newSortOption) return
      this.performanceAnalysisObject.sortedBy = newSortOption
      await this.savePerformanceToProject(this.performanceAnalysisObject.uuid)
    },
    async applyChartThreshold(threshold) {
      if (threshold < -1) return
      this.performanceAnalysisObject.threshold = parseInt(threshold)
      await this.savePerformanceToProject(this.performanceAnalysisObject.uuid)
    },
    // Currently unavailable, also turned off in AnalysisSettings
    async changeChart(chartType) {
      if (chartType == "Horizontal Bar") {
        this.performanceAnalysisObject.chartType = "HorizontalBarChart"
      } else if (chartType == "Vertical Bar") {
        this.performanceAnalysisObject.chartType = "VerticalBarChart"
      }
      await this.savePerformanceToProject(this.performanceAnalysisObject.uuid)
    },
    async savePerformanceToProject(uuid = null) {
      if (this.propReadOnly || !this.performanceAnalysisObject) return

      const newObject = {
        last_modified_on: moment().valueOf(),
        uuid: uuid,
        filter_params: this.performanceAnalysisObject.filterParam,
        selectedQuestions: this.performanceAnalysisObject.selectedQuestions,
        isCancelled: this.performanceAnalysisObject.isCancelled,
        isFetching: this.performanceAnalysisObject.isFetching,
        isLongWait: this.performanceAnalysisObject.isLongWait,
        sortedBy: this.performanceAnalysisObject.sortedBy,
        summary_table: this.performanceAnalysisObject.summary_table,
        threshold: this.performanceAnalysisObject.threshold,
        chartType: this.performanceAnalysisObject.chartType
      }
      // find it in currently saved drivers, if there update, if not add it
      const indx = this.project.performanceAnalysis.findIndex(
        (d) => d.uuid == this.performanceAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.performanceAnalysis[indx].saved_node_ids = this.savedNodes
        newObject.saved_node_ids = this.savedNodes
        this.project.performanceAnalysis.splice(indx, 1, _.cloneDeep(newObject))
      } else {
        this.project.performanceAnalysis.unshift(_.cloneDeep(newObject))
      }

      await this.cleanAndSaveProject()
      this.performanceAnalysisObject = _.cloneDeep(newObject)
    },
    async updateFilteredChartData(sortOption) {
      if (!sortOption) {
        sortOption = "Original"
      }
      // skip reformatting if segmented
      if (this.filteredChartData && this.isSegmented == "") {
        if (this.performanceAnalysisObject.summary_table) {
          let storedData = this.deepCloneObj(
            this.performanceAnalysisObject.summary_table
          )
          let reorderedData = []

          for (let i = 0; i < storedData.length; i++) {
            if (storedData[i][1]) {
              let chartObject = {
                value:
                  storedData[i][1]["client_question_analysis"].overall_mean,
                label:
                  storedData[i][1]["client_question_analysis"].question_title,
                unique_numbers_count_dict:
                  storedData[i][1]["client_question_analysis"]
                    .unique_numbers_count_dict
              }

              if (
                this.performanceAnalysisObject.threshold &&
                this.performanceAnalysisObject.threshold > -1
              ) {
                chartObject.value = this.replaceWithThresold(
                  this.performanceAnalysisObject.threshold,
                  chartObject.unique_numbers_count_dict
                )
              }
              if (chartObject) {
                reorderedData.push(chartObject)
              }
            }
          }

          if (reorderedData.length) {
            if (sortOption === "Original") {
              if (this.$refs.AnalysisSettings) {
                this.$refs.AnalysisSettings.toggleContent()
              }
              this.filteredChartData = reorderedData
            } else if (sortOption === "Lowest to Highest") {
              reorderedData.sort((a, b) => {
                return a.value - b.value
              })
              if (this.$refs.AnalysisSettings) {
                this.$refs.AnalysisSettings.toggleContent()
              }
              this.filteredChartData = reorderedData
            } else if (sortOption === "Highest to Lowest") {
              reorderedData.sort((b, a) => {
                return a.value - b.value
              })
              if (this.$refs.AnalysisSettings) {
                this.$refs.AnalysisSettings.toggleContent()
              }
              this.filteredChartData = reorderedData
            }
          }
        }
      }
    },
    async saveToReport(selectedSlide, newSlideName) {
      const uuid = await this.savePerformanceToReport(
        selectedSlide,
        newSlideName
      )
      // this is used to get all AnalysisSetting->SaveAnalysis to rerun collections
      if (!this.project.reportSaves) {
        this.project.reportSaves = 1
      } else {
        this.project.reportSaves = this.project.reportSaves + 1
      }
      await this.savePerformanceToProject(uuid)
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.updateCollections()
      }
      this.$emit("refresh")
    },
    async savePerformanceToReport(selectedSlide, newSlideName) {
      try {
        this.isLoadingData = true

        let createdReportID = await this.createReportIfEmpty(
          selectedSlide,
          newSlideName
        ) // the above function will do nothing under conditions of emptiness
        if (selectedSlide) {
          createdReportID = selectedSlide.id
        }

        const returnObject = await this.updateReportWithPerformance(
          this.performanceAnalysisObject.uuid,
          createdReportID,
          this.performanceAnalysisObject
        )
        this.isLoadingData = false
        await this.$emit("updateSlides")
        this.onSuccessfulSave()
        this.savedNodes = returnObject.newNodes
        return returnObject.uuid
      } catch (e) {
        throw new Error("ProjectAnalysisPerformance:savePerformanceToReport ")
      }
    },
    /************
     *
     * Deleting
     *
     ***********/
    async deletePerformanceChart() {
      const indx = this.project.performanceAnalysis.findIndex(
        (d) => d.uuid === this.performanceAnalysisObject.uuid
      )
      this.project.performanceAnalysis.splice(indx, 1)
      await this.cleanAndSaveProject()
      // Drops the same nodes from the saved project
      let project
      if (this.savedNodes.length) {
        project = await this.$pigeonline.projects.getByID(
          PigeonDocModel,
          this.savedSlide.id
        )
        if (project) {
          for (let i = 0; i < this.savedNodes.length; i++) {
            for (let x = 0; x < project.nodes.length; x++) {
              if (project.nodes[x].uuid === this.savedNodes[i].uuid) {
                project.nodes = project.nodes.filter(
                  (item) => item !== project.nodes[x]
                )
              }
            }
          }
        }
        await this.saveProject(project)
      }
      this.$emit("refresh")
      // location.reload() // hacky fix to delete problems messing with project, please tighten
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    async onClickDelete() {
      const indx = this.project.performanceAnalysis.findIndex(
        (d) => d.uuid == this.performanceAnalysisObject.uuid
      )
      if (!this.savedNodes.length) {
        this.setConfirmText({
          btn: "Delete?",
          title: "Delete performance chart?"
        })
      } else {
        this.setConfirmText({
          btn: "Delete",
          title:
            "Delete performance chart? This will also delete this chart from your report."
        })
      }
      this.setConfirmType("delete")
      this.setConfirmTarget(this.performanceAnalysisObject.uuid)
      this.setConfirmSourceComponent("text") // double check
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Performance saved",
        message: "Performance successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("performance")
      this.setConfirmIsVisible(true)
    },

    replaceWithThresold(threshold, unique_counts) {
      let keys = Object.keys(unique_counts)
      if (threshold > -1 && keys.length) {
        let countPositive = 0
        let countNegative = 0
        for (let x = 0; x < keys.length; x++) {
          let keyName = keys[x]
          if (parseFloat(keyName) > threshold) {
            countPositive = countPositive + parseFloat(unique_counts[keyName])
          } else {
            countNegative = countNegative + parseFloat(unique_counts[keyName])
          }
        }
        let newValue = (countPositive / (countPositive + countNegative)) * 100
        return this.roundToTwo(newValue)
        // suffix = "%"
      } else {
        return ""
      }
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2")
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  },

  watch: {
    sortSetting: async function (newVal) {
      if (newVal) {
        this.updateFilteredChartData(newVal)
      }
    },
    setThreshold: async function (newVal, oldVal) {
      if (oldVal && newVal) {
        if (oldVal !== newVal) {
          if (this.$refs.AnalysisSettings) {
            this.$refs.AnalysisSettings.toggleContent()
            let sortOption = "Original"
            if (this.performanceAnalysisObject.sortedBy) {
              sortOption = this.performanceAnalysisObject.sortedBy
            }
            this.updateFilteredChartData(sortOption)
          }
        }
      }
    },
    retrievedSummaryTable: function (newVal, oldVal) {
      if (oldVal && newVal) {
        if (oldVal !== newVal && newVal.length) {
          this.cloneSummaryTable()
        }
      }
    },
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "text" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.performanceAnalysisObject.uuid
      ) {
        await this.deletePerformanceChart()
      }
    }
  }
}
</script>
