<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to </VisibleText>
        <router-link to="/library">
          <VisibleText>Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1 class="library__h1">
      <VisibleText>What is thematic analysis?</VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        One of the most difficult types of data to work with is text data. These
        can be customer or employee comments, complaints, responses to surveys,
        live-chats, and more. If the number of text entries is very high, it can
        be very challenging to analyze. What are they saying?
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        ResearchAI has a text analytics / qualitative analytics tool that makes
        this much easier.
      </VisibleText>
    </p>
    <p>
      <VisibleText>Article contents:</VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--traditional-research')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Traditional qualitative research</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--overview-of-text')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Overview of the tool</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--autogenerating-themes')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>
            Auto-generating themes using our thematic analysis tool
          </VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('gotoo--final-text-comments')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Final comments about text analysis</VisibleText>
        </a>
      </li>
    </ul>
    <h2 class="library__h2" ref="goto--traditional-research">
      <VisibleText>Traditional qualitative research</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        This analysis is typically done by qualitative researchers who are
        trained to “code” quotes as reflecting some concept or idea. These are
        called "themes". This is also known as
        <a
          href="https://en.wikipedia.org/wiki/Thematic_analysis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VisibleText>thematic analysis</VisibleText>.</a
        >
        Three common approaches include:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          Counting the presence of words as evidence that people are saying
          something.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Interpreting meaning from words, often going beyond simply what is
          being said. An example is
          <a
            href="https://en.wikipedia.org/wiki/Discourse_analysis"
            target="_blank"
            rel="noopener noreferrer"
          >
            <VisibleText>discourse analysis</VisibleText>.
          </a>
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Interpreting emotions, such as when someone said something with strong
          negative or positive connotations. An example is
          <a
            href="https://en.wikipedia.org/wiki/Sentiment_analysis"
            target="_blank"
            rel="noopener noreferrer"
          >
            <VisibleText>sentiment analysis</VisibleText>.
          </a>
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        ResearchAI intends to make all three of these easier using AI and an
        easy-to-use interface.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--overview-of-text">
      <VisibleText>Overview of the text analytics tool</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        There are four main sections (or views) of ResearchAI's text analytics.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>"Saved themes" section</VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        This view shows you any themes you may have saved. Themes are concepts
        that are reflected in the text. These themes can be made up out of
        keywords that are found in respondent text, or notes left by
        researchers. Themes display a “coverage” which is the percentage of
        respondents who match either a keyword, or had a note left by a
        researcher versus the entire population of responses. 25% coverage would
        imply 1/4 respondents said something that match this theme.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The saved theme views also include two charts for each theme. One called
        keyword sentiment shows if the keywords are particularly positive,
        negative, or neutral. Another is called respondent sentiment, which
        shows if a respondent as a whole was likely positive or negative.
      </VisibleText>
    </p>
    <img
      class="screenshot--60"
      src="/assets/userguide__exampletheme.png"
      alt="screenshot of a saved theme"
    />
    <p>
      <VisibleText>
        At any time if you click “show this only”, all subsequent views will be
        filtered to only be looking at those who match this theme.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>"Filter" section</VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        In this section you can see if a filter has been applied, such as when a
        theme is shown. You can also search for a keyword here. Lastly, you can
        also set whether the next two sections will be in Sentiment Mode or
        Themes Mode.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_filter-text.png"
      alt="screenshot of the filter view for text analysis"
    />
    <h3>
      <VisibleText>"Summary of responses" section</VisibleText>
      :
    </h3>
    <p>
      <VisibleText>
        In this section you will see a summary of all respondents that are in
        view, displayed visually. If Sentiment Mode is applied, a lower bar
        score will imply a more negative respondent. A higher bar score will
        imply a more positive respondent.If you are in Themes Mode, a higher bar
        score will reflect a greater match to the theme. No bar score will
        reflect no match to the theme.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--50"
      src="/assets/userguide__textsummaryview.png"
      alt="screenshot of summary view chart for text analysis"
    />
    <h3><VisibleText>"Responses" section</VisibleText>:</h3>
    <p>
      <VisibleText>
        In this last section you can navigate each respondent. If you are in
        Sentiment Mode, some highly positive or negative keywords will be
        highlighted for your reference. These may or may not be interesting to
        you. In Themes Mode, you will see exactly which keyword, or note exists
        for this respondent.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        By clicking on the respondent number, you can see more information like
        if they answered any categorical questions. This helps understand who
        the respondent is. You may also see an overall performance score if you
        set one. For example, if overall satisfaction was set as a performance
        score, then their satisfaction score will be displayed here. You can
        also pin responses to the top, hide, or ban responses from your
        analysis.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--50"
      src="/assets/userguide__textresponseview.png"
      alt="screenshot of the response view for text analysis"
    />
    <h2 class="library__h2" ref="goto--autogenerating-themes">
      <VisibleText>
        Auto-generating themes using our thematic analysis tool
      </VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        ResearchAI can speed up the finding of key themes in your text. This
        button will automatically generate themes for your consideration. This
        thematic analysis is also called topic modeling. These can come from a
        number of sources:
      </VisibleText>
    </p>
    <h3><VisibleText>Clusters of interesting words</VisibleText></h3>
    <p>
      <VisibleText>
        We combine two of the most common approaches used for finding
        interesting bundles of keywords:
        <a
          href="https://en.wikipedia.org/wiki/Latent_Dirichlet_allocation"
          target="_blank"
          rel="noopener noreferrer"
        >
          LDA</a
        >, and
        <a
          href="https://en.wikipedia.org/wiki/Latent_semantic_analysis#Latent_semantic_indexing"
          target="_blank"
          rel="noopener noreferrer"
        >
          LSI</a
        >. If you see interesting keywords appearing in many of these clusters,
        there is a good chance it was mentioned a lot by your users. These are
        worth closer investigations.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To make the most out of these, remove words that are not as interesting,
        or even ban words that are appearing too often because they are too
        obvious.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        For example, the theme below was generated using LDA. It seems to have
        found two separate but interesting topics, website and staff. One could
        remove staff keywords and add them to a new theme to make better sense
        of these interesting keywords.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--50"
      src="/assets/userguide__lda-theme.png"
      alt="screenshot of a theme generated using LDA"
    />
    <h3><VisibleText>Pre-generated custom text models</VisibleText></h3>
    <p>
      <VisibleText>
        ResearchAI has partnered with academics to pre-define common industry
        best-in-class models for you. For example, two influential customer
        experience models have been uploaded and set to public. One is from the
        <a
          href="https://citizenfirst.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VisibleText>Institute for Citizen-Centred Services</VisibleText>
        </a>
        called CitizenFirst, and the other is a highly cited academic framework
        called
        <a
          href="https://dspace.lib.cranfield.ac.uk/bitstream/handle/1826/8514/Towards_a_Better_Measure_of_Customer_Experie?sequence=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          EXQ.</a
        >
        Factors from these models may be automatically found as themes in your
        text.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/article__cxtopicmodels.jpg"
      alt="screenshot of two pre-uploaded custom text models"
    />
    <h3><VisibleText>Custom text models</VisibleText></h3>
    <p>
      <VisibleText>
        You can build your own thematic models as well. At any point, click your
        profile button in the top right, and click “Custom Text Model”.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--30"
      src="/assets/userguide__useroptions.png"
      alt="screenshot of user options when clicking on your profile avatar"
    />
    <p>
      <VisibleText>
        To create a new custom text model, click “Create custom text model”.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Give your new model a name. For example, “Employee engagement”, or
        “common customer complaints”. If your model comes from a source, place
        it here.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__customtopicmodelgroup.png"
      alt="screenshot of options when creating a custom text model group"
    />
    <p>
      <VisibleText>
        Add a theme to go inside this model by clicking “add theme”. Give the
        theme a name, like, “leadership”, or, “took too long”. Example questions
        help you match the theme to survey questions. More importantly though,
        be sure to give the theme some keywords. These are what will inform the
        Custom Text Model in future use.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__customtopicmodel.png"
      alt="screenshot when creating a new theme in a custom text model group"
    />

    <p>
      <VisibleText>
        Below are a few themes from the CitizenFirst model, to give you a sense
        of how to fill in your own. The tool will then look for these keywords
        and associated words from your text.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/article__citizenfirst-customer-experience-drivers.jpg"
      alt="screenshot a few of the CitizenFirst drivers of customer experience"
    />
    <h2 class="library__h2" ref="gotoo--final-text-comments">
      <VisibleText>Final comments about text analysis</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Remember, language is tricky even for humans. Be sure to treat the
        automatic topics, sentiments, and other AI as suggestions to guide your
        research. As a helpful tip when reporting your findings to colleagues:
        you can always defend your themes based on coverage.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        This tool is under constant development. We are eager to learn from your
        experiences and to continue to evolve this tool. Please don’t hesitate
        to share your experience or make suggestions for improvement.
      </VisibleText>
    </p>
    <div v-if="!isUserGuide" class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.</router-link
              >
              You can run unlimited analysis for datasets up to 150 rows.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "what_is_thematic_analysis",
  setup() {
    const siteData = reactive({
      title: "What is thematic analysis in ResearchAI?",
      description:
        "A deeper look at ResearchAI's automated text and topic analysis. Instant and easy qualitative analysis."
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
