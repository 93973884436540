import { axios } from "@pigeonline/core"
import { mapActions, mapGetters } from "vuex"
import permissions from "@/permissions.js"

import ProfilesService from "@/services/profilesService.js"
import TeamsService from "@/services/teamsService.js"

export default {
  data() {
    return {
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline),
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      hasNoLicenses: false
    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile",
      notifications: "getNotifications",
      loading: "getLoading",
      licenses: "getLicenses",
      permissions: "getPermissions",
      teams: "getTeams"
    }),
    TEXT_SERVICE() {
      return this.$services.TEXT_SERVICE
    },
    parsedLicenses() {
      return this.licenses.map((license) => license.name)
    },
    isPigeon: function () {
      if (this.parsedLicenses.includes("pigeon")) {
        return true
      }
      return false
    },
    isTeaching: function () {
      if (this.parsedLicenses.includes("teaching")) {
        return true
      }
      return false
    },
    isResearchLicense: function () {
      if (this.parsedLicenses.includes("research")) {
        return true
      }
      return false
    },
    isICCS: function () {
      if (
        this.parsedLicenses.includes("iccs") ||
        this.parsedLicenses.includes("iccs-admin")
      ) {
        return true
      }
    },
    isICCSAdmin: function () {
      if (this.parsedLicenses.includes("iccs-admin")) {
        return true
      }
    }
  },
  methods: {
    //TODO: the setLoading function sometimes conflicts with setLoading in store/loader,
    // need to namespace this function
    ...mapActions("user", [
      "setProfile",
      "setLicenses",
      "setPermissions",
      "setTeams",
      "setNotifications",
      "setLoading",
      "resetUser"
    ]),
    async setUserDetails() {
      this.$store.dispatch("user/setLoading", true)
      const profile = await this.$pigeonline.profile.get()
      this.setProfile(profile)
      let licenses = await this.PROFILES_SERVICE.getProfileLicenses(
        this.baseURL
      )
      await this.setLicenses(licenses)
      await this.setLicensePermissions(licenses)

      if (!licenses || !licenses.length) {
        this.hasNoLicenses = true
      }

      let teams = await this.TEAMS_SERVICE.getTeams({
        include_details: true
      })
      this.setTeams(teams)

      this.$store.dispatch("user/setLoading", false)
    },
    async resetTeams() {
      let teams = await this.TEAMS_SERVICE.getTeams({
        include_details: true
      })
      this.setTeams(teams)
    },
    async setUserNotifications() {
      try {
        const res = await axios.get(
          this.$pigeonline.options.baseUrl + "/notifications/"
        )
        this.setNotifications(res.data)
      } catch (e) {
        throw new Error("userMixin.vue:setUserNotifications: " + e.message)
      }
    },
    // This grabs unique permissions from permissions["license name"]
    async setLicensePermissions(licenses) {
      let licensePermissions = []
      // multiple licenses
      // Using FOR instead of filter to prevent async issues
      if (Array.isArray(licenses)) {
        for (let i = 0; i < licenses.length; i++) {
          let thesePermissions = permissions[licenses[i].name]
          for (let x = 0; x < thesePermissions.length; x++) {
            if (!licensePermissions.includes(thesePermissions[x])) {
              licensePermissions.push(thesePermissions[x])
            }
          }
        }
      }
      await this.setPermissions(licensePermissions)
    }
  }
}
