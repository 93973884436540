<template>
  <div class="performance__chart-container">
    <PerformanceChart
      :type="chartType"
      :chart-node="chartNode"
      :key="chartNodeKey"
      @deleteChart="$emit('deleteChart')"
    />
  </div>
</template>

<script>
import PerformanceChart from "./PerformanceChart.vue"

// Helper
import { performanceSimpleScoreChart as performanceChartNode } from "@/components/Reports/Utils/Library/Performance/performanceNodes.js"
import { PigeonDocNodeModel } from "@pigeonline/pigeondoc"

export default {
  name: "PerformanceCharts",
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    savedThreshold: {
      default: () => -1,
      type: Number
    },
    chartType: {
      default: () => "",
      type: String
    },
    isSegmented: {
      default: () => "",
      type: String
    },
    isThematic: {
      default: () => false,
      type: Boolean
    },
    alternative_x: {
      default: () => "",
      type: String
    },
    alternative_y: {
      default: () => "",
      type: String
    },
    alternative_x_hover: {
      default: () => "",
      type: String
    },
    filtered_by: {
      default: () => "",
      type: String
    }
  },
  components: {
    PerformanceChart
  },
  data() {
    return {
      chartNode: null,
      chartNodeKey: 0,
      error: false,
      errorMsg: null
    }
  },
  watch: {
    chartData: {
      immediate: true,
      handler() {
        if (this.chartData && this.chartData.err) {
          this.error = true
          this.errorMsg =
            "Error: There was an error with performance analysis. Please try again."
        } else if (this.chartData) {
          this.error = false
          this.errorMsg = null
          let thisTitle = "Question scores"
          if (this.isThematic) {
            thisTitle = "Theme coverage"
            if (this.filtered_by) {
              thisTitle = "Theme coverage filtered by: " + this.filtered_by
            }
          }
          if (this.isSegmented && this.isSegmented !== "") {
            thisTitle = thisTitle + " - Segmented by " + this.isSegmented
          }
          let suffix = ""
          if (this.isThematic) {
            suffix = "%"
          }
          this.chartNode = new PigeonDocNodeModel(
            performanceChartNode(
              thisTitle,
              this.chartData,
              this.language,
              this.savedThreshold,
              null,
              this.alternative_x,
              this.alternative_y,
              this.alternative_x_hover,
              suffix
            )
          )

          this.chartNodeKey += 1
        }
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters["getLanguage"]
    }
  }
}
</script>

<style></style>
