<template>
  <div style="position: relative">
    <div
      style="
        background-color: var(--primary-lightest);
        margin-top: 1rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
      "
      :style="{ 'background-color': getLicenseColor(license) }"
    >
      <div>
        <div style="">
          <VisibleText>
            {{ capitalizeFirstLetter(getLicenseType(license)) }}
            <span> license</span>
          </VisibleText>
        </div>
      </div>
      <div
        style="
          margin-left: 1rem;
          background-color: white;
          width: 10rem;
          border-radius: 1rem;
          text-align: center;
          margin-left: auto;
          margin-right: 1rem;
        "
      >
        <span style="width: fit-content">
          {{ capitalizeFirstLetter(license.name) }}</span
        >
        <VisibleText> license </VisibleText>
      </div>
    </div>
    <div v-if="owningTeam && owningTeam.team_name" style="margin-left: 1rem">
      <VisibleText>Inherited from </VisibleText>
      <span>{{ owningTeam.team_name }}</span>
    </div>
    <div style="margin-left: 1rem"><VisibleText>Features</VisibleText>:</div>
    <ul
      v-if="license.name == 'free'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>All analysis up to 150 rows of data</VisibleText>
      </li>
    </ul>
    <ul
      v-if="license.name == 'demo'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText
          >Can create new projects but cannot upload datasets or share
          projects.</VisibleText
        >
      </li>
    </ul>
    <ul
      v-if="license.name == 'basic'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>Access to all upload and analysis</VisibleText>
      </li>
      <li>
        <VisibleText>Can create basic teams</VisibleText>
      </li>
    </ul>
    <ul
      v-if="license.name == 'teaching'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>Access to all upload and analysis</VisibleText>
      </li>
      <li>
        <VisibleText
          >Can create demo teams which bypass the need for an email
          login.</VisibleText
        >
      </li>
    </ul>
    <ul
      v-if="license.name == 'project'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>Access to all upload and analysis</VisibleText>
      </li>
      <li>
        <VisibleText
          >Can create teams that get basic analysis features while team
          members.</VisibleText
        >
      </li>
    </ul>
    <ul
      v-if="license.name == 'iccs'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText
          >Access to the Institute for Citizen Centred Service's public sector
          service delivery benchmarking group</VisibleText
        >
      </li>
    </ul>
    <ul
      v-if="license.name == 'iccs-admin'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>Manager for the ICCS benchmarking group</VisibleText>
      </li>
    </ul>
    <ul
      v-if="license.name == 'pigeon'"
      style="font-size: 0.9rem; font-weight: 400; margin-right: 1rem"
    >
      <li>
        <VisibleText>User analytics</VisibleText>
        <VisibleText>License management</VisibleText>
      </li>
    </ul>
    <ButtonIconOnly
      v-if="isPigeon && !isTeamLicense && $route.path == '/pigeon-settings'"
      @click-handler="$emit('removeUserLicense', license, user, userIndex)"
      icon="minus"
      class="inline-edit-text__action-btn"
      style="color: black; position: absolute; bottom: 0.25rem; right: 0"
    >
      <VisibleText>Remove user license</VisibleText>
    </ButtonIconOnly>
  </div>
</template>

<script>
import UserCardMixin from "@/components/UI/UserCards/userCardMixin"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import UserMixin from "@/utils/mixins/userMixin"
import TeamsService from "@/services/teamsService.js"

export default {
  name: "LicenseCardLarge",
  mixins: [UserCardMixin, UserMixin],
  props: {
    isSelected: {
      default: false,
      type: Boolean
    },
    license: {
      default: () => {},
      type: Object
    },
    user: {
      default: () => {},
      type: Object
    },
    userIndex: {
      default: () => -1,
      type: Number
    }
  },
  components: {
    ButtonIconOnly
  },
  data() {
    return {
      owningTeam: {},
      TEAMS_SERVICE: new TeamsService(this.$pigeonline)
    }
  },
  async created() {
    if (this.license.team_id) {
      let updated_team = await this.TEAMS_SERVICE.getTeam({
        team_id: this.license.team_id,
        include_details: true
      })
      if (updated_team) {
        this.owningTeam = updated_team
      }
    }
  },
  computed: {
    isTeamLicense: function () {
      let isTeamLicense = false
      if (this.license.team_id) {
        isTeamLicense = true
      }
      return isTeamLicense
    }
  }
}
</script>
