<template>
  <main id="main-content" tabindex="-1">
    <section
      id="translation-manager"
      class="translation-manager"
      v-if="isPigeon"
    >
      <h1 class="translation-manager__content--padded translation-manager--h1">
        Translation Manager
      </h1>
      <div class="translation-manager__content--padded">
        Checklist for developers
      </div>
      <ul class="translation-manager__content--padded">
        <li>All new elements are entered into the local store</li>
        <li>All local store entries have a backend match</li>
        <li>
          The backend and the cache match (requires posting backend json to the
          cache and redeploying)
        </li>
      </ul>
      <TranslationTabs
        @update-activeView="updateActiveView"
        class="translation-manager__tabs--indent"
      />
      <div v-if="activeView === 'local'" class="translation-manager__content">
        <div class="translation-manager__content--padded">
          <h2>
            Current local text elements captured by the translation checker
          </h2>
          <div v-if="!localTranslationsFiltered">
            Sorry, the checker has not detected any translatable elements.
          </div>
          <div>
            <button @click="manualEntryMode = !manualEntryMode">
              Add entry manually
            </button>
            <button v-if="manualEntryChanging" @click="saveManualEntry()">
              Save entry manually
            </button>
            <div v-if="manualEntryMode">
              <input
                aria-label="add an english phrase"
                v-model="manualEntry.text_en"
                placeholder="english"
                v-on:change="manualEntryChanging = !manualEntryChanging"
              />
              <input
                aria-label="add a french phrase"
                v-model="manualEntry.text_fr"
                placeholder="french"
                readonly
              />
              <input
                aria-label="add an url"
                v-model="manualEntry.uri"
                placeholder="uri"
              />
              <input
                aria-label="add a parent component"
                v-model="manualEntry.parentComponent"
                placeholder="parentComponent"
              />
            </div>
          </div>
          <div v-if="localTranslationsFiltered">
            <button
              class="translation-manager__button"
              @click="checkTranslations"
            >
              Check for translations
            </button>
            <button v-if="hasChecked" @click="editMode = !editMode">
              <div v-if="editMode">Deactivate edit mode</div>
              <div v-else>Activate edit mode</div>
            </button>
            <div v-if="changedContent">
              Changes detected:
              <button @click="saveEdits">Sav to backend</button>
              <button @click="cancelEdits">Cancel</button>
            </div>
            <div>
              {{ consoleResponse }}
            </div>
            <div class="translation-manager__search-bar center-align-parent">
              <SearchBar
                class="center-align-child"
                placeholder="Filter"
                @search="search"
              />
            </div>
            <div class="translation-manager__filter-options">
              <label for="duplicates"> Hide duplicates</label>
              <input
                aria-label="hide duplicates"
                class="translation-manager__filter-options"
                type="checkbox"
                id="filterDuplicates"
                v-model="filterOptions.hideDuplicates"
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th>English text</th>
                  <th>French text</th>
                  <th>Uri</th>
                  <th>Parent Component</th>
                </tr>
              </thead>
              <tbody v-if="localTranslationsFiltered">
                <tr
                  v-for="visibleText in localTranslationsFiltered"
                  :key="visibleText.index"
                >
                  <td>
                    {{ visibleText.text_en }}
                  </td>
                  <td>
                    <div v-if="!hasChecked">Check for translations</div>
                    <div v-else>
                      <div v-if="editMode">
                        <input
                          aria-label="edit french phrase"
                          v-model="visibleText.text_fr"
                          placeholder="none"
                          v-on:change="contentChanging(visibleText)"
                        />
                      </div>
                      <div v-else>
                        {{ visibleText.text_fr }}
                      </div>
                    </div>
                  </td>
                  <td>{{ visibleText.uri }}</td>
                  <td>{{ visibleText.parentComponent }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="activeView === 'backend'" class="translation-manager__content">
        <div class="translation-manager__content--padded">
          <h2>Official translations received from the backend</h2>
          <button @click="fetchTranslations">Check backend</button>
          <div v-if="!backendTranslations">
            There has been no response from the backend.
          </div>
          <div>
            {{ consoleResponseBackend }}
          </div>
          <div v-if="backendTranslations">
            <button @click="saveCache">Save json to clipboard</button>
          </div>
          <table v-if="backendTranslations">
            <thead>
              <tr>
                <th>English text</th>
                <th>French text</th>
                <th>Uri</th>
                <th>Parent Component</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="visibleText in backendTranslations"
                :key="visibleText.index"
              >
                <td>
                  {{ visibleText.text_en }}
                </td>
                <td>{{ visibleText.text_fr }}</td>
                <td>{{ visibleText.uri }}</td>
                <td>{{ visibleText.parentComponent }}</td>
                <td>
                  <button @click="contentDelete(visibleText)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="activeView === 'cache'" class="translation-manager__content">
        <div class="translation-manager__content--padded">
          <h2>Current cache of translations saved to the frontend</h2>
          <div v-if="!cachedTranslations">
            Sorry, the checker has not detected any translatable elements.
          </div>
          <table>
            <thead>
              <tr>
                <th>English text</th>
                <th>French text</th>
                <th>Uri</th>
                <th>Parent Component</th>
              </tr>
            </thead>
            <tbody v-if="cachedTranslations">
              <tr
                v-for="visibleText in cachedTranslations"
                :key="visibleText.index"
              >
                <td>
                  {{ visibleText.text_en }}
                </td>
                <td>
                  {{ visibleText.text_fr }}
                </td>
                <td>{{ visibleText.uri }}</td>
                <td>{{ visibleText.parentComponent }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <router-view></router-view>
    </section>
    <section v-else>
      <VisibleText
        >Warning: You do not have permission to view this.
      </VisibleText>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
// Services
import TranslationService from "@/services/translationService.js"
import TranslationTabs from "@/components/Translation/TranslationTabs.vue"

import SearchBar from "@/components/UI/SearchBar.vue"

import language_cache from "@/components/Translation/LanguageCache.json"

// mixins
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "TranslationManager",
  components: {
    TranslationTabs,
    SearchBar
  },
  data() {
    return {
      TRANSLATION_SERVICE: new TranslationService(this.$pigeonline),
      hasChecked: false,
      editMode: false,
      localTranslations: null,
      backendTranslations: null,
      changedContent: false,
      editPayload: [],
      deletePayload: [],
      query: {
        term: ""
      },
      consoleResponse: "",
      consoleResponseBackend: "",
      filterOptions: {
        hideDuplicates: false
      },
      activeView: "local",
      manualEntry: {},
      manualEntryChanging: false,
      manualEntryMode: false
    }
  },
  mixins: [UserMixin],
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    translations() {
      return this.$store.getters.getTranslations
    },
    localTranslationsFiltered() {
      let filtered = this.localTranslations
      if (filtered) {
        if (this.filterOptions.hideDuplicates) {
          let x = {}
          filtered = this.localTranslations.filter(
            (obj) =>
              !x[obj.text_en.trim().toLowerCase()] &&
              (x[obj.text_en.trim().toLowerCase()] = true)
          )
        } else {
          filtered = this.localTranslations
        }
      }

      if (this.query.term.length > 0) {
        let list = []
        if (filtered) {
          for (var i = 0; i < this.localTranslations.length; i++) {
            if (
              this.localTranslations[i].text_en
                .toLowerCase()
                .includes(this.query.term.toLowerCase())
            ) {
              list.push(this.localTranslations[i])
            }
          }
          if (this.filterOptions.hideDuplicates) {
            let x = {}
            filtered = list.filter(
              (obj) =>
                !x[obj.text_en.trim().toLowerCase()] &&
                (x[obj.text_en.trim().toLowerCase()] = true)
            )
          } else {
            filtered = list
          }
        }
      }
      return filtered
    },
    cachedTranslations() {
      return language_cache
    }
  },
  mounted() {
    this.setLocalTranslations()
  },
  methods: {
    ...mapActions(["editTranslation", "setTranslations"]),
    async checkTranslations() {
      this.hasChecked = true
      let length = this.translations.length
      for (var counter = 0; counter < length; counter++) {
        const response = await this.fetchTranslation(this.translations[counter])
        let updated_translation = this.translations[counter]
        if (response.length > 0) {
          if (response[0].text_fr) {
            updated_translation.text_fr = response[0].text_fr
            updated_translation.fetched_id = response[0]._id.$oid
            this.editTranslation(updated_translation)
          }
        } else {
          this.translations[counter].text_fr = null
        }
      }
      this.setLocalTranslations()
    },
    async fetchTranslation(translation) {
      try {
        const data = translation
          ? {
              text_en: translation.text_en,
              url: translation.uri,
              parent_component: translation.parentComponent
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.translations(null, data)
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    async fetchTranslations() {
      try {
        this.backendTranslations = await this.TRANSLATION_SERVICE.translations()
        for (var i = 0; i < this.backendTranslations.length; i++) {
          this.backendTranslations[i].uri = this.backendTranslations[i].url
          delete this.backendTranslations[i].url
          this.backendTranslations[i].parentComponent =
            this.backendTranslations[i].parent_component
          delete this.backendTranslations[i].parent_component
        }
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    async patchTranslation(id, translation) {
      try {
        const data = translation
          ? {
              text_fr: translation.text_fr
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.updateTranslations(
          id,
          data
        )
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    async newTranslation(translation) {
      try {
        const data = translation
          ? {
              text_fr: translation.text_fr,
              text_en: translation.text_en,
              url: translation.uri,
              parent_component: translation.parentComponent
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.newTranslation(data)
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    setLocalTranslations() {
      this.localTranslations = this.translations
    },
    saveManualEntry() {
      if (this.manualEntry) {
        let uniqueArray = []
        if (this.translations) {
          uniqueArray = this.translations
        }
        uniqueArray.push(this.manualEntry)
        this.setTranslations(uniqueArray)
        this.setLocalTranslations()
        this.manualEntryMode = false
        this.manualEntryChanging = false
        this.changedContent = true
      }
    },
    contentChanging(text) {
      this.editPayload.push(text)
      this.changedContent = true
    },
    async contentDelete(text) {
      const response = await this.TRANSLATION_SERVICE.deleteTranslation(
        text._id.$oid
      )
      if (response) {
        this.consoleResponseBackend = "Successfully deleted translation"
        this.fetchTranslations()
      }
    },
    cancelEdits() {
      this.checkTranslations()
      this.changedContent = false
    },
    async saveEdits() {
      let length = this.editPayload.length
      let response
      for (var counter = 0; counter < length; counter++) {
        let fetched_id = this.editPayload[counter].fetched_id
        if (fetched_id) {
          delete this.editPayload[counter].fetched_id
          response = await this.patchTranslation(
            fetched_id,
            this.editPayload[counter]
          )
        } else {
          response = await this.newTranslation(this.editPayload[counter])
        }
      }
      if (response) {
        this.consoleResponse = "Successfully saved translation"
      }
      this.changedContent = false
    },
    async saveCache() {
      let filteredTranslations = this.backendTranslations.filter(
        (translation) => translation.text_fr
      )
      if (!navigator.clipboard) {
        // Clipboard API not available
        return
      }
      const text = JSON.stringify(filteredTranslations)
      try {
        await navigator.clipboard.writeText(text)
      } catch (err) {
        console.error("Failed to copy!", err)
      }
    },
    search(query) {
      this.query.term = query
    },
    updateActiveView(item) {
      this.activeView = item
    }
  }
}
</script>
