const TYPE_TO_READABLE = {
  OPEN_ENDED: "text",
  NUMERIC: "numeric",
  ID: "id",
  CAT: "category",
  DATE: "date",
  SURVEY_WEIGHT: "weight",
  OTHER: "other"
}

const SCALE_TYPE_TO_READABLE = {
  SCALE_1_TO_3: "3-point scale",
  SCALE_1_TO_4: "4-point scale",
  SCALE_1_TO_5: "5-point scale",
  SCALE_1_TO_6: "6-point scale",
  SCALE_1_TO_7: "7-point scale",
  SCALE_1_TO_8: "8-point scale",
  SCALE_1_TO_9: "9-point scale",
  SCALE_1_TO_10: "10-point scale",
  SCALE_1_TO_11: "11-point scale",
  SCALE_0_TO_10: "Net-Promoters Score (0 to 10)"
  //CUSTOM_SCALE: "custom scale"
}

// add types
const DATA_TYPE_TO_READABlE = { ...TYPE_TO_READABLE, ...SCALE_TYPE_TO_READABLE }

export { TYPE_TO_READABLE, SCALE_TYPE_TO_READABLE, DATA_TYPE_TO_READABlE }
