<template>
  <div id="dataset">
    <div v-if="hasNoLicenses">You have no registered licenses</div>
    <div
      v-if="
        parsedLicenses.includes('free') &&
        parsedLicenses.length == 1 &&
        ((this.dataset &&
          this.profile &&
          this.dataset.created_by == this.profile.django_ref_id) ||
          !Object.keys(this.dataset).length)
      "
    >
      <MessageBlock class="banner-msg" message-type="warning">
        <template #default>
          <VisibleText>
            Reminder. With a free account any dataset you upload will only
            include the first 150 rows of data in your file.
          </VisibleText>
        </template>
      </MessageBlock>
    </div>
    <!--------------------------------------------
    |
    | MAIN CONTENT
    |
    --------------------------------------------->
    <main
      id="main-content"
      tabindex="-1"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      v-if="!hasNoLicenses"
    >
      <div class="section__top-bar">
        <h1 class="section__top-bar-title">
          <VisibleText>Dataset Wizard</VisibleText>
        </h1>
      </div>
      <div class="dataset__content">
        <!--------------------------------------
        | sidebar messages
        ---------------------------------------->
        <div class="dataset__demo-info" v-if="isReadOnly">
          <MessageBlock message-type="information">
            This is a read-only dataset.
          </MessageBlock>
        </div>
        <div class="dataset__menu-description" v-else>
          <VisibleText>
            You can clean and label your data in just a few clicks
          </VisibleText>
        </div>
        <!--------------------------------------
        | sidebar nav
        ---------------------------------------->
        <DatasetProgress
          :suggestedSteps="suggestedSteps"
          :list="list"
          :readOnly="isReadOnly"
          :isICCSBenchmarkGroup="isICCSBenchmarkGroup"
          :isDatasetCleaningComplete="isDatasetCleaningComplete"
          @updateSuggestions="updateSuggestions"
          @updateList="updateList"
          @connect-dataset="isProjectsCreateModalVisible = true"
        />
        <!--------------------------------------
        | sections
        ---------------------------------------->
        <keep-alive>
          <component
            :is="activeComponent"
            :read-only="isReadOnly"
            :suggestedSteps="suggestedSteps"
            :list="list"
            :detailsForSharingWithBenchmarking="
              detailsForSharingWithBenchmarking
            "
            :isICCSBenchmarkGroup="isICCSBenchmarkGroup"
            :isDatasetCleaningComplete="isDatasetCleaningComplete"
            :datasetWizardStatus="
              this.$store.getters['datasetWizard/getDatasetProject'].status
            "
            @datasetNameChanged="updateBenchmarkShareableDetails"
            @segmentsChanged="updateBenchmarkShareableDetails"
            @updateSuggestions="updateSuggestions"
            @updateList="updateList"
            @open-template-modal="isShowingTemplateModal = true"
            @clearBenchmarks="clearBenchmarks"
          ></component>
        </keep-alive>
      </div>
    </main>

    <!--------------------------------------------
    |
    | PROJECT CREATE MODAL
    |
    --------------------------------------------->
    <ProjectsCreateModal
      v-if="isProjectsCreateModalVisible"
      :refresh="isDatasetCleaningComplete"
      :pre-selected="datasetProject.dataset_id"
      :show="isProjectsCreateModalVisible"
      @close="isProjectsCreateModalVisible = false"
    />
    <!--------------------------------------------
    |
    | UPLOAD TEMPLATE MODAL
    |
    --------------------------------------------->
    <DatasetUploaderTemplateModal
      :show="isShowingTemplateModal"
      @close="isShowingTemplateModal = false"
    />
  </div>
</template>

<script>
import { app } from "@/main.js"
import { useRoute } from "vue-router"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
// Components
import DatasetBenchmarksSelectGroup from "@/components/Dataset/DatasetBenchmarks/DatasetBenchmarksSelectGroup.vue"
import DatasetProgress from "@/components/Dataset/DatasetProgress/DatasetProgress.vue"
import DatasetUploader from "@/components/Dataset/DatasetUploader/DatasetUploader.vue"
import DatasetUploaderTemplateModal from "@/components/Dataset/DatasetUploader/DatasetUploaderTemplateModal.vue"
import DatasetSegments from "@/components/Dataset/DatasetSegments/DatasetSegments.vue"
import DatasetTableView from "@/components/Dataset/DatasetTableView/DatasetTableView.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import ProjectsCreateModal from "@/components/Projects/ProjectsCreateModal/ProjectsCreateModal.vue"

// Mixins
import BenchmarksMixin from "@/components/Dataset/Mixins/benchmarksMixin.js"
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"
// import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Utils
import {
  fetchDataset,
  fetchDatasetProject
} from "@/components/Dataset/Utils/fetch.js"

export default {
  name: "Dataset",
  mixins: [
    BenchmarksMixin,
    ConfirmMixin,
    DatasetMixin,
    DatasetDetailsMixin,
    SharedStatusMixin,
    UserMixin
  ],
  components: {
    DatasetBenchmarksSelectGroup,
    DatasetProgress,
    DatasetUploader,
    DatasetSegments,
    DatasetTableView,
    MessageBlock,
    DatasetUploaderTemplateModal,
    ProjectsCreateModal
  },
  setup() {
    const siteData = reactive({
      title:
        app.config.globalProperties.$theme.theme.pageTitle + "| Dataset Wizard",
      description: app.config.globalProperties.$theme.theme.appDescription
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  data() {
    return {
      route: useRoute(),
      isReadOnly: false,
      noBenchmarkAccess: false,
      datasets: [],
      metaTitle: "Dataset Wizard",
      isProjectsCreateModalVisible: false,
      isShowingTemplateModal: false,
      toRouteCache: null,
      suggestedSteps: [
        {
          name: "benchmarking analysis",
          suggesting: true
        },
        {
          name: "additional dataset details",
          suggesting: true
        }
      ],
      list: [
        {
          name: "Upload your file",
          component: "DatasetUploader",
          statusName: "datasetUploadingCompleted",
          completed: false,
          activated: true
        },
        {
          name: "Clean your data",
          component: "DatasetCleaner",
          statusName: "datasetColumnDetailsCompleted",
          completed: false,
          activated: true
        },
        {
          name: "Add benchmarking",
          component: "DatasetBenchmarks",
          statusName: "benchmarksApproved",
          completed: false,
          optional: true,
          activated: false
        },
        {
          name: "Define your data",
          component: "DatasetSegments",
          statusName: "datasetSegmentsCompleted",
          completed: false,
          optional: true,
          activated: false
        }
      ]
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id) {
      Promise.all([
        fetchDataset(to.params.id),
        fetchDatasetProject(to.query.project_id)
      ])
        .then((datasetData) => {
          this.setData(null, ...datasetData, to)
          next()
        })
        .catch((err) => {
          this.setData(err, null)
          next()
        })
    } else {
      next()
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.deleteIncompleteUpload) {
      await this.$pigeonline.projects.delete(this.deleteIncompleteUpload)
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.resetConfirm()
      next()
    } else if (this.unsavedChanges && this.unsavedChanges.length > 0) {
      this.onUnsavedChanges()
      this.toRouteCache = to
    } else {
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.resetConfirm()
      next()
    }
  },
  async created() {
    // no id in url so setup empty dataset project
    if (
      (!this.$route.params.id && typeof this.$route.params.id == "undefined") ||
      this.$route.params.id == ""
    ) {
      this.setDatasetProject(new BenchmarkingDataset({ name: null }))

      this.setActiveSection("DatasetUploader")
    }
  },
  mounted() {
    if (this.$route.params.id) {
      Promise.all([
        fetchDataset(this.$route.params.id),
        fetchDatasetProject(this.$route.query.project_id)
      ])
        .then((datasetData) => {
          this.setData(null, ...datasetData)
        })
        .catch((err) => {
          this.setData(err)
        })
    }
  },
  computed: {
    isDatasetCleaningComplete() {
      if (Object.keys(this.datasetProject).length > 0) {
        if (
          this.datasetProject.status.datasetUploadingCompleted &&
          this.datasetProject.status.datasetColumnDetailsCompleted
        ) {
          let foundIncomplete = false
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].name === "Add benchmarking") {
              if (this.list[i].activated && !this.list[i].completed) {
                if (!this.datasetProject.status.benchmarksApproved) {
                  foundIncomplete = true
                }
                if (this.isICCSBenchmarkGroup && !this.list[i + 1].completed) {
                  foundIncomplete = true
                }
              }
            } else if (this.list[i].name === "Define your data") {
              if (this.list[i].activated && !this.list[i].completed) {
                if (!this.datasetProject.status.datasetSegmentsCompleted) {
                  foundIncomplete = true
                }
                if (this.isICCSBenchmarkGroup && !this.list[i - 1].completed) {
                  foundIncomplete = true
                }
              }
            }
          }
          if (!foundIncomplete) {
            return true
          }
        }
      }
      return false
    },
    isICCSBenchmarkGroup() {
      // This is a custom logic for the ICCS, as they wish for specific
      // uploading steps
      if (this.benchmarkGroup) {
        if (
          !this.benchmarkGroup.message ||
          this.benchmarkGroup.message !==
            "You do not have permission to perform this action."
        ) {
          if (
            this.benchmarkGroup.created_by &&
            this.benchmarkGroup.created_by.name === "Michal Dziong" &&
            (this.benchmarkGroup.created_by.organization ===
              "Institute for Citizen-Centred Service" ||
              this.benchmarkGroup.created_by.organization === "CitizenFirst")
          ) {
            return true
          }
        }
      }
      return false
    },
    isBenchmarkGroupSelected() {
      if (this.benchmarkGroup) {
        return true
      } else {
        return false
      }
    },
    activeComponent() {
      if (
        this.activeSection === "DatasetBenchmarks" &&
        !this.isBenchmarkGroupSelected
      ) {
        return DatasetBenchmarksSelectGroup
      } else if (
        ["DatasetCleaner", "DatasetBenchmarks", "DatasetTableView"].includes(
          this.activeSection
        )
      ) {
        return DatasetTableView
      } else {
        if (this.activeSection == "DatasetUploader") {
          return DatasetUploader
        }
        if (this.activeSection == "DatasetSegments") {
          return DatasetSegments
        }
      }

      return DatasetTableView
      // else {
      //   return this.activeSection
      // }
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    },
    userDetailsLoading() {
      return this.$store.getters["user/getLoading"]
    },
    detailsForSharingWithBenchmarking() {
      let user_details = {
        userName: "",
        userEmail: "",
        userOrganization: ""
      }
      const profile = this.$store.getters["user/getProfile"]
      if (profile && profile.fullname) {
        user_details.userName = profile.full_name
      }
      const new_email = this.$pigeonline.getUserEmail()
      if (new_email) {
        user_details.userEmail = new_email
      }
      let dataset_details = {}
      if (this.dataset) {
        if (this.dataset.sheet_name) {
          dataset_details.sheet_name = this.dataset.sheet_name
        }
        if (this.dataset.code_book_sheet_name) {
          dataset_details.code_book_sheet_name =
            this.dataset.code_book_sheet_name
        }
        if (this.dataset.created_on) {
          dataset_details.created_on = parseInt(this.dataset.created_on)
        }
        if (this.dataset.name) {
          dataset_details.name = this.dataset.name
        }
        if (this.dataset.survey_details) {
          if (this.dataset.survey_details.surveyEndDate) {
            dataset_details.surveyEndDate =
              this.dataset.survey_details.surveyEndDate
          }
          if (this.dataset.survey_details.surveyStartDate) {
            dataset_details.surveyStartDate =
              this.dataset.survey_details.surveyStartDate
          }
        }
        if (
          this.dataset.segments &&
          Object.keys(this.dataset.segments).length
        ) {
          dataset_details.segments = this.dataset.segments
        }
      }
      return {
        user_details: user_details,
        dataset_details: dataset_details
      }
    }
  },
  methods: {
    onFetchError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "This project either does not exist or you do not have access to it."
      })
      this.setConfirmSourceComponent("dataset")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    onUnsavedChanges() {
      this.setConfirmText({
        btn: "Stay on page",
        secondaryBtn: "Leave page",
        title: "Unsaved Changes",
        message: `You have unsaved changes in ${this.unsavedChanges.join(
          ","
        )}. Are you sure you want to leave this page?`
      })
      this.setConfirmType("warning")
      this.setConfirmSourceComponent("dataset")
      this.setConfirmIsVisible(true)
    },
    async setData(err, dataset = {}, datasetProject = {}, to = {}) {
      if (err) {
        this.onFetchError()
      } else {
        this.isReadOnly = this.checkIsReadOnly(datasetProject)
        this.setDatasetProject(datasetProject)
        this.setDataset(dataset)
        this.setDatasetBenchmarkGroup(dataset, datasetProject)

        await this.loadDatasetDetails(dataset)

        // if we just uploaded a file, go to next step otherwise go to data table
        const component =
          this.$route.query.from === "datasetUploader" ||
          (to && to.query && to.query.from == "datasetUploader")
            ? "DatasetCleaner"
            : "DatasetTableView"
        // update meta title
        this.metaTitle = dataset.name
          ? `${this.dataset.name} - Dataset Wizard | ${this.$theme.theme.pageTitle}`
          : `Dataset Wizard | ${this.$theme.theme.pageTitle}`

        this.setActiveSection(component)

        this.checkIfSuggestionsAlreadyOpen()
      }
    },
    async setDatasetBenchmarkGroup(dataset, datasetProject) {
      // let userLicense = this.$store.getters["user/getLicense"]
      if (dataset.benchmark_group || datasetProject.benchmark_group) {
        /**
         * set chosen group (backwards compatibility - had benchmark group
         * in datasetProject but v1.2+ it is in dataset object)
         **/
        const benchmarkGroupId =
          dataset.benchmark_group || datasetProject.benchmark_group
        this.$_datasetWizardMixin_setBenchmarkingGroup(benchmarkGroupId, false)
      }
      // ICCS currently does not want this to be auto-selected but may change their mind
      // else if (userLicense && ["iccs-admin", "iccs"].includes(userLicense)) {
      //   // set default group based on license
      //   const defaultBenchmarkGroup = await this.$pigeonline.projects.get(
      //     BenchmarkGroup,
      //     {
      //       official_group: LICENSE_TO_BENCHMARK_GROUP[userLicense]
      //     }
      //   )
      //   this.$_datasetWizardMixin_setBenchmarkingGroup(
      //     defaultBenchmarkGroup[0].id,
      //     true
      //   )
      // }
    },
    clearBenchmarks() {
      this.clearBenchmarkGroup()
    },
    updateSuggestions(index, status) {
      this.suggestedSteps[index].suggesting = status
      if (status) {
        if (this.activeSection === "DatasetBenchmarks" && index === 0) {
          this.setActiveSection("DatasetCleaner")
        }
        if (this.activeSection === "DatasetSegments" && index === 1) {
          this.setActiveSection("DatasetCleaner")
        }
      }
    },
    updateList(index, status) {
      this.list[index].activated = status
      if (index === 2) {
        this.setActiveSection("DatasetBenchmarks")
      } else if (index === 3) {
        this.setActiveSection("DatasetSegments")
      }
    },
    checkIfSuggestionsAlreadyOpen() {
      // Note, some legacy get commands have 'name', others do not, this assumes no name but be careful
      // do we still use .name?
      if (this.$theme.theme.name == "iccs") {
        for (let i = 0; i < this.suggestedSteps.length; i++) {
          this.suggestedSteps[i].suggesting = false
        }
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].activated = true
        }
        return
      }
      if (
        this.datasetProject.status.benchmarksApproved ||
        this.datasetProject.status.benchmarksSent
      ) {
        for (let i = 0; i < this.suggestedSteps.length; i++) {
          if (this.suggestedSteps[i].name === "benchmarking analysis") {
            this.suggestedSteps[i].suggesting = false
          }
        }
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].name === "Add benchmarking") {
            this.list[i].activated = true
          }
        }
      }
      if (
        this.$store.getters["datasetWizard/getDatasetProject"].status
          .datasetSegmentsCompleted
      ) {
        for (let i = 0; i < this.suggestedSteps.length; i++) {
          if (this.suggestedSteps[i].name === "additional dataset details") {
            this.suggestedSteps[i].suggesting = false
          }
        }
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].name === "Define your data") {
            this.list[i].activated = true
          }
        }
      }
    },
    // If a change has been made to the dataset, benchmark admins need updated details

    updateBenchmarkShareableDetails() {
      if (this.benchmarks && this.benchmarks.length) {
        for (let i = 0; i < this.benchmarks.length; i++) {
          const cQ = this.clientQuestions.find(
            (c) => c._id.$oid === this.benchmarks[i].client_question_id
          )
          if (!cQ) {
            continue
          }
          this.$_benchmarksMixin_updateMatch(
            cQ,
            this.benchmarks[i].global_question_id,
            this.detailsForSharingWithBenchmarking.dataset_details,
            this.detailsForSharingWithBenchmarking.user_details
          )
        }
      }
    }
  },
  watch: {
    noBenchmarkAccess: function (val) {
      if (val) {
        let foundList = this.list
        for (let i = 0; i < foundList.length; i++) {
          if (foundList[i].name == "Add benchmarking") {
            foundList[i].completed = true
            foundList[i].activated = true
            foundList[i].blockAccess = true
          }
          if (foundList[i].name == "Define your data") {
            foundList[i].completed = true
            foundList[i].activated = true
            foundList[i].blockAccess = true
          }
        }
        this.list = foundList
      }
    },
    confirmIsVisible: function (val) {
      // user clicked "leave page"
      if (
        !val &&
        !this.confirmClickedClose &&
        !this.confirmStatus &&
        this.confirmSourceComponent === "dataset" &&
        this.confirmType === "warning"
      ) {
        this.setUnsavedChanges([])
        this.$router.push({ path: this.toRouteCache.fullPath })
      }

      if (
        !val &&
        this.confirmSourceComponent === "dataset" &&
        this.confirmType === "error"
      ) {
        this.$router.push({ path: "/projects" })
      }
    },
    userDetailsLoading: function () {
      this.isReadOnly = this.checkIsReadOnly(this.datasetProject)
    },
    datasetProject: {
      handler(val) {
        if (val && val.status) {
          this.list.forEach((item, index) => {
            if (this.datasetProject.status[item.statusName])
              this.list[index].completed = true
          })
        }
      },
      deep: true
    }
  }
}
</script>
