<template>
  <div ref="uploading_files_article" class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText> Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1 class="library__h1"><VisibleText>Uploading your data</VisibleText></h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        ResearchAI starts with your data. If you have surveys, CRM data,
        customer complaints, and more, importing it is easy. The process
        involves 2 simple steps:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--step1')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Step 1 - Upload your file</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--step2')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Step 2 - Clean your data</VisibleText>
        </a>
      </li>
    </ul>
    <p>
      <VisibleText>
        Note: there are two additional "optional" cleaning steps that you can
        learn more about:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--step3')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Optional steps - Enable benchmarking</VisibleText>
        </a>
      </li>
    </ul>
    <h2 class="library__h2" ref="goto--step1">
      <VisibleText>Step 1 - Upload your file</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >To upload data, click 'datasets' when looking at the main page. This
        will take you to a list of all datasets uploaded by you or shared to
        you. You should see the below button titled, "upload new data". Click
        this button to proceed.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_uploading-button.png"
      alt="screenshot of datasets view"
    />

    <p>
      <VisibleText
        >For best results, use SPSS file format .sav (this can be exported from
        most survey software). This will make the next steps easier.
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >If you do not have .sav, please download and fill in the following
        template:
      </VisibleText>
    </p>
    <button
      @click="download"
      class="dataset-labeller__download download_button"
    >
      <VisibleText>Download Template</VisibleText>
    </button>
    <h3><VisibleText>Using the template</VisibleText></h3>
    <p>
      <VisibleText
        >Respondents are in rows, and questions are in columns:
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--40"
      src="/assets/userguide__upload02.png"
      alt="screenshot of excel template respondent row and question columns"
    />
    <p>
      <VisibleText>
        Data can be scale (such as 1 to 5), categorical (where a number
        represents one group of users, such as demographics), or text (such as a
        customer complaint).
      </VisibleText>
    </p>
    <p>
      <VisibleText
        >Add question wording to match your question code. If you do not have
        specific question codes, just add Q1, Q2, Q3 etc.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__upload03.png"
      alt="screenshot of excel template code and question wording"
    />
    <h2 class="library__h2" ref="goto--step2">
      <VisibleText>Step 2 - Clean your data</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >The next step is to clean your data. In this example you can see a
        question has been set to "5-point scale"
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__upload04.png"
      alt="screenshot of a question with data type circled"
    />

    <p>
      <VisibleText>
        You should see a button that will suggest data-types for you.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_suggested-data-type.png"
      alt="screenshot of get suggested data types button"
    />

    <p>
      <VisibleText>
        But be careful, this will require you to scroll through each row to
        'verify' that it matched correctly. Be sure to correct any incorrect
        matches, like text questions detected as categories. Or scale questions
        not getting picked up as scales.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--40"
      src="/assets/userguide_confirming-data-types-count.png"
      alt="screenshot of confirmed data types"
    />

    <p>
      <VisibleText
        >You can also recode your values or label them by clicking 'bulk edit'.
        It is a very good idea to have Question Code filled with a short name,
        and Question Text filled with a long name.</VisibleText
      >
    </p>
    <img
      class="screenshot screenshot--10"
      src="/assets/userguide_bulk-edit-button.png"
      alt="screenshot of bulk edit button"
    />
    <p>
      <VisibleText>
        If at least one data type has been set, the dataset should be ready for
        analysis. In the below example, Steps 1 and 2 are complete, and so you
        can now "Connect dataset to project". Press this to proceed.
      </VisibleText>
    </p>

    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_dataset-cleaning-complete.png"
      alt="screenshot of dataset with completed cleaning"
    />
    <p>
      <VisibleText>
        Try to have at least a Text question, a few Scale questions, and a few
        Categorical questions that denote 'segments' like demographics.
      </VisibleText>
    </p>

    <h2 class="library__h2" ref="goto--step3">
      <VisibleText>Optional steps - Enable benchmarking</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        In the above example, you may notice two tips under Step 2, one
        suggesting to "Add benchmarking analysis", and the other suggesting to
        "Add dataset details". If you click these but do not want these optional
        steps, simply click "remove step".
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--15"
      src="/assets/userguide_optional-steps.png"
      alt="screenshot of additional suggested steps"
    />

    <p>
      <VisibleText>
        But what exactly are these optional steps for? Both of these enable rich
        benchmarking analysis. By matching individual questions from your
        dataset to a larger public benchmarking database you can compare
        yourself to peers.
        <a
          href="javascript:void(0)"
          v-if="isUserGuide"
          @click="$emit('new-page', 'BenchmarkingAnalysis')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Learn more about benchmarking here. </VisibleText>
        </a>
      </VisibleText>
    </p>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import axios from "axios"

export default {
  name: "uploading_files",
  setup() {
    const siteData = reactive({
      title: "Uploading and cleaning your data in ResearchAI",
      description:
        "A guide for uploading and cleaning data like surveys, customer feedback, and text"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    },
    download() {
      axios({
        url: `${window.location.origin}/files/codebook-template.xlsx`,
        method: "GET",
        responseType: "blob"
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        )
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", "codebook-template.xlsx")
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>
