<template>
  <div id="app" :style="borderStyle">
    <TranslationChecker />
    <AccountMessages
      :status-name="accountStatusName"
      v-if="
        !this.showReLoginModal &&
        accountStatusName.length > 0 &&
        this.$route.path !== '/' &&
        this.$route.path !== '/login' &&
        !this.$route.path.includes('/register') &&
        !this.$route.path.includes('/library')
      "
    />

    <!--------------------------------------------
    |
    | HEADER AND BANNERS
    |
    --------------------------------------------->
    <TheHeader
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :isAuthenticated="$pigeonline.userIsAuthenticated()"
      @user-guide-expanded="isUserGuideExpanded = true"
      v-if="
        !this.showReLoginModal &&
        this.$route.path !== '/' &&
        this.$route.path !== '/academia' &&
        this.$route.path !== '/government' &&
        this.$route.path !== '/MENA' &&
        this.$route.path !== '/citizenfirst-analytics' &&
        this.$route.path !== '/citizenfirstanalytics'
      "
    />
    <!--------------------------------------------
    |
    | MAIN CONTENT
    |
    --------------------------------------------->
    <router-view
      v-if="(!this.showReLoginModal && this.tokenUpdated) || this.skipToken"
      @user-guide-expanded="isUserGuideExpanded = true"
      @open-article="guideArticle = $event"
    ></router-view>
    <!--------------------------------------------
    |
    | USER GUIDE
    |
    --------------------------------------------->
    <UserGuide
      v-if="!this.showReLoginModal"
      id="user-guide"
      :status="isUserGuideExpanded"
      :guideArticle="guideArticle"
      @close="isUserGuideExpanded = false"
    />

    <!--------------------------------------------
    |
    | CONFIMRATION MODAL
    |
    --------------------------------------------->
    <Modal
      header-tag="h1"
      :class="['confirmation-modal', `confirmation-modal--${confirmType}`]"
      :show="confirmIsVisible"
      @close="closeConfirmationModal"
    >
      <template #header>
        <VisibleText>{{ confirmText.title }}</VisibleText>
      </template>
      <VisibleText>{{ confirmText.message }}</VisibleText>
      <template #footer>
        <div class="confirmation-modal__footer">
          <button
            type="button"
            class="confirmation-modal__btn cancel"
            @click.stop="cancelled"
            v-if="
              confirmType === 'delete' ||
              confirmType === 'warning' ||
              confirmType === 'merge-delete'
            "
          >
            <VisibleText>
              {{ confirmText.secondaryBtn || "Cancel" }}
            </VisibleText>
          </button>
          <button
            type=" button"
            class="confirmation-modal__btn confirm"
            @click.stop="confirmed"
          >
            <VisibleText>{{ confirmText.btn || "Yes" }}</VisibleText>
          </button>
        </div>
      </template>
    </Modal>
    <ReLoginModal
      :email="this.$pigeonline.getUserEmail()"
      :show="showReLoginModal"
      @authenticated="setAuthenticated"
      @goToLogin="logUserOut"
    >
    </ReLoginModal>
  </div>
</template>

<script>
// import Vue from "vue"
//const Vue = window.vue
import { app } from "@/main.js"

// Components
import AccountMessages from "@/components/AccountMessages.vue"
import TheHeader from "@/components/TheHeader.vue"
import ReLoginModal from "@/components/ReLoginModal.vue"
import Modal from "@/components/UI/Modal.vue"
import UserGuide from "@/components/UserGuide/UserGuide.vue"
import TranslationChecker from "@/components/Translation/TranslationChecker"

// Helpers
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { mapGetters } from "vuex"
import { axios } from "@pigeonline/core"

// Mixin
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"
import { handleError } from "vue"

export default {
  name: "app",
  mixins: [ConfirmMixin, UserMixin],
  components: {
    AccountMessages,
    TheHeader,
    Modal,
    UserGuide,
    TranslationChecker,
    ReLoginModal
  },
  data() {
    return {
      borderStyle: "border-top-width: 0px",
      isUserGuideExpanded: false,
      guideArticle: "",
      reloginModal: null,
      axiosResponseInterceptor: null,
      showReLoginModal: false,
      tokenUpdated: false,
      skipToken: false,
      isLoading: true
    }
  },
  async created() {
    const refreshAuthLogic = (failedRequest) => {
      // if a request failed without requiring auth headers,
      // simply resolve the promise
      if (failedRequest.config.skipAuthHeaders) return Promise.resolve()
      return this.$pigeonline
        .refresh()
        .catch(() => {
          const email = this.$pigeonline.getUserEmail()

          if (email == "") {
            // Log user out - user session already null
            alert("Expired session. Redirecting to login...")
            this.logUserOut()
          } else {
            this.showReLoginModal = true
            return Promise.resolve()
          }
        })
        .then((access) => {
          if (!access) {
            //throw new Error("App.vue:refreshAuthLogic: Access token null")
            return Promise.resolve()
          }
          failedRequest.response.config.headers["Authorization"] =
            this.$pigeonline.getAuthHeader()
          return Promise.resolve()
        })
        .finally(() => {
          this.$store.dispatch("loader/setLoading", false)
        })
    }

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      skipWhileRefreshing: false
    })

    // enable error catching interceptor
    this.enableInterceptor()
  },
  computed: {
    ...mapGetters("loader", {
      loading: "getLoading"
    }),
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    },
    accountStatusName() {
      // checks for full-name
      if (!this.profile) {
        return ""
      }
      if (!this.profile.fullname || !this.profile.fullname.length) {
        return "setupIncomplete"
      }
      if (
        !this.$pigeonline.userIsAuthenticated() ||
        this.showReLoginModal == false
      ) {
        return ""
      }

      return ""
    }
  },
  mounted() {
    // Live chat script from RocketChat
    // TODO explore if we want to continue this, we'd also need to rebuild it for vue3
    // ;(function (w, d, s, u) {
    //   w.RocketChat = function (c) {
    //     w.RocketChat._.push(c)
    //   }
    //   w.RocketChat._ = []
    //   w.RocketChat.url = u
    //   var h = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s)
    //   j.async = true
    //   j.src =
    //     "https://chat.pigeonline.io/livechat/rocketchat-livechat.min.js?_=201903270000"
    //   h.parentNode.insertBefore(j, h)
    // })(window, document, "script", "https://chat.pigeonline.io/livechat")
  },
  methods: {
    skipToMainContent() {
      document.getElementById("main-content").focus()
    },
    goToLogin() {
      this.logUserOut()
    },
    async setAuthenticated() {
      await this.$pigeonline.getAuthToken()
      this.showReLoginModal = false
      this.tokenUpdated = true
      //this.destroyReloginModal()
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    confirmed() {
      this.setConfirmIsVisible(false)
      this.setConfirmStatus(true)
      this.setConfirmClickedClose(false)
    },
    cancelled() {
      this.setConfirmIsVisible(false)
      this.setConfirmStatus(false)
      this.setConfirmClickedClose(false)
    },
    closeConfirmationModal() {
      this.setConfirmIsVisible(false)
      this.setConfirmClickedClose(true)
    },
    destroyReloginModal() {
      this.reloginModal.$destroy()
      this.reloginModal.$el.remove()
      this.reloginModal = null
    },
    logUserOut() {
      this.$store.dispatch("loader/setLoading", false)
      this.$pigeonline.logout()
      this.$router.go({ name: "login" })
    },
    /************
     *
     * Interceptors
     *
     ***********/
    enableInterceptor() {
      this.axiosResponseInterceptor = axios.interceptors.response.use(
        (response) => {
          this.tokenUpdated = true
          return response
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code &&
            error.response.data.code == "token_not_valid"
          ) {
            return Promise.reject(error)
          }

          this.$store.dispatch("loader/setLoading", false)
          let skip = false
          if (
            error.response &&
            error.response.request &&
            error.response.request.responseURL &&
            error.response.request.responseURL.includes(
              "analysis/text/keywords"
            )
          ) {
            skip = true
          }

          if (error.response && !skip) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            switch (error.response.status) {
              case 500:
                this.$router.replace({
                  path: "/500"
                })
                break
              case 403:
                if (error.response.data && error.response.data.detail) {
                  // eslint-disable-next-line
                  console.log(error.response.data.detail)
                }
                break
            }
          }
          return Promise.reject(error)
        }
      )

      axios.interceptors.request.use(
        function (config) {
          config.headers["Accept-Language"] =
            window.localStorage.getItem("apiLanguage") || "en"
          return config
        },
        function (error) {
          return Promise.reject(error)
        }
      )
    },
    disableInterceptor() {
      window.axios.interceptors.request.eject(this.axiosResponseInterceptor)
    }
  },
  watch: {
    loading: function (val) {
      if (val) {
        this.borderStyle = "border-top-width: 0px"
      } else {
        setTimeout(() => {
          this.borderStyle = "border-top-width: 6px"
        }, 200)
      }
    },
    async $route() {
      if (
        this.$route.path !== "/login" &&
        this.$route.path !== "/logout" &&
        !this.$route.path.includes("/register") &&
        this.$route.path !== "/" &&
        this.$route.path !== "/academia" &&
        this.$route.path !== "/government" &&
        this.$route.path !== "/MENA" &&
        this.$route.path !== "/citizenfirst-analytics" &&
        this.$route.path !== "/citizenfirstanalytics" &&
        !this.$route.path.includes("/library")
      ) {
        // Check for authentication on these pages

        // Attempt to get profile
        const profile = await this.$pigeonline.profile.get()

        // update user store if already logged in
        if (this.$pigeonline.userIsAuthenticated() && this.tokenUpdated) {
          await this.setUserDetails()
        }
        if (!this.$pigeonline.userIsAuthenticated()) {
          this.showReLoginModal = true
        }
      } else {
        this.skipToken = true
      }
    }
  }
}
</script>
