<template>
  <div class="user-analytics__session">
    <div class="user-analytics__session__device">
      <div class="user-analytics__session__device__content">
        <div class="user-analytics__font--bold">
          <VisibleText>Browser</VisibleText>:
        </div>
        {{ userSession.deviceInfo.browserCodeName }}
      </div>
      <div class="user-analytics__session__device__content">
        <div class="user-analytics__font--bold">
          <VisibleText>Language</VisibleText>:
        </div>
        {{ userSession.deviceInfo.language }}
      </div>
      <div class="user-analytics__session__device__content">
        <div class="user-analytics__font--bold">
          <VisibleText>Operating System</VisibleText>:
        </div>
        {{ userSession.deviceInfo.platform }}
      </div>
    </div>
    <div class="user-analytics__session__journey">
      <div
        class="user-analytics__session__journey__content"
        v-for="(session, index) in userSession.sessions"
        :key="index"
      >
        <div class="user-analytics__font--bold user-analytics__font--inline">
          {{ formatDateTime(session.sessionStart, $store.getters.getLanguage) }}
          <VisibleText>Session Start</VisibleText>
        </div>
        <div v-if="session.referrer">
          <VisibleText>Referrer</VisibleText>:
          {{ session.referrer }}
        </div>
        <div v-if="session.events.length">
          <div
            class="user-analytics__session__journey__content"
            v-for="(event, second_index) in session.events"
            :key="second_index"
          >
            {{ formatDateTime(event.timestamp, $store.getters.getLanguage) }}
            <span v-if="event.routeName">
              {{ event.routeName.toString() }}
            </span>
            <span v-if="event.routePath">
              {{ event.routePath.toString() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from "@/utils/mixins/userMixin.js"
import moment from "moment"
export default {
  name: "UserSession",
  mixins: [FiltersMixin],
  props: {
    userSession: { type: Object }
  },
  methods: {
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  }
}
</script>
