<template>
  <header class="header">
    <a
      class="sr-only"
      href="#main-content"
      id="skip-link-main"
      @click.prevent="skipToMainContent"
    >
      <VisibleText> Skip to main content </VisibleText>
    </a>
    <div class="logo logo--nonresponsive">
      <router-link to="/" exact aria-label="home">
        <img
          class="logo-image"
          style="width: 6rem; height: 3rem; margin-top: 1.5rem"
          src="/assets/landing/clients/cf_logo_color.png"
          alt=""
          v-if="$theme.theme.name == 'iccs'"
        />
        <img
          class="logo-image"
          src="/assets/landing/research-ai-logo.svg"
          alt=""
          v-else
        />
      </router-link>
    </div>
    <div class="alt-logo alt-logo--nonresponsive">
      <router-link to="/" exact aria-label="home">
        <img class="alt-logo-image" src="/assets/bird-logo.png" alt="" />
      </router-link>
    </div>
    <!-- logo -->

    <!-- main navigation -->
    <template v-if="isAuthenticated">
      <nav
        aria-label="primary"
        class="navigation"
        :class="{ 'navigation__wrapper-active': isNavExpanded }"
      >
        <router-link
          to="/projects"
          class="navigation__item-link"
          :class="isActiveProjectsLink ? 'navigation__item-active' : ''"
        >
          <VisibleText>Projects</VisibleText>
        </router-link>
        <router-link
          to="/datasets"
          class="navigation__item-link"
          :class="isActiveDatasetsLink ? 'navigation__item-active' : ''"
        >
          <VisibleText>Datasets</VisibleText>
        </router-link>
        <div :class="['navigation__item']" id="navigation__item-link">
          <button
            class="guideLink"
            @click="$emit('user-guide-expanded')"
            style="padding-top: 0.5rem"
          >
            <SvgIconDecorative icon="questionCircle" style="height: 1.25rem" />
            <VisibleText>Help/Guide</VisibleText>
          </button>
        </div>
        <!--  <label for="lang-select">
           <SvgIconDecorative icon="translate" style="height: 1.25rem" />
            <span class="sr-only"><VisibleText>language</VisibleText></span>
          </label>
          <select
            class="select"
            id="lang-select"
            @change="changeLanguage($event)"
          >
            <option
              value="en"
              :data-parent="$options.name"
              :selected="$store.getters['getLanguage'] === 'en'"
            >
              {{ translate("English", $options.name) }}
            </option>
            <option
              value="fr"
              :data-parent="$options.name"
              :selected="$store.getters['getLanguage'] === 'fr'"
            >
              {{ translate("French", $options.name) }}
            </option>
          </select> -->
      </nav>
    </template>

    <div
      :class="[
        'navigation__item',
        'navigation__item--profile',
        {
          'navigation__item-active': $route.name === 'accountProfile'
        }
      ]"
      id="profile-nav"
      v-click-outside="closeAccountSubmenu"
      v-if="isAuthenticated"
    >
      <button
        aria-haspopup="true"
        class="navigation__toggle"
        id="profile-nav-btn"
        ref="profile-nav"
        :aria-expanded="isAccountSubmenuExpanded.toString()"
        @click="toggleAccountSubmenu"
      >
        <span class="sr-only">
          <VisibleText>show my account submenu</VisibleText>
        </span>
        <SvgIconMeaningful
          icon="profile"
          title="profile options"
          style="height: 1.7rem; width: 1.7rem"
        />
        <SvgIconDecorative icon="chevronDown" style="width: 1rem" />
      </button>
      <transition name="fade">
        <div
          id="profile-nav-items"
          role="menu"
          ref="profile-nav-submenu"
          v-show="isAccountSubmenuExpanded"
        >
          <router-link
            to="/account/profile"
            class="profile-nav__item"
            role="menuitem"
            tabindex="-1"
            @click="closeAccountSubmenu"
            @keydown="focusNextItem"
          >
            <VisibleText>Profile</VisibleText>
          </router-link>
          <div v-if="permissions.includes('benchmarking-manager')">
            <router-link
              to="/benchmark-manager"
              class="profile-nav__item"
              role="menuitem"
              tabindex="-1"
              @click="closeAccountSubmenu"
              @keydown="focusNextItem"
            >
              <VisibleText>Benchmark Manager</VisibleText>
            </router-link>
          </div>
          <router-link
            to="/custom-text-model"
            class="profile-nav__item"
            role="menuitem"
            tabindex="-1"
            @click="closeAccountSubmenu"
            @keydown="focusNextItem"
          >
            <VisibleText>Custom Text Model</VisibleText>
          </router-link>
          <router-link
            to="/pigeon-settings"
            class="profile-nav__item"
            role="menuitem"
            tabindex="-1"
            @click="closeAccountSubmenu"
            @keydown="focusNextItem"
            v-if="isPigeon"
          >
            <VisibleText>Pigeon Settings</VisibleText>
          </router-link>
          <button
            class="profile-nav__item"
            id="change_language"
            role="menuitem"
            type="button"
            @click="onClickTranslationChecker()"
            @keydown="focusNextItem"
            v-if="isPigeon"
            v-show="false"
          >
            <VisibleText>
              Translation checker {{ changeLangDebugStatus }}
            </VisibleText>
          </button>
          <router-link
            to="/logout"
            class="profile-nav__item"
            id="logout"
            role="menuitem"
            tabindex="-1"
            @keydown="focusNextItem"
          >
            <VisibleText>Logout</VisibleText>
          </router-link>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

// Icons
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

//mixins
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "TheHeader",
  components: {
    SvgIconDecorative,
    SvgIconMeaningful
  },
  mixins: [UserMixin],
  props: {
    isAuthenticated: { type: Boolean }
  },
  data() {
    return {
      isNavExpanded: false,
      isAccountSubmenuExpanded: false,
      languageSelectOpen: false,
      changeLangDebugStatus: "on"
    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    isActiveDatasetsLink() {
      const validNames = ["datasets", "dataset"]
      return validNames.includes(this.$route.name)
    },
    isActiveProjectsLink() {
      const validNames = [
        "projects",
        "project",
        "data",
        "analysis",
        "report",
        "report-edit"
      ]
      return validNames.includes(this.$route.name)
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    skipToMainContent() {
      document.getElementById("main-content").focus()
    },
    /**
     * Navigation keyboard helpers
     */
    focusNextItem($e) {
      switch ($e.key) {
        case "Down":
        case "ArrowDown":
          if ($e.target.nextElementSibling) {
            $e.target.nextElementSibling.focus()
          } else {
            this.$refs["profile-nav-submenu"].firstElementChild.focus()
          }
          break
        case "Up":
        case "ArrowUp":
          if ($e.target.previousElementSibling) {
            $e.target.previousElementSibling.focus()
          } else {
            this.$refs["profile-nav-submenu"].lastElementChild.focus()
          }
          break
        case "Esc":
        case "Escape":
          this.$refs["profile-nav"].focus()
          this.isAccountSubmenuExpanded = false
          break
        case "Tab":
          this.isAccountSubmenuExpanded = false
          break
        default:
          return
      }
      $e.preventDefault()
    },
    // custom directive--v-click-outside needs a method
    closeAccountSubmenu() {
      this.isAccountSubmenuExpanded = false
    },
    toggleAccountSubmenu() {
      this.isAccountSubmenuExpanded = !this.isAccountSubmenuExpanded
    },
    onClickTranslationChecker() {
      this.setLanguageDebug(this.changeLangDebugStatus)
      this.changeLangDebugStatus =
        this.$store.getters["getLanguageDebug"] === "on" ? "off" : "on"
    },
    async changeLanguage(language) {
      const profile = await this.$pigeonline.profile.save({
        languagePreference: language.target.value
      })
      this.$store.dispatch("user/setProfile", profile)
    },
    setLangAttr(language) {
      document.documentElement.setAttribute("lang", language)
    },
    setLangValue(language) {
      if (!language) return
      // store language to localStorage and vue store
      this.setLanguage(language)
      window.localStorage.setItem("apiLanguage", language)
    }
  },
  watch: {
    "profile.languagePreference": {
      handler: function (langVal) {
        const val = langVal ? langVal : "en"
        this.setLangValue(val)
        this.setLangAttr(val)
      }
    },
    isAccountSubmenuExpanded: function () {
      this.$refs["profile-nav"].setAttribute(
        "aria-expanded",
        this.isAccountSubmenuExpanded
      )
      if (this.isAccountSubmenuExpanded) {
        this.$nextTick(() =>
          this.$refs["profile-nav-submenu"].firstElementChild.focus()
        )
      }
    }
  }
}
</script>
