<template>
  <DialogPopup
    class="theme__popup-dialog"
    :style="
      avoidLeftOffset
        ? 'position: absolute; width: 40vw; max-width: 40vw; left: 0;'
        : 'position: absolute; width: 40vw; max-width: 40vw'
    "
    header-tag="h4"
    :show="showThematicInfo"
    @close="closeGuide"
  >
    <template #header>
      <div
        style="
          display: flex;
          top: 0;
          flex-direction: row;
          text-align: left;
          font-size: 1rem;
          text-transform: none;
        "
      >
        Learn more about thematic analysis
      </div>
    </template>
    <template #body>
      <div>
        <div v-if="thematicPagination.page == 1">
          <p>
            <VisibleText
              >Thematic analysis is one of the most reliable and common forms of
              qualitative (text) analysis. It emphasizes the finding and
              interpreting of meaning from text. It differs from other text
              analysis in a few key ways:
            </VisibleText>
          </p>
          <ul>
            <li>
              It is human-centric, where a researcher must interpret and
              validate themes found in text.
            </li>
            <li>It involves the finding of discrete and specific themes.</li>
            <li>
              When done right, themes and sub-themes should be tied to empirical
              evidence, such as retraceable to very specific 'tags' or 'codes'
              of text that reflect a key theme, or notes from a researcher.
            </li>
            <li>
              <a href="https://en.wikipedia.org/wiki/Thematic_analysis"
                >Learn more about thematic analysis</a
              >
            </li>
          </ul>
        </div>
        <div v-if="thematicPagination.page == 2">
          <p>
            <VisibleText
              >ResearchAI supports thematic analysis by letting you find themes
              in text. This can be done by:</VisibleText
            >
          </p>
          <ul>
            <li>
              Finding keywords in specific responses that may reflect a theme.
              E.g., 'staff' appearing in text could signal someone is talking
              about staff.
            </li>
            <li>
              Finding sentences that are similar to sentences a researcher has
              already interpreted as meaningful.
            </li>
            <li>
              Leaving notes that reflect interpreted themes that cannot
              otherwise be captured by text matches.
            </li>
          </ul>
        </div>
        <div v-if="thematicPagination.page == 3">
          <p>
            <VisibleText
              >Thematic analysis, as a human-centric analysis, needs to allow a
              researcher to correct mathematical errors:</VisibleText
            >
          </p>
          <ul>
            <li>Keyword matches can be ignored</li>
            <li>Can ignore similar sentence matches that are not accurate</li>
            <li>
              Notes can allow for more contextual analysis not tied to
              specifical algorithmic responses
            </li>
          </ul>
        </div>
        <div v-if="thematicPagination.page == 4">
          <p>
            ResearchAI uses a special value called 'coverage' which counts
            whether an individual row of text (say a response from a user)
            contains at least one keyword match, similar sentence, or researcher
            note, and excludes keywords or similar sentences that a researcher
            determined was not accurate.
          </p>
          <!-- TODO add examples and flesh out a related article -->
        </div>

        <div
          class="theme__popup-pagination"
          v-show="thematicPagination.totalPages > 1"
        >
          <span class="details">
            {{ translate("Page", $options.name) }}
            {{ thematicPagination.page }}
            {{ translate("of", $options.name) }}
            {{ thematicPagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="thematicPagination.page === 1"
              @click="onClickPrevPageSentiments"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="
                thematicPagination.page === thematicPagination.totalPages
              "
              @click="onClickNextPageSentiments"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
      </div>
    </template>
  </DialogPopup>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"

// Mixins
import textAnalysisMixin from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/Mixins/textAnalysisMixin.js"

export default {
  name: "ThematicAnalysisMiniGuide",
  components: {
    DialogPopup
  },
  mixins: [textAnalysisMixin],
  props: {
    showThematicInfo: {
      type: Boolean,
      default: () => false
    },
    avoidLeftOffset: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      localShowThematicInfo: false,
      thematicPagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 4,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  methods: {
    onClickNextPageSentiments() {
      if (this.thematicPagination.page === this.thematicPagination.totalPages)
        return
      this.thematicPagination.page = this.thematicPagination.page + 1
      this.setPaginationDetailsSentiments(
        this.thematicPagination.startIndx + this.thematicPagination.limit,
        this.thematicPagination.endIndx + this.thematicPagination.limit
      )
    },
    onClickPrevPageSentiments() {
      if (this.thematicPagination.page === 1) return
      this.thematicPagination.page = this.thematicPagination.page - 1
      this.setPaginationDetailsSentiments(
        this.thematicPagination.startIndx - this.thematicPagination.limit,
        this.thematicPagination.endIndx - this.thematicPagination.limit
      )
    },
    setPaginationDetailsSentiments(start, end) {
      this.thematicPagination.startIndx = start
      this.thematicPagination.endIndx = end
    },
    closeGuide() {
      this.$emit("closingGuide")
      this.localShowThematicInfo = false
    }
  },
  created() {
    if (this.showThematicInfo) {
      this.localShowThematicInfo = true
    }
  },
  watch: {
    showThematicInfo(newVal) {
      if (newVal) {
        this.localShowThematicInfo = true
      } else {
        this.localShowThematicInfo = false
      }
    }
  }
}
</script>
