<template>
  <div class="response-list" v-if="responses">
    <div
      :ref="responseListRef"
      class="response-list__wrapper"
      v-if="responsesPaginated.length > 0"
    >
      <ResponseListItem
        tabindex="0"
        class="response-list__item"
        v-for="item in responsesPaginated"
        :key="item.idx"
        :item="item"
        :propReadOnly="propReadOnly"
        :datasetIDQuestions="datasetIDQuestions"
        :client-question-id="clientQuestionId"
        :is-hidden="hiddenComments.includes(item.id)"
        :highlight-items="getHighlightItemsMethod(item)"
        :textAnalysisObject="textAnalysisObject"
        :hasFetchedLemmas="hasFetchedLemmas"
        :hasFetchedSentiments="hasFetchedSentiments"
        :hasFetchedSentences="hasFetchedSentences"
        :sortedThemes="sortedThemes"
        @showSentimentDetails="$emit('showSentimentDetails', $event)"
        @keyword="onKeyword"
        @note="onNote"
        @search="onSearch"
        @remove-note="$emit('remove-note', $event)"
        @remove-keyword="$emit('remove-keyword', $event)"
        @ban-response="$emit('ban-response', $event)"
        @adjust-sentiment="$emit('adjust-sentiment', $event)"
        @toggle-response-hide="$emit('toggle-response-hide', $event)"
        @runSortAndSelect="$emit('runSortAndSelect')"
        @updateTextObject="$emit('updateTextObject', $event)"
        @updateTheseThemes="$emit('updateTheseThemes', $event)"
        @find-similar="findSimilar($event)"
      />
      <div class="response-list__load-more">
        <button
          class="response-list__load-more-btn"
          v-if="isLoadMoreBtnVisible"
          @click.stop="handleLoadMoreEvent"
        >
          <VisibleText>Load more responses</VisibleText>
        </button>
        <VisibleText v-else>End of response list</VisibleText>
      </div>
    </div>
    <div class="response-list__empty-wrapper" v-show="responses.length === 0">
      <p
        class="response-list__empty-message"
        v-if="!showResponseListLoadingSpinner"
      >
        <VisibleText>
          No responses found. Please try changing the filters, use a new search
          keyword or select a different text question.
        </VisibleText>
      </p>
    </div>

    <!-- Modals -->
    <!-- This is the same as ThemesAdd -->
    <SelectedTextModal
      ref="MainSentimentThemesAddRef"
      :type="selectedTextModalType"
      :propReadOnly="propReadOnly"
      :textAnalysisObject="textAnalysisObject"
      :selection="selection"
      :show="isSelectedTextModalVisible"
      :selectedTextModalResponseId="selectedTextModalResponseId"
      :hasFetchedLemmas="hasFetchedLemmas"
      :hasFetchedSentences="hasFetchedSentences"
      :includeSmartCoverage="includeSmartCoverage"
      :sortedResponsesLength="sortedResponsesLength"
      :sortedThemes="sortedThemes"
      @closeModal="closeModal"
      @updateTextObject="$emit('updateTextObject', $event)"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
    />
    <CreateNoteModal
      ref="MainSentimentCreateNoteRef"
      v-if="textAnalysisObject"
      :allResponses="responses"
      :textAnalysisObject="textAnalysisObject"
      :selection="selection"
      :propReadOnly="propReadOnly"
      :show="isCreateNoteModalVisible"
      :sortedThemes="sortedThemes"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      @closeModal="closeModal"
      @updateTextObject="$emit('updateTextObject', $event)"
    />

    <SimilarSentencesPopUp
      ref="NotesSimilarSentencesPopup"
      :source="'ResponseList'"
      :textAnalysisObject="textAnalysisObject"
      :sortedThemes="sortedThemes"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      @selectThisResponse="$emit('selectThisResponse', $event)"
    />
  </div>
</template>

<script>
// Components
import ResponseListItem from "./ResponseListItem.vue"
import SimilarSentencesPopUp from "../../Components/SimilarSentencesPopUp.vue"

// Mixins
import SelectTextMixin from "../Mixins/selectTextMixin.js"
import ResponseListPaginationMixin from "../Mixins/responseListPaginationMixin.js"

export default {
  name: "ResponseList",
  mixins: [SelectTextMixin, ResponseListPaginationMixin],
  components: {
    ResponseListItem,
    SimilarSentencesPopUp
  },
  props: {
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    isSentiment: {
      type: Boolean,
      default: () => false
    },
    responses: {
      type: Array,
      default: () => []
    },
    clientQuestionId: {
      type: String,
      default: () => {}
    },
    hiddenComments: {
      type: Array,
      default: () => []
    },
    getHighlightItemsMethod: {
      type: Function
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    /* paginated responses - depends on responseListPaginationMixin.js */
    responsesPaginated: function () {
      if (!this.responses) {
        return []
      }
      const newResponses = this.deepCloneObj(this.responses)
      if (this.numResponsesToDisplay < newResponses.length) {
        return newResponses.slice(0, this.numResponsesToDisplay)
      }

      return newResponses
    },
    sentimentSortOrder: function () {
      return this.textAnalysisObject.sentimentSortOrder
    },
    showResponseListLoadingSpinner: function () {
      return this.$store.getters[
        "analysisText/getShowResponseListLoadingSpinner"
      ]
    },
    isLoadMoreBtnVisible: function () {
      if (!this.responses) {
        return false
      }
      return this.responsesPaginated.length < this.responses.length
    }
  },
  mounted() {
    this.isComponentMounted = true
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    refreshThemesFromParent() {
      if (this.$refs.MainSentimentThemesAddRef) {
        this.$refs.MainSentimentThemesAddRef.refreshThemesFromParent()
      }
      if (this.$refs.MainSentimentCreateNoteRef) {
        this.$refs.MainSentimentCreateNoteRef.refreshThemesFromParent()
      }
    },
    handleLoadMoreEvent: function () {
      if (!this.isLoadMoreBtnVisible) return
      this.loadMore()
    },
    findSimilar(action) {
      if (this.$refs["NotesSimilarSentencesPopup"]) {
        this.$refs["NotesSimilarSentencesPopup"].forceOpen(action)
      }
    }
  },
  watch: {
    responses: {
      immediate: true,
      handler: function (val) {
        if (!val || !Array.isArray(val)) return
        this.resetResponseListPagination()
      }
    },
    sentimentSortOrder: function () {
      this.$refs[this.responseListRef].scrollTop = 0
    }
  }
}
</script>
