<template>
  <section id="project-analysis">
    <div
      style="display: flex; width: 100%; justify-content: space-between"
      v-if="hasMissingAnalysis"
    >
      <div
        v-if="tabMode == 'showAvailable'"
        style="margin-left: auto; padding: 0 0.5em 0.5em 0.5em"
      >
        <button
          @click="changeTabMode('showAll')"
          style="background-color: transparent; border: none"
        >
          <VisibleText>Show all analysis</VisibleText>
        </button>
      </div>
      <div
        v-if="tabMode == 'showAll'"
        style="margin-left: auto; padding: 0 0.5em 0.5em 0.5em"
      >
        <button
          @click="changeTabMode('showAvailable')"
          style="background-color: transparent; border: none"
        >
          <VisibleText>Show only available analysis</VisibleText>
        </button>
      </div>
    </div>
    <div v-if="!hasMissingAnalysis" style="margin-top: 1rem"></div>
    <div style="display: flex; width: 100%; font-size: 1rem">
      <div style="display: flex; flex-direction: row; width: 100%">
        <!-- *** ToC *** -->
        <div
          v-show="!showToC"
          style="
            border-radius: 0.5em;
            border: 1px solid transparent;
            border-color: var(--outline-light-colour);
            display: flex;
            margin: 0;
            margin-left: -0.5rem;
            width: 2.5%;
            background-color: var(--base-ui-colour);
            position: absolute;
          "
        >
          <button
            style="
              transform: rotate(-90deg);
              display: flex;
              white-space: nowrap;
              margin: auto;
              background-color: transparent;
              border-color: transparent;
              width: 1em;
              margin-top: 10em;
              height: max-content;
            "
            @click="openToC()"
          >
            open table of contents
          </button>
        </div>
        <div
          v-show="showToC"
          style="
            border-radius: 0.5em;
            border: 1px solid transparent;
            border-color: var(--outline-light-colour);
            display: flex;
            max-width: 20%;
            padding-right: 1rem;
            background-color: var(--base-ui-colour);
            height: max-content;
            position: absolute;
            z-index: 20;
          "
        >
          <div
            class="project-analysis__toc__heading--content"
            style="
              padding: 2em 0 0 2em;
              line-height: 1.5em;
              display: flex;
              flex-direction: column;
            "
            v-if="!this.project.collection_id"
          >
            <VisibleText style="display: flex">
              Empty report: run an analysis and click "save to report"
            </VisibleText>
            <button
              @click="closeToC"
              style="
                display: flex;
                color: var(--primary);
                border: none;
                background-color: white;
                justify-content: flex-end;
                margin-right: 1em;
              "
            >
              <VisibleText>hide</VisibleText>
            </button>
          </div>
          <DocManager
            v-if="this.project.collection_id"
            ref="DocManagerRef"
            :collectionId="this.project.collection_id"
            v-model="docs"
            :templates="templates"
            class="custom-report__docmanager"
            @toggleToC="closeToC"
            @deleteThisNode="deleteThisNode"
            @placeHere="placeHere"
          />
        </div>
        <div
          class="project-analysis__analysis"
          id="project-analysis__analysis"
          style="
            display: flex;
            background-color: transparent;
            flex-direction: column;
            margin: 0 2em;
            width: 100%;
          "
        >
          <nav
            style="
              margin: 0 0 1.5em 0;
              background-color: var(--base-ui-colour);
              border-radius: 0.5em;
              border: 1px solid transparent;
              border-color: var(--outline-light-colour);
            "
            aria-label="project analysis"
            :aria-hidden="modalOpen"
            :inert="modalOpen"
          >
            <ul class="project-analysis__analysis__tabs-list">
              <li
                v-for="item in reformatedTabs"
                :key="item.name"
                :class="[
                  'project-analysis__tabs-list-item',
                  {
                    'project-analysis__tabs-list-item-active':
                      activeTab === item.name
                  }
                ]"
                style="padding: 1em"
              >
                <router-link
                  :to="{ name: `projectAnalysis${item.name}` }"
                  class="project-analysis__tabs-list-item-btn"
                >
                  <div v-if="item.name === 'Performance'">
                    <img
                      style="width: 1.5em"
                      src="/assets/performance_chart.png"
                      alt="create your own performance charts"
                    />
                  </div>
                  <div v-if="item.name === 'Drivers'">
                    <img
                      style="width: 1.5em"
                      src="/assets/matrix_chart.png"
                      alt="predict top drivers of key scores"
                    />
                  </div>
                  <div v-if="item.name === 'Benchmarking'">
                    <img
                      style="width: 1.5em"
                      src="/assets/benchmarking_chart.png"
                      alt="benchmark your dataset to peers"
                    />
                  </div>
                  <div v-if="item.name === 'Text'">
                    <img
                      style="width: 1.5em"
                      src="/assets/sentiment_chart.png"
                      alt="find themes and sentiment in text"
                    />
                  </div>
                  <div v-if="item.name === 'Outliers'">
                    <img
                      style="width: 1.5em"
                      src="/assets/outliers_analysis.png"
                      alt="find outliers which may be red flags"
                    />
                  </div>
                  <div v-if="item.name === 'Tools'">
                    <img
                      style="width: 1.5em"
                      src="/assets/tools.png"
                      alt="additional report tools"
                    />
                  </div>
                  <VisibleText
                    class="project-analysis__tabs-list-item-text"
                    style="font-size: 1.1rem"
                  >
                    {{ item.name }}
                  </VisibleText>
                </router-link>
              </li>
            </ul>
          </nav>
          <div
            class="project-analysis__main"
            style="
              border-radius: 0.5em;
              border: 1px solid transparent;
              border-color: var(--outline-light-colour);
              display: flex;
              background-color: var(--base-ui-colour);
            "
          >
            <router-view
              :prop-read-only="propReadOnly"
              :isDatasetReadOnly="isDatasetReadOnly"
              :indexForPlacing="indexForPlacing"
              :slideIndexForPlacing="slideIndexForPlacing"
              :hasTextQuestions="hasTextQuestions"
              :hasScaleQuestions="hasScaleQuestions"
              :hasCategoryQuestions="hasCategoryQuestions"
              :hasBMQuestions="hasBMQuestions"
              :datasetIDQuestions="foundDatasetIDQuestions"
              @refresh="sendRefresh"
              @updateSlides="$emit('updateSlides')"
              @set-placement-mode="setPlacementMode"
              @deactivatePlacementMode="deactivatePlacementMode"
              @user-guide-expanded="$emit('user-guide-expanded')"
              @open-article="$emit('open-article', $event)"
            ></router-view>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

// PigeonDoc tools
import { PigeonDocModel, DocManager } from "@pigeonline/pigeondoc"

export default {
  name: "ProjectAnalysis",
  mixins: [DatasetDetailsMixin, ProjectReportMixin, ProjectMixin],
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    isDatasetReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  components: {
    DocManager
  },
  data() {
    return {
      showToC: false,
      tabs: [
        { name: "Performance", level: "Basic" },
        { name: "Drivers", level: "Basic" },
        { name: "Benchmarking", level: "Basic" },
        { name: "Text", level: "Intermediate" },
        { name: "Outliers", level: "Advanced" },
        { name: "Tools", level: "Basic" }
      ],
      tabMode: "showAvailable",
      docs: [],
      templates: { Blank: PigeonDocModel },
      selectedDoc: null,
      chartNode: null,
      acceptablePaths: [
        "performance",
        "drivers",
        "benchmarking",
        "text",
        "outliers"
      ], // Make this a const
      indexForPlacing: -1,
      slideIndexForPlacing: -1
    }
  },
  computed: {
    activeTab() {
      const tab = this.$route.path.split("/").pop()
      return tab.charAt(0).toUpperCase() + tab.substr(1).toLowerCase()
    },
    tabsWithMissing() {
      let reformatedTabs = []
      for (let i = 0; i < this.tabs.length; i++) {
        let newTab = this.tabs[i]
        if (!this.hasTextQuestions && newTab.name == "Text") {
          newTab.deactivate = true
        }
        if (!this.hasBMQuestions && newTab.name == "Benchmarking") {
          newTab.deactivate = true
        }
        if (
          !this.hasScaleQuestions &&
          (newTab.name == "Performance" || newTab.name == "Outliers")
        ) {
          newTab.deactivate = true
        }
        if (!this.hasScaleQuestions && newTab.name == "Drivers") {
          newTab.deactivate = true
        }
        if (!this.hasCategoryQuestions && newTab.name == "Outliers") {
          newTab.deactivate = true
        }

        reformatedTabs.push(newTab)
      }
      return reformatedTabs
    },
    reformatedTabs() {
      if (this.tabMode === "showAll") {
        return this.tabs
      }
      let removeMissing = []
      for (let i = 0; i < this.tabsWithMissing.length; i++) {
        if (this.tabsWithMissing[i].deactivate !== true) {
          removeMissing.push(this.tabsWithMissing[i])
        }
      }
      return removeMissing
    },
    hasMissingAnalysis() {
      let foundMissing = false
      for (let i = 0; i < this.tabsWithMissing.length; i++) {
        if (this.tabsWithMissing[i].deactivate) {
          foundMissing = true
          break
        }
      }
      return foundMissing
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    },
    thisCollectionId() {
      return this.project.collection_id
    },
    hasTextQuestions() {
      if (this.clientTextQuestions && this.clientTextQuestions.length) {
        return true
      }
      return false
    },
    hasScaleQuestions() {
      let foundVars = []
      if (this.clientQuestions && this.clientQuestions.length) {
        for (let i = 0; i < this.clientQuestions.length; i++) {
          if (
            this.clientQuestions[i].approved_data_type &&
            this.clientQuestions[i].approved_data_type.includes("SCALE")
          ) {
            foundVars.push(this.clientQuestions[i])
          }
        }
      }
      if (foundVars.length) {
        return true
      }
      return false
    },
    foundDatasetIDQuestions() {
      let foundVars = []
      if (this.clientQuestions && this.clientQuestions.length) {
        for (let i = 0; i < this.clientQuestions.length; i++) {
          if (
            this.clientQuestions[i].approved_data_type &&
            this.clientQuestions[i].approved_data_type.includes("ID")
          ) {
            if (
              this.clientQuestions[i]._id &&
              this.clientQuestions[i]._id.$oid
            ) {
              foundVars.push(this.clientQuestions[i])
            }
          }
        }
      }
      return foundVars
    },
    hasCategoryQuestions() {
      let foundVars = []
      if (this.clientQuestions && this.clientQuestions.length) {
        for (let i = 0; i < this.clientQuestions.length; i++) {
          if (
            this.clientQuestions[i].approved_data_type &&
            this.clientQuestions[i].approved_data_type.includes("CAT")
          ) {
            foundVars.push(this.clientQuestions[i])
          }
        }
      }
      if (foundVars.length) {
        return true
      }
      return false
    },
    hasBMQuestions() {
      if (this.matchesDetails && Object.keys(this.matchesDetails).length) {
        return true
      }
      return false
    }
  },
  async created() {},
  mounted() {
    this.selectedDoc = 0
    // Auto-open ToC, not currently needed
    // if (this.project.collection_id) {
    //   this.openToC()
    // } else {
    //   this.closeToC()
    // }
  },
  watch: {
    activeTab: {
      immediate: true,
      handler: function (val) {
        if (!val || val === "") return
        document.title = `${this.$store.getters["project/getProject"].name} - Analysis - ${val} || ${this.$theme.theme.pageTitle}`
        if (this.project) {
          const tab = this.$route.path.split("/").pop()
          if (tab) {
            if (this.acceptablePaths.includes(tab)) {
              let cleanedProject = this.deepCloneObj(this.project)
              cleanedProject.settings.lastOpenedTab = tab
              cleanedProject.id = this.project.id
              if (cleanedProject._id) {
                delete cleanedProject._id
              }
              if (!this.propReadOnly) {
                this.saveProject(cleanedProject)
              }

              this.sendRefresh()
            }
          }
        }
      }
    },
    thisCollectionId: async function (val) {
      if (!val) {
        this.closeToC()
        return
      } else {
        this.openToC()
        return
      }
    }
  },
  methods: {
    openToC() {
      this.showToC = true
    },
    closeToC() {
      this.showToC = false
    },
    async deleteThisNode(parent_id, document_id) {
      let cleanedProject = this.deepCloneObj(this.project)
      if (cleanedProject._id) {
        delete cleanedProject._id
      }
      let saveProject = false
      if (
        cleanedProject.driversAnalysis &&
        cleanedProject.driversAnalysis.length
      ) {
        for (let x = 0; x < cleanedProject.driversAnalysis.length; x++) {
          if (cleanedProject.driversAnalysis[x].saved_node_ids) {
            for (
              let y = 0;
              y < cleanedProject.driversAnalysis[x].saved_node_ids.length;
              y++
            ) {
              if (
                cleanedProject.driversAnalysis[x].saved_node_ids[y].uuid ===
                parent_id
              ) {
                cleanedProject.driversAnalysis[x].saved_node_ids.splice(y, 1)
                break
              }
              if (
                cleanedProject.driversAnalysis[x].saved_node_ids[y].type ==
                "section"
              ) {
                if (cleanedProject.driversAnalysis[x].content) {
                  for (
                    let z = 0;
                    z < cleanedProject.driversAnalysis[x].content.length;
                    z++
                  ) {
                    if (cleanedProject.benchmarkAnalysis[x].content) {
                      if (
                        cleanedProject.driversAnalysis[x].saved_node_ids[y]
                          .content[z].uuid === parent_id
                      ) {
                        cleanedProject.driversAnalysis[x].saved_node_ids[
                          y
                        ].content[z].splice(y, 1)
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }
        saveProject = true
      }
      if (
        cleanedProject.benchmarkAnalysis &&
        cleanedProject.benchmarkAnalysis.length
      ) {
        for (let x = 0; x < cleanedProject.benchmarkAnalysis.length; x++) {
          if (cleanedProject.benchmarkAnalysis[x].saved_node_ids) {
            for (
              let y = 0;
              y < cleanedProject.benchmarkAnalysis[x].saved_node_ids.length;
              y++
            ) {
              if (
                cleanedProject.benchmarkAnalysis[x].saved_node_ids[y].uuid ===
                parent_id
              ) {
                cleanedProject.benchmarkAnalysis[x].saved_node_ids.splice(y, 1)
                break
              }
              if (
                cleanedProject.benchmarkAnalysis[x].saved_node_ids[y].type ==
                "section"
              ) {
                if (cleanedProject.benchmarkAnalysis[x].content) {
                  for (
                    let z = 0;
                    z < cleanedProject.benchmarkAnalysis[x].content.length;
                    z++
                  ) {
                    if (
                      cleanedProject.benchmarkAnalysis[x].saved_node_ids[y]
                        .content[z].uuid === parent_id
                    ) {
                      cleanedProject.benchmarkAnalysis[x].saved_node_ids[
                        y
                      ].content[z].splice(y, 1)
                      break
                    }
                  }
                }
              }
            }
          }
        }
        saveProject = true
      }
      if (
        cleanedProject.outliersAnalysis &&
        cleanedProject.outliersAnalysis.length
      ) {
        for (let x = 0; x < cleanedProject.outliersAnalysis.length; x++) {
          if (cleanedProject.outliersAnalysis[x].saved_node_ids) {
            for (
              let y = 0;
              y < cleanedProject.outliersAnalysis[x].saved_node_ids.length;
              y++
            ) {
              if (
                cleanedProject.outliersAnalysis[x].saved_node_ids[y].uuid ===
                parent_id
              ) {
                cleanedProject.outliersAnalysis[x].saved_node_ids.splice(y, 1)
                break
              }
              if (
                cleanedProject.outliersAnalysis[x].saved_node_ids[y].type ==
                "section"
              ) {
                if (cleanedProject.outliersAnalysis[x].content) {
                  for (
                    let z = 0;
                    z < cleanedProject.outliersAnalysis[x].content.length;
                    z++
                  ) {
                    if (
                      cleanedProject.outliersAnalysis[x].saved_node_ids[y]
                        .content[z].uuid === parent_id
                    ) {
                      cleanedProject.outliersAnalysis[x].saved_node_ids[
                        y
                      ].content[z].splice(y, 1)
                      break
                    }
                  }
                }
              }
            }
          }
        }
        saveProject = true
      }
      if (
        cleanedProject.performanceAnalysis &&
        cleanedProject.performanceAnalysis.length
      ) {
        for (let x = 0; x < cleanedProject.performanceAnalysis.length; x++) {
          if (cleanedProject.performanceAnalysis[x].saved_node_ids) {
            for (
              let y = 0;
              y < cleanedProject.performanceAnalysis[x].saved_node_ids.length;
              y++
            ) {
              if (
                cleanedProject.performanceAnalysis[x].saved_node_ids[y].uuid ===
                parent_id
              ) {
                cleanedProject.performanceAnalysis[x].saved_node_ids.splice(
                  y,
                  1
                )
                break
              }
              if (
                cleanedProject.performanceAnalysis[x].saved_node_ids[y].type ==
                "section"
              ) {
                if (cleanedProject.performanceAnalysis[x].content) {
                  for (
                    let z = 0;
                    z < cleanedProject.performanceAnalysis[x].content.length;
                    z++
                  ) {
                    if (
                      cleanedProject.performanceAnalysis[x].saved_node_ids[y]
                        .content[z].uuid === parent_id
                    ) {
                      cleanedProject.performanceAnalysis[x].saved_node_ids[
                        y
                      ].content[z].splice(y, 1)
                      break
                    }
                  }
                }
              }
            }
          }
        }
        saveProject = true
      }
      if (
        cleanedProject.textAnalysisObjects &&
        cleanedProject.textAnalysisObjects.length
      ) {
        for (let x = 0; x < cleanedProject.textAnalysisObjects.length; x++) {
          if (cleanedProject.textAnalysisObjects[x].saved_node_ids) {
            for (
              let y = 0;
              y < cleanedProject.textAnalysisObjects[x].saved_node_ids.length;
              y++
            ) {
              if (
                cleanedProject.textAnalysisObjects[x].saved_node_ids[y].uuid ===
                parent_id
              ) {
                cleanedProject.textAnalysisObjects[x].saved_node_ids.splice(
                  y,
                  1
                )
                break
              }
              if (
                cleanedProject.textAnalysisObjects[x].saved_node_ids[y].type ==
                "section"
              ) {
                if (cleanedProject.textAnalysisObjects[x].content) {
                  for (
                    let z = 0;
                    z < cleanedProject.textAnalysisObjects[x].content.length;
                    z++
                  ) {
                    if (
                      cleanedProject.textAnalysisObjects[x].saved_node_ids[y]
                        .content[z].uuid === parent_id
                    ) {
                      cleanedProject.textAnalysisObjects[x].saved_node_ids[
                        y
                      ].content[z].splice(y, 1)
                      break
                    }
                  }
                }
              }
            }
          }
        }
        saveProject = true
      }

      if (saveProject) {
        if (!this.propReadOnly) {
          await this.saveProject(cleanedProject)
        }
      }

      const project = await this.$pigeonline.projects.getByID(
        PigeonDocModel,
        document_id
      )
      if (project) {
        for (let x = 0; x < project.nodes.length; x++) {
          if (project.nodes[x].uuid === parent_id) {
            project.nodes = project.nodes.filter(
              (item) => item !== project.nodes[x]
            )
          }
        }
      }
      await this.saveProject(project)

      location.reload()
    },
    placeHere(index, slideIndex) {
      this.indexForPlacing = index
      this.slideIndexForPlacing = slideIndex
    },
    sendRefresh() {
      if (this.$refs.DocManagerRef) {
        this.$refs.DocManagerRef.refresh()
      }
    },
    setPlacementMode(slide) {
      this.$refs.DocManagerRef.setPlacementMode(slide)
    },
    deactivatePlacementMode() {
      this.indexForPlacing = -1
      this.slideIndexForPlacing = -1
      this.$refs.DocManagerRef.deactivatePlacementMode()
    },
    changeTabMode(mode) {
      if (mode) {
        this.tabMode = mode
      }
    }
  }
}
</script>
