<template>
  <MessageBlock class="banner-msg" message-type="warning">
    <!-- account pending verification -->
    <template v-if="statusName === 'notVerified'">
      <VisibleText> Your account is pending verification. </VisibleText>
    </template>

    <!--- account setup complete -->
    <template v-if="statusName === 'setupIncomplete'">
      <VisibleText>Your account setup is not complete. </VisibleText>
      <router-link :to="{ name: 'accountNextSteps' }">
        {{ translate("Click here to complete your setup.", $options.name) }}
      </router-link>
    </template>
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import UserMixin from "@/utils/mixins/userMixin.js"
export default {
  name: "AccountMessages",
  props: {
    statusName: {
      default: () => "",
      type: String
    }
  },
  mixins: [UserMixin],
  components: {
    MessageBlock
  }
}
</script>
