<template>
  <SidePanel label="select match" :show="show" @close="$emit('close-selector')">
    <h2 :id="`${uid}-header`">Select a Match</h2>
    <p>
      <VisibleText
        >Select a question from our global database to match with your question.
      </VisibleText>
    </p>
    <div style="padding: 1rem 0">
      <span class="text-smallcaps text-smallcaps--title">
        <VisibleText>Your Question</VisibleText>
      </span>
      {{ clientQuestion.question_title }} - {{ clientQuestion.question_text }}
    </div>

    <template v-if="statusBenchmarkGroupAvailable">
      <SearchBar
        class="dataset__search-questions"
        placeholder="Begin typing to filter question code and question text"
        @search="globalQuestionSearch = $event"
      >
        <template #label> Search </template>
        <!-- <template #submit>
          <ButtonIconOnly icon="search" button-type="submit">
            search
          </ButtonIconOnly>
        </template> -->
      </SearchBar>

      <form
        class="dataset-table__benchmarks-selector-form dataset__select-questions-form"
        @submit.prevent
      >
        <fieldset
          class="form-fieldset-group light-scroll-bar"
          id="global-questions-group"
        >
          <legend
            class="form-fieldset-group__legend sr-only"
            :data-parent="$options.name"
          >
            {{ translate("select a global question match", $options.name) }}
          </legend>
          <div
            v-for="globalQuestion in filteredGlobalQuestions"
            :key="`select-${globalQuestion._id.$oid}`"
            style="margin: 1rem 0"
          >
            <label
              class="form-radio-label"
              :for="`gq-${globalQuestion._id.$oid}`"
            >
              <input
                class="form-radio-input"
                type="radio"
                :id="`gq-${globalQuestion._id.$oid}`"
                :name="globalQuestion._id.$oid"
                :value="globalQuestion._id.$oid"
                v-model="selectedMatch"
                :disabled="!hasMatchingType(globalQuestion.data_type)"
              />
              <span class="form-radio-selector"></span>
              <span style="font-weight: bold">
                {{ globalQuestion.question_title }}
              </span>
              <div style="margin-left: 1.5rem; color: var(--base-colour-light)">
                {{ globalQuestion.question_text }}
              </div>
            </label>
          </div>
        </fieldset>
        <button
          type="submit"
          class="side-pannel__primary-btn"
          :disabled="!permissions.includes('update-dataset')"
          @click="submitMatch"
        >
          <VisibleText>Submit Match</VisibleText>
        </button>
        <button
          type="button"
          class="side-pannel__close-btn"
          @click="$emit('close-selector')"
        >
          <VisibleText>Close</VisibleText>
        </button>
      </form>
    </template>

    <template v-else>
      <p class="error-msg__inline" style="margin: 2rem 0">
        <VisibleText>Please select a benchmark group (step 3)</VisibleText>
      </p>
      <button
        type="button"
        class="side-pannel__primary-btn"
        @click="$emit('close-selector')"
      >
        <VisibleText>Close</VisibleText>
      </button>
    </template>
  </SidePanel>
</template>

<script>
// Components
import SidePanel from "@/components/UI/SidePanel.vue"
//import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SearchBar from "@/components/UI/SearchBar.vue"
import MailerService from "@/services/mailerService.js"

// Mixins
import BenchmarksMixin from "@/components/Dataset/Mixins/benchmarksMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"
// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

export default {
  name: "DatasetTableBenchmarksSelector",
  mixins: [BenchmarksMixin, DatasetDetailsMixin, UserMixin, FiltersMixin],
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    uid: {
      default: () => "selector",
      type: String
    },
    show: {
      default: () => false,
      type: Boolean
    },
    detailsForSharingWithBenchmarking: {
      default: () => {},
      type: Object
    },
    benchmarkGroup: {
      type: BenchmarkGroup,
      default: () => {}
    }
  },
  components: {
    SearchBar,
    SidePanel
  },
  data() {
    return {
      MAILER_SERVICE: new MailerService(this.$pigeonline),
      globalQuestionSearch: null,
      selectedMatch: null
    }
  },
  computed: {
    emailMessage() {
      let thisContent = ""
      const email = this.$pigeonline.getUserEmail()
      if (email) {
        thisContent = `From: ${email}`
      }
      if (this.detailsForSharingWithBenchmarking) {
        if (this.detailsForSharingWithBenchmarking.dataset_details) {
          if (this.detailsForSharingWithBenchmarking.dataset_details.name) {
            thisContent =
              thisContent +
              `
            File name: ${this.detailsForSharingWithBenchmarking.dataset_details.name}`
          }
          if (this.detailsForSharingWithBenchmarking.dataset_details.name) {
            thisContent =
              thisContent +
              `
            Uploaded on: ${this.formatDate(
              this.detailsForSharingWithBenchmarking.dataset_details.created_on
            )}`
          }
        }
      }
      if (this.selectedMatch) {
        for (let i = 0; i < this.globalQuestions.length; i++) {
          if (
            this.globalQuestions[i]._id &&
            this.globalQuestions[i]._id.$oid &&
            this.globalQuestions[i]._id.$oid == this.selectedMatch
          ) {
            if (this.globalQuestions[i].question_title) {
              thisContent =
                thisContent +
                `
              Match requested to the BM Question title: ${this.globalQuestions[i].question_title}`
            }
            if (this.globalQuestions[i].question_text) {
              thisContent =
                thisContent +
                `
              Match requested to the BM Question wording: ${this.globalQuestions[i].question_text}`
            }
          }
        }
      }

      return thisContent
    },
    filteredGlobalQuestions() {
      const keys = ["question_title", "question_text"]
      return this.searchInObjectValues(
        this.globalQuestions,
        keys,
        this.globalQuestionSearch
      )
    },
    statusBenchmarkGroupAvailable() {
      const localBenchmarkGroup =
        this.$store.getters["datasetWizard/getBenchmarkGroup"]
      return localBenchmarkGroup && Object.keys(localBenchmarkGroup).length > 0
    },
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "Search global questions",
        "forms__global-search",
        this.$store.getters.getLanguage
      )
    }
  },
  methods: {
    /**
     * This also exists in filters.mixin but was not loading in time for the computed
     * Search through object values of specified (allowed) keys
     * @param {Array} array of objects
     * @param {Array} keys list of allowed keys to construct search (world)
     * @param {String} searchTerm term to search for
     */
    searchInObjectValues: (array, keys, searchTerm) => {
      if (!searchTerm) return array
      const regExp = new RegExp(`${searchTerm}`, "ig")
      let filtered = []
      filtered = array.filter((item) => {
        let match = false
        keys.forEach((key) => {
          if (item[key].search(regExp) !== -1) match = true
        })
        return match
      })
      return filtered || array
    },
    hasMatchingType(data_type) {
      if (this.clientQuestion && data_type) {
        if (
          this.clientQuestion.approved_data_type &&
          this.clientQuestion.approved_data_type == data_type
        ) {
          return true
        }
      }
      return false
    },
    submitMatch() {
      if (!this.selectedMatch) return
      // This should always be the case unless the prop failed
      if (Object.keys(this.detailsForSharingWithBenchmarking).length) {
        if (this.clientQuestion.benchmarking_id) {
          this.$_benchmarksMixin_updateMatch(
            this.clientQuestion,
            this.selectedMatch,
            this.detailsForSharingWithBenchmarking.dataset_details,
            this.detailsForSharingWithBenchmarking.user_details
          )
          this.sendForm()
        } else {
          const suggestedMatch =
            this.suggestedMatches[this.clientQuestion._id.$oid] || ""
          this.$_benchmarksMixin_newMatch(
            this.clientQuestion,
            this.selectedMatch,
            suggestedMatch,
            this.detailsForSharingWithBenchmarking.dataset_details,
            this.detailsForSharingWithBenchmarking.user_details
          )
          this.sendForm()
        }
        this.$emit("approved-global-match")
        this.selectedMatch = null
        this.$emit("close-selector")
      }
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-GB")
    },
    async sendForm() {
      const localBenchmarkGroup =
        this.$store.getters["datasetWizard/getBenchmarkGroup"]
      if (
        localBenchmarkGroup &&
        localBenchmarkGroup.share_admin &&
        localBenchmarkGroup.admin_email
      ) {
        const email = this.$pigeonline.getUserEmail()
        if (email) {
          await this.MAILER_SERVICE.sendContactUs({
            subject: "A benchmarking request has been submitted.",
            message: `
            ${this.emailMessage}

          `,
            to: [localBenchmarkGroup.admin_email]
          })
        }
      }
    }
  }
}
</script>
