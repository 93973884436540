import { axios, BaseService } from "@pigeonline/core"
import { app } from "@/main.js"

export default class mailerService extends BaseService {
  async sendContactUs(data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/mailer"
      const response = await axios.post(`${URL}/send`, data, {
        skipAuthHeaders: true
      })
      return response.data
    } catch (e) {
      throw new Error("mailerService.js:sendContactUs " + e.message)
    }
  }
}
