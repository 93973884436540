<template>
  <div
    v-if="!isBanned"
    class="response-list-item"
    :style="itemIsCollapsed ? icon.collapsedHeight : ''"
  >
    <template v-if="!isHidden && !isDropped">
      <div
        class="response-list-item__header"
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div style="display: flex; width: 15rem">
          <!-- Pin section (for selecting) -->
          <button
            class="response-list-item__header-btn"
            style="display: flex; margin-left: -1rem"
            type="button"
            :aria-expanded="isSelected ? 'true' : 'false'"
            @click.stop="selectThisResponse()"
          >
            <SvgIconMeaningful
              :icon="isSelected ? icon.pinned : icon.notpinned"
              :class="[
                'response-list-item__header-btn-icon',
                { collapsed: !isSelected }
              ]"
              :title="!isSelected ? 'Pin row' : 'Unpin row'"
              style="display: flex"
            />
          </button>
          <!-- Expand if text is larger than default height -->
          <button
            class="response-list-item__header-btn"
            style="display: flex"
            type="button"
            :aria-expanded="itemIsCollapsed ? 'true' : 'false'"
            @click.stop="collapseThisItem"
            v-if="allowCollapse"
          >
            <SvgIconMeaningful
              :icon="itemIsCollapsed ? icon.expanded : icon.collapsed"
              :class="[
                'response-list-item__header-btn-icon',
                { collapsed: !itemIsCollapsed }
              ]"
              title="Expand view"
              style="display: flex"
            />
          </button>
          <!-- Index or text row number -->
          <div style="display: flex">
            <span class="response-list-item__header-title">
              <VisibleText>Row </VisibleText>
              {{ item.row_index }}
            </span>
            <button
              class="response-list-item__header-btn"
              style="display: flex; margin-top: -0.5rem"
              type="button"
              :aria-expanded="isRespondentInfoExpanded ? 'true' : 'false'"
              @click.stop="toggleRespondentInfo"
            >
              <SvgIconMeaningful
                :icon="
                  isRespondentInfoExpanded
                    ? icon.respondentInfoExposed
                    : icon.respondentInfoHidden
                "
                :class="[
                  'response-list-item__header-btn-icon',
                  { collapsed: !isRespondentInfoExpanded }
                ]"
                title="Show row info"
                style="display: flex"
              />
            </button>
          </div>
        </div>
        <!-- Theme header (lists all matching themes) -->
        <div
          style="
            display: flex;
            overflow-x: auto;
            padding-bottom: 1rem;
            flex-direction: row;
            width: 100%;
          "
          v-if="listOfThemes.length"
        >
          <div style="margin-left: auto; display: flex; margin-right: 1rem">
            <span style="padding-right: 0.5rem">
              <VisibleText>Themes</VisibleText>:
            </span>
            <div style="display: flex">
              <div v-for="(matching_theme, index) in listOfThemes" :key="index">
                <ResponseTheme
                  :propReadOnly="propReadOnly"
                  :theme_index="index"
                  :theme="matching_theme"
                  :theme_length="listOfThemes.length"
                  :highlightedNotes="highlightItems['theme-note']"
                  :highlightedKeywords="highlightItems['theme-keyword']"
                  :isDropped="false"
                  :responseContent="item.response"
                  @dropNoteFromResponse="dropNoteFromResponse"
                  @dropFromKeywordMatch="dropFromKeywordMatch"
                  @toggleTheme="toggleTheme"
                ></ResponseTheme>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            z-index: 10;
            position: absolute;
            right: 5.5rem;
            padding: 0.5rem;
            background-color: white;
            border: 1px solid var(--outline-light-colour);
            border-top: 0;
            border-radius: 0 0 4px 4px;
            color: var(--base-colour);
          "
          v-if="listOfDroppedThemes.length && showIgnoredThemes"
        >
          <div style="margin-left: auto; display: flex; margin-right: 1rem">
            <span style="padding-right: 0.5rem">
              <VisibleText>Ignored</VisibleText>:
            </span>
            <div
              v-for="(dropped_theme, index) in listOfDroppedThemes"
              :key="index"
              style="font-weight: 500; border: none"
            >
              <ResponseTheme
                :theme_index="index"
                :theme="dropped_theme"
                :isDropped="true"
                :propReadOnly="propReadOnly"
                :theme_length="listOfDroppedThemes.length"
                :highlightedNotes="highlightItems['theme-note']"
                :highlightedKeywords="highlightItems['theme-keyword']"
                :responseContent="item.response"
                @dropNoteFromResponse="dropNoteFromResponse"
                @dropFromKeywordMatch="dropFromKeywordMatch"
                @toggleTheme="toggleTheme"
              ></ResponseTheme>
            </div>
          </div>
        </div>

        <div>
          <button
            style="
              background-color: transparent;
              border: 0;
              margin-top: -0.1rem;
              margin-right: 1rem;
              width: 2rem;
              background-color: white;
              border: 0;
            "
            class="inline-edit-text__action-btn"
            v-if="listOfDroppedThemes.length"
            @click="setShowIgnoredThemes()"
          >
            <div style="display: flex; flex-direction: row">
              <SvgIconMeaningful
                style="cursor: pointer"
                class="complete-icon-svg"
                :icon="showIgnoredThemes ? 'remove' : 'eye'"
                title="view ignored themes"
              />
            </div>
          </button>
        </div>
        <div
          v-if="themeAdd.isExpanded"
          style="
            z-index: 10;
            position: absolute;
            right: 3rem;
            background-color: white;
            border: 1px solid var(--outline-light-colour);
            border-top: 0;
            border-radius: 0 0 4px 4px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
            border: 1px solid var(--outline-colour);
            border-radius: 8px;
          "
        >
          <div style="display: flex; flex-direction: row">
            <div style="display: flex; flex-direction: column">
              <div
                style="
                  margin-top: -0.5rem;
                  margin-bottom: 0.5rem;
                  background: var(--outline-light-colour);
                  border-bottom: 1px solid var(--outline-light-colour);
                  padding: 0.75rem;
                  font-family: Isidora, Arial, sans-serif;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  width: 40rem;
                "
              >
                <div style="display: flex; flex-direction: row">
                  <VisibleText v-if="listOfUnusedThemes.length">
                    Add theme to entire text
                  </VisibleText>
                  <button
                    style="
                      border: 1px solid var(--outline-colour);
                      background: var(--secondary-ui-colour);
                      border-radius: 4px;
                      margin-left: auto;
                      width: 1.5rem;
                      height: 1.5rem;
                    "
                    @click="setAddThemeIsExpanded()"
                  >
                    <SvgIconMeaningful
                      style="
                        cursor: pointer;
                        width: 1rem;
                        margin-left: -0.05rem;
                      "
                      class="complete-icon-svg"
                      icon="remove"
                      title="close"
                    />
                  </button>
                </div>
              </div>
              <div
                style="display: flex; flex-direction: row; margin-bottom: -1rem"
              >
                <SvgIconMeaningful
                  class="complete-icon-svg"
                  icon="search"
                  title="filter below themes"
                  style="margin-top: 1rem"
                />
                <input
                  id="filter_themes"
                  type="text"
                  name="Filter themes"
                  v-model="themeAdd.filter"
                  placeholder="Filter below themes"
                />
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  max-height: 15rem;
                  overflow-y: auto;
                  margin-bottom: 1rem;
                  padding: 1rem;
                "
              >
                <button
                  class="ui-rounded-pill__wrapper"
                  v-for="(theme, index) in listOfUnusedThemes"
                  :key="index"
                  style="
                    font-weight: 500;
                    height: 1.5rem;
                    border: none;
                    width: fit-content;
                    max-width: 30rem;
                    display: flex;
                    flex-direction: row;
                  "
                  :style="{ 'background-color': theme.theme_color }"
                  @click="addThemeToRow(theme)"
                >
                  <VisibleText
                    style="display: flex; overflow: hidden; padding-right: 1rem"
                    >{{ theme.name }}
                  </VisibleText>
                  <SvgIconDecorative
                    icon="add"
                    style="display: flex; max-width: 3rem"
                  />
                </button>
              </div>

              <label
                for="create-theme-from-row"
                v-if="listOfUnusedThemes.length"
                style="width: 80%; padding-left: 1rem"
              >
                <VisibleText> Or create a new theme </VisibleText>
              </label>
              <label
                for="create-theme-from-row"
                v-if="!listOfUnusedThemes.length"
              >
                <VisibleText>
                  Create a theme you think matches this text
                </VisibleText>
              </label>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  margin-bottom: -0.5rem;
                  width: 80%;
                  padding-left: 1rem;
                "
              >
                <SvgIconMeaningful
                  class="complete-icon-svg"
                  icon="add"
                  title="Add new theme"
                  style="margin-top: 1rem"
                  v-if="!themeAdd.themeName.length"
                />
                <input
                  id="create-theme-from-row"
                  type="text"
                  name="New theme name"
                  v-model="themeAdd.themeName"
                  placeholder="New theme name"
                />
                <ButtonIconOnly
                  @click-handler="addNewTheme"
                  icon="check"
                  class="inline-edit-text__action-btn"
                  style="color: black; display: flex; margin-left: 1rem"
                  v-if="
                    themeAdd.themeName.length &&
                    !sortedThemes
                      .map((theme) => theme.name)
                      .includes(themeAdd.themeName)
                  "
                >
                  <VisibleText>Add new theme</VisibleText>
                </ButtonIconOnly>
              </div>
              <div
                v-if="
                  themeAdd.themeName.length &&
                  sortedThemes
                    .map((theme) => theme.name.toLowerCase())
                    .includes(themeAdd.themeName.toLowerCase())
                "
                style="
                  padding-left: 1rem;
                  padding-bottom: 1rem;
                  color: var(--warning-text-colour);
                "
              >
                <VisibleText>Theme name already exists</VisibleText>
              </div>
            </div>
          </div>
        </div>
        <div style="position: absolute; right: 0; margin-top: -0.25rem">
          <div style="display: flex; flex-direction: row">
            <div
              v-if="
                !listOfThemes.length &&
                !listOfDroppedThemes.length &&
                textAnalysisObject.activeTab == 'Themes'
              "
              style="
                margin-right: 0.5rem;
                font-size: 0.8rem;
                margin-top: 0.5rem;
              "
            >
              <VisibleText> Add a theme: </VisibleText>
            </div>
            <button
              @click="setAddThemeIsExpanded()"
              style="background-color: white; border: 0"
              class="inline-edit-text__action-btn"
              v-if="textAnalysisObject.activeTab == 'Themes'"
              :disabled="propReadOnly"
            >
              <div style="display: flex; flex-direction: row">
                <SvgIconMeaningful
                  style="color: black"
                  :style="
                    propReadOnly ? 'cursor: not-allowed' : 'cursor: pointer;'
                  "
                  class="complete-icon-svg"
                  :icon="themeAdd.isExpanded ? 'remove' : 'add'"
                  title="add a theme to this text"
                />
              </div>
            </button>
          </div>
        </div>

        <div
          style="display: flex; flex-direction: row"
          v-if="textAnalysisObject.activeTab === 'Sentiment'"
        >
          <div v-if="!hasFetchedSentiments">
            <VisibleText> Sentiment: Data not available </VisibleText>
          </div>
          <div
            style="display: flex; flex-direction: column"
            v-if="hasFetchedSentiments"
          >
            <div style="display: flex" v-if="suggestedIsNew">
              <VisibleText>Edited score:</VisibleText>
              <span> {{ suggestedAdjustment }}</span>
              <div style="display: flex">
                <span
                  class="ui-rounded-pill__wrapper"
                  style="color: black; font-weight: 500"
                  :style="getSentimentStyle(suggestedAdjustment)"
                >
                  <VisibleText
                    >{{ getSentimentEmoji(suggestedAdjustment) }}
                  </VisibleText>
                </span>
              </div>
            </div>
            <div style="display: flex">
              <div v-if="!isCorrectingSentiment">
                <button
                  style="font-size: 0.8rem"
                  class="table__toolbar-btn"
                  @click="isCorrectingSentiment = true"
                >
                  <VisibleText>Adjust</VisibleText>
                </button>
              </div>
              <form v-if="isCorrectingSentiment" onsubmit="return false;">
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <label for="adjustmentRange"> Adjust sentiment:</label>
                    <input
                      v-model="suggestedAdjustment"
                      type="range"
                      min="-1"
                      max="1"
                      class="slider"
                      step="0.01"
                      id="adjustmentRange"
                    />
                    <!-- @change="changeAdjustedSentiment" -->
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <button
                      v-if="suggestedIsNew"
                      class="table__toolbar-btn"
                      style="margin-left: 0.5rem"
                      @click="applyChangeToSentiment"
                    >
                      <SvgIconDecorative icon="check" />
                      <VisibleText>Apply</VisibleText>
                    </button>
                    <button
                      class="table__toolbar-btn"
                      @click="cancelAdjustment"
                    >
                      <SvgIconDecorative icon="remove" />
                      <VisibleText>Cancel</VisibleText>
                    </button>
                    <button
                      class="table__toolbar-btn"
                      v-if="
                        adjustedSentiment &&
                        suggestedAdjustment !== adjustedSentiment
                      "
                      @click="reverseAdjustment"
                    >
                      <SvgIconDecorative icon="minus" />
                      <VisibleText>Revert</VisibleText>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            v-if="
              textAnalysisObject.activeTab === 'Sentiment' &&
              hasFetchedSentiments
            "
            style="display: flex, flex-direction: column;"
          >
            <div style="display: flex">
              <VisibleText>Sentiment: </VisibleText>
              <span style="padding-left: 0.3rem">{{
                roundToTwo(this.compiledSentiment)
              }}</span>
              <span
                style="font-size: 0.8rem; padding: 0 0.5rem 0 0.5rem"
                v-if="adjustedSentiment !== null"
                >adjusted</span
              >
              <span v-if="adjustedSentiment == null">
                <button
                  @click="$emit('showSentimentDetails', true)"
                  style="
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                  "
                >
                  <SvgIconMeaningful
                    :icon="icon.respondentInfoHidden"
                    title="Learn about this score"
                    style="display: flex"
                  />
                </button>
              </span>
            </div>
            <div style="display: flex">
              <span
                class="ui-rounded-pill__wrapper"
                style="color: black; font-weight: 500"
                :style="getSentimentStyle(this.compiledSentiment)"
              >
                <VisibleText>{{
                  getSentimentLabel(this.compiledSentiment)
                }}</VisibleText>
              </span>
            </div>
          </div>
        </div>
      </div>
      <RespondentInfo
        :response-id="item.id"
        :client-question-id="clientQuestionId"
        :show="isRespondentInfoExpanded"
        v-show="isRespondentInfoExpanded"
      />
      <!-- As of 1.7.0, search has been removed as a button, but maybe we want to bring it back? -->
      <!-- @search="handleSelectableEvent('search', $event)" -->
      <UISelectable
        ref="ui-selectable"
        v-if="
          !isRespondentInfoExpanded ||
          (isRespondentInfoExpanded && !itemIsCollapsed)
        "
        style="overflow-y: auto; padding-top: 2rem"
        :style="itemIsCollapsed ? icon.respondentInfoHiddenCollapsed : ''"
        class="response-list-item__selectable-text"
        :responseId="item.id"
        :hasFetchedSentences="hasFetchedSentences"
        @keyword="handleSelectableEvent('keyword', $event)"
        @note="handleSelectableEvent('note', $event)"
        @remove-note="handleSelectableEvent('remove-note', $event)"
        @remove-keyword="parseKeywordForDropping($event)"
        @tool-option-selected="$emit($event)"
        @similar="handleSelectableEvent('find-similar', $event)"
      >
        <p class="highlighted-content" v-html="highlightedHTMLString" />
      </UISelectable>
      <div
        class="response-list-item__options sr-show-on-focus"
        tabindex="0"
        :aria-label="`Respondent ${item.idx + 1} options`"
      >
        <button
          v-if="
            !textAnalysisObject.selectedThemeUUIDs &&
            textAnalysisObject.activeTab === 'Themes'
          "
          type="button"
          class="response-list-item__options-btn"
          @click="
            handleSelectableEvent('note', {
              content: '',
              length: 0,
              startIndex: 0,
              row_index: item.row_index
            })
          "
        >
          <SvgIconDecorative icon="add" />
          <VisibleText>Add a note</VisibleText>
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          style="background-color: transparent; color: var(--base-colour-light)"
          @click="confirmBanResponse"
          :disabled="propReadOnly"
        >
          <SvgIconDecorative
            icon="ban"
            style="color: var(--base-colour-light)"
          />
          <VisibleText>Ban</VisibleText>
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="toggleBan(item.id)"
          style="background-color: transparent; color: var(--base-colour-light)"
        >
          <SvgIconDecorative icon="eye" />
          <VisibleText>Hide</VisibleText>
        </button>
      </div>
    </template>
    <div class="response-list-item__is-hidden" v-else>
      <div v-if="isHidden">
        <button
          type="button"
          class="response-list-item__options-btn show-response-btn"
          @click="$emit('toggle-response-hide', item.id)"
        >
          <SvgIconDecorative icon="eye" />
          <VisibleText>Show hidden response</VisibleText>
        </button>
      </div>
      <div v-if="isDropped">
        <div v-if="isRevealingDropped">
          <div
            style="
              z-index: 10;
              position: absolute;
              right: 4rem;
              padding: 0.5rem;
              background-color: white;
              border: 1px solid var(--outline-light-colour);
              border-top: 0;
              border-radius: 0 0 4px 4px;
              color: var(--base-colour);
            "
            v-if="listOfDroppedThemes.length"
          >
            <div style="margin-left: auto; display: flex; margin-right: 1rem">
              <span style="padding-right: 0.5rem">
                <VisibleText>Ignored</VisibleText>:
              </span>
              <div
                v-for="(dropped_theme, index) in listOfDroppedThemes"
                :key="index"
                style="font-weight: 500; border: none"
              >
                <!-- Hidden responses -->
                <ResponseTheme
                  :theme_index="index"
                  :theme="dropped_theme"
                  :isDropped="true"
                  :theme_length="listOfDroppedThemes.length"
                  :highlightedNotes="highlightItems['theme-note']"
                  :highlightedKeywords="highlightItems['theme-keyword']"
                  :responseContent="item.response"
                  :propReadOnly="propReadOnly"
                  @dropNoteFromResponse="dropNoteFromResponse"
                  @dropFromKeywordMatch="dropFromKeywordMatch"
                  @toggleTheme="toggleTheme"
                ></ResponseTheme>
              </div>
            </div>
          </div>
          <UISelectable
            ref="ui-selectable"
            :responseId="item.id"
            class="response-list-item__selectable-text"
            :hasFetchedSentences="hasFetchedSentences"
            @keyword="handleSelectableEvent('keyword', $event)"
            @note="handleSelectableEvent('note', $event)"
            @ban="handleSelectableEvent('ban', $event)"
            @search="handleSelectableEvent('search', $event)"
            @remove-note="handleSelectableEvent('remove-note', $event)"
            @similar="handleSelectableEvent('find-similar', $event)"
            @tool-option-selected="$emit($event)"
          >
            <p
              style="margin-top: 2.5rem"
              class="highlighted-content"
              v-html="highlightedHTMLString"
            />
          </UISelectable>
          <button
            type="button"
            class="response-list-item__options-btn show-response-btn"
            @click="isRevealingDropped = !isRevealingDropped"
          >
            <SvgIconDecorative icon="eye" />
            <VisibleText>Hide dropped response</VisibleText>
          </button>
        </div>
        <div v-else-if="!isRevealingDropped">
          <button
            type="button"
            class="response-list-item__options-btn show-response-btn"
            @click="isRevealingDropped = !isRevealingDropped"
          >
            <SvgIconDecorative icon="eye" />
            <VisibleText>Show dropped response</VisibleText>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import RespondentInfo from "./ResponseListItemRespondentInfo.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import UISelectable from "../UI/Selectable.vue"
import ResponseTheme from "./ResponseTheme.vue"
// import Tooltip from "@/components/Tooltip/Tooltip.vue"

// Mixins
import ConfirmMixin from "/src/utils/mixins/confirmMixin.js"
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"

// Utils
import { CustomIntervalTree } from "../Utils/highlight.js"
import moment from "moment"

// Consts
import { HIGHLIGHT_TYPES } from "../Utils/constants.js"
import VisibleText from "../../../../../Translation/VisibleText.vue"

export default {
  name: "ResponseListItem",
  mixins: [ConfirmMixin, textAnalysisMixin, themeColorsMixin],
  components: {
    SvgIconMeaningful,
    SvgIconDecorative,
    ButtonIconOnly,
    RespondentInfo,
    UISelectable,
    VisibleText,
    ResponseTheme
  },
  props: {
    sortedThemes: {
      type: Array,
      default: () => []
    },
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    item: {
      type: Object
    },
    clientQuestionId: {
      type: String,
      default: () => {}
    },
    isHidden: {
      type: Boolean,
      default: () => false
    },
    highlightItems: {
      type: Object,
      default: () => {}
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      highlightedHTMLString: "",
      isComponentMounted: false,
      isRespondentInfoExpanded: false,
      isWrapMarkPending: false,
      icon: {
        collapsed: "arrowUp",
        collapsedHeight: "max-height: 15rem;",
        expanded: "arrowDown",
        respondentInfoHidden: "information",
        respondentInfoHiddenCollapsed: "max-height: 4rem; margin-bottom: 2rem;",
        respondentInfoExposed: "remove",
        pinned: "remove",
        notpinned: "pin"
      },
      allowCollapse: false,
      itemIsPinned: false,
      itemIsCollapsed: false,
      isRevealingDropped: false,
      isCorrectingSentiment: false,
      suggestedAdjustment: 0,
      themeAdd: {
        isExpanded: false,
        themeName: "",
        filter: ""
      },
      themeMatchSource: [],
      themeIgnoredMatchSource: [],
      showIgnoredThemes: false
    }
  },
  computed: {
    adjustedSentiment() {
      for (
        let i = 0;
        i < this.textAnalysisObject.adjustedSentiments.length;
        i++
      ) {
        if (
          this.textAnalysisObject.adjustedSentiments[i].responseId ==
          this.item.id
        ) {
          return parseFloat(
            this.textAnalysisObject.adjustedSentiments[i].adjustedSentiment
          )
        }
      }
      return null
    },
    suggestedIsNew() {
      if (this.suggestedAdjustment != this.compiledSentiment) {
        return true
      }
      return false
    },
    highlightRanges: function () {
      return this.convertHighlightItemsToRanges(this.highlightItems)
    },
    highlightRangesTree: function () {
      return new CustomIntervalTree(this.highlightRanges).getTree()
    },
    textResponseContent: function () {
      const responseContent = this.item.response
      return responseContent
    },
    selectedTextQuestionId: function () {
      return this.textAnalysisObject.selectedTextQuestion._id
    },
    $UISelectable: function () {
      if (!this.isComponentMounted) return
      return this.$refs["ui-selectable"]
    },
    activeTab: function () {
      if (this.textAnalysisObject) {
        if (this.textAnalysisObject.activeTab) {
          return this.textAnalysisObject.activeTab
        }
      }
      return ""
    },
    isBanned: function () {
      if (this.textAnalysisObject) {
        if (this.textAnalysisObject.bannedComments.length) {
          let newId = this.item.id
          if (this.item.id.$oid) {
            newId = this.item.id
          }
          if (this.textAnalysisObject.bannedComments.includes(newId)) {
            return true
          }
        }
      }
      return false
    },
    isDropped: function () {
      if (this.textAnalysisObject) {
        if (this.textAnalysisObject.selectedThemeUUIDs) {
          for (let i = 0; i < this.sortedThemes.length; i++) {
            if (
              this.textAnalysisObject.selectedThemeUUIDs.includes(
                this.sortedThemes[i]._id
              )
            ) {
              if (this.sortedThemes[i].dropped.length) {
                let itemId = this.item.id
                if (this.item.id.$oid) {
                  itemId = this.item.id.$oid
                }
                if (this.sortedThemes[i].dropped.includes(itemId)) {
                  return true
                }
              }
            }
          }
        }
      }
      return false
    },
    isAdded: function () {
      if (this.textAnalysisObject) {
        if (this.textAnalysisObject.selectedThemeUUIDs) {
          for (let i = 0; i < this.sortedThemes.length; i++) {
            if (
              this.textAnalysisObject.selectedThemeUUIDs.includes(
                this.sortedThemes[i]._id
              )
            ) {
              if (this.sortedThemes[i].notes.length) {
                let newId = this.item.id
                if (this.item.id.$oid) {
                  newId = this.item.id.$oid
                }
                for (let x = 0; x < this.sortedThemes[i].notes.length; x++) {
                  if (
                    this.sortedThemes[i].notes[x].responseId == newId &&
                    this.sortedThemes[i].notes[x].content == ""
                  ) {
                    return true
                  }
                }
              }
            }
          }
        }
      }
      return false
    },
    isSelected: function () {
      if (
        this.textAnalysisObject.selectedResponseIds &&
        this.textAnalysisObject.selectedResponseIds.length
      ) {
        for (
          let i = 0;
          i < this.textAnalysisObject.selectedResponseIds.length;
          i++
        ) {
          if (this.textAnalysisObject.selectedResponseIds[i] == this.item.id) {
            return true
          }
        }
      }
      return false
    },
    compiledSentiment() {
      if (this.adjustedSentiment == null) {
        return this.item.response_sentiment.compound_en
      } else {
        return this.adjustedSentiment
      }
    },
    listOfThemes() {
      let list_of_themes = []
      let unique_theme_names = []
      if (
        this.highlightItems["theme-keyword"] &&
        this.highlightItems["theme-keyword"].length
      ) {
        list_of_themes = this.highlightItems["theme-keyword"].filter(
          (theme) => !this.specificThemeIsDropped(theme.theme_name)
        )
        unique_theme_names = list_of_themes.map((theme) => theme.theme_name)
      }
      if (
        this.highlightItems["theme-note"] &&
        this.highlightItems["theme-note"].length
      ) {
        list_of_themes = list_of_themes.concat(
          this.highlightItems["theme-note"].filter(
            (note) => !unique_theme_names.includes(note.theme_name)
          )
        )
      }
      return list_of_themes
    },
    listOfDroppedThemes() {
      let list_of_themes = []
      let theme_names = this.listOfThemes.map((theme) => theme.theme_name)
      if (
        this.highlightItems["theme-keyword"] &&
        this.highlightItems["theme-keyword"].length
      ) {
        list_of_themes = this.highlightItems["theme-keyword"].filter(
          (theme) =>
            this.specificThemeIsDropped(theme.theme_name) &&
            !theme_names.includes(theme.theme_name)
        )
      }
      return list_of_themes
    },
    listOfUnusedThemes() {
      let theme_names = this.listOfThemes.map((theme) => theme.theme_name)
      let list_of_themes = this.sortedThemes.filter(
        (theme) => !theme_names.includes(theme.name)
      )
      if (this.themeAdd.filter) {
        list_of_themes = list_of_themes.filter((theme) =>
          theme.name.toLowerCase().includes(this.themeAdd.filter.toLowerCase())
        )
      }
      return list_of_themes
    }
  },
  async mounted() {
    this.isComponentMounted = true
    this.highlightedHTMLString = this.createHighlightedHTMLString()

    this.setSuggestedToCompiled()
    await this.checkIfShouldExpand()
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    async toggleBan(itemId) {
      await this.$emit("toggle-response-hide", itemId)
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    selectThisResponse() {
      let selection = []
      if (!this.isSelected) {
        selection.push(this.item)
      }
      this.selectionChanged(selection)
    },
    async addThemeToRow(theme) {
      let theseThemes = this.deepCloneObj(this.sortedThemes)
      for (let i = 0; i < theseThemes.length; i++) {
        if (theseThemes[i]._id == theme._id) {
          let note = {
            content: "",
            length: 0,
            responseId: this.item.id,
            row_index: this.item.row_index,
            source: {
              questionId: this.clientQuestionId,
              responseText: ""
            },
            startIndex: 0,
            text: ""
          }
          theseThemes[i].notes.push(note)
          let themes = []

          if (theseThemes[i]._id) {
            if (theseThemes[i]._id.$oid) {
              theseThemes[i]._id = theseThemes[i]._id.$oid
            }
          }
          if (theseThemes[i].is_proposed) {
            delete theseThemes[i].is_proposed
            delete theseThemes[i]._id
          }
          if (theseThemes[i].is_autogenerated) {
            delete theseThemes[i].is_autogenerated
            delete theseThemes[i]._id
          }

          themes.push(theseThemes[i])
          let payload = {
            themes: themes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const savedThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (savedThemes.length) {
            let updatedThemeInfo = {
              themes: savedThemes,
              source: "CreateNote"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
            this.themeAdd.isExpanded = false
            this.themeAdd.filter = ""
            this.themeAdd.themeName = ""
          }
        }
      }
    },
    setThemeIgnoredMatchSource(index) {
      let filteredThemes = []
      filteredThemes = this.themeIgnoredMatchSource.filter(
        (theme) => theme == index
      )
      if (filteredThemes.length) {
        this.themeIgnoredMatchSource = this.themeIgnoredMatchSource.filter(
          (theme) => theme != index
        )
      } else {
        this.themeIgnoredMatchSource.push(index)
      }
      this.themeMatchSource = []
    },
    setShowIgnoredThemes() {
      this.showIgnoredThemes = !this.showIgnoredThemes
      this.themeAdd.isExpanded = false
      this.themeAdd.filter = ""
      this.themeAdd.themeName = ""
      this.themeMatchSource = []
    },
    setAddThemeIsExpanded() {
      this.themeAdd.isExpanded = !this.themeAdd.isExpanded
      this.showIgnoredThemes = false
      this.themeMatchSource = []
    },
    keywordMatches(theme_name) {
      let found_themes = 0
      if (this.highlightItems["theme-keyword"]) {
        for (let i = 0; i < this.highlightItems["theme-keyword"].length; i++) {
          if (
            this.highlightItems["theme-keyword"][i].theme_name == theme_name
          ) {
            found_themes =
              found_themes +
              this.highlightItems["theme-keyword"][i].matching_reasons.filter(
                (reason) =>
                  this.item.response
                    .toLowerCase()
                    .includes(reason.toLowerCase())
              ).length
          }
        }
      }
      return found_themes
    },
    noteMatches(theme_name) {
      let found_themes = 0
      if (this.highlightItems["theme-note"]) {
        for (let i = 0; i < this.highlightItems["theme-note"].length; i++) {
          if (this.highlightItems["theme-note"][i].theme_name == theme_name) {
            found_themes =
              found_themes +
              this.highlightItems["theme-note"][i].matching_reasons.filter(
                (reason) => reason !== ""
              ).length
          }
        }
      }
      return found_themes
    },
    noteManualMatches(theme_name) {
      let found_themes = 0
      if (this.highlightItems["theme-note"]) {
        for (let i = 0; i < this.highlightItems["theme-note"].length; i++) {
          if (this.highlightItems["theme-note"][i].theme_name == theme_name) {
            found_themes =
              found_themes +
              this.highlightItems["theme-note"][i].matching_reasons.filter(
                (reason) => reason == ""
              ).length
          }
        }
      }
      return found_themes
    },
    specificThemeIsDropped(theme_name) {
      let isDropped = this.sortedThemes.filter(
        (theme) =>
          theme.name == theme_name && theme.dropped.includes(this.item.id)
      )
      if (isDropped.length) {
        return true
      } else {
        return false
      }
    },
    async addNewTheme() {
      let notes = {
        content: "",
        length: 0,
        responseId: this.item.id,
        row_index: this.item.row_index,
        source: {
          questionId: this.clientQuestionId,
          responseText: ""
        },
        startIndex: 0,
        text: ""
      }

      let notesForSaving = []
      if (Object.keys(notes).length > 0) {
        notesForSaving.push(notes)
      }
      this.shouldFocusFirstTheme = true

      let found_colors = this.sortedThemes.map((theme) => theme.theme_color)

      let newColor = "#6a88aa"
      let assignedColor = await this.assignColor(found_colors)
      if (assignedColor) {
        newColor = assignedColor
      }

      let new_theme = {
        text_analysis_uuid: this.textAnalysisObject.uuid,
        name: this.themeAdd.themeName,
        keywords: [],
        keyword_matches: [],
        filtered_keyword_matches: [],
        dropped: [],
        added: [],
        coverage_queue: 0,
        coverage: {},
        notes: notesForSaving,
        selected: false,
        created_on: moment().valueOf(),
        theme_color: newColor
      }

      let themes = []
      themes.unshift(new_theme)
      if (new_theme) {
        let payload = {
          themes: themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (theseThemes.length) {
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "CreateNote"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.themeAdd.isExpanded = false
          this.themeAdd.filter = ""
          this.themeAdd.themeName = ""
        }
      }
    },
    async checkIfShouldExpand() {
      if (this.allowCollapse == false) {
        return
      }
      if (this.textAnalysisObject.expandedResponses) {
        let found = false
        for (
          let i = 0;
          i < this.textAnalysisObject.expandedResponses.length;
          i++
        ) {
          if (this.textAnalysisObject.expandedResponses[i] == this.item.id) {
            found = true
          }
        }
        if (found) {
          this.itemIsCollapsed = false
        }
      }
    },
    shouldAllowCollapse() {
      if (this.$refs["ui-selectable"]) {
        if (this.$refs["ui-selectable"].$el.clientHeight > 100) {
          this.allowCollapse = true
          this.itemIsCollapsed = true
        }
      }
    },
    collapseThisItem() {
      this.itemIsCollapsed = !this.itemIsCollapsed
      const skipUpdateEmit = true
      this.setResponseAsExpanded(this.item.id, skipUpdateEmit)
    },
    applyChangeToSentiment() {
      let adjustedSentiments = this.deepCloneObj(
        this.textAnalysisObject.adjustedSentiments
      )
      let foundMatch = false
      for (let i = 0; i < adjustedSentiments.length; i++) {
        if (adjustedSentiments[i].responseId == this.item.id) {
          adjustedSentiments[i].newSentiment = this.suggestedAdjustment
          this.$emit("adjust-sentiment", adjustedSentiments[i])
          this.$emit("runSortAndSelect")
          foundMatch = true
          break
        }
      }

      if (!adjustedSentiments.length || foundMatch == false) {
        let sentimentUpdates = {
          responseId: this.item.id,
          newSentiment: this.suggestedAdjustment
        }
        this.$emit("adjust-sentiment", sentimentUpdates)
        this.$emit("runSortAndSelect")
      }

      this.isCorrectingSentiment = false
    },
    // This is the same as the computed, but running here to ensure it calculates ahead of computed
    setSuggestedToCompiled() {
      let foundAdjusted = false
      if (this.textAnalysisObject.adjustedSentiments) {
        for (
          let i = 0;
          i < this.textAnalysisObject.adjustedSentiments.length;
          i++
        ) {
          if (
            this.textAnalysisObject.adjustedSentiments[i].responseId ==
            this.item.id
          ) {
            this.suggestedAdjustment =
              this.textAnalysisObject.adjustedSentiments[i].adjustedSentiment

            foundAdjusted = true
            break
          }
        }
      }

      if (!foundAdjusted && this.item.response_sentiment) {
        this.suggestedAdjustment = this.item.response_sentiment.compound_en
      }
    },
    async cancelAdjustment() {
      this.isCorrectingSentiment = false
      this.suggestedAdjustment = this.compiledSentiment
    },
    async reverseAdjustment() {
      this.isCorrectingSentiment = false
    },
    // When keywords match, you can manually remove
    async dropFromKeywordMatch(themeID) {
      this.showIgnoredThemes = false
      let thisTheme = ""
      if (themeID && themeID != "selected") {
        thisTheme = themeID
      }
      if (thisTheme) {
        let response_id = this.item.id
        if (this.item.id.$oid) {
          response_id = this.item.id.$oid
        }

        let newThemes = this.deepCloneObj(this.sortedThemes)
        let updateThisTheme = []
        for (let x = 0; x < newThemes.length; x++) {
          if (newThemes[x]._id == thisTheme) {
            const isFound = newThemes[x].dropped.includes(response_id)
            if (isFound) {
              newThemes[x].dropped = newThemes[x].dropped.filter(
                (item) => item !== response_id
              )
            } else {
              newThemes[x].dropped.push(response_id)
            }
            updateThisTheme.push(newThemes[x])
            break
          }
        }

        let payload = {
          themes: updateThisTheme,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const savedThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (savedThemes.length) {
          let updatedThemeInfo = {
            themes: savedThemes,
            source: "ResponseList"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
        }
      }
    },
    // This is for when a user adds an entire response to a theme (which is an empty note)
    dropNoteFromResponse: function (themeID) {
      let thisTheme = ""
      if (themeID) {
        thisTheme = themeID
      }
      if (thisTheme) {
        let newId = this.item.id
        if (this.item.id.$oid) {
          newId = this.item.id.$oid
        }
        const copiedThemes = this.deepCloneObj(this.sortedThemes)
        for (let x = 0; x < copiedThemes.length; x++) {
          // First, find this note. It must exist with starting index as 0, content as blank, and responseId matching
          const thisNote = copiedThemes[x].notes.find((obj) => {
            if (
              obj.responseId === newId &&
              obj.startIndex === 0 &&
              obj.content == ""
            )
              return obj
          })
          if (thisNote) {
            this.saveTextThemeNotesRemoved(thisTheme, thisNote)
            break
          }
        }
      }
    },
    toggleRespondentInfo: function () {
      this.isRespondentInfoExpanded = !this.isRespondentInfoExpanded
    },
    parseKeywordForDropping(selection) {
      this.dropFromKeywordMatch(selection.themeId)
    },
    handleSelectableEvent: function (event, selection) {
      if (typeof selection === "object") {
        this.$emit(event, {
          responseId: this.item.id,
          ...selection,
          source: {
            questionId: this.selectedTextQuestionId,
            responseText: ""
          },
          row_index: this.item.row_index
        })
        // responseText: this.textResponseContent
        return
      }
      this.$emit(event, selection)
    },
    confirmBanResponse: function () {
      if (this.propReadOnly) return
      this.setConfirmText({
        btn: "yes",
        title: "Confirm ban response?",
        message: "Are you sure you want to ban the selected response item?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("ResponseListItem")
      this.setConfirmStatus(false)
      this.setConfirmTarget(this.item.id)
      this.setConfirmIsVisible(true)
    },

    /* highlighting methods */
    convertHighlightItemsToRanges: function (items) {
      const ranges = []

      for (let [type, arr] of Object.entries(items)) {
        // continue if not a supported highlight type or arr is not an arry
        if (!Object.keys(HIGHLIGHT_TYPES).includes(type)) continue
        if (!arr || !Array.isArray(arr)) continue

        for (let el of arr) {
          if (el.type == "keywords" || el.type == "notes") {
            for (let elKeywords of el.matching_reasons) {
              if (
                el.type == "keywords" &&
                this.specificThemeIsDropped(el.theme_name)
              ) {
                continue
              }
              if (typeof elKeywords === "string" && elKeywords.trim() !== "") {
                let re = new RegExp(elKeywords, "gi")
                let match
                while ((match = re.exec(this.textResponseContent)) !== null) {
                  let color = "lightgreen"
                  if (el.color) {
                    color = el.color
                  }
                  ranges.push({
                    begin: match.index,
                    end: match.index + elKeywords.length,
                    text: elKeywords,
                    type: type,
                    color: color,
                    theme_id: el.theme_id
                  })
                }
              }
            }
          }

          if (typeof el === "object" && el.responseId === this.item.id) {
            let color = "lightgreen"
            if (el.color) {
              color = el.color
            }
            ranges.push({
              begin: el.startIndex,
              end: el.startIndex + el.content.length,
              text: el.content,
              tooltipText: el.text, // note text
              type: type,
              color: color,
              theme_id: el.theme_id
            })
          } else if (typeof el === "string" && el.trim() !== "") {
            let re = new RegExp(el, "gi")
            let match
            while ((match = re.exec(this.textResponseContent)) !== null) {
              ranges.push({
                begin: match.index,
                end: match.index + el.length,
                text: el,
                type: type,
                color: "",
                theme_id: el.theme_id
              })
            }
          }
        }
      }
      return ranges
    },
    createHighlightedHTMLString() {
      const root = this.highlightRangesTree[0]
      const rootText = this.textResponseContent
      const processNode = (parentNode, startIndex = 0) => {
        let children = parentNode.children.sort((c1, c2) => c1.a - c2.a)

        // return text content if no or invalid children
        if (!Array.isArray(children) || children.length === 0) {
          return rootText.slice(parentNode.a, parentNode.b)
        }

        let string = ""
        for (let i = 0; i < children.length; i++) {
          let childNode = children[i]
          let color = "lightgreen"
          if (childNode.data && childNode.data.color) {
            color = childNode.data.color
          }

          string += rootText.slice(startIndex, childNode.a)
          startIndex = childNode.a
          string +=
            // "<mark class='main__highlight " +
            "<mark style='color:" +
            "white !important;" +
            "background-color:" +
            color +
            "'" +
            "class='main__highlight " +
            HIGHLIGHT_TYPES[childNode.data.type].className +
            // " " +
            //Array.from(childNode.meta.classNames).join(" ") +
            childNode.data.id +
            "' data-type='" +
            childNode.data.type +
            "' data-theme='" +
            childNode.data.theme_id +
            "' data-a='" +
            childNode.a +
            (childNode.data.tooltipText
              ? "' data-tooltip='" + childNode.data.tooltipText
              : "") +
            (childNode.meta.attrs.has("no-tabindex")
              ? "' data-tabindex='-1"
              : "") +
            "'>" +
            processNode(childNode, startIndex) +
            "</mark>"

          // move start index to end of child block
          startIndex = childNode.b

          if (i === children.length - 1) {
            // last node reached
            string += rootText.slice(childNode.b, parentNode.b)
            startIndex = parentNode.b
          }
        }

        return string
      }

      return processNode(root)
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2")
    },
    async changeAdjustedSentiment() {
      if (this.adjustedSentiment == null) {
        let sentimentUpdates = {
          responseId: this.item.id,
          newSentiment: null
        }
        await this.$emit("adjust-sentiment", sentimentUpdates)
      } else {
        let sentimentUpdates = {
          responseId: this.item.id,
          newSentiment: this.adjustedSentiment
        }
        await this.$emit("adjust-sentiment", sentimentUpdates)
        await this.$emit("runSortAndSelect")
      }
      let newSentiment
      if (this.adjustedSentiment >= -2 && this.adjustedSentiment <= 2) {
        if (this.adjustedSentiment == -2) {
          newSentiment = -1
        } else if (this.adjustedSentiment == -1) {
          newSentiment = -0.5
        } else if (this.adjustedSentiment == 0) {
          newSentiment = 0
        } else if (this.adjustedSentiment == 1) {
          newSentiment = 0.5
        } else if (this.adjustedSentiment == 2) {
          newSentiment = 1
        }
        let sentimentUpdates = {
          responseId: this.item.id,
          newSentiment: newSentiment
        }
        await this.$emit("adjust-sentiment", sentimentUpdates)
        await this.$emit("runSortAndSelect")
      }
    },
    toggleTheme() {
      if (!this.textAnalysisObject.selectedThemeUUIDs.length) {
        this.setSelectedThemeUUIDs([])
      } else {
        this.setSelectedThemeUUIDs(this.textAnalysisObject.selectedThemeUUIDs)
      }
    }
  },
  watch: {
    confirmStatus: async function (val) {
      // only delete if status is true
      if (
        val &&
        this.confirmSourceComponent === "ResponseListItem" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.item.id
      ) {
        await this.$emit("ban-response", this.item.id)
      }
    },
    adjustedSentiment: async function (val) {
      if (val == null) {
        return
      }
      if (val >= -2 && val <= 2) {
        let newID
        if (this.item.id.$oid) {
          newID = this.item.id.$oid
        } else {
          newID = this.item.id
        }
        let sentimentUpdates = {
          responseId: newID,
          newSentiment: val
        }
        await this.$emit("adjust-sentiment", sentimentUpdates)
      }
    },
    highlightItems: function (val) {
      if (!val || typeof val !== "object") return
      this.highlightedHTMLString = this.createHighlightedHTMLString()
      this.isWrapMarkPending = true
    },
    highlightedHTMLString: function (val) {
      if (!val || val === "") return
      this.$nextTick(() => {
        // wrap mark elements
        if (this.$UISelectable) {
          this.$UISelectable.wrapMark()
          this.isWrapMarkPending = false
        }
      })
    },
    $UISelectable: function (val) {
      if (!val) return
      this.$nextTick(() => {
        // wrap mark elements
        if (this.isWrapMarkPending) {
          this.$UISelectable.wrapMark()
          this.isWrapMarkPending = false
        }
        this.shouldAllowCollapse()
        this.checkIfShouldExpand()
      })
    },
    activeTab: function (val) {
      if (!val) return
      this.shouldAllowCollapse()
      this.checkIfShouldExpand()
    }
  }
}
</script>
