<template>
  <div
    class="search__container"
    style="align-items: center; justify-content: center"
  >
    <div style="display: flex; margin: 0 2rem; width: 100%">
      <h2>
        <span>
          <SvgIconDecorative
            icon="filter"
            class="editor-question__pending-icon"
            style="color: black"
          /> </span
        >Filters
      </h2>
    </div>
    <div class="search__bar-wrapper" style="display: flex; flex-direction: row">
      <div style="border-radius: 4px; margin: 0.5rem">
        <VisibleText> Apply a filter </VisibleText>
        <ButtonIconOnly
          style="
            border: 1px solid var(--outline-colour);
            background: var(--secondary-ui-colour);
            border-radius: 4px;
            margin: 0.5rem;
            margin-right: 1rem;
          "
          icon="filter"
          @click-handler="filterMode = true"
          :propReadOnly="propReadOnly"
        >
          <VisibleText style="font-weight: 800">filter by category</VisibleText>
        </ButtonIconOnly>
        <VisibleText> or search for keywords </VisibleText>
      </div>

      <div class="search__bar" style="position: relative">
        <label for="search-input" class="search__bar-input-label">
          <span class="sr-only">
            <VisibleText>Search keywords and press enter</VisibleText>
          </span>
        </label>
        <input
          ref="search-input"
          class="search__bar-input"
          id="search-input"
          type="text"
          name="searchBar__generic"
          :placeholder="visiblePlaceholder"
          v-model="searchString"
          v-on:keyup.enter="handleSearch"
          :disabled="isSearching"
        />

        <label
          class="search__bar-advanced-chkbox-label"
          for="search-similar-chkbox"
          :style="!hasFetchedSentences ? 'cursor: not-allowed' : ''"
          @mouseover="checkToOpenNoSentencesTip"
          @mouseleave="openNoSentencesTip = false"
        >
          <input
            id="search-similar-chkbox"
            class="search__bar-advanced-chkbox"
            type="checkbox"
            v-model="advancedSearchEnabled"
            :disabled="isSearching || !hasFetchedSentences"
          />
          <VisibleText>Find similar</VisibleText>
        </label>

        <div
          @click="showSentencesInfo = true"
          style="
            display: flex;
            cursor: pointer;
            border: 1px solid var(--outline-colour);
            border-radius: 3rem;
            height: 1rem;
            width: 1rem;
            margin-left: 0.2rem;
          "
        >
          <span style="padding-left: 0.25rem; font-size: 0.8rem"> ?</span>
        </div>

        <SentencesMiniGuide
          style="transform: translateY(0)"
          @closingGuide="showSentencesInfo = false"
          :showSentencesInfo="showSentencesInfo"
        ></SentencesMiniGuide>

        <div
          v-if="openNoSentencesTip"
          style="
            position: absolute;
            right: -3rem;
            width: 10rem;
            padding: 0.5rem;
            background: #fff;
            border: 1px solid var(--outline-colour);
            border-radius: 3px;
          "
        >
          <VisibleText>
            You need to preprocess sentences in 'AI options' to use this.
          </VisibleText>
        </div>

        <label
          class="search__bar-advanced-chkbox-label"
          for="search-advanced-chkbox"
          :style="!hasFetchedLemmas ? 'cursor: not-allowed' : ''"
          @mouseover="checkToOpenNoLemmasTip"
          @mouseleave="openNoLemmasTip = false"
        >
          <input
            id="search-advanced-chkbox"
            class="search__bar-advanced-chkbox"
            type="checkbox"
            v-model="localIncludeSmartCoverage"
            :disabled="isSearching || !hasFetchedLemmas"
            @click="updateIncludeSmartCoverage"
          />
          <VisibleText>Smart search</VisibleText>
        </label>

        <div
          @click="showLemmasInfo = true"
          style="
            display: flex;
            cursor: pointer;
            border: 1px solid var(--outline-colour);
            border-radius: 3rem;
            height: 1rem;
            width: 1rem;
            margin-left: 0.2rem;
          "
        >
          <span style="padding-left: 0.25rem; font-size: 0.8rem"> ?</span>
        </div>

        <LemmasMiniGuide
          style="transform: translateY(0)"
          @closingGuide="showLemmasInfo = false"
          :showLemmasInfo="showLemmasInfo"
        ></LemmasMiniGuide>

        <div
          v-if="openNoLemmasTip"
          style="
            position: absolute;
            right: -10rem;
            width: 10rem;
            padding: 0.5rem;
            background: #fff;
            border: 1px solid var(--outline-colour);
            border-radius: 3px;
          "
        >
          <VisibleText>
            You need to preprocess lemmas in 'AI options' to use this.
          </VisibleText>
        </div>
      </div>
      <div
        style="
          color: var(--warning-text-colour);
          padding: 0.5rem;
          background: #fff;
          border-radius: 3px;
        "
      >
        <VisibleText v-if="noResultsFound">No results found.</VisibleText>
      </div>
      <div v-if="searchingForWordAssociations">
        {{ searchingForWordAssociations }}
        <span class="search__searching-spinner spinner"></span>
      </div>

      <AlsoFound
        ref="search-also-found"
        :isFromSearch="true"
        :textAnalysisObject="textAnalysisObject"
        class="search__also-found"
        @save="saveAlsoFoundKeyword"
        :isVisible="showAlsoFound"
      >
        <button class="also-found__save-all-btn" @click="saveAlsoFound">
          <VisibleText>Save all</VisibleText>
        </button>
      </AlsoFound>
    </div>
    <p
      class="search__searching-text"
      v-if="isSearching"
      style="margin-left: 1rem"
    >
      <VisibleText>Searching</VisibleText>...
      <span v-if="advancedSearchEnabled">
        <VisibleText
          >Please note that performing advanced search can take longer than
          usual.
        </VisibleText>
      </span>
      <span class="search__searching-spinner spinner"></span>
    </p>
    <div class="search__rounded-pills-wrapper" style="margin: 1rem">
      <div class="search__rounded-pills filter-by" v-if="isFilterPillsVisible">
        <div style="display: flex; flex-direction: column; margin-left: 1rem">
          <div style="display: flex; flex-direction: row">
            <span
              v-if="textAnalysisObject.search.searchString"
              class="search__rounded-pills-label"
            >
              <VisibleText>Search</VisibleText>:
            </span>
            <div>
              <RoundedPill
                icon="search"
                :hideDelete="propReadOnly"
                :value="textAnalysisObject.search.searchString"
                :css-styles="{
                  backgroundColor: 'lightgreen',
                  color: 'var(--base-colour)'
                }"
                @remove="resetThisSearch"
                :hasGenericClick="true"
                @genericClick="showSearchDetails = !showSearchDetails"
                v-if="isSearchMode"
              />
              <div
                v-if="showSearchDetails == true"
                style="
                  z-index: 10;
                  border-top: 0;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  color: var(--base-colour);
                  position: absolute;
                  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                  background: var(--base-lighter-ui-colour);
                  border: 1px solid var(--outline-light-colour);
                  border-radius: 8px;
                  width: 13rem;
                "
              >
                <div
                  style="
                    padding: 0.75rem;
                    font-weight: 500;
                    display: flex;
                    flex-direction: row;
                  "
                >
                  <VisibleText>
                    Search results found:
                    {{
                      this.textAnalysisObject.search.searchResultsIds.length
                    }}</VisibleText
                  >
                  <span>
                    <ButtonIconOnly
                      class="ui-rounded-pill__close-btn"
                      icon="remove"
                      @click-handler="showSearchDetails = false"
                    >
                      <VisibleText>close</VisibleText>
                    </ButtonIconOnly>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="parsedFromFilter.length">
            <div style="display: flex; flex-direction: row">
              <span class="search__rounded-pills-label">
                <VisibleText>Categories</VisibleText>:
              </span>
              <RoundedPill
                v-for="(parsedFilter, index) in parsedFromFilter"
                :key="index"
                :hideDelete="propReadOnly"
                icon="filter"
                :value="filterDetails(parsedFilter)"
                :css-styles="{ backgroundColor: 'var(--amulet-green)' }"
                @remove="$emit('removeThisFilter', parsedFilter)"
              />
            </div>
          </div>
          <div v-if="selectedThemes.length">
            <div style="display: flex; flex-direction: row">
              <span class="search__rounded-pills-label">
                <VisibleText>Themes</VisibleText>:
              </span>
              <RoundedPill
                v-for="(selectedTheme, index) in selectedThemes"
                :key="index"
                icon="list"
                :hideDelete="propReadOnly"
                :value="selectedTheme.name"
                :css-styles="{ backgroundColor: 'var(--secondary)' }"
                @remove="
                  setSelectedThemeUUIDs(
                    textAnalysisObject.selectedThemeUUIDs.filter(
                      (theme) => theme !== selectedTheme._id
                    )
                  )
                "
              />
              <ButtonIconOnly
                v-if="textAnalysisObject.selectedThemeUUIDs.length > 1"
                @click-handler="setSelectedThemeUUIDs([])"
                style="
                  border: 1px solid var(--outline-colour);
                  background: var(--secondary-ui-colour);
                  border-radius: 4px;
                  margin-top: 0.5rem;
                "
                :smaller="true"
                icon="remove"
              >
                <VisibleText style="font-weight: 800">remove all</VisibleText>
              </ButtonIconOnly>
            </div>
          </div>
          <div v-if="isVisFilterActivated">
            <div style="display: flex; flex-direction: row">
              <span class="search__rounded-pills-label">
                <VisibleText>Pinned</VisibleText>:
              </span>
              <RoundedPill
                icon="list"
                :hideDelete="propReadOnly"
                value="Selected responses"
                :css-styles="{
                  backgroundColor: '#56b4e9'
                }"
                @remove="changeSelection"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- This is the same as ThemesAdd -->
    <SaveAlsoFoundModal
      type="keyword"
      uid="save-also-found-modal"
      :textAnalysisObject="textAnalysisObject"
      :selection="alsoFoundModal.selection"
      :autoCreateTheme="alsoFoundModal.autoCreateTheme"
      :autoCreateThemeTitle="alsoFoundModal.autoCreateThemeTitle"
      :hasFetchedLemmas="hasFetchedLemmas"
      :unsortedResponses="unsortedResponses"
      :autoCreateThemeKeywords="alsoFoundModal.autoCreateThemeKeywords"
      :sortedResponsesLength="sortedResponsesLength"
      @updateTextObject="$emit('updateTextObject', $event)"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      :show="isSaveAlsoFoundModalVisible"
      @closeModal="closeModal"
    />
    <AddFilter
      :show="filterMode"
      :type="'own'"
      :data="allQuestions"
      @close-modal="filterMode = false"
      @confirmNewFilter="confirmNewFilter"
    />
  </div>
</template>

<script>
// Components
import AlsoFound from "./AlsoFound.vue"
import RoundedPill from "../UI/RoundedPill.vue"
import SaveAlsoFoundModal from "../Modals/ThemesAdd.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import AddFilter from "@/components/Project/ProjectAnalysis/Components/AddFilter.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import LemmasMiniGuide from "@/components/UserGuide/LemmasMiniGuide.vue"
import SentencesMiniGuide from "@/components/UserGuide/SentencesMiniGuide.vue"

// Mixins
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"

export default {
  name: "Search",
  mixins: [textAnalysisMixin],
  components: {
    AlsoFound,
    RoundedPill,
    SaveAlsoFoundModal,
    ButtonIconOnly,
    AddFilter,
    SvgIconDecorative,
    LemmasMiniGuide,
    SentencesMiniGuide
  },
  props: {
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedResponsesProp: {
      type: Array,
      required: false,
      default: () => []
    },
    sortedThemes: {
      type: Array,
      required: false,
      default: () => []
    },
    unsortedResponses: {
      type: Array,
      default: () => []
    },
    responsesCount: {
      type: Number,
      required: false,
      default: () => 0
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    searchingForWordAssociations: {
      type: String,
      default: () => ""
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      filterMode: false,
      thisSearchString: "",
      advancedSearchEnabled: false,
      isSaveAlsoFoundModalVisible: false,
      alsoFoundModal: {
        autoCreateTheme: false,
        autoCreateThemeTitle: "",
        autoCreateThemeKeywords: [],
        selection: null
      },
      localSearch: {
        searchString: "",
        alsoFound: [],
        searchResultsIds: [],
        searchResults: [],
        excludeAssociations: false,
        wordAssociations: [],
        isFetchingWordAssociations: false
      },
      showLemmasInfo: false,
      showSentencesInfo: false,
      localIncludeSmartCoverage: false,
      isSearching: false,
      showAlsoFound: false,
      noResultsFound: false,
      showSearchDetails: false,
      openNoSentencesTip: false,
      openNoLemmasTip: false
    }
  },
  computed: {
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    searchString: {
      get() {
        return this.thisSearchString
      },
      set(val) {
        this.thisSearchString = val
        if (val === "") {
          this.setSearch({
            searchString: "",
            searchResults: []
          })
          this.isSearching = false
        }
      }
    },
    $alsoFound: function () {
      return this.$refs["search-also-found"]
    },
    selectedThemes: function () {
      if (this.textAnalysisObject.selectedThemeUUIDs.length) {
        return this.sortedThemes.filter((theme) =>
          this.textAnalysisObject.selectedThemeUUIDs.includes(theme._id)
        )
      }
      return []
    },
    visiblePlaceholder: function () {
      return this.checkTranslationCache(
        "Enter a search keyword and press enter.",
        "searchBar__generic",
        this.$store.getters.getLanguage
      )
    },
    isFilterPillsVisible: function () {
      return (
        this.selectedThemes.length ||
        this.isVisFilterActivated ||
        this.parsedFromFilter.length ||
        this.textAnalysisObject.search.searchString.length
      )
    },

    parsedFromFilter() {
      if (
        !this.textAnalysisObject.filterParams ||
        !this.textAnalysisObject.filterParams.operands
      ) {
        return []
      }
      let filters = []
      for (
        let i = 0;
        i < this.textAnalysisObject.filterParams.operands.length;
        i++
      ) {
        if (
          this.textAnalysisObject.filterParams.operands[i].client_question_id
        ) {
          const thisQuestion = this.allQuestions.find(
            (q) =>
              q._id.$oid ===
              this.textAnalysisObject.filterParams.operands[i]
                .client_question_id
          )
          if (thisQuestion) {
            let thisObject = {
              question_text: thisQuestion.question_text,
              question_title: thisQuestion.question_title,
              valuesForComparison:
                this.textAnalysisObject.filterParams.operands[i].values,
              filter_index: i
            }
            filters.push(thisObject)
          }
        } else {
          if (
            this.textAnalysisObject.filterParams.operands[i].operator ==
            "created_after"
          ) {
            let thisObject = {
              filter_index: i,
              question_title:
                "After " +
                this.textAnalysisObject.filterParams.operands[i].values
            }
            filters.push(thisObject)
          }
        }
      }
      return filters
    }
  },
  created() {
    // // focus search input
    this.focusSearchInput = this.focusSearchInput.bind(this)
    if (!this.hasFetchedSentences) {
      this.advancedSearchEnabled = false
    }
    if (
      this.hasFetchedSentences &&
      this.textAnalysisObject.search.searchMode &&
      this.textAnalysisObject.search.searchMode == "similar sentences"
    ) {
      this.advancedSearchEnabled = true
    }
    if (this.includeSmartCoverage && this.includeSmartCoverage == "include") {
      this.localIncludeSmartCoverage = true
    } else {
      this.localIncludeSmartCoverage = false
    }
  },
  methods: {
    async updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      await this.$emit("updateTextObject", object)
    },
    async emitSortAndSelect() {
      await this.$emit("emitSortAndSelect")
    },
    changeSelection() {
      let selection = []
      this.selectionChanged(selection)
    },
    confirmNewFilter(selected) {
      if (selected) {
        this.$emit("updateFilterParam", selected)
        this.filterMode = false
      }
    },
    filterDetails(filter) {
      let title = "Question: "
      if (filter.question_title) {
        title = title + filter.question_title
      } else if (filter.question_text) {
        title = title + filter.question_text
      }
      for (let i = 0; i < filter.valuesForComparison.length; i++) {
        title = title + " - " + filter.valuesForComparison[i]
        if (i > 0) {
          title = title + ", "
        }
      }
      return title
    },
    resetThisSearch() {
      this.resetSearch()
      this.$emit("runSortAndSelect")
    },
    async apiSearch(searchString, searchType = "simple") {
      if (!searchString) return []
      let searchStrategy

      if (searchType == "advanced") {
        searchStrategy = this.TEXT_SERVICE.searchAdvanced.bind(
          this.TEXT_SERVICE
        )
      } else {
        searchStrategy = this.TEXT_SERVICE.filterTheme.bind(this.TEXT_SERVICE)
      }
      this.setShowResponseListLoadingSpinner(true)
      try {
        let keywords = []
        let search_string = searchString
        keywords.push(search_string)
        let useThisID = this.textAnalysisObject.selectedTextQuestion._id
        if (useThisID.$oid) {
          useThisID = useThisID.$oid
        }
        let smart_coverage = false
        if (this.includeSmartCoverage === "include") {
          smart_coverage = true
        }
        let filter_params = {}
        if (this.textAnalysisObject.filterParams) {
          filter_params = this.textAnalysisObject.filterParams
        }
        const response = await searchStrategy({
          keywords: keywords,
          data_set_id: this.textAnalysisObject.datasetId,
          project_id: this.project.id,
          client_question_id: useThisID,
          uuid: this.textAnalysisObject.uuid,
          filter_params: filter_params,
          smart_coverage: smart_coverage
        })
        return response
      } catch (e) {
        throw new Error("Search.vue:apiSearch " + e.message)
      } finally {
        this.setShowResponseListLoadingSpinner(false)
      }
    },
    async handleSearch(keyword = null, focusSearch = false) {
      // Advanced search will send the text to findSimilarSentences API, and if found, add these found sentences as the matching text.
      // Normal search will simply run a normal coverage (treats the search as a keyword, looking for advanced match). If found, returns the exact keyword matches.
      // RunSortAndSelect then filters by either

      if (keyword && typeof keyword === "string") {
        this.searchString = keyword.trim().toLowerCase()
      }

      if (!this.searchString && !keyword) return
      this.isSearching = true

      // focus search input
      if (focusSearch) this.focusSearchInput()

      // handle search
      let searchResults = []
      if (this.advancedSearchEnabled) {
        let payload = {
          client_question_id: this.textAnalysisObject.selectedTextQuestion._id,
          user_string: this.searchString.toLowerCase(),
          sim_thresh: 0.4
        }
        const thisThemeResponses = await this.TEXT_SERVICE.findSimilarSentences(
          payload
        ).then((response) => {
          return response
        })
        if (thisThemeResponses.length) {
          this.setSearch({
            searchString: this.searchString.trim().toLowerCase(),
            similarSentences: thisThemeResponses.map((theme) =>
              theme.text.toLowerCase()
            ),
            searchMode: "similar sentences"
          })
        } else {
          // No search results
          this.noResultsFound = true
          // this works fine, but the "last" successful search will still actually reload if someone hard refreshs. Not the worst thing
          this.localSearch = {
            searchString: "",
            searchMode: "similar sentences",
            alsoFound: [],
            searchResults: [],
            searchResultsIds: [],
            excludeAssociations: false,
            wordAssociations: [],
            similarSentences: [],
            isFetchingWordAssociations: false
          }
          this.setSearch({
            searchString: this.searchString.trim().toLowerCase(),
            similarSentences: [],
            searchMode: "similar sentences"
          })
        }
      } else {
        searchResults = await this.apiSearch(this.searchString.toLowerCase())

        if (searchResults.keyword_matches) {
          let theseIds = searchResults.keyword_matches.map((a) => a.responseId)
          if (theseIds.length) {
            this.setSearch({
              searchString: this.searchString.trim().toLowerCase(),
              searchResultsIds: theseIds,
              searchResults: searchResults.keyword_matches,
              searchMode: "exact matches"
            })
          } else {
            // No search results
            this.noResultsFound = true
            // this works fine, but the "last" successful search will still actually reload if someone hard refreshs. Not the worst thing
            this.localSearch = {
              searchString: "",
              alsoFound: [],
              searchResults: [],
              searchResultsIds: [],
              excludeAssociations: false,
              wordAssociations: [],
              similarSentences: [],
              isFetchingWordAssociations: false,
              searchMode: "exact matches"
            }
            this.setSearch({
              searchString: this.searchString.trim().toLowerCase(),
              searchResultsIds: [],
              searchResults: [],
              searchMode: "exact matches"
            })
          }
        } else {
          // No search results
          this.noResultsFound = true
          // this works fine, but the "last" successful search will still actually reload if someone hard refreshs. Not the worst thing
          this.localSearch = {
            searchString: "",
            alsoFound: [],
            searchResults: [],
            searchResultsIds: [],
            excludeAssociations: false,
            wordAssociations: [],
            similarSentences: [],
            isFetchingWordAssociations: false,
            searchMode: "exact matches"
          }
          this.setSearch({
            searchString: this.searchString.trim().toLowerCase(),
            searchResultsIds: [],
            searchResults: [],
            searchMode: "exact matches"
          })
        }
      }

      this.isSearching = false
    },
    updateIncludeSmartCoverage() {
      let option = "exclude"
      if (
        !this.localIncludeSmartCoverage ||
        this.localIncludeSmartCoverage == "exclude"
      ) {
        option = "include"
      }
      this.localIncludeSmartCoverage = option
      this.setIncludeSmartCoverage(option)
    },
    focusSearchInput() {
      if (this.$refs["search-input"]) {
        document.scrollingElement.scrollTop +=
          this.$refs["search-input"].getBoundingClientRect().top - 100
        this.$refs["search-input"].focus()
      }
    },
    reset() {
      this.resetSearch()
      this.$alsoFound.setVisible(false)
    },
    /* Save also found modal */
    saveAlsoFound() {
      this.alsoFoundModal.autoCreateTheme = true
      this.alsoFoundModal.autoCreateThemeTitle = this.thisSearchString
      let keywords = [this.thisSearchString, ...this.$alsoFound.similar]
      this.alsoFoundModal.autoCreateThemeKeywords = keywords
      this.alsoFoundModal.selection = null
      this.$nextTick(() => (this.isSaveAlsoFoundModalVisible = true))
    },
    saveAlsoFoundKeyword(keyword) {
      this.alsoFoundModal.autoCreateTheme = false
      this.alsoFoundModal.autoCreateThemeTitle = ""
      this.alsoFoundModal.autoCreateThemeKeywords = []
      this.alsoFoundModal.selection = {
        content: keyword
      }
      this.$nextTick(() => (this.isSaveAlsoFoundModalVisible = true))
    },
    checkToOpenNoSentencesTip() {
      if (this.hasFetchedSentences == false) {
        this.openNoSentencesTip = true
      }
    },
    checkToOpenNoLemmasTip() {
      if (this.hasFetchedLemmas == false) {
        this.openNoLemmasTip = true
      }
    },
    closeModal() {
      this.localSearch.alsoFound = []
      this.isSaveAlsoFoundModalVisible = false
    }
  },
  mounted() {
    if (this.textAnalysisObject.search.searchString) {
      this.thisSearchString = this.textAnalysisObject.search.searchString
    }
    if (
      this.textAnalysisObject.search &&
      Object.keys(this.textAnalysisObject.search)
    ) {
      this.localSearch = this.textAnalysisObject.search
    }
  },
  watch: {
    "textAnalysisObject.search.searchMode": function (val) {
      if (val && val == "similar sentences") {
        this.advancedSearchEnabled = true
      } else {
        this.advancedSearchEnabled = false
      }
      if (!this.hasFetchedSentences) {
        this.advancedSearchEnabled = false
      }
    },
    includeSmartCoverage: function (val) {
      if (val && val == "include") {
        this.localIncludeSmartCoverage = true
      } else {
        this.localIncludeSmartCoverage = false
      }
    }
  }
}
</script>
