import { app } from "@/main.js"

export default {
  namespaced: true,
  state() {
    return {
      loading: false
    }
  },
  getters: {
    getLoading: (state) => state.loading
  },
  mutations: {
    SET_LOADING(state, payload) {
      state["loading"] = payload
    }
  },
  actions: {
    async setLoading({ commit }, payload) {
      await commit("SET_LOADING", payload)
    }
  }
}
