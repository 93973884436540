<template>
  <article id="project-analysis-drivers" v-if="project">
    <!--------------------------------------------
    |
    | HEADER
    |
    --------------------------------------------->

    <div
      class="project-analysis__header"
      style="justify-content: space-between"
      :inert="modalOpen"
      :aria-hidden="modalOpen"
    >
      <h2 class="project-analysis__header-title">
        <VisibleText>Find top drivers</VisibleText>
      </h2>

      <div style="display: flex; justify-content: flex-end">
        <Spinner
          :is-loading="findingSuggestedVariables"
          message="Searching for suggested analysis"
          complete-message=""
        />
        <button
          class="project-analysis__header-add-btn"
          style="margin: 0 1em"
          v-if="
            (!this.project.driversAnalysis ||
              !this.project.driversAnalysis.length) &&
            this.hasScaleQuestions
          "
          :disabled="propReadOnly"
          @click="findSuggestedVariables()"
        >
          <VisibleText>Auto-suggest analysis</VisibleText>
        </button>
        <button
          class="project-analysis__header-add-btn"
          @click="onClickAddNewDrivers"
          :disabled="propReadOnly"
        >
          <VisibleText>Add New Drivers Analysis</VisibleText>
        </button>
      </div>
    </div>

    <!--------------------------------------------
    |
    | DESCRIPTIONS
    |
    --------------------------------------------->
    <p class="drivers__desc" :inert="modalOpen" :aria-hidden="modalOpen">
      <template>
        <VisibleText
          >Drivers analysis is a technique used to make predictions about any
          scale, category or numeric data. This is done with common statistical
          techniques like
          <a
            href="https://en.wikipedia.org/wiki/Regression_analysis"
            target="_blank"
            rel="noopener noreferrer"
            >regression analysis</a
          >.
        </VisibleText>
        <VisibleText
          >By finding what questions predict an outcome like "overall
          satisfaction" or "willingness to recommend", organizations can
          prioritize where to focus energies.
        </VisibleText>
      </template>
    </p>
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Watch: </VisibleText>
          <SvgIconDecorative icon="video" />
          <button
            @click="openVideo('regression')"
            style="
              background: none;
              color: var(--secondary);
              border: none;
              padding: 0;
              font: inherit;
              text-decoration: underline;
            "
          >
            <VisibleText>Drivers/regression video</VisibleText>
          </button>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Guide: </VisibleText>
          <SvgIconDecorative icon="externalLink" />
          <a
            href="javascript:void(0)"
            @click="
              $emit('user-guide-expanded'),
                $emit('open-article', 'DriversAnalysis')
            "
          >
            A guide for using ResearchAI drivers analysis
          </a>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Read more: </VisibleText>
          <SvgIconDecorative icon="externalLink" />
          <a
            href="https://www.linkedin.com/pulse/cx-driver-analysis-turning-statistics-visual-road-map-edward-murphy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Article about drivers analysis and how important it is to pick the
            right performance measure to predict against.
          </a>
        </div>
      </div>
    </div>
    <p
      class="warning-msg__inline"
      v-if="statusNoAutoDriversFound && statusName.length === 0"
    >
      <VisibleText
        >Out tool could not automatically find an outcome question, you can
        create one by clicking "add new drivers analysis" button below.
      </VisibleText>
    </p>

    <!--------------------------------------------
    |
    | WARNINGS AND INFO MESSAGES
    |
    --------------------------------------------->
    <DriversMessages :status-name="statusName" />

    <!--------------------------------------------
    |
    | DRIVERS
    |
    --------------------------------------------->
    <template
      v-if="project.driversAnalysis && project.driversAnalysis.length > 0"
    >
      <SortProjects
        :sortBy="sortBy"
        @toggleSort="toggleSort"
        v-if="sortedItems.length > 1"
      />
      <div v-for="(driver, index) in sortedItems" :key="driver.uuid">
        <ProjectAnalysisDriversItem
          :index="index"
          :prop-read-only="propReadOnly"
          @refresh="$emit('refresh')"
          @updateSlides="$emit('updateSlides')"
        />
      </div>
    </template>

    <!--------------------------------------------
    |
    | NEW DRIVER MODAL
    |
    --------------------------------------------->
    <DriversVariables
      dependent-variable-title="Create New Drivers Analysis"
      :dependent-questions="projectCompositeIndex"
      :new-driver="true"
      :show-drivers-variables="isNewDriversModalVisible"
      @modify-vars="createNewDrivers"
      @close-drivers-variables="isNewDriversModalVisible = false"
    />
    <VideoClip
      :videoShortURL="videoShortURL"
      :status="isVideoClipExpanded"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
  </article>
</template>

<script>
// Components
import DriversMessages from "./Components/DriversMessages.vue"
import DriversVariables from "./Components/VariablesModal.vue"
import ProjectAnalysisDriversItem from "./ProjectAnalysisDriversItem.vue"
import SortProjects from "../Components/SortProjects.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
// import Spinner from "@/components/UI/Spinner.vue"

// Helpers
import moment from "moment"
import _ from "lodash"

// Mixins
import DriversAnalysisMixin from "./Mixins/driversAnalysisMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"

export default {
  name: "ProjectAnalysisDrivers",
  mixins: [DriversAnalysisMixin, ProjectMixin],
  components: {
    DriversMessages,
    DriversVariables,
    ProjectAnalysisDriversItem,
    SortProjects,
    VideoClip,
    SvgIconDecorative
  },
  data() {
    return {
      statusNoAutoDriversFound: false,
      suggestedVariables: null,
      isNewDriversModalVisible: false,
      sortBy: "new",
      videoShortURL: "",
      isVideoClipExpanded: false,
      findingSuggestedVariables: false
    }
  },
  props: {
    hasScaleQuestions: {
      default: () => false,
      type: Boolean
    },
    hasCategoryQuestions: {
      default: () => false,
      type: Boolean
    }
  },
  async created() {
    //backwards compatability: project.driversAnalysis used to be an object
    if (
      this.project.driversAnalysis &&
      !Array.isArray(this.project.driversAnalysis)
    ) {
      const savedDrivers = _.cloneDeep(this.project.driversAnalysis)
      this.project.driversAnalysis = []
      this.project.driversAnalysis.push(savedDrivers)
    }
  },
  computed: {
    statusName() {
      if (!this.hasScaleQuestions) {
        return "hasNoScale"
      }
      if (this.statusOrgNotVerified) return "orgNotVerified"
      return ""
    },
    projectCompositeIndex() {
      if (this.project) {
        if (this.project.settings) {
          return this.project.settings.performanceMeasure
            ? this.project.settings.performanceMeasure.clientQuestionsIds
            : []
        }
      }
      return []
    },
    sortedItems: function () {
      if (this.project) {
        if (this.project.driversAnalysis) {
          if (this.project.driversAnalysis.length > 1) {
            if (this.sortBy === "old") {
              let theseObjects = this.project.driversAnalysis
              theseObjects.sort((a, b) => {
                return a.last_modified_on - b.last_modified_on
              })
              return theseObjects
            } else {
              let theseObjects = this.project.driversAnalysis
              theseObjects.sort((a, b) => {
                return b.last_modified_on - a.last_modified_on
              })
              return theseObjects
            }
          } else {
            return this.project.driversAnalysis
          }
        }
      }
      return []
    }
  },
  methods: {
    async findSuggestedVariables() {
      this.findingSuggestedVariables = true
      this.suggestedVariables = await this.fetchSuggestedVariables()
      // returned an error, no drivers automatically found
      if ([406, 424].includes(this.suggestedVariables)) {
        this.statusNoAutoDriversFound = true
      } else {
        this.useSuggestedVariables()
      }
      this.findingSuggestedVariables = false
    },
    createNewDrivers(dependent, independent, filterParam) {
      const driversObject = {
        saved_performance_type: this.projectPerformanceType || "scale-average",
        dependent_variable_id: dependent,
        dependent_variable_title:
          this.$_driversAnalysisMixin_getDependentVarTitle(dependent),
        independent_variables_ids: independent,
        last_modified_on: moment().valueOf(),
        uuid: this.$pigeonline.createUUID(),
        filter_params: filterParam || {}
      }
      if (this.project.driversAnalysis == null) {
        this.project.driversAnalysis = []
        this.project.driversAnalysis.push(driversObject)
      } else {
        this.project.driversAnalysis.unshift(driversObject)
      }
    },
    onClickAddNewDrivers() {
      this.isNewDriversModalVisible = true
    },
    useSuggestedVariables() {
      this.createNewDrivers(
        [this.suggestedVariables.dependent_variable_id],
        this.suggestedVariables.independent_variables_ids
      )
    },
    async fetchSuggestedVariables() {
      try {
        const datasetId =
          this.$store.getters["datasetDetails/getDataset"]._id.$oid
        const response =
          await this.$services.DRIVERS_SERVICE.suggestedVariables({
            data_set_id: datasetId
          })
        return response
      } catch (e) {
        throw new Error(
          "ProjectAnalysisDrivers:fetchSuggestedVariables" + e.message
        )
      }
    },
    toggleSort() {
      if (this.sortBy === "new") {
        this.sortBy = "old"
      } else {
        this.sortBy = "new"
      }
    },
    openVideo(shortUrl) {
      this.videoShortURL = shortUrl
      this.isVideoClipExpanded = !this.isVideoClipExpanded
    }
  }
}
</script>
