<template>
  <div>
    <!--------------------------------------
    | read only
    ---------------------------------------->
    <template v-if="(readOnly || isDatasetReadOnly) && approvedDataType">
      <VisibleText>
        {{
          TYPE_TO_READABLE[approvedDataType] ||
          SCALE_TYPE_TO_READABLE[approvedDataType]
        }}
      </VisibleText>
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-if="!readOnly && !isDatasetReadOnly">
      <VisibleText class="dataset-table__type-noresponses" v-if="noResponses">
        No responses. Setting data type unavailable.
      </VisibleText>
      <div
        :class="[
          'dataset-table__type-select select-wrapper',
          { 'dataset-table__type-select--withicon': withicon }
        ]"
      >
        <label
          class="sr-only"
          :for="`edit-data-type-${clientQuestion._id.$oid}`"
          :data-parent="$options.name"
        >
          {{ translate("select data type", $options.name) }}
        </label>
        <select
          :class="['select', { 'dataset-table__type-unknown': unknown }]"
          :id="`edit-data-type-${clientQuestion._id.$oid}`"
          :disabled="noResponses"
          tabindex="-1"
          v-model="$data.$_approvedDataType"
          @change="onChange"
          style="width: 100%"
        >
          <option disabled value="" :data-parent="$options.name">
            {{ translate("select type", $options.name) }}
          </option>
          <option value="none" :data-parent="$options.name">
            {{ translate("none", $options.name) }}
          </option>
          <optgroup label="Data Types">
            <option
              v-for="type in Object.keys(TYPE_TO_READABLE)"
              :key="type"
              :value="type"
              :data-parent="$options.name"
            >
              {{ translate(TYPE_TO_READABLE[type], $options.name) }}
            </option>
          </optgroup>
          <optgroup label="Scale Types">
            <option
              v-for="scale in Object.keys(SCALE_TYPE_TO_READABLE)"
              :key="scale"
              :value="scale"
              :data-parent="$options.name"
            >
              {{ translate(SCALE_TYPE_TO_READABLE[scale]) }}
            </option>
          </optgroup>
        </select>
        <SvgIconMeaningful
          class="dataset-table__type-icon"
          icon="question"
          title="unknown data type"
          v-if="unknown"
        />
        <SvgIconMeaningful
          class="dataset-table__type-icon"
          icon="warning"
          title="no responses"
          v-if="noResponses"
        />
      </div>
    </template>
    <div
      v-if="
        approvedDataType &&
        approvedDataType == 'OPEN_ENDED' &&
        activeSection == 'DatasetCleaner'
      "
    >
      <LemmasMiniGuide
        @closingGuide="showLemmasInfo = false"
        :showLemmasInfo="showLemmasInfo"
        :avoidLeftOffset="true"
      >
      </LemmasMiniGuide>
      <SentimentsMiniGuide
        @closingGuide="showSentimentsInfo = false"
        :showSentimentsInfo="showSentimentsInfo"
        :avoidLeftOffset="true"
      ></SentimentsMiniGuide>
      <SentencesMiniGuide
        @closingGuide="showSentencesInfo = false"
        :showSentencesInfo="showSentencesInfo"
        :avoidLeftOffset="true"
      ></SentencesMiniGuide>
      <div v-if="askUserToConfirmText">
        <div
          style="display: flex; flex-direction: column"
          class="message__block message__block--warning"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <VisibleText
                >Text questions need to be formatted
                <SvgIconDecorative icon="error" />
              </VisibleText>

              <button
                class="btn-secondary"
                style="height: 1rem; padding-top: 0.3rem"
                @click="confirmThisText"
              >
                <VisibleText
                  style="color: var(--base-colour); text-decoration: underline"
                  >Click here to confirm this is a text question</VisibleText
                >
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="tempConfirmingText">
          <div
            style="display: flex; flex-direction: column"
            class="message__block message__block--warning"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="project-analysis-item__header-wrapper-btn"
                style="display: flex; font-weight: 200"
              >
                <VisibleText
                  >Text is being generated. Please wait.
                  <SvgIconDecorative icon="error" />
                </VisibleText>
                <span class="also-found__loading-spinner spinner"></span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="clientQuestion.is_adding_all_responses == true">
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--warning"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <VisibleText
                    >Text is still be generated. Please refresh the page until
                    this is complete.
                  </VisibleText>
                  <SvgIconDecorative icon="error" />
                  <span
                    style="height: 1rem; width: 2rem; margin-top: 1rem"
                    class="also-found__loading-spinner spinner"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="clientQuestion.is_adding_all_responses == false">
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <VisibleText
                    >Text has been formated
                    <SvgIconDecorative icon="success" />
                  </VisibleText>
                  <div
                    v-if="
                      clientQuestion.added_count &&
                      clientQuestion.added_all_responses == false
                    "
                    style="padding-left: 0.5rem"
                  >
                    <VisibleText
                      >Your text is too large. Our current version is optimized
                      for 5,000,000 characters. Only &nbsp;</VisibleText
                    >
                    <span>{{ clientQuestion.added_count }}</span>
                    <VisibleText>&nbsp;responses were generated.</VisibleText>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: -1rem;
                margin-top: -1rem;
              "
            >
              <div style="display: flex; flex-direction: row">
                <button
                  class="project-analysis-item__header-wrapper-btn"
                  style="
                    display: flex;
                    font-weight: 800;
                    color: var(--primary);
                    background-color: transparent;
                    border-color: transparent;
                  "
                  @click="showAISettings = !showAISettings"
                >
                  <VisibleText v-if="showAISettings"
                    >Hide AI settings</VisibleText
                  >
                  <VisibleText v-if="!showAISettings"
                    >Show AI settings</VisibleText
                  >
                  <span>
                    <SvgIconDecorative
                      :icon="showAISettings ? 'arrowUp' : 'arrowDown'"
                      :class="['right', 'icon']"
                      style="margin-top: 0.2em"
                    />
                  </span>
                </button>
              </div>
            </div>

            <div
              v-if="!clientQuestion.fetched_lemmas && showAISettings"
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--warning"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <VisibleText
                      >Lemmas are needed for
                      <button
                        style="
                          margin-left: -0.4rem;
                          margin-right: -0.2rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showLemmasInfo = !showLemmasInfo"
                      >
                        <strong>smart search/coverage.</strong>
                      </button>
                      <SvgIconDecorative icon="error" />
                    </VisibleText>

                    <button
                      class="btn-secondary"
                      style="padding-top: 0.3rem"
                      :style="
                        clientQuestion.chars && clientQuestion.chars > 5000000
                          ? 'height: 5rem;'
                          : 'height: 1rem;'
                      "
                      @click="fetchLemmas"
                      :disabled="awaitingLemmasFetch == 'waiting'"
                      v-if="!isDatasetReadOnly"
                    >
                      <VisibleText
                        style="
                          color: var(--base-colour);
                          text-decoration: underline;
                        "
                        >Click here to generate lemmas</VisibleText
                      >
                      <span
                        v-if="
                          awaitingLemmasFetch &&
                          awaitingLemmasFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                      <p>
                        <VisibleText
                          class="message__block--warning"
                          style="padding: 0.1rem"
                          v-if="
                            clientQuestion.chars &&
                            clientQuestion.chars > 5000000
                          "
                          >Warning! We are optimized for 5,000,000 characters
                          but you have {{ clientQuestion.chars }}. Generation
                          will be slow.</VisibleText
                        >
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="clientQuestion.fetched_lemmas && showAISettings"
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--success"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <VisibleText>
                      Lemmas have been fetched.
                      <button
                        style="
                          margin-left: -0.2rem;
                          margin-right: -0.2rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showLemmasInfo = !showLemmasInfo"
                      >
                        <strong>Smart search/coverage</strong>
                      </button>
                      enabled.
                      <SvgIconDecorative icon="success" />
                    </VisibleText>
                    <button
                      class="btn-secondary"
                      @click="fetchLemmas"
                      v-if="!isDatasetReadOnly"
                      style="
                        height: 1rem;
                        padding-top: 0.3rem;
                        color: black;
                        text-decoration: underline;
                      "
                      :disabled="awaitingLemmasFetch == 'waiting'"
                    >
                      <VisibleText>Generate again</VisibleText>
                      <span
                        v-if="
                          awaitingLemmasFetch &&
                          awaitingLemmasFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!clientQuestion.fetched_sentiments && showAISettings"
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--warning"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <VisibleText
                      >Sentiments are needed for
                      <button
                        style="
                          margin-left: -0.2rem;
                          margin-right: -0.3rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showSentimentsInfo = !showSentimentsInfo"
                      >
                        <strong>sentiment analysis</strong>
                      </button>
                      <SvgIconDecorative icon="error" />
                    </VisibleText>

                    <button
                      class="btn-secondary"
                      style="padding-top: 0.3rem"
                      :style="
                        clientQuestion.chars && clientQuestion.chars > 5000000
                          ? 'height: 5rem;'
                          : 'height: 1rem;'
                      "
                      @click="fetchSentiments"
                      :disabled="awaitingSentimentsFetch == 'waiting'"
                      v-if="!isDatasetReadOnly"
                    >
                      <VisibleText
                        style="
                          color: var(--base-colour);
                          text-decoration: underline;
                        "
                        >Click here to generate sentiments</VisibleText
                      >
                      <span
                        v-if="
                          awaitingSentimentsFetch &&
                          awaitingSentimentsFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                      <p>
                        <VisibleText
                          class="message__block--warning"
                          style="padding: 0.1rem"
                          v-if="
                            clientQuestion.chars &&
                            clientQuestion.chars > 5000000
                          "
                          >Warning! We are optimized for 5,000,000 characters
                          but you have {{ clientQuestion.chars }}. Sentiments
                          may crash your browser!</VisibleText
                        >
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="clientQuestion.fetched_sentiments && showAISettings"
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--success"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <div>
                      Sentiments have been generated.
                      <button
                        style="
                          margin-left: -0.2rem;
                          margin-right: -0.3rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showSentimentsInfo = !showSentimentsInfo"
                      >
                        <strong>Sentiment analysis </strong>
                      </button>
                      enabled.
                      <SvgIconDecorative icon="success" />
                    </div>
                    <button
                      style="
                        height: 1rem;
                        padding-top: 0.3rem;
                        color: black;
                        text-decoration: underline;
                      "
                      class="btn-secondary"
                      @click="fetchSentiments"
                      :disabled="awaitingSentimentsFetch == 'waiting'"
                      v-if="!isDatasetReadOnly"
                    >
                      <VisibleText>Generate again</VisibleText>
                      <span
                        v-if="
                          awaitingSentimentsFetch &&
                          awaitingSentimentsFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                !clientQuestion.sentence_tokenized_and_vectorized &&
                showAISettings
              "
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--warning"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <VisibleText
                      >Sentences need to be tokenized to support
                      <button
                        style="
                          margin-left: -0.2rem;
                          margin-right: -0.3rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showSentencesInfo = !showSentencesInfo"
                      >
                        <strong>sentence-based AI</strong>
                      </button>
                      <SvgIconDecorative icon="error" />
                    </VisibleText>

                    <button
                      class="btn-secondary"
                      style="padding-top: 0.3rem"
                      :style="
                        clientQuestion.chars && clientQuestion.chars > 5000000
                          ? 'height: 5rem;'
                          : 'height: 1rem;'
                      "
                      @click="fetchSentenceTokens"
                      :disabled="awaitingSentenceTokenizationFetch == 'waiting'"
                      v-if="!isDatasetReadOnly"
                    >
                      <VisibleText
                        style="
                          color: var(--base-colour);
                          text-decoration: underline;
                        "
                        >Click here to tokenize sentences</VisibleText
                      >
                      <span
                        v-if="
                          awaitingSentenceTokenizationFetch &&
                          awaitingSentenceTokenizationFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                      <p>
                        <VisibleText
                          class="message__block--warning"
                          style="padding: 0.1rem"
                          v-if="
                            clientQuestion.chars &&
                            clientQuestion.chars > 5000000
                          "
                          >Warning! We are optimized for 5,000,000 characters
                          but you have {{ clientQuestion.chars }}. Sentence
                          tokenization may crash your browser!</VisibleText
                        >
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                clientQuestion.sentence_tokenized_and_vectorized &&
                showAISettings
              "
              style="margin-bottom: -1rem"
            >
              <div
                style="display: flex; flex-direction: column"
                class="message__block message__block--success"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 200"
                  >
                    <VisibleText
                      >Sentences have been tokenized
                      <button
                        style="
                          margin-left: -0.2rem;
                          margin-right: -0.3rem;
                          background-color: transparent;
                          font-family: Core-Sans;
                          border-color: transparent;
                          color: var(--primary);
                        "
                        @click="showSentencesInfo = !showSentencesInfo"
                      >
                        <strong>Sentence-based AI </strong>
                      </button>
                      enabled.
                      <SvgIconDecorative icon="success" />
                    </VisibleText>
                    <button
                      style="
                        height: 1rem;
                        padding-top: 0.3rem;
                        color: black;
                        text-decoration: underline;
                      "
                      class="btn-secondary"
                      @click="fetchSentenceTokens"
                      :disabled="awaitingSentenceTokenizationFetch == 'waiting'"
                      v-if="!isDatasetReadOnly"
                    >
                      <VisibleText>Generate again</VisibleText>
                      <span
                        v-if="
                          awaitingSentenceTokenizationFetch &&
                          awaitingSentenceTokenizationFetch == 'waiting'
                        "
                        class="also-found__loading-spinner spinner"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import LemmasMiniGuide from "@/components/UserGuide/LemmasMiniGuide.vue"
import SentimentsMiniGuide from "@/components/UserGuide/SentimentsMiniGuide.vue"
import SentencesMiniGuide from "@/components/UserGuide/SentencesMiniGuide.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DataTypeMixin from "@/components/Dataset/Mixins/datatypeMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "DataType",
  mixins: [DatasetDetailsMixin, DataTypeMixin, UserMixin],
  components: {
    SvgIconMeaningful,
    SvgIconDecorative,
    LemmasMiniGuide,
    SentimentsMiniGuide,
    SentencesMiniGuide
  },
  props: {
    activeSection: {
      type: String,
      default: () => ""
    },
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    suggestedType: {
      defauly: null,
      type: String
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    isDatasetReadOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      $_approvedDataType: "", // have a local copy of the approved data type
      approvedDataType: "",
      unknown: false,
      awaitingLemmasFetch: "",
      awaitingSentimentsFetch: "",
      awaitingSentenceTokenizationFetch: "",
      askUserToConfirmText: false,
      tempConfirmingText: false,
      showLemmasInfo: false,
      showSentimentsInfo: false,
      showSentencesInfo: false,
      showAISettings: false,
      local_suggested_data_type: ""
    }
  },
  created() {
    if (this.clientQuestion.approved_data_type) {
      this.setLocalApprovedDataType(this.clientQuestion.approved_data_type)

      // No pre-processing found on an old (pre1.7.0) file
      if (
        this.clientQuestion.approved_data_type == "OPEN_ENDED" &&
        !Object.keys(this.clientQuestion).includes("added_count")
      ) {
        this.askUserToConfirmText = true
      }
    }
  },
  computed: {
    clientQuestionId() {
      return this.clientQuestion._id.$oid
    },
    noResponses() {
      if (
        this.clientQuestion.approved_data_type !== "OPEN_ENDED" &&
        this.clientQuestion.unique_values.length === 0
      ) {
        return true
      }
      return false
    },
    withicon() {
      return this.noResponses || this.unknown
    }
  },
  methods: {
    setLemmasLoadingSpinner(status) {
      if (!status) this.awaitingLemmasFetch = ""
      this.awaitingLemmasFetch = status
    },
    setSentimentsLoadingSpinner(status) {
      if (!status) this.awaitingSentimentsFetch = ""
      this.awaitingSentimentsFetch = status
    },
    setSentencesLoadingSpinner(status) {
      if (!status) this.awaitingSentenceTokenizationFetch = ""
      this.awaitingSentenceTokenizationFetch = status
    },
    async confirmThisText() {
      this.askUserToConfirmText = false
      this.tempConfirmingText = true
      await this.$services.DATASETS_SERVICE.updateClientQuestion(
        this.clientQuestion._id.$oid,
        {
          approved_data_type: "OPEN_ENDED",
          local_suggested_data_type: this.clientQuestion.suggested_data_type,
          scale_range: null,
          unique_values: []
        }
      )
      this.tempConfirmingText = false
      this.updateClientQuestions(this.dataset._id.$oid)
    },

    fetchLemmas() {
      this.setLemmasLoadingSpinner("waiting")
      this.$emit("fetch-lemmas", this.clientQuestion._id.$oid)
    },
    fetchSentiments() {
      this.setSentimentsLoadingSpinner("waiting")
      this.$emit("fetch-sentiments", this.clientQuestion._id.$oid)
    },
    fetchSentenceTokens() {
      this.setSentencesLoadingSpinner("waiting")
      this.$emit("fetch-sentence-tokens", this.clientQuestion._id.$oid)
    },
    onChange() {
      this.saveDataType(this.$data.$_approvedDataType, true)
      this.approvedDataType = this.$data.$_approvedDataType
      this.$emit("on-change-data-type", this.$data.$_approvedDataType)
    },
    setLocalApprovedDataType(dataType) {
      this.$data.$_approvedDataType =
        dataType && dataType.length > 0 ? dataType : ""
      this.approvedDataType = dataType
    },
    async saveDataType(dataType, udpateStore = false) {
      if (
        !this.readOnly &&
        this.permissions.includes("update-dataset") &&
        !["CAT_OR_SCALE"].includes(dataType)
      ) {
        if (dataType.includes("OPEN_ENDED")) {
          this.setLemmasLoadingSpinner("waiting")
          this.setSentimentsLoadingSpinner("waiting")
        }
        await this.$_dataTypeMixin_saveDataType(
          this.clientQuestion,
          dataType,
          udpateStore
        )
        this.$emit("approved-data-type")
        this.unknown = false
        if (dataType == "OPEN_ENDED" || dataType == "text") {
          this.setLemmasLoadingSpinner("")
          this.setSentimentsLoadingSpinner("")
        }
      }
    }
  },
  watch: {
    clientQuestionId: {
      immediate: false,
      handler: function () {
        this.setLocalApprovedDataType(this.clientQuestion.approved_data_type)
      }
    },
    suggestedType: {
      immediate: true,
      handler: function (suggestedType) {
        if (suggestedType && suggestedType === "CAT_OR_SCALE") {
          this.local_suggested_data_type = suggestedType
          this.unknown = true
        } else if (suggestedType) {
          this.local_suggested_data_type = suggestedType
          this.setLocalApprovedDataType(suggestedType)
          this.saveDataType(suggestedType) // auto save suggested type
        }
      }
    }
  }
}
</script>
