import { app } from "@/main.js"

export default {
  namespaced: true,
  state() {
    return {
      profile: null,
      organization: null,
      licenses: [],
      permissions: [],
      notifications: [],
      valid: true,
      complete: true,
      loading: false,
      teams: []
    }
  },
  getters: {
    getProfile: (state) => state.profile,
    getTeams: (state) => state.teams,
    getLicenses: (state) => state.licenses,
    getPermissions: (state) => state.permissions,
    getNotifications: (state) => state.notifications,
    getValid: (state) => state.valid,
    getComplete: (state) => state.complete,
    getLoading: (state) => state.loading
  },
  mutations: {
    SET_PROFILE(state, payload) {
      state["profile"] = payload
    },
    SET_TEAMS(state, payload) {
      state["teams"] = payload
    },
    SET_LICENSES(state, payload) {
      state["licenses"] = payload
    },
    SET_PERMISSIONS(state, payload) {
      state["permissions"] = payload
    },
    SET_NOTIFICATIONS(state, payload) {
      state["notifications"] = payload
    },
    SET_VALID(state, payload) {
      state["valid"] = payload
    },
    SET_COMPLETE(state, payload) {
      state["complete"] = payload
    },
    SET_LOADING(state, payload) {
      state["loading"] = payload
    },
    RESET_USER(state) {
      state["profile"] = null
      state["licenses"] = []
      state["permissions"] = []
      state["notifications"] = []
      state["loading"] = false
      state["teams"] = []
    }
  },
  actions: {
    async setProfile({ commit }, payload) {
      await commit("SET_PROFILE", payload)
    },
    async setTeams({ commit }, payload) {
      await commit("SET_TEAMS", payload)
    },
    async setLicenses({ commit }, payload) {
      await commit("SET_LICENSES", payload)
    },
    async setPermissions({ commit }, payload) {
      await commit("SET_PERMISSIONS", payload)
    },
    async setNotifications({ commit }, payload) {
      await commit("SET_NOTIFICATIONS", payload)
    },
    async setLoading({ commit }, payload) {
      await commit("SET_LOADING", payload)
    },
    resetUser({ commit }) {
      commit("RESET_USER")
    }
  }
}
