import { axios, BaseService } from "@pigeonline/core"
import { app } from "@/main.js"

export default class TextService extends BaseService {
  /**
   * Fetch keywords for an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {Object} key value pair of theme: word list
   */
  async keywordGenerator(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/keywords`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:keywords " + e.message)
    }
  }
  /**
   * Fetch keywords for an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {Object} key value pair of theme: word list
   */

  async bertSentenceTopicsGenerator(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(
        `${URL}/sentence_level_bertopic`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:keywords " + e.message)
    }
  }

  /**
   * Fetch word associations for a user_string of an OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.user_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of word associations
   */
  async wordAssociations(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/word_association`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:wordAssociations " + e.message)
    }
  }

  /**
   * NOTE: discontinued as now search just uses filterTheme
   * Search API: Simple
   * @param {Object} request
   * @param {String} request.search_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of words sorted by match strength
   */
  async searchSimple(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/search_simple`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:searchSimple " + e.message)
    }
  }

  /**
   * Search API: Advanced
   * @param {Object} request
   * @param {String} request.search_string
   * @param {String} request.client_question_id
   * @param {String} request.data_set_id data set ID of client questions
   * @returns {List} of words sorted by match strength
   */
  async searchAdvanced(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/search_advanced`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:searchAdvanced " + e.message)
    }
  }

  /**
   * Search for similar sentences (BERT-based)
   * @param {Object} request
   * @param {String} request.search_string
   * @param {String} request.client_question_id
   * @returns {List} of words sorted by match strength
   */
  async findSimilarSentences(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/sentence_similarity`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:sentence_similarity " + e.message)
    }
  }

  /**
   * Fetch sentiments OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @returns [Object] all text respones with their sentiments
   */
  async lemmas_and_sentiments(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(
        `${URL}/lemmas_and_sentiments`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:sentiments " + e.message)
    }
  }

  /**
   * Fetch sentiments OPEN_ENDED client question
   * @param {Object} request
   * @param {String} request.client_question_id
   * @param {String} request.theme_name
   * @returns [Object] all text respones belonging to a theme
   */
  async filterTheme(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/filter_theme`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:filterTheme " + e.message)
    }
  }

  async saveThemes(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/save_themes`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:saveThemes " + e.message)
    }
  }

  async fetchTextThemes(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/get_themes`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:fetchTextThemes " + e.message)
    }
  }

  async deleteTextTheme(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/delete_theme`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:fetchTextThemes " + e.message)
    }
  }

  async deleteTextThemes(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/delete_themes`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:fetchTextThemes " + e.message)
    }
  }

  /**
   * Fetch respondent info for a client question response id
   * @param {Object} request
   * @param {String} request.project_id
   * @param {String} request.client_question_id
   * @param {String} request.response_id
   * @returns [String] 200 OK success
   */
  async respondentInfo(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/text"
      const response = await axios.post(`${URL}/respondent_info`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:respondentInfo " + e.message)
    }
  }
}
