import { mapActions, mapGetters } from "vuex"
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

export default {
  props: {
    readOnly: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("datasetWizard", {
      activeSection: "getActiveSection",
      benchmarkGroup: "getBenchmarkGroup",
      copyingDetails: "getCopyingDetails",
      datasetProject: "getDatasetProject",
      deleteIncompleteUpload: "getDeleteIncompleteUpload",
      toFetchSuggestedDataType: "getToFetchSuggestedDataType",
      toFetchSuggestedMatches: "getToFetchSuggestedMatches",
      unsavedChanges: "getUnsavedChanges",
      spinner: "getSpinner"
    }),
    ...mapGetters("user", {
      profile: "getProfile"
    })
  },
  methods: {
    ...mapActions("datasetWizard", [
      "setActiveSection",
      "setBenchmarkGroup",
      "setCopyingDetails",
      "setDatasetProject",
      "setUnsavedChanges",
      "setDeleteIncompleteUpload",
      "setToFetchSuggestedDataType",
      "setToFetchSuggestedMatches",
      "setSpinner",
      "addUnsavedChanges",
      "removeUnsavedChanges",
      "resetDatasetWizard"
    ]),
    ...mapActions("datasetWizard", {
      datasetWizard_updateDatasetProjectStatus: "updateDatasetProjectStatus"
    }),
    async $_datasetWizardMixin_setBenchmarkingGroup(
      benchmarkGroupId,
      updateDataset
    ) {
      if (updateDataset)
        await this.updateDataset(this.dataset._id.$oid, {
          benchmark_group: benchmarkGroupId
        })

      const groupDetails = await this.$pigeonline.projects.getByID(
        BenchmarkGroup,
        benchmarkGroupId
      )
      if (
        !groupDetails ||
        !Object.keys(groupDetails) ||
        (groupDetails.message &&
          groupDetails.message ==
            "You do not have permission to perform this action.") ||
        groupDetails ==
          "Error: projectsService:getByID: Request failed with status code 403"
      ) {
        this.noBenchmarkAccess = true
      } else {
        this.setBenchmarkGroup(groupDetails)
        // segments rely on updated dataset details, so make sure if it is a new
        // dataset or they updated the benchmark group we update seg details too
        const segmentDetails = await this.fetchSegmentDetails(groupDetails.id)
        this.$store.dispatch("datasetDetails/setSegmentDetails", segmentDetails)
      }
    },
    async clearBenchmarkGroup() {
      if (this.dataset) {
        if (this.dataset._id) {
          await this.updateDataset(this.dataset._id.$oid, {
            benchmark_group: {}
          })
        }
      }
      this.setBenchmarkGroup()
    },
    async editDataset(codebook = null, segments = null, surveyDetails = null) {
      let formData = new FormData()
      formData.append("name", this.datasetProject.name)
      formData.append(
        "segments",
        segments
          ? JSON.stringify(segments)
          : JSON.stringify(
              this.$store.getters["datasetDetails/getDataset"].segments
            )
      )
      formData.append(
        "empty_question_text_count",
        this.$store.getters["datasetDetails/getDataset"]
          .empty_question_text_count || 0
      )
      formData.append(
        "non_empty_question_text_count",
        this.$store.getters["datasetDetails/getDataset"]
          .non_empty_question_text_count || 0
      )

      // add codebook
      if (codebook) {
        if (codebook.sheet)
          formData.append("code_book_sheet_name", codebook.sheet)
        formData.append("code_book", codebook.file)
      }

      // add survey details
      if (surveyDetails)
        formData.append("survey_details", JSON.stringify(surveyDetails))

      // if name has been changed, retain other details otehrwise erased
      if (this.dataset && this.dataset.sheet_name) {
        formData.append("sheet_name", this.dataset.sheet_name)
      }
      if (this.dataset && this.dataset.code_book_sheet_name) {
        formData.append(
          "code_book_sheet_name",
          this.dataset.code_book_sheet_name
        )
      }

      if (this.dataset && this.dataset.created_on) {
        formData.append("created_on", this.dataset.created_on)
      }
      try {
        const response = await this.$services.DATASETS_SERVICE.edit(
          this.$store.getters["datasetDetails/getDataset"]._id.$oid,
          formData
        )
        return response
      } catch (e) {
        throw new Error("DatasetMixin:edit " + e.message)
      }
    },
    /************
     *
     * Methods to handle project object
     *
     ***********/
    async saveDatasetProject(project) {
      try {
        this.setDatasetProject(await this.$pigeonline.projects.update(project))
      } catch (e) {
        throw new Error("DatasetMixin:saveDatasetProject " + e.message)
      }
    }
  }
}
