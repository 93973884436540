<template>
  <DatasetLayout section-name="dataset-uploader" title="Upload Your File">
    <Spinner :is-loading="isLoading" :message="'loading file, please wait'" />

    <p>
      <VisibleText>
        Your journey starts with data. Please upload a data file. For survey
        data, the SPSS format (.sav) is best because it comes with metadata. If
        using excel (.xlsx), .ods, or csv, removing formatting helps improve
        compatibility. For a guide on how to include sub-headings, see the
        following
      </VisibleText>
      <button
        class="dataset-uploader__template-show-btn"
        @click="$emit('open-template-modal')"
      >
        <VisibleText>template</VisibleText>
      </button>
    </p>

    <form
      class="form dataset-uploader__form dataset__section-group"
      @submit.prevent
    >
      <div id="dataset-file-upload-group">
        <p class="error-msg__inline" id="file-err" v-show="errMsg.file">
          {{ errMsg.file }}
        </p>
        <!-- <DatasetDragAndDrop />         -->
        <div class="dataset-file-upload-container">
          <label
            :class="[
              'center-align-parent',
              { 'upload-error': Boolean(errMsg.file) }
            ]"
            id="dataset-file-upload-label"
            for="dataset-file-upload"
            ref="file-label"
          >
            <span class="center-align-child" ref="file-upload-label">
              <UploadCloudIcon />
              <span id="dataset-file-upload-label-formats">
                <VisibleText>
                  Supported formats: .xls, .xlsx, .ods, .sav, .csv
                </VisibleText>
              </span>
            </span>
          </label>
          <input
            aria-describedby="file-err"
            type="file"
            id="dataset-file-upload"
            ref="file"
            accept=".sav, .xls, .xlsx, .csv, .ods"
            :aria-invalid="Boolean(errMsg.file)"
            :disabled="readOnly"
            @change="onFileChange"
          />
        </div>
        <div v-if="form.file">
          <label id="dataset-file-name-label" for="dataset-file-name">
            <VisibleText>Dataset Name</VisibleText>
          </label>
          <p
            class="error-msg__inline"
            id="file-name-err"
            v-show="errMsg.fileName"
          >
            {{ errMsg.fileName }}
          </p>
          <input
            aria-describedby="file-name-err"
            type="text"
            id="dataset-file-name"
            ref="file-name"
            :aria-invalid="Boolean(errMsg.fileName)"
            :readonly="readOnly"
            v-model="datasetProject.name"
            @change="onNameChange"
          />

          <div v-if="dataset.sheet_name">
            <div class="dataset-file-sheet__label">
              <VisibleText>Sheet used for data:</VisibleText>
            </div>
            <p style="padding-left: 2rem">
              {{ dataset.sheet_name }}
            </p>
          </div>

          <div v-if="dataset.code_book_sheet_name">
            <div class="dataset-file-sheet__label">
              <VisibleText>Sheet used for codebook:</VisibleText>
            </div>
            <p style="padding-left: 2rem">
              {{ dataset.code_book_sheet_name }}
            </p>
          </div>

          <div id="dataset-file-sheet-wrapper">
            <div v-if="isExcelMultiSheetUploaded && !dataset.sheet_name">
              <label
                for="dataset-file-sheet"
                id="dataset-file-sheet-label"
                class="dataset-file-sheet__label"
              >
                <VisibleText>Select Data Sheet</VisibleText>
              </label>
              <p
                class="error-msg__inline"
                id="select-data-sheet-err"
                v-show="errMsg.dataSheet"
              >
                {{ errMsg.dataSheet }}
              </p>
              <div class="select-wrapper dataset-file-sheet__select">
                <select
                  aria-describedby="select-data-sheet-err"
                  class="select"
                  ref="select-sheet"
                  id="dataset-file-sheet"
                  :aria-invalid="
                    errMsg.dataSheet &&
                    isExcelMultiSheetUploaded &&
                    !form.selectedSheet
                  "
                  :disabled="readOnly"
                  v-model="form.selectedSheet"
                  @change="onSelectSheetChange"
                >
                  <option
                    v-for="(sheet, index) in form.sheets"
                    :key="`sheet-options-${index}`"
                  >
                    {{ sheet }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="isExcelMultiSheetUploaded && !dataset.code_book_sheet_name"
            >
              <label
                for="dataset-file-sheet"
                id="dataset-file-sheet-label"
                class="dataset-file-sheet__label"
              >
                <VisibleText>Select Codebook Sheet</VisibleText>
                <div class="select-wrapper dataset-file-sheet__select">
                  <select
                    class="select"
                    ref="select-codebook-sheet"
                    id="dataset-file-codebook-sheet"
                    :disabled="readOnly"
                    v-model="form.selectedCodebookSheet"
                  >
                    <option
                      v-for="(sheet, index) in form.sheets"
                      :key="`codebook-sheet-options-${index}`"
                    >
                      {{ sheet }}
                    </option>
                  </select>
                </div>
              </label>
            </div>
          </div>

          <div id="dataset-file-sheet-wrapper">
            <div v-if="isCSVUploaded && !dataset.use_delimiter">
              <label
                for="dataset-file-sheet"
                id="dataset-file-sheet-label"
                class="dataset-file-sheet__label"
              >
                <VisibleText>Select Delimiter</VisibleText>
              </label>

              <div class="select-wrapper dataset-file-sheet__select">
                <select
                  aria-describedby="select-data-sheet-err"
                  class="select"
                  ref="select-sheet"
                  id="dataset-file-sheet"
                  :aria-invalid="isCSVUploaded && !form.delimiter"
                  :disabled="readOnly"
                  v-model="form.delimiter"
                  @change="onSelectDelimiterChange"
                >
                  <option
                    v-for="(delimiter, index) in form.delimiters"
                    :key="`sheet-options-${index}`"
                  >
                    {{ delimiter }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="isCSVUploaded && !dataset.encoding">
              <label
                for="dataset-file-sheet"
                id="dataset-file-sheet-label"
                class="dataset-file-sheet__label"
              >
                <VisibleText>Select Encoding</VisibleText>
              </label>

              <div class="select-wrapper dataset-file-sheet__select">
                <select
                  aria-describedby="select-data-sheet-err"
                  class="select"
                  ref="select-sheet"
                  id="dataset-file-sheet"
                  :aria-invalid="isCSVUploaded && !form.encoding"
                  :disabled="readOnly"
                  v-model="form.encoding"
                  @change="onSelectEncodingChange"
                >
                  <option
                    v-for="(encoding, index) in form.encodings"
                    :key="`sheet-options-${index}`"
                  >
                    {{ encoding }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MessageBlock
        messageType="warning"
        v-if="isCodebookUploaded && isCodebookErr"
      >
        <template>
          <span class="desc">
            <VisibleText>
              We found 0 question code to question wording matches. There might
              be an issue with your codebook. You can continue or you can try
              re-uploading the codebook.
            </VisibleText>
            <button
              class="dataset-uploader__reupload-btn"
              @click="isShowingReupload = true"
            >
              <VisibleText>Re-upload Codebook</VisibleText>
            </button>
          </span>
        </template>
      </MessageBlock>

      <!-- Given our shift towards more data than just survey data, this may not be appropriate -->

      <!-- <MessageBlock
        messageType="warning"
        v-if="!isCodebookUploaded && uploadResponse"
      >
        <template>
          <span class="desc">
            <VisibleText>
              We noticed you did not upload a codebook file. You can click
              continue or, for best results, please upload a codebook file. see
              the following
            </VisibleText>
            <button
              class="dataset-uploader__template-show-btn"
              @click="$emit('open-template-modal')"
            >
              <VisibleText>template</VisibleText>
            </button>
            <VisibleText>for details.</VisibleText>
            <button
              class="dataset-uploader__reupload-btn"
              @click="isShowingReupload = true"
            >
              <VisibleText>Upload Codebook</VisibleText>
            </button>
          </span>
        </template>
      </MessageBlock> -->

      <!-- modifying a dataset -->
      <!-- <button
        class="dataset__section-save-btn"
        type="submit"
        @click="cont"
        v-if="
          datasetProject.status &&
          datasetProject.status.datasetUploadingCompleted
        "
      >
        <VisibleText>Continue</VisibleText>
      </button> -->

      <!-- uploading a new dataset -->
      <div style="display: flex; flex-direction: column">
        <CreateProject
          ref="create-btn"
          class="dataset__section-save-btn"
          :project="datasetProject"
          @projectCreated="datasetProjectCreated"
          @projectCreationError="datasetProjectCreationError"
          :disabled="
            (isExcelMultiSheetUploaded && !form.selectedSheet) ||
            (isCSVUploaded && !form.encoding && !form.delimiter)
          "
          v-if="!isCodebookErr && form.file"
        >
          <VisibleText>Upload file</VisibleText>
        </CreateProject>
        <!-- <button v-if="datasetWizardStatus.datasetUploadingCompleted"           class="dataset__section-save-btn">
          <VisibleText>Rename</VisibleText>
        </button> -->
      </div>
      <div
        v-if="datasetWizardStatus.datasetUploadingCompleted && !isLoading"
        style="width: 50rem"
      >
        <label id="dataset-file-name-label" for="dataset-file-name">
          <VisibleText>Dataset Name</VisibleText>
        </label>
        <input
          aria-describedby="file-name-err"
          type="text"
          id="dataset-file-name"
          ref="file-name"
          :readonly="readOnly"
          v-model="datasetProject.name"
        />
        <button
          :disabled="!datasetProject || !datasetProject.name"
          class="dataset__section-save-btn"
          @click="onRename"
        >
          <VisibleText>Rename</VisibleText>
        </button>
      </div>
    </form>

    <!-- Reuploading codebook side pannel -->
    <DatasetUploaderReupload
      uid="dataset-uploader-reupload"
      v-if="isShowingReupload"
      @codebook-reuploaded="codebookReuploaded($event)"
      @close-reupload="isShowingReupload = false"
    />
  </DatasetLayout>
</template>

<script src="./datasetUploader.js"></script>
