<template>
  <Modal
    header-tag="h1"
    :show="isScreenshotExpanded"
    :resetListener="resetListener"
    @close="closeScreenshot"
    :wider-format="true"
  >
    <div v-if="thisScreenshot !== ''" class="user-guide__container">
      <div
        v-if="thisScreenshot === 'screenshot_suggested-themes'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot we can see two themes were generated for a
            dataset with text data.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            One was generated from a common Natural Language Processing
            technique called
            <a
              href="https://en.wikipedia.org/wiki/Latent_semantic_analysis#Latent_semantic_indexing"
              target="_blank"
              rel="noopener noreferrer"
            >
              LSI</a
            >. These may be rough at first, but could indicate interesting
            keywords and are easily modified.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            And the other a custom theme called "CitizenFirst Customer
            Satisfaction". Learn more about building your own custom models
            <a
              href="http://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              here</a
            >.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_suggested-themes-2'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot we can see two themes that were generated for us
            from customer feedback. ResearchAI instantly identified website and
            staff competance issues.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Many organizations are sitting on mountains of text data without
            even realizing it. There may be Google Reviews about you, social
            media references made to you, customer interactions in your CRM
            system, live-chat data and more.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Use ResearchAI to measure and track customer or employee
            satisfaction, engagement, emotion, burnout, and more.
            <a
              href="http://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our text analysis tools</a
            >.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_CX-drivers'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot we can see a drivers analysis that can help us
            predict overall customer satisfaction (an ICCS Common Measurements
            Tool question). In this case, 5 top drivers (or predictors) of
            satisfaction. The top driver is: "the site is visually appealing".
            It has a score of 0.11, which means 11% of satisfaction can be
            explained by this driver relative to all others.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            The next chart helps you prioritze which drivers are both highly
            important in predicting satisfaction, but also are performing low
            from respondents. In this case, while "site was visually appealing"
            and "staff knowledgeability" both predict 11% of satisfaction,
            customers scored staff knowledgability lower so this is an area to
            invest in to improve satisfaction.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            You can use CitizenFirst Analytics to make drivers predictions on
            many types of data. This works especially well with ICCS Common
            Measurement Tool questions which you will get access to with this
            subscription. Many of you have to use SPSS, R-Statistics, and have
            trained teams to do prediction. Unlock even more powerful models
            than these tools without having to write lines of code.
            <a
              href="http://research-ai.io/library/guide/drivers-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our drivers analysis</a
            >.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_cxdrivers'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot we can see a drivers analysis that can help us
            predict overall customer satisfaction. In this case, 5 top drivers
            (or predictors) of satisfaction. The top driver is: "the site is
            visually appealing". It has a score of 0.11, which means 11% of
            satisfaction can be explained by this driver relative to all others.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            The next chart helps you prioritze which drivers are both highly
            important in predicting satisfaction, but also are performing low
            from respondents. In this case, while "site was visually appealing"
            and "staff knowledgeability" both predict 11% of satisfaction,
            customers scored staff knowledgability lower so this is an area to
            invest in to improve satisfaction.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            You can use ResearchAI to make drivers predictions on many types of
            data. Many of you have to use SPSS, R-Statistics, and have trained
            teams to do prediction. Unlock even more powerful models than these
            tools without having to write lines of code.
            <a
              href="http://research-ai.io/library/guide/drivers-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our drivers analysis</a
            >.
          </VisibleText>
        </p>
      </div>
      <div
        v-if="thisScreenshot === 'screenshot_cx-outliers-iccs'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot CitizenFirst Analytics has suggested 3 possible
            outliers across 2 Common Measurements Tool questions. These are
            segments of customers that are over- or under-performing compared to
            their peers.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Those who answered "2" to the data point: "community size" are a
            group of 38 people that are on average more disatisfied with
            accessibility than everyone else.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Similarly, those who answered "1" to the data point: "service type"
            are a group of 15 who are on average more upset with staff going the
            extra mile. While those who answered "1" to "community size" are
            generally happier.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Outliers analysis takes advantage of a technique called 'z-scores'.
            <a
              href="http://research-ai.io/library/guide/outliers-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our outliers analysis</a
            >.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_cx-outliers'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot ResearchAI has suggested 3 possible outliers
            across 2 questions. These are segments of customers that are over-
            or under-performing on text or survey questions compared to their
            peers.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Those who answered "2" to the data point: "community size" are a
            group of 38 people that are on average more disatisfied with
            accessibility than everyone else.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Similarly, those who answered "1" to the data point: "service type"
            are a group of 15 who are on average more upset with staff going the
            extra mile. While those who answered "1" to "community size" are
            generally happier.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Outliers analysis takes advantage of a technique called 'z-scores'.
            <a
              href="http://research-ai.io/library/guide/outliers-analysis-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about our outliers analysis</a
            >.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_text-theme-sentiment'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            In this screenshot we can see three themes. One theme, "Website",
            has been mentioned by 29.5% of all respondents (18 out of 61
            respondents).
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            This theme has mostly positive respondents. This 'sentiment' is
            determined by the number of 'negative' or 'positive' keywords and
            combinations of keywords.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            Another theme, "Staff competence" has less coverage (only 16.4%),
            but it has many more negative respondents.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_airport-outliers'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            Our outliers analysis takes any experience data (like survey scores)
            and automatically finds segments that had a significantly better or
            worse experience.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            In this case, live customer journey data revealed a red flag. One
            'segment' of customers was particular upset with the courtesy and
            helpfulness of the staff. The tool isolated this negative experience
            to those who arrived from a specific mode of transportation.
          </VisibleText>
        </p>
      </div>

      <div
        v-if="thisScreenshot === 'screenshot_benchmarking-report'"
        class="user-guide__description"
      >
        <p>
          <VisibleText>
            A government agency wanted to compare their service quality scores
            (from surveys) to a community of practice. By connecting their data
            to the the Institute for Citizen-Centred Services' benchmarking
            service, they were able to see how they compared to not only all
            other governments in the database, but to specific types of peers
            like those that deliver "Finance and payments" services.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            This reveals that the government was 4th best in performance to the
            question "overall satisfaction". The table below also reveals
            information about the top peers they were compared to.
          </VisibleText>
        </p>
        <p>
          <VisibleText>
            It is easy to create your own benchmarking databases or to connect
            to already existing ones. Learn more
            <a
              href="https://research-ai.io/library/guide/what-is-benchmarking-in-researchai"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </VisibleText>
        </p>
      </div>
      <div class="user-guide__image">
        <img
          class="landing-page__image landing-page__image--inherit-width"
          :src="thisScreenshotUrl"
          alt="ResearchAI screenshot"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

export default {
  name: "ScreenshotGuide",
  components: {
    Modal
  },
  props: {
    status: {
      type: Boolean,
      default: () => false
    },
    thisScreenshot: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      newStatus: false,
      firstLoad: true,
      resetListener: false,
      showTranscript: false
    }
  },
  methods: {
    closeScreenshot() {
      this.isScreenshotExpanded = false
      this.$emit("close")
    }
  },
  computed: {
    isScreenshotExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    thisScreenshotUrl() {
      if (this.thisScreenshot === "") {
        return ""
      }
      const url = "/assets/landing/" + this.thisScreenshot + ".png"
      return url
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    }
  }
}
</script>
