<template>
  <section id="project-analysis-text-main-sentiment">
    <div class="sentiment__header">
      <ul class="sentiment__header-labels main__search-labels">
        <transition name="fade">
          <li v-show="isSearchMode">
            <span
              class="main__highlight search"
              style="background-color: lightgreen"
              id="search-keyword"
            >
              <VisibleText>Search</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="highlightFilters.includes('pos-keyword')">
            <span class="main__highlight positive-sentiment" id="pos-keyword">
              <VisibleText>Positive keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="highlightFilters.includes('neg-keyword')">
            <span class="main__highlight negative-sentiment" id="neg-keyword">
              <VisibleText>Negative keyword</VisibleText>
            </span>
          </li>
        </transition>
      </ul>
      <div class="select-wrapper sentiment__sort-wrapper">
        <label for="sentiment-sort-by" class="sentiment__sort-label">
          <VisibleText>Sort by:</VisibleText>
        </label>
        <select
          id="sentiment-sort-by"
          class="select sentiment__sort-options"
          v-model.number="sortBy"
        >
          <option value="-1" :data-parent="$options.name">
            {{ translate("Negative sentiment", $options.name) }}
          </option>
          <option value="0" :data-parent="$options.name">
            {{ translate("Default", $options.name) }}
          </option>
          <option value="1" :data-parent="$options.name">
            {{ translate("Positive sentiment", $options.name) }}
          </option>
        </select>
      </div>
    </div>
    <ResponseList
      ref="MainSentimentResponseRef"
      class="sentiment__response-list"
      :textAnalysisObject="textAnalysisObject"
      :propReadOnly="propReadOnly"
      :client-question-id="
        convertId(textAnalysisObject.selectedTextQuestion._id)
      "
      :responses="sortedResponsesProp"
      :hidden-comments="textAnalysisObject.hiddenComments"
      :get-highlight-items-method="getHighlightItems"
      :hasFetchedLemmas="hasFetchedLemmas"
      :hasFetchedSentiments="hasFetchedSentiments"
      :sortedResponsesLength="sortedResponsesLength"
      :includeSmartCoverage="includeSmartCoverage"
      :sortedThemes="sortedThemes"
      @showSentimentDetails="$emit('showSentimentDetails', $event)"
      @ban-response="banResponseItem($event)"
      @adjust-sentiment="adjustSentimentItem"
      @toggle-response-hide="toggleResponseItemHide"
      @runSortAndSelect="$emit('runSortAndSelect')"
      @updateTextObject="$emit('updateTextObject', $event)"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
    />
  </section>
</template>

<script>
// Components
import ResponseList from "./Components/ResponseList.vue"

// Mixins
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"

export default {
  name: "ProjectTextAnalysisMainSentiment",
  mixins: [textAnalysisMixin],
  components: {
    ResponseList
  },
  props: {
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    highlightFilters: {
      type: Array,
      default: () => []
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedResponsesProp: {
      type: Array,
      required: false,
      default: () => []
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    sortBy: {
      get: function () {
        return this.textAnalysisObject.sentimentSortOrder
      },
      set: function (val) {
        this.setSentimentSortOrder(val)
      }
    }
  },
  methods: {
    refreshThemesFromParent() {
      if (this.$refs.MainSentimentResponseRef) {
        this.$refs.MainSentimentResponseRef.refreshThemesFromParent()
      }
    },
    convertId(id) {
      if (id && id.$oid) {
        return id.$oid
      } else {
        return id
      }
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    findResponseScore(response) {
      if (!response.scores) {
        return 0
      }
      return response.scores.sentiment || 0
    },
    getHighlightItems(response) {
      if (!this.textAnalysisObject.activeTab === "Sentiment") {
        return {}
      }

      let neg_keyword = []
      let pos_keyword = []

      if (this.textAnalysisObject.selectedTextQuestion) {
        if (
          this.textAnalysisObject.selectedTextQuestion.fetched_sentiments &&
          this.textAnalysisObject.includeSentiments == "include"
        ) {
          if (response.word_sentiments) {
            pos_keyword.push(
              ...response.word_sentiments
                .filter((el) => el.sentiment > 0.1)
                .map((el) => el.word)
            )
            neg_keyword.push(
              ...response.word_sentiments
                .filter((el) => el.sentiment < 0.1)
                .map((el) => el.word)
            )
          }
        }
      }

      let obj = {
        "pos-keyword": pos_keyword,
        "neg-keyword": neg_keyword
      }
      for (let key of Object.keys(obj)) {
        if (!this.highlightFilters.includes(key)) delete obj[key]
      }
      if (
        this.textAnalysisObject &&
        this.textAnalysisObject.search &&
        this.textAnalysisObject.search.searchResults
      ) {
        obj["search-keyword"] = this.textAnalysisObject.search.searchResults
      }
      return obj
    }
  },
  watch: {
    search: function (val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = 0
    }
  }
}
</script>
