<template>
  <DatasetLayout
    section-name="dataset-table-view"
    :title="SECTION_TO_TITLE[activeSection]"
  >
    <component
      :is="isComponentExists(`${activeSection}Desc`)"
      :benchmarkGroup="benchmarkGroup"
      @clearBenchmarks="clearBenchmarks"
    />
    <h3 class="dataset-table-view__data-title">{{ dataset.name }}</h3>
    <MessageBlock
      class="dataset-table__message-block"
      message-type="warning"
      :show-icon="false"
      v-if="questionsWithNoResponses.length > 0 && !isDismissed"
    >
      <template #default>
        <span class="text">
          <strong> {{ questionsWithNoResponses.length }} </strong>
          <VisibleText>
            question have no responses. Would you like to delete these
            questions?
          </VisibleText>
        </span>
        <div style="display: flex">
          <button type="button" class="btn" @click="onClickDeleteQuestions">
            <VisibleText> Delete Questions </VisibleText>
          </button>
          <button type="button" class="btn" @click="isDismissed = true">
            <VisibleText> Dismiss </VisibleText>
          </button>
        </div>
      </template>
    </MessageBlock>

    <!--------------------------------------------
    |
    | TABLE
    |
    --------------------------------------------->
    <div class="dataset__section-group">
      <DatasetCleanerTooltip
        v-if="activeSection === 'DatasetCleaner' && clientQuestions.length"
        :readOnly="readOnly"
      >
      </DatasetCleanerTooltip>

      <DatasetBenchmarksTooltip
        v-if="activeSection === 'DatasetBenchmarks' && clientQuestions.length"
        :readOnly="readOnly"
      >
      </DatasetBenchmarksTooltip>

      <DatasetTable
        :benchmark-group="benchmarkGroup"
        :activeSection="activeSection"
        id="dataset-table-wrapper"
        :read-only="true"
        :isDatasetReadOnly="readOnly"
        :refresh-pagination="isRefreshPagination"
        :detailsForSharingWithBenchmarking="detailsForSharingWithBenchmarking"
        @approved-global-match="fetchBenchmarkUpdates"
        @setComplete="setComplete"
        @set-modifying-match="setModifyingMatch"
      />
      <!--
        **
        The "continue" button is custom for each dataset table view
      -->
      <div v-if="activeSection === 'DatasetCleaner'">
        <button
          type="button"
          class="dataset__section-save-btn"
          @click="onClickContinue()"
          v-if="fromCleaningGoToSuggestedStep"
          :disabled="!this.datasetWizardStatus.datasetColumnDetailsCompleted"
        >
          <VisibleText>Continue</VisibleText>
        </button>
        <div v-else>
          <div v-if="this.datasetWizardStatus.datasetColumnDetailsCompleted">
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <SvgIconDecorative icon="success" />
                    <VisibleText
                      >Cleaning complete! You can now start a project using this
                      data.</VisibleText
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <SvgIconDecorative icon="information" />
                    <VisibleText
                      >Cleaning incomplete. Please confirm at least one data-
                      type</VisibleText
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeSection === 'DatasetBenchmarks'">
        <button
          type="button"
          class="dataset__section-save-btn"
          @click="onClickContinue()"
          v-if="fromBenchmarkingGoToSuggestedStep"
          :disabled="!this.datasetWizardStatus.benchmarksApproved"
        >
          <VisibleText>Continue</VisibleText>
        </button>
        <div v-else>
          <div v-if="this.datasetWizardStatus.benchmarksApproved">
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <SvgIconDecorative icon="success" />
                    <VisibleText
                      >Cleaning complete! You can now start a project using this
                      data.</VisibleText
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              style="display: flex; flex-direction: column"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <SvgIconDecorative icon="information" />
                    <VisibleText
                      >Incomplete. You need to select a benchmarking database
                      and have at least one approved
                    </VisibleText>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isICCSBenchmarkGroup">
          <div
            style="display: flex; flex-direction: column"
            class="message__block message__block--information"
          >
            <div style="display: flex; flex-direction: row">
              <div
                class="project-analysis-item__header-wrapper-btn"
                style="display: flex; font-weight: 200"
              >
                <p>
                  <SvgIconDecorative icon="information" />
                  <VisibleText
                    >Warning. This benchmarking database requires you to add
                    dataset details and at least one approved
                    match.</VisibleText
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--------------------------------------------
    |
    | BENCHMARK SELECTOR
    |
    --------------------------------------------->
    <BenchmarksSelectorPannel
      @approved-global-match="checkMatchesThenFetchBencmarkUpdates"
      :benchmark-group="benchmarkGroup"
      :client-question="clientQuestionModifyingMatch"
      :show="isShowingBenchmarkSelector"
      :detailsForSharingWithBenchmarking="detailsForSharingWithBenchmarking"
      @close-selector="closeBenchmarkSelector"
    />
    <Spinner
      :message="spinner.message"
      :complete-message="spinner.complete"
      :is-loading="spinner.isLoading"
    />
  </DatasetLayout>
</template>

<script>
// Components
import DatasetBenchmarksDesc from "../DatasetBenchmarks/DatasetBenchmarksDesc.vue"
import DatasetBenchmarksTooltip from "../DatasetBenchmarks/DatasetBenchmarksTooltip.vue"
import DatasetCleanerDesc from "../DatasetCleaner/DatasetCleanerDesc.vue"
import DatasetCleanerTooltip from "../DatasetCleaner/DatasetCleanerTooltip.vue"
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import DatasetTable from "../Components/DatasetTable/DatasetTable.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import Spinner from "@/components/UI/Spinner.vue"
import BenchmarksSelectorPannel from "./DatasetTableViewBenchmarksSelectorPannel.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// New eslint standards cannot handle the inline import, refactor needed
// const BenchmarksSelectorPannel = () => ({
//   component: import("./DatasetTableViewBenchmarksSelectorPannel.vue"),
//   loading: Spinner
// })

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"

// Consts
const SECTION_TO_TITLE = {
  DatasetBenchmarks: "Add benchmarking",
  DatasetCleaner: "Clean Your Data",
  DatasetTableView: "Data Table"
}

export default {
  name: "DatasetTableView",
  mixins: [DatasetDetailsMixin, DatasetMixin],
  components: {
    BenchmarksSelectorPannel,
    DatasetBenchmarksDesc,
    DatasetBenchmarksTooltip,
    DatasetCleanerDesc,
    DatasetCleanerTooltip,
    DatasetLayout,
    DatasetTable,
    MessageBlock,
    Spinner,
    SvgIconDecorative
  },
  props: {
    readOnly: {
      default: () => false,
      type: Boolean
    },
    suggestedSteps: {
      default: () => [],
      type: Array
    },
    list: {
      default: () => [],
      type: Array
    },
    isICCSBenchmarkGroup: {
      default: false,
      type: Boolean
    },
    isDatasetCleaningComplete: {
      default: false,
      type: Boolean
    },
    datasetWizardStatus: {
      default: () => {},
      type: Object
    },
    detailsForSharingWithBenchmarking: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      SECTION_TO_TITLE: Object.freeze(SECTION_TO_TITLE),
      clientQuestionModifyingMatch: {},
      // UI falgs
      isDismissed: false,
      isShowingBenchmarkSelector: false,
      isRefreshPagination: false,
      // only allow users to click "get suggested" once
      tooltipClickedCounter: {
        DatasetBenchmarks: 0,
        DatasetCleaner: 0
      }
    }
  },
  async created() {
    this.fetchBenchmarkUpdates()
  },
  computed: {
    questionsWithNoResponses() {
      let qsWithNoResponses = []
      for (let i = 0; i < this.clientQuestions.length; i++) {
        if (
          this.clientQuestions[i].approved_data_type !== "OPEN_ENDED" &&
          this.clientQuestions[i].unique_values.length === 0
        ) {
          qsWithNoResponses.push(this.clientQuestions[i]._id.$oid)
        }
      }
      return qsWithNoResponses
    },
    fromCleaningGoToSuggestedStep() {
      for (let i = 0; i < this.suggestedSteps.length; i++) {
        if (!this.suggestedSteps[i].suggesting) {
          return true
        }
      }
      return false
    },
    fromBenchmarkingGoToSuggestedStep() {
      for (let i = 0; i < this.suggestedSteps.length; i++) {
        if (i > 0) {
          if (!this.suggestedSteps[1].suggesting) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    isComponentExists(component) {
      return this.$options.components[component] || null
    },
    /**
     * modal actions
     **/
    setModifyingMatch(clientQuestion) {
      this.clientQuestionModifyingMatch = clientQuestion
      this.isShowingBenchmarkSelector = true
    },
    closeBenchmarkSelector() {
      this.clientQuestionModifyingMatch = {}
      this.isShowingBenchmarkSelector = false
    },
    /**
     * message actions
     **/
    async onClickDeleteQuestions() {
      this.setSpinner({
        complete: "questions successfully deleted",
        isLoading: true
      })
      await this.$_datasetDetailsMixin_deleteQuestions(
        this.questionsWithNoResponses,
        this.dataset._id.$oid
      )
      this.setSpinner({ isLoading: false })
      this.isRefreshPagination = !this.isRefreshPagination
    },
    /**
     * save & continue
     **/
    onClickContinue() {
      if (this.activeSection === "DatasetBenchmarks") {
        if (this.isICCSBenchmarkGroup) {
          // If ICCS, open next step automatically
          this.$emit("updateSuggestions", 1, false)
          this.$emit("updateList", 3, true)
        }
      }
      this[`save${this.activeSection}`]()
    },
    // Replaced with approvedDataTypes in DatasetTable, delete if confirmed
    async saveDatasetCleaner() {
      this.datasetWizard_updateDatasetProjectStatus(
        "datasetColumnDetailsCompleted"
      )
      this.setActiveSection("DatasetBenchmarks")
    },
    async saveDatasetBenchmarks() {
      if (!this.datasetProject.status.benchmarksApproved) {
        this.addUnsavedChanges("step 3")
      } else {
        this.datasetWizard_updateDatasetProjectStatus("benchmarksSent")
      }
      this.setActiveSection("DatasetSegments")
    },
    checkMatchesThenFetchBencmarkUpdates() {
      this.datasetWizard_updateDatasetProjectStatus("benchmarksSent")
      this.fetchBenchmarkUpdates()
    },
    async fetchBenchmarkUpdates() {
      if (this.datasetProject) {
        if (this.datasetProject.status.benchmarksSent) {
          const approvedBenchmarks =
            await this.$services.BENCHMARKING_SERVICE.benchmarking({
              client_data_set_id: this.datasetProject.dataset_id,
              approved: "True"
            })
          const temporarilyApprovedBenchmarks =
            await this.$services.BENCHMARKING_SERVICE.benchmarking({
              client_data_set_id: this.datasetProject.dataset_id,
              temporarily_approved: "True"
            })
          // at least one benchmark is approved, set to approved
          if (
            approvedBenchmarks.length > 0 ||
            temporarilyApprovedBenchmarks.length > 0
          ) {
            this.datasetProject.updateStatus("benchmarksApproved")
            await this.$pigeonline.projects.update(this.datasetProject)
          }
        }
      }
    },
    clearBenchmarks() {
      this.$emit("clearBenchmarks")
      // this.clearBenchmarkGroup();
    },
    setComplete() {
      this.datasetWizard_updateDatasetProjectStatus(
        "datasetColumnDetailsCompleted"
      )
    }
  }
}
</script>
