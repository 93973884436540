<template>
  <table>
    <caption :class="{ 'visually-hidden': hideCaption }">
      <slot name="caption">
        <VisibleText>{{ caption }}</VisibleText>
      </slot>
    </caption>
    <thead>
      <slot name="thead" :columnHeaders="columnHeaders">
        <tr>
          <component
            v-for="(header, index) in columnHeaders"
            :is="header && header.length > 0 ? 'th' : 'td'"
            :key="`table-column-header-${index}`"
            :scope="header && header.length > 0 ? 'col' : null"
          >
            <slot name="header" :header="header">
              <SortPopup
                v-if="sortConfig && sortConfig[header]"
                :sort-config="sortConfig[header]"
                :sort-title="header"
                @sort="$emit('sort', $event)"
              />
              <VisibleText v-else> {{ header }} </VisibleText>
            </slot>
          </component>
        </tr>
      </slot>
    </thead>
    <tbody>
      <slot name="tbody" :rowData="rowData">
        <tr v-for="(row, index) in rowData" :key="`table-row-${index}`">
          <slot name="row" :index="index" :row="row">
            <td
              v-for="key in tdKeys"
              :key="`table-row-${index}-cell-${key}`"
              :data-label="columnHeaders[index]"
              v-html="row[key]"
            ></td>
          </slot>
        </tr>
      </slot>
    </tbody>
  </table>
</template>

<script>
import SortPopup from "@/components/UI/Sort/SortPopup.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "DefaultTable",
  components: {
    SortPopup,
    SvgIconDecorative
  },
  props: {
    hideCaption: {
      type: Boolean,
      required: false,
      default: false
    },
    caption: {
      type: String,
      required: true
    },
    columnHeaders: {
      type: Array,
      required: false,
      default: () => []
    },
    rowData: {
      type: Array,
      required: true,
      default: () => []
    },
    rowKeys: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * sortConfig: {
     *  [headerName]: {
     *    options: <array of sort options>,
     *    selected: <string, currently selected option>
     *  }
     * }
     */
    sortConfig: {
      type: Object
    }
  },
  computed: {
    tdKeys() {
      return this.rowKeys.length > 0 ? this.rowKeys : this.columnHeaders
    }
  }
}
</script>
