<template>
  <form class="custom-text-model-editor__form" novalidate @submit.prevent>
    <div style="padding: 1.5rem">
      <label class="form-label" for="theme-name">
        <VisibleText>Theme Name </VisibleText>
      </label>
      <input type="text" id="theme-name" v-model="newModel.name" />

      <Tagging class="tagging--gray" @add="addKeyword">
        <VisibleText>Keywords</VisibleText>
      </Tagging>
      <TaggingList :tags="newModel.keywords" @remove="removeKeyword" />

      <Tagging
        class="tagging--gray"
        button-title="add question"
        @add="addExampleQuestion"
      >
        <VisibleText>Example sentences</VisibleText>
      </Tagging>
      <TaggingList
        class="tagging-list--block tagging-list--nobackground"
        button-title="remove question"
        :tags="newModel.example_questions"
        @remove="removeExampleQuestion"
      />
    </div>
    <div class="custom-text-model-editor__btns">
      <button
        class="btn-default"
        style="min-height: unset"
        type="submit"
        @click="onClickCreateTheme"
      >
        <VisibleText>Create theme</VisibleText>
      </button>
      <button class="btn-cancel" type="button" @click="$emit('close')">
        <VisibleText>cancel</VisibleText>
      </button>
    </div>
  </form>
</template>

<script>
// Components
import Tagging from "@/components/UI/Tagging/Tagging.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"

// Models
import CustomTopicModel from "../Models/CustomTopicModel.js"
import CustomTopicModelGroup from "../Models/CustomTopicModelGroup.js"

// Services
import customTopicModelService from "@/services/customTopicModelService.js"

const INHERIT = ["lang", "source", "tags"]

export default {
  name: "CustomTopicModelEditorNewTheme",
  components: {
    Tagging,
    TaggingList
  },
  props: {
    topicModelGroup: {
      type: CustomTopicModelGroup
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      newModel: new CustomTopicModel()
    }
  },
  methods: {
    async onClickCreateTheme() {
      // populate inherited fields
      Object.assign(
        this.newModel,
        { group_id: this.topicModelGroup.id },
        ...INHERIT.map((k) => ({ [k]: this.topicModelGroup[k] }))
      )
      try {
        const newTheme = await this.CUSTOM_TOPIC_MODEL_SERVICE.create(
          this.newModel
        )
        this.$emit("new-theme-created", newTheme)
        this.$emit("close")
      } catch (e) {
        throw new Error("onClickCreateTheme: " + e.message)
      }
    },
    addExampleQuestion(question) {
      this.newModel.example_questions.push(question)
    },
    addKeyword(keyword) {
      this.newModel.keywords.push(keyword)
    },
    removeExampleQuestion(question) {
      const indx = this.newModel.example_questions.indexOf(question)
      if (indx === -1) return
      this.newModel.example_questions.splice(indx, 1)
    },
    removeKeyword(keyword) {
      const indx = this.newModel.keywords.indexOf(keyword)
      if (indx === -1) return
      this.newModel.keywords.splice(indx, 1)
    }
  }
}
</script>
