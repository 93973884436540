import { app } from "@/main.js"

export default {
  namespaced: true,
  state() {
    return {
      benchmarks: [],
      clientQuestions: [],
      clientTextQuestions: [],
      dataset: {},
      datasetDetailsLoaded: false,
      globalQuestions: [],
      matchesDetails: {},
      segmentDetails: {},
      suggestedMatches: {}
    }
  },
  getters: {
    getBenchmarks: (state) => state.benchmarks,
    getClientQuestions: (state) => state.clientQuestions,
    getClientTextQuestions: (state) => state.clientTextQuestions,
    getDataset: (state) => state.dataset,
    getDatasetDetailsLoaded: (state) => state.datasetDetailsLoaded,
    getGlobalQuestions: (state) => state.globalQuestions,
    getMatchesDetails: (state) => state.matchesDetails,
    getSegmentDetails: (state) => state.segmentDetails,
    getSuggestedMatches: (state) => state.suggestedMatches
  },
  mutations: {
    SET_BENCHMARKS(state, payload) {
      state["benchmarks"] = payload
    },
    SET_CLIENT_QUESTIONS(state, payload) {
      state["clientQuestions"] = payload
    },
    SET_CLIENT_TEXT_QUESTIONS(state, payload) {
      state["clientTextQuestions"] = payload
    },
    SET_DATASET(state, payload) {
      state["dataset"] = payload
    },
    SET_DATASET_DETAILS_LOADED(state, payload) {
      state["datasetDetailsLoaded"] = payload
    },
    SET_GLOBAL_QUESTIONS(state, payload) {
      state["globalQuestions"] = payload
    },
    SET_MATCHES_DETAILS(state, payload) {
      state["matchesDetails"] = payload
    },
    SET_SEGMENT_DETAILS(state, payload) {
      state["segmentDetails"] = payload
    },
    SET_SUGGESTED_MATCHES(state, payload) {
      state["suggestedMatches"][payload.client_question_id] =
        payload.global_question_id
    },
    UPDATE_CLIENT_QUESTION(state, payload) {
      state["clientQuestions"][payload.indx] = payload.clientQuestion
    },
    UPDATE_DATASET_SEGMENTS(state, payload) {
      state["segments"] = payload
    },
    RESET_DATASET_DETAILS(state) {
      state["benchmarks"] = []
      state["clientQuestions"] = []
      state["clientTextQuestions"] = []
      state["dataset"] = {}
      state["datasetDetailsLoaded"] = false
      state["globalQuestions"] = []
      state["matchesDetails"] = []
      state["segmentDetails"] = []
      state["suggestedMatches"] = []
    }
  },
  actions: {
    async setBenchmarks({ commit }, payload) {
      await commit("SET_BENCHMARKS", payload)
    },
    async setClientQuestions({ commit }, payload) {
      await commit("SET_CLIENT_QUESTIONS", payload)
    },
    async setClientTextQuestions({ commit }, payload) {
      await commit("SET_CLIENT_TEXT_QUESTIONS", payload)
    },
    async setDataset({ commit }, payload) {
      await commit("SET_DATASET", payload)
    },
    async setDatasetDetailsLoaded({ commit }, payload) {
      await commit("SET_DATASET_DETAILS_LOADED", payload)
    },
    async setGlobalQuestions({ commit }, payload) {
      await commit("SET_GLOBAL_QUESTIONS", payload)
    },
    async setMatchesDetails({ commit }, payload) {
      await commit("SET_MATCHES_DETAILS", payload)
    },
    async setSegmentDetails({ commit }, payload) {
      await commit("SET_SEGMENT_DETAILS", payload)
    },
    async updateClientQuestion({ commit, state }, clientQ) {
      const indx = state.clientQuestions.findIndex(
        (q) => clientQ && q._id.$oid === clientQ._id.$oid
      )
      if (indx < 0) return
      const data = {
        indx: indx,
        clientQuestion: clientQ
      }
      await commit("UPDATE_CLIENT_QUESTION", data)
    },
    async updateDatasetSegments({ commit }, segments) {
      await commit("UPDATE_DATASET_SEGMENTS", segments)
    },
    setSuggestedMatches({ commit }, payload) {
      commit("SET_SUGGESTED_MATCHES", payload)
    },
    resetDatasetDetails({ commit }) {
      commit("RESET_DATASET_DETAILS")
    }
  }
}
