<template>
  <div class="landing-page__wrapper">
    <header
      class="landing-page__hero-section"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/hero.png')})`
      }"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <div class="landing-page__head">
        <img
          class="landing-page__image landing-page__image--left landing-page__image--logo landing-page__image--padded"
          style="cursor: pointer"
          src="/assets/landing/RAI_logo.png"
          alt="Research AI"
          @click="returnToHome"
        />
        <nav aria-label="primary" class="landing-page__nav">
          <ul class="landing-page__nav-inner">
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/register"
              >
                <VisibleText>Register for free</VisibleText>
              </router-link>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/library"
              >
                <VisibleText>Library</VisibleText>
              </router-link>
            </li>
            <li
              class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
              v-show="false"
            >
              <label for="lang-select" class="label">
                <SvgIconDecorative icon="translate" />
                <span class="sr-only"><VisibleText>language</VisibleText></span>
              </label>
              <select
                class="landing-page__select landing-page__font--white landing-page__font--bold"
                id="lang-select"
                @change="changeLanguage($event)"
              >
                <option
                  value="en"
                  :data-parent="$options.name"
                  :selected="currentLang === 'en'"
                >
                  {{ translate("English", $options.name) }}
                </option>
                <option
                  value="fr"
                  :data-parent="$options.name"
                  :selected="currentLang === 'fn'"
                >
                  {{ translate("French", $options.name) }}
                </option>
              </select>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/login"
              >
                <VisibleText v-if="$pigeonline.userIsAuthenticated()">
                  Return to app
                </VisibleText>
                <VisibleText v-else>Log in</VisibleText>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="landing__content-box">
        <div
          class="landing-page__content-box landing-page__content-box--padded-bottom"
        >
          <div class="landing-page__seven landing-page__column-widen">
            <h1
              class="landing-page__font--h1 landing-page__font--white landing-page__font--outline"
            >
              <VisibleText>
                ResearchAI has its roots in the Middle East
              </VisibleText>
            </h1>
            <div
              class="landing-page__font landing-page__font--dark-blue landing-page__font--right-padding landing-page__font--large"
            >
              <VisibleText>
                ResearchAI began its journey advancing AI accessibility across
                the Gulf. Our goal is to be the leader in finding business
                intelligence insights in Arabic text.
              </VisibleText>
            </div>
          </div>
          <div
            class="landing-page__five"
            style="display: flex; flex-direction: row"
          >
            <div style="width: 50%; display: flex; flex-direction: column">
              <img
                style="height: 6em; width: auto"
                class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                src="/assets/landing/dubaifutureaccelerators.png"
                alt=""
              />
              <img
                style="height: 6em; width: auto"
                class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                src="/assets/landing/NYU-AD.png"
                alt=""
              />
            </div>
            <div style="width: 50%; display: flex; flex-direction: column">
              <img
                style="height: 6em; width: auto"
                class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                src="/assets/landing/du.png"
                alt=""
              />

              <img
                style="height: 4em; width: auto; margin-top: 0.5em"
                class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                src="/assets/landing/UAE-PMO.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      tabindex="-1"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <section>
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>What we built</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              1.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/collect-data_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              Business intelligence platform
            </h3>
            <p>
              <VisibleText>
                We built a tool that makes it easy to connect your data and find
                the kinds of insights that drive business improvement.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              2.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/leaders_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Supporting digital leadership</VisibleText>
            </h3>
            <p>
              <VisibleText>
                Using ResearchAI combined with our workshops and training,
                organizations have been able to build or advance their first
                digital strategies and become sector leaders.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              3.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/analyze_text-arabic_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Arabic text analysis</VisibleText>
            </h3>
            <p>
              <VisibleText>
                We have partnered with New York University: Abu Dhabi to build
                an experimental Arabic version of the tool. But we need
                partnerships with organizations like you to complete our
                innovations.
              </VisibleText>
            </p>
          </div>
        </div>
      </section>
      <div class="landing-page__grey_skew"></div>
      <section class="landing-page__grey-section">
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText
            >Our vision: help the MENA region become digital
            leaders</VisibleText
          >
        </h2>
        <p style="text-align: center; padding: 0 3em">
          <VisibleText>
            While we may have been founded in the UK and Canada, our innovation
            roots are in the Middle East and we hope to give back to this
            vibrant and growing community in any way we can.
          </VisibleText>
        </p>
        <div
          class="landing-page__content-box landing-page__content-box--mobile-flex"
          role="tablist"
          aria-label="Examples of using ResearchAI for social research"
        >
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-drivers"
            role="tab"
            :aria-selected="tab_active === 0"
            :class="[
              tab_active === 0
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 0"
          >
            <VisibleText>Tailored business intelligence</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-benchmarking"
            role="tab"
            :aria-selected="tab_active === 1"
            :class="[
              tab_active === 1
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 1"
          >
            <VisibleText>Advancing Arabic text analysis</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-performance"
            role="tab"
            :aria-selected="tab_active === 2"
            :class="[
              tab_active === 2
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 2"
          >
            <VisibleText>Growing partnerships</VisibleText>
          </button>
        </div>
        <div
          v-show="tab_active === 0"
          aria-labelledby="tab-drivers"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 0"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <div style="display: flex; flex-direction: row">
              <h3 class="landing-page__font--h3--large">
                <VisibleText>Built from regional leaders</VisibleText>
              </h3>
            </div>
            <p>
              <VisibleText>
                Our founding team and Board of Directors has 50+ years of
                collective experience helping businesses and governments in the
                Middle East and North Africa use evidence and data about
                customers, employees and operations to drive improvement. We
                came together to build ResearchAI so you can:
              </VisibleText>
            </p>
            <h4>
              <VisibleText> Predict improvement </VisibleText>
            </h4>

            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Know exactly what aspects of an experience are 'driving'
                    satisfaction, churn, purchase, loyalty, engagement, or other
                    business outcome.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Prioritize top predictors and track progress over time
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Identify drivers that may be unique for different segments.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_cxdrivers')"
                >
                  <img
                    style="width: 50%"
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_cxdrivers.png"
                    alt="Screenshot of customer experience drivers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>

            <h4>
              <VisibleText> Track segments and journey </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Track the entire journey of a customer or employee
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Benchmark sectors or departments against each other
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Detect important 'outliers' in your service experience, such
                    as specific groups that are particularly upset.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_cx-outliers')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_cx-outliers.png"
                    alt="Screenshot of segmentation outliers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>

            <h4>
              <VisibleText>
                Unlock the voice of the customer or employee
              </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Use open-ended surveys to build customer or employee
                    measures that actually matter to them.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Capture important signals about your organization from
                    already-available text data, like social media.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Go beyond satisfaction: understand emotions, sentiment, and
                    organic trends.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_suggested-themes-2')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_suggested-themes.png"
                    alt="Screenshot of auto-generation thematic analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 1"
          aria-labelledby="tab-benchmarking"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 1"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>
                Finding sentiments and themes in Arabic text
              </VisibleText>
            </h3>
            <h4>
              <VisibleText> Phase one - Successful experiments </VisibleText>
            </h4>
            <div style="display: flex; flex-direction: row">
              <p style="width: 70%">
                <VisibleText>
                  In partnership with the New York University Abu Dhabi we
                  leveraged tools built by industry-leading researcher Professor
                  Nizar Habish to show that automated insights about the Arabic
                  language can be made accessible in our APIs.
                </VisibleText>
              </p>
              <div style="display: flex; width: 30%">
                <img
                  style="height: 6em; width: auto"
                  class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                  src="/assets/landing/NYU-AD.png"
                  alt=""
                />
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <p style="width: 70%">
                <VisibleText>
                  We then partnered with Du Telecom to test if Arabic social
                  media could be summarized automatically, and if clues about
                  customer leads could be found.
                </VisibleText>
              </p>
              <div style="display: flex; width: 30%">
                <img
                  style="height: 4em; width: auto"
                  class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                  src="/assets/landing/du.png"
                  alt=""
                />
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <p style="width: 70%">
                <VisibleText>
                  Our next partnership was with the Prime Ministers Office of
                  the United Arab Emirates. We built an engine that can take any
                  query about a desired talent HR might be looking for when
                  filling leadership positions, and then make recommended
                  matches based on the profiles existing in their system.
                </VisibleText>
              </p>
              <div style="display: flex; width: 30%">
                <img
                  style="height: 4em; width: auto"
                  class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                  src="/assets/landing/UAE-PMO.png"
                  alt=""
                />
              </div>
            </div>
            <h4>
              <VisibleText> Phase two - We need your help </VisibleText>
            </h4>
            <p>
              <VisibleText>
                Given the success of ResearchAI in using text AI to make it
                easier to find themes and sentiments, our next goal is to allow
                the same to be done over Arabic text.
              </VisibleText>
            </p>
            <p>
              <VisibleText>
                This is where we need your help. The easiest way for us to add
                Arabic to ResearchAI is to test our tools with organizations
                like you. Register for free to try the tool in English and let
                us know if you think you would like to do the same for Arabic.
                If you have customer or employee data that is in Arabic, that's
                all we need to begin working together.
              </VisibleText>
            </p>
          </div>
        </div>
        <div
          v-show="tab_active === 2"
          aria-labelledby="tab-performance"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 2"
        >
          <div
            class="landing-page__font landing-page__bottom-padding"
            style="display: flex; flex-direction: column"
          >
            <div style="display: flex; flex-direction: column">
              <h3 class="landing-page__font--h3--large">
                <VisibleText
                  >Let's work together to make MENA a world leader in digital
                  management</VisibleText
                >
              </h3>
              <div style="display: flex; flex-direction: row">
                <p style="width: 70%">
                  <VisibleText>
                    We graduated from the 4th Cohort of the Dubai Future
                    Accelerators. Based in a hub of business and government
                    innovation, steps from the Dubai International Finacial
                    Center and numerous government offices, ResearchAI's
                    founding team (PigeonLine Inc.) built lasting relationships
                    with many of the region's leading innovators.
                  </VisibleText>
                </p>
                <div style="display: flex; width: 30%">
                  <img
                    style="height: 6em; width: auto"
                    class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                    src="/assets/landing/dubaifutureaccelerators.png"
                    alt=""
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: row">
                <p style="width: 70%">
                  <VisibleText>
                    Thanks to our partners at Farah Enterprises, a local UAE
                    venture, we are licensed to distribute our tools and
                    services across the UAE. We hope to build similar
                    partnerships with ventures across MENA.
                  </VisibleText>
                </p>
                <img
                  style="height: 6em; width: auto"
                  class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                  src="/assets/landing/farah-enterprises.png"
                  alt=""
                />
              </div>
              <div style="display: flex; flex-direction: row">
                <p style="width: 70%">
                  <VisibleText>
                    We have the support of the region's investment community,
                    with 2 of our Board Members and 3 of our investors from UAE,
                    including Albeed Investments.
                  </VisibleText>
                </p>
                <img
                  style="height: 6em; width: auto"
                  class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
                  src="/assets/landing/albeed-group.png"
                  alt=""
                />
              </div>
              <p>
                <VisibleText>
                  We are keen to replicate these successes leaders from other
                  communities across the region.
                </VisibleText>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--mobile-fit-height"
        :style="{
          backgroundImage: `url(${require('@/assets/landing/blue_section.png')})`
        }"
      >
        <h2
          class="landing-page__font--h2 landing-page__font--center landing-page__font--white landing-page__font--outline"
        >
          <VisibleText>Why we're different</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__font landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__four landing-page__shadow landing-page__font--center landing-page--widen"
          >
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/leaders_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>We were borne from the MENA</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Unlike most technologies which are built elsewhere but then
                marketed across the region, we are proud that our technologies
                have been built from the ground up solving MENA needs.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__four landing-page__shadow landing-page__font--center landing-page--widen"
          >
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/research_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText
                >We focus on quick results, not burdensome
                contracts</VisibleText
              >
            </h3>
            <p>
              <VisibleText
                >One of the main reasons ResearchAI was built was to make life
                easier for governments and businesses across the Gulf to access
                business intelligence without the slow and expansive contracts
                that we are used to in the region.
              </VisibleText>
            </p>
          </div>
        </div>
      </section>
    </main>
    <footer :aria-hidden="modalOpen" :inert="modalOpen">
      <div
        class="landing-page__content-center landing-page__content-box--adjusted-padding"
      >
        <h2
          class="landing-page__font--h2"
          style="margin-top: 2em; margin-bottom: -1em"
        >
          <VisibleText>Contact us</VisibleText>
        </h2>
      </div>
      <div class="landing-page__content-box landing-page__content-box--center">
        <div>
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/mail_icon.png"
            alt=""
          />
          <div style="display: inline-block" class="landing-page__font">
            <div class="landing-page__font--blue">
              <VisibleText>Email</VisibleText>
            </div>
            <div>hello@researchai.io</div>
          </div>
        </div>
        <a href="https://www.linkedin.com/company/25166569/" target="_blank">
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/linkedin.png"
            alt=""
          />
          <span style="display: inline-block" class="landing-page__font">
            <span
              class="landing-page__footer-text-block landing-page__font--blue"
            >
              LinkedIn
            </span>
            <span
              class="landing-page__footer-text-block landing-page__font--black landing-page__font--underline"
            >
              <VisibleText>Follow us!</VisibleText>
              <SvgIconDecorative icon="externalLink" style="width: 1rem" />
            </span>
          </span>
          <span class="sr-only">
            <VisibleText>(opens in a new tab)</VisibleText>
          </span>
        </a>
      </div>
      <div
        class="landing-page__content-box landing-page__font landing-page__padded-footer"
      >
        <VisibleText>Copyright © 2024 PigeonLine Inc.</VisibleText>
        <div
          class="landing-page__six landing-page__font landing-page__font--right landing-page__font--margined-top"
        >
          <router-link to="/library/privacy-policy">
            <VisibleText>PRIVACY POLICY</VisibleText>
          </router-link>
          |
          <router-link to="/library/ethics-policy">
            <VisibleText>ETHICS POLICY</VisibleText>
          </router-link>
        </div>
      </div>
    </footer>
    <ContactUs
      v-bind:status="isContactUsExpanded"
      @close="isContactUsExpanded = false"
    ></ContactUs>
    <ScreenshotGuide
      :status="isScreenshotExpanded"
      :thisScreenshot="thisScreenshot"
      @close="isScreenshotExpanded = false"
    >
    </ScreenshotGuide>
  </div>
</template>
<script>
import { app } from "@/main.js"
import ContactUs from "@/components/ContactUs/ContactUs.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import ScreenshotGuide from "@/components/UserGuide/ScreenshotGuide.vue"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import { mapActions } from "vuex"

export default {
  name: "mena",
  components: {
    ContactUs,
    SvgIconDecorative,
    ScreenshotGuide
  },
  data() {
    return {
      tab_active: 0,
      carousel_active: 1,
      carousel_transform: null,
      carousel_curr_item: null,
      transition_class: null,
      isContactUsExpanded: false,
      isScreenshotExpanded: false,
      thisScreenshot: ""
    }
  },
  setup() {
    const siteData = reactive({
      title: app.config.globalProperties.$theme.theme.pageTitle + "| MENA",
      description:
        "ResearchAI has its roots in the MENA region, learn more about our vision to support business intelligence across the Middle East, including text analysis for Arabic."
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    if (window.localStorage.getItem("apiLanguage"))
      this.setLanguage(window.localStorage.getItem("apiLanguage"))
  },
  computed: {
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    changeLanguage(language) {
      this.setLanguage(language.target.value)
      window.localStorage.setItem("apiLanguage", language.target.value)
    },
    currentLang() {
      return (
        this.$store.getters["getLanguage"] ||
        window.localStorage.getItem("apiLanguage")
      )
    },
    onCarouselFocus() {
      // set to currently active carousel item
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    nextCarousel() {
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      if (this.carousel_active !== length) {
        let shift_amount = 130 * this.carousel_active
        this.carousel_transform =
          "transform: translateX(-" + shift_amount + "%);"
        this.carousel_active = this.carousel_active + 1
      } else {
        this.carousel_active = 1
        this.carousel_transform = "transform: translateX(0);"
      }

      // set tabindex of ICCS link
      if (this.carousel_active === 3) {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "0")
      } else {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "-1")
      }

      // curr item text for sr
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    openScreenshot(screenshot) {
      this.thisScreenshot = screenshot
      this.isScreenshotExpanded = true
    },
    returnToHome() {
      this.$router.push({ path: "/" })
    }
  }
}
</script>
