import {
  header,
  subHeader,
  summary,
  table
} from "./benchmarkingSummaryNodes.js"

/**
 * Generate skeleton for benchmarking summary, which is just the header.
 * @param {String} lang of report
 * @returns skeleton of benchmarking section formatted as a pigondoc section node
 */
const benchmarkingSummarySkeleton = (lang = "en") => {
  return {
    type: "section",
    id: "benchmarking-summary-section",
    content: [header(lang)]
  }
}

const benchmarkingSummarySection = (data, lang = "en") => {
  if (
    data &&
    (data.dateParams || (data.otherFilters && data.otherFilters.length))
  ) {
    return {
      type: "section",
      id: "benchmarking-summary-section",
      content: [
        header(lang),
        subHeader(data.dateParams, data.otherFilters),
        summary(data.overallDynamicSummary),
        table(data.summaryTable, data.summaryTableColNames, lang)
      ],
      meta: {
        subTitle: "BM SUMMARY"
      },
      uuid: data.uuid
    }
  } else {
    return {
      type: "section",
      id: "benchmarking-summary-section",
      content: [
        header(lang),
        summary(data.overallDynamicSummary),
        table(data.summaryTable, data.summaryTableColNames, lang)
      ],
      meta: {
        subTitle: "BM SUMMARY"
      },
      uuid: data.uuid
    }
  }
}

export { benchmarkingSummarySkeleton, benchmarkingSummarySection }
