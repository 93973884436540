<template>
  <Modal
    header-tag="h1"
    :show="isVideoClipExpanded"
    :resetListener="resetListener"
    @close="closeVideo"
  >
    <video
      v-show="videoShortURL === 'text-analysis'"
      class="video-clip"
      ref="video-text-analysis"
      controls
    >
      <source src="/assets/landing/text-analysis.mp4" type="video/mp4" />
      <VisibleText
        >Sorry, your browser doesn't support embedded videos.
      </VisibleText>
    </video>
    <video
      v-show="videoShortURL === 'regression'"
      class="video-clip"
      ref="video-regression"
      controls
    >
      <source src="/assets/landing/regression.mp4" type="video/mp4" />
      <VisibleText
        >Sorry, your browser doesn't support embedded videos.
      </VisibleText>
    </video>
    <video
      v-show="videoShortURL === 'intro'"
      class="video-clip"
      ref="video-intro"
      controls
    >
      <source src="/assets/landing/researchai-promo.mp4" type="video/mp4" />
      <VisibleText
        >Sorry, your browser doesn't support embedded videos.
      </VisibleText>
    </video>
    <video
      v-show="videoShortURL === 'demo'"
      class="video-clip"
      ref="video-demo"
      controls
    >
      <source src="/assets/landing/intro.mp4" type="video/mp4" />
      <VisibleText
        >Sorry, your browser doesn't support embedded videos.
      </VisibleText>
    </video>
    <VideoTranscript
      v-if="videoShortURL === 'demo'"
      class="landing-page__font"
      mdFilePath="/files/demoTranscript.md"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import VideoTranscript from "@/components/VideoClip/VideoTranscript.vue"

export default {
  name: "VideoClip",
  components: {
    Modal,
    VideoTranscript
  },
  props: {
    status: {
      type: Boolean,
      default: () => false
    },
    videoShortURL: {
      type: String,
      default: () => "intro"
    }
  },
  data() {
    return {
      newStatus: false,
      firstLoad: false,
      resetListener: false
    }
  },
  methods: {
    // removed close and save, is it needed?
    // close_and_save() {
    //   this.isVideoClipExpanded = false
    //   this.$emit("close")
    // },
    closeVideo() {
      this.$refs["video-" + this.videoShortURL].pause()
      this.isVideoClipExpanded = false
      this.$emit("close")
    }
  },
  computed: {
    isVideoClipExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    }
    // isVideoClipExpanded(newVal) {
    //   if (newVal) {
    //     this.$refs["video-" + this.videoURL].play()
    //   } else {
    //     this.$refs["video-" + this.videoURL].pause()
    //   }
    // }
  }
}
</script>
