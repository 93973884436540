<template>
  <article id="project-analysis-outliers" v-if="project">
    <div
      class="project-analysis__header"
      style="justify-content: space-between"
      :inert="modalOpen"
      :aria-hidden="modalOpen"
    >
      <h2 class="project-analysis__header-title">
        <VisibleText>Find groups of outliers</VisibleText>
      </h2>

      <div
        style="display: flex; justify-content: flex-end"
        v-if="hasScaleQuestions"
      >
        <Spinner
          :is-loading="findingSuggestedVariables"
          message="Searching for suggested analysis"
          complete-message=""
        />
        <button
          class="project-analysis__header-add-btn"
          style="margin: 0 1em"
          v-if="
            !this.project.outliersAnalysis ||
            !this.project.outliersAnalysis.length
          "
          :disabled="propReadOnly"
          @click="findSuggestedVariables()"
        >
          <VisibleText>Auto-suggest analysis</VisibleText>
        </button>
        <button
          class="project-analysis__header-add-btn"
          @click="addNewOutliersAnalysis"
          :disabled="propReadOnly"
        >
          <VisibleText>Add new outliers analysis</VisibleText>
        </button>
      </div>
    </div>
    <p class="drivers__desc" :inert="modalOpen" :aria-hidden="modalOpen">
      <VisibleText>
        This analysis lets you find outliers across rows of data by finding
        groups (or categories) or rows that have a high
      </VisibleText>
      <Tooltip
        class="drivers__info"
        :text-visible="isInfoTooltipOpen"
        @close="isInfoTooltipOpen = false"
      >
        <template #title>
          <button
            class="drivers__regression-btn"
            @click="isInfoTooltipOpen = true"
          >
            <VisibleText>z-scores</VisibleText>
            <SvgIconDecorative
              icon="info"
              class="drivers__regression-btn-icon"
              title="details"
            />
          </button>
        </template>
        <VisibleText>
          Z-scores are standardized measures for how much of an outlier a
          datapoint is compared to its peers. Most data are between -2 or 2
          standard deviations (z-scores) away from the mean. Scores above or
          below this become considered outliers.
        </VisibleText>
        <ul>
          <li>
            <VisibleText>
              Greater or less than 2: Somewhat an outlier
            </VisibleText>
          </li>
          <li>
            <VisibleText>Greater or less than 3: An outlier</VisibleText>
          </li>
          <li>
            <VisibleText>Greater or less than 4: A strong outlier</VisibleText>
          </li>
          <li>
            <VisibleText>
              Greater or less than 5: An extreme outlier
            </VisibleText>
          </li>
        </ul>
      </Tooltip>
      <VisibleText>
        which signals these groups may be considerably above or below the
        average scores. Identifying these can be helpful for removing extreme
        results, or for finding specific groups that may be in need of
        attention. Below you can explore and 'save' the most interesting
        outliers.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Note that this requires both scale questions (such as 5-point scales) as
        well as categorical questions. As this tool looks for those with high or
        low scale performance across different categories as groups.
      </VisibleText>
    </p>
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Guide: </VisibleText>
          <SvgIconDecorative icon="externalLink" />
          <a
            href="javascript:void(0)"
            @click="
              $emit('user-guide-expanded'),
                $emit('open-article', 'OutliersAnalysis')
            "
          >
            Our guide to using outliers analysis to find over- or under-
            performing segments.
          </a>
        </div>
      </div>
    </div>
    <OutliersMessages :status-name="statusName" />

    <template
      v-if="project.outliersAnalysis && project.outliersAnalysis.length > 0"
    >
      <ProjectAnalysisOutliersItem
        v-for="(outlier, index) in project.outliersAnalysis"
        :key="outlier.uuid"
        :index="index"
        @refresh="$emit('refresh')"
        :propReadOnly="propReadOnly"
        @updateSlides="$emit('updateSlides')"
      />
    </template>
    <NewOutliersAnalysisModal
      :available-questions="availableQuestions"
      :show="isNewTextModalVisible"
      @confirmSelection="createNewOutliers"
      @close-modal="isNewTextModalVisible = false"
    />
  </article>
</template>

<script>
// Components
import OutliersMessages from "./Components/OutliersMessages.vue"
import NewOutliersAnalysisModal from "./Modals/NewOutliersAnalysisModal.vue"
import ProjectAnalysisOutliersItem from "./ProjectAnalysisOutliersItem.vue"
import Tooltip from "@/components/UI/Tooltip.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
// import Spinner from "@/components/UI/Spinner.vue"

// Helpers
import moment from "moment"

// Mixins
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

export default {
  name: "ProjectAnalysisOutliers",
  mixins: [ProjectMixin, DatasetDetailsMixin, ProjectReportMixin],
  components: {
    NewOutliersAnalysisModal,
    ProjectAnalysisOutliersItem,
    Tooltip,
    SvgIconDecorative,
    OutliersMessages
  },
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    hasScaleQuestions: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      availableQuestions: [],
      isNewTextModalVisible: false,
      isInfoTooltipOpen: false,
      findingSuggestedVariables: false,
      suggestedVariables: []
    }
  },
  async created() {
    this.updateTheseQuestions()
  },
  computed: {
    statusName() {
      if (!this.hasScaleQuestions) {
        return "hasNoScale"
      }
      return ""
    }
  },
  methods: {
    async findSuggestedVariables() {
      this.findingSuggestedVariables = true

      this.suggestedVariables = await this.fetchSuggestedVariables()
      if (this.suggestedVariables.length) {
        this.useSuggestedVariables()
      }
      this.findingSuggestedVariables = false
    },
    fetchSuggestedVariables() {
      let foundVars = []
      if (this.availableQuestions && this.availableQuestions.length) {
        for (let i = 0; i < this.availableQuestions.length; i++) {
          if (
            this.availableQuestions[i].approved_data_type &&
            this.availableQuestions[i].approved_data_type.includes("SCALE")
          ) {
            foundVars.push(this.availableQuestions[i]._id.$oid)
          }
        }
      }
      return foundVars
    },
    useSuggestedVariables() {
      this.createNewOutliers(this.suggestedVariables)
    },
    async updateTheseQuestions() {
      if (this.project) {
        if (this.project.datasets) {
          if (this.project.datasets.length) {
            // modify for multi-dataset selection
            const theseQuestions = await this.fetchClientQuestions(
              this.project.datasets[0]
            )
            if (theseQuestions) {
              for (let i = 0; i < theseQuestions.length; i++) {
                this.availableQuestions.push(theseQuestions[i])
              }
            }
          }
        }
      }
    },
    async createNewOutliers(selectedQuestions, filterParam) {
      if (this.project) {
        if (this.project.datasets) {
          if (this.project.datasets.length) {
            // check length
            const outliersObject = {
              last_modified_on: moment().valueOf(),
              uuid: this.$pigeonline.createUUID(),
              filter_params: filterParam || {},
              summary_table: [],
              outliers: [],
              selectedQuestions: selectedQuestions
            }
            this.project.outliersAnalysis.unshift(outliersObject)
          }
        }
      }
    },
    async addNewOutliersAnalysis() {
      this.isNewTextModalVisible = true
    }
  }
}
</script>
