// Helpers
import { mapActions, mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("datasetDetails", {
      benchmarks: "getBenchmarks",
      clientQuestions: "getClientQuestions",
      clientTextQuestions: "getClientTextQuestions",
      dataset: "getDataset",
      datasetDetailsLoaded: "getDatasetDetailsLoaded",
      globalQuestions: "getGlobalQuestions",
      matchesDetails: "getMatchesDetails",
      segmentDetails: "getSegmentDetails",
      suggestedMatches: "getSuggestedMatches"
    }),

    // services
    BENCHMARKING_SERVICE() {
      return this.$services.BENCHMARKING_SERVICE
    },
    DATASETS_SERVICE() {
      return this.$services.DATASETS_SERVICE
    },
    DRIVERS_SERVICE() {
      return this.$services.DRIVERS_SERVICE
    }
  },
  methods: {
    ...mapActions("datasetDetails", [
      "setBenchmarks",
      "setClientQuestions",
      "setClientTextQuestions",
      "setDataset",
      "setDatasetDetailsLoaded",
      "setGlobalQuestions",
      "setMatchesDetails",
      "setSegmentDetails",
      "setSuggestedDataTypes",
      "setSuggestedMatches",
      "updateClientQuestion",
      "resetDatasetDetails"
    ]),
    async $_datasetDetailsMixin_reloadBenchmarks() {
      const benchmarks = await this.fetchBenchmarks(this.dataset._id.$oid)
      this.setBenchmarks(benchmarks)
      this.setMatchesDetails(await this.fetchMatchesDetails(benchmarks))
    },
    /************
     *
     * Loading and setting up data in store
     *
     ***********/
    async loadDatasetDetails(dataset) {
      this.$store.dispatch("loader/setLoading", true)
      this.loadDatasetData(dataset)
        .then(
          async ({
            benchmarks,
            clientQuestions,
            clientTextQuestions,
            globalQuestions
          }) => {
            this.setBenchmarks(benchmarks)
            this.setClientQuestions(clientQuestions)
            this.setClientTextQuestions(clientTextQuestions)
            this.setGlobalQuestions(globalQuestions)
            this.setMatchesDetails(this.fetchMatchesDetails(benchmarks))
            this.setDatasetDetailsLoaded(true)
            this.$store.dispatch("loader/setLoading", false)
          }
        )
        .catch(() => this.$store.dispatch("loader/setLoading", false))
    },
    async loadDatasetData(dataset) {
      const [
        benchmarks,
        clientQuestions,
        clientTextQuestions,
        globalQuestions,
        segmentDetails
      ] = await Promise.all([
        await this.fetchBenchmarks(dataset._id.$oid),
        await this.fetchClientQuestions(dataset._id.$oid),
        await this.fetchClientTextQuestions(dataset._id.$oid),
        await this.fetchGlobalQuestions(dataset)
      ])
      return {
        benchmarks,
        clientQuestions,
        clientTextQuestions,
        globalQuestions,
        segmentDetails
      }
    },
    /************
     *
     * Updating data in store
     *
     ***********/
    async updateDataset(id, body) {
      const result = await this.DATASETS_SERVICE.update(id, body)
      this.setDataset(result.data_set_object)
    },
    async updateClientQuestions(datasetId) {
      const fetchedQuestions = await this.fetchClientQuestions(datasetId)

      if (fetchedQuestions.length) {
        await this.setClientQuestions(fetchedQuestions)
        return fetchedQuestions
      }
      return []
    },
    async $_datasetDetailsMixin_deleteQuestions(questionIds, datasetId) {
      await this.$services.DATASETS_SERVICE.batchDeleteClientQuestions(
        questionIds
      )
      await this.updateClientQuestions(datasetId)
    },
    /************
     *
     * Fetching data
     *
     ***********/
    async fetchDataset(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.dataset(datasetId)
        return response
      } catch (e) {
        throw new Error("DatasetDetailsMixin:fetchDataset " + e.message)
      }
    },
    async fetchBenchmarks(datasetId) {
      try {
        const response = await this.BENCHMARKING_SERVICE.benchmarking({
          client_data_set_id: datasetId
        })
        return response
      } catch (e) {
        throw new Error("DatasetDetailsMixin.js:fetchBenchmarks " + e.message)
      }
    },
    async fetchClientQuestions(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.clientQuestions(null, {
          data_set_id: datasetId,
          sort_by: "original_order"
        })
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchClientQuestions " + e.message
        )
      }
    },
    async fetchClientTextQuestions(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.clientQuestions(null, {
          data_set_id: datasetId,
          sort_by: "original_order"
        })
        return response.filter((c) => c.approved_data_type === "OPEN_ENDED")
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchClientTextQuestions " + e.message
        )
      }
    },
    async fetchGlobalQuestions(dataset = null) {
      try {
        const data = dataset
          ? {
              benchmark_group: dataset.benchmark_group
            }
          : {}
        const response = await this.DATASETS_SERVICE.globalQuestions(null, data)
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchGlobalQuestions " + e.message
        )
      }
    },
    async fetchSegmentDetails(datasetBenchmarkGroupId) {
      try {
        if (!datasetBenchmarkGroupId) return {}
        const response = await this.BENCHMARKING_SERVICE.segmentsDetails({
          benchmark_group: datasetBenchmarkGroupId
        })
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchSegmentDetails " + e.message
        )
      }
    },
    fetchMatchesDetails(benchmarks) {
      if (this.globalQuestions.length == 0) return {}
      return benchmarks.reduce((matches, match) => {
        let globalQ = this.globalQuestions.find(
          (q) => q._id.$oid == match.global_question_id
        )
        let temp = {
          benchmark_id: match._id.$oid,
          global_question_id: globalQ._id.$oid,
          global_question_title: globalQ.question_title,
          global_question_text: globalQ.question_text
        }
        matches[match.client_question_id] = temp
        return matches
      }, {})
    }
  }
}
