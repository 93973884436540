<template>
  <div>
    <div v-if="benchmarkGroup">
      <p>
        <VisibleText
          >You are requesting to match your questions to the following
          benchmarking database:
        </VisibleText>
      </p>
      <div style="display: flex; justify-content: flex-end">
        <button
          style="
            border: none;
            background-color: transparent;
            font-size: 0.8em;
            margin-bottom: -1em;
            color: var(--error-text-colour);
          "
          @click="removeBenchmarkMatch"
        >
          <SvgIconDecorative icon="deleteIcon" />
          Cancel matching to this database
        </button>
      </div>
      <div
        style="display: flex; flex-direction: column; width: 95%"
        class="message__block message__block--information"
      >
        <div style="display: flex; flex-direction: row">
          <div
            class="project-analysis-item__header-wrapper-btn"
            style="
              display: flex;
              font-weight: 200;
              flex-direction: column;
              line-height: 1.5em;
            "
          >
            <div>
              <VisibleText style="font-weight: 800"
                >Benchmark database name: &nbsp;</VisibleText
              >
              <span>
                <VisibleText>{{ benchmarkGroup.name }}</VisibleText>
              </span>
            </div>
            <div
              v-if="
                benchmarkGroup.created_by &&
                (benchmarkGroup.created_by.name ||
                  benchmarkGroup.created_by.organization)
              "
            >
              <VisibleText style="font-weight: 800">Owner:&nbsp;</VisibleText>
              <span v-if="benchmarkGroup.created_by.name">
                <VisibleText>{{ benchmarkGroup.created_by.name }}</VisibleText>
              </span>
              <span v-else>
                <VisibleText>
                  {{ benchmarkGroup.created_by.organization }}
                </VisibleText>
              </span>
            </div>
            <div>
              <router-link
                :to="{
                  name: 'benchmarkGroupEdit',
                  params: { id: benchmarkGroup.id }
                }"
                class="benchmark-manager__groups-item-name"
                target="_blank"
              >
                View benchmark questions
                <SvgIconDecorative icon="externalLink" style="width: 1rem" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <MessageBlock
        message-type="warning"
        title="Benchmarks pending approval"
        v-if="statusBenchmarksPending"
      >
        Questions submitted to the benchmarking service are pending approval.
      </MessageBlock>
    </div>
  </div>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "DatasetBenchmarksDesc",
  mixins: [UserMixin],
  components: {
    MessageBlock,
    SvgIconDecorative
  },
  props: {
    benchmarkGroup: {
      default: () => {},
      type: Object
    }
  },
  methods: {
    removeBenchmarkMatch() {
      this.$emit("clearBenchmarks")
    }
  },
  computed: {
    statusBenchmarksPending() {
      return (
        this.$store.getters["datasetWizard/getDatasetProject"].status &&
        this.$store.getters[
          "datasetWizard/getDatasetProject"
        ].isBenchmarksPending()
      )
    }
  }
}
</script>
