<template>
  <section>
    <div
      class="dataset-table__filters-active shadow--light"
      style="
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        overflow-x: auto;
        max-width: 50vw;
      "
      :class="noToolBarClass"
    >
      <div class="title text-smallcaps">
        <div
          v-if="!isInToolBar"
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <h4 v-if="altTitle !== 'blank'" style="display: flex">
            <VisibleText>{{ altTitle }}</VisibleText>
          </h4>
          <h4 v-else style="display: flex">
            <VisibleText>Filters</VisibleText>
          </h4>
          <button
            v-if="isInAnalysisView && !hideClear"
            style="
              display: flex;
              border: none;
              background-color: transparent;
              font-size: 0.8em;
              padding-top: 0.5em;
            "
            @click="clearFilters"
          >
            <SvgIconDecorative icon="deleteIcon" />
            <VisibleText> Clear </VisibleText>
          </button>
        </div>
        <h4></h4>
        <ul class="dataset-table__filters-active__list" v-if="parsedFromObject">
          <li
            v-for="(question, index) in parsedFromObject"
            :key="`result-${index}`"
          >
            <div style="display: flex; flex-direction: column">
              <div
                class="item search rounded-pill"
                style="font-size: 0.8em; font-weight: 500"
              >
                <div v-if="question.question_title">
                  {{ limitStringLength(question.question_title) }}
                </div>
                <div v-if="!question.question_title && question.question_text">
                  {{ limitStringLength(question.question_text) }}
                </div>
              </div>
              <div
                v-if="
                  question.valuesForComparison &&
                  question.valuesForComparison.length &&
                  question.valuesForComparison.length !==
                    question.original_length
                "
              >
                <div style="font-size: 0.8em; font-weight: 500">
                  <VisibleText>Values:</VisibleText>
                </div>
                <div
                  class="item search rounded-pill"
                  style="font-size: 0.8em; font-weight: 500; display: table"
                >
                  <div
                    v-for="(value, subIndex) in question.valuesForComparison"
                    :key="`sub-result-${subIndex}`"
                    class="item search rounded-pill"
                    style="font-size: 0.8em; font-weight: 500; display: table"
                  >
                    <div>
                      {{ limitStringLength(value) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "FilterPills",
  props: {
    filterParamFromSelectedQuestions: {
      type: Object,
      default: () => {}
    },
    isInToolBar: {
      type: Boolean,
      default: () => false
    },
    isInAnalysisView: {
      type: Boolean,
      default: () => false
    },
    altTitle: {
      type: String,
      default: () => "blank"
    },
    hideClear: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    SvgIconDecorative
  },
  methods: {
    limitStringLength(string) {
      if (_.isString(string)) {
        string = string.substring(0, 20)
        if (string.length >= 20) {
          string = string + "..."
        }
      }
      return string
    },
    clearFilters() {
      this.$emit("clearFilters")
    }
  },
  computed: {
    noToolBarClass() {
      if (this.isInToolBar) {
        return "noToolBar"
      }
      return ""
    },
    parsedFromObject() {
      if (
        !this.filterParamFromSelectedQuestions ||
        !this.filterParamFromSelectedQuestions.operands
      ) {
        return []
      }
      let filters = []
      for (
        let i = 0;
        i < this.filterParamFromSelectedQuestions.operands.length;
        i++
      ) {
        if (
          this.filterParamFromSelectedQuestions.operands[i].client_question_id
        ) {
          const thisQuestion = this.$store.getters[
            "datasetDetails/getClientQuestions"
          ].find(
            (q) =>
              q._id.$oid ===
              this.filterParamFromSelectedQuestions.operands[i]
                .client_question_id
          )
          if (thisQuestion) {
            let thisObject = {
              question_text: thisQuestion.question_text,
              question_title: thisQuestion.question_title,
              valuesForComparison:
                this.filterParamFromSelectedQuestions.operands[i].values
            }
            filters.push(thisObject)
          }
        } else {
          if (
            this.filterParamFromSelectedQuestions.operands[i].operator ==
            "created_after"
          ) {
            let thisObject = {
              question_title:
                "After " +
                this.filterParamFromSelectedQuestions.operands[i].values
            }
            filters.push(thisObject)
          }
        }
      }
      return filters
    }
  }
}
</script>

<style scoped>
.noToolBar {
  margin: 0 0 1em 0;
  padding: 0.2em;
  background-color: white;
}
</style>
