<template>
  <article id="benchmark-manager-editor" class="section__content">
    <router-link to="/benchmark-manager" class="btn-back">
      <SvgIconDecorative icon="arrowLeft" />
      <VisibleText>
        Back <span class="sr-only"> to benchmark groups </span>
      </VisibleText>
    </router-link>
    <div class="benchmark-manager__editor-header" style="margin-top: 1rem">
      <div v-if="!hasFullAccess && !isLoading">
        <h3>
          <VisibleText>
            You do not have permission to access this group.
          </VisibleText>
        </h3>
      </div>
      <h2
        class="benchmark-manager__editor-title"
        v-if="hasFullAccess && isPublicReadOnly && !isOwner"
      >
        {{ benchmarkGroup ? benchmarkGroup.name : "" }}
      </h2>
      <InlineEditTextInput
        label="benchmark database name"
        input-id="benchmark-group-name-input"
        input-name="benchmark-group-name"
        :input-value="benchmarkGroup.name"
        @save="saveName"
        v-if="hasFullAccess && isOwner"
      >
        <h2 class="benchmark-manager__editor-title">
          {{ benchmarkGroup ? benchmarkGroup.name : "" }}
        </h2>
      </InlineEditTextInput>
      <button
        class="benchmark-manager__editor-add"
        @click="isAddQuestionVisible = true"
        v-if="hasFullAccess && isOwner"
      >
        <VisibleText>Add Queston</VisibleText>
      </button>
    </div>

    <div style="margin-bottom: 1rem">
      <div v-if="ownerEmail" style="display: flex; flex-direction: row">
        <VisibleText>Created by</VisibleText>:
        <span style="margin-left: 1rem">{{ ownerEmail }}</span>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 30%;
        border-radius: 0.5em;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
      "
      class="message__block--information"
      :style="
        !benchmarkGroupIsICCS
          ? 'background-color:var(--outline-light-colour); border-color:var(--outline-colour)'
          : ''
      "
      v-if="isICCSAdmin && hasFullAccess"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <div style="display: flex; flex-direction: column; font-size: 1rem">
            <div v-if="!benchmarkGroupIsICCS">
              <VisibleText
                >As an ICCS admin, you can set this to be only visible by ICCS
                license holders.</VisibleText
              >
              <button
                style="
                  color: #fff;
                  background: var(--teritary-ui-colour);
                  border: 0;
                  border-radius: 3rem;
                  font-family: Core-Sans;
                  font-size: 0.9em;
                  font-weight: bold;
                  padding: 0.3rem 1rem 0.3rem 1rem;
                  width: fit-content;
                  height: 1.5rem;
                  margin-top: 0.5rem;
                "
                @click="setToICCS"
              >
                <VisibleText
                  >Make this group an official ICCS benchmarking
                  group.</VisibleText
                >
              </button>
            </div>

            <div
              v-if="benchmarkGroupIsICCS"
              style="display: flex; flex-direction: column"
            >
              <VisibleText
                >This benchmarking group has been officially set to be viewable
                by ICCS members only</VisibleText
              >
              <button
                style="
                  color: #fff;
                  background: var(--primary);
                  border: 0;
                  border-radius: 3rem;
                  font-family: Core-Sans;
                  font-size: 0.9em;
                  font-weight: bold;
                  padding: 0.3rem 1rem 0.3rem 1rem;
                  width: fit-content;
                  height: 1.5rem;
                  margin-top: 0.5rem;
                "
                @click="removeFromICCS"
              >
                <VisibleText
                  >Remove this as an official ICCS group.</VisibleText
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 30%;
        border-radius: 0.5em;
        margin-top: 1rem;
        margin-left: 1rem;
      "
      class="message__block--information"
      v-if="benchmarkGroupIsICCS && !isICCSAdmin && hasFullAccess"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <div style="display: flex; flex-direction: column; font-size: 1rem">
            <div style="display: flex; flex-direction: column">
              <VisibleText
                >This benchmarking group has been officially set to be viewable
                by ICCS members only</VisibleText
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        profile &&
        benchmarkGroup.owner !== profile.django_ref_id &&
        hasFullAccess &&
        !isOwner &&
        benchmarkGroup.access_type == 'public__team'
      "
    >
      <h3>
        <VisibleText> Viewing as a team member </VisibleText>
      </h3>
    </div>

    <div v-if="hasFullAccess">
      <VisibleText>
        This benchmarking group is set to the following access level:
      </VisibleText>
      <select
        aria-label="access level"
        class="select"
        id="select-access-level"
        v-model="benchmarkGroup.access_type"
        style="width: 50%; margin-top: 1rem"
        :disabled="
          (profile && benchmarkGroup.owner !== profile.django_ref_id) ||
          benchmarkGroupIsICCS
        "
        @change="changeAccessLevel"
      >
        <option
          v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
          :key="level"
          :value="level"
          :data-parent="$options.name"
        >
          {{ translate(ACCESS_TYPE_TO_DESC[level].name, $options.name) }} -
          {{ translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name) }}
        </option>
      </select>
      <div
        style="margin-top: 1rem"
        v-if="benchmarkGroup.access_type == 'public__team'"
      >
        <VisibleText> For this team: </VisibleText>
        <select
          aria-label="access level"
          class="select"
          id="select-access-level-team"
          style="width: 50%; margin-top: 1rem"
          v-model="selectedTeam"
          @change="changeTeamAccessLevel"
          :disabled="profile && benchmarkGroup.owner !== profile.django_ref_id"
        >
          <option
            v-for="team in teams"
            :key="team.team_name"
            :value="team"
            :data-parent="$options.name"
          >
            {{ team.team_name }}
          </option>
        </select>
      </div>
    </div>

    <!--------------------------------------------
    |
    | BENCHMARK GROUP DETAILS
    |
    --------------------------------------------->
    <BenchmarkManagerEditorDetails
      @updateBenchmarkGroupDetails="updateBenchmarkGroupDetails($event)"
      :benchmark-group="benchmarkGroup"
      :key="benchmarkGroup.id"
      :isPublicReadOnly="!isOwner"
      v-if="benchmarkGroup && hasFullAccess"
    />

    <!--------------------------------------------
    |
    | NEW QUESTION
    |
    --------------------------------------------->
    <BenchmarkManagerNewQuestion
      :benchmark-group="benchmarkGroup"
      v-if="isAddQuestionVisible && hasFullAccess"
      @close-new-question="isAddQuestionVisible = false"
      @add-global-question="addGlobalQuestion"
    />

    <!--------------------------------------------
    |
    | PENDING REQUESTS MESSAGE
    |
    --------------------------------------------->
    <MessageBlock
      message-type="warning"
      :title="`You have ${pending.total} pending benchmark requests.`"
      v-if="pending.total && hasFullAccess && isOwner"
    >
      <ul class="benchmark-manager__editor-pending-list">
        <li
          v-for="question in pending.globalQuestions"
          :key="`pending-${question._id.$oid}`"
        >
          <a
            class="benchmark-manager__editor-pending-list-a"
            :href="`#${question._id.$oid}`"
            @click.prevent="scrollToAnchor(question._id.$oid)"
          >
            {{ question.question_title }}
          </a>
        </li>
      </ul>
    </MessageBlock>

    <h2 class="benchmark-manager__editor-questions" v-if="hasFullAccess">
      <VisibleText> Benchmarking Questions </VisibleText>
    </h2>
    <div v-if="hasFullAccess">
      <section
        class="benchmark-manager__editor-question"
        v-for="(question, index) in globalQuestions"
        :key="question._id.$oid"
        :id="question._id.$oid"
      >
        <BenchmarkManagerEditorQuestion
          :question="question"
          :index="index"
          :benchmark-group="benchmarkGroup"
          :isPublicReadOnly="!isOwner"
          :teamMembers="teamMembers"
          @remove-global-question="removeGlobalQuestion"
          @update-global-question="updateGlobalQuestion"
        />
      </section>
    </div>
  </article>
</template>

<script>
// Components
import BenchmarkManagerEditorDetails from "./BenchmarkManagerEditorDetails.vue"
import BenchmarkManagerEditorQuestion from "./BenchmarkManagerEditorQuestion/BenchmarkManagerEditorQuestion.vue"
import BenchmarkManagerNewQuestion from "./BenchmarkManagerEditorNewQuestion.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Helpers
// import { mapActions } from "vuex"
import { ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"
import TeamsService from "@/services/teamsService.js"

// Mixins
import UserMixin from "@/utils/mixins/userMixin"

export default {
  name: "BenchmarkManagerEditor",
  components: {
    BenchmarkManagerEditorDetails,
    BenchmarkManagerNewQuestion,
    BenchmarkManagerEditorQuestion,
    MessageBlock,
    InlineEditTextInput,
    SvgIconDecorative
  },
  mixins: [UserMixin],
  data() {
    return {
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      updatedAccessType: [],
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      benchmarkGroup: new BenchmarkGroup(),
      globalQuestions: null,
      isAddQuestionVisible: false,
      hasAccess: false,
      pending: {
        total: null,
        globalQuestions: []
      },
      selectedTeam: {},
      isLoading: false,
      ownerEmail: "",
      teamMembers: []
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => vm.setData())
  // },

  computed: {
    matchingTeam() {
      if (
        this.benchmarkGroup &&
        this.benchmarkGroup.created_by &&
        this.benchmarkGroup.created_by.organization &&
        this.benchmarkGroup.access_type == "public__team"
      ) {
        if (this.teams && this.teams.length) {
          let filteredTheme = this.teams.filter(
            (team) =>
              team._id.$oid == this.benchmarkGroup.created_by.organization
          )
          if (filteredTheme.length) {
            return filteredTheme[0]
          }
        }
      }
      return false
    },
    isOwner() {
      if (this.profile) {
        let userId = this.profile.django_ref_id
        if (userId && userId == this.benchmarkGroup.owner) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    isPublicReadOnly() {
      let status = false
      if (
        (this.profile &&
          this.benchmarkGroup &&
          this.benchmarkGroup.owner !== this.profile.django_ref_id) ||
        this.benchmarkGroupIsICCS
      ) {
        status = true
      }
      return status
    },
    benchmarkGroupIsICCS() {
      let status = false
      if (
        this.benchmarkGroup &&
        this.benchmarkGroup.official_group &&
        this.benchmarkGroup.official_group.includes("iccs")
      ) {
        status = true
      }

      return status
    },
    hasFullAccess() {
      let status = false
      if (this.hasAccess) {
        status = this.hasAccess
      }
      if (this.benchmarkGroupIsICCS && !this.isICCS) {
        status = false
      }
      return status
    }
  },
  async mounted() {
    // this.setData()
    this.findBenchmarkGroup()
  },

  created() {},
  methods: {
    async findBenchmarkGroup() {
      this.updatedAccessType = ACCESS_TYPE_TO_DESC
      this.isLoading = true
      this.globalQuestions = []
      this.benchmarkGroup = {}
      this.benchmarkGroup = await this.fetchGroup()
      if (this.benchmarkGroup) {
        if (
          this.benchmarkGroup.message &
          (this.benchmarkGroup.message ==
            "You do not have permission to perform this action.")
        ) {
          this.isLoading = false
        } else {
          this.hasAccess = true
          this.globalQuestions = await this.fetchQuestions()
          this.setTotalPending(this.globalQuestions)
          this.findTeamMembers()
          this.isLoading = false
        }
      }
    },
    setToICCS() {
      if (this.isICCSAdmin) {
        if (!this.benchmarkGroup.official_group) {
          this.benchmarkGroup.official_group = []
        }
        this.benchmarkGroup.official_group.push("iccs")
        this.benchmarkGroup.access_type = "public__all"
        this.changeAccessLevel()
      }
    },
    removeFromICCS() {
      let newOfficialGroup = this.benchmarkGroup.official_group.filter(
        (group) => group != "iccs"
      )
      this.benchmarkGroup.official_group = newOfficialGroup
    },
    async getOwnerEmail() {
      const email = await this.$pigeonline.getUserEmail()
      this.ownerEmail = email
    },
    async findTeamMembers() {
      this.teamMembers = await this.TEAMS_SERVICE.getAllTeamMembers({
        include_details: true
      })
    },
    changeAccessLevel() {
      if (this.isOwner == true) {
        if (
          this.benchmarkGroup.access_type == "public__all" ||
          this.benchmarkGroup.access_type == "private"
        ) {
          let full_name = ""
          if (this.profile) {
            if (this.profile.fullname) {
              full_name = this.profile.fullname
            }
          }
          if (full_name) {
            this.benchmarkGroup["created_by"] = {
              name: full_name,
              organization: ""
            }
            this.$pigeonline.projects.update(this.benchmarkGroup)
          }
        }
      }
    },
    changeTeamAccessLevel() {
      if (this.isOwner == true) {
        // if teams, change created_by to include org name
        if (this.benchmarkGroup.access_type == "public__team") {
          let team_id = ""
          if (this.selectedTeam && this.selectedTeam["_id"]) {
            team_id = this.selectedTeam._id.$oid
          }
          let team_name = ""
          if (this.selectedTeam && this.selectedTeam["team_name"]) {
            team_name = this.selectedTeam.team_name
          }
          if (team_id && team_name) {
            this.benchmarkGroup["created_by"] = {
              organization: team_id,
              name: team_name
            }
          }
          this.$pigeonline.projects.update(this.benchmarkGroup)
        }
      }
    },
    updateBenchmarkGroupDetails(benchmarkGroup) {
      this.$pigeonline.projects.update(benchmarkGroup)
    },
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkGroup type.
     */
    async fetchGroup() {
      try {
        const response = await this.$pigeonline.projects.getByID(
          BenchmarkGroup,
          this.$route.params.id
        )
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    /**
     * Fetching and saving global questions
     */
    async fetchQuestions() {
      try {
        const response = await this.DATASETS_SERVICE.globalQuestions(null, {
          benchmark_group: this.$route.params.id
        })
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    saveName(name) {
      this.benchmarkGroup.name = name
      this.$pigeonline.projects.update(this.benchmarkGroup)
    },
    setTotalPending(globalQuestions) {
      this.pending = globalQuestions.reduce(
        (pendingObj, question) => {
          if (question.matches.pending > 0) {
            pendingObj.total += question.matches.pending
            pendingObj.globalQuestions.push(question)
          }
          return pendingObj
        },
        { total: 0, globalQuestions: [] }
      )
    },
    scrollToAnchor(hash) {
      const el = document.getElementById(hash)
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        })
      }
    },
    addGlobalQuestion(newQuestion) {
      this.globalQuestions.unshift(newQuestion)
      this.isAddQuestionVisible = false
    },
    updateGlobalQuestion(index, question) {
      this.globalQuestions.splice(index, 1, question)
    },
    removeGlobalQuestion(index) {
      this.globalQuestions.splice(index, 1)
    },
    async refreshTopicModels() {
      if (this.hasAccess) {
        this.benchmarkGroup = await this.fetchGroup()
      }
    }
  },
  watch: {
    matchingTeam: function (val) {
      if (val) {
        this.selectedTeam = val
      }
    },
    teamMembers: function (val) {
      if (val) {
        let filtered_team_members = val.filter(
          (member) => member.id == this.benchmarkGroup.owner
        )
        if (filtered_team_members.length) {
          this.ownerEmail = filtered_team_members[0].email
        }
      }
    },
    benchmarkGroup: {
      deep: true,
      handler: function (value) {
        if (value && Object.keys(value).length > 0)
          this.$pigeonline.projects.update(value)
      }
    },
    isOwner: function (val) {
      if (val) {
        if (
          !this.benchmarkGroup.created_by ||
          (this.benchmarkGroup.created_by &&
            !this.benchmarkGroup.created_by.name)
        ) {
          this.changeAccessLevel()
        }
        this.getOwnerEmail()
      }
    },
    hasAccess: function (val) {
      if (val) {
        this.refreshTopicModels()
        this.changeAccessLevel()
      }
    }
  }
}
</script>
