<template>
  <article id="custom-text-model-editor" class="section__content">
    <router-link to="/custom-text-model" class="btn-back">
      <SvgIconDecorative icon="arrowLeft" />
      <VisibleText>
        Back <span class="sr-only"> to custom text model groups </span>
      </VisibleText>
    </router-link>
    <div>
      <div class="custom-text-model-editor__header">
        <InlineEditTextInput
          label="topic model group name"
          input-id="topic-model-group-name-input"
          input-name="topic-model-group-name"
          :input-value="topicModelGroup.name"
          @save="saveName"
          v-if="profile && topicModelGroup.owner == profile.django_ref_id"
        >
          <h2>
            {{ topicModelGroup.name }}
          </h2>
        </InlineEditTextInput>
        <h1 v-if="profile && topicModelGroup.owner != profile.django_ref_id">
          {{ topicModelGroup.name }}
        </h1>
      </div>
      <div style="margin-top: -1rem; margin-bottom: 1rem">
        <div v-if="ownerEmail" style="display: flex; flex-direction: row">
          <VisibleText>Created by</VisibleText>:
          <span style="margin-left: 1rem">{{ ownerEmail }}</span>
        </div>
      </div>
      <div
        v-if="
          profile &&
          topicModelGroup.owner !== profile.django_ref_id &&
          hasAccess &&
          topicModelGroup.access_type == 'public__team'
        "
      >
        <h3>
          <VisibleText> Viewing as a team member </VisibleText>
        </h3>
      </div>
      <div v-if="!hasAccess">
        <h3>
          <VisibleText>
            You do not have permission to access this group.
          </VisibleText>
        </h3>
      </div>
      <div>
        <VisibleText>
          This custom text model is set to the following access level:
        </VisibleText>
        <select
          aria-label="access level"
          class="select"
          id="select-access-level"
          @change="changeAccessLevel"
          v-model="topicModelGroup.access_type"
          style="width: 50%; margin-top: 1rem"
          :disabled="profile && topicModelGroup.owner !== profile.django_ref_id"
        >
          <option
            v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
            :key="level"
            :value="level"
            :data-parent="$options.name"
          >
            {{ translate(ACCESS_TYPE_TO_DESC[level].name, $options.name) }} -
            {{ translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name) }}
          </option>
        </select>
        <div
          style="margin-top: 1rem"
          v-if="topicModelGroup.access_type == 'public__team'"
        >
          <VisibleText> For this team: </VisibleText>
          <select
            aria-label="access level"
            class="select"
            id="select-access-level-team"
            style="width: 50%; margin-top: 1rem"
            v-model="selectedTeam"
            @change="changeTeamAccessLevel"
            :disabled="
              profile && topicModelGroup.owner !== profile.django_ref_id
            "
          >
            <option
              v-for="team in teams"
              :key="team.team_name"
              :value="team"
              :data-parent="$options.name"
            >
              {{ team.team_name }}
            </option>
          </select>
        </div>
      </div>

      <!--------------------------------------------
      |
      | TOPIC GROUP DETAILS
      |
      --------------------------------------------->
      <CustomTopicModelEditorDetails
        :topic-model-group="topicModelGroup"
        :isPublicReadOnly="
          !isOwner || !topicModelGroup.access_type == 'public__team'
        "
      />
    </div>

    <!--------------------------------------------
    |
    | TOPIC MODEL OPTIONS
    |
    --------------------------------------------->
    <CustomTopicModelEditorOptions
      :selected-topic-models="selectedTopicModels"
      @add-theme="isAddThemeVisible = true"
      @deleted-themes="deletedThemes"
      @deselect="selectedTopicModels = []"
      @refresh-topic-models="refreshTopicModels"
      @select="selectAll"
      @toggle-editing="readOnlyTable = !readOnlyTable"
      v-if="isOwner || topicModelGroup.access_type == 'public__team'"
    />

    <!--------------------------------------------
    |
    | NEW TOPIC MODEL
    |
    --------------------------------------------->
    <CustomTopicModelEditorNewTheme
      :topic-model-group="topicModelGroup"
      v-if="isAddThemeVisible && hasAccess"
      @close="isAddThemeVisible = false"
      @new-theme-created="newThemeCreated"
    />

    <!--------------------------------------------
    |
    | THEMES GRID
    |
    --------------------------------------------->
    <ListGrid
      :custom-grid-style="{
        gridTemplateColumns: '4rem repeat(3, minmax(auto, 1fr))'
      }"
      :headers="headers"
      :list="topicModels"
      :key="themesGridKey"
    >
      <template #default="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <span class="form-checkbox-label">
            <input
              type="checkbox"
              tabindex="-1"
              :aria-label="item.name"
              :value="item._id.$oid"
              v-model="selectedTopicModels"
            />
          </span>
        </ListGridItem>
        <ListGridItem col="1" :headers="headers" :row="index">
          <ThemeName
            :oid="item._id.$oid"
            :read-only="readOnlyTable"
            :theme-name="item.name"
          />
        </ListGridItem>
        <ListGridItem col="2" :headers="headers" :row="index">
          <Keywords
            :oid="item._id.$oid"
            :keywords="item.keywords"
            :read-only="readOnlyTable"
            :isPublicReadOnly="isPublicReadOnly"
          />
        </ListGridItem>
        <ListGridItem col="3" :headers="headers" :row="index">
          <ExampleQuestions
            :oid="item._id.$oid"
            :example-questions="item.example_questions"
            :read-only="readOnlyTable"
            :isPublicReadOnly="isPublicReadOnly"
          />
        </ListGridItem>
      </template>
    </ListGrid>
  </article>
</template>

<script>
// Components
import CustomTopicModelEditorDetails from "./CustomTopicModelEditorDetails.vue"
import CustomTopicModelEditorNewTheme from "./CustomTopicModelEditorNewTheme.vue"
import CustomTopicModelEditorOptions from "./CustomTopicModelEditorOptions.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import ThemeName from "./Components/ThemeName.vue"
import Keywords from "./Components/Keywords.vue"
import ExampleQuestions from "./Components/ExampleQuestions.vue"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import UserMixin from "@/utils/mixins/userMixin"

// Services
import { fetchGroup, fetchTopicModels } from "../Utils/fetch.js"
import { ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"
import customTopicModelService from "@/services/customTopicModelService.js"
import TeamsService from "@/services/teamsService.js"

export default {
  name: "CustomTopicModelEditor",
  mixins: [ConfirmMixin, UserMixin],
  components: {
    CustomTopicModelEditorDetails,
    CustomTopicModelEditorNewTheme,
    CustomTopicModelEditorOptions,
    InlineEditTextInput,
    ListGrid,
    ListGridItem,
    SvgIconDecorative,
    ThemeName,
    Keywords,
    ExampleQuestions
  },
  data() {
    return {
      fetchingComplete: false,
      headers: ["", "Theme Name", "Keywords", "Example Sentences"],
      isAddThemeVisible: false,
      readOnlyTable: true,
      selectedTopicModels: [],
      topicModels: [], // These are the keywords
      topicModelGroup: {}, // These are the groups of keywords under one banner
      selectedTeam: {},
      themesGridKey: this.$pigeonline.createUUID(),
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      ownerEmail: "",

      hasAccess: false,
      teamMembers: []
    }
  },

  async mounted() {
    this.topicModels = []
    this.topicModelGroup = {}
    const modelId = this.$route.params.id
    await this.checkForGroup(modelId)
  },
  async created() {
    // await this.refreshTopicModels()
  },
  computed: {
    matchingTeam() {
      if (
        this.topicModelGroup &&
        this.topicModelGroup.created_by &&
        this.topicModelGroup.created_by.organization &&
        this.topicModelGroup.access_type == "public__team"
      ) {
        if (this.teams && this.teams.length) {
          let filteredTheme = this.teams.filter(
            (team) =>
              team._id.$oid == this.topicModelGroup.created_by.organization
          )
          if (filteredTheme.length) {
            return filteredTheme[0]
          }
        }
      }
      return false
    },
    isOwner() {
      if (this.profile && this.profile.django_ref_id) {
        if (this.profile.django_ref_id == this.topicModelGroup.owner) {
          return true
        }
      }
      return false
    },
    isPublicReadOnly() {
      let status = false
      if (
        this.topicModelGroup &&
        this.topicModelGroup.access_type == "public__all" &&
        this.isOwner == false
      ) {
        status = true
      }
      return status
    }
  },
  methods: {
    onError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem loading the page. Please try again."
      })
      this.setConfirmSourceComponent("topicModelEditor")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    async checkForGroup(modelId) {
      let fetchedModelGroup = await fetchGroup(modelId)
      if (
        fetchedModelGroup["project_type"] &&
        fetchedModelGroup["project_type"] == "custom_topic_model_group"
      ) {
        this.topicModelGroup = fetchedModelGroup
        this.hasAccess = true
      }
    },
    // async checkIsOwner() {
    //   const userId = await this.$store.getters["user/getProfile"].django_ref_id
    //   if (userId == this.topicModelGroup.owner) {
    //     this.isOwner = true
    //   }
    // },
    async findTeamMembers() {
      this.teamMembers = await this.TEAMS_SERVICE.getAllTeamMembers({
        include_details: true
      })
    },
    changeAccessLevel() {
      if (this.isOwner == true) {
        if (
          this.topicModelGroup.access_type == "public__all" ||
          this.topicModelGroup.access_type == "private"
        ) {
          let full_name = ""
          if (this.profile) {
            if (this.profile.fullname) {
              full_name = this.profile.fullname
            }
          }
          if (full_name) {
            this.topicModelGroup["created_by"] = {
              name: full_name,
              organization: ""
            }
            this.$pigeonline.projects.update(this.topicModelGroup)
          }
        }
      }
    },
    changeTeamAccessLevel() {
      if (this.isOwner == true) {
        // if teams, change created_by to include org name
        if (this.topicModelGroup.access_type == "public__team") {
          let team_id = ""
          if (this.selectedTeam && this.selectedTeam["_id"]) {
            team_id = this.selectedTeam._id.$oid
          }
          let team_name = ""
          if (this.selectedTeam && this.selectedTeam["team_name"]) {
            team_name = this.selectedTeam.team_name
          }
          if (team_id && team_name) {
            this.topicModelGroup["created_by"] = {
              organization: team_id,
              name: team_name
            }
          }
          this.$pigeonline.projects.update(this.topicModelGroup)
        }
      }
    },
    async getOwnerEmail() {
      let email = await this.$pigeonline.getUserEmail()
      this.ownerEmail = email
    },
    newThemeCreated(theme) {
      this.topicModels.unshift(theme)
      this.topicModelGroup.addNewTheme(theme._id.$oid)
      this.themesGridKey = this.$pigeonline.createUUID()
    },
    saveName(name) {
      this.topicModelGroup.name = name
      this.$pigeonline.projects.update(this.topicModelGroup)
    },
    selectAll() {
      this.selectedTopicModels = this.topicModels.map((topic) => topic._id.$oid)
    },
    async deletedThemes() {
      this.selectedTopicModels = []
      this.topicModels = await fetchTopicModels(this.$route.params.id)
      this.topicModelGroup.themes = this.topicModels.map(
        (model) => model._id.$oid
      )
      this.themesGridKey = this.$pigeonline.createUUID()
    },
    async refreshTopicModels() {
      if (this.hasAccess) {
        this.topicModels = await fetchTopicModels(this.$route.params.id)
      }
    }
  },
  watch: {
    confirmIsVisible: function (val) {
      // user clicked "leave page"
      if (
        !val &&
        !this.confirmClickedClose &&
        !this.confirmStatus &&
        this.confirmSourceComponent === "topicModelEditor" &&
        this.confirmType === "warning"
      ) {
        this.$router.push({ name: "projects" })
      }
    },

    hasAccess: function (val) {
      if (val) {
        this.refreshTopicModels()
        this.changeAccessLevel()
      }
    },
    isOwner: function (val) {
      if (val) {
        this.getOwnerEmail()
      }
    },
    matchingTeam: function (val) {
      if (val) {
        this.selectedTeam = val
        // Get all team members (one way to get owner email)
        this.findTeamMembers()
      }
    },
    teamMembers: function (val) {
      if (val) {
        let filtered_team_members = val.filter(
          (member) => member.id == this.topicModelGroup.owner
        )
        if (filtered_team_members.length) {
          this.ownerEmail = filtered_team_members[0].email
        }
      }
    },
    topicModelGroup: {
      deep: true,
      handler: function (value) {
        if (value && Object.keys(value).length > 0)
          this.$pigeonline.projects.update(value)
      }
    }
  }
}
</script>
