import DriversAnalysisRequest from "../Models/DriversAnalysisRequest"

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      dependentVariableTitle: "Overall Satisfaction",
      chartData: null,
      matrixNode: null,
      isLoadingChartData: false
    }
  },
  methods: {
    getVariableTitle(questionID, isComposite) {
      const clientQs = this.$store.getters["datasetDetails/getClientQuestions"]
      let question = clientQs.find((q) => q._id.$oid === questionID)
      // return concat of question title and quesiton text
      const questionText =
        question.question_text.length > 0 ? `- ${question.question_text}` : ""

      // for composite index, only return the titles
      return isComposite
        ? `${question.question_title}`
        : `${question.question_title} ${questionText}`
    },
    $_driversAnalysisMixin_getDependentVarTitle(questionIDs) {
      const isComposite = Array.isArray(questionIDs) && questionIDs.length > 1
      let title = new String()
      if (isComposite) {
        title = title.concat("Composite Index: ")
        questionIDs.forEach((id, index) => {
          let trailing =
            isComposite && index !== questionIDs.length - 1 ? " & " : " " // trailing string to append
          title = title.concat(this.getVariableTitle(id, isComposite), trailing)
        })
      } else {
        title = title.concat(
          this.getVariableTitle(questionIDs[0], isComposite),
          ""
        )
      }
      return title
    },
    async $_driversAnalysisMixin_fetchChartData(
      projectID,
      datasetID,
      dependentIDs,
      independentIDs,
      algorithm,
      filter_params
    ) {
      if (!filter_params) {
        filter_params = {}
      }
      // create wrapper class to help create the proper request body
      const drivers = new DriversAnalysisRequest({
        projectID: projectID,
        datasetID: datasetID,
        dependentIDs: dependentIDs,
        independentIDs: independentIDs,
        algorithm: algorithm,
        filter_params: filter_params
      })

      //generate composite-index driver
      const body =
        Array.isArray(dependentIDs) && dependentIDs.length > 1
          ? drivers.getCompositeIndexDriversBody()
          : drivers.getSingleDriversBody()

      try {
        const response =
          await this.$services.DRIVERS_SERVICE.driversAnalysis(body)
        response.data.filter_params = filter_params
        return response.data
      } catch (e) {
        if (e) {
          return { err: true, errObj: e }
        } else {
          throw new Error("ProjectAnalysisDrivers:fetchChartData " + e)
        }
      }
    }
  }
}
