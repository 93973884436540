import filters from "@/utils/filters.js"

const date = () => {
  return {
    type: "text",
    content: filters.formatDate(Date.now()),
    id: "header-date",
    meta: {
      pdfConfig: {
        style: "header-date"
      }
    }
  }
}

const datasetName = (name) => {
  return {
    type: "text",
    content: name,
    id: "header-dataset-name",
    meta: {
      pdfConfig: {
        style: "header-dataset-name"
      }
    }
  }
}

const orgName = (org) => {
  return {
    type: "text",
    content: org,
    id: "header-org",
    meta: {
      pdfConfig: {
        style: "header-org"
      }
    }
  }
}

export { date, datasetName, orgName }
