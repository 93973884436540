// import Vuex from "vuex"
import { createStore } from "vuex"

/**
 * import all modules
 * if you want to register a new module import it in ./modules/modules.store.js
 */
import modules from "./modules/modulesStore.js"

// Vue2
// export default new Vuex.Store({
//   modules
// })

// Vue3
export default createStore({
  modules
})
