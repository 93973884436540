// Models
import { PigeonDocCollectionModel, PigeonDocModel } from "@pigeonline/pigeondoc"

// mixins
import UserMixin from "@/utils/mixins/userMixin.js"

// Helpers
import { mapActions } from "vuex"
import TeamsService from "@/services/teamsService.js"

export default {
  data() {
    return {
      isMounted: false,
      currViewIdx: 0,
      unsavedShareChanges: false,
      users: [],
      team_members: [],
      resetListener: false,
      shareStatus: null,
      TEAMS_SERVICE: new TeamsService(this.$pigeonline)
    }
  },
  mixins: [UserMixin],
  computed: {
    modalGroups() {
      if (!this.isMounted) return
      return this.$refs["share-modal"].$el.getElementsByClassName("modal-group")
    },
    usersShared() {
      return this.users
        .filter((user) => user.type == "shared" || user.type == "selected")
        .sort((a, b) => new String(a.label).localeCompare(new String(b.label)))
    },
    DEFAULT_PERMS() {
      return {
        project: ["read"],
        dataset: ["read"]
      }
    },
    DEFAULT_PERMS_LABELS() {
      return {
        project: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ],
        dataset: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ]
      }
    },
    // dataset() {
    //   return this.datasetProject
    // },
    SHARING_KEYS() {
      return Object.keys(this.DEFAULT_PERMS).filter((key) => this.IS_OWNER[key])
    },
    SHARING_PK() {
      return "project" // modify this to `dataset` if it's the main project being shared
    },
    PRIMARY_SHARED() {
      let key = this.SHARING_PK
      if (key == "dataset") {
        key = "localDataset"
      }
      return (this[key] && this[key].shared) || []
    },
    IS_OWNER() {
      let obj = {}
      Object.keys(this.DEFAULT_PERMS).map(
        function (key) {
          let newKey = key
          if (key == "dataset") {
            newKey = "localDataset"
          }
          if (!this[newKey]) return
          obj[key] =
            this.profile && this.profile.django_ref_id === this[newKey].owner
        }.bind(this)
      )
      return obj
    },
    isSaving() {
      return this.shareStatus === "saving"
    },
    isSaveBtnDisabled() {
      return !this.unsavedShareChanges || this.isSaving
    },
    shareUrl() {
      const domain = `${window.location.protocol}//${window.location.hostname}`
      const port = window.location.port ? `:${window.location.port}` : ""
      const path = this.$route.path
      return `${domain}${port}${path}`
    }
  },
  methods: {
    ...mapActions("project", ["setProject"]),
    ...mapActions("datasetWizard", ["setDatasetProject"]),
    async mount() {
      this.isMounted = true
      this.users = await this.fetchUsers()
      this.goToView(0)
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r)
        ? r
        : typeof r.pk !== "undefined"
          ? r.pk
          : typeof r.id !== "undefined"
            ? r.id
            : -1
    },
    find(user, arr) {
      if (!arr) return []
      return arr.filter((r) =>
        [user.id, user.email].includes(this.bkCompatiblePk(r))
      )[0]
    },
    sharingPerms(user) {
      let perms = { ...this.DEFAULT_PERMS }
      this.SHARING_KEYS.map(
        function (key) {
          if (!this[key]) return
          if (this.find(user, this[key].shared)) {
            perms[key] = this.find(user, this[key].shared).perms || perms[key]
          }
        }.bind(this)
      )
      return perms
    },
    async fetchUsers() {
      try {
        let response = await this.TEAMS_SERVICE.getAllTeamMembers({
          include_details: true
        })
        let existingUsers = []
        let internalUsers = response.reduce((users, user) => {
          if (!existingUsers.includes(user.email)) {
            existingUsers.push(user.email)
            let temp = {}
            temp["id"] = user.id
            temp["label"] =
              !user.fullname || user.fullname == "" ? user.email : user.fullname
            temp["value"] = user.email
            temp["type"] = this.find(user, this.PRIMARY_SHARED)
              ? "shared"
              : "suggested"
            temp["permissions"] = this.sharingPerms(user)
            users.push(temp)
          }

          return users
        }, [])
        return internalUsers
          .concat(
            this.PRIMARY_SHARED.filter(
              (r) =>
                !this.find(
                  { id: this.bkCompatiblePk(r), email: this.bkCompatiblePk(r) },
                  internalUsers
                )
            ).map((row) => {
              return {
                id: this.bkCompatiblePk(row),
                label: this.bkCompatiblePk(row),
                value: this.bkCompatiblePk(row),
                type: "shared",
                permissions: this.sharingPerms({ id: row.pk, email: row.pk })
              }
            })
          )
          .sort((a, b) =>
            new String(a.label).localeCompare(new String(b.label))
          )
      } catch (e) {
        throw e
      }
    },
    addNewUser(user) {
      user["permissions"] = this.DEFAULT_PERMS
      this.users = [...this.users, user]
    },
    copy() {
      // copy project url to clipboard
      const copyText = document.getElementById("share-url")
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy")

      this.$refs["copy-url"].textContent = "Successfully copied!"
    },
    closeModal() {
      this.$emit("closeModal")
    },
    async saveChanges(reloadOnSaveSuccess = false) {
      let clonedProject = {}
      if (this.project) {
        clonedProject = this.deepCloneObj(this.project)
        clonedProject.id = this.project.id
        if (clonedProject._id) {
          delete clonedProject._id
        }
      }

      const users = [...this.usersShared]
      const sharingTypes = [...this.SHARING_KEYS]
      const shared = {}
      sharingTypes.map(function (type) {
        shared[type] = []
      })
      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        for (const [type, permissions] of Object.entries(user.permissions)) {
          shared[type] &&
            shared[type].push([user.id, user.notify || false, permissions])
        }
      }
      if (Object.keys(shared).length === 0) return

      this.shareStatus = "saving"
      for (const [type, value] of Object.entries(shared)) {
        let _shared = this[type] && this[type].shared
        let dataset = {}
        if (this.dataset) {
          dataset = this.dataset
        }
        if (this.parentDataset) {
          dataset = this.parentDataset
        }

        try {
          switch (type) {
            case "dataset":
              dataset.shared = value
              try {
                await this.setDatasetProject(
                  await this.$pigeonline.projects.update(dataset)
                )
              } catch (e) {
                if (_shared) dataset.shared = _shared
                throw new Error(e)
              }
              break
            case "project":
              clonedProject.shared = value

              try {
                await this.setProject(
                  await this.$pigeonline.projects.update(clonedProject)
                )

                // save shared value to project report as well
                if (clonedProject.report) {
                  const report = await this.$pigeonline.projects.getByID(
                    PigeonDocModel,
                    clonedProject.report
                  )
                  report.shared = value
                  await this.$pigeonline.projects.update(report)
                }
                // and collection
                if (clonedProject.collection_id) {
                  const collection = await this.$pigeonline.projects.getByID(
                    PigeonDocCollectionModel,
                    clonedProject.collection_id
                  )
                  collection.shared = value
                  await this.$pigeonline.projects.update(collection)
                }
              } catch (e) {
                if (_shared) clonedProject.shared = _shared
                throw new Error(e)
              }
              break
            default:
              continue
          }
        } catch (e) {
          this.shareStatus = "error"
          throw new Error("shareMixin.js:saveChanges:: " + e)
        }
      }
      if (this.shareStatus === "error") {
        this.$refs["share-modal"].showMessage(
          "error",
          "There was an error processing your request. Please try again later."
        )
      }
      if (this.shareStatus === "saving") {
        this.$refs["share-modal"].showMessage(
          "success",
          "Your changes have been saved successfully."
        )
        // reset share status
        this.shareStatus = null
        this.unsavedShareChanges = false

        // reload page
        if (reloadOnSaveSuccess) window.location.reload()
      }
    },

    // Go to view
    goToView(index) {
      if (index >= this.modalGroups.length || index < 0) return
      if (index > 0) {
        this.modalGroups[index - 1].style.display = "none"
        this.$nextTick(() => this.modalGroups[index].focus())
      }
      if (index < this.modalGroups.length - 1)
        this.modalGroups[index + 1].style.display = "none"
      this.modalGroups[index].style.display = "grid"
      this.modalGroups[index].parentElement.scrollTop = 0
      this.currViewIdx = index
      this.resetListener = !this.resetListener
    },
    back() {
      this.goToView(this.currViewIdx - 1)
    }
  }
}
