<template>
  <div
    :class="['theme__popup', { show: showDialog }]"
    v-click-outside="forceClose"
  >
    <div style="display: flex; flex-direction: row">
      <button
        type="button"
        style="margin-left: 0.25rem; display: flex"
        class="open-btn"
        @click="onClickOpen"
      >
        <VisibleText>source</VisibleText>
      </button>
      <DialogPopup
        class="theme__popup-dialog"
        header-tag="h4"
        :show="showDialog"
        @close="showDialog = false"
      >
        <template #header>
          <div
            style="
              display: flex;
              flex-direction: row;
              text-align: left;
              font-size: 1rem;
              text-transform: none;
            "
          >
            Source for the theme:
          </div>
          <span
            class="theme__popup-dialog-name"
            style="
              font-family: Core-Sans;
              text-align: left;
              font-size: 1rem;
              text-transform: none;
              margin-left: 0.5rem;
              text-decoration: none;
            "
            >&nbsp;{{ themeName }}</span
          >
        </template>
        <template #body>
          <div
            style="
              text-align: left;
              display: flex;
              flex-direction: column;
              text-transform: none;
              padding-left: 0.5rem;
            "
          >
            <div class="theme-source__content">
              <div style="display: flex; flex-direction: row">
                <div>
                  <div v-if="themeSource">
                    <div v-if="themeSource.source_type === 'lda'">
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="statisticsGraph"
                      />
                    </div>
                    <div v-if="themeSource.source_type === 'lsi'">
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="statisticsGraph"
                      />
                    </div>
                    <div v-if="themeSource.source_type === 'bert_sentence'">
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="brain"
                      />
                    </div>
                    <div
                      v-if="
                        themeSource &&
                        themeSource.hasOwnProperty('source') &&
                        themeSource.source.hasOwnProperty(
                          'topic_model_group_id'
                        )
                      "
                    >
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="customWrench"
                      />
                    </div>
                    <div v-if="themeSource && themeSource.manually_linked">
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="customWrench"
                      />
                    </div>
                    <div
                      v-if="!themeSource || !Object.keys(themeSource).length"
                    >
                      <SvgIconDecorative
                        style="margin-top: -0.4rem"
                        icon="add"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <SvgIconDecorative style="margin-top: -0.4rem" icon="add" />
                  </div>
                </div>
                <div>
                  <div
                    v-if="
                      !themeSource ||
                      (!themeSource.source &&
                        !themeSource.source_type &&
                        !themeSource.manually_linked)
                    "
                  >
                    <VisibleText style="font-weight: 200"
                      >This theme was created manually</VisibleText
                    >
                  </div>
                  <div v-if="themeSource && themeSource.manually_linked">
                    <VisibleText style="font-weight: 200">
                      This theme has been linked to the Custom Text Model:
                    </VisibleText>
                    <router-link
                      :to="'/custom-text-model/' + themeSource.manually_linked"
                      target="_blank"
                    >
                      {{ themeSource.manually_linked_model_name }}
                      <SvgIconDecorative
                        style="margin-left: -0.6rem; margin-top: -0.3rem"
                        icon="externalLink"
                      />
                    </router-link>
                  </div>
                  <div v-if="themeSource && themeSource.source_type">
                    <div v-if="themeSource.source_type === 'lda'">
                      <VisibleText style="font-weight: 200">
                        This theme was inspired by a cluster of suggested
                        keywords through the use of
                      </VisibleText>
                      <a
                        href="https://en.wikipedia.org/wiki/Latent_Dirichlet_allocation"
                        target="_blank"
                      >
                        LDA
                      </a>
                    </div>
                    <div
                      v-if="themeSource && themeSource.source_type === 'lsi'"
                    >
                      <VisibleText>
                        This theme was inspired by a cluster of suggested
                        keywords through the use of
                      </VisibleText>
                      <a
                        href="https://en.wikipedia.org/wiki/Latent_semantic_analysis#Latent_semantic_indexing"
                        target="_blank"
                        >LSI
                        <SvgIconDecorative
                          style="margin-left: -0.6rem; margin-top: -0.3rem"
                          icon="externalLink"
                        />
                      </a>
                    </div>
                    <div
                      v-if="
                        themeSource &&
                        themeSource.source_type === 'bert_sentence'
                      "
                    >
                      <VisibleText style="font-weight: 200">
                        This theme was created using our privacy-based,
                        self-hosted Generative AI: a combination of BERT and
                        sentence tokenization.
                      </VisibleText>
                    </div>
                  </div>
                  <div
                    v-if="themeSource && themeSource.hasOwnProperty('source')"
                  >
                    <div
                      v-if="
                        themeSource.source.hasOwnProperty(
                          'topic_model_group_id'
                        )
                      "
                    >
                      <VisibleText style="font-weight: 200">
                        This theme was found by matching keywords to a custom
                        dictionary:
                      </VisibleText>
                      <router-link
                        :to="
                          '/custom-text-model/' +
                          themeSource.source.topic_model_group_id
                        "
                        target="_blank"
                      >
                        {{ themeSource.source.topic_model_group_name }}
                        <SvgIconDecorative
                          style="margin-left: -0.6rem; margin-top: -0.3rem"
                          icon="externalLink"
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DialogPopup>
    </div>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "SourcePopup",
  components: {
    DialogPopup,
    SvgIconDecorative
  },
  props: {
    themeName: {
      default: () => "theme",
      type: String
    },
    themeSource: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  created() {},
  methods: {
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      this.showDialog = true
    }
  }
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 32rem;
  width: calc(100% * 3);
}
</style>
