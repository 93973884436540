import {
  HELPER_TRANSLATIONS,
  LABEL_TRANSLATIONS
} from "../../../translations.js"
import findSegmentTranslation from "../../findSegmentTranslation.js"

const RANK_TO_COLOUR = {
  A: "#BDD8B8",
  B: "#fff0cf",
  C: "#e6b2a9"
}
const RANK_TO_INFO = {
  A: "your average ranks in the top third of all averages.",
  B: "your average ranks in the middle third of all averages.",
  C: "your average ranks in the bottom third of all averages."
}

/**
 * Format values of the summary tables
 * @param {Number} value the value of a column in the summary table
 * @returns {Number} value formatted to 2 decimal places or
 *  "N/A if value is NaN
 */
const formatValue = (value) => {
  if (isNaN(value) || !value) {
    return "N/A"
  } else {
    return roundToTwo(parseFloat(value))
  }
}

/**
 * Replace spaces of a word with dashes
 * @param {String} word to modify
 * @returns {String} string where all spaces are replaced with dashes
 */
const replaceSpaceWithDash = (word) => {
  return word.replace(/\s/g, "-")
}

/**************************************
 * BENCHMARK SUMMARY SECTION HELPERS
 **************************************/

/**
 * Format benchmarking summary table data to a pigeondoc table node
 * @param {Array} summaryResultsTable from ProjectAnalysisBenchmarking, it is the
 * data from summary_table filed of benchmarking/summary_results API
 * @param {*} summaryTableColNames list of column names to include in the table
 * @param {*} lang current report language
 * @returns summary table data formatted as pigeondoc table data
 */
const formatBenchmarkSummaryTableData = (
  summaryResultsTable,
  summaryTableColNames,
  lang = "en"
) => {
  const rowNames = summaryResultsTable.map((item) => item["question"])

  const formattedTableData = summaryResultsTable.reduce((array, item) => {
    let row = []
    summaryTableColNames.forEach((col) => {
      if (col === "question") return
      else row.push(item[col])
    })
    array.push(row)
    return array
  }, [])

  const capitalizeColNames = summaryTableColNames.reduce(
    (capitalized, colName) => {
      let col = colName.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
      col = lang != "en" ? findSegmentTranslation(colName) : col
      capitalized.push(col)
      return capitalized
    },
    []
  )

  return {
    columnNames: capitalizeColNames,
    rowNames: rowNames,
    data: formattedTableData
  }
}

/**************************************
 * QUESTION BY QUESTION SECTION HELPERS
 **************************************/

/**
 * Create random organization id for each organization. This is to allow
 * for anonymity in the data.
 * @param {*} detailedResults response object from
 * /benchmarking/detailed_results API endpoint
 * @param {*} lang current report language
 * @returns {Object} object that maps the dataset ids to the randomly generated
 * organization id
 */
const createRandomOrgIds = (detailedResults, lang) => {
  const randomOrgLabel = (orgPrefix) => {
    return `${orgPrefix} (q${Math.floor(Math.random() * (20 - 1)) + 1})`
  }

  const idStore = {}
  let orgNo = 1
  detailedResults.forEach((question) => {
    let idToIndxStore = new Map()

    question.average_values.forEach((org, indx) => {
      if (org.is_user) {
        org.org_id = LABEL_TRANSLATIONS.yourOrg[lang]
      } else if (idToIndxStore.has(org.data_set_id)) {
        /**
         * organization already exists, update the org name to include
         * a generic question marker (random number)
         **/

        // update first occurance of dataset
        let firstOccurance =
          question.average_values[idToIndxStore.get(org.data_set_id)[0]].org_id
        if (!firstOccurance.search(/(\(q)*/g)) {
          question.average_values[
            idToIndxStore.get(org.data_set_id)[0]
          ].org_id = randomOrgLabel(idStore[org.data_set_id])
        }

        // set this orgs details and add index to index array
        org.org_id = randomOrgLabel(idStore[org.data_set_id])
        idToIndxStore.get(org.data_set_id).push(indx)
      } else {
        idStore[org.data_set_id] = LABEL_TRANSLATIONS.org[lang] + orgNo
        idToIndxStore.set(org.data_set_id, [indx])
        org.org_id = idStore[org.data_set_id]
        orgNo++
      }
    })

    idToIndxStore.clear()
  })
  return idStore
}

/**
 * Format benchmark group table data
 * @param {Array} unformatted benchmark group objects
 * @param {String} group name of benchmark group, null if its for "all group" section
 * @param {Boolean} withid include "id" column in table row
 * @param {String} rank of "your org". possible values are [A, B, C]
 * @returns {Array} of formatted benchmark group table data
 */
const formatBenchmarkGroupData = (
  unformatted,
  group = null,
  withid = null,
  rank = null
) => {
  return unformatted.map((dataset) => {
    let row = []
    let allSegments = dataset.all_segments
      .map((s) => findSegmentTranslation(s.toLowerCase()))
      .join(", ")
    let item = group ? dataset.segments_mean[group] : dataset

    // add style & info to ranked rows
    if (rank && dataset.is_user) {
      row = [
        {
          value: formatValue(item.mean),
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        {
          value: item.rank,
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        {
          value: formatValue(item.percentile),
          style: { backgroundColor: RANK_TO_COLOUR[rank] }
        },
        allSegments
      ]
      if (withid) {
        row.unshift({
          value: dataset.org_id,
          style: {
            backgroundColor: RANK_TO_COLOUR[rank],
            textDecoration: "underline wavy"
          },
          info: RANK_TO_INFO[rank]
        })
      }
    }
    // add non-ranked rows
    else {
      row = [
        formatValue(item.mean),
        item.rank,
        formatValue(item.percentile),
        allSegments
      ]
      if (withid) row.unshift(dataset.org_id)
    }
    return row
  })
}

/**
 * Generate the "top and lowest performers" summary table for a benchmark group
 * @param {Array} benchmarkGroupData objects
 * @param {String} group name of benchmark group, null if for "all group" section
 * @returns {Object} Pigeondoc formatted table object
 */
const getTopPerformersTable = (benchmarkGroupData, group = null, lang) => {
  let performersData = [],
    lowest = null,
    org = null,
    rowNames = [],
    tableData = []

  if (benchmarkGroupData.length <= 3) {
    performersData = formatBenchmarkGroupData(
      benchmarkGroupData,
      group,
      true,
      "A"
    )
    tableData.push(performersData)
    rowNames = [LABEL_TRANSLATIONS.topPerformers[lang]]
  }

  if (benchmarkGroupData.length > 3) {
    let indx = benchmarkGroupData.findIndex((data) => data.is_user)

    // org is in top 3
    if (indx < 3) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true,
        "A"
      )
      tableData.push(performersData)
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.lowestPerformers[lang]
      ]
    }
    // org is lowest, add top 3
    else if (indx === benchmarkGroupData.length - 1) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      tableData.push(performersData)
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.yourOrg[lang]
      ]
    }
    // org is somewhere in the middle
    else {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      org = formatBenchmarkGroupData(
        [benchmarkGroupData.find((data) => data.is_user)],
        group,
        true,
        "B"
      )
      tableData.push(performersData)
      tableData.push(org[0])
      rowNames = [
        LABEL_TRANSLATIONS.topPerformers[lang],
        LABEL_TRANSLATIONS.yourOrg[lang],
        LABEL_TRANSLATIONS.lowestPerformers[lang]
      ]
    }

    // add the lowest performer
    lowest = formatBenchmarkGroupData(
      [benchmarkGroupData[benchmarkGroupData.length - 1]],
      group,
      true,
      "C"
    )
    tableData.push(lowest[0])
  }

  let performersTable = {
    type: "table",
    content: {
      data: {
        columnNames: HELPER_TRANSLATIONS.qByQTopPerfsCols[lang],
        rowNames: rowNames,
        data: tableData
      }
    },
    id: `b-group-perf-table-${group ? replaceSpaceWithDash(group) : "all"}`,
    meta: {
      caption: HELPER_TRANSLATIONS.qByQTopPerfCaption[lang],
      lang: lang,
      state: "visible"
    }
  }

  return performersTable
}

/**
 * Format values of response distribution table for a client question
 * @param {Object} uniqueVals response values of client questions
 * @param {String} questionHeadingFormatted global match title formatted so that all
 * spaces are replaced with dashes
 * @param {String} lang the language we want to use for this node
 * @returns response distribution table
 */
const getResponseDistributionTable = (
  uniqueVals,
  questionHeadingFormatted,
  lang
) => {
  const total = Object.values(uniqueVals).reduce((total, c) => total + c)
  const sortedData = Object.entries(uniqueVals)
    .sort(([v1], [v2]) => +v2 - +v1)
    .reduce((sorted, [response, value]) => {
      const percent = formatValue((100 * value) / total)
      const row = []
      row.push(value)
      row.push(`${percent} %`)
      sorted.set(response, row)
      return sorted
    }, new Map())
  sortedData.set("Total", [total])
  const tableData = {
    rowNames: [...sortedData.keys()],
    data: [...sortedData.values()]
  }
  const responseDistributionTable = {
    type: "table",
    content: {
      data: {
        columnNames: HELPER_TRANSLATIONS.qByQResponseTableCols[lang],
        rowNames: tableData.rowNames,
        data: tableData.data
      }
    },
    meta: {
      caption: "Response Distribution",
      lang: lang,
      pdfConfig: {
        size: "width-50",
        widths: ["auto", "auto", 200]
      }
    },
    id: `q-group-table-${questionHeadingFormatted}`,
    layout: "document-node--width-50"
  }
  return responseDistributionTable
}

/**
 * Format values of response distribution for the chart
 * @param {Object} uniqueVals response values of client question
 * @param {String} questionHeadingFormatted global match title formatted so that all
 * spaces are replaced with dashes
 * @param {String} lang the language we want to use for this node
 * @returns response distribution chart
 */
const getResponseDistributionChart = (
  uniqueVals,
  questionHeadingFormatted,
  lang
) => {
  const sortedData = Object.keys(uniqueVals).sort((a, b) => b - a)
  const chartData = sortedData.reduce((data, response) => {
    let obj = {}
    obj["label"] = response // response (1 | 2 | 3 etc.)
    obj["value"] = uniqueVals[response] // count
    data.push(obj)
    return data
  }, [])
  const responseDistributionChart = {
    type: "horizontalBarChart",
    content: {
      title: `<span class='pdf-hidden-text sr-only'>${HELPER_TRANSLATIONS.qByQResponseSummary[lang]}</span>`,
      data: chartData
    },
    meta: {
      headingLevel: "span",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: LABEL_TRANSLATIONS.count[lang],
        y: LABEL_TRANSLATIONS.response[lang]
      },
      pdfConfig: {
        chartWidth: 360,
        size: "width-50"
      }
    },
    id: `q-group-chart-${questionHeadingFormatted}`,
    layout: "document-node--width-50"
  }
  return responseDistributionChart
}

/**
 * Format methodology data to pigeondoc table node
 * @param {Object} methodologyData data_set_meta_data field from
 * benchmarking/detailed_results API
 * @param {Array} orgs list of unique organization ids
 * @param {String} lang current report language
 * @returns {Object} pigeondoc table data
 */
const formatMethodologyTableData = (methodologyData, orgs, lang) => {
  // because we want it in a specific order, explicity list columns
  const columnNames = ["ID", "Date Collected", "Sample Size", "Response Rate"]
  let rowNames = []

  // reduce to object readable by pigeondoc data node
  let tableData = methodologyData.reduce((tableDataRows, item) => {
    let row = []
    columnNames.forEach((col) => {
      if (col === "ID") {
        rowNames.push(orgs[item.data_set_id] || "Your org")
      } else if (col === "Date Collected") {
        row.push(item.date_collected || "n/a")
      } else if (col === "Sample Size") {
        row.push(item["average_valid_sample_size"])
      } else if (col === "Response Rate") {
        row.push(item.response_rate || "n/a")
      }
    })
    tableDataRows.push(row)
    return tableDataRows
  }, [])

  return {
    columnNames: HELPER_TRANSLATIONS.methodologyCols[lang],
    rowNames: rowNames,
    data: tableData
  }
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}

export {
  formatValue,
  replaceSpaceWithDash,
  createRandomOrgIds,
  formatBenchmarkSummaryTableData,
  formatBenchmarkGroupData,
  formatMethodologyTableData,
  getResponseDistributionTable,
  getResponseDistributionChart,
  getTopPerformersTable
}
