<template>
  <article id="project-analysis-performance" class="performance">
    <!--------------------------------------------
    |
    | HEADER
    |
    --------------------------------------------->
    <div
      class="project-analysis__header"
      style="justify-content: space-between"
      :inert="modalOpen"
      :aria-hidden="modalOpen"
    >
      <h2 class="project-analysis__header-title">
        <VisibleText>Performance Analysis</VisibleText>
      </h2>
      <div
        v-if="hasScaleQuestions"
        style="display: flex; justify-content: flex-end"
      >
        <button
          class="project-analysis__header-add-btn"
          style="margin: 0 1em"
          v-if="
            !this.project.performanceAnalysis ||
            !this.project.performanceAnalysis.length
          "
          :disabled="propReadOnly"
          @click="findSuggestedVariables()"
        >
          <VisibleText>Auto-suggest analysis</VisibleText>
        </button>
        <button
          class="project-analysis__header-add-btn"
          @click="showCreateModal"
          :disabled="!isClientQuestionsLoaded || propReadOnly"
        >
          <VisibleText>Add New Performance Analysis</VisibleText>
        </button>
      </div>
    </div>

    <!--------------------------------------------
    |
    | DESCRIPTIONS
    |
    --------------------------------------------->
    <p class="performance__desc" :inert="modalOpen" :aria-hidden="modalOpen">
      <VisibleText
        >Select a range of scale questions to chart how each question performs.
      </VisibleText>
    </p>

    <!--------------------------------------------
    |
    | WARNINGS AND INFO MESSAGES
    |
    --------------------------------------------->
    <PerformanceMessages :status-name="statusName" />

    <!--------------------------------------------
    |
    | PERFROMANCE ANALYSIS
    |
    --------------------------------------------->
    <ProjectAnalysisPerformanceItem
      v-for="(outlier, index) in project.performanceAnalysis"
      :key="outlier.uuid"
      :index="index"
      :retrievedSummaryTable="retrievedSummaryTable"
      @refresh="$emit('refresh')"
      @updateSlides="$emit('updateSlides')"
      :propReadOnly="propReadOnly"
    />

    <!--------------------------------------------
    |
    | MODALS
    |
    --------------------------------------------->
    <CreateNewAnalysisModal
      :show="isCreateModalVisible"
      :data="clientQuestionsPreprocessed"
      @confirmSelection="createNewPerformance"
      @close-modal="closeModal"
    />
  </article>
</template>

<script>
// Components

import PerformanceMessages from "./Components/PerformanceMessages.vue"
import CreateNewAnalysisModal from "./Modals/CreateNewAnalysis.vue"
import ProjectAnalysisPerformanceItem from "./ProjectAnalysisPerformanceItem.vue"

// Helpers
import moment from "moment"

// Mixins
// check that all are used
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

import PerformanceAnalysisMixin from "./Mixins/performanceAnalysisMixin.js"

export default {
  name: "ProjectAnalysisPerformance",
  mixins: [
    PerformanceAnalysisMixin,
    ProjectMixin,
    DatasetDetailsMixin,
    ProjectReportMixin
  ],
  components: {
    CreateNewAnalysisModal,
    ProjectAnalysisPerformanceItem,
    PerformanceMessages
  },
  props: {
    hasScaleQuestions: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      clientQuestionsPreprocessed: [],
      error: false,
      isCreateModalVisible: false,
      isClientQuestionsLoaded: false,
      retrievedSummaryTable: [],
      findingSuggestedVariables: false,
      suggestedVariables: []
    }
  },
  computed: {
    statusName() {
      if (!this.hasScaleQuestions) {
        return "hasNoScale"
      }
      return ""
    }
  },
  methods: {
    async findSuggestedVariables() {
      this.findingSuggestedVariables = true

      this.suggestedVariables = await this.fetchSuggestedVariables()
      if (this.suggestedVariables.length) {
        this.useSuggestedVariables()
      }
      this.findingSuggestedVariables = false
    },
    fetchSuggestedVariables() {
      let foundVars = []
      if (this.clientQuestions && this.clientQuestions.length) {
        for (let i = 0; i < this.clientQuestions.length; i++) {
          if (
            this.clientQuestions[i].approved_data_type &&
            this.clientQuestions[i].approved_data_type.includes("SCALE")
          ) {
            foundVars.push(this.clientQuestions[i])
          }
        }
      }
      return foundVars.map((el) => [el._id.$oid, el.question_title])
    },
    useSuggestedVariables() {
      this.createNewPerformance(this.suggestedVariables)
    },
    generateRandomId() {
      return this.$pigeonline.createUUID()
    },

    /* Modal methods */
    showCreateModal() {
      this.isCreateModalVisible = true
    },
    closeModal() {
      this.isCreateModalVisible = false
    },
    preprocessClientQuestions() {
      return this.deepCloneObj(this.clientQuestions).map(
        function (el) {
          el.id = el._id.$oid
          if (el.options) {
            el.options = {
              selected: false,
              disabled: false
            }
          }
          return el
        }.bind(this)
      )
    },

    async createNewPerformance(selectedQuestions, filterParam, segmentParam) {
      if (this.project) {
        if (this.project.datasets) {
          if (this.project.datasets.length) {
            // this.project.performanceAnalysis.unshift(performanceObject)
            this.generatePerformanceAnalysis(
              selectedQuestions,
              filterParam,
              segmentParam
            )
          }
        }
      }
    },
    async generatePerformanceAnalysis(
      selectedQuestions,
      filterParam,
      segmentParam
    ) {
      this.closeModal()
      this.error = false
      try {
        // add new performance placeholder
        // isLongWait shows "analysis may take a minute" warning
        // true if exceed THRESHOLD or if APi takes more than 30 seconds

        let placeholder = {
          uuid: this.$pigeonline.createUUID(),
          last_modified_on: moment().valueOf(),
          filter_params: filterParam || {},
          selectedQuestions: selectedQuestions,
          isCancelled: false,
          isFetching: true,
          isLongWait: false,
          sortedBy: "Original",
          chartType: "HorizontalBarChart",
          summary_table: [],
          threshold: -1
        }
        let running = true

        // show the empty loading wrapper
        this.project.performanceAnalysis.unshift(placeholder)

        // after 30 seconds if fetch is still in progress, show warning
        setTimeout(() => {
          if (running) {
            placeholder.isLongWait = true
          }
        }, 30000)

        // fetch performance analysis data
        const response = await this.$_performanceAnalysisMixin_fetchAnalysis(
          this.dataset._id.$oid,
          selectedQuestions.map((el) => el[0]), // el: [id, question_title]
          segmentParam, // segments
          placeholder.filter_params,
          placeholder.uuid
        )
        running = false
        if (response && typeof response === "object") {
          response.summary_table = Object.entries(response.summary_table)
          const indx = this.project.performanceAnalysis.findIndex(
            (a) => a.uuid === placeholder.uuid
          )
          Object.assign(this.project.performanceAnalysis[indx], response, {
            isFetching: false,
            isLongWait: false,
            summary_table: response.summary_table
          })
          this.retrievedSummaryTable =
            this.project.performanceAnalysis[indx].summary_table
          await this.cleanAndSaveProject()
        } else {
          throw new Error("Invalid performance response.")
        }
      } catch (e) {
        if (e.code == 499) {
          this.cancelAnalysis(e.uuid)
        } else {
          // cleanup
          this.project.performanceAnalysis.shift()
          this.error = true
          throw new Error(
            "ProjectAnalysisPerformance:generatePerformanceAnalysis:: " +
              e.message
          )
        }
      }
    },

    cancelAnalysis(uuid) {
      let analysis = this.project.performanceAnalysis.find(
        (a) => a.uuid === uuid
      )
      if (analysis) {
        analysis.isCancelled = true
        analysis.isFetching = false
        analysis.isLongWait = false
        //this.saveProject(this.project)
      }
    }

    // mapClientQuestionsToBenchmarkGlobalQuestions(summaryTable) {
    //   for (let [key, val] of summaryTable) {
    //     let clientQuestion = this.clientQuestionsPreprocessed.find(
    //       (q) => q.id == key
    //     )
    //     if (clientQuestion && clientQuestion.question_text) {
    //       if (clientQuestion.question_text.trim() !== "") {
    //         val.client_question_analysis.question_text =
    //           clientQuestion.question_text
    //       }
    //     }
    //   }
    // },
  },
  watch: {
    clientQuestions: {
      immediate: true,
      handler: function (val) {
        if (!val || !Array.isArray(val) || val.length === 0) return
        this.clientQuestionsPreprocessed =
          this.preprocessClientQuestions().filter(
            (el) =>
              el.approved_data_type &&
              el.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/)
          )
        this.isClientQuestionsLoaded = true
      }
    }
  }
}
</script>
