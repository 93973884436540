<template>
  <dl class="key-value">
    <div class="key-value__item"></div>
    <div class="key-value__item">
      <dt><VisibleText>Questions</VisibleText></dt>
      <dd>
        {{ localBenchmarkGroup.questions }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Approved matches</VisibleText></dt>
      <dd>
        {{ localBenchmarkGroup.approved_matches }}
      </dd>
    </div>
    <div class="key-value__item">
      <dt><VisibleText>Default approval</VisibleText></dt>
      <div v-if="isPublicReadOnly" style="display: flex; flex-direction: row">
        <div v-if="localBenchmarkGroup.temporarily_approve">
          <VisibleText>Tentative</VisibleText>
        </div>
        <div v-if="localBenchmarkGroup.auto_approve">
          <VisibleText>Auto</VisibleText>
        </div>
        <div
          v-if="
            !localBenchmarkGroup.auto_approve &&
            !localBenchmarkGroup.temporarily_approve
          "
        >
          <VisibleText>Off</VisibleText>
        </div>
        <div>
          <Tooltip>
            <p>
              <VisibleText>
                Tentatively approve means letting the user create reports but
                their scores will not be included in the database.
              </VisibleText>
            </p>
          </Tooltip>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <dd v-if="!isPublicReadOnly" style="display: flex">
          <div
            class="switch-toggle switch-3 switch-candy"
            style="background-color: transparent"
          >
            <form>
              <input
                id="auto-approve-on"
                name="state-d"
                type="radio"
                :checked="
                  !localBenchmarkGroup.auto_approve &&
                  !localBenchmarkGroup.temporarily_approve
                "
              />
              <label
                for="auto-approve-on"
                @click="SetDefaultApproval('off')"
                style="
                  font-weight: 800;
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 1rem;
                  background-color: var(--base-lighter-ui-colour);
                  border: 1px solid var(--outline-colour);
                  color: var(--base-colour-light);
                  margin: 0 0.1rem;
                "
                :style="
                  !localBenchmarkGroup.auto_approve &&
                  !localBenchmarkGroup.temporarily_approve
                    ? 'background-color: var(--primary); color: white; border: 1px solid var(--primary);'
                    : ''
                "
              >
                <VisibleText>Off</VisibleText>
              </label>

              <input
                id="auto-approve-na"
                name="state-d"
                type="radio"
                :checked="localBenchmarkGroup.auto_approve"
              />
              <label
                style="
                  font-weight: 800;
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 1rem;
                  background-color: var(--base-lighter-ui-colour);
                  border: 1px solid var(--outline-colour);
                  color: var(--base-colour-light);
                  margin: 0 0.1rem;
                "
                :style="
                  localBenchmarkGroup.auto_approve
                    ? 'background-color: var(--primary); color: white; border: 1px solid var(--primary);'
                    : ''
                "
                for="auto-approve-na"
                class="disabled"
                @click="SetDefaultApproval('approve')"
              >
                <VisibleText>Approve</VisibleText>
              </label>

              <input
                id="auto-approve-off"
                name="state-d"
                type="radio"
                :checked="localBenchmarkGroup.temporarily_approve"
              />
              <label
                style="
                  font-weight: 800;
                  font-family: Core-Sans;
                  border-radius: 0.75rem;
                  height: 1rem;
                  background-color: var(--base-lighter-ui-colour);
                  border: 1px solid var(--outline-colour);
                  color: var(--base-colour-light);
                  margin: 0 0.1rem;
                "
                :style="
                  localBenchmarkGroup.temporarily_approve
                    ? 'background-color: var(--primary); color: white; border: 1px solid var(--primary);'
                    : ''
                "
                for="auto-approve-off"
                @click="SetDefaultApproval('temporarily approve')"
                >Tentatively approve</label
              >
            </form>
          </div>
        </dd>
        <div>
          <Tooltip v-if="!isPublicReadOnly">
            <p>
              <VisibleText>
                Tentatively approve means letting the user create reports but
                their scores will not be included in the database.
              </VisibleText>
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
    <div class="key-value__item">
      <dt>
        <VisibleText>Reveal admin email </VisibleText>
      </dt>
      <div v-if="isPublicReadOnly">
        <div
          v-if="
            localBenchmarkGroup.admin_email && localBenchmarkGroup.share_admin
          "
        >
          <VisibleText>Yes</VisibleText>
        </div>
        <div v-else>
          <VisibleText>No</VisibleText>
        </div>
      </div>
      <dd v-if="!isPublicReadOnly">
        <div
          class="switch-toggle switch-3 switch-candy"
          style="background-color: transparent"
        >
          <form>
            <input
              id="admin-email-off"
              name="state-d"
              type="radio"
              :checked="
                !localBenchmarkGroup.admin_email ||
                (localBenchmarkGroup.admin_email &&
                  localBenchmarkGroup.admin_email.length == 0) ||
                !localBenchmarkGroup.share_admin
              "
            />
            <label
              for="admin-email-off"
              @click="setShareAdmin(false)"
              style="
                font-weight: 800;
                font-family: Core-Sans;
                border-radius: 0.75rem;
                height: 1rem;
                background-color: var(--base-lighter-ui-colour);
                border: 1px solid var(--outline-colour);
                color: var(--base-colour-light);
                margin: 0 0.1rem;
              "
              :style="
                !localBenchmarkGroup.admin_email ||
                (localBenchmarkGroup.admin_email &&
                  localBenchmarkGroup.admin_email.length == 0) ||
                !localBenchmarkGroup.share_admin
                  ? 'background-color: var(--primary); color: white; border: 1px solid var(--primary);'
                  : ''
              "
            >
              <VisibleText>No</VisibleText>
            </label>

            <input
              id="admin-email-on"
              name="state-d"
              type="radio"
              :checked="
                localBenchmarkGroup.admin_email &&
                localBenchmarkGroup.share_admin
              "
            />
            <label
              for="admin-email-on"
              style="
                font-weight: 800;
                font-family: Core-Sans;
                border-radius: 0.75rem;
                height: 1rem;
                background-color: var(--base-lighter-ui-colour);
                border: 1px solid var(--outline-colour);
                color: var(--base-colour-light);
                margin: 0 0.1rem;
              "
              @click="setShareAdmin(true)"
              :style="
                localBenchmarkGroup.admin_email &&
                localBenchmarkGroup.share_admin
                  ? 'background-color: var(--primary); color: white; border: 1px solid var(--primary);'
                  : ''
              "
              >Yes</label
            >
          </form>
        </div>
      </dd>
    </div>
  </dl>
</template>

<script>
// Consts
import { ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

import Tooltip from "@/components/Tooltip/Tooltip.vue"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "BenchmarkManagerEditorDetails",
  components: { Tooltip },
  mixins: [UserMixin],
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    },
    isPublicReadOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      localBenchmarkGroup: {}
    }
  },
  watch: {
    benchmarkGroup: function (val) {
      if (val && Object.keys(val).length) {
        this.selectedTeam = val
      }
    }
  },
  created() {
    this.localBenchmarkGroup = this.benchmarkGroup
  },
  methods: {
    SetDefaultApproval(new_default) {
      if (new_default == "off") {
        this.localBenchmarkGroup.auto_approve = false
        this.localBenchmarkGroup.temporarily_approve = false
      } else if (new_default == "approve") {
        this.localBenchmarkGroup.auto_approve = true
        this.localBenchmarkGroup.temporarily_approve = false
      } else if (new_default == "temporarily approve") {
        this.localBenchmarkGroup.auto_approve = false
        this.localBenchmarkGroup.temporarily_approve = true
      }
      this.$emit("updateBenchmarkGroupDetails", this.localBenchmarkGroup)
    },
    setShareAdmin(status) {
      if (status) {
        const email = this.$pigeonline.getUserEmail()
        if (email) {
          this.localBenchmarkGroup.admin_email = email
          this.localBenchmarkGroup.share_admin = true
        }
      } else {
        this.localBenchmarkGroup.admin_email = ""
        this.localBenchmarkGroup.share_admin = false
      }
      this.$emit("updateBenchmarkGroupDetails", this.localBenchmarkGroup)
    }
  }
}
</script>
