<template>
  <!-- <img :src="icon_link" /> -->
  <component :is="icon_link" class="svg-icon"> </component>
  <!-- <add v-if="this.icon == 'add'" />
        <list v-if="this.icon == 'list'" /> -->

  <!-- <svg
    class="svg-icon"
    :aria-hidden="ariaHidden"
    :focusable="focusable.toString()"
  >
    <slot></slot>
    <use :xlink:href="`#${icon}`" :aria-hidden="ariaHiddenUse.toString()" />
  </svg>-->
</template>

<script>
import add from "@/assets/sprites/add.svg"
import alphabet from "@/assets/sprites/alphabet.svg"
import analysis from "@/assets/sprites/analysis.svg"
import approved from "@/assets/sprites/approved.svg"
import arrowDown from "@/assets/sprites/arrowDown.svg"
import arrowLeft from "@/assets/sprites/arrowLeft.svg"
import arrowUp from "@/assets/sprites/arrowUp.svg"
import ascending from "@/assets/sprites/ascending.svg"
import ban from "@/assets/sprites/ban.svg"
import brain from "@/assets/sprites/brain.svg"
import calendar from "@/assets/sprites/calendar.svg"
import chart from "@/assets/sprites/chart.svg"
import check from "@/assets/sprites/check.svg"
import chevronDown from "@/assets/sprites/chevronDown.svg"
import chevronRight from "@/assets/sprites/chevronRight.svg"
import chevronUp from "@/assets/sprites/chevronUp.svg"
import clearCircle from "@/assets/sprites/clearCircle.svg"
import copyMerge from "@/assets/sprites/copyMerge.svg"
import copy from "@/assets/sprites/copy.svg"
import customWrench from "@/assets/sprites/customWrench.svg"
import deleteIcon from "@/assets/sprites/deleteIcon.svg"
import descending from "@/assets/sprites/descending.svg"
import duplicate from "@/assets/sprites/duplicate.svg"
import editAlt from "@/assets/sprites/editAlt.svg"
import edit from "@/assets/sprites/edit.svg"
import error from "@/assets/sprites/error.svg"
import exit from "@/assets/sprites/exit.svg"
import externalLink from "@/assets/sprites/externalLink.svg"
import eye from "@/assets/sprites/eye.svg"
import filter from "@/assets/sprites/filter.svg"
import gear from "@/assets/sprites/gear.svg"
import grid from "@/assets/sprites/grid.svg"
import info from "@/assets/sprites/info.svg"
import information from "@/assets/sprites/information.svg"
import list from "@/assets/sprites/list.svg"
import locked from "@/assets/sprites/locked.svg"
import merge from "@/assets/sprites/merge.svg"
import minus from "@/assets/sprites/minus.svg"
import nextPage from "@/assets/sprites/nextPage.svg"
import notLocked from "@/assets/sprites/notLocked.svg"
import percentage from "@/assets/sprites/percentage.svg"
import phraseSentiment from "@/assets/sprites/phraseSentiment.svg"
import pin from "@/assets/sprites/pin.svg"
import profile from "@/assets/sprites/profile.svg"
import question from "@/assets/sprites/question.svg"
import questionCircle from "@/assets/sprites/questionCircle.svg"
import refresh from "@/assets/sprites/refresh.svg"
import remove from "@/assets/sprites/remove.svg"
import report from "@/assets/sprites/report.svg"
import search from "@/assets/sprites/search.svg"
import share from "@/assets/sprites/share.svg"
import shared from "@/assets/sprites/shared.svg"
import signalFair from "@/assets/sprites/signalFair.svg"
import signalGood from "@/assets/sprites/signalGood.svg"
import signalStrong from "@/assets/sprites/signalStrong.svg"
import sortArrows from "@/assets/sprites/sortArrows.svg"
import sort from "@/assets/sprites/sort.svg"
import star from "@/assets/sprites/star.svg"
import statisticsGraph from "@/assets/sprites/statisticsGraph.svg"
import success from "@/assets/sprites/success.svg"
import tag from "@/assets/sprites/tag.svg"
import table from "@/assets/sprites/table.svg"
import thickArrowDown from "@/assets/sprites/thickArrowDown.svg"
import thickArrowUp from "@/assets/sprites/thickArrowUp.svg"
import timeAdd from "@/assets/sprites/timeAdd.svg"
import timeCheck from "@/assets/sprites/timeCheck.svg"
import translate from "@/assets/sprites/translate.svg"
import upload from "@/assets/sprites/upload.svg"
import value from "@/assets/sprites/value.svg"
import video from "@/assets/sprites/video.svg"
import warning from "@/assets/sprites/warning.svg"

export default {
  name: "SvgIcon",
  props: {
    ariaHidden: {
      default: () => false,
      type: Boolean
    },
    ariaHiddenUse: {
      default: () => false,
      type: Boolean
    },
    focusable: {
      default: () => true,
      type: Boolean
    },
    icon: {
      type: String,
      required: true
    }
  },
  components: {},
  computed: {
    icon_link() {
      if (this.icon == "add") {
        return add
      }
      if (this.icon == "alphabet") {
        return alphabet
      }
      if (this.icon == "analysis") {
        return analysis
      }
      if (this.icon == "approved") {
        return approved
      }
      if (this.icon == "arrowDown") {
        return arrowDown
      }
      if (this.icon == "arrowLeft") {
        return arrowLeft
      }
      if (this.icon == "arrowUp") {
        return arrowUp
      }
      if (this.icon == "ascending") {
        return ascending
      }
      if (this.icon == "ban") {
        return ban
      }
      if (this.icon == "brain") {
        return brain
      }
      if (this.icon == "calendar") {
        return calendar
      }
      if (this.icon == "chart") {
        return chart
      }
      if (this.icon == "check") {
        return check
      }
      if (this.icon == "chevronDown") {
        return chevronDown
      }
      if (this.icon == "chevronRight") {
        return chevronRight
      }
      if (this.icon == "chevronUp") {
        return chevronUp
      }
      if (this.icon == "clearCircle") {
        return clearCircle
      }
      if (this.icon == "copyMerge") {
        return copyMerge
      }
      if (this.icon == "copy") {
        return copy
      }
      if (this.icon == "customWrench") {
        return customWrench
      }
      if (this.icon == "deleteIcon") {
        return deleteIcon
      }
      if (this.icon == "descending") {
        return descending
      }
      if (this.icon == "duplicate") {
        return duplicate
      }
      if (this.icon == "editAlt") {
        return editAlt
      }
      if (this.icon == "edit") {
        return edit
      }
      if (this.icon == "error") {
        return error
      }
      if (this.icon == "exit") {
        return exit
      }
      if (this.icon == "externalLink") {
        return externalLink
      }
      if (this.icon == "eye") {
        return eye
      }
      if (this.icon == "filter") {
        return filter
      }
      if (this.icon == "gear") {
        return gear
      }
      if (this.icon == "grid") {
        return grid
      }
      if (this.icon == "info") {
        return info
      }
      if (this.icon == "information") {
        return information
      }
      if (this.icon == "list") {
        return list
      }
      if (this.icon == "locked") {
        return locked
      }
      if (this.icon == "merge") {
        return merge
      }
      if (this.icon == "minus") {
        return minus
      }
      if (this.icon == "nextPage") {
        return nextPage
      }
      if (this.icon == "notLocked") {
        return notLocked
      }
      if (this.icon == "percentage") {
        return percentage
      }
      if (this.icon == "pin") {
        return pin
      }
      if (this.icon == "phraseSentiment") {
        return phraseSentiment
      }
      if (this.icon == "profile") {
        return profile
      }
      if (this.icon == "question") {
        return question
      }
      if (this.icon == "questionCircle") {
        return questionCircle
      }
      if (this.icon == "refresh") {
        return refresh
      }
      if (this.icon == "remove") {
        return remove
      }
      if (this.icon == "report") {
        return report
      }
      if (this.icon == "search") {
        return search
      }
      if (this.icon == "share") {
        return share
      }
      if (this.icon == "shared") {
        return shared
      }
      if (this.icon == "signalFair") {
        return signalFair
      }
      if (this.icon == "signalGood") {
        return signalGood
      }
      if (this.icon == "signalStrong") {
        return signalStrong
      }
      if (this.icon == "sortArrows") {
        return sortArrows
      }
      if (this.icon == "sort") {
        return sort
      }
      if (this.icon == "star") {
        return star
      }
      if (this.icon == "statisticsGraph") {
        return statisticsGraph
      }
      if (this.icon == "success") {
        return success
      }
      if (this.icon == "tag") {
        return tag
      }
      if (this.icon == "table") {
        return table
      }
      if (this.icon == "thickArrowDown") {
        return thickArrowDown
      }
      if (this.icon == "thickArrowUp") {
        return thickArrowUp
      }
      if (this.icon == "timeAdd") {
        return timeAdd
      }
      if (this.icon == "timeCheck") {
        return timeCheck
      }
      if (this.icon == "translate") {
        return translate
      }
      if (this.icon == "upload") {
        return upload
      }
      if (this.icon == "value") {
        return value
      }
      if (this.icon == "video") {
        return video
      }
      if (this.icon == "warning") {
        return warning
      }

      return list
    }
  }
}
</script>
