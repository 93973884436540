<template>
  <div>
    <article
      class="project-analysis-item__wrapper"
      :inert="modalOpen"
      :aria-hidden="modalOpen"
    >
      <AnalysisSettings
        ref="AnalysisSettings"
        :propReadOnly="propReadOnly"
        :statusDisableActionBtns="statusDisableActionBtns"
        :index="index"
        :thisAnalysisName="dependentVariableTitle"
        :isDriversAnalysis="true"
        :viableDriversModel="viableModels"
        :savedNodes="this.savedNodes"
        :filterParams="this.chartData ? this.chartData.filter_params : null"
        :contentChanged="this.contentChanged"
        :filterTop="this.filterTop"
        :isWeighted="isWeighted"
        :lastModified="
          formatDateTime(
            driversAnalysisObject.last_modified_on,
            $store.getters.getLanguage
          )
        "
        @toggled="isExpanded = !isExpanded"
        @on-change-drivers-model="onChangeDriversModel"
        @is-selected-var-visible="isSelectVarVisible = true"
        @save-to-report="save"
        @on-click-delete="onClickDelete"
        @changeIsLoadingChartData="isLoadingChartData = true"
        @changeIsLoadingChartDataOff="isLoadingChartData = false"
        @saved-slide="updateSavedSlide"
        @filterTop="updateFilterTop"
      ></AnalysisSettings>
      <div v-if="isExpanded">
        <LoadingSpinner
          :isLoading="isLoadingChartData"
          :componentStyle="true"
          style="position: relative"
          :style="{ 'min-height': minHeight }"
        />
      </div>

      <!--------------------------------------------
      |
      | CHARTS
      |
      --------------------------------------------->
      <DriversCharts
        v-if="isExpanded"
        :skipMatrix="skipMatrix"
        :dependent-variable-title="dependentVariableTitle"
        :chart-data="filteredChartData"
        :isLoading="isLoadingChartData"
      />
    </article>
    <!--------------------------------------------
    |
    | VARIABLES MODAL
    |
    --------------------------------------------->
    <DriversVariables
      :dependent-variable-title="dependentVariableTitle"
      :dependent-questions="selectedDependentVars"
      :independent-questions="selectedIndependentVars"
      :show-drivers-variables="isSelectVarVisible"
      @modify-vars="modifyVars"
      @close-drivers-variables="isSelectVarVisible = false"
    />
  </div>
</template>

<script>
// Components
import DriversCharts from "./Components/DriversCharts.vue"
import DriversVariables from "./Components/VariablesModal.vue"
import AnalysisSettings from "@/components/Project/ProjectAnalysis/Components/AnalysisSettings.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DriversAnalysisMixin from "./Mixins/driversAnalysisMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"

// Helpers
import moment from "moment"
import _ from "lodash"
import { PigeonDocModel } from "@pigeonline/pigeondoc"

export default {
  name: "ProjectAnalysisDriversItem",
  mixins: [
    DatasetDetailsMixin,
    DriversAnalysisMixin,
    ProjectMixin,
    ProjectReportMixin,
    FiltersMixin
  ],
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  components: {
    DriversCharts,
    DriversVariables,
    AnalysisSettings,
    LoadingSpinner
  },
  data() {
    return {
      savedSlide: "",
      savedNodes: [],
      selectedDriversModel: "",
      driversAnalysisObject: {},
      dependentVariableTitle: "overall satisfaction",
      isSelectVarVisible: false,
      selectedDependentVars: null,
      selectedIndependentVars: null,
      uuid: this.$pigeonline.createUUID(),
      isExpanded: false,
      contentChanged: false,
      filteredChartData: {},
      filterTop: 10,
      isLoadingChartData: false
    }
  },
  async created() {
    this.driversAnalysisObject = _.cloneDeep(
      this.project.driversAnalysis[this.index]
    )
    if (this.driversAnalysisObject.saved_node_ids) {
      this.savedNodes = this.driversAnalysisObject.saved_node_ids
    }
    if (
      this.driversAnalysisObject.filter_params &&
      Object.keys(this.driversAnalysisObject.filter_params).length
    ) {
      this.filter_params = this.driversAnalysisObject.filter_params
    }
    this.setVariables(
      this.driversAnalysisObject.dependent_variable_id,
      this.driversAnalysisObject.independent_variables_ids,
      this.driversAnalysisObject.dependent_variable_title
    )
    // if no chart data saved or performance type
    // was recently updated fetch chart data & update project
    if (
      !this.driversAnalysisObject.chart_data ||
      (this.projectPerformanceType &&
        this.projectPerformanceType !==
          this.driversAnalysisObject.saved_performance_type)
    ) {
      await this.setChartData(
        this.driversAnalysisObject.dependent_variable_id,
        this.driversAnalysisObject.independent_variables_ids,
        this.selectedDriversModel,
        this.filter_params
      )
      await this.saveDriversToProject(this.driversAnalysisObject.uuid)
    } else {
      this.chartData = this.driversAnalysisObject.chart_data
    }
    if (this.driversAnalysisObject.filterTop) {
      this.filterTop = this.driversAnalysisObject.filterTop
    }
    this.filteredChartData = this.chartData
    // Note: The below requires a 'close' and open due to responsivity, requires a fix
    this.updateFilteredChartData()
  },
  computed: {
    statusDisableActionBtns() {
      return this.isLoadingChartData || this.propReadOnly
    },
    // not directly used, save for bilingual expansion
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "Please name this slide",
        "slide-name",
        this.$store.getters.getLanguage
      )
    },
    viableModels() {
      let models = []
      let dvTypes = []
      let ivTypes = []
      for (let i = 0; i < this.selectedDependentVars.length; i++) {
        this.$store.getters["datasetDetails/getClientQuestions"].reduce(
          (allqs, q) => {
            if (this.selectedDependentVars[i].includes(q._id.$oid)) {
              if (q.approved_data_type) {
                // Check if categorical, then check if binary or multiclass
                if (q.approved_data_type === "CAT") {
                  if (q.unique_values.length === 2) {
                    dvTypes.push("CAT--binary")
                  } else {
                    dvTypes.push("CAT--multiclass")
                  }
                } else {
                  dvTypes.push(q.approved_data_type)
                }
              }
            }
          }
        )
      }
      for (let i = 0; i < this.selectedIndependentVars.length; i++) {
        this.$store.getters["datasetDetails/getClientQuestions"].reduce(
          (allqs, q) => {
            if (this.selectedIndependentVars[i].includes(q._id.$oid)) {
              if (q.approved_data_type) {
                ivTypes.push(q.approved_data_type)
              }
            }
          }
        )
      }
      if (ivTypes.length && dvTypes.length) {
        if (dvTypes.length === 1) {
          if (dvTypes.includes("CAT--binary")) {
            models.push("random_forest_classification")
            models.push("logistic_regression")
          }
          if (dvTypes.includes("CAT--multiclass")) {
            models.push("random_forest_classification")
          }
        }
        if (
          !dvTypes.includes("CAT--binary") &&
          !dvTypes.includes("CAT--multiclass")
        ) {
          models.push("random_forest_regression")
          models.push("elastic_net_regression")
        }
      }
      return models
    },
    skipMatrix() {
      let skipMatrix = false
      let ivTypes = []
      for (let i = 0; i < this.selectedIndependentVars.length; i++) {
        this.$store.getters["datasetDetails/getClientQuestions"].reduce(
          (allqs, q) => {
            if (this.selectedIndependentVars[i].includes(q._id.$oid)) {
              if (q.approved_data_type) {
                ivTypes.push(q.approved_data_type)
              }
            }
          }
        )
      }
      let checkingForUnique = []
      if (ivTypes.length) {
        for (let i = 0; i < ivTypes.length; i++) {
          if (!checkingForUnique.includes(ivTypes[i])) {
            checkingForUnique.push(ivTypes[i])
          }
        }
      }
      if (checkingForUnique.length) {
        if (checkingForUnique.length > 1) {
          skipMatrix = true
        }
        for (let i = 0; i < checkingForUnique.length; i++) {
          if (!checkingForUnique[i].includes("SCALE")) {
            skipMatrix = true
          }
        }
      }
      return skipMatrix
    },
    isFiltered() {
      if (this.chartData) {
        if (this.chartData.filter_params) {
          return true
        }
      }
      return false
    },
    minHeight() {
      if (this.isLoadingChartData) {
        return "5em"
      } else {
        return "0"
      }
    },
    isWeighted() {
      if (this.chartData) {
        if (this.chartData.is_weight_applied) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    async updateFilterTop(number) {
      if (!number) return
      this.filterTop = parseInt(number)
      await this.saveDriversToProject(this.driversAnalysisObject.uuid)
    },
    async updateFilteredChartData() {
      if (this.chartData) {
        if (this.chartData.num_drivers) {
          let storedData = this.deepCloneObj(this.chartData)
          let num_drivers = this.chartData.num_drivers
          let new_num_drivers
          if (num_drivers.length) {
            new_num_drivers = num_drivers.slice(0, this.filterTop)
            this.filteredChartData.num_drivers = new_num_drivers
            if (this.$refs.AnalysisSettings) {
              this.$refs.AnalysisSettings.toggleContent()
            }
            this.chartData = storedData
          }
        }
      }
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
    },
    filterClientQuestions(questionIDs) {
      return this.clientQuestions.filter((q) =>
        questionIDs.includes(q._id.$oid)
      )
    },
    /**
     * backwards compatability: project.driversAnalysis updated from object
     * to array, replace it with an array
     */
    async handleBackwardsCompatibility() {
      if (!this.project.driversAnalysis) {
        this.project.driversAnalysis = []
        await this.cleanAndSaveProject()
        // await this.saveProject(this.project)
      } else if (
        !Array.isArray(this.project.driversAnalysis) &&
        Object.keys(this.project.driversAnalysis).length > 0
      ) {
        this.project.driversAnalysis = new Array(this.driversAnalysisObject)
        await this.cleanAndSaveProject()
        // await this.saveProject(this.project)
      }
    },
    /************
     *
     * Setters
     *
     ***********/
    setVariables(dependentVar, independentVars, dependentVarTitle) {
      this.dependentVariableTitle = dependentVarTitle
      this.selectedDependentVars = dependentVar
      this.selectedIndependentVars = independentVars
    },
    async setChartData(
      dependentVar,
      independentVars,
      algorithm,
      filter_params
    ) {
      this.isLoadingChartData = true
      this.chartData = {}
      this.chartData = await this.$_driversAnalysisMixin_fetchChartData(
        this.project.id,
        this.dataset._id.$oid,
        dependentVar,
        independentVars,
        algorithm,
        filter_params
      )
      this.isLoadingChartData = false
    },
    /************
     *
     * Saving methods
     *
     ***********/
    async modifyVars(dependent, independent, model) {
      if (model === "") {
        this.selectedDriversModel = ""
      }
      this.setVariables(
        dependent,
        independent,
        this.$_driversAnalysisMixin_getDependentVarTitle(dependent)
      )
      await this.setChartData(dependent, independent, model)
      this.saveDriversToProject(this.driversAnalysisObject.uuid)
      this.contentChanged = true
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.toggleContent()
      }
      this.filteredChartData = this.chartData
    },
    async save(selectedSlide, newSlideName) {
      if (this.propReadOnly) return
      const uuid = await this.saveDriversToReport(selectedSlide, newSlideName)
      // this.project.updateStatus("analysisCompleted")
      // this is used to get all AnalysisSetting->SaveAnalysis to rerun collections
      if (!this.project.reportSaves) {
        this.project.reportSaves = 1
      } else {
        this.project.reportSaves = this.project.reportSaves + 1
      }
      await this.saveDriversToProject(uuid)
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.updateCollections()
      }
      this.$emit("refresh")
      this.contentChanged = false
    },
    async saveDriversToReport(selectedSlide, newSlideName) {
      try {
        this.isLoadingChartData = true
        let createdReportID = await this.createReportIfEmpty(
          selectedSlide,
          newSlideName
        ) // the above function will do nothing under conditions of emptiness
        if (selectedSlide) {
          createdReportID = selectedSlide.id
        }
        let savedNodes = []
        let returnedHeader =
          await this.updateReportWithDriversHeader(createdReportID)
        if (returnedHeader) {
          savedNodes = returnedHeader
        }
        // This report should only update matrix if the IVs are the same scale
        const returnObject = await this.updateReportWithDrivers(
          this.driversAnalysisObject.uuid,
          createdReportID,
          this.filteredChartData,
          this.dependentVariableTitle,
          this.skipMatrix
        )
        const mergedNodes = savedNodes.concat(returnObject.newNodes)
        this.isLoadingChartData = false
        await this.$emit("updateSlides")
        this.onSuccessfulSave()
        this.savedNodes = mergedNodes
        return returnObject.uuid
      } catch (e) {
        throw new Error(
          "ProjectAnalysisDrivers:saveDriversToReport " + e.message
        )
      }
    },
    async saveDriversToProject(uuid = null) {
      if (this.propReadOnly) return
      const newObject = {
        saved_performance_type: this.projectPerformanceType || "scale-average",
        dependent_variable_id: this.selectedDependentVars,
        dependent_variable_title: this.dependentVariableTitle,
        independent_variables_ids: this.selectedIndependentVars,
        chart_data: this.chartData,
        saved_node_ids: this.savedNodes,
        last_modified_on: moment().valueOf(),
        filterTop: this.filterTop,
        uuid: uuid || this.$pigeonline.createUUID()
      }
      await this.handleBackwardsCompatibility()

      // find it in currently saved drivers, if there update, if not add it
      const indx = this.project.driversAnalysis.findIndex(
        (d) => d.uuid == this.driversAnalysisObject.uuid
      )
      if (indx > -1)
        this.project.driversAnalysis.splice(indx, 1, _.cloneDeep(newObject))
      else this.project.driversAnalysis.unshift(_.cloneDeep(newObject))

      await this.cleanAndSaveProject()
      // await this.saveProject(this.project)
      this.driversAnalysisObject = _.cloneDeep(newObject)
    },
    /************
     *
     * Deleting
     *
     ***********/
    async deleteDriver() {
      // Drops the driver from the "analysis view"
      const indx = this.project.driversAnalysis.findIndex(
        (d) => d.uuid == this.driversAnalysisObject.uuid
      )
      this.project.driversAnalysis.splice(indx, 1)

      await this.cleanAndSaveProject()
      // Drops the same nodes from the saved project
      let project
      if (this.savedNodes.length) {
        project = await this.$pigeonline.projects.getByID(
          PigeonDocModel,
          this.savedSlide.id
        )
        if (project) {
          for (let i = 0; i < this.savedNodes.length; i++) {
            for (let x = 0; x < project.nodes.length; x++) {
              if (project.nodes[x].uuid === this.savedNodes[i].uuid) {
                project.nodes = project.nodes.filter(
                  (item) => item !== project.nodes[x]
                )
              }
            }
          }
        }
        // This saves changes to a "collection" which has a field "savedNodes"
        await this.saveProject(project)
      }
      this.$emit("refresh")
      location.reload() // hacky fix to delete problems messing with project, please tighten
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    async onClickDelete() {
      const indx = this.project.driversAnalysis.findIndex(
        (d) => d.uuid == this.driversAnalysisObject.uuid
      )
      if (!this.savedNodes.length) {
        this.setConfirmText({
          btn: "Delete Drivers",
          title: "Delete drivers analysis?"
        })
      } else {
        this.setConfirmText({
          btn: "Delete Drivers",
          title:
            "Delete drivers analysis? This will also delete this analysis from your report."
        })
      }
      this.setConfirmType("delete")
      this.setConfirmTarget(this.driversAnalysisObject.uuid)
      this.setConfirmSourceComponent("drivers")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Drivers saved",
        message: "Drivers successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("drivers")
      this.setConfirmIsVisible(true)
    },
    async onChangeDriversModel(thisModel) {
      this.selectedDriversModel = thisModel
      await this.modifyVars(
        this.selectedDependentVars,
        this.selectedIndependentVars,
        this.selectedDriversModel
      )
      if (this.savedNodes.length) {
        this.contentChanged = true
      }
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  },
  watch: {
    filterTop: async function (newVal, oldVal) {
      if (oldVal && newVal) {
        if (oldVal !== newVal) {
          this.updateFilteredChartData()
        }
      }
    },
    activeTab: async function (val) {
      if (val === "Drivers") {
        if (!this.chartData) {
          this.setVariables(
            this.driversAnalysisObject.dependent_variable_id,
            this.driversAnalysisObject.independent_variables_ids,
            this.driversAnalysisObject.dependent_variable_title
          )
        }
      }
    },
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "drivers" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.driversAnalysisObject.uuid
      ) {
        await this.deleteDriver()
      }
    },
    projectUpdatedPerformanceType: async function (type) {
      if (type && type !== this.driversAnalysisObject.saved_performance_type) {
        await this.setChartData(
          this.driversAnalysisObject.dependent_variable_id,
          this.driversAnalysisObject.independent_variables_ids,
          this.selectedDriversModel
        )
        await this.saveDriversToProject(this.driversAnalysisObject.uuid)
      }
    }
  }
}
</script>
