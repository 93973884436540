<template>
  <Modal class="create-new-analysis" :show="show" @close="$emit('close-modal')">
    <template #header>
      <VisibleText>Create new chart based on themes</VisibleText>
    </template>
    <VisibleText>Select themes to include in the chart.</VisibleText>

    <div style="width: 10rem">
      <label
        class="form-label form-checkbox-label table__toolbar-checkbox-select"
      >
        <input
          class="table__toolbar-checkbox-select-input"
          type="checkbox"
          name="select-all-questions"
          v-model="selectAll"
        />
        Select all
      </label>
    </div>

    <!--------------------------------------------
    |
    | FORM - QUESTION CHECKBOXES
    |
    --------------------------------------------->
    <form
      class="dataset__select-questions-form"
      @submit.prevent
      style="margin-top: -0.3em"
    >
      <fieldset class="form-fieldset-group light-scroll-bar">
        <legend class="sr-only">
          {{ translate("select themes", $options.name) }}
        </legend>
        <div
          class="dataset__select-questions-form__checkbox"
          style="display: flex"
          v-for="theme in sortedThemes"
          :key="`select-${theme._id}`"
        >
          <div class="col-1">
            <label class="form-checkbox-label">
              <input
                class="form-checkbox-input"
                type="checkbox"
                name="question"
                :value="theme._id"
                v-model="selectedThemeIds"
              />
              <span class="question-title">
                {{ theme.name }}
              </span>
            </label>
          </div>
        </div>
      </fieldset>
    </form>

    <template #footer>
      <div style="display: flex; width: 100%">
        <div style="display: flex; width: 20%; align-items: center">
          <button
            class="create-new-analysis__confirm-select-btn"
            @click="confirmSelection"
            :disabled="!selectedThemeIds.length"
          >
            <SvgIconDecorative
              icon="check"
              class="create-new-analysis__confirm-select-btn-icon"
            />
            <VisibleText>Confirm selection</VisibleText>
          </button>
        </div>
        <div style="display: flex; width: 50%; margin-left: auto">
          <div style="display: flex; flex-direction: column">
            <button
              class="btn-cancel"
              style="margin-left: auto; max-height: 3rem; margin: auto"
              @click="segmentMode = true"
              :disabled="Object.keys(filterParams).length"
            >
              <div v-if="segmentParams">
                <VisibleText>Edit segment</VisibleText>
              </div>
              <div v-else>
                <VisibleText>Add segment to chart</VisibleText>
              </div>
            </button>
            <VisibleText
              v-if="Object.keys(filterParams).length"
              style="margin-bottom: auto"
            >
              Cannot apply segments when a filter is applied.
            </VisibleText>
          </div>

          <FilterPills
            v-if="isSegmented"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="segmentAsPill"
            @clearFilters="clearSegments"
            :altTitle="''"
          >
          </FilterPills>
          <div class="btn-cancel" v-if="isFiltered">
            <VisibleText> Filters </VisibleText>
          </div>
          <FilterPills
            v-if="isFiltered"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="filterParams"
            :altTitle="''"
            :hideClear="true"
            @clearFilters="clearFilters"
          >
          </FilterPills>
        </div>
      </div>
      <AddSegment
        :show="segmentMode"
        :type="'own'"
        :data="allCatQuestions"
        @close-modal="segmentMode = false"
        @confirmNewSegment="confirmNewSegment"
      />
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import AddSegment from "@/components/Project/ProjectAnalysis/Components/AddSegment.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

import _ from "lodash"
import VisibleText from "../../../../Translation/VisibleText.vue"

export default {
  name: "CreateNewThemeChart",
  components: {
    Modal,
    SvgIconDecorative,
    FilterPills,
    AddSegment
  },
  props: {
    sortedThemes: {
      type: Array,
      default: () => []
    },
    show: {
      default: () => false,
      type: Boolean
    },
    filterParams: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      selectedThemes: [],
      selectedThemeIds: [],
      filterMode: false,
      segmentMode: false,
      segmentParams: "",
      segmentAsPill: [],
      selectAll: false
    }
  },
  mounted() {
    this.selectAll = true
  },
  computed: {
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    },
    filteredTitle() {
      let filter_title = ""
      if (Object.keys(this.filterParams).length) {
        for (let i = 0; i < this.filterParams.operands.length; i++) {
          const thisQuestion = this.$store.getters[
            "datasetDetails/getClientQuestions"
          ].find(
            (q) =>
              q._id.$oid === this.filterParams.operands[i].client_question_id
          )
          if (thisQuestion) {
            let question_values = ""
            for (
              let x = 0;
              x < this.filterParams.operands[i].values.length;
              x++
            ) {
              if (x < this.filterParams.operands[i].values.length - 1) {
                question_values =
                  question_values +
                  parseInt(this.filterParams.operands[i].values[x]) +
                  ", "
              } else {
                question_values =
                  question_values +
                  parseInt(this.filterParams.operands[i].values[x])
              }
            }
            let possible_title = ""
            if (thisQuestion.question_title) {
              possible_title = thisQuestion.question_title
            } else {
              possible_title = thisQuestion.question_text
            }

            if (i == 0) {
              filter_title =
                "Those who answered the question " + possible_title + "'"
              if (question_values) {
                filter_title =
                  filter_title + "' with the values of " + question_values
              }
            } else {
              filter_title =
                filter_title +
                " and those who answered the question " +
                possible_title
              if (question_values) {
                filter_title =
                  filter_title + " with the values of " + question_values
              }
            }
          }
        }
      }

      return filter_title
    },
    isSegmented() {
      if (this.segmentParams && this.segmentParams !== "") {
        return true
      }
      return false
    },
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    allCatQuestions() {
      let cats = []
      if (this.allQuestions.length) {
        for (let i = 0; i < this.allQuestions.length; i++) {
          if (
            this.allQuestions[i].approved_data_type &&
            this.allQuestions[i].approved_data_type == "CAT"
          ) {
            cats.push(this.allQuestions[i])
          }
        }
      }
      return cats
    }
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    confirmSelection() {
      this.selectedThemes = this.deepCloneObj(this.sortedThemes).filter(
        (theme) => this.selectedThemeIds.includes(theme._id)
      )
      this.$emit(
        "confirmSelection",
        this.selectedThemes,
        this.filteredTitle,
        this.segmentParams
      )

      this.selectedThemes = []
      this.$emit("close-modal")
    },
    confirmNewSegment(selectedIds, selectedAsPill) {
      if (selectedIds && selectedAsPill) {
        this.segmentParams = selectedIds
        this.segmentAsPill = selectedAsPill
      }
    },
    clearSegments() {
      this.segmentMode = false
      this.segmentParams = ""
      this.segmentAsPill = []
    }
  },
  watch: {
    selectAll: function (select) {
      if (select) {
        this.selectedThemeIds = this.sortedThemes.map((theme) => theme._id)
      } else {
        this.selectedThemeIds = []
      }
    }
  }
}
</script>
