const datasetWizardCRUD = ["create-dataset", "update-dataset", "read-dataset"]
const projectCRUD = ["create-project", "update-project", "read-project"]
const allAnalysis = ["create-analysis", "create-analysis-text", "create-report"]

// Many new priveleges like for demo and teaching also use licenses.includes("demo") throughout
const licenses = {
  free: [
    "accounts-invite-free",
    "accounts-join-team",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...datasetWizardCRUD,
    ...projectCRUD,
    ...allAnalysis
  ],
  demo: [
    "accounts-invite-free",
    "accounts-join-team",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...datasetWizardCRUD,
    ...projectCRUD,
    ...allAnalysis
  ],
  basic: [
    "accounts-invite-free",
    "accounts-join-team",
    "benchmarking-group-select",
    "accounts-create-team",
    "benchmarking-manager",
    ...datasetWizardCRUD,
    ...projectCRUD,
    ...allAnalysis
  ],
  teaching: [
    "accounts-join-team",
    "accounts-create-team",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...allAnalysis,
    ...datasetWizardCRUD,
    ...projectCRUD
  ],
  research: [
    "accounts-join-team",
    "accounts-create-team",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...allAnalysis,
    ...datasetWizardCRUD,
    ...projectCRUD
  ],
  iccs: [
    "accounts-join-team",
    "benchmarking-group-iccs",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...allAnalysis,
    ...datasetWizardCRUD,
    ...projectCRUD
  ],
  pigeon: [
    "user-analytics",
    "accounts-join-team",
    "accounts-create-team",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...allAnalysis,
    ...datasetWizardCRUD,
    ...projectCRUD
  ]
}

licenses["iccs-admin"] = ["benchmarking-manager", ...licenses.iccs]
licenses["basic-iccs"] = ["benchmarking-group-iccs", ...licenses.basic]

export default licenses
