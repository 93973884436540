<template>
  <div class="dataset-table__filters" style="margin-bottom: 0.2em">
    <div class="flex">
      <SearchBar
        class="dataset__search-questions"
        placeholder="Search question code and question text"
        :prevent-auto-search="true"
        @search="searchAndFilter"
      >
        <template #label>
          <VisibleText> Search </VisibleText>
        </template>
        <template #submit>
          <ButtonIconOnly icon="search" button-type="submit">
            {{ translate("search", $options.name) }}
          </ButtonIconOnly>
        </template>
      </SearchBar>
      <div class="dataset-table__filters-filter">
        <label
          class="label"
          for="data-table-filter"
          :data-parent="$options.name"
        >
          <VisibleText> Filter</VisibleText>
        </label>
        <div class="select-wrapper">
          <select
            class="select"
            id="data-table-filter"
            v-model="filter"
            @change="searchAndFilter"
          >
            <option value="" :data-parent="$options.name">
              {{ translate("All questions", $options.name) }}
            </option>
            <optgroup label="Data Type">
              <option
                v-if="showNoType"
                value="no-type"
                :data-parent="$options.name"
              >
                {{ translate("no data type", $options.name) }}
              </option>
              <option
                v-for="type in Object.keys(dataTypeToReadableFiltered)"
                :key="type"
                :value="type"
                :data-parent="$options.name"
              >
                {{ translate(dataTypeToReadableFiltered[type], $options.name) }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>
    <div
      class="dataset-table__filters-active shadow--light"
      style="padding-top: 0.2em; padding-bottom: 0.2em"
      v-show="searchQuery.length > 0 || filter.length > 0"
    >
      <h4 class="title text-smallcaps">
        <VisibleText> ACTIVE FILTERS </VisibleText>
        <span>
          - {{ numResults }} {{ translate("QUESTIONS FOUND", $options.name) }}
        </span>
        <span v-if="filteredNumResults < numResults && filteredNumResults != 0">
          - {{ numResults - filteredNumResults }}
          {{ translate("removed as invalid", $options.name) }}
        </span>
      </h4>
      <ul class="dataset-table__filters-active__list">
        <li
          class="item search rounded-pill"
          v-for="(query, index) in searchQuery"
          :key="`result-${index}`"
        >
          <SvgIconDecorative icon="search" />
          {{ query }}
          <ButtonIconOnly icon="remove" @click-handler="onClickRemoveSearch">
            {{ translate("remove", $options.name) }}
          </ButtonIconOnly>
        </li>
        <li class="item filter rounded-pill" v-if="filter.length > 0">
          <SvgIconDecorative icon="filter" />
          {{ dataTypeToReadableFiltered[filter] || "no data type" }}
          <ButtonIconOnly icon="remove" @click-handler="onClickRemoveFilter">
            {{ translate("remove", $options.name) }}
          </ButtonIconOnly>
        </li>
      </ul>
      <button class="btn-secondary" type="button" @click="onClickReset">
        <VisibleText> Clear Filters </VisibleText>
      </button>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SearchBar from "@/components/UI/SearchBar.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"

const searchObj = (regExp, q) => {
  return ["question_title", "question_text"].some(
    (key) => q[key].search(regExp) !== -1
  )
}

export default {
  name: "DatasetTableSearchAndFilter",
  components: {
    ButtonIconOnly,
    SearchBar,
    SvgIconDecorative
  },
  props: {
    suggestedDataTypes: {
      default: () => {},
      type: Object
    },
    refreshFilter: {
      default: false,
      type: Boolean
    },
    invalidTypes: {
      default: () => [],
      type: Array,
      required: false
    },
    questionsWithValid: {
      default: () => [],
      type: Array,
      required: false
    },
    showNoType: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      DATA_TYPE_TO_READABlE: Object.freeze(DATA_TYPE_TO_READABlE),
      filter: "",
      searchQuery: [],
      numResults: 0,
      filteredNumResults: 0
    }
  },
  computed: {
    dataTypeToReadableFiltered() {
      let questions = this.deepCloneObj(DATA_TYPE_TO_READABlE)
      let filtered = {}

      const objectArray = Object.entries(questions)

      objectArray.forEach(([key, value]) => {
        if (this.questionsWithValid.includes(key)) {
          filtered[key] = value
        }
      })
      return filtered
    }
  },
  methods: {
    onClickRemoveFilter() {
      this.filter = ""
      this.searchAndFilter()
    },
    onClickRemoveSearch(query) {
      const indx = this.searchQuery.indexOf(query)
      this.searchQuery.splice(indx)
      this.searchAndFilter()
    },
    onClickReset() {
      this.filter = ""
      this.searchQuery = []
      this.numResults = 0
      this.filteredNumResults = 0
      this.$emit("reset")
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    filterDataType(q) {
      let isMatch = false
      if (this.filter == "no-type")
        isMatch =
          (!q.approved_data_type || q.approved_data_type === "none") &&
          !this.suggestedDataTypes[q._id.$oid]
      else if (this.filter.length > 0 && q.approved_data_type) {
        isMatch = q.approved_data_type === this.filter
      } else if (this.filter.length > 0 && !q.approved_data_type) {
        isMatch = false
      } else if (
        this.filter.length > 0 &&
        (!this.suggestedDataTypes || this.suggestedDataTypes[q._id.$oid])
      ) {
        if (!this.suggestedDataTypes) {
          isMatch = this.filter
        } else {
          isMatch = this.suggestedDataTypes[q._id.$oid] === this.filter
        }
      }
      return isMatch
    },
    /**
     * Apply search and filter to clientQuestions
     * @param query new search query to apply
     * @refreshPagination flag to tell parent DatasetTable if we want to refresh
     * pagination after we return filtered results
     */
    searchAndFilter(query = "", refreshPagination = true) {
      if (query.length > 0) this.searchQuery.push(query)
      if (this.searchQuery.length == 0 && this.filter.length == 0) {
        this.onClickReset()
        return
      }

      const questions = this.$store.getters["datasetDetails/getClientQuestions"]
      let regExp = new RegExp()
      if (this.searchQuery.length > 0) {
        let regExGroup = ""
        this.searchQuery.forEach(
          (q) => (regExGroup = regExGroup.concat(`(?=.*?${q})`))
        )
        regExp = new RegExp(`^${regExGroup}.*$`, "ig")
      }

      let filtered = []
      let validFiltered = []
      filtered = questions.filter((item) => {
        if (this.searchQuery.length > 0 && this.filter.length > 0) {
          return searchObj(regExp, item) && this.filterDataType(item)
        } else if (this.searchQuery.length > 0 && this.filter.length == 0) {
          return searchObj(regExp, item)
        } else if (this.filter.length > 0 && this.searchQuery.length == 0) {
          return this.filterDataType(item)
        }
      })
      this.numResults = filtered.length
      if (this.invalidTypes.length) {
        for (let i = 0; i < filtered.length; i++) {
          if (!this.invalidTypes.includes(filtered[i].approved_data_type)) {
            validFiltered.push(filtered[i])
          }
        }
        this.filteredNumResults = validFiltered.length
        this.$emit("results", validFiltered, refreshPagination)
      } else {
        this.$emit("results", filtered, refreshPagination)
      }
      if (!this.numResults) {
        this.$emit("reset")
      }
    }
  },
  watch: {
    refreshFilter: function () {
      this.searchAndFilter("", false)
    }
  }
}
</script>
