export default {
  computed: {
    datasetSegments() {
      return this.$store.getters["datasetDetails/getDataset"].segments
    }
  },
  methods: {
    /***********
     *
     * Adding and removing segments
     * - we need to update both the datasetProject object and the
     * dataset.segments field
     *
     ***********/
    async $_datasetSegmentsMixin_addSegment(
      isCustom,
      category,
      segment,
      segmentObject = {}
    ) {
      if (!this.datasetProject.schema_version)
        await this.convertOldCustomSegments()

      let _segment = segment

      if (isCustom) {
        // for custom segments, check if it already exists
        _segment = isCustom ? this.isSegmentExists(segment) : segment
        this.datasetProject.addCustomSegment(category, _segment)
      }
      this.updateSegments(_segment, segmentObject)
      this.setQuestionAsComplete(category)
    },
    async $_datasetSegmentsMixin_removeSegment(isCustom, category, segment) {
      if (isCustom) this.datasetProject.removeCustomSegment(category, segment)
      this.removeSegment(segment)
      this.setQuestionAsIncomplete(category)
    },
    /***********
     *
     * Helper methods
     *
     ***********/
    removeSegment(segment) {
      if (segment == "None") return
      /* eslint-disable no-unused-vars */
      const { [segment]: _, ...segments } = this.datasetSegments
      this.$store.dispatch("datasetDetails/updateDatasetSegments", segments)
    },
    updateSegments(segment, segmentObject) {
      if (segment === "None") return
      // segment already exists
      if (
        Object.keys(this.datasetSegments)
          .map((k) => k.toLowerCase())
          .includes(segment.toLowerCase())
      )
        return
      this.datasetSegments[segment] = segmentObject
    },
    isSegmentExists(newSegment) {
      let segment = null
      if (
        Object.keys(this.$store.getters["datasetDetails/getSegmentDetails"])
          .length > 0
      ) {
        segment = Object.keys(
          this.$store.getters["datasetDetails/getSegmentDetails"]
        ).find(
          (savedSegment) =>
            savedSegment.toLocaleLowerCase() === newSegment.toLocaleLowerCase()
        )
      }
      return segment || newSegment
    },
    setQuestionAsComplete(category) {
      //add to step5 progress if segments are added to a category
      if (this.datasetProject.progress.step5.indexOf(category) === -1)
        this.datasetProject.progress.step5.push(category)
    },
    setQuestionAsIncomplete(category) {
      //remove category from step5 progress if all inputs are unchecked
      // & custom input is empty
      const checkboxes = document
        .getElementById(category)
        .querySelectorAll('[type="checkbox"]')
      const isAllUnchecked = [...checkboxes].every((c) => !c.checked)
      const customSegments = this.datasetProject.customSegments[category]

      if (isAllUnchecked && (!customSegments || customSegments.length === 0)) {
        const indx = this.datasetProject.progress.step5.indexOf(category)
        this.datasetProject.progress.step5.splice(indx, 1)
      }
    },
    /**
     * convert custom segments so its compatible with schema version 2.
     * in older versions custom segment were by default categorized as typeservice group.
     * assumes datasetProject is v1 and datasetProject.customSegment is a string
     */
    async convertOldCustomSegments() {
      if (!this.datasetProject.customSegments) {
        this.datasetProject.customSegments = {}
        this.datasetProject.schema_version = "2"
        await this.saveDatasetProject(this.datasetProject)
      } else {
        const customSegments = (" " + this.datasetProject.customSegments).slice(
          1
        ) // copy string
        this.datasetProject.customSegments = {}
        this.datasetProject.schema_version = "2"
        if (customSegments && customSegments.length > 0) {
          customSegments.split(",").forEach((seg) => {
            this.datasetProject.addCustomSegment(
              "dataset-typeservice-group",
              seg
            )
          })
        }
        await this.saveDatasetProject(this.datasetProject)
      }
    }
  }
}
