import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class TranslationService extends BaseService {
  /**
   * Create new translation
   * @param {String} data new translation
   * @returns {Object} translation
   */
  async newTranslation(data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/translations/"
      const response = await axios.post(`${URL}`, data)
      return response.data
    } catch (e) {
      throw new Error("translationService.js:newTranslation " + e.message)
    }
  }

  /**
   * Get translations (or a specific translation) based on params
   * @param {String} [id] of translations to fetch
   * @param {String} [params] paramteres to filter by
   * @returns {(Object|Array)} object or array of translations
   */
  async translations(id, params = {}) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/translations/"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""

      let url = `${URL}${id ? `${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("translationService.js:translations " + e.message)
    }
  }

  async updateTranslations(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/translations/"
      const response = await axios.patch(`${URL}${id}`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("translationService.js:updateTranslations " + e.message)
    }
  }

  /**
   * Delete translations
   * @param {String} id of translation
   */
  async deleteTranslation(id) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/translations/"
      const response = await axios.delete(`${URL}${id}`)
      return response.data
    } catch (e) {
      throw new Error("translationService.js:deleteTranslation " + e.message)
    }
  }
}
