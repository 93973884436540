const NUM_RESPONSES_INCREMENT = 10

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isComponentMounted: false,
      numResponsesToDisplay: NUM_RESPONSES_INCREMENT,
      resetKeyDownListener: null,
      resetScrollEventListener: null,
      shouldResetResponseList: false
    }
  },
  computed: {
    responseListRef: function () {
      return `response-list-ref-${this.$.uid}`
    }
  },
  beforeUnmout() {
    this.removeEventListeners()
  },
  methods: {
    getResponseListRef() {
      return this.$refs[this.responseListRef]
    },

    //  event listeners
    addEventListeners() {
      if (!this.isComponentMounted || !this.getResponseListRef()) return
      this.resetKeyDownListener = this.getResponseListRef().addEventListener(
        "keydown",
        this.onKeydown.bind(this)
      )
      this.resetScrollEventListener =
        this.getResponseListRef().addEventListener(
          "scroll",
          this.onScroll.bind(this)
        )
    },
    removeEventListeners() {
      if (this.resetKeyDownListener && this.getResponseListRef()) {
        this.getResponseListRef().removeEventListener(
          "keydown",
          this.resetKeyDownListener
        )
      }
      if (this.resetScrollEventListener && this.getResponseListRef()) {
        this.getResponseListRef().removeEventListener(
          "scroll",
          this.resetScrollEventListener
        )
      }
    },
    isScrollHeightExceeded() {
      if (!this.getResponseListRef()) {
        return
      }
      return (
        this.getResponseListRef().scrollTop +
          this.getResponseListRef().clientHeight >=
        this.getResponseListRef().scrollHeight
      )
    },
    onKeydown(event) {
      if (!this.isScrollHeightExceeded()) return
      if (event.code == "Tab" || event.code == "ArrowDown") {
        // support tab and down arrow
        this.loadMore()
      }
    },
    onScroll() {
      if (!this.isScrollHeightExceeded()) return
      this.loadMore()
    },

    // load more items
    loadMore(count = NUM_RESPONSES_INCREMENT) {
      const lastElementFocusIndex = this.numResponsesToDisplay - 1
      this.numResponsesToDisplay = this.numResponsesToDisplay + count

      this.$nextTick(() => {
        // shift focus to last element of previous list
        const responseListItems =
          this.getResponseListRef().getElementsByClassName("response-list-item")
        if (lastElementFocusIndex < responseListItems.length) {
          responseListItems[lastElementFocusIndex].focus()
        }
      })
    },
    resetResponseListPagination() {
      this.numResponsesToDisplay = NUM_RESPONSES_INCREMENT
      this.shouldResetResponseList = true
      this.resetResponseListScrollTop()
    },
    resetResponseListScrollTop() {
      if (
        this.show &&
        this.shouldResetResponseList &&
        this.getResponseListRef()
      ) {
        this.getResponseListRef().scrollTop = 0
        this.shouldResetResponseList = false
      }
    }
  },
  watch: {
    isComponentMounted: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => {
            this.addEventListeners()
          })
        }
      }
    },
    show: function (val) {
      if (!val) return
      this.resetResponseListScrollTop()
    }
  }
}
