// Components
import Spinner from "@/components/UI/Spinner.vue"

// Helpers
import { mapActions, mapGetters } from "vuex"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"

export default {
  mixins: [ConfirmMixin],
  components: {
    Spinner
  },
  computed: {
    ...mapGetters("project", {
      project: "getProject",
      projectUpdatedPerformanceType: "getProjectUpdatedPerformanceType"
    }),
    ...mapGetters("user", {
      profile: "getProfile",
      valid: "getValid"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    isProjectOwner() {
      if (!this.profile || !this.project) return false
      return this.project.owner == this.profile.django_ref_id
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    },
    projectPerformanceType() {
      if (!this.project) return null
      if (
        this.project.settings.performanceType &&
        this.project.settings.performanceType.selected
      )
        return this.project.settings.performanceType.selected
      return null
    },
    statusOrgNotVerified() {
      return !this.valid
    }
  },
  methods: {
    ...mapActions("confirm", [
      "setConfirmIsVisible",
      "setConfirmStatus",
      "setConfirmSourceComponent",
      "setConfirmTarget",
      "setConfirmText",
      "setConfirmType",
      "resetConfirm"
    ]),
    ...mapActions("project", [
      "setProject",
      "setProjectUpdatedPerformanceType",
      "resetProject"
    ]),
    ...mapActions("loader", ["setLoading"]),
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkingProject type.
     */
    async saveProject(project) {
      if (!this.propReadOnly) {
        try {
          this.setProject(await this.$pigeonline.projects.update(project)) // this is stripping out collection_id
        } catch (e) {
          throw new Error("ProjectMixin.js:saveProject " + e.message)
        }
      }
    },
    async saveCollection(collection) {
      if (!this.propReadOnly) {
        try {
          if (this.project.access_type) {
            collection.access_type = this.project.access_type
            if (this.project.owning_team_id) {
              collection.owning_team_id = this.project.owning_team_id
            }
          }
          await this.$pigeonline.projects.update(collection)
        } catch (e) {
          throw new Error("ProjectMixin.js:saveCollection " + e.message)
        }
      }
    },
    /**
     * Helpers
     */
    // Called by PigeonCore
    updateProjectDataset(datasetID) {
      if (!this.isReadOnly || !this.propReadOnly) {
        this.project.addDataset(datasetID)
        this.project.updateStatus("datasetSelected")
        this.saveProject(this.project)
      }
    }
  }
}
