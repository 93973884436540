import { app } from "@/main.js"

import CustomTopicModelGroup from "../Models/CustomTopicModelGroup.js"
import customTopicModelService from "@/services/customTopicModelService.js"

const fetchGroups = async () => {
  try {
    const response = await app.config.globalProperties.$pigeonline.projects.get(
      CustomTopicModelGroup
    )
    return response
  } catch (e) {
    throw new Error("CustomTopicModel:fetch.js:fetchGroups " + e.message)
  }
}

const fetchGroup = async (id) => {
  try {
    const response =
      await app.config.globalProperties.$pigeonline.projects.getByID(
        CustomTopicModelGroup,
        id
      )
    return response
  } catch (e) {
    return e
  }
}

const fetchTopicModels = async (id) => {
  const CUSTOM_TOPIC_MODEL_SERVICE = new customTopicModelService(
    app.config.globalProperties.$pigeonline
  )
  try {
    const response = await CUSTOM_TOPIC_MODEL_SERVICE.get(null, {
      group_id: id
    })
    return response.length > 0 ? response.reverse() : response
  } catch (e) {
    throw new Error("CustomTopicModel:fetch.js:fetchTopicModels " + e.message)
  }
}

export { fetchGroups, fetchGroup, fetchTopicModels }
