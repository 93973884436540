<template>
  <div>
    <div
      class="ui-rounded-pill__wrapper"
      :style="{ 'background-color': theme.color }"
      style="
        font-weight: 500;
        border: none;
        height: 1rem;
        display: flex;
        flex-direction: row;
        text-align: left;
      "
    >
      <button
        @click="setThemeMatchSource(theme_index)"
        style="border: 0; color: white; display: flex; flex-direction: row"
        :style="{ 'background-color': theme.color }"
      >
        <SvgIconMeaningful
          style="
            cursor: pointer;
            display: flex;
            margin: 0;
            background-color: transparent;
          "
          class="complete-icon-svg"
          icon="list"
          title="source of match"
        />
        <VisibleText style="margin-right: 1rem">{{
          theme.theme_name
        }}</VisibleText>
      </button>

      <div
        v-if="themeMatchSource.includes(theme_index)"
        style="
          z-index: 10;
          background-color: white;
          border-top: 0;
          border-radius: 0 0 4px 4px;
          color: var(--base-colour);
          position: absolute;
          margin-top: 10rem;

          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
          border: 1px solid var(--outline-colour);
          border-radius: 8px;
        "
        :style="theme_index == theme_length - 1 ? 'right:3rem' : ''"
      >
        <div
          style="
            margin-top: -0.5rem;
            margin-bottom: 0.5rem;
            background: var(--outline-light-colour);
            border-bottom: 1px solid var(--outline-light-colour);
            padding: 0.75rem;
            font-family: Isidora, Arial, sans-serif;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          "
        >
          <VisibleText v-if="!isDropped">
            Reasons for theme match:
          </VisibleText>
          <VisibleText v-if="isDropped"> Theme ignored: </VisibleText>
          <button
            style="
              border: 1px solid var(--outline-colour);
              background: var(--secondary-ui-colour);
              border-radius: 4px;
              margin-left: 0.5rem;
              width: 1.5rem;
              height: 1.5rem;
            "
            @click="setThemeMatchSource(theme_index)"
          >
            <SvgIconMeaningful
              style="cursor: pointer; width: 1rem; margin-left: -0.05rem"
              class="complete-icon-svg"
              icon="remove"
              title="close"
            />
          </button>
        </div>
        <div style="padding: 1rem">
          <div
            v-if="noteManualMatches(theme.theme_name)"
            style="margin-bottom: 0.5rem"
          >
            <VisibleText> Theme added to entire text </VisibleText>
            <button
              style="
                border: 1px solid var(--outline-colour);
                background: var(--secondary-ui-colour);
                margin-top: -2rem;
                border-radius: 4px;
                margin-left: 0.5rem;
                width: 1.5rem;
                height: 1.5rem;
              "
              :disabled="propReadOnly"
              @click="dropNoteFromResponse(theme.theme_id)"
            >
              <SvgIconMeaningful
                style="width: 1rem; margin-left: -0.05rem; color: black"
                :style="
                  propReadOnly ? 'cursor: not-allowed' : 'cursor: pointer;'
                "
                class="complete-icon-svg"
                icon="minus"
                title="remove this manual match"
              />
            </button>
          </div>
          <div
            v-if="noteMatches(theme.theme_name)"
            style="margin-bottom: 0.5rem"
          >
            <VisibleText> Sentence match </VisibleText>
          </div>
          <div
            v-if="keywordMatches(theme.theme_name)"
            style="display: flex; flex-direction: row"
          >
            <div v-if="!isDropped" style="margin-bottom: 0.5rem">
              <VisibleText> Keyword matches: </VisibleText>
              <span>{{ keywordMatches(theme.theme_name) }}</span>
            </div>
            <div v-if="isDropped" style="margin-bottom: 0.5rem">
              <VisibleText> Ignoring keywords: </VisibleText>
            </div>
            <button
              style="
                border: 1px solid var(--outline-colour);
                background: var(--secondary-ui-colour);
                border-radius: 4px;
                margin-left: 0.5rem;
                width: 1.5rem;
                height: 1.5rem;
              "
              :disabled="propReadOnly"
              @click="dropFromKeywordMatch(theme.theme_id)"
            >
              <SvgIconMeaningful
                style="width: 1rem; margin-left: -0.05rem"
                :style="
                  propReadOnly ? 'cursor: not-allowed' : 'cursor: pointer;'
                "
                class="complete-icon-svg"
                :propReadOnly="propReadOnly"
                :icon="!isDropped ? 'minus' : 'add'"
                :title="
                  !isDropped ? 'ignore keywords' : 'stop ignoring keywords'
                "
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

export default {
  name: "ResponseTheme",
  components: {
    SvgIconMeaningful
  },
  props: {
    theme_index: {
      type: Number,
      default: () => -1
    },
    theme: {
      type: Object,
      default: () => {}
    },
    theme_length: {
      type: Number,
      default: () => -1
    },
    highlightedNotes: {
      type: Array,
      default: () => []
    },
    highlightedKeywords: {
      type: Array,
      default: () => []
    },
    isDropped: {
      type: Boolean,
      default: () => false
    },
    responseContent: {
      type: String,
      default: () => ""
    },
    propReadOnly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      themeMatchSource: [],
      themeIgnoredMatchSource: []
    }
  },
  computed: {},
  async mounted() {},
  methods: {
    setThemeMatchSource(index) {
      let filteredThemes = []
      filteredThemes = this.themeMatchSource.filter((theme) => theme == index)
      if (filteredThemes.length) {
        this.themeMatchSource = this.themeMatchSource.filter(
          (theme) => theme != index
        )
      } else {
        this.themeMatchSource = []
        this.themeMatchSource.push(index)
      }
      this.themeIgnoredMatchSource = []
    },
    // The theme reason is from keyword matches
    keywordMatches(theme_name) {
      let found_themes = 0
      if (this.highlightedKeywords) {
        for (let i = 0; i < this.highlightedKeywords.length; i++) {
          if (this.highlightedKeywords[i].theme_name == theme_name) {
            found_themes =
              found_themes +
              this.highlightedKeywords[i].matching_reasons.filter((reason) =>
                this.responseContent
                  .toLowerCase()
                  .includes(reason.toLowerCase())
              ).length
          }
        }
      }
      return found_themes
    },
    // The theme reason is from pressing the + and attaching a note theme to a whole response
    noteManualMatches(theme_name) {
      let found_themes = 0
      if (this.highlightedNotes) {
        for (let i = 0; i < this.highlightedNotes.length; i++) {
          if (this.highlightedNotes[i].theme_name == theme_name) {
            found_themes =
              found_themes +
              this.highlightedNotes[i].matching_reasons.filter(
                (reason) => reason == ""
              ).length
          }
        }
      }
      return found_themes
    },
    // The theme reason is from pressing adding a note directly to text within the response
    noteMatches(theme_name) {
      let found_themes = 0
      if (this.highlightedNotes) {
        for (let i = 0; i < this.highlightedNotes.length; i++) {
          if (this.highlightedNotes[i].theme_name == theme_name) {
            found_themes =
              found_themes +
              this.highlightedNotes[i].matching_reasons.filter(
                (reason) => reason !== ""
              ).length
          }
        }
      }
      return found_themes
    },
    toggleTheme(theme_id) {
      this.$emit("toggleTheme", theme_id)
    },
    dropNoteFromResponse(theme_id) {
      this.$emit("dropNoteFromResponse", theme_id)
      this.themeMatchSource = []
      this.themeIgnoredMatchSource = []
    },
    async dropFromKeywordMatch(theme_id) {
      this.themeMatchSource = []
      this.themeIgnoredMatchSource = []
      this.$emit("dropFromKeywordMatch", theme_id)
    }
  },
  watch: {}
}
</script>
