<template>
  <dl class="key-value">
    <div style="display: flex; flex-direction: column">
      <div style="display: flex; flex-direction: row; width: 100%">
        <div
          class="key-value__item custom-text-model-editor__source"
          style="width: 100%; display: flex; flex-direction: column"
        >
          <div>
            <VisibleText style="color: var(--base-colour-light)"
              >Source</VisibleText
            >
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-top: 1rem;
              width: 50%;
            "
          >
            <div
              v-if="localTopicModelGroup && localTopicModelGroup.source"
              style="display: flex; margin: 0 2rem 0 0"
            >
              <input
                aria-label="name"
                class="form-input--width--100 form-input--margin--none"
                type="text"
                id="source"
                placeholder="name"
                v-model="localTopicModelGroup.source.name"
                style="width: 15vw"
                :disabled="isPublicReadOnly"
              />
            </div>
            <div v-if="localTopicModelGroup && localTopicModelGroup.source">
              <input
                aria-label="url"
                class="form-input--width--100 form-input--margin--none"
                type="text"
                id="source-url"
                placeholder="url of source"
                v-model="localTopicModelGroup.source.url"
                style="width: 15vw"
                :disabled="isPublicReadOnly"
              />
            </div>
          </div>
        </div>
        <div class="key-value__item">
          <dt><VisibleText>Language</VisibleText></dt>
          <dd style="margin-top: 0.5rem">
            <div v-if="localTopicModelGroup && localTopicModelGroup.lang">
              <select
                aria-label="language"
                class="select"
                id="select-access-level"
                v-model="localTopicModelGroup.lang"
                style="height: 2.2rem"
                :disabled="isPublicReadOnly"
              >
                <option
                  v-for="lang in Object.keys(LANG_NAME)"
                  :key="lang"
                  :value="lang"
                  :data-parent="$options.name"
                >
                  {{ translate(LANG_NAME[lang], $options.name) }}
                </option>
              </select>
            </div>
          </dd>
        </div>

        <div class="key-value__item" style="width: 50%">
          <dt><VisibleText>Tags</VisibleText></dt>
          <dd>
            <div v-if="localTopicModelGroup && localTopicModelGroup.tags">
              <TaggingList
                :tags="localTopicModelGroup.tags"
                @remove="removeTag"
                :disabledInteraction="isPublicReadOnly"
              />
              <button
                class="btn-secondary"
                style="padding-top: 0"
                @click="isNewTagShowing = true"
                v-if="!isPublicReadOnly"
              >
                <VisibleText>add tag</VisibleText>
              </button>

              <div v-if="isNewTagShowing">
                <input
                  aria-label="new tag"
                  class="form-input--width--100 inline-edit-text__input"
                  type="text"
                  @keydown.enter.prevent="addTag"
                  v-model="newTag"
                />
                <ButtonIconOnly
                  class="inline-edit-text__action-btn"
                  icon="check"
                  id="inline-edit-save"
                  @click-handler="addTag"
                >
                  <VisibleText>save</VisibleText>
                </ButtonIconOnly>
                <ButtonIconOnly
                  class="inline-edit-text__action-btn"
                  icon="remove"
                  id="inline-edit-cancel"
                  @click-handler="isNewTagShowing = false"
                >
                  <VisibleText>cancel</VisibleText>
                </ButtonIconOnly>
              </div>
            </div>
          </dd>
        </div>
      </div>
    </div>
  </dl>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"
//import CustomTopicModelGroup from "../Models/CustomTopicModelGroup.js"
import { LANG_NAME, ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

export default {
  name: "CustomTopicModelEditorDetails",
  components: {
    ButtonIconOnly,
    TaggingList
  },
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    topicModelGroup: {
      type: Object,
      default: () => {}
    },
    isPublicReadOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      LANG_NAME: LANG_NAME,
      isNewTagShowing: false,
      newTag: "",
      localTopicModelGroup: {
        tags: [],
        source: {
          name: "",
          url: "",
          lang: ""
        }
      }
    }
  },
  created() {
    this.localTopicModelGroup = this.topicModelGroup
  },
  methods: {
    addTag() {
      this.localTopicModelGroup.tags.push(this.newTag)
      this.newTag = ""
    },
    removeTag(tag) {
      const indx = this.localTopicModelGroup.tags.indexOf(tag)
      if (indx === -1) return
      this.localTopicModelGroup.tags.splice(indx, 1)
    }
  },
  watch: {
    topicModelGroup: {
      deep: true,
      handler: function (value) {
        if (value && Object.keys(value).length > 0)
          this.localTopicModelGroup = this.topicModelGroup
      }
    }
  }
}
</script>
