import { HELPER_TRANSLATIONS, LABEL_TRANSLATIONS } from "../../../translations"
import { formatPerformanceChartData } from "./helpers"

/*******************************
 *
 * Generate performance analysis chart node
 * @param {String} performanceTitle of the performance analysis chart
 * @param {Array} performanceChartData performance analysis chart data from analysis/performance
 * @returns {Object} pigeondoc chart node
 *
 ******************************/
const performanceSimpleScoreChart = (
  performanceTitle,
  performanceChartData,
  lang,
  threshold,
  chartType,
  alternative_x,
  alternative_y,
  alternative_x_hover,
  add_suffix
) => {
  const chartData = formatPerformanceChartData(performanceChartData, threshold)
  let newTitle = ""
  let newSuffix = ""
  if (add_suffix) {
    newSuffix = add_suffix
  }

  if (performanceTitle == "") {
    newTitle = `<span class='performace-chart-title'>${HELPER_TRANSLATIONS.performanceTitle[lang]}</span>`
  } else {
    newTitle = `<span class='performace-chart-title'>${performanceTitle}</span>`
  }
  if (threshold > -1) {
    newTitle = newTitle + " | % of those who scored above " + threshold
    newSuffix = "%"
  }

  // if segments, add sub-heading

  let thisChartType = "horizontalBarChart"
  if (chartType == "VerticalBarChart") {
    thisChartType = "verticalBarChart"
  } else if (chartType == "HorizontalBarChart") {
    thisChartType = "horizontalBarChart"
  }
  const chart = {
    type: thisChartType,
    content: {
      title: newTitle,
      data: chartData
    },
    id: "performance-chart",
    meta: {
      headingLevel: "div",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: LABEL_TRANSLATIONS.performanceScore[lang],
        y: LABEL_TRANSLATIONS.questions[lang]
      },
      axisHoverText: {
        x: HELPER_TRANSLATIONS.performanceScoreAverage[lang]
      },
      suffix: newSuffix,
      scoreCutoff: threshold
    }
  }
  if (alternative_x) {
    chart.meta.axisLabels.x = LABEL_TRANSLATIONS[alternative_x][lang]
  }
  if (alternative_y) {
    chart.meta.axisLabels.y = LABEL_TRANSLATIONS[alternative_y][lang]
  }
  if (alternative_x_hover) {
    chart.meta.axisHoverText.x = HELPER_TRANSLATIONS[alternative_x_hover][lang]
  }
  return chart
}

export { performanceSimpleScoreChart }
