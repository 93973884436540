<template>
  <Modal
    id="share-project"
    ref="share-modal"
    uid="share-project-modal"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <Spinner id="share-spinner" :component-style="true" :isLoading="isSaving" />

    <template #header>
      <VisibleText>
        {{ currViewIdx == 0 ? "Share Project" : "Manage Permissions" }}
      </VisibleText>
    </template>

    <div style="display: flex; flex-direction: row; height: 2rem">
      <h3 style="font-size: 1rem">
        <VisibleText>
          You can share directly to users if you know their email:
        </VisibleText>
      </h3>
      <div style="display: flex; flex-direction: row; margin-left: auto">
        <button
          v-if="modalGroups && currViewIdx == modalGroups.length - 1"
          class="share__next-btn"
          style="margin-left: auto; height: 1.5rem; margin-right: 2rem"
          @click.stop="back"
        >
          <VisibleText>Back</VisibleText>
        </button>
        <button
          class="share__next-btn"
          style="margin-left: auto; height: 1.5rem"
          @click="goToView(1)"
          v-if="modalGroups && currViewIdx == 0"
        >
          <VisibleText>Next: User Permissions</VisibleText>
        </button>
        <button
          class="share__save-changes-btn"
          :aria-disabled="isSaveBtnDisabled"
          :disabled="isSaveBtnDisabled"
          style="margin-left: auto; height: 1.5rem"
          @click="saveChanges(true)"
          v-if="modalGroups && currViewIdx == modalGroups.length - 1"
        >
          <VisibleText>Save changes</VisibleText>
        </button>
      </div>
    </div>

    <ModalGroupLayout
      :showBackBtn="false"
      @back="back"
      @changed="unsavedShareChanges = true"
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 1rem;
      "
    >
      <Sharing
        :label="'Add people to start sharing your project.'"
        :shared="users"
        ref="sharing"
        style="display: flex"
        @addNewUser="addNewUser($event)"
      />
    </ModalGroupLayout>

    <ModalGroupLayout @back="back" @changed="unsavedShareChanges = true">
      <ManagePermissions
        :shared="usersShared"
        :types="SHARING_KEYS"
        :perm-labels="DEFAULT_PERMS_LABELS"
      />
    </ModalGroupLayout>

    <template #footer>
      <div style="display: flex; flex-direction: column; width: 100%">
        <div v-if="teams && teams.length && localProject">
          <div style="display: flex; flex-direction: row">
            <div style="display: flex; flex-direction: column; width: 40%">
              <h3>
                <VisibleText style="font-size: 1rem">
                  Or you can change the project's access level:
                </VisibleText>
              </h3>
              <div style="display: flex; flex-direction: row">
                <select
                  aria-label="access level"
                  class="select"
                  id="select-access-level"
                  v-model="projectAccessType"
                  style="width: 80%; margin-top: 1rem"
                  @change="changeAccessLevel"
                >
                  <option
                    v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
                    :key="level"
                    :value="level"
                    :data-parent="$options.name"
                  >
                    {{
                      translate(ACCESS_TYPE_TO_DESC[level].name, $options.name)
                    }}
                    -
                    {{
                      translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name)
                    }}
                  </option>
                </select>
                <span
                  style="
                    color: var(--secondary);
                    margin-left: 1rem;
                    margin-top: 1.25rem;
                  "
                  v-if="successfullyUpdated"
                >
                  <VisibleText> Successfully updated! </VisibleText>
                </span>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 40%;
                background-color: white;
                padding: 0.5rem 1rem;
                border: 1px solid var(--outline-colour);
                border-radius: 4px;
                color: var(--base-colour);
              "
            >
              <div style="display: flex; flex-direction: row">
                <h4>
                  <VisibleText style="font-size: 0.8rem">
                    This project's dataset access level
                  </VisibleText>
                </h4>
                <div style="margin-left: auto; font-size: 0.8rem">
                  <VisibleText> Only owner can edit </VisibleText>
                </div>
              </div>
              <div style="display: flex; flex-direction: row">
                <select
                  aria-label="access level"
                  class="select"
                  id="select-access-level"
                  v-model="localDataset.access_type"
                  style="width: 80%; margin-top: 1rem"
                  @change="changeDatasetAccessLevel"
                  :disabled="!isDatasetOwner"
                >
                  <option
                    v-for="level in Object.keys(DATASET_ACCESS_TYPE_TO_DESC)"
                    :key="level"
                    :value="level"
                    :data-parent="$options.name"
                  >
                    {{
                      translate(
                        DATASET_ACCESS_TYPE_TO_DESC[level].name,
                        $options.name
                      )
                    }}
                    -
                    {{
                      translate(
                        DATASET_ACCESS_TYPE_TO_DESC[level].desc,
                        $options.name
                      )
                    }}
                  </option>
                </select>
                <span
                  style="
                    color: var(--secondary);
                    margin-left: 1rem;
                    margin-top: 1.25rem;
                  "
                  v-if="successfullyUpdatedDataset"
                >
                  <VisibleText> Successfully updated! </VisibleText>
                </span>
              </div>

              <div
                style="margin-top: 1rem"
                v-if="localDataset.access_type == 'public__team'"
              >
                <VisibleText> For this team: </VisibleText>
                <select
                  aria-label="access level"
                  class="select"
                  id="select-access-level-team"
                  style="width: 80%; margin-top: 1rem"
                  v-model="selectedDatasetTeam"
                  @change="changeDatasetTeamAccessLevel"
                  :disabled="!isDatasetOwner"
                >
                  <option
                    v-for="team in teams"
                    :key="team.team_name"
                    :value="team"
                    :data-parent="$options.name"
                  >
                    {{ team.team_name }}
                  </option>
                </select>
              </div>
              <div
                v-if="
                  localDataset.access_type == 'public__team' ||
                  localDataset.access_type == 'public__all'
                "
                style="display: flex; flex-direction: row"
              >
                <label
                  for="set-to-readonly"
                  style="
                    margin-top: 1rem;
                    text-align: right;
                    margin-right: 1rem;
                    margin-left: 1rem;
                    font-size: 0.8rem;
                  "
                  class="form-label"
                >
                  <VisibleText>Is read only </VisibleText>:
                </label>
                <input
                  type="checkbox"
                  class="datasets__table-checkbox"
                  name="set-to-readonly"
                  id="set-to-readonly"
                  @change="changeDatasetTeamAccessLevel"
                  :disabled="!isDatasetOwner"
                  v-model="readOnlyDataset"
                  style="width: 1.3rem; height: 1.3rem; margin-top: 1rem"
                />
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; flex-direction: row; width: 100%">
          <div class="share-copy-url" style="margin-left: auto">
            <a
              role="button"
              ref="copy-url"
              class="share__copy-url-btn"
              @click="copy"
            >
              <VisibleText>Copy link</VisibleText>
            </a>
            <input
              aria-label="share url"
              type="text"
              id="share-url"
              class="share__copy-url"
              readonly
              :value="shareUrl"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import ModalGroupLayout from "./Base/ModalGroupLayout.vue"
import Sharing from "./Base/Sharing.vue"
import ManagePermissions from "./Base/ManagePermissions.vue"
import Spinner from "@/components/UI/Spinner.vue"

// Mixins
import ShareMixin from "./Mixins/shareMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"

export default {
  name: "ShareProject",
  mixins: [ShareMixin, ProjectMixin, DatasetMixin],
  components: {
    Modal,
    ModalGroupLayout,
    Sharing,
    ManagePermissions,
    Spinner
  },
  data() {
    return {
      selectedDatasetTeam: {},
      localProject: {},
      localDataset: {},
      readOnlyProject: false,
      readOnlyDataset: false,
      successfullyUpdated: false,
      successfullyUpdatedDataset: false,
      projectAccessType: "private",
      ACCESS_TYPE_TO_DESC: {
        private: {
          name: "Private",
          desc: "only you can use this project"
        },
        inherit_from_dataset: {
          name: "Inherit",
          desc: "Match the access level of this project's dataset"
        }
      },
      DATASET_ACCESS_TYPE_TO_DESC: {
        private: {
          name: "Private",
          desc: "only you can use this dataset"
        },
        public__all: {
          name: "Public",
          desc: "anyone can use this dataset"
        },
        public__team: {
          name: "Teams",
          desc: "only users in your team can use this dataset"
        }
      }
    }
  },
  props: {
    parentProject: {
      type: Object
    },
    dataset: {
      type: Object
    },
    parentCollection: {
      type: Object
    },
    parentPigeonDoc: {
      type: Object
    },
    show: {
      defult: () => false,
      type: Boolean
    },
    saveCount: {
      default: () => 0,
      type: Number
    }
  },
  computed: {
    lastModified() {
      let count = 0
      if (this.project && this.project.last_modified_on.$date) {
        count = this.project.last_modified_on.$date
      }
      return count
    },
    isDatasetOwner() {
      let isOwner = false
      if (
        this.localDataset &&
        this.profile &&
        this.profile.django_ref_id == this.localDataset.owner
      ) {
        isOwner = true
      }
      return isOwner
    },
    matchingTeam() {
      if (
        this.localDataset &&
        this.localDataset.owning_team_id &&
        this.localDataset.access_type == "public__team"
      ) {
        if (this.teams && this.teams.length) {
          let filteredTheme = this.teams.filter(
            (team) => team._id.$oid == this.localDataset.owning_team_id
          )
          if (filteredTheme.length) {
            return filteredTheme[0]
          }
        }
      }
      return false
    }
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    // The project can only inherit dataset access or be private
    async changeAccessLevel() {
      if (this.projectAccessType == "private") {
        this.localProject.access_type = "private"
        this.localProject.is_read_only = true
        this.localProject.owning_team_id = ""
      }

      if (this.projectAccessType == "inherit_from_dataset") {
        this.localProject.access_type = this.localDataset.access_type
        this.localProject.is_read_only = this.localDataset.is_read_only
        this.localProject.owning_team_id = this.localDataset.owning_team_id
      }
      if (this.parentCollection && Object.keys(this.parentCollection).length) {
        let collection = this.deepCloneObj(this.parentCollection)
        collection.access_type = this.localProject.access_type
        collection.owning_team_id = this.localProject.owning_team_id
        await this.saveCollection(collection)
      }
      if (this.parentPigeonDoc && Object.keys(this.parentPigeonDoc).length) {
        let report = this.parentPigeonDoc
        report.access_type = this.localProject.access_type
        report.owning_team_id = this.localProject.owning_team_id
        await this.$pigeonline.projects.update(report)
      }
      this.saveProject(this.localProject)
      this.successfullyUpdated = true
      setTimeout(() => (this.successfullyUpdated = false), 2000)
    },
    async changeDatasetAccessLevel() {
      if (this.localDataset.access_type != "public__team") {
        this.localDataset.owning_team_id = ""
        this.localDataset.is_read_only = this.readOnlyDataset
        await this.saveDatasetProject(this.localDataset)
        this.selectedDatasetTeam = {}
        this.successfullyUpdatedDataset = true
        if (this.projectAccessType == "inherit_from_dataset") {
          this.changeAccessLevel()
        }
        setTimeout(() => (this.successfullyUpdatedDataset = false), 2000)
      }
    },
    async changeDatasetTeamAccessLevel() {
      if (
        this.localDataset.access_type == "public__team" &&
        this.selectedDatasetTeam
      ) {
        // If it does not exist, we need to add
        this.localDataset.owning_team_id = this.selectedDatasetTeam._id.$oid
        this.localDataset.is_read_only = this.readOnlyDataset
        await this.saveDatasetProject(this.localDataset)
        this.successfullyUpdatedDataset = true
        if (this.projectAccessType == "inherit_from_dataset") {
          this.changeAccessLevel()
        }
        setTimeout(() => (this.successfullyUpdatedDataset = false), 2000)
      }
      if (this.localDataset.access_type == "public__all") {
        this.localDataset.owning_team_id = ""
        this.localDataset.is_read_only = this.readOnlyDataset
        await this.saveDatasetProject(this.localDataset)
        this.successfullyUpdatedDataset = true
        if (this.projectAccessType == "inherit_from_dataset") {
          this.changeAccessLevel()
        }
        setTimeout(() => (this.successfullyUpdatedDataset = false), 2000)
      }
    },
    refreshProject() {
      this.localProject = this.parentProject
      if (this.localProject._id) {
        delete this.localProject._id
      }
      this.localDataset = this.dataset

      if (this.localDataset.is_read_only) {
        this.readOnlyDataset = this.localDataset.is_read_only
      }
      if (this.localProject.access_type != "private") {
        this.projectAccessType = "inherit_from_dataset"
      }
    }
  },
  watch: {
    dataset: function () {
      this.mount()
      this.refreshProject()
      // this.localProject = this.parentProject
      // this.localDataset = this.connectedDataset

      // if (this.localDataset.is_read_only) {
      //   this.readOnlyDataset = this.localDataset.is_read_only
      // }
      // if (this.localProject.access_type != "private") {
      //   this.projectAccessType = "inherit_from_dataset"
      // }
    },
    lastModified: function (val) {
      if (val) {
        this.refreshProject()
      }
    },
    matchingTeam: function (val) {
      if (val) {
        this.selectedDatasetTeam = val
      }
    },
    selectedDatasetTeam: function (val) {
      if (val) {
        if (
          this.localDataset.access_type == "public__team" &&
          this.isDatasetOwner
        ) {
          this.changeDatasetTeamAccessLevel()
        }
      }
    }
  }
}
</script>
