<template>
  <Accordion
    title-element-type="span"
    class="dataset-table__values-wrapper"
    :style="{ height: contentHeight }"
    :iconColor="'white'"
    :expandedIconColor="'#f2f2f2'"
    @toggled="toggled"
  >
    <template #heading>
      <span ref="btn" class="sr-only"> show row {{ index }} values </span>
    </template>
    <div :ref="`${clientQuestion._id.$oid}-content`" v-if="clientQuestion">
      <ValuesScale
        :client-question-id="clientQuestion._id.$oid"
        :unique-values="clientQuestion.unique_values"
        :hide-options="hideOptions"
        v-if="isScaleType"
      />
      <ValuesList
        v-if="clientQuestion._id.$oid && !isScaleType"
        :client-question-id="clientQuestion._id.$oid"
        :is-category-type="isCategoryType"
        :unique-values="clientQuestion.unique_values"
        :approved-data-type="clientQuestion.approved_data_type"
        :hide-options="hideOptions"
      />
    </div>
  </Accordion>
</template>

<script>
import Accordion from "@/components/UI/Accordion.vue"
import { SCALE_TYPE_TO_READABLE } from "@/utils/consts/constsDataTypes.js"

import ValuesList from "./ValuesList.vue"
import ValuesScale from "./ValuesScale.vue"

export default {
  name: "Values",
  props: {
    approvedDataType: {
      default: () => null,
      type: String
    },
    clientQuestionId: {
      type: String,
      default: () => {},
      required: true
    },
    index: {
      default: () => null,
      type: Number
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    }
  },
  components: {
    Accordion,
    ValuesList,
    ValuesScale
  },
  data() {
    return {
      contentHeight: "100%",
      expanded: false
    }
  },
  mounted() {
    // set initial index to -1 for keyboard accessibility within a grid
    if (this.$refs["btn"]) {
      this.$refs["btn"].parentElement.tabIndex = "-1"
    }
  },
  computed: {
    clientQuestion() {
      return this.$store.getters["datasetDetails/getClientQuestions"].find(
        (q) => q._id.$oid === this.clientQuestionId
      )
    },
    isScaleType() {
      return Object.keys(SCALE_TYPE_TO_READABLE).includes(this.approvedDataType)
    },
    isCategoryType() {
      return this.approvedDataType && this.approvedDataType === "CAT"
    }
  },
  methods: {
    toggled() {
      this.$nextTick(() => {
        let height = "100%"
        if (this.$refs[`${this.clientQuestion._id.$oid}-content`]) {
          height =
            this.$refs[`${this.clientQuestion._id.$oid}-content`].clientHeight
        }
        this.expanded = !this.expanded
        this.contentHeight = this.expanded ? `${height + 200}px` : height
        this.$emit("details-toggled", this.expanded, this.index)
      })
    }
  }
}
</script>
