<template>
  <div>
    <!--------------------------------------------
    |
    | SEARCH AND FILTER
    |
    --------------------------------------------->
    <DatasetTableSearchAndFilter
      :suggested-data-types="suggestedDataTypes"
      :refresh-filter="isRefreshFilter"
      :questionsWithValid="questionsWithValid"
      :showNoType="true"
      @results="filteredResults"
      @reset="resetFilters"
    />

    <!--------------------------------------------
    |
    | OPTIONS
    |
    --------------------------------------------->
    <DatasetTableOptions
      :selected-client-questions="clientQuestionsSelected"
      @select="selectAll"
      @deselect="deselect"
      @setComplete="$emit('setComplete')"
      @refresh="refreshFilterAndDeselect"
      @toggle-editing="isEditing = !isEditing"
      :isDatasetReadOnly="isDatasetReadOnly"
      v-if="!hideOptions"
    />

    <!--------------------------------------------
    |
    | MESSAGES
    |
    --------------------------------------------->

    <!--------------------------------------
    | when get suggested data types is clicked
    ---------------------------------------->
    <div
      class="dataset-table__message"
      v-if="
        dataTypeQueue.finished &&
        $store.getters['datasetWizard/getActiveSection'] === 'DatasetCleaner'
      "
    >
      <p>
        <span v-if="Object.keys(suggestedDataTypes).length > 0">
          {{
            translate("We automatically found data types for", $options.name)
          }}
          <strong>
            {{ Object.keys(suggestedDataTypes).length }}
          </strong>
          {{ translate("questions", $options.name) }}.
          <strong>
            {{ approvedDataTypes }}
            {{ translate("confirmed", $options.name) }}.
          </strong>
        </span>
        <VisibleText v-else>All data types confirmed.</VisibleText>
      </p>
    </div>

    <div
      v-if="
        $store.getters['datasetWizard/getActiveSection'] === 'DatasetCleaner'
      "
    >
      <p class="dataset-table__message" v-if="dataTypeQueue.running.length > 0">
        <span class="spinner--small spinner--inline" aria-hidden="true"></span>
        <VisibleText>Fetching suggested types.</VisibleText>
      </p>
    </div>

    <!--------------------------------------
    | when get suggested global matches is clicked
    ---------------------------------------->
    <div
      v-if="
        $store.getters['datasetWizard/getActiveSection'] === 'DatasetBenchmarks'
      "
    >
      <p
        class="dataset-table__message"
        v-if="globalMatchesQueue.running.length > 0"
      >
        <span class="spinner--small spinner--inline" aria-hidden="true"></span>
        <VisibleText>Fetching global matches.</VisibleText>
      </p>
      <p
        class="dataset-table__message"
        v-if="Object.keys(suggestedGlobalMatches).length > 0"
      >
        {{
          translate("We automatically found global matches for", $options.name)
        }}
        <strong>
          {{ Object.keys(suggestedGlobalMatches).length }}
        </strong>
        {{ translate("questions", $options.name) }}.
        <strong>
          {{ approvedGlobalMatches }}
          {{ translate("confirmed by you", $options.name) }}.
        </strong>
      </p>
      <VisibleText
        class="dataset-table__message"
        v-if="
          globalMatchesQueue.finished &&
          Object.keys(suggestedGlobalMatches).length === 0
        "
      >
        No global matches found.
      </VisibleText>
    </div>

    <!--------------------------------------
    | when select all is clicked
    ---------------------------------------->
    <div v-if="clientQuestionsSelected.length > 0">
      <p
        class="dataset-table__message"
        v-if="clientQuestionsSelected.length === pagination.totalQuestions"
      >
        {{ clientQuestionsSelected.length }}
        {{ translate("questions selected", $options.name) }}
      </p>
      <p class="dataset-table__message" v-else>
        <strong> {{ clientQuestionsSelected.length }} </strong>
        {{ translate("questions on this page are selected", $options.name) }}.
        <button
          class="btn-secondary"
          type="button"
          @click="onClickSelectAllQuestions"
        >
          {{ translate("Select all", $options.name) }}
          {{ pagination.totalQuestions }}
          {{ translate("questions", $options.name) }}.
        </button>
      </p>
    </div>

    <!--------------------------------------------
    |
    | DATA TABLE
    |
    --------------------------------------------->
    <div
      id="dataset-table"
      class="dataset__section-group-content light-scroll-bar"
      style="font-size: 0.9rem"
    >
      <ListGrid
        class="dataset-table__data"
        :aria-label="`${dataset.name} data`"
        :id="listGridUUID"
        :customGridStyle="{ gridTemplateColumns: customColumns }"
        :headers="
          activeSection == 'DatasetCleaner'
            ? listGridHeadersNoBM
            : listGridHeaders
        "
        :list="clientQuestionItems"
        :key="listGridRefreshKey"
      >
        <template #default="{ item, index }">
          <ListGridItem col="0" :headers="listGridHeaders" :row="index">
            <span class="form-label form-checkbox-label" v-if="!hideOptions">
              <input
                v-if="!item.benchmarked_global_question"
                type="checkbox"
                tabindex="-1"
                :id="`input-${item._id.$oid}`"
                :value="item._id.$oid"
                :ref="index == 0 ? 'first-input' : null"
                v-model="clientQuestionsSelected"
              />
              <label
                :for="`input-${item._id.$oid}`"
                :id="`row-${item._id.$oid}`"
              >
                {{ item.question_title }}
              </label>
            </span>
            <span :id="`row-${item._id.$oid}`" v-else>
              {{ item.question_title }}
            </span>
          </ListGridItem>
          <ListGridItem
            class="question-text"
            col="1"
            :headers="listGridHeaders"
            :row="index"
            :rowuid="`row-${item._id.$oid}`"
          >
            <QuestionText
              v-if="item._id.$oid"
              :client-question-id="item._id.$oid"
              :question-text="item.question_text"
              :read-only="readOnly && !isEditing"
            />
          </ListGridItem>
          <ListGridItem
            class="global-match"
            v-if="activeSection !== 'DatasetCleaner'"
            col="2"
            :headers="listGridHeaders"
            :row="index"
            :rowuid="`row-${item._id.$oid}`"
            :focus-cell="true"
          >
            <GlobalMatch
              v-if="
                benchmarkGroup &&
                (!benchmarkGroup.message ||
                  benchmark.message !==
                    'You do not have permission to perform this action.')
              "
              :benchmark-group="benchmarkGroup"
              :client-question-id="item._id.$oid"
              :confirmed-global-question-match="
                matchesDetails[item._id.$oid] || null
              "
              :detailsForSharingWithBenchmarking="
                detailsForSharingWithBenchmarking
              "
              :read-only="readOnlyGlobalMatch"
              :suggested-top-match="suggestedGlobalMatches[item._id.$oid]"
              :approvedType="item.approved_data_type"
              @modify-global-match="$emit('set-modifying-match', item)"
              @approved-global-match="updateLocalGlobalMatches"
            />
          </ListGridItem>
          <ListGridItem
            col="3"
            :headers="listGridHeaders"
            :row="index"
            :rowuid="`row-${item._id.$oid}`"
          >
            <DataType
              :ref="'dataType' + item._id.$oid"
              v-if="!item.benchmarked_global_question"
              :client-question="item"
              :suggested-type="suggestedDataTypes[item._id.$oid]"
              :read-only="readOnlyDataType"
              :isDatasetReadOnly="isDatasetReadOnly"
              @setComplete="$emit('setComplete')"
              @approved-data-type="updateApprovedMatches"
              @on-change-data-type="onChangeDataType($event, index)"
              @fetch-lemmas="fetchLemmas"
              @fetch-sentiments="fetchSentiments"
              @fetch-sentence-tokens="fetchSentenceTokens"
              :activeSection="activeSection"
            />
            <div v-if="item.benchmarked_global_question">
              <div style="padding: 0.5rem">
                <VisibleText>{{
                  TYPE_TO_READABLE[item.approved_data_type] ||
                  SCALE_TYPE_TO_READABLE[item.approved_data_type]
                }}</VisibleText>
              </div>

              <VisibleText v-if="isEditing"
                >Please "remove match" from benchmarking group before
                editing.</VisibleText
              >
            </div>
          </ListGridItem>
          <ListGridItem
            style="overflow: hidden"
            col="4"
            :headers="listGridHeaders"
            :row="index"
            :rowuid="`row-${item._id.$oid}`"
          >
            <Values
              v-if="item._id.$oid"
              :approved-data-type="item.approved_data_type"
              :client-question-id="item._id.$oid"
              :index="index"
              :hide-options="
                hideOptions ||
                (item.benchmarked_global_question &&
                  Object.keys(item.benchmarked_global_question).length > 0)
              "
              @details-toggled="detailsToggled"
            />
          </ListGridItem>
        </template>
      </ListGrid>

      <div class="dataset-table__footer">
        <button
          class="btn-nobg--secondary"
          type="button"
          :disabled="clientQuestionItems.length === pagination.totalQuestions"
          @click="onClickLoadMoreQuestions"
        >
          <VisibleText>load more questions</VisibleText>
        </button>
        <button class="btn-nobg--gray" type="button" @click="onClickBackToTop">
          <VisibleText>back to top</VisibleText>
        </button>
        <span class="page-details">
          {{ translate("Showing", $options.name) }}
          {{ clientQuestionItems.length }} / {{ pagination.totalQuestions }}
          {{ translate("questions", $options.name) }}
        </span>
      </div>
    </div>

    <!--------------------------------------------
    |
    | COPYING DETAILS
    |
    --------------------------------------------->
    <CopyDetailsPannel
      :client-question-id="clientQuestionsSelected[0]"
      v-if="!hideOptions && clientQuestionsSelected.length"
    />
  </div>
</template>

<script>
// Components
import DatasetTableOptions from "./DatasetTableOptions/DatasetTableOptions.vue"
import DatasetTableSearchAndFilter from "./DatasetTableSearchAndFilter.vue"
import Values from "./Components/Values/Values.vue"
import DataType from "./Components/DataType.vue"
import GlobalMatch from "./Components/GlobalMatch.vue"
import QuestionText from "./Components/QuestionText.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// TODO Rebuild
const CopyDetailsPannel = () => ({
  component: import("./Components/CopyDetailsPannel.vue"),
  loading: LoadingSpinner
})

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

// Models
import GlobalMatchesPromiseQueue from "../../Utils/GlobalMatchesPromiseQueueModel.js"
import PromiseQueue from "@/models/PromiseQueueModel.js"

// Fetch
import {
  fetchRecommendedGlobalQuestions,
  fetchRecommendedDataType
} from "../../Utils/fetch.js"

import DataTypeMixin from "@/components/Dataset/Mixins/datatypeMixin.js"
import { SCALE_TYPE_TO_READABLE } from "@/utils/consts/constsDataTypes.js"

export default {
  name: "DatasetTable",
  mixins: [DatasetDetailsMixin, DataTypeMixin],
  props: {
    activeSection: {
      default: "",
      type: String
    },
    hideOptions: {
      default: false,
      type: Boolean
    },
    readOnly: {
      // used for editing, it means is the bulk edit on or off
      default: false,
      type: Boolean
    },
    isDatasetReadOnly: {
      default: () => false,
      type: Boolean
    },
    refreshPagination: {
      default: false,
      type: Boolean
    },
    detailsForSharingWithBenchmarking: {
      default: () => {},
      type: Object
    },
    benchmarkGroup: {
      type: BenchmarkGroup
    }
  },
  components: {
    DatasetTableSearchAndFilter,
    CopyDetailsPannel,
    DataType,
    GlobalMatch,
    DatasetTableOptions,
    QuestionText,
    Values,
    ListGrid,
    ListGridItem
  },
  data() {
    return {
      SCALE_TYPE_TO_READABLE_KEYS: Object.keys(SCALE_TYPE_TO_READABLE),

      // list grid
      listGridHeaders: [
        "question code",
        "question text",
        "global match",
        "data type",
        "values"
      ],
      listGridHeadersNoBM: [
        "question code",
        "question text",
        "data type",
        "values"
      ],
      listGridUUID: this.$pigeonline.createUUID(),
      listGridRefreshKey: 0,

      // keeping track of the number of suggestions that were approved
      approvedDataTypes: 0,
      approvedGlobalMatches: 0,

      // client question subsets
      clientQuestionsSelected: [],
      clientQuestionsFilteredResults: [],

      // ui flags
      isEditing: false,
      isActiveFilter: false,
      isRefreshFilter: false,

      // pagination
      pagination: {
        endIndx: 20,
        limit: 20, //limit of how many are shown in a page
        startIndx: 0,
        totalQuestions: 0
      },

      // queues
      dataTypeQueue: new PromiseQueue({ concurrent: 3 }),
      globalMatchesQueue: new GlobalMatchesPromiseQueue({ concurrent: 2 })
    }
  },
  computed: {
    customColumns() {
      if (this.activeSection == "DatasetCleaner") {
        return "15% 1fr 50% 6%"
      }
      return "15% 1fr 1fr 20% 6%"
    },
    clientQuestionItems() {
      if (this.isActiveFilter) {
        return this.clientQuestionsFilteredResults.slice(
          this.pagination.startIndx,
          this.pagination.endIndx
        )
      }
      return this.clientQuestions.slice(
        this.pagination.startIndx,
        this.pagination.endIndx
      )
    },
    questionsWithValid() {
      let uniqueTypes = []
      const clientQuestionItems = this.deepCloneObj(this.clientQuestionItems)
      if (clientQuestionItems) {
        for (let i = 0; i < clientQuestionItems.length; i++) {
          if (
            clientQuestionItems[i].approved_data_type &&
            !uniqueTypes.includes(clientQuestionItems[i].approved_data_type)
          ) {
            uniqueTypes.push(clientQuestionItems[i].approved_data_type)
          }
        }
      }
      return uniqueTypes
    },
    toFetchSuggestedDataType() {
      return this.$store.getters["datasetWizard/getToFetchSuggestedDataType"]
    },
    toFetchSuggestedMatches() {
      return this.$store.getters["datasetWizard/getToFetchSuggestedMatches"]
    },

    // suggested values
    suggestedDataTypes() {
      return this.$store.getters["datasetWizard/getActiveSection"] ===
        "DatasetCleaner"
        ? Object.assign({}, ...this.dataTypeQueue.complete)
        : {}
    },
    suggestedGlobalMatches() {
      return Object.assign({}, ...this.globalMatchesQueue.complete)
    },

    // set read only for specific columns depending on what section is active
    readOnlyDataType() {
      return (
        !this.isEditing &&
        this.readOnly &&
        this.$store.getters["datasetWizard/getActiveSection"] !==
          "DatasetCleaner"
      )
    },
    readOnlyGlobalMatch() {
      return (
        !this.isEditing &&
        this.readOnly &&
        this.$store.getters["datasetWizard/getActiveSection"] !==
          "DatasetBenchmarks"
      )
    }
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    /**
     * table actions
     **/
    onClickBackToTop() {
      if (document.getElementById("dataset-table"))
        document
          .getElementById("dataset-table")
          .scroll({ top: 0, behavior: "smooth" })
      this.$refs["first-input"].focus()
    },
    onClickLoadMoreQuestions() {
      this.pagination.endIndx = this.pagination.endIndx + this.pagination.limit
    },
    onClickSelectAllQuestions() {
      const qs = this.isActiveFilter
        ? this.clientQuestionsFilteredResults
        : this.clientQuestions
      this.clientQuestionsSelected = qs.map((q) => q._id.$oid)
    },
    onChangeDataType() {
      if (this.isActiveFilter) {
        this.isRefreshFilter = !this.isRefreshFilter
      }
    },
    detailsToggled(expanded, index) {
      expanded
        ? document
            .getElementById(this.listGridUUID)
            .querySelector(`div.list-grid-item-${index}`)
            .classList.add("expanded")
        : document
            .getElementById(this.listGridUUID)
            .querySelector(`div.list-grid-item-${index}`)
            .classList.remove("expanded")
    },
    filteredResults(results, refreshPagination) {
      if (refreshPagination) this.resetPagination()
      this.clientQuestionsFilteredResults = results
      this.pagination.totalQuestions = results.length
      this.isActiveFilter = true
    },
    refreshFilterAndDeselect() {
      // if there are active filters, re-run the filters
      if (this.isActiveFilter) this.isRefreshFilter = !this.isRefreshFilter
      this.deselect()
    },
    resetFilters() {
      this.resetPagination()
      this.isActiveFilter = false
      this.listGridRefreshKey++
    },
    resetPagination() {
      this.pagination = {
        endIndx: 20,
        limit: 20, //limit of how many are shown in a page
        startIndx: 0,
        totalQuestions: this.clientQuestions.length
      }
    },
    deselect() {
      this.clientQuestionsSelected = []
    },
    selectAll() {
      this.clientQuestionsSelected = this.clientQuestionItems.map(
        (question) => question._id.$oid
      )
    },
    /**
     * promise queue actions & helpers
     **/
    async setupQueue(filter, fetch) {
      const filteredClientQs = this.clientQuestions.reduce(
        (questionIds, question) => {
          if (filter(question)) questionIds.push(question._id.$oid)
          return questionIds
        },
        []
      )
      if (filteredClientQs.length === 0) return
      let p = 0,
        i = 0
      let totalGroups = Math.ceil(
        filteredClientQs.length / this.pagination.limit
      )
      while (p < totalGroups + 1) {
        let sliced = filteredClientQs.slice(
          i,
          i + Math.min(this.pagination.limit, filteredClientQs.length)
        )
        fetch(sliced)
        i = i + Math.min(this.pagination.limit, filteredClientQs.length)
        p++
      }
    },
    async runDataTypeQueue() {
      // This function 'crashes' the data cleaning wizard if the BE returns an error

      // non-empty values and does not have an approved data type
      const filter = (question) =>
        !question.approved_data_type && question.unique_values.length > 0

      const fetch = (slicedQs) => {
        if (slicedQs && slicedQs.length) {
          // this is a Promise
          this.dataTypeQueue.todo.push(() =>
            fetchRecommendedDataType({
              data_set_id: this.dataset._id.$oid,
              client_question_ids: slicedQs
            })
          )
        }
      }
      await this.setupQueue(filter, fetch)
      if (this.dataTypeQueue.todo.length === 0) {
        this.dataTypeQueue.finished = true
      } else {
        this.dataTypeQueue.runConcurrent()
      }
    },
    async runMatchesQueue() {
      // approved data type is a scale question & does not already have a match
      const filter = (question) =>
        question.approved_data_type &&
        this.SCALE_TYPE_TO_READABLE_KEYS.includes(
          question.approved_data_type
        ) &&
        !this.matchesDetails[question._id.$oid]
      const fetch = (slicedQs) => {
        if (slicedQs.length) {
          this.globalMatchesQueue.todo.push(() =>
            fetchRecommendedGlobalQuestions({
              client_questions_ids: slicedQs,
              benchmark_group:
                this.$store.getters["datasetWizard/getBenchmarkGroup"].id,
              number_of_questions: 1
            })
          )
        }
      }
      await this.setupQueue(filter, fetch)
      if (this.globalMatchesQueue.todo.length === 0)
        this.globalMatchesQueue.finished = true
      else this.globalMatchesQueue.runConcurrent()
    },
    updateApprovedMatches() {
      let count = 0
      if (this.clientQuestions && this.clientQuestions.length) {
        for (let i = 0; i < this.clientQuestions.length; i++) {
          if (
            this.clientQuestions[i].approved_data_type &&
            this.clientQuestions[i].approved_data_type !== "" &&
            this.clientQuestions[i].approved_data_type !== "none"
          ) {
            count = count + 1
          }
        }
      }
      this.approvedDataTypes = count
    },
    updateLocalGlobalMatches() {
      this.approvedGlobalMatches = this.approvedGlobalMatches + 1
      this.$emit("approved-global-match")
    },
    async fetchLemmas(id) {
      let questionID = ""
      if (id.$oid) {
        questionID = id.$oid
      } else {
        questionID = id
      }

      await this.DATASETS_SERVICE.addLemmasToResponses({
        client_question_id: questionID
      })
      this.updateClientQuestions(this.dataset._id.$oid)
      if (this.$refs["dataType" + questionID]) {
        this.$refs["dataType" + questionID].setLemmasLoadingSpinner("")
      }
    },
    async fetchSentiments(id) {
      let questionID = ""
      if (id.$oid) {
        questionID = id.$oid
      } else {
        questionID = id
      }

      await this.DATASETS_SERVICE.addSentimentsToResponses({
        client_question_id: questionID
      })
      this.updateClientQuestions(this.dataset._id.$oid)
      if (this.$refs["dataType" + questionID]) {
        this.$refs["dataType" + questionID].setSentimentsLoadingSpinner("")
      }
    },

    async fetchSentenceTokens(id) {
      let questionID = ""
      if (id.$oid) {
        questionID = id.$oid
      } else {
        questionID = id
      }

      await this.DATASETS_SERVICE.addSentenceTokensToResponses({
        client_question_id: questionID
      })
      this.updateClientQuestions(this.dataset._id.$oid)
      if (this.$refs["dataType" + questionID]) {
        this.$refs["dataType" + questionID].setSentencesLoadingSpinner("")
      }
    }
  },
  watch: {
    approvedDataTypes: function (count) {
      if (
        !this.$store.getters["datasetWizard/getDatasetProject"].status
          .datasetColumnDetailsCompleted &&
        count > 0
      ) {
        this.$store.dispatch(
          "datasetWizard/updateDatasetProjectStatus",
          "datasetColumnDetailsCompleted"
        )
      }
    },
    /**
     * triggered by store value
     */
    datasetDetailsLoaded: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.pagination.totalQuestions = this.clientQuestions.length
        }
      }
    },
    toFetchSuggestedDataType: function (val) {
      if (val) {
        this.runDataTypeQueue()
      }
    },
    toFetchSuggestedMatches: function (val) {
      if (val) this.runMatchesQueue()
    },
    /**
     * triggered by parent
     */
    refreshPagination: function () {
      this.resetPagination()
    }
  }
}
</script>
