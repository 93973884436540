import { app } from "@/main.js"

export default {
  namespaced: true,
  state() {
    return {
      clickedClose: false,
      isVisible: false,
      status: false, //false = canceled, true = confirmed
      sourceComponent: null, //keep track of which component the confirmation is coming from
      target: null, //keep track of the target object or id
      text: {
        message: "Are you sure you want to continue?",
        title: "Confirm?",
        btn: "continue"
      },
      type: "error"
    }
  },
  getters: {
    getClickedClose: (state) => state.clickedClose,
    getIsVisible: (state) => state.isVisible,
    getStatus: (state) => state.status,
    getSourceComponent: (state) => state.sourceComponent,
    getTarget: (state) => state.target,
    getText: (state) => state.text,
    getType: (state) => state.type
  },
  mutations: {
    SET_CLICKED_CLOSE(state, payload) {
      state["clickedClose"] = payload
    },
    SET_IS_VISIBLE(state, payload) {
      state["isVisible"] = payload
    },
    SET_STATUS(state, payload) {
      state["status"] = payload
    },
    SET_SOURCE_COMPONENT(state, payload) {
      state["sourceComponent"] = payload
    },
    SET_TARGET(state, payload) {
      state["target"] = payload
    },
    SET_TEXT(state, payload) {
      state["text"] = payload
    },
    SET_TYPE(state, payload) {
      state["type"] = payload
    },
    RESET_CONFIRM(state) {
      const textInitState = {
        message: "Are you sure you want to continue?",
        title: "Confirm?",
        btn: "confirm"
      }
      state["clickedClose"] = false
      state["isVisible"] = false
      state["status"] = false
      state["sourceComponent"] = null
      state["target"] = null
      state["text"] = textInitState
      state["type"] = "error"
    }
  },
  actions: {
    async setConfirmClickedClose({ commit }, payload) {
      await commit("SET_CLICKED_CLOSE", payload)
    },
    async setConfirmIsVisible({ commit }, payload) {
      await commit("SET_IS_VISIBLE", payload)
    },
    async setConfirmStatus({ commit }, payload) {
      await commit("SET_STATUS", payload)
    },
    async setConfirmSourceComponent({ commit }, payload) {
      await commit("SET_SOURCE_COMPONENT", payload)
    },
    async setConfirmTarget({ commit }, payload) {
      await commit("SET_TARGET", payload)
    },
    async setConfirmText({ commit }, payload) {
      await commit("SET_TEXT", payload)
    },
    async setConfirmType({ commit }, payload) {
      await commit("SET_TYPE", payload)
    },
    resetConfirm({ commit }) {
      commit("RESET_CONFIRM")
    }
  }
}
