<template>
  <div class="register-verify__content" v-if="currentStatus">
    <h1 class="register-verify__title">{{ currentStatus.title }}</h1>
    <section class="register-verify__desc">
      <p v-html="currentStatus.description"></p>
      <div v-if="status == 'account_created'">
        <a href="#" @click="resendVerificationEmail">
          <VisibleText>Resend verification email</VisibleText>
        </a>
      </div>
      <div v-if="status == 'account_verified'">
        <router-link to="/login">
          <VisibleText> Go to login page</VisibleText>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "registerVerify",
  data() {
    return {
      email: null,
      status: null,
      statusDetails: {
        account_created: {
          title: "Account created successfully.",
          description:
            "An email has been sent to your inbox for verification. Please click the link in your email to verify your account."
        },
        account_verified: {
          title: "Account verification success.",
          description:
            "Your account has been verified successfully. Please click the link below to proceed to login page."
        },
        verification_error: {
          title: "Email verification error!",
          description: ""
        }
      }
    }
  },
  computed: {
    currentStatus: function () {
      return this.statusDetails[this.status]
    }
  },
  async mounted() {
    this.email = this.$route.query.email

    if (this.$route.query.uidb64 && this.$route.query.token) {
      // send token to backend for verification
      const uidb64 = this.$route.query.uidb64
      const token = this.$route.query.token

      try {
        await axios.get(
          `${this.$pigeonline.options.baseUrl}/rauth/activate?uidb64=${uidb64}&token=${token}`
        )
        this.status = "account_verified"
      } catch (e) {
        this.status = "verification_error"
        this.statusDetails[this.status].description = e.response.data
      }
    } else {
      if (
        this.$route.query.status &&
        this.statusDetails[this.$route.query.status]
      ) {
        this.status = this.$route.query.status
      }

      if (!this.email || !this.status) {
        this.$router.push({ name: "notFound" })
      }
    }
  },
  methods: {
    resendVerificationEmail: async function () {
      try {
        const res = await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/resend-verification-email`,
          {
            email: this.email
          }
        )
        alert(res.data)
      } catch (e) {
        this.status = "verification_error"
        this.statusDetails[this.status].description = e.response.data
      }
    }
  }
}
</script>
