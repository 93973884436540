<template>
  <fieldset
    class="form-fieldset-group dataset-segments__form-group"
    id="about-survey"
    tabindex="-1"
  >
    <legend
      class="form-fieldset-group__legend form-fieldset-group__legend--base-colour"
    >
      <VisibleText> Survey Details </VisibleText>
    </legend>
    <p class="form-fieldset-group__p">
      <VisibleText>
        Tell us a little bit about about your survey. All fields marked with
        <span class="required-indicator"> * </span>
        are required.
      </VisibleText>
    </p>

    <div class="form-inline-group">
      <label for="survey-start-date">
        <VisibleText> Survey Start Date </VisibleText>
        <span class="required-indicator"> * </span>
      </label>
      <VisibleText
        class="error-msg__inline form__err--block"
        id="end-start-err"
        v-show="errMsg.surveyStartDate"
      >
        {{ errMsg.surveyStartDate }}
      </VisibleText>
      <input
        aria-describedby="end-date-err"
        required
        ref="start-date"
        type="date"
        id="survey-start-date"
        :aria-invalid="
          errMsg.surveyStartDate && !localSurveyDetails.surveyStartDate
            ? 'true'
            : 'false'
        "
        :readOnly="readOnly"
        v-model="localSurveyDetails.surveyStartDate"
        @change="errMsg.surveyStartDate = null"
      />
    </div>

    <div class="form-inline-group">
      <label for="survey-end-date">
        <VisibleText> Survey End Date </VisibleText>
        <span class="required-indicator"> * </span>
      </label>
      <VisibleText
        class="error-msg__inline form__err--block"
        id="end-date-err"
        v-show="errMsg.surveyEndDate"
      >
        {{ errMsg.surveyEndDate }}
      </VisibleText>
      <input
        aria-describedby="end-date-err"
        required
        ref="end-date"
        type="date"
        id="survey-end-date"
        :aria-invalid="
          errMsg.surveyEndDate && !localSurveyDetails.surveyEndDate
            ? 'true'
            : 'false'
        "
        :readOnly="readOnly"
        v-model="localSurveyDetails.surveyEndDate"
        @change="errMsg.surveyEndDate = null"
      />
    </div>

    <div class="form-inline-group">
      <label for="response-rate">
        <VisibleText> Response Rate </VisibleText>
        <span class="required-indicator"> * </span>
        <VisibleText class="form-label-details">
          ( Number of completed surveys / Number of respondents contacted )%
        </VisibleText>
      </label>
      <div class="dataset-segments__response-rate">
        <VisibleText
          class="error-msg__inline form__err--block"
          id="response-rate-err"
          v-show="errMsg.responseRate"
        >
          {{ errMsg.responseRate }}
        </VisibleText>
        <input
          aria-describedby="response-rate-err"
          class="dataset-segments__response-rate-input"
          ref="response-rate"
          type="number"
          min="0"
          step="1"
          id="response-rate"
          :aria-invalid="Boolean(errMsg.responseRate)"
          :readOnly="readOnly"
          v-model="localSurveyDetails.rate"
          @change="onChangeResponseRate"
        />
        <label
          for="response-rate-na"
          class="dataset-segments__response-rate-na"
        >
          <input
            id="response-rate-na"
            type="checkbox"
            :disabled="readOnly"
            v-model="localSurveyDetails.responseRateNa"
            @change="errMsg.responseRate = null"
          />
          <VisibleText> response rate not available </VisibleText>
        </label>
      </div>
    </div>

    <div class="form-inline-group">
      <label for="coverage-area">
        <VisibleText> Coverage Area </VisibleText>
      </label>
      <input
        type="text"
        id="coverage-area"
        :readOnly="readOnly"
        v-model="localSurveyDetails.coverageArea"
      />
    </div>

    <div class="form-inline-group">
      <label for="coverage-area-population">
        <VisibleText> Coverage Area Population </VisibleText></label
      >
      <input
        type="text"
        id="coverage-area-population"
        :readOnly="readOnly"
        v-model="localSurveyDetails.coveragePopulation"
      />
    </div>

    <fieldset class="form-fieldset-child">
      <legend><VisibleText> Third Party Delivery </VisibleText></legend>
      <label>
        <input
          type="radio"
          value="yes"
          :disabled="readOnly"
          v-model="localSurveyDetails.thirdParty"
        />
        {{ translate("yes", $options.name) }}
      </label>
      <label>
        <input
          type="radio"
          value="no"
          :disabled="readOnly"
          v-model="localSurveyDetails.thirdParty"
        />
        {{ translate("no", $options.name) }}
      </label>
    </fieldset>

    <div class="form-inline-group">
      <label for="data-collector">
        <VisibleText>
          Data Collector
          <span class="form-label-details">
            Company/organization that conducted the survey
          </span>
        </VisibleText>
      </label>
      <input
        type="text"
        id="data-collector"
        :readOnly="readOnly"
        v-model="localSurveyDetails.dataCollector"
      />
    </div>

    <div class="form-inline-group">
      <label for="survey-frequency">
        <VisibleText>
          Survey Frequency
          <span class="form-label-details">
            one time, annual, monthly, etc.
          </span>
        </VisibleText>
      </label>
      <input
        type="text"
        id="survey-frequency"
        :readOnly="readOnly"
        v-model="localSurveyDetails.frequency"
      />
    </div>

    <div class="form-inline-group">
      <label for="sampling-procedure">
        <VisibleText>
          Sampling Procedure
          <span class="form-label-details">
            random, stratified, census, etc
          </span>
        </VisibleText>
      </label>
      <input
        type="text"
        id="sampling-procedure"
        :readOnly="readOnly"
        v-model="localSurveyDetails.procedure"
      />
    </div>

    <fieldset class="form-fieldset-child">
      <legend><VisibleText> Survey Language </VisibleText></legend>
      <label>
        <input
          type="radio"
          value="english"
          :disabled="readOnly"
          v-model="localSurveyDetails.language"
        />
        {{ translate("English", $options.name) }}
      </label>
      <label>
        <input
          type="radio"
          value="french"
          :disabled="readOnly"
          v-model="localSurveyDetails.language"
        />
        {{ translate("French", $options.name) }}
      </label>
      <label>
        <input
          type="radio"
          value="both"
          :disabled="readOnly"
          v-model="localSurveyDetails.language"
        />
        {{ translate("Both", $options.name) }}
      </label>
      <label>
        <input
          type="radio"
          value="other"
          :disabled="readOnly"
          v-model="localSurveyDetails.language"
        />
        {{ translate("Other", $options.name) }}
      </label>
    </fieldset>

    <div class="form-inline-group">
      <label for="respondent-sources">
        <VisibleText>
          Respondent Sources
          <span class="form-label-details">
            tax rolls, client lists, etc.
          </span>
        </VisibleText>
      </label>
      <input
        type="text"
        id="respondent-sources"
        :readOnly="readOnly"
        v-model="localSurveyDetails.sources"
      />
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "DatasetSegmentsSurveyDetails",
  props: {
    surveyDetails: {
      type: Object,
      required: true
    },
    readOnly: {
      default: () => false,
      type: Boolean
    },
    validate: { type: Boolean }
  },
  data() {
    return {
      errMsg: {
        responseRate: null,
        surveyEndDate: null,
        surveyStartDate: null
      },
      localSurveyDetails: {
        rate: 0,
        responseRateNa: false,
        surveyStartDate: "",
        surveyEndDate: "",
        sources: "",
        language: "",
        procedure: "",
        frequency: "",
        dataCollector: "",
        thirdParty: "",
        coveragePopulation: "",
        coverageArea: ""
      }
    }
  },
  mounted() {
    this.localSurveyDetails = this.surveyDetails
  },
  methods: {
    onChangeResponseRate() {
      this.errMsg.responseRate = null
      if (this.localSurveyDetails.rate)
        this.localSurveyDetails.responseRateNa = false
    },
    validateFields() {
      let firstErr = null
      if (!this.localSurveyDetails.surveyStartDate) {
        this.errMsg.surveyStartDate = "Error: survey start date is required."
        if (!firstErr) firstErr = "start-date"
      }
      if (!this.localSurveyDetails.surveyEndDate) {
        this.errMsg.surveyEndDate = "Error: survey end date is required."
        if (!firstErr) firstErr = "end-date"
      }
      if (
        !this.localSurveyDetails.rate &&
        !this.localSurveyDetails.responseRateNa
      ) {
        this.errMsg.responseRate =
          "Error: enter a response rate or check 'response rate not available'."
        if (!firstErr) firstErr = "response-rate"
      }
      if (firstErr) this.$refs[firstErr].focus()
      return !(firstErr && firstErr.length > 0)
    }
  },
  watch: {
    validate: function (val) {
      if (val) this.validateFields()
    }
  }
}
</script>
