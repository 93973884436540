<template>
  <div
    class="ui-selectable"
    ref="selectable-container"
    v-click-outside="closeTools"
  >
    <div
      v-show="showTools"
      aria-label="text tools"
      class="ui-selectable__tools"
      role="dialog"
      ref="selectable-tools"
      tabindex="-1"
      :data-opened-tools="showTools"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <button
        class="ui-selectable__tools-item"
        v-for="item of selectedToolOptions"
        :key="`selectable-tools-button-${item.id}`"
        @click.prevent="handleAction(`${item.id}`, $event.target)"
        style="background-color: black"
        :style="
          !hasFetchedSentences && item.label == 'Similar'
            ? 'cursor: not-allowed'
            : ''
        "
      >
        <VisibleText>{{ item.label }}</VisibleText>
      </button>
    </div>
    <div
      v-show="tooltip.isVisible"
      aria-label="text tooltip"
      class="ui-selectable__tooltip"
      role="dialog"
      tabindex="-1"
      :style="{
        left: `${tooltip.x}px`,
        top: `${tooltip.y}px`
      }"
    >
      {{ tooltip.tooltipText }}
    </div>
    <div ref="selectablecontent">
      <slot />
    </div>
  </div>
</template>

<script>
import HighlightMixin from "../Mixins/highlightMixin.js"
import { ref } from "vue"

export default {
  name: "Selectable",
  mixins: [HighlightMixin],
  setup() {
    const selectablecontent = ref(null)
    return {
      selectablecontent
    }
  },
  props: {
    responseId: {
      type: String,
      default: () => ""
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    }
  },
  mounted() {
    this.isMounted = true
    this.$refs["selectable-container"].addEventListener(
      "mouseup",
      () => setTimeout(this.onMouseUp.bind(this), 10),
      false
    )
  },
  methods: {
    handleAction(action, target) {
      if (!this.hasFetchedSentences && action == "similar") {
        this.showTooltip(
          target,
          "You need to preprocess sentences in 'AI options' to run this."
        )
        this.skipAllActions = true
      } else {
        this.showTools = false
        this.skipMouseup = true
        let selection = this.getSelectedTextInfo()
        selection.responseId = this.responseId
        this.$emit(action, selection)
      }
    }
  },
  watch: {
    showTools: function (val) {
      if (!val) {
        this.tooltip.tooltipText = ""
        return
      }
      this.$nextTick(
        function () {
          this.clipTools()
        }.bind(this)
      )
    }
  }
}
</script>
