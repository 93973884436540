<template>
  <div>
    <button
      class="landing-page__button landing-page__button--small"
      @click="showTranscript = !showTranscript"
    >
      <VisibleText>Show transcript</VisibleText>
    </button>
    <InsertMarkdown v-if="showTranscript" :mdFilePath="mdFilePath" />
  </div>
</template>

<script>
import InsertMarkdown from "@/components/InsertMarkdown/InsertMarkdown.vue"

export default {
  name: "VideoTranscript.vue",
  components: {
    InsertMarkdown
  },
  props: {
    mdFilePath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showTranscript: false
    }
  }
}
</script>
