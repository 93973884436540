<template>
  <button
    :class="['ui-tappable', { selected: selected }]"
    @click.prevent="toggleToolsView"
    v-click-outside="hideToolsView"
  >
    <div
      v-show="showTools"
      aria-label="text tools"
      class="ui-tappable__tools"
      role="dialog"
      ref="tappable-tools"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <button
        v-for="(option, key) in options"
        :key="key"
        class="ui-tappable__tools-item"
        @click.prevent="handleAction(key)"
      >
        <VisibleText>{{ option.text }}</VisibleText>
      </button>
    </div>
    <slot />
  </button>
</template>

<script>
export default {
  name: "Tappable",
  props: {
    options: {
      type: Object,
      required: true
    },
    childRect: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      selected: false,
      showTools: false
    }
  },
  computed: {},
  created() {
    if (this.$el) {
      const parentPos = this.$el.getBoundingClientRect()
      childPos = this.childRect
      const top = childPos.top - parentPos.top
      const left = childPos.left - parentPos.left
      const width = childPos.width

      if (!width) {
        this.showTools = false
        return
      }
      this.x = left + width / 2
      this.y = top - 10
    }
  },
  methods: {
    hideToolsView() {
      if (this.showTools) {
        this.showTools = false
        this.setSelected(false)
      }
    },
    toggleToolsView() {
      this.showTools = !this.showTools
      this.setSelected(this.showTools)
      this.$nextTick(() => {
        if (this.$refs["tappable-tools"]) {
          this.$refs["tappable-tools"].focus()
        }
      })
    },
    handleAction(action) {
      this.showTools = false
      if (
        this.$slots &&
        this.$slots.default() &&
        this.$slots.default().length
      ) {
        this.$emit(action, this.$slots.default()[0].children)
      }
    },
    setSelected(val) {
      this.selected = val
      if (val) this.$emit("selected")
    }
  }
}
</script>
