<template>
  <fieldset class="switch">
    <legend class="sr-only">{{ title }}</legend>
    <input
      style="position: relative; z-index: 50; cursor: pointer"
      class="input"
      type="radio"
      :name="uuid"
      :id="`op1-${uuid}`"
      :value="0"
      @focus="isFocused = true"
      @blur="isFocused = false"
      v-model="value"
      :disabled="
        !hasFetchedSentiments ||
        textAnalysisObject.includeSentiments !== 'include'
      "
      @change="setToggle"
    />
    <label
      :class="['label', { focused: isFocused }, { checked: value == 0 }]"
      :for="`op1-${uuid}`"
      style="height: 2rem; font-size: 1rem; cursor: pointer"
    >
      <span class="text">{{ op1 }}</span>
    </label>
    <input
      class="input"
      type="radio"
      :name="uuid"
      :id="`op2-${uuid}`"
      :value="1"
      @focus="isFocused = true"
      @blur="isFocused = false"
      v-model="value"
      @change="setToggle"
    />
    <label
      :class="['label', { checked: value == 1 }]"
      :for="`op2-${uuid}`"
      style="height: 2rem; font-size: 1rem; cursor: pointer"
    >
      <span class="text"> {{ op2 }} </span>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "SwitchToggle",
  props: {
    title: {
      type: String,
      requiried: true
    },
    op1: {
      type: String,
      requiried: true
    },
    op2: {
      type: String,
      requiried: true
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    textAnalysisObject: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uuid: "0",
      isFocused: false
    }
  },
  created() {
    this.uuid = this.createUuid()
  },
  mounted() {
    // if (this.textAnalysisObject.activeTab == "Sentiment") {
    //   this.value = 0
    // } else if (this.textAnalysisObject.activeTab == "Themes") {
    //   this.value = 1
    // }
  },
  computed: {
    value: {
      get() {
        if (this.textAnalysisObject && this.textAnalysisObject.activeTab) {
          if (this.textAnalysisObject.activeTab == "Sentiment") {
            return 0
          }
          if (this.textAnalysisObject.activeTab == "Themes") {
            return 1
          }
        }
        return 0
      },
      set() {}
    }
  },
  methods: {
    //FIXME: update when pigeon-core is updated
    createUuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
    setToggle() {
      this.$emit("onToggle", this.value)
    }
  },
  watch: {
    // activeTab: function (val) {
    //   if (!val) return
    //   if (val == "Sentiment") {
    //     this.value = 0
    //   } else if (val == "Themes") {
    //     this.value = 1
    //   }
    // }
    // value: {
    //   immediate: true,
    //   handler: function (val) {
    //     this.$emit("onToggle", val)
    //   }
    // }
  }
}
</script>
