<template>
  <section :id="sectionName" class="dataset__section">
    <h2 class="dataset__section-title">
      <VisibleText>{{ title }}</VisibleText>
    </h2>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "DatasetLayout",
  props: {
    sectionName: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>
