import { performanceSimpleScoreChart } from "./performanceNodes"

const performanceSection = (data, lang = "en") => {
  const performanceChart = performanceSimpleScoreChart(
    data.performanceTitle,
    data.performanceData,
    lang,
    data.performanceThreshold,
    data.performanceChartType
  )

  return {
    type: "section",
    id: "performance-section",
    content: [performanceChart],
    meta: {
      performanceUUID: data.performanceUUID,
      subTitle: "PERFORMANCE ANALYSIS"
    }
  }
}

export { performanceSection }
