<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <AnalysisSettings
        ref="AnalysisSettings"
        :propReadOnly="isReadOnly"
        :statusDisableActionBtns="statusDisableActionBtns"
        :index="index"
        :thisAnalysisName="benchmarkingTitle"
        :isBenchmarkingAnalysis="true"
        :savedNodes="savedNodes"
        :isWeighted="isWeighted"
        :unfilteredDate="unfilteredDate"
        :filterParams="benchmarkAnalysisObject.filter_param"
        @toggled="isExpanded = !isExpanded"
        @changeIsLoadingChartData="isLoading = true"
        @changeIsLoadingChartDataOff="isLoading = false"
        @save-to-report="save"
        @saved-slide="updateSavedSlide"
        @on-click-delete="onClickDelete"
        @on-change-benchmark-filters="onChangeBenchmarkFilters"
      ></AnalysisSettings>

      <article v-if="statusBenchmarksAvailable && isExpanded">
        <!-- is this needed given the analysis settings above? -->
        <Spinner :is-loading="isLoading" :message="spinnerMessage" />

        <!--id="benchmarking-table"-->
        <DefaultTable
          class="benchmarking__table benchmarking__summary-results-table"
          :caption="'Benchmarking Averages'"
          :hide-caption="true"
          :row-data="summaryResultsTable"
          :column-headers="summaryResultsTableHeaders"
          :row-keys="summaryResultsTableHeaders"
          :sortConfig="sortConfig"
          @sort="sort"
        />
        <div
          v-if="hasTemporarilyApproved"
          style="font-size: 0.8rem; padding-left: 1rem; padding-bottom: 2rem"
        >
          <VisibleText
            >* At least one question is set to 'tentatively approved' which
            means you can create a report, but your question has not been
            accepted to the database.</VisibleText
          >
        </div>
      </article>
    </article>
  </div>
</template>
<script>
// components
import AnalysisSettings from "@/components/Project/ProjectAnalysis/Components/AnalysisSettings.vue"
import DefaultTable from "@/components/UI/DefaultTable.vue"

// utils
import moment from "moment"
import _ from "lodash"
import Spinner from "@/components/UI/Spinner.vue"
import { PigeonDocModel, PigeonDocCollectionModel } from "@pigeonline/pigeondoc"

// mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"
import BenchmarkAnalysisMixin from "./Mixins/benchmarkAnalysisMixin.js"

const SORT_BY = {
  ascending: 1,
  descending: -1
}

export default {
  name: "ProjectAnalysisBenchmarkingItem",
  components: {
    AnalysisSettings,
    Spinner,
    DefaultTable
  },
  mixins: [
    ProjectMixin,
    BenchmarkAnalysisMixin,
    DatasetDetailsMixin,
    ProjectReportMixin
  ],
  props: {
    index: {
      type: Number,
      required: true
    },
    statusBenchmarksAvailable: {
      type: Boolean,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      benchmarkAnalysisObject: {},
      spinnerMessage: null,
      isLoading: false,
      benchmarkingTitle: "Benchmarking report",
      sortConfig: {
        question: {
          options: ["default", "ascending", "descending"],
          selected: "default"
        },
        rating: {
          options: [
            "asc (outstanding - no org)",
            "desc (no org - outstanding)"
          ],
          selected: "asc (outstanding - no org)"
        }
      },
      savedNodes: [],
      savedSlide: "",
      isExpanded: false,
      dateParam: ""
    }
  },
  async created() {
    this.isLoading = true
    this.spinnerMessage = "loading benchmarks, please wait"
    // clone object
    this.benchmarkAnalysisObject = _.cloneDeep(
      this.project.benchmarkAnalysis[this.index]
    )
    if (this.benchmarkAnalysisObject) {
      this.formatTables(this.benchmarkAnalysisObject.summary_table)
      if (this.benchmarkAnalysisObject.filtered_date) {
        let newParam = {}
        newParam.operands = []
        newParam.operands.push({
          operator: "created_after",
          client_question_id: null,
          values: this.benchmarkAnalysisObject.filtered_date
        })
        this.dateParam = newParam
      }
    }
    if (this.benchmarkAnalysisObject.saved_node_ids) {
      this.savedNodes = this.benchmarkAnalysisObject.saved_node_ids
    }
    if (this.index === 0) {
      this.isExpanded = true
    }
    this.isLoading = false
    this.checkForSlides()
  },
  methods: {
    async checkForSlides() {
      this.slides = []
      let collection
      if (this.project.collection_id) {
        collection = await this.$pigeonline.projects.getByID(
          PigeonDocCollectionModel,
          this.project.collection_id
        )
        if (collection) {
          if (collection.docIds) {
            let project
            for (let i = 0; i < collection.docIds.length; i++) {
              project = await this.$pigeonline.projects.getByID(
                PigeonDocModel,
                collection.docIds[i]
              )
              if (project) {
                this.slides.push(project)
              }
            }
          }
        }
      }
      this.$emit("updateCollection")
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
    },
    sort(args) {
      this.sortConfig[args.sortTitle].selected = args.option
      this[`sort${args.sortTitle}`](args.option)
    },
    sortquestion(option) {
      if (option === "default")
        this.summaryResultsTable = Array.from(this.defaultSummaryResultsTable)
      this.summaryResultsTable.sort(
        (a, b) => SORT_BY[option] * a.question.localeCompare(b.question)
      )
    },
    sortrating(option) {
      let direction = option.includes("asc") ? 1 : -1
      this.summaryResultsTable.sort(
        (a, b) => direction * a.ratingLetter.localeCompare(b.ratingLetter)
      )
    },
    async save(selectedSlide, newSlideName) {
      if (this.propReadOnly) return
      const response = await this.saveBenchmarkingToReport(
        selectedSlide,
        newSlideName
      )
      // this.project.updateStatus("analysisCompleted")
      await this.saveBenchmarkingToProject(response, this.combinedParams)
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.updateCollections()
      }
      this.$emit("refresh")
    },
    async saveBenchmarkingToReport(selectedSlide, newSlideName) {
      try {
        this.isLoading = true
        this.spinnerMessage = "saving to report, please wait"

        let createdReportID = await this.createReportIfEmpty(
          selectedSlide,
          newSlideName
        ) // the above function will do nothing under conditions of emptiness
        if (selectedSlide) {
          createdReportID = selectedSlide.id
        }

        let filter_param = {}
        if (this.combinedParams) {
          filter_param = this.combinedParams
        }

        // get report data
        const clientQuestionDetails = await this.fetchClientQuestionsDetails(
          filter_param,
          this.theseBenchmarkClientQuestionIds
        )
        const details = await this.fetchDetailedResults(
          createdReportID,
          filter_param,
          this.theseBenchmarkClientQuestionIds
        )
        const summaryResults = await this.fetchSummaryResults(
          createdReportID,
          filter_param,
          this.theseBenchmarkClientQuestionIds
        )

        // summarySectionNode
        let summarySectionNodeParams = {}
        summarySectionNodeParams.summaryTable = this.summaryResultsTable
        summarySectionNodeParams.summaryTableColNames =
          this.summaryResultsTableHeaders
        summarySectionNodeParams.overallDynamicSummary =
          summaryResults.overall_dynamic_summary[1]
        summarySectionNodeParams.uuid = this.$pigeonline.createUUID()

        // benchmarkingQbyQSectionNode
        let benchmarkingQbyQSectionNodeParams = {}
        benchmarkingQbyQSectionNodeParams.clientQuestions =
          this.$store.getters["datasetDetails/getClientQuestions"]
        const idSortOrder = this.summaryResultsTable.map((o) => o.questionId)
        const idToIndex = clientQuestionDetails.reduce((arr, detail, indx) => {
          arr[detail.client_question_id] = indx
          return arr
        }, {})
        const sortedClientQuestionDetails = idSortOrder.map(
          (id) => clientQuestionDetails[idToIndex[id]]
        )
        benchmarkingQbyQSectionNodeParams.clientQuestionAnalysis =
          sortedClientQuestionDetails
        benchmarkingQbyQSectionNodeParams.clientBenchmarkGroups =
          this.dataset.segments
        benchmarkingQbyQSectionNodeParams.detailedResults = details
        benchmarkingQbyQSectionNodeParams.globalMatchesDetails =
          this.matchesDetails
        benchmarkingQbyQSectionNodeParams.uuid = this.$pigeonline.createUUID()

        // benchmarkingMethodologySectionNode
        let benchmarkingMethodologySectionNodeParams = {}
        benchmarkingMethodologySectionNodeParams.data_set_meta_data =
          summaryResults.data_set_meta_data
        benchmarkingMethodologySectionNodeParams.uuid =
          this.$pigeonline.createUUID()
        const updatedReport = await this.updateReportWithBenchmarks(
          this.benchmarkAnalysisObject.uuid,
          createdReportID,
          summarySectionNodeParams,
          benchmarkingQbyQSectionNodeParams,
          benchmarkingMethodologySectionNodeParams,
          filter_param
        )
        this.savedNodes = updatedReport.newNodes //works
        this.isLoading = false
        await this.$emit("updateSlides")
        this.onSuccessfulSave()
        return { uuid: updatedReport.uuid }
      } catch (e) {
        throw new Error(
          "ProjectAnalysisBenchmarking:saveBenchmarkingToReport " + e.message
        )
      }
    },
    async saveBenchmarkingToProject(response, filter_param) {
      let uuid
      if (!response.uuid) {
        uuid = this.$pigeonline.createUUID()
      } else {
        uuid = response.uuid
      }

      let parsedDate = ""
      if (this.dateParam && this.dateParam.operands) {
        for (let i = 0; i < this.dateParam.operands.length; i++) {
          if (this.dateParam.operands[i].operator === "created_after") {
            parsedDate = this.dateParam.operands[i].values
          }
        }
      }
      let parsedFilter = {}
      if (filter_param) {
        parsedFilter = filter_param
      }
      const newObject = {
        summary_table: this.benchmarkAnalysisObject.summary_table,
        overall_dynamic_summary:
          this.benchmarkAnalysisObject.overall_dynamic_summary,
        data_set_meta_data: this.benchmarkAnalysisObject.data_set_meta_data,
        saved_node_ids: this.savedNodes,
        last_modified_on: moment().valueOf(),
        uuid: uuid,
        filtered_date: parsedDate,
        filter_param: parsedFilter
      }
      // find it in currently saved benchmarks, if there update, if not add it
      const indx = this.project.benchmarkAnalysis.findIndex(
        (d) => d.uuid == this.benchmarkAnalysisObject.uuid
      )
      if (indx > -1)
        this.project.benchmarkAnalysis.splice(indx, 1, _.cloneDeep(newObject))
      else this.project.benchmarkAnalysis.unshift(_.cloneDeep(newObject))

      await this.cleanAndSaveProject()
      // await this.saveProject(this.project)
      this.benchmarkAnalysisObject = _.cloneDeep(newObject)
    },
    async onClickDelete() {
      const indx = this.project.benchmarkAnalysis.findIndex(
        (d) => d.uuid == this.benchmarkAnalysisObject.uuid
      )
      if (!this.savedNodes.length) {
        this.setConfirmText({
          btn: "Delete Benchmarks",
          title: "Delete benchmarking analysis?"
        })
      } else {
        this.setConfirmText({
          btn: "Delete Benchmarks",
          title:
            "Delete benchmarking analysis? This will also delete this analysis from your report."
        })
      }
      this.setConfirmType("delete")
      this.setConfirmTarget(this.benchmarkAnalysisObject.uuid)
      this.setConfirmSourceComponent("benchmarking")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Benchmarks saved",
        message: "Benchmarks successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("benchmarking")
      this.setConfirmIsVisible(true)
    },
    async deleteBenchmark() {
      const indx = this.project.benchmarkAnalysis.findIndex(
        (d) => d.uuid == this.benchmarkAnalysisObject.uuid
      )
      this.project.benchmarkAnalysis.splice(indx, 1)

      await this.cleanAndSaveProject()
      let project
      if (this.savedNodes.length) {
        project = await this.$pigeonline.projects.getByID(
          PigeonDocModel,
          this.savedSlide.id
        )
        if (project) {
          for (let i = 0; i < this.savedNodes.length; i++) {
            for (let x = 0; x < project.nodes.length; x++) {
              if (project.nodes[x].uuid === this.savedNodes[i].uuid) {
                project.nodes = project.nodes.filter(
                  (item) => item !== project.nodes[x]
                )
              }
            }
          }
        }
        // Saves updates to a "collection" field called "saved_nodes"
        await this.saveProject(project)
      }
      this.$emit("refresh")
      location.reload()
    },
    async onChangeBenchmarkFilters(thisDateParam) {
      this.isLoading = true
      const orig_param = _.cloneDeep(this.benchmarkAnalysisObject.filter_param)
      let combinedParams = {}
      let validParams = false
      if (
        orig_param &&
        Object.keys(orig_param).length &&
        orig_param.operands &&
        orig_param.operands.length
      ) {
        for (let i = 0; i < orig_param.operands.length; i++) {
          if (orig_param.operands[i].operator !== "created_after") {
            validParams = true
          }
        }
      }
      if (thisDateParam && !validParams) {
        combinedParams = thisDateParam
        this.dateParam = thisDateParam
      } else if (!thisDateParam && validParams) {
        this.dateParam = {}

        // erase previous date param if empty is sent
        let adjustedParam = _.cloneDeep(orig_param)
        adjustedParam.operands = []
        for (let i = 0; i < orig_param.operands.length; i++) {
          if (orig_param.operands[i].operator !== "created_after") {
            adjustedParam.operands.push(orig_param.operands[i])
          }
        }
        combinedParams = adjustedParam
      } else if (thisDateParam && validParams) {
        combinedParams = orig_param
        if (!combinedParams.operands) {
          combinedParams.operands = []
        }
        combinedParams.operands.push(thisDateParam.operands[0])
      }
      // if thisDateParam is none, should get full results
      const summaryResults = await this.fetchSummaryResults(
        this.project.id,
        combinedParams,
        this.theseBenchmarkClientQuestionIds
      )
      if (summaryResults) {
        let existingUUID
        if (this.benchmarkAnalysisObject.uuid) {
          existingUUID = this.benchmarkAnalysisObject.uuid
        } else {
          existingUUID = this.$pigeonline.createUUID()
        }
        this.benchmarkAnalysisObject = summaryResults
        this.benchmarkAnalysisObject.uuid = existingUUID
        this.formatTables(this.benchmarkAnalysisObject.summary_table)
        this.saveBenchmarkingToProject(summaryResults, combinedParams)
      }
      this.isLoading = false
    }
  },
  computed: {
    theseBenchmarkClientQuestionIds() {
      let clientQuestionIds = []
      for (
        let i = 0;
        i < this.benchmarkAnalysisObject.summary_table.length;
        i++
      ) {
        if (this.benchmarkAnalysisObject.summary_table[i].client_question_id) {
          clientQuestionIds.push(
            this.benchmarkAnalysisObject.summary_table[i].client_question_id
          )
        }
      }
      return clientQuestionIds
    },
    combinedParams() {
      let combinedParams = {}
      if (this.dateParam && !this.benchmarkAnalysisObject.filter_param) {
        combinedParams = this.dateParam
      } else if (!this.dateParam && this.benchmarkAnalysisObject.filter_param) {
        combinedParams = this.benchmarkAnalysisObject.filter_param
      } else if (
        this.dateParam &&
        this.dateParam.operands &&
        this.benchmarkAnalysisObject.filter_param &&
        Object.keys(this.benchmarkAnalysisObject.filter_param).length
      ) {
        combinedParams = this.benchmarkAnalysisObject.filter_param
        if (!combinedParams.operands) {
          combinedParams.operands = []
        }
        combinedParams.operands.push(this.dateParam.operands[0])
      }
      return combinedParams
    },
    hasTemporarilyApproved() {
      let foundTemp = false
      if (this.benchmarks && this.benchmarks.length) {
        for (let i = 0; i < this.benchmarks.length; i++) {
          if (this.benchmarks[i].temporarily_approved) {
            foundTemp = true
            break
          }
        }
        if (foundTemp) return true
      }
      return false
    },
    statusDisableActionBtns() {
      return this.isLoading
    },
    isWeighted() {
      if (this.benchmarkAnalysisObject) {
        if (this.benchmarkAnalysisObject.is_current_user_weight_applied) {
          return true
        }
      }
      return false
    },
    unfilteredDate() {
      if (this.benchmarkAnalysisObject) {
        if (this.benchmarkAnalysisObject.filtered_date) {
          return this.benchmarkAnalysisObject.filtered_date
        }
      }
      return ""
    }
  },
  watch: {
    // benchmarkAnalysisObject: async function (val) {
    //   if (val.length) {
    //     this.formatTables(this.benchmarkAnalysisObject.summary_table)
    //   }
    // },
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "benchmarking" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.benchmarkAnalysisObject.uuid
      ) {
        await this.deleteBenchmark()
      }
    }
  }
}
</script>
