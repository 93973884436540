/**
 * An object representing a benchmark group
 * @typedef {Object} BenchmarkGroup
 */

import { ProjectModel } from "@pigeonline/core"

export default class BenchmarkGroup extends ProjectModel {
  constructor(data = {}) {
    super(data)

    this.name = "new benchmark group"
    this.access_type = "private"
    this.approved_matches = 0
    this.temporarily_approve = false
    this.share_admin = false
    this.admin_email = ""
    this.auto_approve = false
    this.created_by = {}
    this.official_group = null
    this.questions = 0

    this.setProps(data)
  }

  /**
   * Get the project type
   * @returns {String} string representation of this project type
   */
  static PROJECT_TYPE() {
    return "benchmark_group"
  }
}
