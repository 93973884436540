<template>
  <div class="modal-group" tabindex="-1">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalGroupLayout",
  props: {
    showBackBtn: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  methods: {
    back() {
      this.$emit("back")
    }
  }
}
</script>
