<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1>
      <VisibleText>Versions and change logs:</VisibleText>
    </h1>
    <hr />
    <h2>Current version - v1.9.9</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-12
    </p>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Fixed digital reporting editing and table issues that were introduced when the engine upgraded.</li>
    </ul>
    <hr />
    <h2>v1.9.8</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-12
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>New ability to manually delete account or clear data (GDPR).</li>
      <li>Admins now have the ability to transfer accounts should users request it.</li>
    </ul>
    <hr />
    <h2>v1.9.7</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-09
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Improved naming logic for bert-based topic models.</li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Fixed Chrome/Edge overflow issue on modal that was recently introduced
        by Chrome-based browsers.
      </li>
      <li>
        Fixed crash that could occur if running Bert topic modelling on small
        subsets.
      </li>
    </ul>
    <hr />
    <h2>v1.9.6</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-08
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Improved topic/theme generation interface for text analysis. Can now
        individually choose which type of generation to conduct.
      </li>
      <li>
        BERTtopic-based engine that uses our sentence logic, text filtering and
        other project settings to suggest themes by keyword and sentences.
      </li>
      <li>
        Multi-word logic is now compatible with topic generation, including the
        new BERTtopic and custom text themes.
      </li>
    </ul>
    <hr />
    <h2>v1.9.5</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-06
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Can now upload CSV files and modify delimiters and encodings needed to
        run these.
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Fixed empty banner in dataset cleaning that was supposed to allow you to
        delete empty questions.
      </li>
      <li>Cleaned up data storage to improve overall performance.</li>
      <li>Improved coverage count and dropped/banned count responsivity.</li>
      <li>Improved accuracy of segment and filtered charts.</li>
    </ul>
    <hr />
    <h2>v1.9.4</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-06
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Themes can now be charted/graphed in text analysis reports</li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Improved Save to PDF feature which sometimes required a page refresh to
        update
      </li>
    </ul>
    <hr />
    <h2>v1.9.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-05
    </p>

    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Fixed unresponsive auto-generation of themes</li>
    </ul>
    <hr />
    <h2>v1.9.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-05
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText> Better text analysis search interfaces </VisibleText>
      </li>
      <li>
        <VisibleText>
          More responsive text analysis coverage re-loading when applying
          filters
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Response views were disappearing for some filtered project views in text
        analysis
      </li>
    </ul>
    <hr />
    <h2>v1.9.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-05
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Add or remove notes/keywords directly from the response view</li>
      <li>Can now find similar sentences directly from the response view</li>
      <li>
        Can now run a search and filter for all sentences that match a search
        query
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Can now regenerate sentence tokens if they failed the first time</li>
    </ul>
    <hr />
    <h2>v1.9.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2024-04
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Upgraded from Vue 2 to Vue 3, unlocking faster and more stable browser
        experiences.
      </li>
      <li>
        Added GenAI-based (using BERT) the ability to find similar
        notes/sentences (by creating a note, viewing the note details, and
        selecting 'find similar sentences').
      </li>
      <li>Overhauled text analytics interfaces, including:</li>
      <li>
        Easier adding and dropping of themes directly from response views
        (saving clicks and scrolling)
      </li>
      <li>More context behind theme matches at the response level.</li>
      <li>
        Can now filter text analysis and all theme coverage by categories, live.
      </li>
      <li>Can now sort themes by alphabet, coverage, and date.</li>
      <li>Can now filter by multiple themes instead of just one at a time.</li>
      <li>Can now duplicate, copy, and merge themes.</li>
      <li>Can now access a project's dataset from the project itself.</li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Gmail users can now receive server notification/emails.</li>
      <li>Fixed issue where some text themes were stuck on blue.</li>
      <li>
        Fixed issue where some team or shared projects were being corrupted by
        guests saving changes to the report.
      </li>
      <li>
        Fixed issue where sentiment analysis was skipping the first text row.
      </li>
    </ul>
    <hr />
    <h2>v1.8.4</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-12
    </p>

    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Upgraded from Python 3.9 to 3.10, unlocking our ability to use cutting
        edge machine learning packages.
      </li>
    </ul>
    <hr />
    <h2>v1.8.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-12
    </p>

    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Upgraded from Python 3.8 to 3.9, bringing a boost to overall performance
        and better security.
      </li>
      <li>Improved projects landing page sorting.</li>
    </ul>
    <hr />
    <h2>v1.8.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-12
    </p>

    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Upgraded from Python 3.7 to 3.8, bringing a boost to overall performance
        and better security.
      </li>
    </ul>
    <hr />
    <h2>v1.8.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-12
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Upgraded from Python 3.6 to 3.7, bringing a boost to overall
        performance.
      </li>
      <li>Improved projects landing page load times</li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Fixed email style issues.</li>
      <li>Dataset details now showing the correct user in all cases.</li>
      <li>
        Outliers removed from suggested analysis from projects with no
        categorical questions present.
      </li>
      <li>Fixed error when deleting outliers analysis.</li>
      <li>Fixed error when charting segmented and weighted performance.</li>
      <li>
        Fixed error some had with benchmark management (not being able to see
        matches).
      </li>
      <li>Removed extra page that was appearing in some benchmark reports.</li>
    </ul>
    <hr />
    <h2>v1.8.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-11
    </p>

    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Can now join 'teams', and team admins have team management features like
        inviting, removing, or changing access levels.
      </li>
      <li>
        Projects, datasets, custom text models and benchmark groups can now all
        be shared with teams or set to public, with read only options as well.
      </li>
      <li>
        Can now directly link to custom text models and benchmarking groups,
        especially useful for sharing with team members.
      </li>
      <li>Modular licenses (you can have more than one now).</li>
      <li>
        Revamped 'profile view' that lets you manage your teams and licenses.
      </li>
      <li>
        New 'teaching' license license that lets you invite demo users and
        automatically share projects/datasets, bypassing the need for emails.
      </li>
      <li>
        New 'research' license license that lets you invite team members, giving
        them full access to features and team sharing.
      </li>
      <li>Published our Ethics Policy.</li>
      <li>
        PigeonLine staff now have better tools for supporting customer licenses.
      </li>
      <li>
        Skip email verification if clicking in from email recieved by team
        invite.
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Fixed white-label logos which stopped loading in our last responsivity
        fix.
      </li>
      <li>
        Deactivation of an old package that was preventing optimal package
        updating.
      </li>
    </ul>
    <hr />
    <h2>v1.7.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-08
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Smoother experience for read-only guests of files.</li>
    </ul>
    <h4>
      <VisibleText>Bugs:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Sentiment charts in themes required keywords, now fixed.</li>
      <li>
        Sometimes themes were not appearing in response lists, if only notes but
        not keywords, now fixed.
      </li>
    </ul>
    <hr />
    <h2>v1.7.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-08
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Faster loading of all of your past projects in the projects view, and
        faster searching.
      </li>
      <li>Improved library look and feel.</li>
      <li>Basic mobile responsivity fixes.</li>
    </ul>
    <hr />
    <h2>v1.7.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-07
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Keyword analysis in text analysis can now include multiple words,
          including with smart-coverage (find matches across tenses, genders,
          etc).</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Enhanced view of saved themes, including the option to visualize
          keywords and notes from the main analysis view.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Sentiment charts can now be toggled on or off for final
          reports.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Better descriptions of each AI tool that is used, to enhance overall
          explainability.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >New themes now generate at the top of the lists to prevent
          scrolling.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Sentiments are more granular and include emojis. 😃</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Optimization:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Text data is well optimized for 5,000,000 character-sized questions,
          but has been tested for up to 20,000,000.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Text data in datasets can now generate sentiments and lemmas
          separately, giving users more control over when to use AI or
          not.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Backend APIs were redesigned, allowing some calculations to be done
          ahead of time and cached, allowing for faster use.</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >The frontend (browser application) was redesigned to minimize
          calculations and speed up performance.</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Bug fixes:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Can now share projects from the project view.</li>
      <li>
        <VisibleText>
          Some tags were padded with extra colours in text analysis.
        </VisibleText>
      </li>
    </ul>

    <hr />
    <h2>v1.6.19</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-04
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Improved the user-guide experience</VisibleText>
      </li>
      <li>
        <VisibleText>Better banner and title page options</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Manually dropping themes was causing an incorrect numerator for some
          coverage counts</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Fixed auto-theme analysis failing when non-string theme names
          exist</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.18</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-03
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Significant text analysis optimization, having removed unnecessary data
        and tightened functions throughout
      </li>
      <li>
        Coding interface for text analysis has been optimized for longer-formats
        like interview data. This has been tested over hundreds of hours of
        transcript data.
      </li>
      <li>More text analysis report and summary table details</li>
      <li>Notes can be better managed and edited</li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        First time loading of text analysis often failed for larger fails. It
        will now re-run until it works.
      </li>
    </ul>
    <hr />
    <h2>v1.6.17</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-01
    </p>
    <h4>
      <VisibleText>Security:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Improved site certificate management</li>
    </ul>
    <hr />
    <h2>v1.6.16</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2023-01
    </p>
    <h4>
      <VisibleText>Features:</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Weighting applied to benchmarking is now aligned with common practices
      </li>
      <li>More accessible transcriptions</li>
    </ul>
    <hr />
    <h2>v1.6.15</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-12
    </p>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Can rename project across all analysis views</li>
      <li>Fixed error where date filters on BM were sometimes reversed</li>
    </ul>
    <hr />
    <h2>v1.6.14</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-12
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Benchmark reports can now be filtered (including nested
          filters)</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Data can now be dropped onto the browser during dataset upload
          (drag-and-drop)</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.13</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-09
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Pagination added to coverage to improve performance</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Can add notes and individual responses to themes more
          effectively</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.12</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-09
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Improved performance of coverage calculation for text
          themes</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Can now query suggested data types or benchmark matches any
          time</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>New text analysis will not create duplicates</VisibleText>
      </li>
      <li>
        <VisibleText>Search now works in benchmark matching</VisibleText>
      </li>
      <li>
        <VisibleText
          >Data type filter is now responsive in data set cleaning</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >The 'saved to slide' feature is now more responsive</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Can now run performance across multiple types in one
          project</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.11</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-09
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Greater control over sentiment analysis</VisibleText>
      </li>
      <li>
        <VisibleText
          >Text analysis coverage score now provide more details</VisibleText
        >
      </li>
      <li>
        <VisibleText>Text responses now show matching themes</VisibleText>
      </li>
      <li>
        <VisibleText
          >Searches and theme filters can be stacked in text
          analysis</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Text analysis sentiment analysis charts now included in
          PDFs</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Text analysis coverage is now more accurate</VisibleText>
      </li>
      <li>
        <VisibleText
          >Notes and keywords can be added from individual
          responses</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Complex text analysis datasets no longer fail to find sugested
          themes</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Text analysis filtering is now much faster / more stable</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Saved analyses now recognize when sections have been removed from
          Table of Contents</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.10</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-07
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Better control and management to benchmarking managers and
          users</VisibleText
        >
      </li>
    </ul>

    <hr />
    <h2>v1.6.9</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-06-23
    </p>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText
          >Removed bug where text analysis goes blank when a bad auto-suggestion
          arrives.</VisibleText
        >
      </li>
    </ul>
    <hr />
    <h2>v1.6.8</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-06-18
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Extended scale types to all between 0 and 11</VisibleText>
      </li>
      <li>
        <VisibleText
          >Can now produce "performance" charts, which show average scores for
          any scale questions</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Performance charts can be broken up into segments</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Performance charts can be viewed as "percentage" or top-box
          mode</VisibleText
        >
      </li>
      <li>
        <VisibleText
          >Better look and feel for analysis, and quality of life improvements
          like hiding analysis types that do not fit datasets</VisibleText
        >
      </li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        "Card" view of projects was not allowing users to open past projects. In
        fixing this we also improved the card descriptions of projects.
      </li>
    </ul>
    <hr />
    <h2>v1.6.7</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-05-04
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Benchmarking analysis can now be filtered by dates.</li>
      <li>
        Custom reporting options such as adding text, title pages, and headers.
      </li>
      <li>Dataset wizard UI improvements</li>
    </ul>
    <hr />
    <h2>v1.6.6</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-03-31
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Better mobile friendliness for the information pages (but not the app
        itself), and accessibility/SEO improvements for some pages
      </li>
      <li>
        More sensitive sentiment labeling for responses during text analysis
      </li>
      <li>Auto-benchmarking report template updates</li>
    </ul>
    <hr />
    <h2>v1.6.5</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-02-22
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Can now select manually from suggested themes</li>
      <li>New landing pages for MENA and academia</li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Empty text responses no longer appearing in text analysis</li>
    </ul>
    <hr />
    <h2>v1.6.4</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-01-31
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Improved guides and tutorials</li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Fixed mean score rounding error that occassionally occured with 0.05
      </li>
    </ul>
    <hr />
    <h2>v1.6.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2022-01-10
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>Sharing now compatible with the report building functionality</li>
      <li>Improved dataset wizard uploading experience</li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Benchmarking summary sentence now correctly rendering.
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.6.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-12-20
    </p>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        Better control over what benchmarking database to join and when requests
        have been approved
      </li>
      <li>
        Better guidance/support for first-time users of analytics, through
        better descriptions, external links, and videos
      </li>
      <li>Visual cues for when weighting has been applied to analysis</li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Free users or users without an organization can now produce full
          reports
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.6.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-12-11
    </p>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed issue with frequency tables and hidden scores in benchmarking
        </VisibleText>
      </li>
      <li>
        <VisibleText>Returned custom white-labels</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.6.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-12-06
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>All analysis can now be saved to a report</VisibleText>
      </li>
      <li>
        <VisibleText>
          In the process of saving to a report, a Table of Contents is now
          visible.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Drivers analysis and Text analysis can now have filters applied, using
          a new filters system.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          All analysis has a consistent way of stacking multiple analyses at the
          same time, deleting, renaming, saving to report, etc.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Can now apply a limit to the number of visibe drivers in drivers
          analysis.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Outliers analysis now included, which finds positive/negative outliers
          based on their z-scores.
        </VisibleText>
      </li>
      <li>
        <VisibleText>Can now edit text within the final report.</VisibleText>
      </li>
      <li>
        <VisibleText> Freemium access for first-time users </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>User experience</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText> Text analysis performance improvements </VisibleText>
      </li>
      <li>
        <VisibleText> New landing experiences and use-cases </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.7</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-08-10
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Weights are a new data type, and are applied to analysis automatically
          if present
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Two additional prediction models, bringing the list to: random forest
          regression, random forest classification, elastic net, and logistic
          regression
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Can now run regression on numeric and categorical data instead of just
          scale data
        </VisibleText>
      </li>
      <li>
        <VisibleText>Can now change the prediction model yourself</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>User experience</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Better filtering of prediction / drivers variables
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          New data uploading formats behind the scenes to enable easier data
          cleaning
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.6</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-06-24
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Added change log</VisibleText>
      </li>
      <li>
        <VisibleText>
          Refactored the way documents are rendered in preparation for 1.6.0
        </VisibleText>
      </li>
      <li>
        <VisibleText>Improved charts & layout of PDF reports</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>User experience</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Improved auto-generating analysis to prevent crashes
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Security</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Updating core packages and a move to a routine updating
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.5</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-06-02
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          To support your journey, your customer success manager can now view
          your touchpoints (but not data, as that is private)
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Language attribute is now more accessible</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bug fixes</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Fixed inability to create new themes</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.4</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-05-26
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Apply simple search filter to various sections that show data
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Significantly expand the size of datasets that can be uploaded.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Added pagination and UX enhancements to the data cleaning wizard.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          The system suggests empty rows for you to drop, making it easier to
          clean large datasets.
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Expanded WCAG AA compliance by passing more of our mandatory
          acessibility tests
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fix behaviour when sharing a dataset with private benchmark group
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed issues pre-selecting benchmark group for users with multiple
          licenses
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Return segments depending on language in question by question
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-05-03
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Content</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Updated library content for better searchability
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fix issue with CitizenFirst users being unable to register
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-04-22
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed selective issue where drivers analysis returns empty independent
          & dependent variables
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed performance outliers that were not working for text
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed issue with crashing when generating themes from new reports
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-04-19
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Expanded use of Custom Modeller by applying Customer Experience models
          (1 NGO and 1 academic model)
        </VisibleText>
      </li>
      <li>
        <VisibleText>Expanded user data recoding capabilities</VisibleText>
      </li>
      <li>
        <VisibleText>
          Created a library to support FAQs, articles, and guides
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Acessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText> Expanded use of loading indicators </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed selective issue where after confirming email, when user logs
          back in the "complete account setup" banner does not show up
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed bug with first time loading of text themes not showing coverage
          for LDA-generated results.
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.5.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2021-03-29
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Released the custom modeller for thematic analysis
        </VisibleText>
      </li>
      <li>
        <VisibleText> Allow text researchers to add notes </VisibleText>
      </li>
      <li>
        <VisibleText>
          Expanded data tagging / labelling for all users
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Automatically suggest and run a performance analysis if possible
        </VisibleText>
      </li>
      <li>
        <VisibleText>Allow users to delete sections of the report</VisibleText>
      </li>
      <li>
        <VisibleText>
          Allow users to track a performance score, and create their own
          combined score (composite index)
        </VisibleText>
      </li>
      <li>
        <VisibleText>Columns and charts are now sortable</VisibleText>
      </li>
      <li>
        <VisibleText>Improved search and filter options</VisibleText>
      </li>
      <li>
        <VisibleText>
          Allow users to set a performance type for the entire project
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Add text data to performance analysis and outliers analysis
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Show multiple levels of sentiment in one view
        </VisibleText>
      </li>
      <li>
        <VisibleText>Launched translation platform</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed accessibility audit issues in dataset wizard
        </VisibleText>
      </li>
      <li>
        <VisibleText>Created table views for all charts</VisibleText>
      </li>
      <li>
        <VisibleText>
          Auto-click themes when generating them to avoid unnecessary clicks
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Improve confirmation messages like saved themes, errors in drivers
          analysis and deletions
        </VisibleText>
      </li>
      <li>
        <VisibleText>Improved navigation of analysis tabs</VisibleText>
      </li>
      <li>
        <VisibleText>
          Improved the viewing and management of themes, summary of themes, and
          theme respondents
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed ban/keyword popup not appearing if dragging over text with
          sentiment labels
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed no active state e.g. on the "submit" button on the "contact us"
          form
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.4.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-12-15
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Added introductory video to the landing page</VisibleText>
      </li>
      <li>
        <VisibleText>
          Allow users to "drop" or "hide" responses in the text view/analysis
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Improved navigation experience when clicking hyperlinks in report
          charts
        </VisibleText>
      </li>
      <li>
        <VisibleText>Improved modals and notification sizes</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Added unique performance item chart title</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.4.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-11-30
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Improved new user experience</VisibleText>
      </li>
      <li>
        <VisibleText>Added a detailed landing page</VisibleText>
      </li>
      <li>
        <VisibleText>Zoomable and interactive text visualizer</VisibleText>
      </li>
      <li>
        <VisibleText>
          Added performance analysis (displays question performance in a chart)
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Added initial 'outlier analysis' which finds unique clusters that
          deviate from the average experience
        </VisibleText>
      </li>
      <li>
        <VisibleText>Made tables collapsible</VisibleText>
      </li>
      <li>
        <VisibleText>
          Users can run multiple regression analyses (drivers) in one project
        </VisibleText>
      </li>
      <li>
        <VisibleText>Allows for multiple licenses at once</VisibleText>
      </li>
      <li>
        <VisibleText>The report view has been made more dynamic</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Achieved 95% compliance on WCAG AA Accessibility Standards
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed selectedTextQuestion null if no text responses
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed querySelector null when expanding/collapsing question values
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed drivers delete button not showing details, selecting/deselecting
          DVs
        </VisibleText>
      </li>
      <li>
        <VisibleText>Fixed reuploading codebook side panel</VisibleText>
      </li>
      <li>
        <VisibleText>Fixed bulk saving of question changes</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.3.3</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-10-08
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Add a drop down list where users can change the open ended question
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.3.2</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-09-10
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Sort by themes</VisibleText>
      </li>
      <li>
        <VisibleText>Visualize themes</VisibleText>
      </li>
      <li>
        <VisibleText>Added sentiment analysis</VisibleText>
      </li>
      <li>
        <VisibleText>
          Hover over axes to provide further information
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Allow users to share datasets and allow edit-mode for shared users to
          datasets and projects
        </VisibleText>
      </li>
      <li>
        <VisibleText>Display interactive data table</VisibleText>
      </li>
      <li>
        <VisibleText>Options and edit options for the report</VisibleText>
      </li>
      <li>
        <VisibleText>Launch dynamic summary algorithms</VisibleText>
      </li>
      <li>
        <VisibleText>Track report versions</VisibleText>
      </li>
      <li>
        <VisibleText>Dataset description more generic</VisibleText>
      </li>
      <li>
        <VisibleText>Improved charting</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Improve deleting projects and datasets</VisibleText>
      </li>
      <li>
        <VisibleText>Expand loading indicators</VisibleText>
      </li>
      <li>
        <VisibleText>Improved performance by optimizing caching</VisibleText>
      </li>
      <li>
        <VisibleText>
          Gives warnings when connecting to incomplete datasets
        </VisibleText>
      </li>
      <li>
        <VisibleText>Improve project renaming</VisibleText>
      </li>
      <li>
        <VisibleText>
          Easier data cleaning and new project process
        </VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Fixed months, day, year</VisibleText>
      </li>
      <li>
        <VisibleText>
          Fixed bug: save bencharks button disabled after creation
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.3.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-07-31
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Fixed issues with uploading CSVs and single-sheet XLS files
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.3.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-07-30
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>
          Expanded number of successful file formats and encodings
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Add "generate themes" to text analysis to give users more control over
          it
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Improved text search experience ('also found', advanced search, and
          other features)
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Improved theme saving and editing experience
        </VisibleText>
      </li>
      <li>
        <VisibleText>Allow multi-words to be added to themes</VisibleText>
      </li>
      <li>
        <VisibleText>Searches 'past projects' for 'past themes'</VisibleText>
      </li>
      <li>
        <VisibleText>
          First view of sentiment over the text responses
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.2.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-07-23
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Improved dataset cleaning wizard visuals</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Better experience when using a code-book</VisibleText>
      </li>
      <li>
        <VisibleText>Improved deleting experience</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Fixed dataset status not updating to complete</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.2.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-06-29
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Create sample dataset for new users</VisibleText>
      </li>
      <li>
        <VisibleText>
          Enable white-label options for organizational users
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Expand drivers analysis beyond overall satisfaction
        </VisibleText>
      </li>
      <li>
        <VisibleText>Improved data view</VisibleText>
      </li>
      <li>
        <VisibleText>Easy sharing through email</VisibleText>
      </li>
      <li>
        <VisibleText>Released build-your-own benchmarking</VisibleText>
      </li>
      <li>
        <VisibleText>
          Launch live-chat for easier problem and question resolution
        </VisibleText>
      </li>
      <li>
        <VisibleText> Improved registration process </VisibleText>
      </li>
      <li>
        <VisibleText>
          Improved data cleaning by adding 'other' types
        </VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.1.1</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-06-09
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Updated methodology section in reports</VisibleText>
      </li>
      <li>
        <VisibleText>Add date and time data types</VisibleText>
      </li>
      <li>
        <VisibleText>First version of text analytics</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Accessibility</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Fix headings and structural semantics</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.1.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-05-29
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>PDF export</VisibleText>
      </li>
      <li>
        <VisibleText>Updated dataset tagging</VisibleText>
      </li>
    </ul>
    <h4>
      <VisibleText>Bugs</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Fixed missing bars when too many comparators</VisibleText>
      </li>
    </ul>
    <hr />
    <h2>v1.0.0</h2>
    <p>
      <VisibleText>Released:</VisibleText>
      2020-04-27
    </p>
    <h3>
      <VisibleText>Changes applied:</VisibleText>
    </h3>
    <h4>
      <VisibleText>Features</VisibleText>
    </h4>
    <ul class="library__list">
      <li>
        <VisibleText>Dataset uploading and cleaning</VisibleText>
      </li>
      <li>
        <VisibleText>Automated benchmarking analysis and drivers</VisibleText>
      </li>
      <li>
        <VisibleText>Automated digital report</VisibleText>
      </li>
    </ul>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "current_version",
  setup() {
    const siteData = reactive({
      title: "PigeonLine's ResearchAI | Current version and change log",
      description:
        "What is ResearchAI's current version? What changes have been introduced?"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  }
}
</script>
