import { HELPER_TRANSLATIONS } from "../../../translations"
import {
  outliersDescription,
  outliersHeader,
  outliersUL
} from "./outliersNodes"

const thisHeader = (lang) => {
  const driversAnalysisIconSrc = "/files/ReportIcons/driversAnalysis.svg"
  let driversAnalysisHeading = `<img src="${driversAnalysisIconSrc}" class="report__header-icon" alt="" />\
${HELPER_TRANSLATIONS.driversHeader[lang]}`
  const heading = {
    type: "heading",
    content: driversAnalysisHeading,
    id: "section-title-outliers",
    meta: {
      headingLevel: "h2",
      pdfConfig: {
        pageBreak: "before",
        style: "title"
      }
    }
  }

  return heading
}

const outliersSection = (data, lang = "en") => {
  const header = outliersHeader(data.outliersTitle, lang)
  const description = outliersDescription()
  const displayUL = outliersUL(data.outliersTitle, data.outliersData, lang)

  return {
    type: "section",
    id: "outliers-section",
    content: [header, description, displayUL],
    meta: {
      outlierUUID: data.outlierUUID,
      subTitle: "OUTLIERS ANALYSIS"
    }
  }
}

export { thisHeader, outliersSection }
