<template>
  <article
    style="display: flex; justify-content: flex-end; margin-bottom: -1em"
  >
    <button
      @click="$emit('toggleSort')"
      class="project-analysis-item__header-wrapper-btn"
      style="display: flex; flex-direction: row"
    >
      <VisibleText>Sorting by: </VisibleText>
      <div v-if="this.sortBy === 'new'">
        <VisibleText>&nbsp; Newest</VisibleText>
      </div>
      <div v-else>
        <VisibleText>&nbsp; Oldest</VisibleText>
      </div>
    </button>
  </article>
</template>

<script>
export default {
  name: "SortProjects",
  props: {
    sortBy: {
      type: String
    }
  }
}
</script>
