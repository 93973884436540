<template>
  <nav aria-label="dataset" id="dataset-progress">
    <ol class="dataset-progress__list">
      <li
        :class="{
          'dataset-progress__list-item-active':
            activeSection === 'DatasetTableView'
        }"
      >
        <button
          class="dataset-progress__view-btn"
          @click="backToDataset"
          :disabled="!$store.getters['datasetDetails/getDataset']"
        >
          <VisibleText class="title">View Dataset</VisibleText>
          <VisibleText class="subtitle">Go to data table view.</VisibleText>
        </button>
      </li>
      <li v-for="(item, index) in items" :key="item.name">
        <div
          v-if="item.activated"
          :class="[
            'dataset-progress__list-item',
            {
              'dataset-progress__list-item-active':
                activeSection === item.component
            }
          ]"
        >
          <button
            class="dataset-progress__list-item-btn"
            :disabled="isDisabled(item)"
            @click="goToSection(item)"
          >
            <VisibleText class="dataset-progress__list-item-title">
              {{ item.name }}
            </VisibleText>
            <div class="dataset-progress__list-item-step">
              <div v-if="index <= 1">
                <VisibleText>Step&nbsp;</VisibleText>
                {{ index + 1 }}
              </div>
              <div v-else>
                <div v-if="isDisabled(item)" style="font-size: 0.8rem">
                  {{ disabledMessage(item) }}
                </div>
                <div v-else>
                  <VisibleText>Optional step</VisibleText>
                </div>
              </div>
            </div>
            <span
              :class="[
                'dataset-progress__list-item-status',
                progressClassName(item)
              ]"
              :data-parent="$options.name"
            >
              {{ translate(sectionProgress(item), $options.name) }}
            </span>
          </button>
          <button
            v-if="index >= 2 && progressClassName(item) !== 'completed'"
            style="
              border: none;
              background-color: transparent;
              font-size: 0.8em;
              margin-bottom: -1em;
              color: var(--error-text-colour);
            "
            @click="removeStep(index)"
          >
            <SvgIconDecorative icon="deleteIcon" />
            <VisibleText>remove step</VisibleText>
          </button>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Mixin
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"

export default {
  name: "DatasetProgressList",
  mixins: [DatasetMixin],
  components: {
    SvgIconDecorative
  },
  props: {
    items: {
      type: Array
    }
  },
  methods: {
    backToDataset() {
      this.setActiveSection("DatasetTableView")
    },
    isDisabled(item) {
      return (
        (item.component !== "DatasetUploader" &&
          !this.datasetProject.dataset_id) ||
        item.blockAccess
      )
    },
    disabledMessage(item) {
      if (item.blockAccess) {
        return "You do not have access to this dataset's benchmarking group"
      }
    },
    sectionProgress(item) {
      let status = "incomplete"
      if (item.completed) status = "completed"
      else if (item.skipped) status = "skipped"
      else if (this.activeSection === item.component) status = "in progress"
      return status
    },
    progressClassName(item) {
      let className = "incomplete"
      if (item.completed) className = "completed"
      else if (item.skipped) className = "skipped"
      else if (this.activeSection === item.component) className = "inprogress"
      return className
    },
    goToSection(item) {
      this.setActiveSection(item.component)
    },
    removeStep(step) {
      this.$emit("removeStep", step)
    }
  }
}
</script>
