import { onDeleteUpdateGlobalQuestionBenchmarkMatchCount } from "@/components/Dataset/Utils/helpers.js"

export default {
  methods: {
    async $_benchmarksMixin_deleteBenchmarkMatch(benchmarkId) {
      try {
        const benchmark = this.benchmarks.find(
          (b) => (b._id.$oid = benchmarkId)
        )
        // decrese number of approved matches in global question
        const globalQ = await this.$services.DATASETS_SERVICE.globalQuestions(
          benchmark.global_question_id
        )
        if (globalQ.matches) {
          onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
            this.$services.DATASETS_SERVICE,
            globalQ,
            benchmark
          )
        }
        await this.$services.BENCHMARKING_SERVICE.deleteBenchmark(benchmarkId)
      } catch (e) {
        throw new Error(
          "datasetDetailsMixin:deleteBenchmarkMatch " + e.messages
        )
      }
    },
    async $_benchmarksMixin_updateMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      shareableDatasetDetails,
      shareableUserDetails
    ) {
      // delete current benchmark
      await this.$_benchmarksMixin_deleteBenchmarkMatch(
        clientQuestion.benchmarking_id
      )

      // update client question based on version
      const clientQ = await this.updateClientQuestionBenchmarkedMatches(
        clientQuestion,
        selectedGlobalQuestionId
      )

      // create new benchmark
      let recommended = ""
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        recommended = clientQuestion.benchmarked_global_question.recommended
      await this.$_benchmarksMixin_newMatch(
        clientQ,
        selectedGlobalQuestionId,
        recommended,
        shareableDatasetDetails,
        shareableUserDetails
      )
    },
    async $_benchmarksMixin_newMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId,
      shareableDatasetDetails,
      shareableUserDetails
    ) {
      const response = await this.newBenchmarkMatch(
        clientQuestion,
        selectedGlobalQuestionId,
        recommendedGlobalQuestionId,
        shareableDatasetDetails,
        shareableUserDetails
      )

      this.updateGlobalQuestionMatchesCounts(selectedGlobalQuestionId)

      // update client question store
      const clientQ = await this.$services.DATASETS_SERVICE.clientQuestions(
        clientQuestion._id.$oid
      )
      this.updateClientQuestion(clientQ)

      // update store values
      this.$_datasetDetailsMixin_reloadBenchmarks()
      this.$store.dispatch(
        "datasetWizard/updateDatasetProjectStatus",
        "benchmarksSent"
      )
      this.$store.dispatch("datasetWizard/removeUnsavedChanges", "step 3")

      return response
    },
    /************
     *
     * Helpers
     *
     ***********/
    clientQuestionIsVersion(clientQuestion, versionNumber = "1") {
      return (
        Boolean(
          clientQuestion.schema_version &&
            clientQuestion.schema_version == versionNumber
        ) || versionNumber === "1"
      )
    },
    async newBenchmarkMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId,
      shareableDatasetDetails,
      shareableUserDetails
    ) {
      try {
        let reformated_client_question = this.deepCloneObj(clientQuestion)
        delete reformated_client_question._id
        const benchmarkGroup =
          this.$store.getters["datasetWizard/getBenchmarkGroup"]
        const profile = this.$store.getters["user/getProfile"]
        if (benchmarkGroup && profile) {
          const data = {
            client_question_id: clientQuestion._id.$oid,
            date_requested: Date.now(),
            client_question: reformated_client_question,
            user_details: shareableUserDetails,
            dataset_details: shareableDatasetDetails,
            global_question_id: selectedGlobalQuestionId,
            recommended_global_question_id: recommendedGlobalQuestionId,
            approved: benchmarkGroup.auto_approve,
            auto_approved: benchmarkGroup.auto_approve,
            temporarily_approved: benchmarkGroup.temporarily_approve,
            admin_email: benchmarkGroup.admin_email,
            share_admin: benchmarkGroup.share_admin,
            owner: {
              user: profile.django_ref_id,
              organization: ""
            }
          }
          const response =
            await this.$services.BENCHMARKING_SERVICE.request(data)
          return response
        }
        return {}
      } catch (e) {
        throw new Error("datasetDetailsMixin:newBenchmarkMatch " + e)
      }
    },
    /**
     * update global question matches counts
     * depending on benchmark group config
     **/

    // This value is also edited by BM Admins when they accept or reject.
    // Here it uses a =+ which is dangerous compared to the admin version which
    // properly counts.
    updateGlobalQuestionMatchesCounts(globalQuestionId) {
      let globalQ = this.globalQuestions.find(
        (q) => q._id.$oid == globalQuestionId
      )
      if (this.$store.getters["datasetWizard/getBenchmarkGroup"].auto_approve) {
        this.$store.dispatch(
          "datasetWizard/updateDatasetProjectStatus",
          "benchmarksApproved"
        )
        globalQ.matches.approved += 1
        this.$services.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      } else {
        globalQ.matches.pending += 1
        this.$services.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      }
    },
    async updateClientQuestionBenchmarkedMatches(clientQuestion, selected) {
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        return clientQuestion
      const benchmarked = { benchmarked_global_question_id: selected }
      const response =
        await this.$services.DATASETS_SERVICE.updateClientQuestion(
          clientQuestion._id.$oid,
          benchmarked
        )
      return response.client_question_object
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  }
}
