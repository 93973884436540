<template>
  <main id="main-content" tabindex="-1">
    <div v-if="hasNoLicenses">You have no registered licenses</div>
    <div v-if="!hasNoLicenses" style="display: flex; flex-direction: row">
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 4rem;
        "
      >
        <h1 class="section__top-bar-title">
          <router-link to="/benchmark-manager">
            <VisibleText>Benchmark Manager</VisibleText>
          </router-link>
        </h1>
        <p>
          <VisibleText>
            Benchmark groups let you anonymously share quantitative data (such
            as from 5-point scale questions in surveys).
          </VisibleText>
        </p>
      </div>

      <div
        class="section__top-bar-actions-wrapper"
        style="margin: 4rem; width: 20rem"
        v-if="$route.name === 'benchmarkManager'"
      >
        <CreateProject
          class="btn-default"
          v-if="newGroup"
          :project="newGroup"
          @projectCreated="newGroupCreated"
          @projectCreationError="newGroupCreationError"
        >
          <VisibleText>Create New Group</VisibleText>
        </CreateProject>
      </div>
    </div>
    <router-view v-if="!hasNoLicenses"></router-view>
  </main>
</template>

<script>
// Components
import { CreateProject } from "@pigeonline/core"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "BenchmarkManager",
  mixins: [UserMixin],
  components: {
    CreateProject
  },
  data() {
    return {
      newGroup: new BenchmarkGroup()
    }
  },
  created() {
    document.title = `Benchmark Manager | ${this.$theme.theme.pageTitle}`
  },
  methods: {
    newGroupCreated(group) {
      this.$router.push({
        name: "benchmarkGroupEdit",
        params: { id: group.id }
      })
    },
    newGroupCreationError() {
      alert("Sorry there was an error creating a new group.")
    }
  }
}
</script>
