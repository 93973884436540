<template>
  <div>
    <p>
      <VisibleText
        >Please answer a few questions about your data. You have subscribed to
        the CitizenFirst Analytics service. By participating, you agree to
        submit anonymized data for the purposes of benchmarking.
      </VisibleText>
    </p>
    <form
      class="form dataset-segments__form dataset__section-group"
      @submit.prevent
      novalidate
    >
      <div class="dataset__section-group-content light-scroll-bar">
        <p class="p">
          <VisibleText> answer the following:</VisibleText>
        </p>
        <DatasetSegmentsQuestionnaire
          :segments="datasetSegments"
          :read-only="readOnly"
        />
        <DatasetSegmentsSurveyDetails
          :survey-details="datasetSurveyDetails"
          :read-only="readOnly"
          :validate="validateSurveyDetails"
          v-if="datasetSurveyDetails"
        />
      </div>
      <button
        type="submit"
        class="dataset__section-save-btn"
        :disabled="!permissions.includes('update-dataset')"
        @click="$emit('save')"
      >
        <VisibleText>Save</VisibleText>
      </button>
      <MessageInline :message="stateText" :message-type="messageType" />
    </form>
  </div>
</template>

<script>
// Components
import DatasetSegmentsQuestionnaire from "../DatasetSegmentsQuestionnaire.vue"
import DatasetSegmentsSurveyDetails from "../DatasetSegmentsSurveyDetails.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"

// Mixin
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

const SURVEY_DETAILS = {
  surveyStartDate: null,
  surveyEndDate: null,
  coverageArea: null,
  converagePopulation: null,
  thirdParty: null,
  dataCollector: null,
  frequency: null,
  procedure: null,
  language: null,
  sources: null,
  rate: null,
  responseRateNa: null
}

export default {
  name: "DatasetSegmentsFormICCS",
  mixins: [DatasetMixin, UserMixin],
  components: {
    DatasetSegmentsQuestionnaire,
    DatasetSegmentsSurveyDetails,
    MessageInline
  },
  props: {
    state: {
      type: Object
    },
    validateSurveyDetails: {
      default: () => false,
      type: Boolean
    }
  },
  async mounted() {
    // initialize survey details
    const body = {
      survey_details: { ...SURVEY_DETAILS }
    }
    if (!this.datasetSurveyDetails) {
      const updatedDataset = await this.$services.DATASETS_SERVICE.update(
        this.$store.getters["datasetDetails/getDataset"]._id.$oid,
        body
      )
      this.$store.dispatch(
        "datasetDetails/setDataset",
        updatedDataset.data_set_object
      )
    }
  },
  computed: {
    messageType() {
      if (this.state.isError) return "error"
      else if (this.state.isSaved) return "success"
      return ""
    },
    stateText() {
      if (this.state.isError)
        return "Sorry there were errors in the form. Please try again."
      else if (this.state.isSaved) return "Dataset details saved."
      return ""
    },
    datasetSurveyDetails() {
      return this.$store.getters["datasetDetails/getDataset"].survey_details
    },
    datasetSegments() {
      return this.$store.getters["datasetDetails/getDataset"].segments
    }
  }
}
</script>
