<template>
  <div class="landing-page__wrapper">
    <header
      class="landing-page__hero-section"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/hero.png')})`
      }"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <div class="landing-page__head">
        <router-link
          to="/"
          exact
          aria-label="home"
          class="landing-page__image landing-page__image--left landing-page__image--logo landing-page__image--padded"
        >
          <img
            style="width: 100%"
            src="/assets/landing/RAI_logo.png"
            alt="Research AI"
          />
        </router-link>
        <nav aria-label="primary" class="landing-page__nav">
          <ul class="landing-page__nav-inner">
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <button
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                @click="isBookADemoExpanded = !isBookADemoExpanded"
              >
                <VisibleText>Request a demo</VisibleText>
              </button>
            </li>
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/register"
              >
                <VisibleText>Register for free</VisibleText>
              </router-link>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/library"
              >
                <VisibleText>Library</VisibleText>
              </router-link>
            </li>
            <li
              class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
              v-show="false"
            >
              <label for="lang-select" class="label">
                <SvgIconDecorative icon="translate" />
                <span class="sr-only"><VisibleText>language</VisibleText></span>
              </label>
              <select
                class="landing-page__select landing-page__font--white landing-page__font--bold"
                id="lang-select"
                @change="changeLanguage($event)"
              >
                <option
                  value="en"
                  :data-parent="$options.name"
                  :selected="currentLang === 'en'"
                >
                  {{ translate("English", $options.name) }}
                </option>
                <option
                  value="fr"
                  :data-parent="$options.name"
                  :selected="currentLang === 'fn'"
                >
                  {{ translate("French", $options.name) }}
                </option>
              </select>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/login"
              >
                <VisibleText v-if="$pigeonline.userIsAuthenticated()"
                  >Return to app</VisibleText
                >
                <VisibleText v-else>Log in</VisibleText>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="landing__content-box">
        <div
          class="landing-page__content-box landing-page__content-box--padded-bottom landing-page__content-box--row-to-col"
        >
          <div class="landing-page__seven landing-page__column-widen">
            <h1
              class="landing-page__font--h1 landing-page__font--white landing-page__font--outline"
              style="padding-right: 1em"
            >
              <VisibleText
                >Introducing Citizen First Analytics - Powered by ResearchAI
              </VisibleText>
            </h1>
            <div
              class="landing-page__font landing-page__font--dark-blue landing-page__font--right-padding landing-page__font--large"
              style="margin-bottom: 1em"
            >
              <VisibleText
                >Improve customer experiences across the public sector thanks to
                the Institute for Citizen-Centred Service.
              </VisibleText>
            </div>
            <a
              class="landing-page__button landing-page__button--small-dark"
              href="https://citizenfirst.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisibleText>Visit CitizenFirst.ca</VisibleText>
            </a>
          </div>
          <div class="landing-page__five landing-page__full-width">
            <div
              style="display: flex; flex-direction: column"
              class="landing-page__font"
            >
              <h2>
                <VisibleText> Get access to: </VisibleText>
              </h2>
              <div style="display: flex; flex-direction: row; padding: 0.5em">
                <img
                  src="/assets/landing/small_rai_logo_white.png"
                  alt=""
                  style="display: flex; height: 2.5em"
                />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding-left: 0.5em;
                  "
                >
                  <VisibleText>
                    the complete ResearchAI suite of tools
                  </VisibleText>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; padding: 0.5em">
                <img
                  src="/assets/landing/improve_icon_white.png"
                  alt=""
                  style="display: flex; height: 2.5em"
                />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding-left: 0.5em;
                  "
                >
                  <VisibleText>
                    public sector comparisons/benchmarks
                  </VisibleText>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; padding: 0.5em">
                <img
                  src="/assets/landing/collect-data_icon_white.png"
                  alt=""
                  style="display: flex; height: 2.5em"
                />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding-left: 0.5em;
                  "
                >
                  <VisibleText> additional ICCS data and reports </VisibleText>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; padding: 0.5em">
                <img
                  src="/assets/landing/voice_white.png"
                  alt=""
                  style="display: flex; height: 2.5em"
                />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding-left: 0.5em;
                  "
                >
                  <VisibleText> the growing community of practice </VisibleText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      tabindex="-1"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <section
        class="landing-page__grey-section landing-page__grey-section--smaller"
      >
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>What is Citizen First Analytics?</VisibleText>
        </h2>
        <p style="text-align: center">
          <VisibleText>
            Citizens First Analytics is a new analytics and benchmarking service
            offered by the Institute for Citizen-Centred Service.
          </VisibleText>
        </p>
        <div
          class="landing-page__content-box landing-page__content-box--mobile-flex"
          role="tablist"
          aria-label="Useful for"
        >
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-drivers"
            role="tab"
            :aria-selected="tab_active === 0"
            :class="[
              tab_active === 0
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 0"
          >
            <VisibleText>Drivers of Customer Experience</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-benchmarking"
            role="tab"
            :aria-selected="tab_active === 1"
            :class="[
              tab_active === 1
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 1"
          >
            <VisibleText>Benchmarking</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-performance"
            role="tab"
            :aria-selected="tab_active === 2"
            :class="[
              tab_active === 2
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 2"
          >
            <VisibleText>Outliers</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-text"
            role="tab"
            :aria-selected="tab_active === 3"
            :class="[
              tab_active === 3
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 3"
          >
            <VisibleText>Text Analysis</VisibleText>
          </button>
        </div>
        <div
          v-show="tab_active === 0"
          aria-labelledby="tab-drivers"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 0"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Drivers of Customer Experience</VisibleText>
            </h3>
            <p>
              <VisibleText>
                The Institute for Citizen-Centred Services uses drivers analysis
                to help governments know where to invest their energy to drive
                service improvement. Learn more from their latest reports,
                <a
                  href="https://citizenfirst.ca/research-and-publications"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Citizens First 2020, and Business First 2022.
                </a>
              </VisibleText>
            </p>
            <p>
              <VisibleText>
                Subscribe to Citizen First Analytics to do the same:
              </VisibleText>
            </p>
            <h4>
              <VisibleText> Predict improvement </VisibleText>
            </h4>

            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Discover what predicts satisfaction, loyalty, and
                    engagement? Choose a measure you want to predict, and then
                    identify its top drivers.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Prioritize top predictors and track progress over time.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Identify drivers that may be unique for different segments.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_CX-drivers')"
                >
                  <img
                    style="width: 50%"
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_CX-drivers.png"
                    alt="Screenshot of customer experience drivers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 1"
          aria-labelledby="tab-benchmarking"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 1"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <div style="display: flex; flex-direction: row">
              <h3 class="landing-page__font--h3--large">
                <VisibleText>Benchmarking</VisibleText>
              </h3>
            </div>
            <p>
              <VisibleText>
                The Institute for Citizen-Centred Service hosts the worlds
                largest public sector benchmarking database. Comprised of
                governments across Canada and around the world, and across a
                diverse range of service types, public sector organizations can
                submit matching questions from the ICCS common measurements tool
                to compare their service delivery to over 80 benchmarks and
                growing.
              </VisibleText>
            </p>
            <div class="landing-page__row-to-col">
              <div>
                <ul class="landing-page__seven landing-page--less-widen">
                  <li>
                    <VisibleText>
                      Match your own survey questions to the ICCS database with
                      ease.
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      Select from over a dozen lenses to customize your
                      comparisons.
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      Create summary tables, detailed question-by-question data,
                      and the identification of priorities for service
                      improvement.
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      Build your own benchmarking database by setting some
                      standard questions.
                    </VisibleText>
                  </li>
                </ul>
                <VisibleText>
                  To learn more about the contents of the Benchmarking Report,
                  including sample charts, see this
                  <a
                    href="https://citizenfirst.ca/measure-and-benchmark/benchmarking-service/benchmarking-report"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sample Report.
                  </a>
                </VisibleText>
              </div>

              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_benchmarking-report')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_benchmarking-report.png"
                    alt="Screenshot of benchmarking analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 2"
          aria-labelledby="tab-performance"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 2"
          style="flex-direction: column"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Outliers</VisibleText>
            </h3>
            <p>
              <VisibleText>
                Some customer segments, like demographics or service type, may
                have a specific experience that is considerably over- or
                under-performing compared to the rest of your customers.
              </VisibleText>
            </p>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Find segments that are outliers by taking advantage of a
                    common approach called 'z-scores'.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Explore outliers by survey question (those who score much
                    higher or lower than others on a scale question).
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Explore outliers by text questions (those who use more
                    negative or positive words).
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_cx-outliers-iccs')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_cx-outliers-iccs.png"
                    alt="Screenshot of segmentation outliers"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 3"
          aria-labelledby="tab-text"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 3"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>Text analytics</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Many of us are sitting on large amounts of text data but do not
                know how to make use of it. Change the way you look at surveys,
                live-chats, social media, or complaints:
              </VisibleText>
            </p>
            <h4>
              <VisibleText> Sort and arrange themes </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Coverage scores tell you precisely how often a theme is
                    mentioned.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Sentiment scores suggest how negative or positive
                    respondents are across your themes.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_text-theme-sentiment')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_text-theme-sentiment.png"
                    alt="Screenshot of text analysis coverage and sentiment"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <h4>
              <VisibleText> Get suggested themes </VisibleText>
            </h4>

            <div style="display: flex; flex-direction: row">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Using the most common text AI (Natural Langauge Processing),
                    get suggested themes based on interesting keywords.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Build your own text models or find themes built from text
                    models built and managed by leading HR and management
                    researchers.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_suggested-themes')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_suggested-themes.png"
                    alt="Screenshot of auto-generation thematic analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; width: 95%"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <VisibleText style="font-weight: 800">
                      Read more:
                    </VisibleText>
                    <SvgIconDecorative icon="externalLink" />
                    <a
                      href="http://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Our guide to text analysis, sentiment analysis, and
                      building your own text models.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="landing-page__section--padded-top">
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>Case studies</VisibleText>
        </h2>
        <div
          aria-label="use-cases-heading"
          aria-describedby="carousel-sr-instructions"
          class="landing-page__carousel-wrapper"
          role="region"
          tabindex="0"
          @focus="onCarouselFocus"
        >
          <ul
            class="landing-page__carousel"
            :style="carousel_transform"
            style="width: 75%; margin: 0 12.5vw 0 12.5vw"
          >
            <li class="landing-page__carousel__item" style="line-height: 1.5em">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >Automation of customer satisfaction analysis and reporting
                  </VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText
                    >Government agencies are using Citizen First Analytics as a
                    simple, fast and low cost means to analyze customer service
                    survey data and provide recommendations to management.
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <ul
                  style="list-style-type: disc; padding: 2em"
                  class="landing-page__font landing-page__font--padded"
                >
                  <li style="margin-bottom: 1em">
                    <VisibleText>
                      A provincial logisitics agency provincial internal
                      logistics agency saved $10,000 in a few minutes of use (by
                      redirecting investments that were earmarked for an area
                      that was over-performing)
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      Canadian provincial service center brought satisfaction
                      reporting from six weeks (manual) to a few days using the
                      platform, across 30,000 customer interaction points
                    </VisibleText>
                  </li>
                </ul>
              </div>
            </li>
            <li class="landing-page__carousel__item" style="line-height: 1.5em">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>Benchmarking</VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>
                    In addition to using Citizen First Analytics to generate
                    benchmarks easily and quickly from the rich ICCS data base,
                    organizations are using Citizens First Analytics to build
                    and operate custom benchmarking data bases.
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <ul
                  class="landing-page__font landing-page__font--padded"
                  style="list-style-type: disc; padding: 2em"
                >
                  <li style="margin-bottom: 1em">
                    <VisibleText>
                      A provincial organization is building a custom
                      benchmarking to measure assess and report the service
                      delivery performance of 30+ office locations. The ease of
                      use and flexibility of Citizens First Analytics allowed
                      this service to be configured and deployed in three days
                      and takes less than 2 hours a month to support.
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      A federal department is working with Citizens First
                      Analytics to centralize, pull-together and normalize its
                      various client satisfaction and service experience
                      measurement tools and data.
                    </VisibleText>
                  </li>
                </ul>
              </div>
            </li>
            <li class="landing-page__carousel__item" style="line-height: 1.5em">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>Text analytics </VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>
                    Citizens First Analytics offers powerful text analytics
                    capabilities, enabling organizations to capture, analyze and
                    act on previously unavailable sources of data. Organizations
                    are now able to pull together text data (e.g. interviews,
                    notes, voice recordings and social media) and instantly
                    organize this data into themes, topics and sentiments.
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <ul
                  style="list-style-type: disc; padding: 2em"
                  class="landing-page__font landing-page__font--padded"
                >
                  <li style="margin-bottom: 1em">
                    <VisibleText>
                      A municipal government was able to use Citizen First
                      Analytics to quickly and accurately capture the voice of
                      the citizen re COVID 19. Analyzing tens of thousands of
                      comments from multiple surveys, public consultations,
                      comment cards, e-mails and calls to contact centre this
                      organization was able to find and report a common set of
                      themes, topics and sentiment to council.
                    </VisibleText>
                  </li>
                  <li>
                    <VisibleText>
                      A Canadian municipality aims to save $100,000 in vendor
                      costs by using the self-service analytics platform.
                    </VisibleText>
                  </li>
                </ul>
              </div>
            </li>
            <li class="landing-page__carousel__item" style="line-height: 1.5em">
              <div
                class="landing-page__split-content landing-page__split-content--left"
              >
                <h3
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>Customer journey analysis </VisibleText>
                </h3>
                <div
                  class="landing-page__font--h3 landing-page__font--white landing-page__font--padded landing-page__font--shrink"
                >
                  <VisibleText>
                    Citizens First Analytics allows those organizations with
                    operational and administrative data to identify and correct
                    service issues at each key step in the service journey.
                    Issues can be flagged and staff can be automatically
                    notified in real time of corrective actions to be taken.
                    This analysis can easily be reported by service, customer
                    segment, time, and location.
                  </VisibleText>
                </div>
              </div>
              <div
                class="landing-page__split-content landing-page__split-content--right"
              >
                <ul
                  style="list-style-type: disc; padding: 2em"
                  class="landing-page__font landing-page__font--padded"
                >
                  <li style="margin-bottom: 1em">
                    <VisibleText>
                      Two large-sized airports identified strategic actions
                      about passenger experiences and pain points across
                      operational data and survey data in real-time with no set
                      up.
                    </VisibleText>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div
            class="landing-page__content-center landing-page--shrink-padding-top"
          >
            <button @click="nextCarousel" class="landing-page__button">
              <VisibleText>Next case</VisibleText>
            </button>
          </div>
        </div>
        <span class="sr-only" aria-live="polite">
          {{ carousel_curr_item }}
        </span>
      </section>

      <section class="landing-page__section--padded-top">
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>Why use it?</VisibleText>
        </h2>
        <div style="padding: 0 3em">
          <p>
            <VisibleText>
              Service delivery managers can improve organizational performance
              by:
            </VisibleText>
          </p>
          <ul style="line-height: 1.5em">
            <li>
              <VisibleText>
                making data-driven strategic and tactical decisions
              </VisibleText>
            </li>
            <li>
              <VisibleText>
                understanding how your respective service areas are rated by
                citizens
              </VisibleText>
            </li>
            <li>
              <VisibleText>
                comparing how your organization is performing against its peers
              </VisibleText>
            </li>
            <li>
              <VisibleText>
                tracking your organization's performance and formulating
                long-term objectives based on empirical research
              </VisibleText>
            </li>
          </ul>
        </div>
        <h2
          class="landing-page__font--h2 landing-page__font--center"
          style="padding-top: 3em"
        >
          <VisibleText>Measure and benchmark</VisibleText>
        </h2>
        <div style="padding: 0 3em">
          <p>
            <VisibleText>
              The ICCS's online Citizen First Analytics uses the common
              measurements tool providing an easy-to-use client satisfaction
              survey analysis and reporting platform that facilitates
              benchmarking to peers. As public-sector managers, you are quickly
              able to understand client expectations, assess levels of
              satisfaction, and identify priorities for improvement.
            </VisibleText>
          </p>
          <p>
            <VisibleText>
              Get customized reporting, giving you a quick summary of key
              findings and immediate access to actionable insights. The
              benchmarking reports include detailed question-by-question
              analysis that is easy to access, interpret and put into action.
            </VisibleText>
          </p>
        </div>
      </section>

      <p id="carousel-sr-instructions" hidden>
        <VisibleText
          >Use the next case button to navigate between slides
        </VisibleText>
      </p>
      <section
        class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--increase-background"
        :style="{
          backgroundImage: `url(${require('@/assets/landing/blue_section2.png')})`
        }"
      >
        <div class="landing-page__content-box">
          <div class="landing-page__seven">
            <img
              class="landing-page__image landing-page__image--inherit-width landing-page__image--inherit-width--small-pad landing-page__image--reverse-margin-top"
              src="/assets/landing/hero_image2.png"
              alt=""
            />
          </div>
          <div class="landing-page__five landing-page--widen">
            <div class="landing-page__shadow" style="padding-bottom: 3em">
              <h2 class="landing-page__font--h2" style="padding-bottom: 1em">
                <VisibleText>Would you like to learn more?</VisibleText>
              </h2>
              <a
                class="landing-page__button landing-page__button--no-padding"
                href="https://citizenfirst.ca/contact"
                target="_blank"
                rel="noopener noreferrer"
                style="font-size: 0.8em"
              >
                Contact the ICCS
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="landing-page__content-box landing-page__content-box--center">
        <div>
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/mail_icon.png"
            alt=""
          />
          <div style="display: inline-block" class="landing-page__font">
            <div class="landing-page__font--blue">
              <VisibleText>Email</VisibleText>
            </div>
            <div>hello@researchai.io</div>
          </div>
        </div>
        <a href="https://www.linkedin.com/company/25166569/" target="_blank">
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/linkedin.png"
            alt=""
          />
          <span style="display: inline-block" class="landing-page__font">
            <span
              class="landing-page__footer-text-block landing-page__font--blue"
            >
              LinkedIn
            </span>
            <span
              class="landing-page__footer-text-block landing-page__font--black landing-page__font--underline"
            >
              <VisibleText>Follow us!</VisibleText>
              <SvgIconDecorative icon="externalLink" style="width: 1rem" />
            </span>
          </span>
          <span class="sr-only">
            <VisibleText>(opens in a new tab)</VisibleText>
          </span>
        </a>
      </div>
      <div
        class="landing-page__content-box landing-page__font landing-page__padded-footer"
      >
        <VisibleText>Copyright © 2024 PigeonLine Inc.</VisibleText>
        <div
          class="landing-page__six landing-page__font landing-page__font--right landing-page__font--margined-top"
        >
          <router-link to="/library/privacy-policy">
            <VisibleText>PRIVACY POLICY</VisibleText>
          </router-link>
          |
          <router-link to="/library/ethics-policy">
            <VisibleText>ETHICS POLICY</VisibleText>
          </router-link>
        </div>
      </div>
    </footer>
    <ContactUs
      v-bind:status="isContactUsExpanded"
      @close="isContactUsExpanded = false"
    ></ContactUs>
    <BookAWorkshop
      v-bind:status="isBookAWorkshopExpanded"
      @close="isBookAWorkshopExpanded = false"
    ></BookAWorkshop>
    <BookADemo
      v-bind:status="isBookADemoExpanded"
      @close="isBookADemoExpanded = false"
    ></BookADemo>
    <VideoClip
      v-bind:status="isVideoClipExpanded"
      :videoShortURL="videoShortURL"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
    <ScreenshotGuide
      :status="isScreenshotExpanded"
      :thisScreenshot="thisScreenshot"
      @close="isScreenshotExpanded = false"
    >
    </ScreenshotGuide>
  </div>
</template>
<script>
import { app } from "@/main.js"
import ContactUs from "@/components/ContactUs/ContactUs.vue"
import BookAWorkshop from "@/components/ContactUs/BookAWorkshop.vue"
import BookADemo from "@/components/ContactUs/BookADemo.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"
import ScreenshotGuide from "@/components/UserGuide/ScreenshotGuide.vue"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import { mapActions } from "vuex"

export default {
  name: "citizenFirstAnalytics",
  components: {
    ContactUs,
    BookAWorkshop,
    BookADemo,
    SvgIconDecorative,
    VideoClip,
    ScreenshotGuide
  },
  data() {
    return {
      tab_active: 0,
      carousel_active: 1,
      carousel_transform: null,
      carousel_curr_item: null,
      transition_class: null,
      isContactUsExpanded: false,
      isBookAWorkshopExpanded: false,
      isBookADemoExpanded: false,
      isVideoClipExpanded: false,
      isScreenshotExpanded: false,
      thisScreenshot: "",
      videoShortURL: ""
    }
  },
  setup() {
    const siteData = reactive({
      title:
        app.config.globalProperties.$theme.theme.pageTitle +
        " | Citizen First Analytics",
      description:
        "ResearchAI powers the Institute For Citizen-Centred Services's Citizen First Analytics, a platform for public sector business intelligence."
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    if (window.localStorage.getItem("apiLanguage"))
      this.setLanguage(window.localStorage.getItem("apiLanguage"))
  },
  computed: {
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    changeLanguage(language) {
      this.setLanguage(language.target.value)
      window.localStorage.setItem("apiLanguage", language.target.value)
    },
    currentLang() {
      return (
        this.$store.getters["getLanguage"] ||
        window.localStorage.getItem("apiLanguage")
      )
    },
    onCarouselFocus() {
      // set to currently active carousel item
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    nextCarousel() {
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      if (this.carousel_active !== length) {
        let shift_amount = 120 * this.carousel_active
        this.carousel_transform =
          "transform: translateX(-" + shift_amount + "%);"
        this.carousel_active = this.carousel_active + 1
      } else {
        this.carousel_active = 1
        this.carousel_transform = "transform: translateX(0);"
      }

      // curr item text for sr
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    openVideo(shortUrl) {
      this.videoShortURL = shortUrl
      this.isVideoClipExpanded = !this.isVideoClipExpanded
    },
    openScreenshot(screenshot) {
      this.thisScreenshot = screenshot
      this.isScreenshotExpanded = true
    }
  }
}
</script>
