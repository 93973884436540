<template>
  <UITappable
    ref="tappable"
    :options="options"
    @ban="ban"
    @search="search"
    @save="save"
    @remove="remove"
  >
    <span>{{ value }}</span>
    <slot :remove="remove" />
  </UITappable>
</template>

<script>
import UITappable from "../UI/Tappable.vue"

export default {
  name: "AlsoFoundKeywordTappable",
  components: {
    UITappable
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: {
        search: {
          text: "search"
        },
        ban: {
          text: "ban"
        },
        save: {
          text: "save"
        }
      }
    }
  },
  computed: {
    tappableEl() {
      return this.$refs["tappable"]
    }
  },
  methods: {
    ban(keyword) {
      this.tappableEl.setSelected(false)
      this.$root.$emit("ban-keyword", keyword)
    },

    search(keyword) {
      this.tappableEl.setSelected(false)
      this.$root.$emit("search-keyword", keyword)
    },

    save(keyword) {
      this.tappableEl.setSelected(false)
      this.$emit("save", keyword)
    },

    remove(keyword) {
      this.tappableEl.setSelected(false)
      this.$parent.similar_.splice(this.$parent.similar.indexOf(keyword), 1)
    }
  }
}
</script>
