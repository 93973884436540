<template>
  <p class="dataset-cleaner__p">
    <VisibleText>
      Help us understand each question's data-type. You need to set or approve
      at least one data-type before you can proceed.
    </VisibleText>
    <VisibleText v-if="parsedLicenses.includes('iccs')"
      >Remember that the CitizenFirst benchmarking requires 5-point scale
      questions.
    </VisibleText>
  </p>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "DatasetCleanerDesc",
  mixins: [UserMixin]
}
</script>
