import { createUUID } from "@pigeonline/core"
import {
  SECTION_TRANSLATIONS,
  LABEL_TRANSLATIONS,
  HELPER_TRANSLATIONS
} from "../../../translations.js"
import {
  formatValue,
  replaceSpaceWithDash,
  formatBenchmarkGroupData,
  getResponseDistributionTable,
  getResponseDistributionChart,
  getTopPerformersTable
} from "./helpers.js"

const header = (lang) => {
  const qbyqIconSrc = "/files/ReportIcons/qByq.svg"
  let qbyqHeading = `<img src="${qbyqIconSrc}" class="report__header-icon" alt=""/>\
  ${SECTION_TRANSLATIONS.qByQHeading[lang]}`

  return {
    type: "heading",
    content: qbyqHeading,
    id: "section-title-question-by-question",
    meta: {
      headingLevel: "h1",
      pdfConfig: {
        pageBreak: "before",
        style: "heading-h1"
      }
    }
  }
}

const description = (lang) => {
  return {
    type: "text",
    content: SECTION_TRANSLATIONS.qByQDesc[lang],
    id: "question-by-question-desc"
  }
}

const questionSummaryNodes = (
  averageScore,
  questionHeadingFormatted,
  questionHeading,
  uniqueVals,
  lang
) => {
  // question title, also the section heading
  let header = {
    type: "heading",
    content: questionHeading,
    id: `q-group-heading-${questionHeadingFormatted}`,
    meta: {
      headingLevel: "h2",
      pdfConfig: {
        pageBreak: "before",
        style: "q-group-heading"
      }
    }
  }

  // average score
  const avg = formatValue(averageScore)
  let avgText = {
    type: "text",
    content: `<strong>${LABEL_TRANSLATIONS.avgScore[lang]}:</strong> ${avg}`,
    id: `q-group-avg-${questionHeadingFormatted}`
  }

  // response distribution table
  let responseDistributionTable = getResponseDistributionTable(
    uniqueVals,
    questionHeadingFormatted,
    lang
  )

  // response distribution bar chart
  let responseDistributionChart = getResponseDistributionChart(
    uniqueVals,
    questionHeadingFormatted,
    lang
  )

  return [header, avgText, responseDistributionTable, responseDistributionChart]
}

/**
 * Construct nodes to be used in "all groups" section.
 *
 * @param {Array} averageValues average_values array for a particular question (containes benchmarking data for each group)
 * @param {Array} overallAverage mean_and_count_by_segment[All]
 * @param {String} questionHeading question title and wording used as a heading
 * @param {String} globalMatchHeadingFormatted global match title formatted to contain dahses
 * @param {Array} lang current report language
 *
 * Note: averageValues and overallAverage is an item from /benchmarking/detailed_results
 *
 * @returns {Array} "All groups" section nodes:
 * - vertical bar chart with averages of all peer organizations
 * - text containing overall average and total number of peer organizations
 * - top performers table
 * - table with averages of all peer organizations
 */
const benchmarksForAllGroupsNodes = (
  averageValues,
  overallAverage,
  questionHeading,
  globalMatchHeadingFormatted,
  lang
) => {
  //undefined all groups
  if (!overallAverage) return []
  //there is none or only one organization in this group
  if (overallAverage[1] < 2) return []
  //sort by mean
  averageValues.sort((a, b) => (a.mean < b.mean ? 1 : -1))

  /** create all groups chart node **/
  let allGroupsChart = {
    type: "verticalBarChart",
    content: {
      title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${HELPER_TRANSLATIONS.qByQChartAllTitle[lang]}</strong> ${questionHeading}</span>`,
      data: []
    },
    meta: {
      headingLevel: "h3",
      primaryBarColour: "#c6cdd2",
      highlightBarColour: "#6A88AA",
      meanLineColour: "#9F200A",
      pdfConfig: {
        pageBreak: "before"
      },
      groupAverage: formatValue(overallAverage[0])
    },
    id: `b-group-chart-all-${globalMatchHeadingFormatted}`
  }

  /** create all groups table node **/
  let allGroupsTable = {
    type: "table",
    content: {
      data: {
        columnNames: HELPER_TRANSLATIONS.qByQAllPerfCols[lang],
        rowNames: averageValues.map((group) => group.org_id),
        data: []
      }
    },
    id: `b-group-table-all-${globalMatchHeadingFormatted}`,
    meta: {
      caption: HELPER_TRANSLATIONS.qByQChartAllPerf[lang],
      lang: lang,
      state: "hidden",
      pdfConfig: {
        ignore: true
      }
    }
  }

  // fill in all groups table and chart node data
  averageValues.forEach((dataset) => {
    // chart data
    let chartObj = {}
    chartObj["value"] = formatValue(dataset.mean)
    chartObj["label"] = dataset.org_id
    if (dataset.is_user) chartObj["highlight"] = true
    allGroupsChart.content.data.push(chartObj)

    // table data
    allGroupsTable.content.data.data.push([
      formatValue(dataset.mean),
      dataset.rank,
      formatValue(dataset.percentile),
      dataset.all_segments.map((s) => s.toLowerCase()).join(", ")
    ])
  })

  /** create all groups top performers table **/
  let performersTable = getTopPerformersTable(averageValues, null, lang)

  /** create all groups average detail text node **/
  let groupAvgDetails = {
    type: "text",
    content: `<span><strong>${
      LABEL_TRANSLATIONS.overallAvg[lang]
    }</strong> ${formatValue(overallAverage[0])}</span><br/>
    <span><strong>${HELPER_TRANSLATIONS.qByQTotalBenchmarks[lang]}</strong>: ${
      overallAverage[1]
    }</span>`,
    id: `b-group-avg-details-${globalMatchHeadingFormatted}`
  }

  return [allGroupsChart, groupAvgDetails, performersTable, allGroupsTable]
}

/**
 * Construct section nodes for each benchmark group of a CMT match.
 * It includes comparison data against each peer organizations in that group.
 *
 * @param {Array} averageValues average_values array for a particular question (contained benchmarking data for each group)
 * @param {Object} overallMeans mean_and_count_by_segment object
 * @param {Array} clientBenchmarkGroups is the benchmark groups the user wants comparators for
 * @param {String} questionHeading is the question header
 * @param {Array} lang current report language
 *
 * Note: averageValues and overallMeans is an item from /benchmarking/detailed_results
 *
 * @returns {Array} section nodes for each benchmark group:
 *
 */
const benchmarksByGroupNodes = (
  averageValues,
  overallMeans,
  clientBenchmarkGroups,
  questionHeading,
  lang
) => {
  let sections = []

  /**
   * loop through each benchmark group selected by the user.
   *
   * for each benchmark group section we have 3 nodes:
   * - vertical bar chart node which includes all peer organizations
   * - text node that includes overall average and total number of peers
   * - top performers table
   * - table with averages for all perr organizations (same data as vertical bar chart)
   **/
  Object.keys(clientBenchmarkGroups).forEach((group) => {
    let nodes = []
    // check if dataset/organization has data for this group
    let benchmarkGroupData = averageValues.filter((avgValuesObj) =>
      Object.keys(avgValuesObj.segments_mean).includes(group)
    )
    // v1.5.1 -- BC requested we dont include groups if no comparators
    if (benchmarkGroupData.length < 2) {
      return
    }
    // sort by mean
    benchmarkGroupData.sort((a, b) =>
      a.segments_mean[group].mean < b.segments_mean[group].mean ? 1 : -1
    )

    /** create bar chart **/
    let chartData = benchmarkGroupData.reduce((d, i) => {
      let obj = {}
      obj["value"] = formatValue(i.segments_mean[group].mean)
      obj["label"] = i.org_id
      if (i.is_user) obj["highlight"] = true
      d.push(obj)
      return d
    }, [])
    let chart = {
      type: "verticalBarChart",
      content: {
        title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${group}</strong> ${questionHeading}</span>`,
        data: chartData
      },
      meta: {
        headingLevel: "h3",
        primaryBarColour: "#c6cdd2",
        highlightBarColour: "#6A88AA",
        meanLineColour: "#9F200A",
        pdfConfig: {
          pageBreak: "before"
        },
        groupAverage: formatValue(overallMeans[group][0])
      },
      id: `b-group-chart-${replaceSpaceWithDash(group)}`
    }
    nodes.push(chart)

    /** create average details text node **/
    if (overallMeans[group]) {
      let groupAvgDetails = {
        type: "text",
        content: `<span><strong>${
          LABEL_TRANSLATIONS.overallAvg[lang]
        }</strong> ${formatValue(overallMeans[group][0])}</span><br/>
        <span><strong>${
          HELPER_TRANSLATIONS.qByQTotalBenchmarks[lang]
        }</strong>: ${overallMeans[group][1]}</span>`,
        id: `b-group-avg-details-${replaceSpaceWithDash(group)}`
      }
      nodes.push(groupAvgDetails)
    }

    /** create top performers table **/
    let performersTable = getTopPerformersTable(benchmarkGroupData, group, lang)
    nodes.push(performersTable)

    /** create all performers in this group table **/
    let groupTable = {
      type: "table",
      content: {
        data: {
          columnNames: HELPER_TRANSLATIONS.qByQAllPerfCols[lang],
          rowNames: benchmarkGroupData.map((group) => group.org_id),
          data: formatBenchmarkGroupData(benchmarkGroupData, group)
        }
      },
      id: `b-group-table-${replaceSpaceWithDash(group)}`,
      meta: {
        caption: HELPER_TRANSLATIONS.qByQChartAllPerf[lang],
        state: "hidden",
        lang: lang,
        pdfConfig: {
          ignore: true
        }
      }
    }
    nodes.push(groupTable)

    /** create benchmarking group section **/
    let benchmarkingGroupSection = {
      type: "section",
      content: nodes,
      uuid: createUUID()
    }

    sections.push(benchmarkingGroupSection)
  })
  return sections
}

export {
  header,
  description,
  questionSummaryNodes,
  benchmarksForAllGroupsNodes,
  benchmarksByGroupNodes
}
