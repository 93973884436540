const HIGHLIGHT_TYPES = {
  // SENTIMENT
  "pos-keyword": {
    id: "pos-keyword",
    label: "Positive Keyword",
    className: "positive-sentiment",
    toolOptions: ["search", "keyword", "note", "ban"]
  },
  "neg-keyword": {
    id: "neg-keyword",
    label: "Negative Keyword",
    className: "negative-sentiment",
    toolOptions: ["search", "keyword", "note", "ban"]
  },

  // THEME
  "theme-keyword": {
    id: "theme-keyword",
    label: "Theme Keyword",
    className: "theme",
    toolOptions: ["search", "note", "ban"]
  },
  "theme-note": {
    id: "theme-note",
    label: "Theme Note",
    className: "note",
    toolOptions: ["remove-note"]
  },

  // sort by themes (match keywords)
  "match-keyword": {
    id: "match-keyword",
    label: "Match Keyword",
    className: "match",
    toolOptions: ["keyword", "note", "ban"]
  },

  // SEARCH
  "search-keyword": {
    id: "search-keyword",
    label: "Search Keyword",
    className: "search",
    toolOptions: ["keyword", "note", "ban"]
  }
}

const SELECTABLE_TOOL_OPTIONS = {
  keyword: {
    id: "keyword",
    label: "Keyword"
  },
  note: {
    id: "note",
    label: "Note"
  },

  similar: {
    id: "similar",
    label: "Similar"
  },
  /* remove buttons */
  "remove-note": {
    id: "remove-note",
    label: "Remove"
  },
  "remove-keyword": {
    id: "remove-keyword",
    label: "Drop theme"
  }
}
// "search"
const SELECTABLE_TOOL_OPTIONS_KEYWORD = ["remove-keyword"]
const SELECTABLE_TOOL_OPTIONS_NOTE = ["similar", "remove-note"]
const SELECTABLE_TOOL_OPTIONS_DEFAULT = ["keyword", "note"]

export {
  HIGHLIGHT_TYPES,
  SELECTABLE_TOOL_OPTIONS,
  SELECTABLE_TOOL_OPTIONS_KEYWORD,
  SELECTABLE_TOOL_OPTIONS_NOTE,
  SELECTABLE_TOOL_OPTIONS_DEFAULT
}
