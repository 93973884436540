<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText> Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1 class="library__h1">
      <VisibleText>What is benchmarking analysis?</VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Benchmarking analysis allows you to compare your performance on certain
        measures to peers. Many institutes, governments and universities produce
        benchmarking data that they make available to the international
        community.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Let's imagine you asked your customers "overall how satisfied with the
        experience were you". This is a very common question in service
        measurement. And an important one. Because so many different
        organizations ask the same or similar questions, it would be nice to be
        able to compared anonmyously to each other. This is benchmarking.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Consider the below results. By simply having "overall satisfaction"
        match to an official benchmarking question (in this case from the
        Institute for Citizen-Centred Service's government database), a report
        is produced that shows this government ranked 4th out of 17. The table
        below it gives anonymous details about the top performers. This way you
        can rest assured your results will be anonymized as well.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/landing/screenshot_benchmarking-report.png"
      alt="screenshot of a completed benchmarking report"
    />

    <h2 class="library__h2">
      <VisibleText>How it works - Request a connection</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>You learned in the </VisibleText>
      <a
        href="javascript:void(0)"
        v-if="isUserGuide"
        @click="$emit('new-page', 'UploadingFiles')"
        onclick="this.style.color = '#3d0071'"
      >
        <VisibleText> Uploading your data</VisibleText>
      </a>
      <VisibleText>
        article that there are optional "steps" during data cleaning that can
        unlock benchmarking.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--15"
      src="/assets/userguide_optional-steps.png"
      alt="screenshot of optional data cleaning steps"
    />
    <p>
      <VisibleText>
        As seen below, after beginning the step "add benchmarking", you will see
        a list of available benchmarking databases that you can connect your
        dataset to. Some of these may be public, some of them may be from your
        organization, while others may be your personal group. Some subscribers
        have access to special datasets like the Institute for Citizen-Centred
        Service's database.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_select-benchmarking-database.png"
      alt="screenshot of available benchmarking groups"
    />
    <p>
      <VisibleText>
        As seen below, after choosing a benchmarking database to make a match
        to, we see information about the database, and a new button over our
        dataset that says "Get suggested matches". Pressing this will look for a
        match between your questions and the database you are connected to.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_benchmarking-match.png"
      alt="screenshot of benchmark matching"
    />
    <p>
      <VisibleText>
        You need to have at least 1 question match to be approved. In the below
        example, Overall Satisfaction has been auto-approved. Some databases
        will have automatic approval while others will require a manager to
        review your request. They will not be able to see your dataset, but will
        see details about this question to confirm the match's quality.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_benchmarking-confirmed-match.png"
      alt="screenshot of benchmark matching approval"
    />
    <p>
      <VisibleText>
        Spend some time to look for matches between your questions and the
        database. You can see benchmark database questions by selecting
        "modify".
      </VisibleText>
    </p>

    <h2 class="library__h2">
      <VisibleText
        >I have matched my questions to a benchmarking database, now
        what?</VisibleText
      >
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        The hard part is over! Producing detailed reports is as simple as
        pressing "Connect dataset to project", give your project a new name, and
        then (as seen below) press the "Benchmarking" tab within the "Analysis"
        tab of your new project.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_benchmarking-analysis-tab.png"
      alt="screenshot of benchmarking analysis widget"
    />
    <p>
      <VisibleText>
        Scroll down, and you will see a summary table showing your matched
        scores compared to the database.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--70"
      src="/assets/userguide_benchmarking-summary.png"
      alt="screenshot of benchmarking summary"
    />
    <p>
      <VisibleText>
        To see the rich detail highlighted at the beginning of this article,
        like graphs and tables for each question, give the summary table a name
        where it says "Please name this slide", then click "Save to report".
        After this completes, you can switch to the Report tab and see the final
        report.
      </VisibleText>
    </p>

    <h2 class="library__h2">
      <VisibleText
        >Adding more benchmarking lenses - comparing to specific
        tags</VisibleText
      >
    </h2>
    <div class="library__heading-line"></div>

    <p>
      <VisibleText
        >You may have noticed another "optional" step in the data cleaning that
        asks you to "add dataset details". Clicking this step will reveal the
        ability to set tags. Why do tags matter? If you happen to "tag" your
        dataset with the same tag as others in your connected benchmarking
        database, you will not only get your results compared to all peers in
        the database, but you will be compared to those that have the same tags
        as well. For example, in the below I am presented with suggested "tags"
        based on my "level of government" (as this is a public sector database).
        If my dataset was municipal, I can compare myself to the 15 other
        municipalities in the database. This is also how the first example in
        this article created a graph and table for "finance and payments"
        services within the Institute for Citizen-Centred Services' database.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__upload05.png"
      alt="screenshot of data labeling step in dataset wizard"
    />

    <h2 class="library__h2">
      <VisibleText>What benchmarking database can I use?</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <h3>
      <VisibleText>ICCS benchmarking data</VisibleText>
    </h3>
    <p>
      <VisibleText>We have partnered with the </VisibleText>
      <a
        href="https://citizenfirst.ca/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VisibleText>Institute for Citizen-Centred Service </VisibleText>
      </a>
      <VisibleText>to make their </VisibleText>
      <a
        href="https://citizenfirst.ca/our-work/measure-and-benchmark"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VisibleText>benchmarking data </VisibleText>
      </a>
      <VisibleText>
        for public sector service experience available to users in a digital and
        self-service way, using ResearchAI. If you are registered for this
        service, you will see ICCS Benchmarking appear when clicking Step 3
        during data cleaning.
      </VisibleText>
    </p>
    <h3>
      <VisibleText>Public and sample data</VisibleText>
    </h3>
    <p>
      <VisibleText>
        At least one dummy database will be available for all users (such as
        ICCS Benchmarking Demo). This copies the shape and format of the ICCS
        benchmarking service, but is not real data. Feel free to use this when
        experimenting. Other organizations may or may not set their groups to
        public, or may share their groups with you directly.
      </VisibleText>
    </p>
    <h3><VisibleText>Create your own benchmarking database</VisibleText></h3>
    <p>
      <VisibleText>
        You can easily create a benchmarking database that you can share with
        colleagues. For example, maybe you work with other departments or other
        regions. Setting a list of common questions to track in studies becomes
        easy to manage.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To create your own group, click your profile icon in the top right. Then
        click “Benchmark Manager”.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--30"
      src="/assets/userguide__useroptions.png"
      alt="screenshot of user options when clicking on your profile avatar"
    />
    <p>
      <VisibleText>
        Here you will see a list of benchmarking databases created by you, or
        shared with you. First, let’s create a new group. Click “Create new
        group” in the top right.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__benchmarkmanager.png"
      alt="screenshot of the view of your benchmarking databases"
    />
    <p>
      <VisibleText>
        Start by naming it. Then, click “Add question”. Give it a question code,
        like, “overall satisfaction”. Give it question wording if it came from a
        survey, like, “Overall I was satisfied with the experience”. Set a
        language, and data type (e.g., what kind of scale question?).
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__benchmarkmanager--addquestion.png"
      alt="screenshot of the add question view for the benchmarking manager"
    />
    <p>
      <VisibleText>
        Add as many questions as you think will be useful for your peers to
        consider measuring.
      </VisibleText>
    </p>
    <h3><VisibleText>Managing your benchmarks</VisibleText></h3>
    <p>
      <VisibleText>
        If you are the creator of a benchmarking databases, you may want to
        control its quality. ResearchAI gives you a number of tools for this. In
        the list of benchmarking databases you can see a toggle that says, “Auto
        approve” on or off. Setting this to on will allow users to submit
        matches and proceed to reporting immediately. Setting this to off will
        mean users will have to wait for their questions to be approved before
        they can create benchmarking reports.
      </VisibleText>
    </p>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "what_is_benchmarking",
  setup() {
    const siteData = reactive({
      title: "What is benchmarking analysis in ResearchAI?",
      description:
        "A guide for conducting benchmarking analysis, compare yourself anonymously to peers"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
