<template>
  <Modal
    class="project-analysis-text-modal"
    header-tag="h3"
    uid="modal-create-note"
    :ref="modalRef"
    :show="show"
    @close="closeModal"
  >
    <template #header
      ><VisibleText>Add a note to a theme</VisibleText></template
    >
    <div
      v-if="selection && selection.content && selection.content.length"
      style="margin-bottom: 2rem"
    >
      <VisibleText>Note: </VisibleText>
      <span style="background-color: lightgreen">
        {{ selection.content }}
      </span>
    </div>
    <div v-if="selection && !selection.content" style="margin-bottom: 2rem">
      <VisibleText>Adding row </VisibleText>
      <span style="background-color: pink">
        {{ selection.row_index }}
      </span>
    </div>
    <fieldset style="display: flex; flex-direction: column">
      <VisibleText v-if="sortedThemes.length">
        Add this note to an existing theme
      </VisibleText>
      <div
        style="display: flex; flex-direction: row; margin-bottom: -1rem"
        v-if="sortedThemes.length"
      >
        <SvgIconMeaningful
          class="complete-icon-svg"
          icon="search"
          title="filter below themes"
          style="margin-top: 1rem"
        />
        <input
          id="filter_themes"
          type="text"
          name="Filter themes"
          v-model="themeAdd.filter"
          placeholder="Filter below themes"
        />
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          max-height: 15rem;
          overflow-y: auto;
          margin-bottom: 1rem;
          padding: 1rem;
        "
      >
        <div
          v-for="(theme, index) in filteredThemes"
          :key="index"
          style="
            font-weight: 500;
            border: none;
            width: 14rem;
            display: flex;
            flex-direction: row;
          "
        >
          <button
            class="ui-rounded-pill__wrapper"
            style="
              font-weight: 500;
              border: none;
              width: 10rem;
              display: flex;
              flex-direction: row;
            "
            :style="{
              'background-color': activeColor(theme.theme_color, index)
            }"
            @click="addThemeToRow(index)"
          >
            <VisibleText
              style="display: flex; width: 80%; overflow-x: hidden"
              >{{ theme.name }}</VisibleText
            >
            <SvgIconDecorative icon="add" style="display: flex; width: 20%" />
          </button>
          <SvgIconDecorative
            v-if="noteSelectTheme == index"
            icon="check"
            style="display: flex; margin-top: 0.5rem"
          />
        </div>
      </div>

      <label
        for="create-theme-from-row"
        v-if="sortedThemes.length"
        style="width: 80%; padding-left: 1rem"
      >
        <VisibleText> Or create a new theme </VisibleText>
      </label>
      <label for="create-theme-from-row" v-if="!sortedThemes.length">
        <VisibleText> Create a theme you think matches this text </VisibleText>
      </label>
      <div
        style="
          display: flex;
          flex-direction: row;
          margin-bottom: -0.5rem;
          width: 80%;
          padding-left: 1rem;
        "
      >
        <SvgIconMeaningful
          class="complete-icon-svg"
          icon="add"
          title="Add new theme"
          style="margin-top: 1rem"
          v-if="!themeAdd.themeName.length"
        />
        <input
          id="create-theme-from-row"
          type="text"
          name="New theme name"
          v-model="themeAdd.themeName"
          placeholder="New theme name"
        />
      </div>
      <div
        v-if="
          themeAdd.themeName.length &&
          sortedThemes
            .map((theme) => theme.name.toLowerCase())
            .includes(themeAdd.themeName.toLowerCase())
        "
        style="
          padding-left: 1rem;
          padding-bottom: 1rem;
          color: var(--warning-text-colour);
        "
      >
        <VisibleText>Theme name already exists</VisibleText>
      </div>
    </fieldset>

    <fieldset style="display: flex; flex-direction: column">
      <label
        style="display: flex"
        class="create-note__label"
        :for="`create-note-${id}`"
      >
        <VisibleText
          >To add text or commentary to your note, enter it below</VisibleText
        >
      </label>
      <textarea
        :id="`create-note-${id}`"
        class="create-note__textarea"
        style="width: 80%; display: flex"
        v-model="noteText"
      >
      </textarea>
    </fieldset>

    <template #footer>
      <button
        class="create-note__save-btn btn-default"
        type="button"
        @click.prevent="chooseSave"
        :disabled="!isSaveBtnEnabled || this.propReadOnly"
      >
        <VisibleText>Create note</VisibleText>
      </button>
      <button class="btn-cancel" type="button" @click="closeModal">
        <VisibleText>Close</VisibleText>
      </button>
      <span class="create-note__hint" v-show="isHintVisible">
        <SvgIconDecorative icon="info" />
        <VisibleText>All fields required.</VisibleText>
      </span>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Mixins
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import themeListMixin from "../Mixins/themeListMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"

export default {
  name: "CreateNote",
  mixins: [textAnalysisMixin, themeListMixin, themeColorsMixin],
  components: {
    Modal,
    SvgIconDecorative,
    SvgIconMeaningful
  },
  props: {
    show: {
      default: () => false,
      type: Boolean
    },
    selection: {
      type: Object,
      required: false
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    allResponses: {
      default: () => [],
      type: Array
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      updateKey: Date.now(),
      noteText: "",
      noteSelectTheme: -1,
      id: this.$pigeonline.createUUID(),
      showNewTheme: false,
      themeAdd: {
        isExpanded: false,
        themeName: "",
        filter: ""
      }
    }
  },
  created() {
    if (this.sortedThemes.length) {
      this.themeList = this.deepCloneObj(this.sortedThemes)
    }
  },
  computed: {
    text: function () {
      if (!this.selection) {
        return ""
      }
      return JSON.stringify(this.selection)
    },
    isSaveBtnEnabled: function () {
      return (
        (this.noteSelectTheme > -1 ||
          (this.themeAdd.themeName.length > 0 &&
            !this.sortedThemes
              .map((theme) => theme.name.toLowerCase())
              .includes(this.themeAdd.themeName.toLowerCase()))) &&
        !this.textAnalysisObject.showSpinner
      )
    },
    isHintVisible: function () {
      return this.noteText.trim() === "" && this.noteSelectTheme == -1
    },
    UUIDofTheme: function () {
      let theme = ""
      if (this.noteSelectTheme > -1) {
        theme = this.filteredThemes[this.noteSelectTheme]._id
      }
      return theme
    },
    filteredThemes() {
      let list_of_themes = this.deepCloneObj(this.sortedThemes)
      if (this.themeAdd.filter) {
        list_of_themes = list_of_themes.filter((theme) =>
          theme.name.toLowerCase().includes(this.themeAdd.filter.toLowerCase())
        )
      }
      return list_of_themes
    }
  },
  methods: {
    refreshThemesFromParent() {
      this.themeList = this.deepCloneObj(this.sortedThemes)
    },
    // This gets called by this component's use of textAnalysisMixin after running ProcessSave
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    addThemeToRow(index) {
      if (this.noteSelectTheme == -1) {
        this.noteSelectTheme = index
      } else {
        if (this.noteSelectTheme == index) {
          this.noteSelectTheme = -1
        } else {
          this.noteSelectTheme = index
        }
      }
    },
    async chooseSave() {
      if (this.themeAdd.themeName.length > 0) {
        await this.saveNewTheme()
      } else if (this.noteSelectTheme > -1) {
        await this.save()
      }
    },
    activeColor(color, index) {
      let newColor = color
      if (this.noteSelectTheme > -1 && this.noteSelectTheme != index) {
        newColor = "grey"
      }
      if (this.themeAdd.themeName.length > 0) {
        newColor = "grey"
      }
      return newColor
    },
    async save() {
      // show spinner
      this.setShowSpinner(true)

      if (this.UUIDofTheme) {
        let theseThemes = this.deepCloneObj(this.sortedThemes)
        for (let i = 0; i < theseThemes.length; i++) {
          if (theseThemes[i]._id == this.UUIDofTheme) {
            let note = {
              ...this.selection,
              text: this.noteText
            }
            theseThemes[i].notes.push(note)
            let themes = []

            if (theseThemes[i]._id) {
              if (theseThemes[i]._id.$oid) {
                theseThemes[i]._id = theseThemes[i]._id.$oid
              }
            }
            if (theseThemes[i].is_proposed) {
              delete theseThemes[i].is_proposed
              delete theseThemes[i]._id
            }
            if (theseThemes[i].is_autogenerated) {
              delete theseThemes[i].is_autogenerated
              delete theseThemes[i]._id
            }

            themes.push(theseThemes[i])
            let payload = {
              themes: themes,
              text_analysis_uuid: this.textAnalysisObject.uuid
            }
            const savedThemes = await this.TEXT_SERVICE.saveThemes(
              payload
            ).then((response) => {
              return response
            })
            if (savedThemes.length) {
              let updatedThemeInfo = {
                themes: savedThemes,
                source: "CreateNote"
              }
              this.$emit("updateTheseThemes", updatedThemeInfo)
              this.closeModal()
            }
          }
        }

        this.setShowSpinner(false)
        this.closeModal()
      }
    },
    async saveNewTheme() {
      if (this.themeAdd.themeName.length) {
        const new_theme = await this.createTheme(this.themeAdd.themeName, [], {
          ...this.selection,
          text: this.noteText
        })
        let themes = []
        if (new_theme.is_proposed || new_theme.is_autogenerated) {
          if (new_theme.is_proposed) {
            delete new_theme.is_proposed
          }
          if (new_theme.is_autogenerated) {
            delete new_theme.is_autogenerated
          }

          delete new_theme["_id"]
        }
        let found_colors = this.sortedThemes.map((theme) => theme.theme_color)
        let newColor = "#6a88aa"
        let assignedColor = await this.assignColor(found_colors)
        if (assignedColor) {
          newColor = assignedColor
        }
        new_theme.theme_color = newColor
        themes.unshift(new_theme)
        if (new_theme) {
          let payload = {
            themes: themes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (theseThemes.length) {
            let updatedThemeInfo = {
              themes: theseThemes,
              source: "CreateNote"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
          }
        }

        // await this.saveTextThemes({
        //   themes: [...this.themeList],
        //   forceCompute: false,
        //   uuid: this.textAnalysisObject.uuid
        // })
        //this.noteSelectTheme = this.themeList.length - 1
        this.themeAdd.themeName = ""
        this.showNewTheme = false
        this.closeModal()
      }
    },
    closeModal() {
      this.themeAdd.themeName = ""
      this.showNewTheme = false
      this.noteSelectTheme = -1
      this.noteText = ""
      this.$emit("closeModal")
    }
  }
}
</script>
