<template>
  <button
    class="clickable-box"
    :key="'license-button' + license.name"
    :style="
      isSelected
        ? 'background-color: var(--primary-brand-ui-colour); color:white;'
        : ''
    "
    style="
      font-weight: 800;
      font-family: Core-Sans;
      border-radius: 0.75rem;
      height: 2rem;
      width: 8rem;
    "
    @click="$emit('changeSelectedLicense', license)"
    v-if="license"
  >
    <div style="display: flex; flex-direction: row">
      <div
        style="display: flex; margin-right: 1rem"
        v-if="getLicenseType(license) == 'Team'"
      >
        <img
          :src="
            isSelected
              ? '/assets/users-team-white.png'
              : '/assets/users-team-black.png'
          "
          style="width: 2rem; height: 1.25rem"
        />
      </div>
      <div
        style="display: flex; margin-right: 1rem"
        v-if="getLicenseType(license) == 'Individual'"
      >
        <img
          :src="
            isSelected
              ? '/assets/users-individual-white.png'
              : '/assets/users-individual-black.png'
          "
          style="width: 2rem; height: 1.25rem; margin: auto; 0"
        />
      </div>

      <div style="display: flex">
        {{ capitalizeFirstLetter(license.name) }}
      </div>
    </div>
  </button>
</template>

<script>
import UserCardMixin from "@/components/UI/UserCards/userCardMixin"

export default {
  name: "LicenseCardSmall",
  mixins: [UserCardMixin],
  props: {
    isSelected: {
      default: false,
      type: Boolean
    },
    license: {
      default: () => {},
      type: Object
    }
  }
}
</script>
