<template>
  <main id="main-content" tabindex="-1">
    <div v-if="hasNoLicenses">You have no registered licenses</div>
    <div v-if="!project && !isLoading" style="padding: 2rem">
      <VisibleText
        >This project either does not exist or you do not have access to
        it.</VisibleText
      >
    </div>
    <div v-if="isLoading" style="display: flex; width: 100%">
      <div
        class="also-found__loading-spinner spinner"
        style="margin: 5rem auto; width: 1rem; height: 1rem"
      ></div>
    </div>
    <article id="project" v-if="project && !hasNoLicenses">
      <!--------------------------------------------
      |
      | HEADER
      |
      --------------------------------------------->
      <div class="project__top-bar" :aria-hidden="modalOpen" :inert="modalOpen">
        <InlineEditTextInput
          label="project title"
          input-id="project-title-input"
          input-name="project-title"
          :disable-editing="isProjectReadOnly"
          :input-value="project.name"
          @save="saveProjectTitle"
        >
          <h1 class="project__title">
            {{ project.name }}
          </h1>
        </InlineEditTextInput>
        <span class="project__date">
          <VisibleText>Last modified on </VisibleText>
          {{
            formatDateTime(
              project.last_modified_on.$date,
              $store.getters.getLanguage
            )
          }}
        </span>
      </div>

      <!--------------------------------------------
      |
      | TABS AND ACTIONS
      |
      --------------------------------------------->
      <div
        class="project__top-bar-2"
        :aria-hidden="modalOpen"
        :inert="modalOpen"
      >
        <ProjectTabs
          :project="project"
          ref="ProjectTabs"
          :selectedSlide="selectedSlide"
        />
        <SaveToPdf
          :document="report"
          class="project__top-bar-2-btn project__export-btn"
          v-if="['report', 'report-edit'].includes($route.name) && report"
        >
          <ExportIcon class="svg-icon" />
          <VisibleText>Export To PDF</VisibleText>
        </SaveToPdf>
        <button
          class="project__top-bar-2-btn btn-default"
          v-if="
            isProjectOwner &&
            !(
              this.parsedLicenses.length == 1 &&
              this.parsedLicenses.includes('demo')
            )
          "
          @click="isShareModalVisible = true"
        >
          <SvgIconDecorative icon="share" />
          <VisibleText>Share Project</VisibleText>
        </button>
      </div>

      <!--------------------------------------------
      |
      | MAIN CONTENT
      | if dataset was deleted, only allow the report
      |
      --------------------------------------------->
      <div class="project__content" v-if="!dataset || errDatasetDeleted">
        <DatasetDeletedMessage
          v-if="!['report', 'report-edit'].includes($route.name)"
        />
        <template v-else>
          <!-- Saved project is not a function, delete -->
          <router-view
            :prop-read-only="isProjectReadOnly"
            :isDatasetReadOnly="isDatasetReadOnly"
            @updateProject="saveProject"
            @loaded="report = $event"
            @updateSlide="updateCollection"
          ></router-view>
        </template>
      </div>

      <div
        class="project__content"
        style="padding-top: 0.5rem"
        v-if="dataset && datasetDetailsLoaded"
        :style="
          ['report-new', 'report-edit', 'report'].includes($route.name)
            ? ' background: var(--base-ui-colour);'
            : ''
        "
      >
        <!--<ProjectSettings-->
        <!--v-show="!['report-new', 'report-edit'].includes($route.name)"-->
        <!--/>-->
        <div
          v-if="$route.name === 'report-edit' && report && slides.length > 1"
          style="text-align: right; cursor: pointer; margin-right: 1em"
          @click="toggleSlide"
        >
          Jump to next slide
          <!-- must be button! -->
          <NextPage style="width: 1em" />
        </div>
        <MessageBlock
          class="project__demo-msg"
          message-type="information"
          v-if="isReadOnly && $route.name !== 'report-edit'"
        >
          <VisibleText
            >This is a read-only project. Any changes you make will not be
            saved.
          </VisibleText>
        </MessageBlock>

        <DatasetSummary
          v-if="
            !['report-new', 'report-edit', 'report'].includes($route.name) &&
            !$route.path.includes('analysis')
          "
        />
        <div
          v-if="
            !['report-new', 'report-edit', 'report'].includes($route.name) &&
            !$route.path.includes('analysis') &&
            dataset._id &&
            dataset._id.$oid &&
            datasetProject &&
            datasetProject.id
          "
          style="display: flex; width: 90%; margin: 0 auto"
        >
          <div style="display: flex; margin-left: auto; margin-top: 1rem">
            <router-link
              class="datasets__table-name"
              :to="`/dataset/${dataset._id.$oid}?project_id=${datasetProject.id}`"
              target="_blank"
            >
              <VisibleText> Edit dataset in a new tab </VisibleText>
              <SvgIconDecorative
                style="margin-left: -0.6rem; margin-top: -0.3rem"
                icon="externalLink"
              />
            </router-link>
          </div>
        </div>
        <!-- <keep-alive exclude="PigeonDoc"> -->
        <!-- :licenses="licenses" -->
        <router-view
          :isICCS="$theme.theme.name == 'iccs'"
          :prop-read-only="isProjectReadOnly"
          :isDatasetReadOnly="isDatasetReadOnly"
          @updateProject="saveProject"
          @loaded="loaded"
          @updateSlides="updateCollection"
          @user-guide-expanded="$emit('user-guide-expanded')"
          @open-article="$emit('open-article', $event)"
        ></router-view>
        <!-- </keep-alive> -->
      </div>
    </article>

    <!--------------------------------------------
      |
      | SHARE MODAL
      |
      --------------------------------------------->
    <ProjectShareModal
      :parentProject="project"
      :parentPigeonDoc="report"
      :parentCollection="collection"
      :dataset="datasetProject"
      :show="isShareModalVisible"
      @closeModal="closeModal"
    />
  </main>
</template>

<script>
// Components
import DatasetSummary from "@/components/Dataset/Components/DatasetSummary.vue"
import DatasetDeletedMessage from "@/components/Project/ProjectDatasetDeletedMessage.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import ProjectTabs from "@/components/Project/ProjectTabs.vue"
// import ProjectSettings from "@/components/Project/ProjectSettings/ProjectSettings.vue"
import ProjectShareModal from "@/components/Share/ShareProject.vue"
import { SaveToPdf } from "@pigeonline/pigeondoc"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

import { PigeonDocModel, PigeonDocCollectionModel } from "@pigeonline/pigeondoc"

// Icons
import ExportIcon from "@/assets/icons/export.svg"
import NextPage from "@/assets/sprites/nextPage.svg"

// Mixins
import UserMixin from "@/utils/mixins/userMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"

// Utils
import { mapActions, mapGetters } from "vuex"
import moment from "moment"
import {
  fetchDataset,
  fetchDatasetProjectByDatasetId
} from "@/components/Dataset/Utils/fetch.js"
import { fetchProject } from "@/components/Project/Utils/fetch.js"

export default {
  name: "Project",
  mixins: [
    UserMixin,
    ProjectMixin,
    DatasetDetailsMixin,
    SharedStatusMixin,
    FiltersMixin
  ],
  components: {
    DatasetSummary,
    DatasetDeletedMessage,
    InlineEditTextInput,
    ProjectTabs,
    ProjectShareModal,
    SaveToPdf,
    MessageBlock,
    ExportIcon,
    NextPage,
    SvgIconDecorative
  },
  data() {
    return {
      isShareModalVisible: false,
      errDatasetDeleted: false,
      report: null,
      collection: null,
      slides: [],
      reportIndex: 0,
      selectedSlide: "",
      isDatasetReadOnly: true,
      isReadOnly: true,
      isLoading: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetDatasetDetails()
    this.resetProject()
    this.resetConfirm()
    // this.$store.dispatch("analysisText/resetAnalysisText") // Is this being used?
    this.$store.dispatch("resetGlobalModule")
    next()
  },
  mounted() {
    this.isLoading = true
    if (this.$route.params.projid) {
      Promise.all([fetchProject(this.$route.params.projid)]).then(
        async (project) => {
          if (project[0].datasets) {
            const dataset = await fetchDataset(project[0].datasets[0])
            if (dataset && (dataset._id || dataset.id)) {
              await this.setData(null, project[0], dataset)
              this.updateCollection()
            } else {
              this.setData("dataset-deleted", project[0], {})
            }
          }
        }
      )
      this.isShareModalVisible = this.$route.query.share == "true"
    }
  },
  computed: {
    ...mapGetters("datasetWizard", {
      datasetProject: "getDatasetProject"
    }),
    isProjectReadOnly() {
      return this.isReadOnly
    },
    // isDatasetReadOnly() {
    //   const be_id = this.profile.django_ref_id
    //   // if guest, and shared status = read
    //   if (this.datasetProject && this.datasetProject.owner != be_id) {
    //     for (let i = 0; i < this.datasetProject.shared.length; i++) {
    //       if (this.datasetProject.shared[i].pk == this.profile.django_ref_id) {
    //         if (this.datasetProject.shared[i].perms.length) {
    //           if (this.datasetProject.shared[i].perms.includes("read")) {
    //             return true
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return false
    // },
    thisCollectionId() {
      if (this.project) {
        if (this.project.collection_id) {
          return this.project.collection_id
        }
      }
      return ""
    }
  },
  methods: {
    ...mapActions("datasetWizard", ["setDatasetProject"]),
    /**
     * when pigeondoc (report) is loaded add theme to class name
     * to apply the appropriate themeing
     * FIXME: temporary solution, need to apply themeing to pigondoc in future
     */
    loaded(report) {
      // this.report = report
      const theme = this.$theme.theme.name
      const pigeondocWrapper = document.getElementsByClassName("pigeondoc")
      pigeondocWrapper[0].classList.add(`theme-${theme}`)
      document.title = `${this.project.name} - Report | ${this.$theme.theme.pageTitle}`
    },
    onFetchError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem fetching your project. Please try again."
      })
      this.setConfirmSourceComponent("project")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    },
    async setData(err, project, dataset) {
      if (err && err !== "dataset-deleted") this.onFetchError()
      else {
        if (!this.profile) {
          await this.setUserDetails()
        }

        this.isReadOnly = await this.checkIsReadOnly(project)
        await this.setProject(project)
        if (err === "dataset-deleted") {
          this.errDatasetDeleted = true
          document.title = `Deleted ${project.name} | ${this.$theme.theme.pageTitle}`
        } else if (dataset) {
          await this.setDataset(dataset)
          await this.loadDatasetDetails(dataset)
          await this.setDatasetProject(
            (await fetchDatasetProjectByDatasetId(dataset._id.$oid))[0]
          )
          this.isDatasetReadOnly = await this.checkIsReadOnly(
            this.datasetProject
          )
        }
      }
      this.isLoading = false
    },
    async saveProjectTitle(text) {
      try {
        this.project.name = text
        this.cleanAndSaveProject()
      } catch (e) {
        throw new Error("Project.vue:saveProject " + e.message)
      }
    },
    async cleanAndSaveProject() {
      if (!this.isReadOnly && !this.propReadOnly) {
        let cleanedProject = this.deepCloneObj(this.project)
        cleanedProject.id = this.project.id
        if (cleanedProject._id) {
          delete cleanedProject._id
        }
        this.saveProject(cleanedProject)
      }
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },

    /* Modal actions */
    closeModal() {
      this.isShareModalVisible = false
    },
    async updateCollection() {
      if (this.project && this.project.collection_id) {
        if (this.project.collection_id.length) {
          this.collection = await this.$pigeonline.projects.getByID(
            PigeonDocCollectionModel,
            this.project.collection_id
          )
          if (this.collection) {
            if (this.collection.docIds) {
              this.slides = this.collection.docIds
              this.selectedSlide = this.slides[this.reportIndex]
            }
          }
        }
      }
      this.updateReport()
    },
    async updateReport() {
      if (this.project && this.project.report) {
        if (this.project.report.length) {
          this.report = await this.$pigeonline.projects.getByID(
            PigeonDocModel,
            this.project.report
          )
        }
      }
    },
    async toggleSlide() {
      // find what position in the list
      let thisIndex = 0
      for (let i = 0; i < this.slides.length; i++) {
        if (window.location.href.indexOf(this.slides[i]) != -1) {
          thisIndex = i
        }
      }
      if (thisIndex === this.slides.length - 1) {
        this.reportIndex = 0
      } else {
        this.reportIndex = this.reportIndex + 1
      }
      await this.updateCollection()

      let pathEnd = "empty"
      if (this.selectedSlide) {
        pathEnd = `report/${this.selectedSlide}`
      }
      let fullPath = `/project/${this.project.id}/${pathEnd}`
      this.$router.push(fullPath)
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  },
  watch: {
    modalOpen: function (isOpen) {
      if (this.$route.name === "report-edit") {
        isOpen
          ? document
              .querySelector("div.pigeondoc")
              .setAttribute("aria-hidden", true)
          : document
              .querySelector("div.pigeondoc")
              .removeAttribute("aria-hidden")
      }
    },
    thisCollectionId: async function (val) {
      if (val) {
        let thisCollection = await this.$pigeonline.projects.getByID(
          PigeonDocCollectionModel,
          val
        )
        if (thisCollection) {
          if (thisCollection.docIds) {
            this.slides = thisCollection.docIds
            this.selectedSlide = this.slides[this.reportIndex]
          }
        }
      }
    }
  }
}
</script>
