<template>
  <div class="library__article">
    <h1 class="library__h1">
      <VisibleText>Your Privacy at PigeonLine</VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        When you use Pigeonline’s products and services you trust us with some
        of your information. Here at Pigeonline, we take privacy and protection
        of your data very seriously. This Privacy Policy is meant to help you
        understand what data we collect, how we use it, and why we collect it.
        Please do take the time to read this Privacy Policy carefully, as this
        is important. At Pigeonline, we comply with the highest possible
        standards for data protection and privacy globally, and are compliant
        with the The Personal Information Protection and Electronic Documents
        Act (PIPEDA) in Canada as well as the applicable European framework, the
        General Data Protection Regulation.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>What Information We Collect</VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        At Pigeonline, when it comes to data protection and privacy, we follow
        the noteworthy principle of data minimization - i.e, we strive to
        collect the least amount of data possible in order to deliver our
        products and services, and to make them better over time.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        To that end, Pigeonline does not share your data or information with any
        third parties for monetary or other business purposes.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        User Account Data: To enable the functioning of our products, we require
        that you, our Customers create a User Account – which means an account
        required to access and/or use certain areas and features of our
        products. When you do so, information about your User Account such as
        email, name, organization and session data is generated. This
        information, connected to the user is called User Account Data.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The following types of data are collected as User Account Data
      </VisibleText>
      :
    </p>
    <ul class="library__list">
      <li><VisibleText>First and last names</VisibleText></li>
      <li><VisibleText>Organizational affiliation</VisibleText></li>
      <li><VisibleText>Email</VisibleText></li>
      <li><VisibleText>Credit card details</VisibleText></li>
    </ul>
    <p>
      <VisibleText>
        Benchmarking Data: Data that is input by Customers and their Users, and
        used for benchmarking with external organizations in the course of their
        use of Pigeonline’s products, such as Survey Question Performance. This
        cannot include any personally identifiable information.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        User File Data: Any file or information uploaded by a Customer or their
        Users to use the products of Pigeonline and to carry out analysis. This
        includes the raw data directly uploaded by a User, for the purposes of
        analytics.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        This User File Data is encrypted using two keys, one generated
        automatically by the User’s password and only accessible to the user,
        and the other generated by Pigeonline’s designated security officer and
        manually applied to the software, and is inaccessible to developers and
        third-parties. Without both keys, datasets cannot be accessed. This
        means User File Data is only accessible by the User, as no one at
        Pigeonline has access to their key.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Session Data: This refers to information generated by Pigeonline’s
        products, when a User uses the product or accesses the tool. Session
        data reflects usage of the products and as such, helps Pigeonline
        improve subsequent versions of its products. This information is
        generated and recorded by the product.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        The following types of data are collected as Session Data
      </VisibleText>
      :
    </p>
    <ul class="library__list">
      <li><VisibleText>IP address</VisibleText></li>
      <li><VisibleText>Web browser type and version</VisibleText></li>
      <li><VisibleText>operating system</VisibleText></li>
    </ul>
    <p>
      <VisibleText>
        Machine learning modelling data: When users interact with the algorithms
        and the infrastructure of the product, data relating to the use of the
        product is generated. This could include data about the quality of
        question matching, accuracy of determining text sentiment, keywords on
        topics, and model hyper parameters. This category of information is also
        generated by Pigeonline’s product itself and assists in improving the
        machine learning models that form the underlying infrastructure of the
        product, and therefore the performance and quality of the product
        itself.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        For more information on how this data is used, and what you can do about
        changing the way we use and protect your data, please go to the
        subsequent sections.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>How We Use the Information We Collect</VisibleText>
      :
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        As part of its commitment to privacy and data protection, Pigeonline
        ensures that even the minimal data collected is only accessible by, and
        controlled by senior – level staff and protected by strict access
        protocols. While different kinds of information are used differently,
        the theme running through our privacy policy is to restrict access and
        management of such data to as few people as possible; The ways in which
        the different kinds of data are used is explained below
      </VisibleText>
      :
    </p>
    <p>
      <VisibleText>
        User Account Data and Sessions Data: Information associated with your
        User Account is only accessible to Customer Success Managers – these are
        our customer facing employees / contractors who interact with our
        Customers and manage their use of our Products. As such, other employees
        / contractors who are not in positions of relational support to
        Customers cannot access or review your User Account Data or Sessions
        data. We use this data for
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>Responding to communications from users</VisibleText>
      </li>
      <li>
        <VisibleText>
          Tailoring the Product for specific needs of the Users
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          Notifying Users of promotions and other Product and Platform related
          news.
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        Benchmarking Data: Data that is input by the Users or Customers can
        anonymously and optionally be compared to external organizations, this
        is regarded as Benchmarking data. This cannot include personally
        identifiable information and we expect that Users will assist us in
        maintaining this standard by not submitting such information as
        benchmarking data. We use this data to
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          Maintain curated databases of anonymous benchmarking data for the
          purpose of peer-to-peer comparisons.
        </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        Users or Customers do not need to use benchmarking services in order to
        access the other services in the Product.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        User File Data: Access to this category of data is controlled by the
        User itself and whoever they choose to share it with (their own
        organization and Pigeonline). Thus, these categories of data cannot be
        accessed directly by anyone from Pigeonline, unless it is explicitly
        shared with us. We use this data for
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>the generation of automated reports.</VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        Machine Learning Modelling data: Access to this category of data is
        controlled by the User itself and whoever they choose to share it with
        (their own organization and Pigeonline). Thus, these categories of data
        cannot be accessed directly by anyone from Pigeonline, unless it is
        explicitly shared with us. We use this data for
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>the generation of automated reports.</VisibleText>
      </li>
      <li>
        <VisibleText>
          the improvement of algorithms that facilitate automated reporting.
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2">
      <VisibleText>Sharing Your Data</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Pigeonline does not share data collected or uploaded by Users with any
        third parties.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        However, User Account Data, Sessions Data and Machine Learning modelling
        Data may be anonymized and aggregated into reports regarding usage,
        length of sessions, and information that is relevant to machine learning
        models and may be shared with a limited number of third parties on a
        need-to-know basis such as prospective investors, affiliates, partners
        and advertisers. Any information shared in this manner will not be
        personally identifiable information. User File Data is explicitly
        excluded from this, and is not shared under any circumstances.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>What You Can Do About your information</VisibleText>:
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        At the heart of Pigeonline’s privacy ethos and infrastructure is the
        prioritization of user control – i.e, users having the ultimate control
        over how their information is stored or used. Users can choose to object
        or remove even some categories of the minimal data collected and stored
        by Pigeonline.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Request to Permanently Delete: Users can also request permanent deletion
        of all their data once they no longer wish to continue use of the
        products or any associated platform including account information.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Request to Disassociate Categories: Users can request that their session
        data be disassociated from their User Account Data so that their user
        details are not always packaged with session data. Users can also
        request that their other data is disassociated from sessions data.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Request to View Stored Information: At any time, a User or Customer can
        contact Pigeonline through the details given and request a copy of the
        data being stored by Pigeonline that pertains to them.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Specifically Opt-In for Model Building: Any data that Pigeonline wishes
        to use from a customer for the purpose of improving underlying models of
        the products has to be specifically opted in by the User, and cannot be
        collected or used otherwise.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Restrict sharing of relevant data: In the case of data and information
        that is created as a result of use of Pigeonline’s products, such as
        Machine learning modelling data or Benchmarking data, a User can
        restrict the sharing of this data as they wish – and can choose to share
        it with either no one (only they are able to see the models generated
        from their usage of the product), only with their organization (the
        Customer) or with their organization and Pigeonline.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>How we Protect Your Data</VisibleText>:
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Pigeonline also takes the protection of the information collected very
        seriously, and strives to meet the highest standards available in terms
        of storage practices. Thus, another principle at the heart of our
        privacy and data protection practices is Data Sovereignty, and
        Pigeonline stores information from a Customer in nationally compliant
        servers wherever available.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Currently, all data is stored on SaaS dedicated servers, strictly housed
        in Canada and maintained by us. Pigeonline does not use third – party
        hosted cloud services to store your data, and at present restricts
        storage to Canada as it is the country of primary operations.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        User File Data, that is, information uploaded by Users for analysis is
        stored in a database separately from User Account Data and other
        information to further safeguard its integrity.
      </VisibleText>
    </p>
    <h2 class="library__h2"><VisibleText>Contacting Us</VisibleText>:</h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        A User may contact us to request any of the actions described above, or
        for any other reason, at privacy@researchai.io
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Changes To This Policy</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Pigeonline may, if circumstances require it, change this privacy policy
        from time to time. Users and Customers will be duly informed of any such
        changes and be given an opportunity to accept or reject such changes. If
        the changes are a necessity for continued use of the products, Users and
        Customers will have an opportunity to review their use of the products
        and associated platform.
      </VisibleText>
    </p>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"

export default {
  name: "privacy_policy",
  setup() {
    const siteData = reactive({
      title: "PigeonLine's ResearchAI | Privacy policy",
      description: "PigeonLine's Privacy Policy"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  }
}
</script>
