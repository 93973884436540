<template>
  <div class="section__content">
    <DefaultTable
      style="width: 100%"
      caption="Topic Model Groups"
      :column-headers="columnHeaders"
      :hide-caption="true"
      :row-data="topicModelGroups"
    >
      <template #row="{ row, index }">
        <td :data-label="columnHeaders[0]">
          <router-link
            class="datasets__table-name"
            :to="{ name: 'customTopicModelEdit', params: { id: row.id } }"
          >
            {{ row.name }}
          </router-link>
          <span
            class="custom-text-model__text--light custom-text-model__text--block"
          >
            Tags: {{ row.tags.join(",") || "none" }}
          </span>
        </td>
        <td :data-label="columnHeaders[1]">
          <span
            class="custom-text-model__text--bold"
            v-if="row.created_by.organization"
          >
            Team:
          </span>
          <span class="custom-text-model__text--bold">
            {{ row.created_by.name }}
          </span>
          <span v-if="row.created_by.name == profile.fullname">
            <SvgIconMeaningful icon="star" title="this is you" />
          </span>
        </td>
        <td :data-label="columnHeaders[2]">
          {{ row.themes.length }}
        </td>
        <td :data-label="columnHeaders[3]">
          <a :href="row.source.url" v-if="row.source.url && row.source.name">
            {{ row.source.name }}
          </a>
          <div v-if="!row.source.url && row.source.name">
            {{ row.source.name }}
          </div>
        </td>
        <td :data-label="columnHeaders[4]">
          <div v-if="ACCESS_TYPE_TO_DESC[row.access_type]">
            <VisibleText class="custom-text-model__text--bold">
              {{ ACCESS_TYPE_TO_DESC[row.access_type].name }}
            </VisibleText>
            <VisibleText
              class="custom-text-model__text--light custom-text-model__text--block"
            >
              {{ ACCESS_TYPE_TO_DESC[row.access_type].desc }}
            </VisibleText>
          </div>
        </td>
        <td :data-label="columnHeaders[5]">
          <VisibleText> {{ LANG_NAME[row.lang] }} </VisibleText>
        </td>
        <td :data-label="columnHeaders[6]">
          <ButtonIconOnly
            icon="deleteIcon"
            :id="`delete-${row.id}`"
            v-if="row.owner == currUserId"
            @click-handler="onClickDelete(row.id, index)"
          >
            <VisibleText>delete</VisibleText>
          </ButtonIconOnly>
        </td>
      </template>
    </DefaultTable>
  </div>
</template>

<script>
// Components
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import DefaultTable from "@/components/UI/DefaultTable.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Mixins
import ConfirmMixin from "@/utils/mixins/confirmMixin.js"
import UserMixin from "@/utils/mixins/userMixin"

// Services
import customTopicModelService from "@/services/customTopicModelService.js"
import { fetchGroups } from "../Utils/fetch.js"

// Consts
import { LANG_NAME, ACCESS_TYPE_TO_DESC } from "../Utils/consts.js"

export default {
  name: "CustomTopicModelGroups",
  mixins: [ConfirmMixin, UserMixin],
  components: {
    ButtonIconOnly,
    DefaultTable,
    SvgIconMeaningful
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      LANG_NAME: LANG_NAME,
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      columnHeaders: [
        "Group Name",
        "Owner",
        "Themes",
        "Source",
        "Access Level",
        "Language",
        ""
      ],
      topicModelGroups: []
    }
  },
  async created() {
    this.topicModelGroups = await fetchGroups()
  },
  computed: {
    currUserId() {
      return this.$store.getters["user/getProfile"]
        ? this.$store.getters["user/getProfile"].django_ref_id
        : -1
    }
  },
  methods: {
    onClickDelete(id, index) {
      this.setConfirmText({
        btn: "delete group",
        title: "delete group?"
      })
      this.setConfirmSourceComponent("topicModelGroups")
      this.setConfirmStatus(false)
      this.setConfirmTarget({ id: id, index: index })
      this.setConfirmType("delete")
      this.setConfirmIsVisible(true)
    },
    async deleteGroup(id, index) {
      try {
        // delete topic models in this group
        await Promise.all(
          this.topicModelGroups[index].themes.map(
            async (modelID) =>
              await this.CUSTOM_TOPIC_MODEL_SERVICE.delete(modelID)
          )
        )
        await this.$pigeonline.projects.delete(id)
        this.topicModelGroups.splice(index, 1)
      } catch (e) {
        throw new Error("CustomTopicModelGroups.vue:deletegroup " + e.message)
      }
    }
  },
  watch: {
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "topicModelGroups" &&
        this.confirmType === "delete"
      ) {
        await this.deleteGroup(this.confirmTarget.id, this.confirmTarget.index)
      }
    },
    $route() {
      if (this.$route.path && this.$route.path == "/custom-text-model") {
        this.topicModelGroups = fetchGroups()
      }
    }
  }
}
</script>
