import { HELPER_TRANSLATIONS } from "../../../translations"
import {
  textThemesTable,
  textThemesTableDescription,
  textThemesTableHeader
} from "./textThemesTableNodes"

import { performanceSimpleScoreChart } from "../Performance/performanceNodes"

const textHeader = (lang) => {
  const textAnalysisIconSrc = "/files/ReportIcons/driversAnalysis.svg"
  let textAnalysisHeading = `<img src="${textAnalysisIconSrc}" class="report__header-icon" alt="" />\
${HELPER_TRANSLATIONS.driversHeader[lang]}`
  const heading = {
    type: "heading",
    content: textAnalysisHeading,
    id: "section-title-text",
    meta: {
      headingLevel: "h2",
      pdfConfig: {
        style: "title"
      }
    }
  }

  return heading
}

const textThemesTableSection = (data, lang = "en") => {
  const header = textThemesTableHeader(data.textTitle, lang)
  const description = textThemesTableDescription(data.hasSentiment)
  const table = textThemesTable(data.textTitle, data.textTableData, true, lang)

  let thisContent = [header, description, table]
  if (data.chartData && data.chartData.length) {
    for (let i = 0; i < data.chartData.length; i++) {
      let title = "Theme coverage"
      if (data.chartData[i].filtered_by) {
        title = data.chartData[i].filtered_by
      }
      if (data.chartData[i].segmented_by) {
        title = data.chartData[i].segmented_by
      }
      thisContent.push(
        performanceSimpleScoreChart(
          title,
          data.chartData[i].chart_data,
          lang,
          -1,
          "horizontalBarChart",
          "thematicScore",
          "thematicLabel",
          "thematicScoreHover",
          "%"
        )
      )
    }
  }

  return {
    type: "section",
    id: "text-themes-section",
    content: thisContent,
    meta: {
      textUUID: data.textUUID,
      subTitle: "THEMATIC ANALYSIS"
    }
  }
}

export { textHeader, textThemesTableSection }
