<template>
  <span dataset-parent="MessageInline" class="message__inline" :role="role">
    <svg
      focusable="false"
      role="img"
      :class="[`message__inline-icon--${messageType}`, 'icon']"
      v-if="messageType.length > 0 && message.length > 0"
    >
      <title>
        {{ $options.methods.translate(messageType, "MessageInline") }}
      </title>
      <use :xlink:href="`#${messageType}`" aria-hidden="true" />
    </svg>
    {{ $options.methods.translate(message, "MessageInline") }}
  </span>
</template>

<script>
export default {
  props: {
    messageType: {
      default: "", // warning, error, information, success
      type: String
    },
    role: {
      default: "status",
      type: String
    },
    message: {
      default: "",
      type: String
    }
  }
}
</script>
