import UserMixin from "@/utils/mixins/userMixin"

// userCardsMixin.js
// ================
//
// This mixin handles common functions used by AccountProfile.vue and PigeonSetting.vue to select UserCards like teams or licenses.
// once selectedTeam or selectedLicense are filled, they render.

export default {
  mixins: [UserMixin],
  data() {
    return {
      localTeams: [], // this can change depending on the page, see AcccountProfile.vue or PigeonSetting for more information
      selectedLicense: [], // show a license card
      selectedTeam: [], // show a team card
      email: "",
      licenseTypes: [
        "free",
        "demo",
        "basic",
        "teaching",
        "research",
        "iccs",
        "iccs-admin",
        "pigeon"
      ]
    }
  },
  async mounted() {
    await this.resetTheseTeams()
    this.email = this.$pigeonline.getUserEmail()
    //this.localTeams = this.teams // This part needs to be page specific! See AccountProfile.vue or PigeonSettings.vue for more information
  },
  methods: {
    // ======================
    // === CORE FUNCTIONS ===
    // ======================

    async changeSelectedLicense(license, user_index) {
      // This means it is a nested query
      if (user_index >= 0) {
        let newQueriedUsers = this.deepCloneObj(this.queriedUsers)
        if (newQueriedUsers && newQueriedUsers.length) {
          if (
            newQueriedUsers[user_index].selectedLicense &&
            newQueriedUsers[user_index].selectedLicense.length
          ) {
            let foundInSelectedLicense = newQueriedUsers[
              user_index
            ].selectedLicense.filter(
              (this_license) =>
                this_license.name == license.name &&
                (this_license.profile_id == license.profile_id ||
                  this_license.team_id == license.team_id)
            )
            if (foundInSelectedLicense.length) {
              newQueriedUsers[user_index].selectedLicense = []
            } else {
              newQueriedUsers[user_index].selectedLicense = []
              newQueriedUsers[user_index].selectedLicense.push(license)
            }
          } else {
            newQueriedUsers[user_index].selectedLicense = []
            newQueriedUsers[user_index].selectedLicense.push(license)
          }
        }
        this.queriedUsers = newQueriedUsers
      } else {
        // this means it is not nested
        if (this.selectedLicense) {
          if (!this.selectedLicense.includes(license)) {
            this.selectedLicense = []
            this.selectedLicense.push(license)
          } else {
            this.selectedLicense = []
          }
        }
      }
    },

    changeSelectedTeam(team) {
      if (this.addingNewTeam) {
        this.addingNewTeam = false
      }

      if (!this.selectedTeam.includes(team)) {
        this.selectedTeam = []
        this.selectedTeam.push(team)
      } else {
        this.selectedTeam = []
      }
    },

    async resetTheseTeams(team_index) {
      let parent = "profile"
      if (this.$route.path.includes("pigeon-settings")) {
        parent = "pigeon"
      }

      if (parent == "profile") {
        await this.resetTeams() // This is in the UserMixin
        this.localTeams = this.teams
        if (team_index >= 0) {
          this.selectedTeam[0] = this.localTeams[team_index]
        } else {
          this.selectedTeam = []
        }
      }
      if (parent == "pigeon") {
        // Could be empty if deleting
        const clonedTeams = this.deepCloneObj(this.localTeams)
        if (team_index >= 0) {
          if (clonedTeams && clonedTeams.length) {
            let updated_team = await this.TEAMS_SERVICE.getTeam({
              team_id: clonedTeams[team_index]._id.$oid,
              include_details: true
            })
            if (updated_team) {
              clonedTeams[team_index] = updated_team
            }
            this.localTeams = []
            this.localTeams = clonedTeams
            this.selectedTeam[0] = this.localTeams[team_index]
          }
        } else {
          this.localTeams = []
          this.localTeams = clonedTeams
          this.selectedTeam = []
        }
      }
    },
    async deleteThisTeam(team_index) {
      let parent = "profile"
      if (this.$route.path.includes("pigeon-settings")) {
        parent = "pigeon"
      }

      if (parent == "profile") {
        await this.resetTeams() // This is in the UserMixin
        this.localTeams = this.teams
        this.selectedTeam = []
      }
      if (parent == "pigeon") {
        const clonedTeams = this.deepCloneObj(this.localTeams)
        if (team_index >= 0) {
          if (clonedTeams && clonedTeams.length) {
            clonedTeams.splice(team_index, 1)
          }
        }
        this.localTeams = []
        this.localTeams = clonedTeams
        this.selectedTeam = []
      }
    },

    // =============
    // === UTILS ===
    // =============
    getLicenseType(license) {
      if (license["team_id"]) {
        return "Team"
      } else {
        return "Individual"
      }
    },

    getLicenseColor(license) {
      if (license.name == "free") {
        return "var(--state-inactive-colour)"
      }
      if (license.name == "demo") {
        return "#98a8b6"
      }
      if (license.name == "basic") {
        return "#98a8b6"
      }
      if (license.name == "teaching") {
        return "#98a8b6"
      }
      if (license.name == "research") {
        return "#98a8b6"
      }
      if (license.name == "iccs") {
        return "var(--tequila-orange)"
      }
      if (license.name == "iccs-admin") {
        return "var(--tequila-orange)"
      }
      if (license.name == "pigeon") {
        return "#c4bdd9"
      }

      return "var(--primary-lighter)"
    },

    capitalizeFirstLetter(string) {
      if (string == "iccs") {
        return string.toUpperCase()
      }
      if (string == "iccs-admin") {
        return "ICCS Admin"
      }
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    getTeamIndex(team) {
      let index = -1
      for (let i = 0; i < this.localTeams.length; i++) {
        if (team._id == this.localTeams[i]._id) {
          index = i
        }
      }
      return index
    },

    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  },
  watch: {
    localTeams: async function (val) {
      if (val && val.length) {
        this.email = this.$pigeonline.getUserEmail()
      }
    }
  }
}
