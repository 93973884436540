export default {
  data() {
    return {}
  },
  methods: {
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r)
        ? r
        : typeof r.pk !== "undefined"
          ? r.pk
          : typeof r.id !== "undefined"
            ? r.id
            : -1
    },
    currUserProfileId() {
      return this.$store.getters["user/getProfile"]
        ? this.$store.getters["user/getProfile"].django_ref_id
        : -1
    },
    checkIsReadOnly(project) {
      // if it is an empty project
      if (!project.owner) {
        return false
      }
      // is the owner, not readonly
      if (project.owner) {
        if (project.owner == this.currUserProfileId()) {
          return false
        }
        if (this.profile) {
          if (project.owner == this.profile.django_ref_id) {
            return false
          }
        }
      }

      // Check shared field in project (direct sharing)
      let sharedUser = []
      const profileEmail = this.$pigeonline.getUserEmail().trim().toLowerCase()
      if (project.shared && this.profile) {
        for (let i = 0; i < project.shared.length; i++) {
          if (
            project.shared[i].pk == this.profile.django_ref_id ||
            (project.shared[i].pk.length &&
              project.shared[i].pk.toLowerCase() == profileEmail)
          ) {
            for (let x = 0; x < project.shared[i].perms.length; x++) {
              if (project.shared[i].perms[x] == "edit") {
                sharedUser.push(project.shared[i])
              }
            }
          }
        }
      }

      // shared but does not have edit rights
      let isDirectSharable = false
      if (sharedUser && sharedUser.length) {
        isDirectSharable = true
      }
      let isTeamSharable = false
      if (
        (project.access_type == "public__team" ||
          project.access_type == "public__all") &&
        (!project.is_read_only || project.is_read_only == false)
      ) {
        isTeamSharable = true
      }
      let isReadOnly = true
      if (isDirectSharable || isTeamSharable) {
        isReadOnly = false
      }
      if (
        this.parsedLicenses &&
        this.parsedLicenses.length == 1 &&
        this.parsedLicenses.includes("demo")
      ) {
        isReadOnly = true
      }
      return isReadOnly
    }
  }
}
