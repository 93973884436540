<template>
  <div class="list-grid-wrapper" role="grid">
    <div role="rowgroup">
      <div class="list-grid-header-wrapper" role="row" :style="gridStyle">
        <div
          v-for="header in headers"
          class="list-grid-header"
          role="columnheader"
          :class="header || replaceSpaceWithDash"
          :id="getFormattedHeaderId(header)"
          :key="header"
          :data-parent="$options.name"
        >
          <SortPopup
            v-if="sortConfig && sortConfig[header]"
            :sort-config="sortConfig[header]"
            :sort-title="header"
            @sort="$emit('sort', $event)"
          />
          {{ translate(header, $options.name) }}
        </div>
      </div>
    </div>
    <div
      style="display: flex; margin: 0 auto"
      v-if="isLoading"
      class="also-found__loading-spinner spinner"
    ></div>
    <div class="list-grid-items" role="rowgroup">
      <div
        v-for="(item, index) in list"
        role="row"
        :key="`list-row-${index}`"
        :style="gridStyle"
        :class="['list-grid-item-wrapper', `list-grid-item-${index}`]"
      >
        <slot :item="item" :index="index" :uuid="uuid">
          {{ item }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SortPopup from "@/components/UI/Sort/SortPopup.vue"

import FiltersMixin from "@/utils/mixins/filtersMixin"

export default {
  name: "ListGrid",
  components: {
    SortPopup
  },
  mixins: [FiltersMixin],
  props: {
    headers: {
      default: () => [],
      type: Array
    },
    list: {
      default: () => [],
      type: Array,
      required: true
    },
    customGridStyle: {
      default: () => null,
      type: Object
    },
    sortConfig: {
      type: Object
    }
  },
  data() {
    return {
      gridStyle: "",
      uuid: this.$pigeonline.createUUID()
      // isLoading: true
    }
  },
  created() {
    this.checkStyle()
  },
  methods: {
    getFormattedHeaderId(header) {
      return `${this.replaceSpaceWithDash(header)}-${this.uuid}`
    },
    checkStyle() {
      const defaultStyle = {
        gridTemplateColumns: `repeat(${this.headers.length}, minmax(auto, 1fr))`
      }
      if (this.customGridStyle) {
        this.gridStyle = this.customGridStyle
      } else {
        this.gridStyle = defaultStyle
      }
    }
  },
  computed: {
    isLoading() {
      let status = true
      if (this.list && this.list.length) {
        status = false
      }
      return status
    }
  },
  watch: {
    customGridStyle: async function (val) {
      if (val) {
        this.checkStyle()
      }
    }
    // list: async function (val) {
    //   if (val && val.length) {
    //     this.isLoading = false
    //   }
    // }
  }
}
</script>
