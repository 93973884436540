import { HELPER_TRANSLATIONS, LABEL_TRANSLATIONS } from "../../../translations"
import { formatPriorityMatrixChartData } from "./helpers"

/*******************************
 *
 * Generate priority matrix chart node
 * @param {String} matrixTitle of the drivers analysis chart
 * @param {Array} matrixChartData drivers analysis chart data from drivers/drivers_analysis
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const priorityMatrixChart = (
  matrixTitle,
  matrixChartData,
  includeLinks = true,
  lang
) => {
  const chartData = formatPriorityMatrixChartData(
    matrixChartData.num_drivers,
    includeLinks
  )
  const chart = {
    type: "priorityMatrix",
    content: {
      title: `<span class='drivers-chart-title'>${HELPER_TRANSLATIONS.priorityChartTitle[lang]} ${matrixTitle}</span>`,
      data: chartData
    },
    id: "drivers-priority-matrix",
    meta: {
      headingLevel: "div",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: LABEL_TRANSLATIONS.performance[lang],
        y: LABEL_TRANSLATIONS.importance[lang]
      },
      quadrantLabels: {
        topLeft: LABEL_TRANSLATIONS.improve[lang],
        topRight: LABEL_TRANSLATIONS.protect[lang],
        bottomLeft: LABEL_TRANSLATIONS.monitor[lang],
        bottomRight: LABEL_TRANSLATIONS.maintain[lang]
      },
      axisHoverText: {
        x: HELPER_TRANSLATIONS.priorityXAxis[lang],
        y: matrixChartData.y_axis
      }
    }
  }
  return chart
}

const priorityMatrixDescription = (matrixChartData) => {
  return {
    type: "text",
    content: `${matrixChartData.dynamic_summary.priority_matrix_summary}`,
    id: "drivers-matrix-details",
    meta: {
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
}

export { priorityMatrixChart, priorityMatrixDescription }
