<template>
  <Accordion
    title-element-type="h3"
    :icon="accordionIcon"
    style="font-size: 0.9rem"
    :isPublicReadOnly="isPublicReadOnly"
  >
    <!--------------------------------------------
    | 
    | Question heading
    |
    --------------------------------------------->
    <template #heading>
      {{ localQuestion.question_title }}
    </template>

    <!--------------------------------------------
    | 
    | Question details
    |
    --------------------------------------------->
    <template #additionalDetails>
      <button
        class="editor-question__remove"
        icon="remove"
        :id="`${localQuestion._id.$oid}-del`"
        @click="deleteQuestion"
        v-if="!isPublicReadOnly"
      >
        <VisibleText> delete </VisibleText>
      </button>

      <!-- Question text -->
      <div class="editor-question__text">
        <ul class="list">
          <li v-if="localQuestion.question_text">
            <VisibleText class="lang">English</VisibleText>
            <template v-if="!isEditingEng">
              {{ localQuestion.question_text }}
              <ButtonIconOnly
                class="action-icon"
                icon="edit"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingEng = true"
                v-if="!isPublicReadOnly"
              >
                edit
              </ButtonIconOnly>
            </template>
            <template v-if="isEditingEng">
              <input
                aria-label="english question wording"
                class="form-input--margin--none input"
                type="text"
                @keydown.enter.stop="isEditingEng = false"
                v-model="localQuestion.question_text"
              />
              <ButtonIconOnly
                class="action-icon"
                icon="check"
                :id="$pigeonline.createUUID()"
                @click-handler="saveEdit('en')"
              >
                save
              </ButtonIconOnly>
            </template>
          </li>
          <li>
            <VisibleText class="lang" v-if="localQuestion.question_text_fr">
              French
            </VisibleText>
            <template v-if="localQuestion.question_text_fr && !isEditingFrench">
              {{ localQuestion.question_text_fr }}
              <ButtonIconOnly
                class="action-icon"
                icon="edit"
                :id="$pigeonline.createUUID()"
                @click-handler="isEditingFrench = true"
                v-if="!isPublicReadOnly"
              >
                edit
              </ButtonIconOnly>
            </template>
            <template v-if="isEditingFrench">
              <input
                aria-label="french question wording"
                class="form-input--margin--none input"
                type="text"
                @keydown.enter.stop="isEditingFrench = false"
                v-model="localQuestion.question_text_fr"
              />
              <ButtonIconOnly
                class="aciton-icon"
                icon="check"
                :id="$pigeonline.createUUID()"
                @click-handler="saveEdit('fr')"
              >
                save
              </ButtonIconOnly>
            </template>
          </li>
        </ul>
        <QuestionWording
          :question="$data.$_question"
          :init-array="false"
          @update-question="updateQuestionWording"
          @updateWordingButNoBackend="updateWordingButNoBackend"
          :hasOtherFrench="
            $data.$_question.question_text_fr || localQuestion.question_text_fr
              ? true
              : false
          "
          :hasOtherEnglish="
            $data.$_question.question_text || localQuestion.question_text
              ? true
              : false
          "
          v-if="!isPublicReadOnly"
        >
        </QuestionWording>
      </div>

      <!-- Details -->
      <div class="editor-question__details">
        <span class="editor-question__type">
          <SvgIconDecorative
            icon="analysis"
            class="editor-question__type-icon"
          />
          <VisibleText v-if="disableEditing || isPublicReadOnly">
            {{
              translate(
                DATA_TYPE_TO_READABlE[question.data_type],
                $options.name
              )
            }}</VisibleText
          >
          <!-- Removing this, preventing type change after at least one match -->
          <div v-if="!disableEditing && !isPublicReadOnly">
            <span
              class="editor-question__type-text"
              :data-parent="$options.name"
              v-show="localQuestion.data_type && !isEditingDataType"
              @click.stop="isEditingDataType = true"
            >
              {{
                translate(
                  DATA_TYPE_TO_READABlE[localQuestion.data_type],
                  $options.name
                )
              }}
            </span>
            <SelectDataType
              class="editor-question__select"
              :id="localQuestion._id.$oid"
              :init-data-type="localQuestion.data_type"
              :sr-only-label="true"
              @set-data-type="setDataType"
              v-show="!localQuestion.data_type || isEditingDataType"
              :isBenchmarking="true"
            >
              <VisibleText>select data type</VisibleText>
            </SelectDataType>
          </div>
        </span>
        <span class="editor-question__approved">
          <SvgIconDecorative
            icon="approved"
            class="editor-question__approved-icon"
          />
          <strong>{{ localQuestion.matches.approved }}</strong>
          <VisibleText> approved</VisibleText>
        </span>
        <span
          class="editor-question__pending"
          v-if="!disableEditing && !isPublicReadOnly"
        >
          <strong>{{ localQuestion.matches.pending }}</strong>
          <VisibleText> pending</VisibleText>
        </span>
      </div>
    </template>

    <!-- Matches -->
    <h4 style="font-size: 1.05rem; margin-bottom: 2rem">
      {{ `${localQuestion.question_title} - Benchmark Matches` }}
    </h4>
    <Matches
      :global-question-id="localQuestion._id.$oid"
      :teamMembers="teamMembers"
      @updateMatchesStatus="updateMatchesStatus"
    />
  </Accordion>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
//import LoadingSpinner from "@/components/UI/Spinner.vue"
import QuestionWording from "../Components/QuestionWording.vue"
import SelectDataType from "@/components/Dataset/Components/DatasetSelectType.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import Matches from "./Matches.vue"

// Consts
import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerEditorQuestion",
  components: {
    Accordion,
    ButtonIconOnly,
    Matches,
    QuestionWording,
    SelectDataType,
    SvgIconDecorative
  },
  props: {
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    },
    isPublicReadOnly: {
      type: Boolean,
      default: () => false
    },
    index: {
      default: null,
      type: Number,
      required: true
    },
    question: {
      default: () => {},
      type: Object,
      required: true
    },
    teamMembers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      DATA_TYPE_TO_READABlE: DATA_TYPE_TO_READABlE,
      accordionIcon: {
        expanded: "chevronUp",
        collapsed: "chevronDown"
      },
      isEditingDataType: false,
      isEditingEng: false,
      isEditingFrench: false,
      $_question: {},
      localBenchmarkGroup: {},
      localQuestion: {}
    }
  },
  created() {
    this.$data.$_question = Object.assign({}, this.question)
    this.localBenchmarkGroup = this.benchmarkGroup
    this.localQuestion = this.question
  },
  methods: {
    saveEdit(lang) {
      if (lang == "fr") {
        this.isEditingFrench = false
      }
      if (lang == "en") {
        this.isEditingEng = false
      }

      this.updateQuestionWording()
    },
    deleteQuestion() {
      if (confirm("Are you sure you want to delete this question?")) {
        this.localBenchmarkGroup.questions = Math.max(
          0,
          this.localBenchmarkGroup.questions - 1
        )
        this.localBenchmarkGroup.approved_matches = Math.max(
          0,
          this.localBenchmarkGroup.approved_matches -
            this.question.matches.approved
        )
        this.$pigeonline.projects.update(this.localBenchmarkGroup)

        this.DATASETS_SERVICE.deleteGlobalQuestion(this.question._id.$oid)
        this.$emit("remove-global-question", this.index)
      }
    },
    updateMatchesStatus(newMatchesStatus) {
      if (newMatchesStatus && Object.keys(newMatchesStatus).length) {
        this.localQuestion.matches.approved =
          newMatchesStatus.approved_count + newMatchesStatus.auto_approved_count
        this.localQuestion.matches.pending = newMatchesStatus.pending_count
        this.localQuestion.matches.temporarily_approved =
          newMatchesStatus.temporarily_approved_count
        this.localQuestion.matches.rejected = newMatchesStatus.rejected_count
        this.localBenchmarkGroup.approved_matches =
          newMatchesStatus.approved_count + newMatchesStatus.auto_approved_count

        this.DATASETS_SERVICE.updateGlobalQuestion(this.question._id.$oid, {
          matches: this.localQuestion.matches
        })
        this.$pigeonline.projects.update(this.localBenchmarkGroup)
      }
    },
    async updateWordingButNoBackend() {
      if (this.$data.$_question.question_text) {
        this.localQuestion.question_text = this.$data.$_question.question_text
      }
      if (this.$data.$_question.question_text_fr) {
        this.localQuestion.question_text_fr =
          this.$data.$_question.question_text_fr
      }
    },
    async updateQuestionWording() {
      let question_text = this.$data.$_question.question_text
      let question_text_fr = this.$data.$_question.question_text_fr
      if (this.$data.localQuestion.question_text) {
        question_text = this.$data.localQuestion.question_text
      }
      if (this.$data.localQuestion.question_text_fr) {
        question_text_fr = this.$data.localQuestion.question_text_fr
      }
      const updated = await this.DATASETS_SERVICE.updateGlobalQuestion(
        this.localQuestion._id.$oid,
        {
          question_text: question_text,
          question_text_fr: question_text_fr
        }
      )
      this.$emit(
        "update-global-question",
        this.index,
        updated.global_question_object
      )
    },
    setDataType(dataType) {
      if (this.localQuestion.data_type === dataType) return
      this.isEditingDataType = false
      this.localQuestion.data_type = dataType
      this.DATASETS_SERVICE.updateGlobalQuestion(this.localQuestion._id.$oid, {
        data_type: dataType
      })
    }
  },
  computed: {
    disableEditing() {
      if (this.localQuestion) {
        if (this.localQuestion.matches) {
          if (
            (this.localQuestion.matches.approved &&
              this.localQuestion.matches.approved > 0) ||
            (this.localQuestion.matches.pending &&
              this.localQuestion.matches.pending > 0) ||
            (this.localQuestion.matches.rejected &&
              this.localQuestion.matches.rejected > 0) ||
            (this.localQuestion.matches.temporarily_approved &&
              this.localQuestion.matches.temporarily_approved > 0)
          ) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>
