import { Model } from "@pigeonline/core"

export default class PromiseQueueModel extends Model {
  constructor(data = {}) {
    super(data)

    this.todo = []
    this.running = []
    this.complete = []
    this.concurrent = 1
    this.finished = false

    this.setProps(data)
  }

  runNext() {
    return this.running.length < this.concurrent && this.todo.length
  }

  runConcurrent() {
    while (this.runNext()) {
      const promise = this.todo.shift()
      promise().then((response) => {
        this.complete.push(response)
        //this.complete.push(response.data)
        this.running.shift()
        this.runConcurrent()
        // all promises resolved
        if (this.todo.length === 0 && this.running.length === 0)
          this.finished = true
      })
      this.running.push(promise)
    }
  }

  async runParallelLimit(limit) {
    let buffer = []
    let total = this.todo.length
    for (let i = 0; i < total; i = i + limit) {
      for (let j = 0; j < limit; j++) {
        if (this.todo.length > 0) {
          let promise = this.todo.shift()
          buffer.push(promise())
        }
      }
      await Promise.all(buffer).then((results) => {
        const data = results.map((r) => r.data)
        this.complete.push(...data)
      })
      buffer = []
    }
  }

  reset() {
    this.todo = []
    this.running = []
    this.complete = []
    this.concurrent = 1
  }

  getTotal() {
    return this.todo.length
  }
}
