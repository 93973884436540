/**
 * An object representing a custom topic model
 * @typedef {Object} CustomTopicModel
 */

import { Model } from "@pigeonline/core"

export default class CustomTopicModel extends Model {
  constructor(data = {}) {
    super(data)

    if (data && data._id) this.id = data._id.$oid
    this.example_questions = []
    this.group_id = "" // inherited from group
    this.keywords = []
    this.lang = "en" //inherited from group
    this.name = "theme"
    this.source = {} // inherited from group
    this.tags = [] //inherited from group

    this.setProps(data)
  }
}
