<template>
  <div
    style="
      z-index: 10;
      background-color: white;
      border-top: 0;
      border-radius: 0 0 4px 4px;
      color: var(--base-colour);
      position: absolute;
      margin-top: 2rem;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid var(--outline-colour);
      border-radius: 8px;
    "
  >
    <div
      style="
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
        background: var(--base-lighter-ui-colour);
        border: 1px solid var(--outline-light-colour);
        padding: 0.75rem;
        font-family: Isidora, Arial, sans-serif;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        font-weight: 500;
      "
    >
      <div style="display: flex; flex-direction: row">
        <VisibleText> Change theme color </VisibleText>
        <button
          style="
            border: 1px solid var(--outline-colour);
            background: var(--secondary-ui-colour);
            border-radius: 4px;
            margin-left: auto;
            width: 1.5rem;
            height: 1.5rem;
          "
          @click="$emit('close')"
        >
          <SvgIconMeaningful
            style="cursor: pointer; width: 1rem; margin-left: -0.05rem"
            class="complete-icon-svg"
            icon="remove"
            title="close"
          />
        </button>
      </div>
    </div>
    <div style="padding: 1rem; display: flex; flex-direction: row">
      <div v-for="(color, index) in theme_colors" :key="index">
        <button
          @click="changeThemeColor(color, themeId)"
          style="
            border-radius: 1rem;
            border: 1px solid var(--outline-colour);
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.25rem;
          "
          :style="{ 'background-color': color }"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import themeColorsMixin from "../Mixins/themeColorsMixin.js"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

export default {
  name: "ColorPopup",
  mixins: [themeColorsMixin],
  components: { SvgIconMeaningful },
  props: {
    themeId: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    changeThemeColor(color, index) {
      this.$emit("changeThemeColor", color, index)
    }
  }
}
</script>
