export default {
  props: {},
  data() {
    return {
      theme_colors: [
        "#6a88aa",
        "#6aaa6c",
        "#aa6aa8",
        "#aa8c6a",
        "#aa6a88",
        "#88aa6a",
        "#6aa8aa",
        "#a8aa6a",
        "#769484",
        "#995555"
      ]
    }
  },
  methods: {
    async assignColor(found_colors) {
      if (!found_colors || !found_colors.length) {
        return this.theme_colors[
          Math.floor(Math.random() * (this.theme_colors.length - 0 + 1)) + 0
        ]
      }
      if (found_colors.length) {
        let available_colors = this.theme_colors.filter(
          (color) => !found_colors.includes(color.toLowerCase())
        )
        if (!available_colors.length) {
          let new_color_index =
            Math.floor(Math.random() * (this.theme_colors.length - 0 + 1)) + 0
          if (found_colors) {
            found_colors.push(this.theme_colors[new_color_index])
          }
          return this.theme_colors[new_color_index]
        }
        if (found_colors) {
          found_colors.push(available_colors[0])
        }
        return available_colors[0]
      }
    }
  }
}
