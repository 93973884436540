<template>
  <article id="project-analysis-text" v-if="dataset" style="position: relative">
    <h2 class="sr-only"><VisibleText>Text Analysis</VisibleText></h2>
    <div
      class="project-analysis__header"
      style="justify-content: space-between"
    >
      <h2 class="project-analysis__header-title">
        <VisibleText>Text analysis</VisibleText>
      </h2>
      <div
        style="display: flex; justify-content: flex-end"
        v-if="hasTextQuestions"
      >
        <button
          class="project-analysis__header-add-btn"
          style="margin: 0 1em"
          v-if="!this.project.textAnalysisObjects.length"
          :disabled="propReadOnly"
          @click="firstTimeLoad()"
        >
          <VisibleText>Auto-suggest analysis</VisibleText>
        </button>
        <button
          class="project-analysis__header-add-btn"
          @click="addNewTextAnalysis"
          :disabled="propReadOnly"
        >
          <VisibleText>Add new text analysis</VisibleText>
        </button>
      </div>
    </div>
    <p class="benchmarking__desc">
      <VisibleText
        >What themes can be found in your text? And what sentiments are found by
        those who match those themes? We use Natural Language Processing to help
        you generate themes from algorithms or from your own custom models.
        After finding themes, you can save results into a report.
      </VisibleText>
    </p>
    <div
      style="display: flex; flex-direction: column; width: 95%"
      class="message__block message__block--information"
    >
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Watch: </VisibleText>
          <SvgIconDecorative icon="video" />
          <button
            @click="openVideo('text-analysis')"
            style="
              background: none;
              color: var(--secondary);
              border: none;
              padding: 0;
              font: inherit;
              text-decoration: underline;
            "
          >
            <VisibleText>Text analysis video</VisibleText>
          </button>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div
          class="project-analysis-item__header-wrapper-btn"
          style="display: flex; font-weight: 200"
        >
          <VisibleText style="font-weight: 800"> Guide: </VisibleText>
          <SvgIconDecorative icon="externalLink" />
          <a
            href="javascript:void(0)"
            @click="
              $emit('user-guide-expanded'),
                $emit('open-article', 'ThematicAnalysis')
            "
          >
            Our guide to text analysis, sentiment analysis, and building your
            own text models.
          </a>
        </div>
      </div>
    </div>
    <MessageBlock
      message-type="information"
      title="No text entries"
      v-if="showNoTextResponsesMessage"
    >
      No text entries found. Please add a dataset with rows of text.
    </MessageBlock>
    <template
      v-if="
        project.textAnalysisObjects && project.textAnalysisObjects.length > 0
      "
    >
      <SortProjects
        :sortBy="sortBy"
        @toggleSort="toggleSort"
        v-if="sortedItems.length > 1"
      />
      <div v-for="(text, index) in sortedItems" :key="text.uuid">
        <ProjectAnalysisTextItem
          :datasetIDQuestions="datasetIDQuestions"
          v-if="text.uuid"
          :uuid="text.uuid"
          :index="index"
          :prop-read-only="propReadOnly"
          :isDatasetReadOnly="isDatasetReadOnly"
          @refresh="$emit('refresh')"
          @updateSlides="$emit('updateSlides')"
        />
      </div>
    </template>
    <NewTextAnalysisModal
      v-if="availableTextQuestions.length"
      text-analysis-title="Create Text Analysis"
      :text-questions="availableTextQuestions"
      :isNewTextModalVisible="isNewTextModalVisible"
      @create-analysis="parseAndCreateFirstAnalysis"
      @close-text-analysis="isNewTextModalVisible = false"
    />
    <VideoClip
      :videoShortURL="videoShortURL"
      :status="isVideoClipExpanded"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
  </article>
</template>

<script>
// Components
import ProjectAnalysisTextItem from "./ProjectAnalysisTextItem.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import NewTextAnalysisModal from "./Components/NewTextAnalysisModal.vue"
import SortProjects from "../Components/SortProjects.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Utils
import moment from "moment"

// Mixins
import textAnalysisMixin from "./Mixins/textAnalysisMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ThemeListMixin from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/ProjectAnalysisTextMain/Mixins/themeListMixin.js"

export default {
  name: "ProjectAnalysisText",
  mixins: [
    textAnalysisMixin,
    ProjectMixin,
    DatasetDetailsMixin,
    ThemeListMixin
  ],
  components: {
    ProjectAnalysisTextItem,
    MessageBlock,
    NewTextAnalysisModal,
    SortProjects,
    VideoClip,
    SvgIconDecorative
  },
  props: {
    hasTextQuestions: {
      default: () => false,
      type: Boolean
    },
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    isDatasetReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      availableTextQuestions: [],
      suggestedQuestionForAnalysis: {},
      isNewTextModalVisible: false,
      sortBy: "new",
      videoShortURL: "",
      isVideoClipExpanded: false
    }
  },
  created() {
    this.updateTheseTextQuestions()
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    async addNewTextAnalysis() {
      this.isNewTextModalVisible = true
    },
    async firstTimeLoad() {
      if (this.dataset._id.$oid) {
        if (this.availableTextQuestions.length) {
          await this.getSuggestedTextQuestion()
          if (this.suggestedQuestionForAnalysis) {
            this.createFirstAnalysis(this.suggestedQuestionForAnalysis)
          }
        }
      }
    },
    async updateTheseTextQuestions() {
      if (this.clientTextQuestions.length) {
        for (let i = 0; i < this.clientTextQuestions.length; i++) {
          this.availableTextQuestions.push(this.clientTextQuestions[i])
        }
      }
    },
    getSuggestedTextQuestion() {
      let largestCount = 0
      let largestID = 0
      if (this.availableTextQuestions.length > 1) {
        let count
        for (let i = 0; i < this.availableTextQuestions.length; i++) {
          count = 0
          for (
            let x = 0;
            x < this.availableTextQuestions[i].unique_values.length;
            x++
          ) {
            if (
              this.availableTextQuestions[i].unique_values[x].is_valid === true
            ) {
              count = count + 1
            }
          }
          if (count > largestCount) {
            largestCount = count
            largestID = i
          }
        }
      }
      this.suggestedQuestionForAnalysis = this.availableTextQuestions[largestID]
    },
    async parseAndCreateFirstAnalysis(questionId, filterParam) {
      let newObject = {}
      if (this.availableTextQuestions.length) {
        for (let i = 0; i < this.availableTextQuestions.length; i++) {
          // oid preserved for availabletextquestions because it's saved directly in frontend but not used in the backend
          if (this.availableTextQuestions[i]._id) {
            if (this.availableTextQuestions[i]._id.$oid) {
              if (this.availableTextQuestions[i]._id.$oid === questionId) {
                newObject = this.availableTextQuestions[i]
                newObject._id = ""
                newObject._id = questionId
                this.createFirstAnalysis(newObject, filterParam)
              }
            } else if (this.availableTextQuestions[i]._id === questionId) {
              newObject = this.availableTextQuestions[i]
              newObject._id = ""
              newObject._id = questionId
              this.createFirstAnalysis(newObject, filterParam)
            }
          }
        }
      }
    },
    async createFirstAnalysis(question, filterParam) {
      // Create empty object which we will fill before saving

      const emptyTextAnalysis = this.deepCloneObj(
        await this.fetchEmptyTextAnalysis()
      )
      if (question && question.unique_values) {
        question.unique_values = []
      }
      emptyTextAnalysis.datasetId = this.dataset._id.$oid
      emptyTextAnalysis.uuid = await this.$pigeonline.createUUID()
      emptyTextAnalysis.selectedTextQuestion = question
      emptyTextAnalysis.filterParams = filterParam
      emptyTextAnalysis.last_modified_on = moment().valueOf()
      // convert to format that can be read by the backend for quick saving
      if (question._id) {
        if (question._id.$oid) {
          emptyTextAnalysis.selectedTextQuestion._id = question._id.$oid
        }
      }
      this.project.textAnalysisObjects.unshift(emptyTextAnalysis)
    },
    toggleSort() {
      if (this.sortBy === "new") {
        this.sortBy = "old"
      } else {
        this.sortBy = "new"
      }
    },
    openVideo(shortUrl) {
      this.videoShortURL = shortUrl
      this.isVideoClipExpanded = !this.isVideoClipExpanded
    }
  },
  computed: {
    // appLang() {
    //   return this.$store.getters["getLanguage"]
    // },
    datasetId: function () {
      return this.dataset._id.$oid
    },
    showNoTextResponsesMessage: function () {
      return (
        this.availableTextQuestions &&
        Array.isArray(this.availableTextQuestions) &&
        this.availableTextQuestions.length === 0
      )
    },
    sortedItems: function () {
      if (this.project) {
        if (this.project.textAnalysisObjects) {
          if (this.project.textAnalysisObjects.length > 1) {
            if (this.sortBy === "old") {
              let theseObjects = this.project.textAnalysisObjects
              theseObjects.sort((a, b) => {
                return a.last_modified_on - b.last_modified_on
              })
              return theseObjects
            } else {
              let theseObjects = this.project.textAnalysisObjects
              theseObjects.sort((a, b) => {
                return b.last_modified_on - a.last_modified_on
              })
              return theseObjects
            }
          } else {
            return this.project.textAnalysisObjects
          }
        }
      }
      return []
    }
  }
}
</script>
