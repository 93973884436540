import { app } from "@/main.js"

const DEFAULTS = {
  activeTab: "Sentiment",
  datasetId: null,

  // text client questions
  textQuestions: null,
  selectedTextQuestion: null,

  // themes and responses
  themes: [],
  selectedThemeUUIDs: [],

  // sort orders
  sentimentSortOrder: -1, // default - negative sentiments

  // Response IDs to filter
  selectedResponseIds: [],

  // search
  search: {
    searchString: "",
    isSearching: false,
    alsoFound: [],
    searchResults: [],
    excludeAssociations: false,
    wordAssociations: [],
    searchMode: "exact matches",
    isFetchingWordAssociations: false,
    similarSentences: []
  },

  // banned keywords + comments
  bannedKeywords: [],
  bannedComments: [],

  // hide comments
  hiddenComments: [],

  // loading spinner
  showSpinner: false,
  showResponseListLoadingSpinner: false
}

export default {
  namespaced: true,
  state() {
    return JSON.parse(JSON.stringify(DEFAULTS))
  },
  getters: {
    getActiveTab: (state) => state.activeTab,
    getDatasetId: (state) => state.datasetId,
    getSelectedTextQuestion: (state) => state.selectedTextQuestion,
    getThemes: (state) => state.themes,
    getSelectedThemeUUIDs: (state) => state.selectedThemeUUIDs,
    getSelectedTheme: (state) => state.selectedTheme,
    getSentimentsSortOrder: (state) => state.sentimentsSortOrder,
    getSelectedResponseIds: (state) => state.selectedResponseIds,
    getNotes: (state) => state.notes,
    getSearch: (state) => state.search,
    getBannedKeywords: (state) => state.bannedKeywords,
    getBannedComments: (state) => state.bannedComments,
    getHiddenComments: (state) => state.hiddenComments,
    getShowSpinner: (state) => state.showSpinner,
    getShowResponseListLoadingSpinner: (state) =>
      state.showResponseListLoadingSpinner
  },
  mutations: {
    SET_ACTIVE_TAB(state, payload) {
      state["activeTab"] = payload
    },
    SET_DATASET_ID(state, payload) {
      state["datasetId"] = payload
    },
    SET_TEXT_QUESTIONS(state, payload) {
      state["textQuestions"] = payload
    },
    SET_SELECTED_TEXT_QUESTION(state, payload) {
      state["selectedTextQuestion"] = payload
    },
    SET_THEMES(state, payload) {
      state["themes"] = payload
    },
    SET_SELECTED_THEME_UUIDS(state, payload) {
      state["selectedThemeUUIDs"] = payload
    },
    SET_SENTIMENTS_SORT_ORDER(state, payload) {
      state["sentimentsSortOrder"] = payload
    },
    SET_SELECTED_RESPONSE_IDS(state, payload) {
      state["selectedResponseIds"] = payload
    },
    SET_NOTES(state, payload) {
      state["notes"] = payload
    },
    SET_SEARCH(state, payload) {
      state["search"] = { ...state.search, ...payload }
    },
    SET_BANNED_KEYWORDS(state, payload) {
      state["bannedKeywords"] = payload
    },
    SET_BANNED_COMMENTS(state, payload) {
      state["bannedComments"] = payload
    },
    SET_HIDDEN_COMMENTS(state, payload) {
      state["hiddenComments"] = payload
    },
    SET_SHOW_SPINNER(state, payload) {
      state["showSpinner"] = payload
    },
    SET_SHOW_RESPONSE_LIST_LOADING_SPINNER(state, payload) {
      state["showResponseListLoadingSpinner"] = payload
    },
    RESET_ANALYSIS_TEXT(state) {
      state["datasetId"] = DEFAULTS.datasetId
      state["selectedTextQuestion"] = DEFAULTS.selectedTextQuestion
      state["themes"] = DEFAULTS.themes
      state["selectedThemeUUIDs"] = DEFAULTS.selectedThemeUUIDs
      state["sentimentsSortOrder"] = DEFAULTS.sentimentsSortOrder
      state["selectedResponseIds"] = DEFAULTS.selectedResponseIds
      state["search"] = DEFAULTS.search
      state["bannedKeywords"] = DEFAULTS.bannedKeywords
      state["bannedComments"] = DEFAULTS.bannedComments
      state["hiddenComments"] = DEFAULTS.hiddenComments
      state["showSpinner"] = DEFAULTS.showSpinner
      state["showResponseListLoadingSpinner"] =
        DEFAULTS.showResponseListLoadingSpinner
    },
    RESET_SEARCH(state) {
      state["search"] = DEFAULTS.search
    }
  },
  // TO AUDIT, as we rarely use these now
  actions: {
    setSelectedTextQuestion({ commit }, payload) {
      commit("SET_SELECTED_TEXT_QUESTION", payload)
    },
    setThemes({ commit }, payload) {
      commit("SET_THEMES", payload)
    },
    setSelectedThemeUUIDs({ commit }, payload) {
      commit("SET_SELECTED_THEME_UUIDS", payload)
    },
    setSentiments({ commit }, payload) {
      commit("SET_SENTIMENTS", payload)
    },
    setSentimentsSortOrder({ commit }, payload) {
      commit("SET_SENTIMENTS_SORT_ORDER", payload)
    },
    setSelectedResponseIds({ commit }, payload) {
      commit("SET_SELECTED_RESPONSE_IDS", payload)
    },
    setSearch({ commit }, payload) {
      commit("SET_SEARCH", payload)
    },
    setShowSpinner({ commit }, payload) {
      commit("SET_SHOW_SPINNER", payload)
    },
    setShowResponseListLoadingSpinner({ commit }, payload) {
      commit("SET_SHOW_RESPONSE_LIST_LOADING_SPINNER", payload)
    },
    setBannedKeywords({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit(
        "SET_BANNED_KEYWORDS",
        payload.map((el) => el.trim().toLowerCase())
      )
    },
    setBannedComments({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit("SET_BANNED_COMMENTS", payload)
    },
    setHiddenComments({ commit }, payload) {
      if (!Array.isArray(payload)) return
      commit("SET_HIDDEN_COMMENTS", payload)
    },
    resetAnalysisText({ commit }) {
      commit("RESET_ANALYSIS_TEXT")
    },
    resetSearch({ commit }) {
      commit("RESET_SEARCH")
    }
  }
}
