<template>
  <article
    id="project-data"
    :aria-hidden="$store.getters['getModalOpen']"
    :inert="$store.getters['getModalOpen']"
  >
    <h2 class="sr-only"><VisibleText>Data</VisibleText></h2>
    <DatasetTable
      class="project-data__dataset-table"
      :read-only="true"
      :hide-options="true"
      style="width: 90%"
    />
  </article>
</template>

<script>
// Components
import DatasetTable from "@/components/Dataset/Components/DatasetTable/DatasetTable.vue"

export default {
  name: "ProjectData",
  components: {
    DatasetTable
  },
  data() {
    return {
      BenchmarkGroup: {}
    }
  },
  async created() {
    document.title = `${this.$store.getters["project/getProject"].name} - Data | ${this.$theme.theme.pageTitle}`
  }
}
</script>
