<template>
  <div class="dataset-table__match-wrapper">
    <!--------------------------------------
     | read only
    ---------------------------------------->
    <template v-if="readOnly && confirmedGlobalQuestion">
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
    </template>
    <!--------------------------------------
     | suggested global match
    ---------------------------------------->
    <template
      v-if="!readOnly && !confirmedGlobalQuestion && suggestedGlobalQuestion"
    >
      <span class="dataset-table__match-title">
        <div class="dataset-table__match-suggested" title="suggested match">
          <span class="sr-only" :data-parent="$options.name">
            {{ translate("suggested match", $options.name) }}
          </span>
        </div>
        {{ suggestedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ suggestedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div v-if="!hasMatchingType">
          <button class="dataset-table__match-action-btn warning" disabled>
            <SvgIconDecorative icon="warning" />
            <VisibleText>data types do not match</VisibleText>
          </button>
          <button
            tabindex="-1"
            class="dataset-table__match-action-btn modify"
            @click="onClickModifyGlobalMatch()"
            @keyup.left.stop="goLeftOrUp"
            @keyup.up.stop="goLeftOrUp"
            @keyup.right.stop="goRightOrDown"
            @keyup.down.stop="goRightOrDown"
          >
            <SvgIconDecorative icon="edit" />
            <VisibleText>modify</VisibleText>
          </button>
        </div>

        <div v-if="hasMatchingType">
          <button
            tabindex="-1"
            class="dataset-table__match-action-btn approve"
            @click="approveSuggestedMatch()"
            @keyup.left.stop="goLeftOrUp"
            @keyup.up.stop="goLeftOrUp"
            @keyup.right.stop="goRightOrDown"
            @keyup.down.stop="goRightOrDown"
          >
            <SvgIconDecorative icon="check" />
            <VisibleText>request approval</VisibleText>
          </button>
          <button
            tabindex="-1"
            class="dataset-table__match-action-btn modify"
            @click="onClickModifyGlobalMatch()"
            @keyup.left.stop="goLeftOrUp"
            @keyup.up.stop="goLeftOrUp"
            @keyup.right.stop="goRightOrDown"
            @keyup.down.stop="goRightOrDown"
          >
            <SvgIconDecorative icon="edit" />
            <VisibleText>modify</VisibleText>
          </button>
        </div>
      </div>
    </template>

    <!--------------------------------------
     | no match found
    ---------------------------------------->
    <button
      v-if="!readOnly && !suggestedGlobalQuestion && !confirmedGlobalQuestion"
      tabindex="-1"
      class="dataset-table__match-action-btn add"
      @click="onClickModifyGlobalMatch()"
    >
      <VisibleText>add global match</VisibleText>
    </button>

    <!--------------------------------------
     | Requested global match
    ---------------------------------------->
    <template
      v-if="
        !readOnly && confirmedGlobalQuestion && matchStatus.status === 'pending'
      "
    >
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div
          style="display: flex; flex-direction: column; width: 95%"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <SvgIconDecorative icon="info" />
              <VisibleText>Request pending</VisibleText>
            </div>
          </div>
        </div>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <VisibleText>remove requested match</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="onClickModifyGlobalMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <VisibleText>modify</VisibleText>
        </button>
      </div>
    </template>

    <!--------------------------------------
     | Global match approved
    ---------------------------------------->
    <template
      v-if="
        !readOnly &&
        confirmedGlobalQuestion &&
        matchStatus.status === 'approved'
      "
    >
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div
          style="display: flex; flex-direction: column; width: 95%"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <SvgIconDecorative icon="approved" />
              <VisibleText>Approved</VisibleText>
            </div>
          </div>
        </div>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <VisibleText>remove match</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="onClickModifyGlobalMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <VisibleText>modify</VisibleText>
        </button>
      </div>
    </template>

    <!--------------------------------------
     | Global match rejected
    ---------------------------------------->
    <template
      v-if="
        !readOnly &&
        confirmedGlobalQuestion &&
        matchStatus.status === 'rejected'
      "
    >
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div
          style="display: flex; flex-direction: column; width: 95%"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <SvgIconDecorative icon="warning" />
              <VisibleText>Rejected</VisibleText>
            </div>
          </div>
        </div>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <VisibleText>remove match</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="onClickModifyGlobalMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <VisibleText>modify</VisibleText>
        </button>
      </div>
    </template>

    <!--------------------------------------
     | Global match auto-approved
    ---------------------------------------->
    <template
      v-if="
        !readOnly &&
        confirmedGlobalQuestion &&
        matchStatus.status === 'auto-approved'
      "
    >
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div
          style="display: flex; flex-direction: column; width: 95%"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <SvgIconDecorative icon="check" />
              <VisibleText>Auto-approved</VisibleText>
            </div>
          </div>
        </div>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <VisibleText>remove match</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="onClickModifyGlobalMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <VisibleText>modify</VisibleText>
        </button>
      </div>
    </template>
    <!--------------------------------------
     | Global match tentatively approved
    ---------------------------------------->
    <template
      v-if="
        !readOnly &&
        confirmedGlobalQuestion &&
        matchStatus.status === 'temporarily-approved'
      "
    >
      <span class="dataset-table__match-title">
        {{ confirmedGlobalQuestion.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ confirmedGlobalQuestion.question_text }}
      </span>
      <div v-if="permissions.includes('update-dataset')">
        <div
          style="display: flex; flex-direction: column; width: 95%"
          class="message__block message__block--information"
        >
          <div style="display: flex; flex-direction: row">
            <div
              class="project-analysis-item__header-wrapper-btn"
              style="display: flex; font-weight: 200"
            >
              <SvgIconDecorative icon="eye" />
              <VisibleText>Tentatively approved</VisibleText>
            </div>
            <Tooltip :leftAlign="true">
              <p>
                <VisibleText>
                  Tentatively approve means letting the user create reports but
                  their scores will not be included in the database.
                </VisibleText>
              </p>
            </Tooltip>
          </div>
        </div>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <VisibleText>remove match</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="onClickModifyGlobalMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <VisibleText>modify</VisibleText>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"
import MailerService from "@/services/mailerService.js"
import Tooltip from "@/components/Tooltip/Tooltip.vue"

// Mixins
import BenchmarksMixin from "@/components/Dataset/Mixins/benchmarksMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Consts
import { GLOBAL_MATCH_THRESHOLD } from "../../../Utils/consts.js"

export default {
  name: "GlobalMatch",
  mixins: [BenchmarksMixin, DatasetDetailsMixin, UserMixin],
  props: {
    clientQuestionId: {
      default: () => {},
      type: String
    },
    approvedType: {
      default: () => "",
      type: String
    },
    // saved or confirmed global match (see $store.datasetDetails.matchesDetails)
    confirmedGlobalQuestionMatch: {
      default: () => null,
      type: Object
    },
    // top_global_questions field from the suggestor
    suggestedTopMatch: {
      default: null,
      type: Array
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    detailsForSharingWithBenchmarking: {
      default: () => {},
      type: Object
    },
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    }
  },
  data() {
    return {
      MAILER_SERVICE: new MailerService(this.$pigeonline),
      suggestedGlobalQuestion: null,
      confirmedGlobalQuestion: null
    }
  },
  components: {
    SvgIconDecorative,
    Tooltip
  },
  methods: {
    goLeftOrUp($e) {
      if ($e.target.previousElementSibling) {
        $e.target.previousElementSibling.focus()
      }
    },
    goRightOrDown($e) {
      if ($e.target.nextElementSibling) {
        $e.target.nextElementSibling.focus()
      }
    },
    onClickModifyGlobalMatch() {
      this.$emit("modify-global-match")
    },
    setSuggestedGlobalQuestion(globalQuestionId) {
      this.suggestedGlobalQuestion = this.globalQuestions.find(
        (question) => question._id.$oid === globalQuestionId
      )
      this.setSuggestedMatches({
        client_question_id: this.clientQuestionId,
        global_question_id: globalQuestionId
      })
    },
    async approveSuggestedMatch() {
      if (!this.suggestedGlobalQuestion) return
      const clientQuestion = this.clientQuestions.find(
        (q) => q._id.$oid === this.clientQuestionId
      )
      await this.$_benchmarksMixin_newMatch(
        clientQuestion,
        this.suggestedGlobalQuestion._id.$oid,
        this.suggestedGlobalQuestion._id.$oid,
        this.detailsForSharingWithBenchmarking.dataset_details,
        this.detailsForSharingWithBenchmarking.user_details
      )
      this.sendForm()
      if (this.suggestedTopMatch) {
        this.$emit("approved-global-match")
      }
    },
    async removeMatch() {
      await this.$_benchmarksMixin_deleteBenchmarkMatch(
        this.matchesDetails[this.clientQuestionId].benchmark_id
      )
      // update client question store
      const clientQ = await this.$services.DATASETS_SERVICE.clientQuestions(
        this.clientQuestionId
      )
      this.updateClientQuestion(clientQ)
      // reset values
      this.suggestedGlobalQuestion = null
      this.$_datasetDetailsMixin_reloadBenchmarks()
    },
    async sendForm() {
      if (
        this.benchmarkGroup &&
        this.benchmarkGroup.share_admin &&
        this.benchmarkGroup.admin_email
      ) {
        const email = this.$pigeonline.getUserEmail()
        if (email) {
          await this.MAILER_SERVICE.sendContactUs({
            subject: "A benchmarking request has been submitted.",
            message: `
            ${this.emailMessage}

          `,
            to: [this.benchmarkGroup.admin_email]
          })
        }
      }
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-GB")
    }
  },
  computed: {
    emailMessage() {
      let thisContent = ""
      const email = this.$pigeonline.getUserEmail()
      if (email) {
        thisContent = `From: ${email}`
      }
      if (this.detailsForSharingWithBenchmarking) {
        if (this.detailsForSharingWithBenchmarking.dataset_details) {
          if (this.detailsForSharingWithBenchmarking.dataset_details.name) {
            thisContent =
              thisContent +
              `
            File name: ${this.detailsForSharingWithBenchmarking.dataset_details.name}`
          }
          if (this.detailsForSharingWithBenchmarking.dataset_details.name) {
            thisContent =
              thisContent +
              `
            Uploaded on: ${this.formatDate(
              this.detailsForSharingWithBenchmarking.dataset_details.created_on
            )}`
          }
        }
      }
      if (
        this.suggestedGlobalQuestion &&
        this.suggestedGlobalQuestion._id &&
        this.suggestedGlobalQuestion._id.$oid
      ) {
        for (let i = 0; i < this.globalQuestions.length; i++) {
          if (
            this.globalQuestions[i]._id &&
            this.globalQuestions[i]._id.$oid &&
            this.globalQuestions[i]._id.$oid ==
              this.suggestedGlobalQuestion._id.$oid
          ) {
            if (this.globalQuestions[i].question_title) {
              thisContent =
                thisContent +
                `
              Match requested to the BM Question title: ${this.globalQuestions[i].question_title}`
            }
            if (this.globalQuestions[i].question_text) {
              thisContent =
                thisContent +
                `
              Match requested to the BM Question wording: ${this.globalQuestions[i].question_text}`
            }
          }
        }
      }

      return thisContent
    },
    matchStatus() {
      if (this.clientQuestionId) {
        const approvedQuestion = this.$store.getters[
          "datasetDetails/getBenchmarks"
        ].filter(
          (question) => question.client_question_id === this.clientQuestionId
        )

        if (approvedQuestion) {
          if (approvedQuestion[0]) {
            if (approvedQuestion[0].auto_approved) {
              return { question: approvedQuestion, status: "auto-approved" }
            }
            if (
              approvedQuestion[0].approved &&
              !approvedQuestion.auto_approved
            ) {
              return { question: approvedQuestion, status: "approved" }
            }
            if (approvedQuestion[0].rejected) {
              return { question: approvedQuestion, status: "rejected" }
            }
            if (approvedQuestion[0].temporarily_approved) {
              return {
                question: approvedQuestion,
                status: "temporarily-approved"
              }
            }
          } else {
            if (approvedQuestion.auto_approved) {
              return { question: approvedQuestion, status: "auto-approved" }
            }
            if (approvedQuestion.approved && !approvedQuestion.auto_approved) {
              return { question: approvedQuestion, status: "approved" }
            }
            if (approvedQuestion.rejected) {
              return { question: approvedQuestion, status: "rejected" }
            }
            if (approvedQuestion.temporarily_approved) {
              return {
                question: approvedQuestion,
                status: "temporarily-approved"
              }
            }
          }

          return { question: approvedQuestion, status: "pending" }
        }
      }
      return {}
    },
    hasMatchingType() {
      if (this.suggestedGlobalQuestion && this.approvedType) {
        if (this.suggestedGlobalQuestion.data_type == this.approvedType) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    confirmedGlobalQuestionMatch: {
      immediate: true,
      handler: function (match) {
        if (match)
          this.confirmedGlobalQuestion = this.globalQuestions.find(
            (question) => question._id.$oid === match.global_question_id
          )
        else this.confirmedGlobalQuestion = null
      }
    },
    suggestedTopMatch: {
      immediate: true,
      handler: function (suggestedMatch) {
        if (suggestedMatch) {
          const score = suggestedMatch[0] ? suggestedMatch[0][1] : 0
          // only if the match is significant
          if (score > GLOBAL_MATCH_THRESHOLD)
            this.setSuggestedGlobalQuestion(suggestedMatch[0][0])
        } else this.suggestedGlobalQuestion = null
      }
    }
  }
}
</script>
