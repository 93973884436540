import loader from "./loader.js"
import datasetDetails from "./datasetDetails.js"
import datasetWizard from "./datasetWizard.js"
import project from "./project.js"
import user from "./user.js"
import analysisText from "./analysisText.js"
import confirm from "./confirm.js"
import checkedTranslations from "./checkedTranslations.js"
import globalModule from "./globalModule.js"

export default {
  loader,
  datasetDetails,
  datasetWizard,
  project,
  user,
  analysisText,
  confirm,
  checkedTranslations,
  globalModule
}
