<template>
  <Modal
    class="create-new-analysis"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>Create new analysis</VisibleText>
    </template>
    <VisibleText
      >Select scale questions to perform outliers analysis. It will then look
      for groups (data you labelled as categories) that are high or low in
      performance compared to the average.</VisibleText
    >
    <SelectQuestionsList
      :questions="dataPreprocessed"
      :toEmitUpdate="true"
      :selected-ids="selectedQuestionIds"
      @update-selected-question-ids="updateSelectedQuestionIds"
    />
    <VisibleText v-if="dataPreprocessed.length == 0" class="info-msg__inline">
      No scale or text questions found.
    </VisibleText>
    <template v-slot:footer>
      <button
        class="create-new-analysis__confirm-select-btn"
        @click="confirmSelection"
        :disabled="isConfirmSelectDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="create-new-analysis__confirm-select-btn-icon"
        />
        <VisibleText>Confirm selection</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SelectQuestionsList from "../../Components/SelectQuestionsList.vue"

import _ from "lodash"

export default {
  name: "CreateNewAnalysis",
  components: {
    Modal,
    SvgIconDecorative,
    SelectQuestionsList
  },
  props: {
    availableQuestions: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isConfirmSelectDisabled: true,
      selectedQuestionIds: [],
      resetListener: false
    }
  },
  computed: {
    dataPreprocessed() {
      return _.cloneDeep(this.availableQuestions).filter(
        (q) =>
          q.approved_data_type &&
          (q.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/) ||
            q.approved_data_type === "OPEN_ENDED")
      )
    }
  },
  methods: {
    updateSelectedQuestionIds(questionIds) {
      this.isConfirmSelectDisabled = questionIds.length === 0
      this.selectedQuestionIds = questionIds
    },
    confirmSelection() {
      this.$emit("confirmSelection", this.selectedQuestionIds)
      this.$emit("close-modal")
      // this.dataPreprocessed
      //   .filter(q => this.selectedQuestionIds.includes(q._id.$oid))
      //   .map(el => [el.id, el.question_title])

      this.selectedQuestionIds = []
    }
  },
  watch: {
    show: function (val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.resetListener = !this.resetListener
    }
  }
}
</script>
