import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  mixins: [ProjectMixin],
  data() {
    return {
      emptyToolsObject: {
        uuid: "",
        last_modified_on: ""
      }
    }
  },
  methods: {
    // ***
    // INITIAL LOGIC SECTION
    async fetchEmptyToolsAnalysis() {
      try {
        return this.emptyToolsObject
      } catch (e) {
        throw new Error(
          "toolsAnalysisMixin:fetchEmptyToolsAnalysis " + e.message
        )
      }
    }
  }
}
