import PromiseQueueModel from "@/models/PromiseQueueModel.js"
import { GLOBAL_MATCH_THRESHOLD } from "./consts"

export default class GlobalMatchesPromiseQueueModel extends PromiseQueueModel {
  constructor(data = {}) {
    super(data)
  }

  runConcurrent() {
    while (this.runNext()) {
      const promise = this.todo.shift()
      promise().then((response) => {
        response.forEach((r) => {
          // only add those that are above the threshold
          if (
            r.top_global_questions[0] &&
            r.top_global_questions[0][1] > GLOBAL_MATCH_THRESHOLD
          ) {
            this.complete.push({
              [r.client_question_id]: r.top_global_questions
            })
          }
        })
        this.running.shift()
        this.runConcurrent()
        if (this.todo.length === 0 && this.running.length === 0)
          this.finished = true
      })
      this.running.push(promise)
    }
  }
}
