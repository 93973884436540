<template>
  <Modal
    class="project-analysis-text-modal modal-themes-edit"
    header-tag="h3"
    :isEditThemes="true"
    :ref="modalRef"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <template #header>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        "
      >
        <h3 style="display: flex">
          <VisibleText>Edit themes</VisibleText>
        </h3>
        <div style="display: flex">
          <button
            type="button"
            class="theme__helper-options-btn"
            @click="$emit('bannedKeywordsModalIsVisible')"
            style="margin-right: 1rem; font-size: 1rem"
          >
            <SvgIconDecorative icon="ban" />
            <VisibleText style="font-size: 1rem"
              >Show banned keywords</VisibleText
            >
          </button>
        </div>
      </div>
    </template>

    <div style="padding: 1em; margin-top: -2em">
      <div v-if="!themeList.length">
        <VisibleText>No themes found.</VisibleText>
      </div>
    </div>
    <ThemeList
      :isThemeEdit="true"
      :ref="themeListRef"
      type="keyword"
      :textAnalysisObject="textAnalysisObject"
      :unsortedResponses="unsortedResponses"
      :themes="themeList"
      :should-focus-first-theme="shouldFocusFirstTheme"
      :key="updateKey"
      :duplicateIndex="duplicateIndex"
      :propReadOnly="propReadOnly"
      :sortedThemes="sortedThemes"
      :sortedResponsesLength="sortedResponsesLength"
      :hasFetchedLemmas="hasFetchedLemmas"
      :includeSmartCoverage="includeSmartCoverage"
      :hasFetchedSentences="hasFetchedSentences"
      @removeTheme="$emit('removeTheme', $event)"
      @updatingSpinner="updateSpinner"
      @updateTextObject="updateTextObject($event)"
      @update-theme-title="
        renameThemeTitle(themeList[$event.index], $event.title)
      "
    />
    <div v-if="foundChanges.length">
      <VisibleText>Warning. Unsaved changes applied.</VisibleText>
    </div>
    <template #footer>
      <div class="modal-footer__options">
        <button
          class="btn-default"
          role="button"
          @click.stop="addToSavedThemes"
          :disabled="!foundChanges.length || awaitingLoading"
        >
          <div v-if="!foundChanges.length">
            <VisibleText>No new themes to save</VisibleText>
          </div>
          <div v-else-if="awaitingLoading">
            <VisibleText>Wait for coverage to complete</VisibleText>
          </div>
          <div v-else>
            <VisibleText>Save theme changes</VisibleText>
          </div>
        </button>
        <button
          class="btn-create btn-secondary"
          role="button"
          @click.stop="createTheme"
          :disabled="propReadOnly"
        >
          <VisibleText>Create new theme</VisibleText>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "ThemesEdit",
  mixins: [ThemeListMixin, textAnalysisMixin, themeColorsMixin],
  components: { SvgIconDecorative },
  props: {
    show: {
      default: () => false,
      type: Boolean
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    unsortedResponses: {
      type: Array,
      default: () => []
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false
    },
    hasFetchedSentences: {
      type: Boolean,
      default: () => false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isSaving: false,
      duplicateIndex: -1,
      suggestedThemes: [],
      spinnerQueue: []
    }
  },
  created() {
    if (this.sortedThemes) {
      if (this.sortedThemes.length) {
        this.themeList = this.deepCloneObj(this.sortedThemes)
      }
    }
  },
  computed: {
    parentThemesLength() {
      let length = 0
      if (this.sortedThemes) {
        if (this.sortedThemes.length) {
          length = this.sortedThemes.themes
        }
      }
      return length
    },
    awaitingLoading() {
      if (this.spinnerQueue.length) {
        return true
      }
      return false
    },
    foundChanges() {
      let changedThemes = []
      if (!this.themeList || !this.themeList.length) return changedThemes
      let themes = this.deepCloneObj(this.themeList)
      // if (!this.sortedThemes.length && themes.length) return themes

      // -1 means a change has been made but update all themes regardless of index
      // if (!this.sortedThemes.length && themes.length) return [-1]
      // if (this.sortedThemes.length !== this.themesList.length) return [-1]
      if (this.sortedThemes) {
        for (let x = 0; x < themes.length; x++) {
          // Each theme has to find at least one matching UUID
          let foundMatchingUUID = false
          for (let i = 0; i < this.sortedThemes.length; i++) {
            if (themes[x]._id === this.sortedThemes[i]._id) {
              foundMatchingUUID = true
              // name changed
              if (themes[x].name !== this.sortedThemes[i].name) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new coverage
              if (
                themes[x].coverage_count != this.sortedThemes[i].coverage_count
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new filtered coverage
              if (
                themes[x].filtered_coverage_count !=
                this.sortedThemes[i].filtered_coverage_count
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new keyword length
              if (
                themes[x].keywords.length !==
                this.sortedThemes[i].keywords.length
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              let foundMatch = []
              // new keyword found
              for (let y = 0; y < themes[x].keywords.length; y++) {
                if (
                  this.sortedThemes[i].keywords.includes(themes[x].keywords[y])
                ) {
                  foundMatch.push(1)
                } else {
                  foundMatch.push(0)
                }
              }
              if (foundMatch.includes(0)) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
            }
          }
          // entirely new theme, refresh all to be safe, can be optimized later
          if (!foundMatchingUUID) {
            changedThemes.push(x)
          }
        }
      }
      return changedThemes
    }
  },
  watch: {
    parentThemesLength: async function (val) {
      if (!val) return
      if (this.sortedThemes) {
        if (this.sortedThemes.length) {
          this.themeList = this.deepCloneObj(this.sortedThemes)
        }
      }
    },
    show: function (val) {
      if (val == true) {
        if (this.sortedThemes) {
          if (this.sortedThemes.length) {
            this.themeList = this.deepCloneObj(this.sortedThemes)
          }
        }
      } else {
        this.themeList = []
      }
    }
  },
  methods: {
    refreshThemesFromParent() {
      //let useTheseThemes = []
      this.themeList = this.deepCloneObj(this.sortedThemes)
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "themes"
      }
      this.$emit("updateTextObject", object)
    },
    updateSpinner(val) {
      this.spinnerQueue = val
    },
    async renameThemeTitle(theme, title) {
      if (this.themeList) {
        let themes = this.deepCloneObj(this.themeList)
        if (typeof title !== "string" || title.trim() === "") {
          title = "unnamed theme"
        }
        let foundIndex = -1
        let foundExisting = false
        for (let i = 0; i < themes.length; i++) {
          if (themes[i]._id === theme._id) {
            foundIndex = i
          } else {
            if (
              themes[i].name &&
              themes[i].name.toLowerCase() == title.toLowerCase()
            ) {
              foundExisting = true
            }
          }
        }
        if (foundIndex > -1 && !foundExisting) {
          themes[foundIndex].name = title
          this.themeList = themes
        }
        if (foundIndex > -1 && foundExisting) {
          this.duplicateIndex = foundIndex
          setTimeout(() => {
            this.duplicateIndex = -1
          }, 6000)
        }
      }
    },
    async addToSavedThemes() {
      if (this.foundChanges.length) {
        let these_themes = []
        for (let x = 0; x < this.foundChanges.length; x++) {
          let this_theme = this.themeList[this.foundChanges[x]]
          if (this_theme.is_proposed) {
            delete this_theme["is_proposed"]
            delete this_theme["_id"]
          }
          these_themes.push(this_theme)
        }
        let payload = {
          themes: these_themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (theseThemes.length) {
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "ThemesEdit"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.closeModal()
        }
      }
    }
  }
}
</script>
