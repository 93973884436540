import { app } from "@/main.js"
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

export default {
  namespaced: true,
  state() {
    return {
      activeSection: null,
      benchmarkGroup: null,
      copyingDetails: {
        isCopyingDetails: false,
        methodName: "copyValAndLabel"
      },
      datasetProject: new BenchmarkingDataset({ name: null }),
      deleteIncompleteUpload: false,
      toFetchSuggestedDataType: false,
      toFetchSuggestedMatches: false,
      unsavedChanges: [],
      spinner: {
        complete: "loading complete",
        message: "loading",
        isLoading: false
      }
    }
  },
  getters: {
    getActiveSection: (state) => state.activeSection,
    getBenchmarkGroup: (state) => state.benchmarkGroup,
    getCopyingDetails: (state) => state.copyingDetails,
    getDatasetProject: (state) => state.datasetProject,
    getDeleteIncompleteUpload: (state) => state.deleteIncompleteUpload,
    getToFetchSuggestedDataType: (state) => state.toFetchSuggestedDataType,
    getToFetchSuggestedMatches: (state) => state.toFetchSuggestedMatches,
    getUnsavedChanges: (state) => state.unsavedChanges,
    getSpinner: (state) => state.spinner
  },
  mutations: {
    SET_ACTIVE_SECTION(state, payload) {
      state["activeSection"] = payload
    },
    SET_BENCHMARK_GROUP(state, payload) {
      state["benchmarkGroup"] = payload
    },
    SET_COPYING_DETAILS(state, payload) {
      state["copyingDetails"] = payload
    },
    SET_DATASET_PROJECT(state, payload) {
      state["datasetProject"] = payload
    },
    SET_UNSAVED_CHANGES(state, payload) {
      state["unsavedChanges"] = payload
    },
    SET_DELETE_INCOMPLETE_UPLOAD(state, payload) {
      state["deleteIncompleteUpload"] = payload
    },
    SET_TO_FETCH_SUGGESTED_DATA_TYPE(state, payload) {
      state["toFetchSuggestedDataType"] = payload
    },
    SET_TO_FETCH_SUGGESTED_MATCHES(state, payload) {
      state["toFetchSuggestedMatches"] = payload
    },
    SET_SPINNER(state, payload) {
      state["spinner"][payload.key] = payload.value
      //app.set(state.spinner, payload.key, payload.value)
    },
    RESET_DATASET_WIZARD(state) {
      state["activeSection"] = null
      state["benchmarkGroup"] = null
      state["copyingDetails"] = {
        isCopyingDetails: false,
        methodName: "copyValAndLabel"
      }
      state["datasetProject"] = new BenchmarkingDataset({ name: null })
      state["unsavedChanges"] = []
      state["deleteIncompleteUpload"] = null
      state["toFetchSuggestedDataType"] = false
      state["toFetchSuggestedMatches"] = false
      state["spinner"] = {
        complete: "loading complete",
        message: "loading",
        isLoading: false
      }
    }
  },
  actions: {
    async setActiveSection({ commit }, payload) {
      await commit("SET_ACTIVE_SECTION", payload || "DatasetUploader")
    },
    async setBenchmarkGroup({ commit }, payload) {
      await commit("SET_BENCHMARK_GROUP", payload)
    },
    async setCopyingDetails({ commit }, payload) {
      await commit("SET_COPYING_DETAILS", payload)
    },
    async setDatasetProject({ commit }, payload) {
      await commit("SET_DATASET_PROJECT", payload)
    },
    async setUnsavedChanges({ commit }, payload) {
      await commit("SET_UNSAVED_CHANGES", payload)
    },
    async setDeleteIncompleteUpload({ commit }, payload) {
      await commit("SET_DELETE_INCOMPLETE_UPLOAD", payload)
    },
    async setToFetchSuggestedDataType({ commit }, payload) {
      await commit("SET_TO_FETCH_SUGGESTED_DATA_TYPE", payload)
    },
    async setToFetchSuggestedMatches({ commit }, payload) {
      await commit("SET_TO_FETCH_SUGGESTED_MATCHES", payload)
    },
    async setSpinner({ commit }, payload) {
      for (const [key, value] of Object.entries(payload)) {
        commit("SET_SPINNER", { key: key, value: value })
      }
    },
    async addUnsavedChanges({ commit, state }, payload) {
      if (!state.unsavedChanges.includes(payload))
        state.unsavedChanges.push(payload)
      await commit("SET_UNSAVED_CHANGES", state.unsavedChanges)
    },
    async removeUnsavedChanges({ commit, state }, payload) {
      const spliced = Array.from(state.unsavedChanges)
      const indx = spliced.indexOf(payload)
      if (indx > -1) {
        spliced.splice(indx, 1)
        await commit("SET_UNSAVED_CHANGES", spliced)
      }
    },
    async updateDatasetProjectStatus({ commit, state }, status) {
      if (!state.datasetProject.status[status]) {
        state.datasetProject.updateStatus(status)
        const updatedProject =
          await app.config.globalProperties.$pigeonline.projects.update(
            state.datasetProject
          )
        await commit("SET_DATASET_PROJECT", updatedProject)
      }
    },
    resetDatasetWizard({ commit }) {
      commit("RESET_DATASET_WIZARD")
    }
  }
}
