<template>
  <div style="max-width: 90%">
    <Tagging
      class="tagging--gray tagging--margin--topnone"
      @add="addKeyword"
      v-if="!readOnly"
    >
      <span class="sr-only"> {{ translate("Keywords", $options.name) }} </span>
    </Tagging>
    <TaggingList
      class="tagging-list--margin--none"
      :tags="$data.$_keywords"
      @remove="removeKeyword"
      :disabledInteraction="isPublicReadOnly"
    />
  </div>
</template>

<script>
// Components
import Tagging from "@/components/UI/Tagging/Tagging.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"

// Services
import customTopicModelService from "@/services/customTopicModelService.js"

export default {
  name: "Keywords",
  components: {
    Tagging,
    TaggingList
  },
  props: {
    isPublicReadOnly: {
      default: true,
      type: Boolean
    },
    readOnly: {
      default: true,
      type: Boolean
    },
    keywords: {
      type: Array,
      required: true
    },
    oid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      $_keywords: this.keywords
    }
  },
  methods: {
    async addKeyword(keyword) {
      try {
        this.$data.$_keywords.push(keyword)
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(this.oid, {
          keywords: this.$data.$_keywords
        })
      } catch (e) {
        throw new Error("Keywords:addKeyword " + e.message)
      }
    },
    async removeKeyword(keyword) {
      try {
        const indx = this.$data.$_keywords.indexOf(keyword)
        if (indx === -1) return
        this.$data.$_keywords.splice(indx, 1)
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(this.oid, {
          keywords: this.$data.$_keywords
        })
      } catch (e) {
        throw new Error("Keywords:removeKeyword " + e.message)
      }
    }
  }
}
</script>
