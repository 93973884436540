<template>
  <div class="register-demo__content">
    <Spinner :is-loading="showSpinner" :message="spinnerMessage" />
    <div>
      <router-link to="/login">
        <VisibleText>Go to login page</VisibleText>
      </router-link>
    </div>
    <h1 class="register-demo__title">
      <VisibleText>Create a demo account</VisibleText>
    </h1>
    <h2 style="font-size: 1.2rem">
      <VisibleText>Create an account without using your email</VisibleText>
    </h2>
    <p>
      <VisibleText
        >Some partners, like educators, want to give access to these tools as
        educational experiences without users needing to sign in with their real
        emails. By creating an account with this invite, you by-pass the need to
        register with your email. We will generate an account for
        you.</VisibleText
      >
    </p>
    <section class="register-demo__content">
      <p>
        If you have receieved an invite code for a demo, please insert it below:
      </p>
      <div style="display: flex; flex-direction: row">
        <form novalidate style="display: flex">
          <label
            for="invite-id"
            style="
              width: 20rem;
              margin-top: 1rem;
              text-align: right;
              margin-right: 1rem;
            "
            class="form-label"
          >
            <VisibleText>Invite ID</VisibleText>:
          </label>
          <input
            id="invite-id"
            type="text"
            ref="invite-id"
            v-model="inviteId"
            style="width: 30rem"
            v-on:keydown.enter.prevent="submitInviteID"
            :disabled="foundMatchingID.status == 'success'"
          />
        </form>
        <button
          class="benchmark-manager__editor-add"
          @click="submitInviteID"
          style="height: 2rem; margin-top: 0.8rem"
          :disabled="!inviteId.length"
          v-if="
            (foundMatchingID.status && foundMatchingID.status != 'success') ||
            !foundMatchingID.status
          "
        >
          <SvgIconDecorative icon="search" />
          <VisibleText>Proceed</VisibleText>
        </button>
        <button
          class="benchmark-manager__editor-add"
          @click="submitInviteID"
          style="
            height: 2rem;
            margin-top: 0.8rem;
            background-color: var(--very-positive-soft);
          "
          :disabled="true"
          v-if="foundMatchingID.status && foundMatchingID.status == 'success'"
        >
          <SvgIconDecorative icon="check" />
          <VisibleText>Invite code is valid</VisibleText>
        </button>
      </div>

      <div v-if="foundMatchingID.status && foundMatchingID.status == 'success'">
        <button
          class="register-demo__form-submit-btn"
          @click.prevent="createDemoAccount"
          :disabled="registered"
        >
          <VisibleText>Create demo account</VisibleText>
        </button>
      </div>

      <div v-if="foundMatchingID.status && foundMatchingID.status == 'failed'">
        <MessageBlock
          message-type="information"
          class="register-demo__groups-unavailable"
        >
          <VisibleText>Invalid id</VisibleText>
        </MessageBlock>
      </div>

      <div class="register-demo__registered" v-if="registered">
        <MessageBlock class="success-message" message-type="information">
          New demo account created successfully. Please use the credentials
          below to log in. Save this information so you can access this account
          any time.
        </MessageBlock>
        <div class="credentials__wrapper">
          <div class="credentials__content">
            <p class="credentials__row">
              <span class="label">
                <VisibleText>Email</VisibleText>
              </span>
              <span class="value">{{ registered.email }}</span>
            </p>
            <p class="credentials__row">
              <span class="label">
                <VisibleText>Password</VisibleText>
              </span>
              <span class="value">{{ registered.password }}</span>
            </p>
            <a
              href="#"
              class="credentials__save-link"
              @click.prevent="saveCredentialsAsText"
              ><VisibleText>Save credentials as a text file</VisibleText></a
            >

            <Login
              :demoEmail="registered.email"
              :demoPassword="registered.password"
              class="login__form"
              @authenticated="afterLogin"
            >
              <template v-slot="{ email, password, update }">
                <label for="email" class="form-style-group-label">
                  <span class="form-style-group-text">
                    <VisibleText>Email Address</VisibleText>
                  </span>
                  <div class="form-style-group-input-wrapper">
                    <EmailIcon class="form-style-group-input-icon" />
                    <input
                      id="email"
                      @input="update('email', $event.target.value)"
                      type="text"
                      :value="email"
                    />
                  </div>
                </label>

                <label for="password" class="form-style-group-label">
                  <span class="form-style-group-text">
                    <VisibleText>Password</VisibleText>
                  </span>
                  <div class="form-style-group-input-wrapper">
                    <PasswordIcon class="form-style-group-input-icon" />
                    <input
                      id="password"
                      @input="update('password', $event.target.value)"
                      type="password"
                      :value="password"
                    />
                  </div>
                </label>
              </template>
            </Login>
          </div>
        </div>
      </div>

      <!-- <MessageBlock
        message-type="information"
        class="register-demo__groups-unavailable"
        v-if="isDemoGroupsEmpty"
      >
        There are no demo groups active at present. Please try again later or
        contact admin.
      </MessageBlock>
      <template v-else>
        <div class="register-demo__registered" v-if="registered">
          <MessageBlock class="success-message" message-type="information">
            New demo account created successfully. Please use the credentials
            below to log in.
          </MessageBlock>
          <div class="credentials__wrapper">
            <div class="credentials__content">
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Group ID</VisibleText>
                </span>
                <span class="value">{{ picked }}</span>
              </p>
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Email</VisibleText>
                </span>
                <span class="value">{{ registered.email }}</span>
              </p>
              <p class="credentials__row">
                <span class="label">
                  <VisibleText>Password</VisibleText>
                </span>
                <span class="value">{{ registered.password }}</span>
              </p>
              <a
                href="#"
                class="credentials__save-link"
                @click.prevent="saveCredentialsAsText"
                ><VisibleText>Save credentials as a text file</VisibleText></a
              >
            </div>
          </div>
        </div>
        <form class="register-demo__form" v-else>
          <p class="register-demo__form-legend">
            <VisibleText
              >Please select the demo group below to create a demo account.
            </VisibleText>
          </p>
          <div
            class="register-demo__form-input"
            v-for="(group, index) in demoGroups"
            :key="index"
          >
            <label
              class="register-demo__form-input-label"
              :for="`input-${index}`"
            >
              <h3 class="title">{{ group.title }}</h3>
              <span class="sub-title">{{ group.group_id }}</span>
            </label>
            <input
              class="register-demo__form-input-radio"
              type="radio"
              :id="`input-${index}`"
              :value="group.group_id"
              v-model="picked"
            />
          </div>
          <button
            class="register-demo__form-submit-btn"
            :disabled="!picked"
            @click.prevent="createDemoAccount"
          >
            <VisibleText>Create account</VisibleText>
          </button>
        </form>
      </template> -->
    </section>
  </div>
</template>

<script>
import axios from "axios"

// Components
import Spinner from "@/components/UI/Spinner.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import { Login } from "@pigeonline/core"

// Icons
import EmailIcon from "@/assets/icons/Login/email.svg"
import PasswordIcon from "@/assets/icons/Login/pswd.svg"

// Mixins
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "registerDemo",
  components: {
    Spinner,
    MessageBlock,
    SvgIconDecorative,
    Login,
    EmailIcon,
    PasswordIcon
  },
  mixins: [UserMixin],
  data() {
    return {
      foundMatchingID: {
        status: ""
      },
      demoGroups: [],
      picked: null,
      registered: null,
      showSpinner: false,
      spinnerMessage: "loading...",
      inviteId: ""
    }
  },
  computed: {
    isDemoGroupsEmpty: function () {
      return this.demoGroups.length == 0
    }
  },
  async mounted() {
    // this.demoGroups = await this.fetchDemoGroups()
    // const groupID = this.$route.query.group_id
    // const group =
    //   groupID && this.demoGroups.find((el) => el.group_id == groupID)
    // if (group) {
    //   this.demoGroups = [group]
    //   this.picked = groupID
    // }
  },
  methods: {
    async submitInviteID() {
      let findId = await this.setIsResearchLicense({ invite_id: this.inviteId })
      if (findId) {
        this.foundMatchingID = findId
      }
    },
    async setIsResearchLicense(request) {
      try {
        const response = await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/register/check-invite-id`,
          request
        )
        return response.data
      } catch (e) {
        throw new Error("teamsService.js " + e.message)
      }
    },

    fetchInviteStatus: async function () {
      this.showSpinner = true
      try {
        const res = await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/register/check-invite-id`
        )
        return res.data.filter((g) => g.is_active)
      } catch (e) {
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("RegisterDemo.vue:fetchDemoGroups:: " + e)
      } finally {
        this.showSpinner = false
      }
    },

    fetchDemoGroups: async function () {
      this.showSpinner = true
      try {
        const res = await axios.get(
          `${this.$pigeonline.options.baseUrl}/rauth/demo-groups/`
        )
        return res.data.filter((g) => g.is_active)
      } catch (e) {
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("RegisterDemo.vue:fetchDemoGroups:: " + e)
      } finally {
        this.showSpinner = false
      }
    },
    async createDemoAccount() {
      this.showSpinner = true
      try {
        const res = await axios.post(
          `${this.$pigeonline.options.baseUrl}/rauth/demo-register/`,
          { team_id: this.inviteId }
        )
        this.registered = res.data
      } catch (e) {
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("RegisterDemo.vue:createDemoAccount:: " + e)
      } finally {
        this.showSpinner = false
      }
    },
    saveCredentialsAsText() {
      let currentDate = new Date().toUTCString()
      let downloadText = `Email: ${this.registered.email}\nPassword: ${this.registered.password}\n\nGenerated on ${currentDate}.`
      let filename = `demo-credentials-${currentDate}`
      var blob = new Blob([downloadText], { type: "text/plain;charset=utf-8;" })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var elem = window.document.createElement("a")
        elem.href = window.URL.createObjectURL(blob)
        elem.download = filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    },

    async afterLogin() {
      // update user store
      await this.setUserDetails()
      await this.setUserNotifications()

      // go to home page or redirect path if it exists
      const redirectPath = this.$route.query.redirect || "/projects"
      this.$router.push({ path: redirectPath })
    }
  }
}
</script>
