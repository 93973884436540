import { app } from "@/main.js"
import { axios } from "@pigeonline/core"

import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

const fetchDataset = async (datasetId) => {
  try {
    const response =
      await app.config.globalProperties.$services.DATASETS_SERVICE.dataset(
        datasetId
      )
    return response
  } catch (e) {
    return new Error("Dataset:fetch.js:fetchDataset " + e.message)
  }
}

const fetchDatasetProject = async (id) => {
  try {
    const response =
      await app.config.globalProperties.$pigeonline.projects.getByID(
        BenchmarkingDataset,
        id
      )
    return response
  } catch (e) {
    return new Error("DatasetMixin:fetchProject " + e.message)
  }
}

const fetchDatasetProjectByDatasetId = async (datasetId) => {
  try {
    const response = await app.config.globalProperties.$pigeonline.projects.get(
      BenchmarkingDataset,
      {
        dataset_id: datasetId
      }
    )
    return response
  } catch (e) {
    return new Error("projectMixin:fetchDatasetProject " + e.message)
  }
}

const fetchRecommendedGlobalQuestions = async (data) => {
  try {
    const URL = app.config.globalProperties.$base_URL + "/analysis/benchmarking"
    const response = await axios.post(
      `${URL}/recommended_global_questions`,
      data
    )
    return response.data
  } catch (e) {
    return new Error(
      "DatasetMixin:fetchRecommendedGlobalQuestions " + e.message
    )
  }
}

const fetchRecommendedDataType = async (data) => {
  try {
    const URL = app.config.globalProperties.$base_URL + "/analysis/drivers"
    const response = await axios.post(`${URL}/data_type`, data)
    return response.data
  } catch (e) {
    return new Error("DatasetMixin:fetchRecommendedDataType " + e.message)
  }
}

export {
  fetchDataset,
  fetchDatasetProject,
  fetchDatasetProjectByDatasetId,
  fetchRecommendedGlobalQuestions,
  fetchRecommendedDataType
}
