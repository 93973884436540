<template>
  <section id="benchmark-manager-groups" class="section__content">
    <ListGrid
      class="benchmark-manager__groups"
      :custom-grid-style="{
        gridTemplateColumns: '2fr 1fr 0.5fr 0.5fr 1fr 3rem'
      }"
      :headers="headers"
      :list="benchmarkGroups"
    >
      <template #default="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <router-link
            :to="{ name: 'benchmarkGroupEdit', params: { id: item.id } }"
            class="benchmark-manager__groups-item-name"
          >
            {{ item.name }}
          </router-link>
        </ListGridItem>

        <ListGridItem
          col="1"
          :headers="headers"
          :row="index"
          style="display: flex; flex-direction: row"
        >
          <span
            class="benchmark-manager__groups-item-created"
            v-if="item.created_by.organization"
          >
            <VisibleText>Team</VisibleText>: {{ item.created_by.name }}
          </span>
          <span
            class="benchmark-manager__groups-item-created"
            v-if="!item.created_by.organization"
          >
            {{ item.created_by.name }}
          </span>
          <span v-if="item.created_by.name == profile.fullname">
            <SvgIconMeaningful icon="star" title="this is you" />
          </span>
          <span
            class="benchmark-manager__groups-item-created"
            v-if="!item.created_by || !Object.keys(item.created_by).length"
          >
            <VisibleText>Anonymous</VisibleText>
          </span>
        </ListGridItem>

        <ListGridItem col="2" :headers="headers" :row="index">
          {{ item.questions }}
        </ListGridItem>

        <ListGridItem
          class="benchmark-manager__groups-item-approved"
          col="3"
          :headers="headers"
          :row="index"
        >
          {{ item.approved_matches }}
        </ListGridItem>

        <ListGridItem col="4" :headers="headers" :row="index">
          <div v-if="isICCSGroup(item)">
            <VisibleText class="benchmark-manager__groups-item-accesslevel">
              ICCS Group
            </VisibleText>
            <VisibleText
              class="benchmark-manager__groups-item-accesslevel-desc"
            >
              Only ICCS license holders can view this group
            </VisibleText>
          </div>
          <div v-else>
            <VisibleText class="benchmark-manager__groups-item-accesslevel">
              {{ ACCESS_TYPE_TO_DESC[item.access_type].name }}
            </VisibleText>
            <VisibleText
              class="benchmark-manager__groups-item-accesslevel-desc"
            >
              {{ ACCESS_TYPE_TO_DESC[item.access_type].desc }}
            </VisibleText>
          </div>
        </ListGridItem>

        <ListGridItem
          class="benchmark-manager__groups-item-delete"
          style="overflow: unset"
          col="5"
          :headers="headers"
          :row="index"
        >
          <ButtonIconOnly
            icon="deleteIcon"
            :id="`delete-${item.id}`"
            v-if="isOwner(item.owner)"
            @click-handler="deleteGroup(item.id, index)"
          >
            <VisibleText>delete</VisibleText> {{ item.name }}
          </ButtonIconOnly>
        </ListGridItem>
      </template>
    </ListGrid>
  </section>
</template>

<script>
// Componenets
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Consts
import { ACCESS_TYPE_TO_DESC } from "./Utils/consts.js"

// Helpers
//import { mapActions } from "vuex"

import UserMixin from "@/utils/mixins/userMixin"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerGroups",
  components: {
    ButtonIconOnly,
    ListGrid,
    ListGridItem,
    SvgIconMeaningful
  },
  mixins: [UserMixin],
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      ACCESS_TYPE_TO_DESC: ACCESS_TYPE_TO_DESC,
      benchmarkGroups: [],
      headers: [
        "Group name",
        "Owner",
        "Questions",
        "Approved",
        "Access Level",
        ""
      ]
    }
  },
  async created() {
    this.fetchBenchmarkGroups()
  },
  watch: {
    $route() {
      if (this.$route.path && this.$route.path == "/benchmark-manager") {
        this.fetchBenchmarkGroups()
      }
    }
  },
  methods: {
    // ...mapActions("loader", ["setLoading"]),
    async fetchBenchmarkGroups() {
      let isICCS = false
      if (this.isICCS) {
        isICCS = true
      }
      let foundGroups = await this.$pigeonline.projects.get(BenchmarkGroup)
      this.benchmarkGroups = []
      for (let i = 0; i < foundGroups.length; i++) {
        let includeThis = false
        if (!foundGroups[i].access_type) {
          foundGroups[i].access_type = "private"
        }
        if (foundGroups[i].access_type == "public__org") {
          foundGroups[i].access_type = "private"
        }
        if (foundGroups[i].access_type == "public__all") {
          if (
            foundGroups[i].official_group &&
            !foundGroups[i].official_group.includes("iccs")
          ) {
            includeThis = true
          }
          if (
            foundGroups[i].official_group &&
            foundGroups[i].official_group.includes("iccs") &&
            isICCS
          ) {
            includeThis = true
          }
          if (!foundGroups[i].official_group) {
            includeThis = true
          }
        }
        if (this.isOwner(foundGroups[i].owner)) {
          includeThis = true
        }
        if (foundGroups[i].auto_approve) {
          includeThis = true
        }
        if (foundGroups[i].access_type == "public__team") {
          includeThis = true
        }
        if (includeThis == true) {
          this.benchmarkGroups.push(foundGroups[i])
        }
      }

      if (this.benchmarkGroups.length) {
        this.benchmarkGroups.map(async (group) => {
          await this.updateGroupApprovedMatches(group)
        })
      }
    },
    isICCSGroup(group) {
      let status = false
      if (
        group &&
        group.official_group &&
        group.official_group.includes("iccs")
      ) {
        status = true
      }
      return status
    },
    async saveGroup(group) {
      try {
        if (!this.isOwner(group.owner)) return
        await this.$pigeonline.projects.update(group)
      } catch (e) {
        throw new Error("BenchmarkManagerGorups.vue:saveGroup " + e.message)
      }
    },
    async deleteGroup(id, index) {
      if (!confirm("Are you sure you want to delete this group?")) return
      try {
        // delete benchmark gorup
        await this.$pigeonline.projects.delete(id)
        this.benchmarkGroups.splice(index, 1)

        // delete global questions in this benchmark group
        const globalQuestions = await this.DATASETS_SERVICE.globalQuestions(
          null,
          {
            benchmark_group: id
          }
        )
        Promise.all(
          globalQuestions.map(async (question) => {
            this.DATASETS_SERVICE.deleteGlobalQuestion(question._id.$oid)
          })
        )
      } catch (e) {
        throw new Error("BenchmarkManagerGorups.vue:deletegroup " + e.message)
      }
    },
    async updateGroupApprovedMatches(benchmarkGroup) {
      const globalQuestions = await this.DATASETS_SERVICE.globalQuestions(
        null,
        {
          benchmark_group: benchmarkGroup.id
        }
      )
      let totalApproved = globalQuestions.reduce(
        (total, question) => (total = total + question.matches.approved),
        0
      )
      // number of matches has changed
      if (totalApproved != benchmarkGroup.approved_matches) {
        benchmarkGroup.approved_matches = totalApproved
        await this.saveGroup(benchmarkGroup)
      }
    },
    isOwner(groupOwner) {
      let status = false
      if (
        this.profile &&
        this.profile.django_ref_id &&
        this.profile.django_ref_id == groupOwner
      ) {
        status = true
      }
      return status
    }
  }
}
</script>
