<template>
  <div class="profile__content">
    <p class="profile__description sub-title">
      <VisibleText
        >Please provide a name. This helps us better serve you and helps with
        team interaction should you join a team.</VisibleText
      >
    </p>
    <form class="profile__form">
      <label for="fullname" class="profile__form-label">
        <VisibleText>Full Name </VisibleText>*
        <input
          id="fullname"
          v-model="vModels.fullname_new"
          type="text"
          class="profile__form-input"
          required
          aria-required="true"
          autocomplete="off"
        />
      </label>
    </form>
    <div v-if="licenses.length == 1 && licenses[0].name == 'free'">
      {{ licenses }}
      <h1 class="account-type__description sub-title">
        <VisibleText>Welcome to your free ResearchAI account</VisibleText>
      </h1>
      <p style="text-align: left">
        <visible-text>
          Free account users have access to most ResearchAI tools.
        </visible-text>
      </p>
      <h2 style="text-align: left">Free account limitations:</h2>
      <ul style="text-align: left">
        <li>
          <VisibleText>
            cannot use data larger than 150 rows (anything larger will be cut
            off)
          </VisibleText>
        </li>
        <li>
          <VisibleText> cannot create a team, but can join teams </VisibleText>
        </li>
        <li>
          <VisibleText>
            cannot access certain extra services like the CitizenFirst
            benchmarking suite
          </VisibleText>
        </li>
      </ul>
      <button class="profile__form-btn" :disabled="disableSave" @click="save">
        <VisibleText>I agree</VisibleText>
      </button>
    </div>
    <div v-else>
      <button class="profile__form-btn" :disabled="disableSave" @click="save">
        <VisibleText>Save</VisibleText>
      </button>
    </div>
  </div>
</template>

<script>
import UserMixin from "@/utils/mixins/userMixin"

export default {
  name: "accountNextStepsProfile",
  mixins: [UserMixin],
  data() {
    return {
      vModels: {
        fullname_original: "",
        fullname_new: ""
      }
    }
  },
  mounted() {
    this.vModels.fullname_original = this.profile.fullname
    this.vModels.fullname_new = this.vModels.fullname_original
  },
  computed: {
    disableSave: function () {
      let disabled = false
      if (!this.vModels.fullname_new.length) {
        disabled = true
      }
      return disabled
    }
  },
  methods: {
    save: async function () {
      this.setLoading(true)
      try {
        const profile = await this.$pigeonline.profile.save({
          fullname: this.vModels.fullname_new
        })
        this.setProfile(profile)
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        this.setLoading(false)
        return
      }
      this.setLoading(false)
      this.setUserDetails()
      this.$router.push({ name: "accountProfile" })
    }
  }
}
</script>
