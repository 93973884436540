<template>
  <Modal
    class="create-new-analysis"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    :widerFormat="true"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>{{ headings[type] }}</VisibleText>
    </template>
    <VisibleText>{{ captions[type] }}</VisibleText>
    <FilterPills
      v-if="selectedQuestionIds.length"
      :filterParamFromSelectedQuestions="filterParamFromSelectedQuestions"
    >
    </FilterPills>
    <SelectQuestionsList
      :toEmitUpdate="true"
      :questions="dataPreprocessed"
      :selected-ids="selectedQuestionIds"
      @update-selected-question-ids="updateSelectedQuestionIds"
      :smallerUI="true"
    >
      <template v-slot:custom-action="{ question, foundObject }">
        <div
          class="colspan-2"
          style="background-color: rgba(0, 0, 0, 0.02); padding: 0.5em"
        >
          <label
            class="form-label form-label--uppercase"
            style="
              color: var(--base-colour-light);
              font-size: 0.75rem;
              margin-top: 0;
            "
          >
            Select value
          </label>
          <span class="select-wrapper">
            <select
              class="select"
              v-model="foundObject.valuesForComparison"
              style="font-size: 0.8em"
            >
              <!--@change="onChangeSelectValue(question._id.$oid)"-->

              <option value="" :data-parent="$options.name">
                {{ translate("Any value", $options.name) }}
              </option>
              <option
                v-for="(value, index) in valuesOf(foundObject)"
                :key="`${question.id}-value-${index}`"
                :value="[value]"
              >
                {{ value }}
              </option>
            </select>
          </span>
        </div>
      </template>
    </SelectQuestionsList>

    <VisibleText v-if="dataPreprocessed.length == 0" class="info-msg__inline">
      No categorical questions found.
    </VisibleText>

    <template v-slot:footer>
      <button
        class="make-comparisons__confirm-select-btn"
        @click="confirmSelection"
        :disabled="isConfirmSelectDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="make-comparisons__confirm-select-btn-icon"
        />
        <VisibleText>Confirm selection</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SelectQuestionsList from "@/components/Project/ProjectAnalysis/Components/SelectQuestionsList.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

import _ from "lodash"

export default {
  name: "AddFilter",
  components: {
    Modal,
    SvgIconDecorative,
    SelectQuestionsList,
    FilterPills
  },
  props: {
    type: {
      type: String,
      default: "own"
    },
    data: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      dataPreprocessed: [],
      isConfirmSelectDisabled: true,
      headings: {
        own: "Make your own filter",
        suggested: "Get suggested filter"
      },
      captions: {
        own: "You can filter your analysis by any question or any values within a question.",
        suggested:
          "Using segmentation analysis we have suggested a filter for you. This is a segment of data that may be worth special attention."
      },
      shouldDisplayValues: {
        own: true,
        suggested: false
      },
      selectedQuestionIds: [],
      updateKey: Date.now(),
      resetListener: false
    }
  },
  mounted() {
    this.preprocessData()
  },
  methods: {
    confirmSelection() {
      // const filteredQuestions = this.dataPreprocessed.filter((q) =>
      //   this.selectedQuestionIds.includes(q._id.$oid)
      // )
      // this.$emit(
      //   "confirmSelection",
      //   filteredQuestions.map((el) => [
      //     el._id.$oid,
      //     el.question_title,
      //     el.valuesForComparison
      //   ])
      // )
      this.$emit("confirmNewFilter", this.filterParamFromSelectedQuestions)
      this.$emit("close-modal")
      this.selectedQuestionIds = []
    },
    isCategory(question) {
      return (
        question.approved_data_type &&
        question.approved_data_type.match(/(CAT)+/)
      )
    },
    updateSelectedQuestionIds(questionIds) {
      this.isConfirmSelectDisabled = questionIds.length === 0
      this.selectedQuestionIds = questionIds
    },
    valuesOf(question) {
      return question.unique_values
        .filter((el) => el.is_valid)
        .map((el) => el.value)
    },
    preprocessData() {
      this.dataPreprocessed = _.cloneDeep(this.data)
        // .filter(q => this.isCategory(q))
        .map(
          function (el) {
            el.valuesForComparison = this.valuesOf(el)
            return el
          }.bind(this)
        )
    },
    limitStringLength(string) {
      if (_.isString(string)) {
        string = string.substring(0, 20)
        if (string.length >= 20) {
          string = string + "..."
        }
      }
      return string
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  },
  computed: {
    filterParamFromSelectedQuestions() {
      let newParam = {
        global_combine_action: "and",
        operands: []
      }
      let exampleOperand = {
        operator: "equal",
        client_question_id: "",
        values: []
      }
      if (this.selectedQuestionIds.length) {
        for (let x = 0; x < this.selectedQuestionIds.length; x++) {
          let newQuestion = {}
          for (let i = 0; i < this.dataPreprocessed.length; i++) {
            if (this.dataPreprocessed[i]._id) {
              if (this.dataPreprocessed[i]._id.$oid) {
                if (
                  this.dataPreprocessed[i]._id.$oid ===
                  this.selectedQuestionIds[x]
                ) {
                  newQuestion = this.deepCloneObj(exampleOperand)
                  newQuestion.client_question_id = this.selectedQuestionIds[x]
                  if (
                    this.dataPreprocessed[i].valuesForComparison.length !==
                    this.dataPreprocessed[i].unique_values.length
                  ) {
                    for (
                      let a = 0;
                      a < this.dataPreprocessed[i].valuesForComparison.length;
                      a++
                    ) {
                      newQuestion.values.push(
                        this.dataPreprocessed[i].valuesForComparison[a]
                      )
                    }
                  }
                  // else {
                  //
                  // }
                  newParam.operands.push(newQuestion)
                }
              }
            }
          }
        }
      }
      return newParam
    }
  },
  watch: {
    show: function (val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.preprocessData()
      // if (this.type == "suggested") {
      //   this.confirmSelection(
      //     this.dataPreprocessed.filter(el => this.isCategory(el))
      //   )
      // }
    }
  }
}
</script>
