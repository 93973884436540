<template>
  <UITappable
    ref="tappable"
    :options="isBannedView ? bannedKeywordsOptions : options"
    :hasFetchedLemmas="hasFetchedLemmas"
    :childRect="childRect"
    @ban="ban"
    @similar="similar"
    style="font-size: 0.8rem"
    :style="
      themeColor ? 'background-color:' + themeColor + '; color: white;' : ''
    "
  >
    <span>{{ value }}</span>
    <slot :ban="ban" :similar="similar" :remove="remove" />
  </UITappable>
</template>

<script>
import UITappable from "../UI/Tappable.vue"
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"

export default {
  name: "KeywordsInputKeywordTappable",
  mixins: [textAnalysisMixin],
  components: {
    UITappable
  },
  props: {
    value: {
      type: String,
      required: true
    },

    alsoFoundRef: {
      type: String,
      required: true
    },
    textAnalysisObject: {
      type: Object,
      default: () => {}
    },
    isBannedView: {
      type: Boolean,
      default: () => false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    themeColor: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      childRect: {},
      options: {
        ban: {
          text: "ban"
        },
        similar: {
          text: "similar"
        }
      },
      bannedKeywordsOptions: {
        similar: {
          text: "similar"
        }
      }
    }
  },
  computed: {
    tappableEl() {
      return this.$refs["tappable"]
    },
    alsoFoundEl() {
      return this.$parent.$refs[this.alsoFoundRef]
    }
  },
  created() {
    let thisRect = {}
    if (this.$el) {
      thisRect = this.$el.getBoundingClientRect()
    }
    this.childRect = thisRect
  },
  methods: {
    ban(keyword) {
      this.tappableEl.setSelected(false)
      this.$emit("banKeyword", keyword)
      //this.$refs["tappable"].toggleToolsView()
    },
    async similar(keyword) {
      // if (this.hasFetchedLemmas) {
      this.$emit("showLoading", true)
      const alsoFound = await this.fetchWordAssociations(
        keyword,
        this.textAnalysisObject.datasetId,
        this.selectedTextQuestionId
      )
      if (alsoFound) {
        this.alsoFoundEl.setKeyword(alsoFound)
        this.alsoFoundEl.setKeywordRef(this.tappableEl)
        this.alsoFoundEl.setVisible(true)
      }
      this.$emit("showLoading", false)
      // }
    },

    remove(index) {
      this.$parent.removeTag(index)

      // blur focused element
      document.activeElement.blur()
    },

    async fetchWordAssociations(searchString, datasetId, questionId) {
      if (!searchString) return []
      try {
        const response = await this.TEXT_SERVICE.wordAssociations({
          user_string: searchString,
          data_set_id: datasetId,
          client_question_id: questionId
        })
        return response
      } catch (e) {
        throw new Error("AlsoFound.vue:fetchWordAssociations " + e.message)
      }
    }
  }
}
</script>
