<template>
  <Modal
    class="create-new-analysis"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    :widerFormat="true"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>{{ headings[type] }}</VisibleText>
    </template>
    <VisibleText>{{ captions[type] }}</VisibleText>
    <FilterPills
      v-if="selectedQuestionId"
      :filterParamFromSelectedQuestions="filterParamFromSelectedQuestions"
      :altTitle="'Selected segment'"
    >
    </FilterPills>
    <SelectQuestionsList
      :toEmitUpdate="true"
      :questions="dataPreprocessed"
      :selected-id="selectedQuestionId"
      @update-selected-question-id="updateSelectedQuestionId"
      :smallerUI="true"
      :isRadio="true"
    >
    </SelectQuestionsList>

    <VisibleText v-if="dataPreprocessed.length == 0" class="info-msg__inline">
      No categorical questions found.
    </VisibleText>

    <template v-slot:footer>
      <button
        class="make-comparisons__confirm-select-btn"
        @click="confirmSelection"
        :disabled="isConfirmSelectDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="make-comparisons__confirm-select-btn-icon"
        />
        <VisibleText>Confirm selection</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SelectQuestionsList from "@/components/Project/ProjectAnalysis/Components/SelectQuestionsList.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

import _ from "lodash"

export default {
  name: "AddSegment",
  components: {
    Modal,
    SvgIconDecorative,
    SelectQuestionsList,
    FilterPills
  },
  props: {
    type: {
      type: String,
      default: "own"
    },
    data: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      dataPreprocessed: [],
      isConfirmSelectDisabled: true,
      headings: {
        own: "Add a segment to your chart",
        suggested: "Get suggested segment"
      },
      captions: {
        own: "This will allow you to break down each question by a specific segment.",
        suggested:
          "This will allow you to break down each question by a specific segment."
      },
      shouldDisplayValues: {
        own: true,
        suggested: false
      },
      selectedQuestionId: "",
      updateKey: Date.now(),
      resetListener: false
    }
  },
  mounted() {
    this.preprocessData()
  },
  methods: {
    confirmSelection() {
      this.$emit(
        "confirmNewSegment",
        this.selectedQuestionId,
        this.filterParamFromSelectedQuestions
      )
      this.$emit("close-modal")
      this.selectedQuestionId = ""
    },
    isCategory(question) {
      return (
        question.approved_data_type &&
        question.approved_data_type.match(/(CAT)+/)
      )
    },
    updateSelectedQuestionId(questionId) {
      this.isConfirmSelectDisabled = questionId === ""
      this.selectedQuestionId = questionId
    },
    valuesOf(question) {
      return question.unique_values
        .filter((el) => el.is_valid)
        .map((el) => el.value)
    },
    preprocessData() {
      this.dataPreprocessed = _.cloneDeep(this.data)
        // .filter(q => this.isCategory(q))
        .map(
          function (el) {
            el.valuesForComparison = this.valuesOf(el)
            return el
          }.bind(this)
        )
    },
    limitStringLength(string) {
      if (_.isString(string)) {
        string = string.substring(0, 20)
        if (string.length >= 20) {
          string = string + "..."
        }
      }
      return string
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  },
  computed: {
    filterParamFromSelectedQuestions() {
      let newParam = {
        global_combine_action: "and",
        operands: []
      }
      let exampleOperand = {
        operator: "equal",
        client_question_id: "",
        values: []
      }
      if (this.selectedQuestionId) {
        let newQuestion = {}
        for (let i = 0; i < this.dataPreprocessed.length; i++) {
          if (this.dataPreprocessed[i]._id) {
            if (this.dataPreprocessed[i]._id.$oid) {
              if (
                this.dataPreprocessed[i]._id.$oid === this.selectedQuestionId
              ) {
                newQuestion = this.deepCloneObj(exampleOperand)
                newQuestion.client_question_id = this.selectedQuestionId
                if (
                  this.dataPreprocessed[i].valuesForComparison.length !==
                  this.dataPreprocessed[i].unique_values.length
                ) {
                  for (
                    let a = 0;
                    a < this.dataPreprocessed[i].valuesForComparison.length;
                    a++
                  ) {
                    newQuestion.values.push(
                      this.dataPreprocessed[i].valuesForComparison[a]
                    )
                  }
                }
                // else {
                //
                // }
                newParam.operands.push(newQuestion)
              }
            }
          }
        }
      }
      return newParam
    }
  },
  watch: {
    show: function (val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.preprocessData()
      // if (this.type == "suggested") {
      //   this.confirmSelection(
      //     this.dataPreprocessed.filter(el => this.isCategory(el))
      //   )
      // }
    }
  }
}
</script>
