import { HELPER_TRANSLATIONS } from "../../../translations"
import { driversChart, driversHeader } from "./driversNodes"
import { priorityMatrixChart } from "./priorityMatrixNodes"

const prioritiesOfServiceImprovementHeader = (lang, uuid) => {
  const driversAnalysisIconSrc = "/files/ReportIcons/driversAnalysis.svg"
  let driversAnalysisHeading = `<img src="${driversAnalysisIconSrc}" class="report__header-icon" alt="" />\
${HELPER_TRANSLATIONS.driversHeader[lang]}`
  const heading = {
    type: "heading",
    content: driversAnalysisHeading,
    id: "section-title-drivers",
    uuid: uuid,
    meta: {
      headingLevel: "h2",
      pdfConfig: {
        pageBreak: "before",
        style: "title"
      }
    }
  }

  return heading
}

const driversSection = (data, lang = "en") => {
  const header = driversHeader(data.driversTitle, lang)
  // const description = driversDescription(data.driversChartData, lang)
  const chart = driversChart(
    data.driversTitle,
    data.driversChartData,
    true,
    lang
  )

  return {
    type: "section",
    id: "drivers-section",
    content: [header, chart],
    meta: {
      driversUUID: data.driversUUID,
      subTitle: "REGRESSION ANALYSIS"
    }
  }
}

// does this have no uuid?
const matrixSection = (data, lang = "en") => {
  // const description = priorityMatrixDescription(data.matrixChartData)
  const chart = priorityMatrixChart(
    data.matrixTitle,
    data.matrixChartData,
    true,
    lang
  )

  return {
    type: "section",
    id: "matrix-section",
    content: [chart],
    meta: {
      subTitle: "MATRIX"
    }
  }
}

export { prioritiesOfServiceImprovementHeader, driversSection, matrixSection }
