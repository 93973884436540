<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText
        >US Executive Order on Transforming Federal Customer Experience and
        Service Delivery</VisibleText
      >
    </h1>
    <div class="library__heading-line"></div>
    <img
      class="library__image"
      style="width: 30%; height: auto"
      src="/assets/landing/whitehouse-executive-order.jpeg"
      alt=""
    />
    <h2 class="library__h2">
      <VisibleText
        >What is it and why does it matter to me as a service
        professional?</VisibleText
      >
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        The US Whitehouse has announced an
        <a
          href="https://www.whitehouse.gov/briefing-room/presidential-actions/2021/12/13/executive-order-on-transforming-federal-customer-experience-and-service-delivery-to-rebuild-trust-in-government/"
        >
          Executive Order to transform federal customer service experience and
          service delivery to rebuild trust in government.</a
        >
        &nbsp;This comes with a series of directives for agencies that are built
        from well-established and exercised practices internationally. But most
        successful applications of these practices come from organic and
        sporadic success stories. No one country has stood apart as having
        succeeded in coordinating these efforts nationally. This is an ambitious
        policy, but very doable.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Given our long history working with leaders in public sector service
        improvement, digitization, and human-centric design, we wanted to
        provide some guidance for agencies in getting up to speed.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText> What are the policy’s goals? </VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Taking from best-practices, the Government aims to have accountable
        service design. This should be done through evidence-based
        decision-making, a tracking of the unfair ‘time-taxes’ that governments
        have put citizens through. And this should be done fairly for all
        regardless of background, which means understanding impacts on different
        groups. This aims to drive customer experience, at all life stages, with
        equity and human-centricty in its design.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        That’s great, but what does it mean for my agency? The policy calls for
        the following government-wide actions:
      </VisibleText>
    </p>
    <ol class="library__list">
      <div style="display: flex; flex-direction: row">
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/landing/research_icon.png"
          style="width: 4em; height: 4em; margin-right: 2em"
          alt=""
        />
        <li style="display: flex; flex-direction: column">
          <h3>
            <VisibleText>
              1. Select core customer experiences to focus on.
            </VisibleText>
          </h3>
          <p>
            <VisibleText>
              If you are just getting started, consider high-volume
              transactional services where the ‘time-tax’ is the heaviest.
            </VisibleText>
          </p>
        </li>
      </div>
      <div style="display: flex; flex-direction: row">
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/article__toolbox.png"
          style="width: 4em; height: 3.8em; margin-right: 2em"
          alt=""
        />
        <li style="display: flex; flex-direction: column">
          <h3>
            <VisibleText>
              2. Develop empirical, measureable improvements.
            </VisibleText>
          </h3>
          <p>
            <VisibleText>
              If you are just getting started, do not re-invent the wheel. The
              easiest beginning for many agencies is to design relatively simple
              surveys that include common measures of service experience, and
              ask about channels used, and key demographics. Consider the
              Institute for Citizen-Centred Services which publishes a list of
              common critical services questions that have been used by
              governments around the world.
              <a
                href="https://citizenfirst.ca/measure-and-benchmark"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more here.
              </a>
            </VisibleText>
          </p>
          <p>
            <VisibleText>
              More advanced organizations should begin thinking about new types
              of data they already have available to them. For example, we have
              taken publicly-available Google Reviews and pasted them into an
              excel sheet, then uploaded it to ResearchAI to find key themes and
              sentiments from one region’s hospitals and compared them to
              another.
            </VisibleText>
          </p>
        </li>
      </div>

      <div style="display: flex; flex-direction: row">
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/article__transform.png"
          style="width: 4em; height: 4em; margin-right: 2em"
          alt=""
        />
        <li style="display: flex; flex-direction: column">
          <h3>
            <VisibleText> 3. Develop reporting. </VisibleText>
          </h3>
          <p>
            With data like the above available, the next step is to find
            important insights. The directive calls for careful analysis. This
            can be hard for most organizations, who may not have a team that can
            conduct statistical analysis, regression, segmentation (to track
            differences across specific groups), or text analysis. This is where
            tools like ResearchAI come in handy.
          </p>
          <p>
            <VisibleText>
              You can take data from any format and put it in simple excel
              sheets, or export directly from survey data tools. This can then
              be imported into the tool and cleaned with the help of
              data-cleaning suggestions. Get lots of your unstructured data into
              a system and ready to share. Once in the system, running
              ‘analysis’ will unlock
              <a
                href="https://research-ai.io/library/guide/drivers-analysis-in-researchai"
                target="_blank"
                rel="noopener noreferrer"
              >
                drivers analysis
              </a>
              &nbsp;so you can see what predicts improvements in outcomes like
              satisfaction. It will also unlock
              <a
                href="https://research-ai.io/library/guide/outliers-analysis-in-researchai"
                target="_blank"
                rel="noopener noreferrer"
              >
                outliers analysis
              </a>
              &nbsp;which will label highly positive or negative segments
              compared to their peers. This is important for tracing equity
              issues. You can connect your dataset to available
              <a
                href="https://research-ai.io/library/guide/what-is-benchmarking-in-researchai"
                target="_blank"
                rel="noopener noreferrer"
              >
                benchmarking groups,
              </a>
              &nbsp;or build your own within your agency to allow for detailed
              comparison reports. Lastly, if you have large amounts of text data
              from your customer, you can unlock sentiments and ‘what they are
              saying’ based on industry leading
              <a
                href="https://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
                target="_blank"
                rel="noopener noreferrer"
              >
                text analysis
              </a>
              &nbsp;without any coding required. Give this all a try for free.
            </VisibleText>
          </p>
        </li>
      </div>
      <div style="display: flex; flex-direction: row">
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/landing/leaders_icon.png"
          style="width: 4em; height: 4em; margin-right: 2em"
          alt=""
        />
        <li style="display: flex; flex-direction: column">
          <h3>
            <VisibleText> 4. Develop capabilities. </VisibleText>
          </h3>
          <p>
            After having collected important data, and begun automating the
            finding of industry-leading questions, an agency can then begin
            expanding this capability across its workforce. Select another level
            of services to collect data on, or increase how often you will track
            this data. Get other departments to import data into a tool like
            ResearchAI so everyone can begin running analysis together.
          </p>
        </li>
      </div>
      <div style="display: flex; flex-direction: row">
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/landing/collect-data_icon.png"
          style="width: 4em; height: 4em; margin-right: 2em"
          alt=""
        />
        <li style="display: flex; flex-direction: column">
          <h3>
            <VisibleText> 5. Integrate with common standards. </VisibleText>
          </h3>
          <p>
            <VisibleText>
              Again, it’s advisable to not re-invent the wheel. Consider
              aligning your questions and the story you are sharing to your
              leadership with the stories that work well across the
              service-quality landscape. Consider building upon the
              <a
                href="https://citizenfirst.ca/measure-and-benchmark/about-the-common-measurements-tool"
                target="_blank"
                rel="noopener noreferrer"
              >
                Citizen-First model,</a
              >
              be sure to identify key segments and track outliers, and track
              pain points across the entire range of the customer life-cycle.
            </VisibleText>
          </p>
        </li>
      </div>
    </ol>
    <p>
      <VisibleText>
        The policy also calls for the following leadership actions, enabled by
        the above:
      </VisibleText>
    </p>
    <ol class="library__list">
      <li>
        <h3>
          <VisibleText>
            Maintain a list of High Impact Service Providers.
          </VisibleText>
        </h3>
        <p>
          While your agencies are selecting which core services to assess,
          leadership should pull these together to assign the most important
          service providers that will be tracked more carefully.
        </p>
      </li>
      <li>
        <h3>
          <VisibleText>
            Assessment of improvement needed and identify areas of the highest
            volume/need.
          </VisibleText>
        </h3>
        <p>
          If your agencies are now reporting to you key drivers of improvement
          and key segments that are over or under-performing, you can begin to
          benchmark these results to identify the performance of those services
          that matter the most, and identify specific actions and track whether
          they have had an impact. For example, are key drivers changing after
          investment of effort? Are under-performing segments no longer outliers
          compared to their peers?
        </p>
      </li>
      <li>
        <h3>
          <VisibleText> Adopt advanced research methodologies. </VisibleText>
        </h3>
        <p>
          As your agencies begin to align measurement and practices, you can
          begin to select early leaders or thought champions to conduct more
          thorough innovations around research, including new tools like Natural
          Language Processing to help find sentiments and the voice of the
          customer, or predictive analytics and outliers to find equity issues
          and under-performing segments. Consider conducting experiments,
          comparing different predictions against each other. And consider
          ethnographic investigations, where you collect richer observations and
          text data and as you can now synthesize those findings with greater
          ease than before.
        </p>
      </li>
      <li>
        <h3>
          <VisibleText> Integrate services. </VisibleText>
        </h3>
        <p>
          Lastly, this can be a shared journey. Bring diverse datasets from
          excel to big data systems together into easy-to-access platforms that
          do not require engineering, such as the privacy-focused ResearchAI or
          open-data platforms. Then celebrate your successes by sharing your
          journey, your findings, and build a culture of integration and
          measurement that crosses departments.
        </p>
      </li>
    </ol>
    <p>
      <VisibleText>
        While there is no one perfect tool to help organizations achieve this,
        we are excited that we may be able to help some agencies due to our
        vision of making industry-leading customer experience tools available to
        every-day managers, with no coding or statistical engineering required.
        If you are an organization with customer experience data from surveys,
        but do not know what you can do with it in terms of reporting, consider
        using a free version of ResearchAI to upload your data, run prediction
        on a question like overall satisfaction, find interesting outliers that
        may be hiding in your data, participate in benchmarking groups that are
        beginning to grow, and more.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        If you have rich text data available to you, you can now capture and
        track the voice of the customer, including their key satisfaction
        drivers, sentiments, and important issues around equity.
        <a
          href="https://research-ai.io/government"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about how we are helping governments around the world.</a
        >
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        If you have no data at all, and would like to understand how government
        agencies can get started with little cost and with high return, reach
        out to us and we’d love to chat. hello@researchai.io
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText> Related reading </VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <div
      class="figure__horizontal"
      style="padding-top: 2em; padding-bottom: 2em"
    >
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Customer_experience">
          <VisibleText>Customer experience</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Employee_engagement">
          <VisibleText>Employee engagement and Digital HRM</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Program_evaluation">
          <VisibleText>Program evaluation</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Brand_strength_analysis">
          <VisibleText>Brand/Product testing</VisibleText>
        </a>
      </div>
    </div>
    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new.
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
              We will be in touch to set you up for success.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "USExecutiveOrder",
  setup() {
    const siteData = reactive({
      title: "US customer service experience Executive Order",
      description:
        "What is the customer service experience executive order and why does it matter to me?"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  }
}
</script>
