<template>
  <article id="project-analysis-tools" style="position: relative">
    <h2 class="sr-only">
      <VisibleText>Tools</VisibleText>
    </h2>
    <MessageBlock
      message-type="information"
      title="Please select a location"
      v-if="placementMode"
    >
      <div>
        <VisibleText
          >Please select a location in the Table of Contents (to the
          left).</VisibleText
        >
        <button
          class="btn-inverted"
          style="margin-left: 2em; font-size: 0.8em"
          @click="cancelPlacement"
        >
          <SvgIconMeaningful icon="remove" title="read only" />
          <VisibleText>Cancel placement</VisibleText>
        </button>
      </div>
    </MessageBlock>
    <div v-if="!placementMode">
      <p>
        <VisibleText>Click a tab above to begin your analysis. </VisibleText>
      </p>
      <div
        class="project-analysis__header"
        style="justify-content: space-between"
      >
        <h2 class="project-analysis__header-title">
          <VisibleText>Report tools</VisibleText>
        </h2>
      </div>
      <p class="benchmarking__desc">
        <VisibleText
          >Optionally you can customize your report with the below.
        </VisibleText>
      </p>

      <div style="display: flex; flex-direction: column; width: 40em">
        <div style="display: flex; flex-direction: row">
          <div style="width: 20em">
            <button
              class="btn-inverted"
              style="
                display: flex;
                margin: 1em;
                min-height: 1em;
                font-size: 0.8em;
                margin-left: auto;
                margin-right: 2em;
              "
              @click="openBannerModal"
              :disabled="propReadOnly"
            >
              <VisibleText>Banner</VisibleText>
            </button>
          </div>
          <img
            class="library__headingIcon__image library__headingIcon"
            style="width: 15em"
            :src="banner"
            alt=""
          />
        </div>
        <div style="display: flex; flex-direction: row; width: 40em">
          <div style="width: 20em">
            <button
              class="btn-inverted"
              style="
                display: flex;
                margin: 1em;
                min-height: 1em;
                font-size: 0.8em;
                margin-left: auto;
                margin-right: 2em;
              "
              @click="openTitleModal"
              :disabled="propReadOnly"
            >
              <VisibleText>Title page</VisibleText>
            </button>
          </div>

          <div style="display: flex; width: 15em; text-align: center">
            <h3>
              <VisibleText>Title example</VisibleText>
            </h3>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; width: 40em">
          <div style="width: 20em">
            <button
              class="btn-inverted"
              style="
                display: flex;
                margin: 1em;
                min-height: 1em;
                font-size: 0.8em;
                margin-left: auto;
                margin-right: 2em;
              "
              :disabled="propReadOnly"
              @click="openHeaderModal"
            >
              <VisibleText>Header</VisibleText>
            </button>
          </div>

          <div style="display: flex; width: 15em">
            <h3>
              <VisibleText>Header example</VisibleText>
            </h3>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; width: 40em">
          <div style="width: 20em">
            <button
              class="btn-inverted"
              style="
                display: flex;
                margin: 1em;
                min-height: 1em;
                font-size: 0.8em;
                margin-left: auto;
                margin-right: 2em;
              "
              :disabled="propReadOnly"
              @click="openTextModal"
            >
              <VisibleText>Text box / paragraph</VisibleText>
            </button>
          </div>
          <div style="display: flex">
            <p style="font-size: 0.8em">
              <VisibleText>Paragraph example</VisibleText>
            </p>
          </div>
        </div>
      </div>
    </div>

    <NewTitlePageToolModal
      ref="NewTitlePageToolModalRef"
      :slides="slides"
      :isNewTitlePageToolModalVisible="isNewTitlePageToolModalVisible"
      @add-new-title-page="addTitlePageNode"
      @close-title-page-tool="isNewTitlePageToolModalVisible = false"
    />
    <NewBannerToolModal
      ref="NewBannerToolModalRef"
      :slides="slides"
      :isNewBannerToolModalVisible="isNewBannerToolModalVisible"
      @add-new-banner="addBannerNode"
      @close-title-page-tool="isNewBannerToolModalVisible = false"
    />
    <NewTextToolModal
      ref="NewTextToolModalRef"
      :slides="slides"
      :isNewTextToolModalVisible="isNewTextToolModalVisible"
      @add-new-text="addTextNode"
      @close-text-tool="isNewTextToolModalVisible = false"
    />
    <NewHeaderToolModal
      ref="NewHeaderToolModalRef"
      :slides="slides"
      :isNewHeaderToolModalVisible="isNewHeaderToolModalVisible"
      @add-new-header="addHeaderNode"
      @close-header-tool="isNewHeaderToolModalVisible = false"
    />
  </article>
</template>

<script>
// components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import NewTextToolModal from "./Components/NewTextToolModal.vue"
import NewHeaderToolModal from "./Components/NewHeaderToolModal.vue"
import NewTitlePageToolModal from "./Components/NewTitlePageToolModal.vue"
import NewBannerToolModal from "./Components/NewBannerToolModal.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import UserMixin from "@/utils/mixins/userMixin.js"
import toolsAnalysisMixin from "./Mixins/toolsAnalysisMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"

// Utils
import moment from "moment"
import { PigeonDocModel, PigeonDocCollectionModel } from "@pigeonline/pigeondoc"

export default {
  name: "ProjectAnalysisTools",
  components: {
    SvgIconMeaningful,
    NewTextToolModal,
    NewHeaderToolModal,
    NewTitlePageToolModal,
    NewBannerToolModal,
    MessageBlock
  },
  mixins: [UserMixin, toolsAnalysisMixin, ProjectMixin, ProjectReportMixin],
  props: {
    indexForPlacing: {
      default: () => -1,
      type: Number
    },
    slideIndexForPlacing: {
      default: () => -1,
      type: Number
    }
  },
  data() {
    return {
      slides: [],
      isNewTextToolModalVisible: false,
      isNewHeaderToolModalVisible: false,
      isNewTitlePageToolModalVisible: false,
      isNewBannerToolModalVisible: false,
      placementMode: "",
      placementContent: null
    }
  },
  created() {
    this.checkForSlides()
  },
  methods: {
    openBannerModal() {
      this.checkForSlides()
      this.isNewBannerToolModalVisible = true
    },
    openTitleModal() {
      this.checkForSlides()
      this.isNewTitlePageToolModalVisible = true
    },
    openHeaderModal() {
      this.checkForSlides()
      this.isNewHeaderToolModalVisible = true
    },
    openTextModal() {
      this.checkForSlides()
      this.isNewTextToolModalVisible = true
    },
    async addBannerNode(selectedSlide, newSlideName) {
      let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
      emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
      emptyToolsAnalysis.last_modified_on = moment().valueOf()
      let parameters = {
        selectedSlide: selectedSlide,
        slideName: newSlideName,
        emptyToolsAnalysis: emptyToolsAnalysis,
        content: "",
        indexForPlacement: 0,
        type: "banner"
      }
      let savedToUUID = await this.saveToolToReport(parameters)
      if (savedToUUID) {
        await this.$emit("refresh")
        await this.$emit("updateSlides")
        this.checkForSlides()
      }
    },
    async addTitlePageNode(
      content,
      subContent,
      date,
      selectedSlide,
      newSlideName
    ) {
      let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
      emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
      emptyToolsAnalysis.last_modified_on = moment().valueOf()
      let parameters = {
        selectedSlide: selectedSlide,
        slideName: newSlideName,
        emptyToolsAnalysis: emptyToolsAnalysis,
        content: content,
        subContent: subContent,
        date: date,
        indexForPlacement: 0,
        type: "titlePage"
      }
      let savedToUUID = await this.saveToolToReport(parameters)
      if (savedToUUID) {
        await this.$emit("refresh")
        await this.$emit("updateSlides")
        this.checkForSlides()
      }
    },
    async addTextNode(content, addToBottom, selectedSlide, newSlideName) {
      let placeOnBottom = true
      if (!addToBottom) {
        placeOnBottom = false
      }
      if (!placeOnBottom) {
        this.$emit("set-placement-mode", selectedSlide)
        this.placementMode = "text"
        this.placementContent = content
      } else {
        let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
        emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
        emptyToolsAnalysis.last_modified_on = moment().valueOf()
        let parameters = {
          selectedSlide: selectedSlide,
          slideName: newSlideName,
          emptyToolsAnalysis: emptyToolsAnalysis,
          content: content,
          indexForPlacement: -1,
          type: "text"
        }
        let savedToUUID = await this.saveToolToReport(parameters)
        if (savedToUUID) {
          await this.$emit("refresh")
          await this.$emit("updateSlides")
          this.checkForSlides()
        }
      }
    },
    async addHeaderNode(content, addToBottom, selectedSlide, newSlideName) {
      if (addToBottom == "manual") {
        this.$emit("set-placement-mode", selectedSlide)
        this.placementMode = "header"
        this.placementContent = content
      } else if (addToBottom == "bottom") {
        let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
        emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
        emptyToolsAnalysis.last_modified_on = moment().valueOf()
        let parameters = {
          selectedSlide: selectedSlide,
          slideName: newSlideName,
          emptyToolsAnalysis: emptyToolsAnalysis,
          content: content,
          indexForPlacement: -1,
          type: "header"
        }
        let savedToUUID = await this.saveToolToReport(parameters)
        if (savedToUUID) {
          await this.$emit("refresh")
          await this.$emit("updateSlides")
          this.checkForSlides()
        }
      }
      // let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
      // emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
      // emptyToolsAnalysis.last_modified_on = moment().valueOf()
      // let savedToUUID = await this.saveToolToReport(this.slides[0], "", emptyToolsAnalysis, content)
      // if (savedToUUID) {
      //   this.$emit("refresh")
      // }
      //}
    },
    // Function used to save tools to the report
    // Sample parameter:
    //
    // "selectedSlide": this.slides[0],
    // "slideName": "",
    // "emptyToolsAnalysis": emptyToolsAnalysis,
    // "content": content,
    // "indexForPlacement": index,
    // "type": "text"

    async saveToolToReport(parameters) {
      if (this.propReadOnly) return
      let createdReportID = await this.createReportIfEmpty(
        parameters.selectedSlide,
        parameters.slideName
      ) // the above function will do nothing under conditions of emptiness
      // this tool needs a slide creator like the other approach
      if (parameters.selectedSlide) {
        createdReportID = parameters.selectedSlide.id
      }
      let returnObject = {}
      let content = parameters.content
      // Used for title page
      if (parameters.type === "titlePage") {
        let date = null
        if (parameters.date) {
          date = Date.now()
        }
        content = {
          heading: parameters.content,
          subheading: parameters.subContent,
          date: date
        }
      }
      returnObject = await this.updateReportWithTool(
        parameters.emptyToolsAnalysis.uuid,
        createdReportID,
        content,
        parameters.indexForPlacement,
        parameters.type
      )
      this.isLoadingData = false
      return returnObject.uuid
    },
    async checkForSlides() {
      this.slides = []
      let collection
      if (this.project.collection_id) {
        collection = await this.$pigeonline.projects.getByID(
          PigeonDocCollectionModel,
          this.project.collection_id
        )
        if (collection) {
          if (collection.docIds) {
            let project
            for (let i = 0; i < collection.docIds.length; i++) {
              project = await this.$pigeonline.projects.getByID(
                PigeonDocModel,
                collection.docIds[i]
              )
              if (project) {
                this.slides.push(project)
              }
            }
          }
        }
      }
      this.$emit("updateCollection")
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    cancelPlacement() {
      ;(this.placementMode = ""), (this.placementContent = null)
      this.$emit("deactivatePlacementMode")
    }
  },
  computed: {
    banner() {
      if (this.$theme.theme.name == "iccs") {
        return "/assets/reportHeader__ICCS.png"
      }
      return "/assets/reportHeader__default.png"
    }
  },
  watch: {
    indexForPlacing: async function (val) {
      if (val >= 0) {
        if (
          this.placementContent &&
          this.indexForPlacing > -1 &&
          this.slideIndexForPlacing > -1
        ) {
          let emptyToolsAnalysis = await this.fetchEmptyToolsAnalysis()
          emptyToolsAnalysis.uuid = await this.$pigeonline.createUUID()
          emptyToolsAnalysis.last_modified_on = moment().valueOf()

          let parameters = {
            selectedSlide: this.slides[this.slideIndexForPlacing],
            slideName: "",
            emptyToolsAnalysis: emptyToolsAnalysis,
            content: this.placementContent,
            indexForPlacement: val,
            type: this.placementMode
          }

          let savedToUUID = await this.saveToolToReport(parameters)
          if (savedToUUID) {
            this.placementMode = ""
            this.placementContent = null
            this.$emit("refresh")
            this.$emit("deactivatePlacementMode")
          }
        }
      }
    }
  }
}
</script>
