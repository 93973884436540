<template>
  <section
    class="dataset-summary"
    v-if="dataset"
    :inert="modalOpen"
    :aria-hidden="modalOpen"
  >
    <Accordion
      class="dataset-summary__accordion"
      title-element-type="h2"
      icon-location="right"
    >
      <template #heading>
        {{ dataset.name }}
      </template>
      <section>
        <h3 class="dataset-summary__details-section-title">
          <SvgIconDecorative icon="information" />
          <VisibleText>Dataset Details</VisibleText>
        </h3>
        <div class="dataset-summary__details">
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Owner
            </VisibleText>
            <span> {{ owner }} </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Survey Start Date
            </VisibleText>
            <span>
              {{
                dataset.survey_details
                  ? dataset.survey_details.surveyEndDate
                  : "n/a"
              }}
            </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Survey End Date
            </VisibleText>
            <span>
              {{
                dataset.survey_details
                  ? dataset.survey_details.surveyStartDate
                  : "n/a"
              }}
            </span>
          </div>
          <div class="dataset-summary__details-wrapper">
            <VisibleText class="dataset-summary__details-title">
              Response Rate
            </VisibleText>
            <span>
              {{
                dataset.survey_details && dataset.survey_details.rate
                  ? dataset.survey_details.rate
                  : "n/a"
              }}
            </span>
          </div>
        </div>
      </section>
      <section
        class="dataset-summary__tags"
        v-if="Object.keys(dataset.segments).length > 0"
      >
        <h3 class="dataset-summary__details-section-title">
          <SvgIconDecorative icon="tag" />
          <VisibleText>Tags</VisibleText>
        </h3>
        <DatasetSegmentsList :segments="dataset.segments" />
      </section>
    </Accordion>
  </section>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import DatasetSegmentsList from "@/components/Dataset/DatasetSegments/DatasetSegmentsList.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"
import TeamsService from "@/services/teamsService.js"

export default {
  name: "DatasetSummary",
  mixins: [DatasetDetailsMixin, UserMixin],
  components: {
    Accordion,
    DatasetSegmentsList,
    SvgIconDecorative
  },
  data() {
    return {
      team_members: [],
      TEAMS_SERVICE: new TeamsService(this.$pigeonline)
    }
  },
  async created() {
    await this.fetchUsers()
  },
  computed: {
    owner() {
      const userDetails = this.team_members.find(
        (user) => user.id === this.dataset.created_by
      )
      if (userDetails) {
        return userDetails.email
      }
      if (this.profile.django_ref_id == this.dataset.created_by) {
        return this.$pigeonline.getUserEmail()
      }
      return "n/a"
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  },
  methods: {
    async fetchUsers() {
      this.team_members = await this.TEAMS_SERVICE.getAllTeamMembers({
        include_details: true
      })
    }
  }
}
</script>
