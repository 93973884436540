<template>
  <Modal
    class="create-new-analysis"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      <VisibleText>Create new analysis</VisibleText>
    </template>
    <VisibleText
      >Select questions to perform analysis. You can only have one data type per
      analysis.</VisibleText
    >
    <SelectQuestionsList
      :questions="dataPreprocessed"
      :selected-ids="selectedQuestionIds"
      :isPerformance="true"
      @update-selected-question-ids="updateSelectedQuestionIds"
      :toEmitUpdate="true"
      :areInvalid="areInvalid"
    />
    <VisibleText v-if="dataPreprocessed.length == 0" class="info-msg__inline">
      No scale questions found.
    </VisibleText>
    <template v-slot:footer>
      <div style="display: flex; width: 100%">
        <div style="display: flex; width: 20%; align-items: center">
          <button
            class="create-new-analysis__confirm-select-btn"
            @click="confirmSelection"
            :disabled="isConfirmSelectDisabled"
          >
            <SvgIconDecorative
              icon="check"
              class="create-new-analysis__confirm-select-btn-icon"
            />
            <VisibleText>Confirm selection</VisibleText>
          </button>
        </div>
        <div style="display: flex; width: 80%">
          <button
            class="btn-cancel"
            style="margin-left: auto"
            @click="segmentMode = true"
          >
            <div v-if="segmentParams">
              <VisibleText>Edit segment</VisibleText>
            </div>
            <div v-else>
              <VisibleText>Add segment to chart</VisibleText>
            </div>
          </button>
          <FilterPills
            v-if="isSegmented"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="segmentAsPill"
            @clearFilters="clearSegments"
            :altTitle="''"
          >
          </FilterPills>
          <button
            class="btn-cancel"
            style="margin-left: auto"
            @click="filterMode = true"
          >
            <div v-if="filterParams.operands && filterParams.operands.length">
              <VisibleText>Edit filters</VisibleText>
            </div>
            <div v-else>
              <VisibleText>Add filter to chart</VisibleText>
            </div>
          </button>
          <FilterPills
            v-if="isFiltered"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="filterParams"
            :altTitle="''"
            @clearFilters="clearFilters"
          >
          </FilterPills>
        </div>
      </div>
      <AddFilter
        :show="filterMode"
        :type="'own'"
        :data="allQuestions"
        @close-modal="filterMode = false"
        @confirmNewFilter="confirmNewFilter"
      />
      <AddSegment
        :show="segmentMode"
        :type="'own'"
        :data="allCatQuestions"
        @close-modal="segmentMode = false"
        @confirmNewSegment="confirmNewSegment"
      />
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SelectQuestionsList from "../../Components/SelectQuestionsList.vue"
import AddFilter from "@/components/Project/ProjectAnalysis/Components/AddFilter.vue"
import AddSegment from "@/components/Project/ProjectAnalysis/Components/AddSegment.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

import _ from "lodash"

export default {
  name: "CreateNewAnalysis",
  components: {
    Modal,
    SvgIconDecorative,
    SelectQuestionsList,
    AddFilter,
    FilterPills,
    AddSegment
  },
  props: {
    data: {
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isConfirmSelectDisabled: true,
      selectedQuestionIds: [],
      selectedQuestionTypes: [],
      resetListener: false,
      filterMode: false,
      filterParams: {},
      segmentMode: false,
      segmentParams: "",
      segmentAsPill: []
    }
  },
  computed: {
    dataPreprocessed() {
      return _.cloneDeep(this.data).filter(
        (q) =>
          q.approved_data_type &&
          (q.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/) ||
            q.approved_data_type === "OPEN_ENDED")
      )
    },
    areInvalid() {
      return _.cloneDeep(this.data)
        .filter(
          (q) =>
            this.selectedQuestionTypes.length &&
            q.approved_data_type &&
            !this.selectedQuestionTypes.includes(q.approved_data_type)
        )
        .map((el) => el._id.$oid)
    },
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    },
    isSegmented() {
      if (this.segmentParams && this.segmentParams !== "") {
        return true
      }
      return false
    },
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    allCatQuestions() {
      let cats = []
      if (this.allQuestions.length) {
        for (let i = 0; i < this.allQuestions.length; i++) {
          if (
            this.allQuestions[i].approved_data_type &&
            this.allQuestions[i].approved_data_type == "CAT"
          ) {
            cats.push(this.allQuestions[i])
          }
        }
      }
      return cats
    }
  },
  methods: {
    updateSelectedQuestionIds(questionIds) {
      this.isConfirmSelectDisabled = questionIds.length === 0
      this.selectedQuestionIds = questionIds
      this.selectedQuestionTypes = this.dataPreprocessed
        .filter((q) => questionIds.includes(q._id.$oid))
        .map((el) => el.approved_data_type)
    },
    confirmSelection() {
      this.$emit(
        "confirmSelection",
        this.dataPreprocessed
          .filter((q) => this.selectedQuestionIds.includes(q._id.$oid))
          .map((el) => [el.id, el.question_title]),
        this.filterParams,
        this.segmentParams
      )

      this.selectedQuestionIds = []
      ;(this.selectedQuestionTypes = []), this.$emit("close-modal")
    },
    confirmNewFilter(selected) {
      if (selected) {
        this.filterParams = selected
      }
    },
    clearFilters() {
      this.filterMode = false
      this.filterParams = {}
    },
    confirmNewSegment(selectedIds, selectedAsPill) {
      if (selectedIds && selectedAsPill) {
        this.segmentParams = selectedIds
        this.segmentAsPill = selectedAsPill
      }
    },
    clearSegments() {
      this.segmentMode = false
      this.segmentParams = ""
      this.segmentAsPill = []
    }
  },
  watch: {
    show: function (val) {
      if (!val) return
      this.isConfirmSelectDisabled = true
      this.resetListener = !this.resetListener
    }
  }
}
</script>
