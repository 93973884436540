<template>
  <div v-if="datasetSurveyDetails">
    <p>
      <VisibleText>
        This step lets you define some information about the dataset you just
        uploaded.
      </VisibleText>
    </p>
    <div style="display: flex; flex-direction: row">
      <h3 style="display: flex">
        <VisibleText> Specify the date of your data </VisibleText>
      </h3>
      <Tooltip>
        <p>
          <VisibleText>
            If your data covers a specific period of time it is a good idea to
            define that here. For example:
          </VisibleText>
        </p>
        <ul style="line-height: 1.5em">
          <li>
            <VisibleText>
              Did you run a survey? When did you stop receiving responses?
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              Did you collect data from social media? When did you collect this
              data?
            </VisibleText>
          </li>
        </ul>
      </Tooltip>
    </div>
    <div class="form-inline-group">
      <label for="survey-end-date">
        <VisibleText> Date this data was last modified </VisibleText>
      </label>
      <VisibleText
        class="error-msg__inline form__err--block"
        id="end-date-err"
        v-show="errMsg.surveyEndDate"
      >
        {{ errMsg.surveyEndDate }}
      </VisibleText>
      <input
        aria-describedby="end-date-err"
        required
        ref="end-date"
        type="date"
        id="survey-end-date"
        :aria-invalid="
          errMsg.surveyEndDate && !datasetSurveyDetails.surveyEndDate
            ? 'true'
            : 'false'
        "
        :readOnly="readOnly"
        v-model="datasetSurveyDetails.surveyEndDate"
        @change="errMsg.surveyEndDate = null"
      />
    </div>
    <div style="display: flex; flex-direction: row">
      <h3>
        <VisibleText> Describe your data with tags </VisibleText>
      </h3>
      <Tooltip>
        <p>
          <VisibleText>
            Think of a 'tag' like a hashtag in social media. A tag describes
            this dataset so that it is easier to find in the future. If you are
            benchmarking, these tags are especially important because you can
            compare yourself to others who use the same tags.
          </VisibleText>
        </p>
      </Tooltip>
    </div>
    <form
      class="form dataset-segments__form dataset__section-group"
      @submit.prevent
      novalidate
    >
      <div class="dataset__section-group-content light-scroll-bar">
        <DatasetSegmentsCustom
          class="dataset-segments__form-group"
          category="uncategorized"
          label="Enter tags"
          description="Tags describe your data with simple keywords."
          id="uncategorized"
          :read-only="readOnly"
          @add-tag="
            $_datasetSegmentsMixin_addSegment(true, 'uncategorized', $event)
          "
          @remove-tag="
            $_datasetSegmentsMixin_removeSegment(true, 'uncategorized', $event)
          "
        />
        <p class="p">
          <VisibleText>or answer the following:</VisibleText>
        </p>
        <DatasetSegmentsQuestionnaire
          :segments="datasetSegments"
          :read-only="readOnly"
        />
      </div>
      <button
        type="submit"
        class="dataset__section-save-btn"
        :disabled="!permissions.includes('update-dataset')"
        @click="$emit('save')"
      >
        <VisibleText>Save</VisibleText>
      </button>
      <MessageInline :message="stateText" :message-type="messageType" />
    </form>
  </div>
</template>

<script>
// Components
import DatasetSegmentsQuestionnaire from "../DatasetSegmentsQuestionnaire.vue"
import DatasetSegmentsCustom from "../DatasetSegmentsCustom/DatasetSegmentsCustom.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import Tooltip from "@/components/Tooltip/Tooltip.vue"

// Mixin
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"
import DatasetSegmentsMixin from "../Mixins/datasetSegmentsMixin.js"
import UserMixin from "@/utils/mixins/userMixin.js"

const SURVEY_DETAILS = {
  surveyStartDate: null,
  surveyEndDate: null,
  coverageArea: null,
  converagePopulation: null,
  thirdParty: null,
  dataCollector: null,
  frequency: null,
  procedure: null,
  language: null,
  sources: null,
  rate: null,
  responseRateNa: null
}

export default {
  name: "DatasetSegmentsForm",
  mixins: [DatasetMixin, DatasetSegmentsMixin, UserMixin],
  components: {
    DatasetSegmentsCustom,
    DatasetSegmentsQuestionnaire,
    MessageInline,
    Tooltip
  },
  props: {
    state: {
      type: Object
    }
  },
  data() {
    return {
      errMsg: {
        responseRate: null,
        surveyEndDate: null,
        surveyStartDate: null
      }
    }
  },
  async mounted() {
    // initialize survey details
    const body = {
      survey_details: { ...SURVEY_DETAILS }
    }
    if (!this.datasetSurveyDetails) {
      const updatedDataset = await this.$services.DATASETS_SERVICE.update(
        this.$store.getters["datasetDetails/getDataset"]._id.$oid,
        body
      )
      this.$store.dispatch(
        "datasetDetails/setDataset",
        updatedDataset.data_set_object
      )
    }
  },
  computed: {
    messageType() {
      if (this.state.isError) return "error"
      else if (this.state.isSaved) return "success"
      return ""
    },
    stateText() {
      if (this.state.isError)
        return "Sorry there were errors in the form. Please try again."
      else if (this.state.isSaved) return "Dataset details saved."
      return ""
    },
    datasetSurveyDetails() {
      return this.$store.getters["datasetDetails/getDataset"].survey_details
    }
  }
}
</script>
