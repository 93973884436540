<template>
  <Modal
    class="drivers__variables"
    :show="isNewTextToolModalVisible"
    @close="$emit('close-text-tool')"
    :widerFormat="true"
  >
    <template #header>
      <VisibleText> Add text </VisibleText>
    </template>
    <form
      class="user-guide__form"
      id="create-text"
      style="display: flex; flex-direction: column"
    >
      <div>
        <h2><VisibleText>Step 1: Choose a slide</VisibleText></h2>

        <p>
          <VisibleText
            >You can keep all your analysis in one place, or separate them into
            different slides.</VisibleText
          >
        </p>

        <div v-if="!slides.length" style="padding: 0 2em">
          <p style="color: var(--warning-text-colour); font-weight: 800">
            <VisibleText
              >No existing slides were detected, create a new one:</VisibleText
            >
          </p>
          <div>
            <div class="project-analysis-item__input">
              <label
                class="value project-analysis-item__input-label"
                :for="uuid"
              >
                <span> Please name this slide </span>
              </label>
              <input
                style="width: 15em"
                name="slide-name"
                type="text"
                :id="uuid"
                v-model="newSlideName"
              />
            </div>
          </div>
        </div>
        <div v-else style="padding: 0 2em">
          <p style="color: var(--success-text-colour); font-weight: 800">
            <VisibleText>Existing slides have been found.</VisibleText>
          </p>

          <div style="display: flex; flex-direction: row">
            <button
              class="project-analysis__header-add-btn"
              style="margin-left: 0; border-radius: 30px 0 0 30px"
              :style="
                !isAddingNewSlide
                  ? 'background-color: var(--highlight--gray); color: var(--teritary-ui-colour)'
                  : ''
              "
              @click.prevent="isAddingNewSlide = true"
            >
              <VisibleText>Create new slide</VisibleText>
            </button>
            <button
              class="project-analysis__header-add-btn"
              style="margin-left: 0; border-radius: 0 30px 30px 0"
              :style="
                isAddingNewSlide
                  ? 'background-color: var(--highlight--gray); color: var(--teritary-ui-colour)'
                  : ''
              "
              @click.prevent="isAddingNewSlide = false"
            >
              <VisibleText>Choose from existing slide</VisibleText>
            </button>
          </div>

          <div v-if="!isAddingNewSlide" style="margin: 1em">
            <label style="font-size: 0.8em">
              <VisibleText>List of slides:</VisibleText>
            </label>
            <div class="select-wrapper">
              <select
                class="select"
                id="saved-slide-filter"
                v-model="selectedSlide"
              >
                <option
                  v-for="(slide, index) in slides"
                  :value="slide"
                  :key="index"
                >
                  {{ slide.name }}
                </option>
              </select>
            </div>

            <div style="margin-top: 1em">
              <label class="form-checkbox-label">
                <input
                  class="form-checkbox-input"
                  type="radio"
                  value="true"
                  v-model="addToBottom"
                />
                Add to the bottom of the report
              </label>
              <label class="form-checkbox-label">
                <input
                  class="form-checkbox-input"
                  type="radio"
                  value="false"
                  v-model="addToBottom"
                />
                Choose where to place the text
              </label>
            </div>
          </div>
          <div v-if="isAddingNewSlide" style="margin: 1em">
            <div style="width: 15em">
              <div class="project-analysis-item__input">
                <label
                  class="value project-analysis-item__input-label"
                  :for="uuid"
                >
                  <span> Please name this slide </span>
                </label>
                <input
                  style="width: 100%"
                  name="slide-name"
                  type="text"
                  :id="uuid"
                  v-model="newSlideName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <label for="name"
        ><h2><VisibleText>Step 2: Enter text content</VisibleText>:</h2></label
      >

      <input
        class="contactUs__input"
        type="text"
        id="content"
        name="content"
        v-model="newTextContent"
      />
      <!-- <div>
      <VisibleText>Note: You can format the text in the Report view</VisibleText>
    </div>
    <label class="form-checkbox-label">
    <input
      class="form-checkbox-input"
      type="radio"
      value="true"
      v-model="addToBottom"
    > Add to the bottom of the report
    </label>
    <label class="form-checkbox-label">
    <input
      class="form-checkbox-input"
      type="radio"
      value="false"
      v-model="addToBottom"
    > Choose where to place the text
    </label> -->

      <div class="contactUs--content-right">
        <button
          :class="disableButton"
          class="landing-page__button"
          @click.prevent="sendForm"
          :disabled="disableButton !== ''"
        >
          <VisibleText>Add to report</VisibleText>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

export default {
  name: "NewTextToolModal",
  components: {
    Modal
  },
  props: {
    isNewTextToolModalVisible: {
      default: () => false,
      type: Boolean
    },
    slides: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      newTextContent: "",
      addToBottom: "true",
      newSlideName: "New slide",
      uuid: this.$pigeonline.createUUID(),
      selectedSlide: {},
      isAddingNewSlide: false
    }
  },
  mounted() {
    if (!this.slides.length) {
      this.isAddingNewSlide = true
    }
  },
  computed: {
    disableButton() {
      if (
        this.newTextContent === "" ||
        (this.isAddingNewSlide && !this.newSlideName.length) ||
        (!this.isAddingNewSlide && !this.selectedSlide)
      ) {
        return "landing-page__button-disabled"
      }
      return ""
    }
  },
  methods: {
    sendForm() {
      if (this.newTextContent) {
        let addToBottom = true
        if (this.addToBottom === "false") {
          addToBottom = false
        }
        if (!this.slides.length) {
          // No slides exist, so must be fixed
          this.$emit(
            "add-new-text",
            this.newTextContent,
            true,
            null,
            this.newSlideName
          )
        } else {
          if (this.isAddingNewSlide) {
            this.$emit(
              "add-new-text",
              this.newTextContent,
              addToBottom,
              null,
              this.newSlideName
            )
          } else {
            this.$emit(
              "add-new-text",
              this.newTextContent,
              addToBottom,
              this.selectedSlide,
              this.newSlideName
            )
          }
          this.isAddingNewSlide = false
        }
      }
      //  this.$emit("add-new-text", this.newTextContent, this.addToBottom)
      this.$emit("close-text-tool")
    }
  },
  watch: {
    isNewTextToolModalVisible: function (val) {
      if (val)
      {
        setTimeout(() => {
          if (this.slides.length) {
            this.isAddingNewSlide = false
            this.selectedSlide = this.slides[0]
          }
        }, 500)
      }
    }
  }
}
</script>
