import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class outliersService extends BaseService {
  constructor(core) {
    super(core)
    this.cancelSource = {}
  }
  /**
   * Fetch performance analysis data to generate performance analysis charts.
   * @param {Array} request.data_set_id dataset id
   * @param {Array} request.client_question_ids array of client question ids
   * @param {Array} request.selected_segments array of segments
   * @returns {Object}
   *
   */
  async outliersAnalysis(request, uuid) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analysis/outliers"
      this.cancelSource[uuid] = axios.CancelToken.source()
      const response = await axios.post(URL, request, {
        cancelToken: this.cancelSource[uuid].token
      })
      return response
    } catch (e) {
      if (axios.isCancel(e)) {
        throw 499
      } else {
        throw new Error("performanceService.js:outliersAnalysis:: " + e.message)
      }
    }
  }

  /**
   * Cancel the request to run performance analysis
   * @param {String} uuid unique identifier for a performance analysis
   */
  cancelRequest(uuid) {
    if (this.cancelSource[uuid]) {
      this.cancelSource[uuid].cancel()
    }
  }
}
