<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText
        >AI-powered text analysis - our latest enhancements:</VisibleText
      >
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>2023-08-20</VisibleText>
    </p>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%"
      src="/assets/landing/rocketship.jpg"
      alt=""
    />
    <p>
      <VisibleText>
        We're thrilled to announce the much-anticipated launch of our latest
        optimization update here at ResearchAI! What initially started as a
        journey to enhance Multi-word smart searching (MWSS) capabilities has
        evolved into a comprehensive re-engineering endeavor. Our focus has been
        on refining the user experience while expanding the capabilities of our
        platform. This update brings a host of improvements that empower you to
        navigate through your projects more efficiently and effectively.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Multi-word smart searching (MWSS)</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Sometimes we want to find not only direct matches to our search, but it
        be smart enough to also find matches across tenses and genders. Consider
        the following search query:</VisibleText
      >
    </p>

    <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
      <VisibleText>she is not polite</VisibleText>
    </p>

    <p>
      <VisibleText>
        Multi-word smart searching should find all exact four words, but for
        each word, also check for their
        <a href="https://en.wikipedia.org/wiki/Lemma_(morphology)">'lemmas'</a>.
        So, it should also find 'he was not polite', where 'she' and 'is' have
        lemma matches with 'he' and 'was'. You can see this with the screenshot
        below.
      </VisibleText>
    </p>
    <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
      <VisibleText>she is not polite = he was not polite</VisibleText>
    </p>
    <div
      class="library__image"
      style="
        flex-direction: column;
        max-height: 40rem;
        max-width: fit-content;
        margin-bottom: 4rem;
      "
    >
      <img
        style="max-height: 40rem; max-width: 95%"
        src="/assets/landing/multi-keyword-smart-search.jpg"
        alt="ResearchAI screenshot showing multi-word smart matching"
      />
      <div style="margin-left: auto; text-align: right; font-size: 0.8rem">
        <VisibleText
          >ResearchAI screenshot showing multi-word smart matching</VisibleText
        >
      </div>
    </div>

    <p>
      <VisibleText>
        Tools like NVivo or Excel lack this, so when you are searching and
        discovering across text, you may find yourself having to re-type many
        alternative versions of the same search to catch
        everything.</VisibleText
      >
    </p>
    <p>
      <VisibleText>
        This is different than finding 'similar sentences', which could find 'he
        was not polite' but also, 'she is polite'. This phrase is very 'similar'
        to the search so it may appear as a result despite lacking the crucial
        word 'not'. This lacks the precision we may want or need for certain
        search and discovery exercises.</VisibleText
      >
    </p>
    <h3>Similar sentences (less precise)</h3>
    <p
      style="
        text-align: center;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration-line: underline;
      "
    >
      <VisibleText>she is not polite</VisibleText>
    </p>
    <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
      <VisibleText>he was not polite</VisibleText>
      <SvgIconDecorative
        class="project-analysis-item__header-wrapper-icon"
        style="margin-left: 3rem; height: 3rem"
        icon="check"
      />
    </p>
    <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
      <VisibleText>she is polite</VisibleText>
      <SvgIconDecorative
        class="project-analysis-item__header-wrapper-icon"
        style="margin-left: 3rem; height: 3rem"
        icon="check"
      />
    </p>

    <h3>Multi-word smart match (more precise)</h3>
    <p
      style="
        text-align: center;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration-line: underline;
      "
    >
      <VisibleText>she is not polite</VisibleText>
    </p>
    <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
      <VisibleText>he was not polite</VisibleText>
      <SvgIconDecorative
        class="project-analysis-item__header-wrapper-icon"
        style="margin-left: 3rem; height: 3rem"
        icon="check"
      />
    </p>

    <p
      style="
        text-align: center;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration: line-through;
      "
    >
      <VisibleText>she is polite</VisibleText>
      <SvgIconDecorative
        class="project-analysis-item__header-wrapper-icon"
        style="margin-left: 3rem; height: 3rem"
        icon="remove"
      />
    </p>
    <h2 class="library__h2"><VisibleText>Some other wins</VisibleText></h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        With this update, we've made significant changes under the hood. We've
        strategically moved complex logic to our backend, freeing up your
        browser's resources for a smoother and faster browsing experience.
        Sentiments and 'smart searching' are now optional features, ensuring
        that your projects won't be slowed down by unnecessary computations.
        Redundant loops and logic have been streamlined, resulting in a more
        intuitive and user-friendly interface. Nevertheless, managing and
        creating themes has never been easier, and our multi-word searching
        functionality, including 'Smart searching' across lemmas, is now finally
        here. Plus, we've added a touch of fun with cute emojis in sentiments
        😃.
      </VisibleText>
    </p>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%"
      src="/assets/landing/more-power.jpg"
      alt=""
    />
    <h2 class="library__h2">
      <VisibleText>Tens of thousands of documents!</VisibleText>
    </h2>
    <div class="library__heading-line"></div>

    <p>
      <VisibleText>
        One of the most exciting milestones achieved through this update is the
        remarkable expansion in project size capacity. Previously, projects were
        capped at around 5,000,000 characters, equivalent to about 3200 pages of
        text. With hard work and dedication, we've shattered that ceiling,
        enabling projects of up to 25,000,000 characters! This upgrade has
        already been put to the test, as we employed it to analyze every
        Canadian public sector university program, showcasing the newfound
        capabilities of ResearchAI.
      </VisibleText>
    </p>

    <p>
      <VisibleText>
        As we introduce this new version, we're committed to addressing any
        potential cosmetic or unusual behaviors promptly. Your feedback is
        invaluable in helping us fine-tune the user experience further. And this
        is just the beginning!
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        We have exciting features in the pipeline, such as enhanced note
        highlighting that identifies similar sentences, improved suggested
        themes through suggested notes, refined sentiments for long-form content
        like interviews, and more sophisticated theme management options. Stay
        tuned for a smarter, more powerful ResearchAI experience that continues
        to push the boundaries of research analysis.
      </VisibleText>
    </p>

    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image library__image--nonresponsive"
          style="max-width: 4rem"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new.
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
              We will be in touch to set you up for success.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "ai_powered_text_analysis_our_recent_enhancements",
  components: {
    SvgIconDecorative
  },
  setup() {
    const siteData = reactive({
      title: "AI-powered text analysis - Our recent enhancements",
      description:
        "What are some of the best examples of how ResearchAI brought value to an organization?"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
