import { createUUID } from "@pigeonline/core"
import {
  description,
  header,
  questionSummaryNodes,
  benchmarksForAllGroupsNodes,
  benchmarksByGroupNodes
} from "./benchmarkingQbyQNodes.js"
import { createRandomOrgIds, replaceSpaceWithDash } from "./helpers.js"

// function createUuid() {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
//     /[xy]/g,
//     function (c) {
//       var r = (Math.random() * 16) | 0,
//         v = c == "x" ? r : (r & 0x3) | 0x8
//       return v.toString(16)
//     }
//   )
// }

/**
 * Generate skeleton for benchmarking question by question
 * section. Includes header and question by question section description.
 * @param {String} lang of report
 * @returns skeleton of benchmarking section formatted as a pigondoc section node
 */
const benchmarkingQbyQSkeleton = (lang = "en") => {
  return {
    type: "section",
    id: "benchmarking-qbyq-header-section",
    content: [header(lang), description(lang)]
  }
}

/**
 * Generate nodes for question by question section of a report.
 *
 * @param {Array} data.clientQuestions is array of client questions for dataset we want to run benchmarking on
 * @param {Array} data.clientQuestionAnalysis is response object from API endpoint /benchmarking/client_question_analysis
 * @param {Object} data.globalMatchesDetails is a map of client question id to global match details (see Vue.$store.getters[datasetDetails/matchesDetails])
 * @param {Array} data.detailedResults is reponse object from /benchmarking/detailed_results API endpoint
 * @param {Array} data.clientBenchmarkGroups is the benchmark groups the user wants to compare against
 * @param {Array} lang current report language
 *
 * @returns question by question section nodes
 */
const benchmarkingQbyQSection = (data, lang = "en") => {
  const idStore = createRandomOrgIds(data.detailedResults, lang)

  // sort according to client questions in Vue.$store.getters[datasetDetails/clientQuestions]
  const clientQuestionToID = data.clientQuestions.map((q) => q._id.$oid)
  const sortedResults = clientQuestionToID.reduce((sorted, id) => {
    let summaryObj = data.clientQuestionAnalysis.find(
      (obj) => obj.client_question_id === id
    )
    if (summaryObj) sorted.push(summaryObj)
    return sorted
  }, [])

  /**
   * loop through each client question and create the "question by question"
   * nodes and sections.
   *
   * for each client question you have 3 sections:
   * - summary section which includes tables and charts of respnse distribution
   * - all groups section which includes data for every peer organization
   * - benchmarks by group section which includes data for each benchmark group of a CMT match
   **/
  let nodes = []
  sortedResults.forEach((question, indx) => {
    /** setup variables */
    let globalMatch =
      data.globalMatchesDetails[question.client_question_id] || "q"
    let questionHeading = `${globalMatch.global_question_title}: ${globalMatch.global_question_text} (${question.question_title})`
    let questionHeadingFormatted = replaceSpaceWithDash(
      globalMatch.global_question_title
    )
    let uniqueVals = question["unique_values_count"]
    if (Object.keys(uniqueVals).length == 0) return
    // get benchmark data for this question (an object in the detailedResults array)
    let benchmarkData = data.detailedResults.find(
      (d) => d.client_question_id === question.client_question_id
    )

    /** create question summary section (response distribution) **/
    let summarySectionNodes = questionSummaryNodes(
      benchmarkData.mean_and_count_by_segment.User[0],
      questionHeadingFormatted,
      questionHeading,
      uniqueVals,
      lang
    )
    // remove page break for first question
    if (indx === 0) delete summarySectionNodes[0].meta.pdfConfig.pageBreak
    let capitalizedSubTitle = questionHeadingFormatted.toUpperCase()
    let summarySection = {
      type: "section",
      id: `${questionHeadingFormatted}-summary-section`,
      content: summarySectionNodes,
      uuid: createUUID(),
      meta: {
        subTitle: capitalizedSubTitle
      }
    }

    /** create "all groups" section **/
    const allGroupsNodes = benchmarksForAllGroupsNodes(
      benchmarkData.average_values,
      benchmarkData.mean_and_count_by_segment["All"],
      questionHeading,
      replaceSpaceWithDash(globalMatch.global_question_text),
      lang
    )
    let allGroupsSection = {
      type: "section",
      id: `${questionHeadingFormatted}-allgroups-section`,
      content: allGroupsNodes,
      uuid: createUUID(),
      meta: {
        subTitle: "BM - ALL QUESTIONS"
      }
    }

    /** create benchmark by group section **/
    const benchmarkByGroupSections = benchmarksByGroupNodes(
      benchmarkData.average_values,
      benchmarkData.mean_and_count_by_segment,
      data.clientBenchmarkGroups,
      questionHeading,
      lang
    )

    nodes.push(summarySection)
    if (allGroupsNodes.length > 0) nodes.push(allGroupsSection)
    nodes = nodes.concat(benchmarkByGroupSections)
  })

  return { sections: nodes, orgs: idStore }
}

export { benchmarkingQbyQSkeleton, benchmarkingQbyQSection }
