import { app } from "@/main.js"

export default {
  namespaced: true,
  state() {
    return {
      project: null,
      projectUpdatedPerformanceType: null,
      benchmarkSummaryResults: null
    }
  },
  getters: {
    getProject: (state) => state.project,
    getProjectUpdatedPerformanceType: (state) =>
      state.projectUpdatedPerformanceType,
    getBenchmarkSummaryResults: (state) => state.benchmarkSummaryResults
  },
  mutations: {
    SET_PROJECT(state, payload) {
      state["project"] = payload
    },
    SET_PROJECT_UPDATED_PERFORMANCE_TYPE(state, payload) {
      state["projectUpdatedPerformanceType"] = payload
    },
    SET_BENCHMARK_SUMMARY_RESULTS(state, payload) {
      state["benchmarkSummaryResults"] = payload
    },
    RESET_PROJECT(state) {
      state["project"] = null
      state["projectUpdatedPerformanceType"] = null
      state["benchmarkSummaryResults"] = null
    }
  },
  actions: {
    async setProject({ commit }, payload) {
      await commit("SET_PROJECT", payload)
    },
    async setProjectUpdatedPerformanceType({ commit }, payload) {
      await commit("SET_PROJECT_UPDATED_PERFORMANCE_TYPE", payload)
    },
    async setBenchmarkSummaryResults({ commit }, payload) {
      await commit("SET_BENCHMARK_SUMMARY_RESULTS", payload)
    },
    resetProject({ commit }) {
      commit("RESET_PROJECT")
    }
  }
}
