<template>
  <span class="dataset-table__values-li__item">
    <label class="value" :for="uuid">
      <slot></slot>
    </label>
    <input
      class="input"
      name="value-label"
      type="text"
      :placeholder="visiblePlaceholder"
      :id="uuid"
      v-model="label"
    />
    <span class="orig-value" v-if="uniqueVal.modified_value">
      <VisibleText>original value</VisibleText>: {{ uniqueVal.value }}
    </span>
  </span>
</template>

<script>
import _ from "lodash"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "EditLabelInput",
  props: {
    uniqueVal: {
      default: () => {},
      type: Object
    }
  },
  mixins: [UserMixin],
  created() {
    this.saveLabel = _.debounce(this.saveLabel, 1000)
  },
  data() {
    return {
      label: null,
      uuid: this.$pigeonline.createUUID()
    }
  },
  methods: {
    saveLabel() {
      this.$emit("update-label", this.label, this.uniqueVal)
    }
  },
  watch: {
    label: {
      immediate: false,
      handler() {
        if (
          this.permissions.includes("update-dataset") &&
          this.uniqueVal.label !== this.label
        )
          this.saveLabel()
      }
    },
    uniqueVal: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.label && val.label.length > 0) this.label = val.label
      }
    }
  },
  computed: {
    visiblePlaceholder() {
      return this.checkTranslationCache(
        "no label",
        `${this.uniqueVal.value}-label`,
        this.$store.getters.getLanguage
      )
    }
  }
}
</script>
