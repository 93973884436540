<template>
  <article id="project-report" :inert="modalOpen" :aria-hidden="modalOpen">
    <div class="project-report-message">
      <VisibleText>Your report is currently empty.</VisibleText>
      <p>
        <VisibleText>
          To begin, please go to the analysis tab to conduct an analysis on your
          dataset, then click "save to report".
        </VisibleText>
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: "ProjectReport",
  props: {
    datasetId: { type: String }
  },
  created() {
    document.title = `${this.$store.getters["project/getProject"].name} - Report | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    path() {
      return `/project/${this.$route.params.projid}/analysis`
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  }
}
</script>
