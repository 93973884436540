<template>
  <div class="benchmark-manager__matches-wrapper">
    <!--------------------------------------------
    | 
    | TOOLBAR
    |
    --------------------------------------------->
    <div class="table__toolbar" v-if="!noMatches">
      <div class="select-wrapper">
        <label class="sr-only" for="status-select">
          <VisibleText>select questions with status</VisibleText>
        </label>
        <select
          class="select table__toolbar-select"
          name="select-status"
          id="status-select"
          ref="status-select"
          @change="selectMatches($event)"
        >
          <option
            value=""
            disabled="true"
            selected
            :data-parent="$options.name"
          >
            {{ translate("Select", $options.name) }}
          </option>
          <option
            v-for="status in statusOptions"
            :key="status"
            :value="status"
            :data-parent="$options.name"
          >
            {{ translate(status, $options.name) }}
          </option>
        </select>
      </div>
      <button class="table__toolbar-btn approve" @click="approveSelected">
        <SvgIconDecorative icon="check" />
        <VisibleText>approve</VisibleText>
      </button>
      <button class="table__toolbar-btn reject" @click="rejectSelected">
        <SvgIconDecorative icon="remove" />
        <VisibleText>reject</VisibleText>
      </button>
      <button
        class="table__toolbar-btn reject"
        @click="temporarilyApproveSelected"
      >
        <SvgIconDecorative icon="eye" />
        <VisibleText>tentatively approve</VisibleText>
      </button>
      <Tooltip :leftAlign="true">
        <p>
          <VisibleText>
            Tentatively approve means letting the user create reports but their
            scores will not be included in the database.
          </VisibleText>
        </p>
      </Tooltip>
    </div>

    <DefaultTable
      class="benchmark-manager__matches-table"
      caption="benchmark matches"
      :hide-caption="true"
      :row-data="benchmarkMatches"
      :column-headers="columnHeaders"
      v-if="!noMatches"
    >
      <template #row="{ row, index }">
        <td data-label="User">
          <label
            :for="`${row.benchmarkId}`"
            class="form-label form-checkbox-label"
          >
            <input
              type="checkbox"
              :id="`${row.benchmarkId}`"
              :name="row.benchmarkId"
              :value="row.benchmarkId"
              v-model="selectedMatches"
              number
            />
            <!-- This is generated by new benchmark requests (after 1.6.91) -->
            <div v-if="Object.keys(row.userDetails).length">
              <span v-if="row.userDetails.userName">
                {{ row.userDetails.userName }}
              </span>
              <span v-if="row.userDetails.userEmail">
                {{ row.userDetails.userEmail }}
              </span>
              <span
                v-if="row.owner && row.owner.userName"
                class="table__secondary-data"
              >
                {{ row.owner.userName }}
              </span>
            </div>
            <!-- Older benchmark owner information (did not share) -->
            <div v-if="!Object.keys(row.userDetails).length && row.owner">
              <span v-if="row.owner.userName">
                {{ row.owner.userName }}
              </span>
              <span v-if="row.owner.userEmail">
                {{ row.owner.userEmail }}
              </span>
              <span v-if="row.owner.orgnization" class="table__secondary-data">
                {{ row.owner.organization }}
              </span>
            </div>
          </label>
        </td>
        <td data-label="Dataset Name">
          <div v-if="Object.keys(row.datasetDetails).length">
            {{ row.datasetDetails.name }}
            <span class="table__secondary-data" v-if="row.date_requested">
              <VisibleText style="color: black">Date requested: </VisibleText
              >{{ formatDateTime(row.date_requested) }}
            </span>
            <span
              class="table__secondary-data"
              v-if="row.datasetDetails.created_on"
            >
              <VisibleText style="color: black">Uploaded: </VisibleText
              >{{ formatDateTime(row.datasetDetails.created_on) }}
            </span>

            <span
              class="table__secondary-data"
              v-if="row.datasetDetails.surveyStartDate"
            >
              <VisibleText style="color: black">Start: </VisibleText
              >{{ row.datasetDetails.surveyStartDate }}
            </span>
            <span
              class="table__secondary-data"
              v-if="row.datasetDetails.surveyEndDate"
            >
              <VisibleText style="color: black">End: </VisibleText
              >{{ row.datasetDetails.surveyEndDate }}
            </span>

            <div
              class="table__secondary-data"
              v-if="
                row.datasetDetails.segments &&
                Object.keys(row.datasetDetails.segments).length
              "
            >
              <VisibleText style="color: black">Tags: </VisibleText>
              <span
                class="ui-rounded-pill__wrapper"
                style="color: black; font-weight: 500"
                v-for="segment in Object.keys(row.datasetDetails.segments)"
                :key="segment"
                :value="segment"
              >
                {{ segment }}
              </span>
            </div>
          </div>
        </td>
        <td data-label="User Question Code">
          {{ row.userQuestionTitle }}
          <button
            class="account-profile__form-btn"
            v-if="Object.keys(row.clientQuestion).length"
            @click="showThisScore(row.clientQuestion)"
          >
            <VisibleText>Question details</VisibleText>
          </button>
        </td>
        <td data-label="User Question Wording">
          {{ row.userQuestionText }}
        </td>
        <td data-label="Status">
          <VisibleText
            :class="[
              'status',
              row.status,
              { approved: row.status === 'auto approved' },
              { 'in-progress': row.status === 'tentatively approved' }
            ]"
          >
            {{ row.status }}
          </VisibleText>
        </td>
        <td data-label="Action" class="matches-table__actions">
          <button
            class="matches-table__action"
            @click="approve(row.benchmarkId, index)"
          >
            <VisibleText>Approve</VisibleText>
          </button>
          <button
            class="matches-table__action"
            @click="reject(row.benchmarkId, index)"
          >
            <VisibleText>Reject</VisibleText>
          </button>
          <button
            class="matches-table__action"
            @click="temporarilyApprove(row.benchmarkId, index)"
          >
            <VisibleText>Tentatively approve</VisibleText>
          </button>
        </td>
      </template>
    </DefaultTable>
    <div v-else>
      <VisibleText>no matches</VisibleText>
    </div>
    <Spinner
      :is-loading="loading"
      :component-style="true"
      message="updating status, please wait"
      complete-message="update complete"
    />
    <ShowScore
      v-bind:status="isShowScoreExpanded"
      :showThisQuestion="showThisQuestion"
      @close="isShowScoreExpanded = false"
    ></ShowScore>
  </div>
</template>

<script>
// Componenets
import DefaultTable from "@/components/UI/DefaultTable.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"
import ShowScore from "../Components/ShowScore.vue"
import Tooltip from "@/components/Tooltip/Tooltip.vue"

// Services
import BenchmarkingService from "@/services/benchmarkingService.js"
import DatasetsService from "@/services/datasetsService.js"
import ProfilesService from "@/services/profilesService.js"
import TeamsService from "@/services/teamsService.js"

import moment from "moment"

export default {
  name: "BenchmarkManagerMatches",
  components: {
    DefaultTable,
    Spinner,
    SvgIconDecorative,
    ShowScore,
    Tooltip
  },
  props: {
    caption: {
      default: () => "Benchmark Matches",
      type: String
    },
    globalQuestionId: {
      default: null,
      type: String,
      required: true
    },
    teamMembers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      BENCHMARKING_SERVICE: new BenchmarkingService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline),
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      columnHeaders: [
        "User",
        "Dataset Name",
        "User Question Code",
        "User Question Wording",
        "Status",
        "Action"
      ],
      benchmarkMatches: [],
      noMatches: false,
      statusOptions: [
        "none",
        "all",
        "approved",
        "pending",
        "rejected",
        "tentatively approved"
      ],
      selectedMatches: [],
      loading: false,
      isShowScoreExpanded: false,
      showThisQuestion: {}
    }
  },
  mounted() {
    this.loadData().then(({ benchmarks, clientQuestions }) => {
      if (benchmarks.length > 0)
        this.formatBenchmarkMatches(benchmarks, clientQuestions)
      else this.noMatches = true
    })
  },
  methods: {
    showThisScore(clientQuestion) {
      this.showThisQuestion = clientQuestion
      this.isShowScoreExpanded = true
    },
    async loadData() {
      const [benchmarks, clientQuestions] = await Promise.all([
        this.fetchBenchmarks(),
        this.fetchClientQuestions()
      ])
      return { benchmarks, clientQuestions }
    },
    async approveSelected() {
      if (this.selectMatches.length === 0) return
      this.loading = true
      await Promise.all(
        this.selectedMatches.map(async (id) => {
          let index = this.benchmarkMatches.findIndex(
            (m) => m.benchmarkId === id
          )
          await this.approve(id, index)
        })
      )
      this.loading = false
    },
    async temporarilyApproveSelected() {
      if (this.selectMatches.length === 0) return
      this.loading = true
      await Promise.all(
        this.selectedMatches.map(async (id) => {
          let index = this.benchmarkMatches.findIndex(
            (m) => m.benchmarkId === id
          )
          await this.temporarilyApprove(id, index)
        })
      )
      this.loading = false
    },
    async rejectSelected() {
      if (this.selectMatches.length === 0) return
      this.loading = true
      await Promise.all(
        this.selectedMatches.map(async (id) => {
          let index = this.benchmarkMatches.findIndex(
            (m) => m.benchmarkId === id
          )
          await this.reject(id, index)
        })
      )
      this.loading = false
    },
    // This is re-used throughout and could be made into a global utility
    async fetchDataset(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.dataset(datasetId)
        return response
      } catch (e) {
        throw new Error("DatasetDetailsMixin:fetchDataset " + e.message)
      }
    },
    /**
     * Fetching and saving benchmarks
     */
    async fetchBenchmarks() {
      try {
        const response = await this.BENCHMARKING_SERVICE.benchmarking({
          global_question_id: this.globalQuestionId
        })
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:fetchBenchmarks " + e.message)
      }
    },
    /**
     * Fetching and saving client questions
     */
    async fetchClientQuestions() {
      try {
        // backwards compatibility with client question schema v1
        const response1 = await this.DATASETS_SERVICE.clientQuestions(null, {
          benchmarked_global_question_id: this.globalQuestionId
        })
        const response2 = await this.DATASETS_SERVICE.clientQuestions(null, {
          "benchmarked_global_question.selected": this.globalQuestionId
        })
        return [...response1, ...response2]
      } catch (e) {
        throw new Error(
          "BenchmarkManagerMatches:fetchClientQuestions " + e.message
        )
      }
    },
    async approve(benchmarkId, index) {
      if (
        ["approved", "auto approved"].includes(
          this.benchmarkMatches[index].status
        )
      )
        return

      try {
        await this.BENCHMARKING_SERVICE.update(benchmarkId, {
          approved: true
        })
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:approve " + e.message)
      }

      this.benchmarkMatches[index].status = "approved"
      this.$emit("updateMatchesStatus", this.matchesStatus)
    },
    async temporarilyApprove(benchmarkId, index) {
      if (
        ["tentatively approved"].includes(this.benchmarkMatches[index].status)
      )
        return
      try {
        await this.BENCHMARKING_SERVICE.update(benchmarkId, {
          temporarily_approved: true,
          approved: false,
          auto_approved: false,
          rejected: false
        })
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:approve " + e.message)
      }

      this.benchmarkMatches[index].status = "tentatively approved"
      this.$emit("updateMatchesStatus", this.matchesStatus)
    },
    async reject(benchmarkId, index) {
      if (this.benchmarkMatches[index].status === "rejected") return

      try {
        await this.BENCHMARKING_SERVICE.update(benchmarkId, {
          auto_approved: false,
          approved: false,
          rejected: true,
          temporarily_approved: false
        })
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:approve " + e.message)
      }

      this.benchmarkMatches[index].status = "rejected"
      this.$emit("updateMatchesStatus", this.matchesStatus)
    },
    async formatBenchmarkMatches(benchmarks, clientQuestions) {
      const setStatus = (
        approved,
        rejected,
        autoApproved,
        temporarilyApproved
      ) => {
        if (autoApproved) return "auto approved"
        else if (approved) return "approved"
        else if (rejected) return "rejected"
        else if (temporarilyApproved) return "tentatively approved"
        else return "pending"
      }

      this.benchmarkMatches = benchmarks.reduce((matches, benchmark) => {
        // Look for user details by matching owner id to team mates, to get more information beyond what is in the userDetails part of the benchmark (which is just email anyway)
        let userDetails = {}
        if (benchmark.created_by) {
          userDetails = this.teamMembers.find(
            (user) => user.id === benchmark.created_by
          )
        }
        if (benchmark.owner && benchmark.owner.user) {
          userDetails = this.teamMembers.find(
            (user) => user.id === benchmark.owner.user
          )
        }
        let owner = {
          userName:
            userDetails && userDetails.fullname ? userDetails.fullname : "n/a",
          userEmail:
            userDetails && userDetails.email ? userDetails.email : "n/a",
          team:
            userDetails && userDetails.team_name
              ? userDetails.connection.team_name
              : "n/a"
        }

        const cQ = clientQuestions.find(
          (c) => c._id.$oid === benchmark.client_question_id
        )
        if (!cQ) return matches
        let date_requested
        if (benchmark.date_requested) {
          date_requested = benchmark.date_requested
        }

        matches.push({
          benchmarkId: benchmark._id.$oid,
          date_requested: date_requested,
          checked: false,
          owner: owner,
          userQuestionTitle: cQ.question_title,
          userQuestionText: cQ.question_text,
          datasetId: benchmark.client_data_set_id,
          clientQuestion:
            benchmark.client_question &&
            Object.keys(benchmark.client_question).length
              ? benchmark.client_question
              : {},
          datasetDetails:
            benchmark.dataset_details &&
            Object.keys(benchmark.dataset_details).length
              ? benchmark.dataset_details
              : {},
          userDetails:
            benchmark.user_details &&
            Object.keys(benchmark.user_details).length &&
            benchmark.user_details.userEmail.length
              ? benchmark.user_details
              : {},
          status: setStatus(
            benchmark.approved,
            benchmark.rejected,
            benchmark.auto_approved,
            benchmark.temporarily_approved
          )
        })
        return matches
      }, [])
    },
    selectMatches($event) {
      let type = $event.target.value
      if (
        [
          "approved",
          "auto approved",
          "tentatively approved",
          "rejected",
          "pending"
        ].includes(type)
      ) {
        this.selectedMatches = this.benchmarkMatches
          .filter((match) => match.status === type)
          .map((m) => m.benchmarkId)
      } else if (type === "all") {
        this.selectedMatches = this.benchmarkMatches.map(
          (match) => match.benchmarkId
        )
      } else {
        this.selectedMatches = []
      }
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value).locale("fr").format("MMMM D, YYYY hh:mm A")
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A")
        }
      }
    }
  },
  computed: {
    matchesStatus() {
      if (this.benchmarkMatches && this.benchmarkMatches.length) {
        let auto_approved_count = 0
        let approved_count = 0
        let temporarily_approved_count = 0
        let rejected_count = 0
        let pending_count = 0
        for (let i = 0; i < this.benchmarkMatches.length; i++) {
          if (this.benchmarkMatches[i].status === "auto approved") {
            auto_approved_count = auto_approved_count + 1
          }
          if (this.benchmarkMatches[i].status === "approved") {
            approved_count = approved_count + 1
          }
          if (this.benchmarkMatches[i].status === "tentatively approved") {
            temporarily_approved_count = temporarily_approved_count + 1
          }
          if (this.benchmarkMatches[i].status === "rejected") {
            rejected_count = rejected_count + 1
          }
          if (this.benchmarkMatches[i].status === "pending") {
            pending_count = pending_count + 1
          }
        }
        return {
          auto_approved_count: auto_approved_count,
          approved_count: approved_count,
          temporarily_approved_count: temporarily_approved_count,
          rejected_count: rejected_count,
          pending_count: pending_count
        }
      }
      return {}
    }
  }
  // watch: {
  //   matchesStatus: {
  //     deep: true,
  //     handler: function (value) {
  //       if (value && Object.keys(value).length > 0)
  //         this.$emit("updateMatchesStatus", value)
  //     }
  //   }
  // }
}
</script>
