import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class UserAnalyticsService extends BaseService {
  /**
   * Get analytics object
   * @returns {(Object|Array)} object or array of analytics
   */
  async analytics(id, params = {}) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/analytics/"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""

      let url = `${URL}${id ? `${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("userAnalyticsService.js:translations " + e.message)
    }
  }
}
