<template>
  <div style="padding-left: 0.3em">
    <button
      style="
        z-index: 10;
        border-radius: 100%;
        height: 2em;
        width: 2em;
        border: 0.5px solid var(--base-colour-light);
      "
      @click="tooltipOpened = !tooltipOpened"
    >
      <span v-if="!tooltipOpened">?</span>
      <span v-else>x</span>
    </button>
    <div
      style="
        z-index: 15;
        position: absolute;
        width: 50vw;
        background-color: white;
        border: 1px solid var(--outline-colour);
        padding: 1em;
      "
      :style="adjustedStyle"
      v-if="tooltipOpened"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    leftAlign: {
      default: () => false,
      type: Boolean
    },
    farLeftAlign: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    adjustedStyle() {
      if (this.leftAlign) {
        return "margin-left: -20rem;"
      } else if (this.farLeftAlign) {
        return "margin-left: -40rem;"
      }
      return ""
    }
  },
  data() {
    return {
      tooltipOpened: false
    }
  }
}
</script>
