export default {
  data() {
    return {
      message: {
        type: "",
        text: ""
      },
      newLabels: null
    }
  },
  methods: {
    $_dataValuesMixin_updateLabel(label, value) {
      // copy unique values object
      this.newLabels = this.newLabels ? this.newLabels : [...this.uniqueValues]

      const indx = this.newLabels.findIndex((val) => val.value === value.value)
      this.newLabels[indx].label = label
    },
    $_dataValuesMixin_resetMessage() {
      this.message = {
        type: "",
        text: ""
      }
    },
    async $_dataValuesMixin_saveLabels() {
      this.$_dataValuesMixin_resetMessage()
      try {
        await this.$services.DATASETS_SERVICE.updateClientQuestion(
          this.clientQuestionId,
          { unique_values: this.newLabels }
        )
        this.message.type = "success"
        this.message.text = "Labels saved."
      } catch (e) {
        this.message.type = "error"
        this.message.text = "Problem saving labels."
        throw new Error("DatasetTableValuesScale:saveLabel " + e.message)
      }
    }
  }
}
