<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <p>
      <VisibleText>
        This is a quick guide to ResearchAI projects and analysis.
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--connect')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Connect a new project to a dataset</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--examples')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Analysis examples</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--widgets')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>ResearchAI analysis widgets</VisibleText>
        </a>
      </li>
    </ul>
    <h1 class="library__h1">
      <VisibleText> Conducting basic analysis in ResearchAI </VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        You have successfully uploaded data and are now ready for analysis.
      </VisibleText>
    </p>

    <h2 class="library__h2" ref="goto--connect">
      <VisibleText>Connect a new project to a dataset</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>To begin, create a new project.</VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__analysis01.png"
      alt="screenshot of projects page with create new project button circled"
    />
    <p>
      <VisibleText
        >Next, select a dataset that you have finished cleaning, and then give
        the project a name.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_new-project.png"
      alt="screenshot of projects page with create new project button circled"
    />
    <p>
      <VisibleText
        >After creating the project, you will be presented with a new view. This
        is your Analysis view. Three important tabs are Data, which you can use
        to remind yourself about the dataset, Analysis where you run drivers,
        text analysis, etc. And Report where you can save and export final
        results. Some analysis types are 'easier' to run than others, and you
        should be able to find some guidance on each in their respective tabs.
      </VisibleText>
    </p>

    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_new-analysis.png"
      alt="screenshot of projects page with create new project button circled"
    />
    <h2 class="library__h2" ref="goto--examples">
      <VisibleText>Analysis examples</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >The tool will have a few types of analysis available for you based on
        your data.
      </VisibleText>
    </p>
    <div class="figure__vertical--card">
      <h4><VisibleText>Prediction using Drivers Analysis</VisibleText></h4>
      <VisibleText>
        Requires scale questions, like responses between 1 to 5:
      </VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText
            >This will find out what aspects of service are most likely to drive
            an outcome like client satisfaction or willingness to recommend to
            others.
          </VisibleText>
        </p>
      </div>
      <a
        href="javascript:void(0)"
        v-if="isUserGuide"
        @click="$emit('new-page', 'DriversAnalysis')"
        onclick="this.style.color = '#3d0071'"
      >
        <VisibleText> Learn more about drivers analysis </VisibleText>
      </a>
      <div v-else>
        <router-link to="/library/guide/drivers-analysis-in-researchai">
          <VisibleText> Learn more about drivers analysis </VisibleText>
        </router-link>
      </div>
    </div>
    <div class="figure__vertical--card">
      <h4><VisibleText>Benchmarking Analysis</VisibleText></h4>
      <VisibleText
        >Requires you to connect questions to questions in a benchmarking
        service:
      </VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText>This will compare your scores to peers.</VisibleText>
        </p>
      </div>
      <a
        href="javascript:void(0)"
        v-if="isUserGuide"
        @click="$emit('new-page', 'BenchmarkingAnalysis')"
        onclick="this.style.color = '#3d0071'"
      >
        <VisibleText> Learn more about benchmarking analysis </VisibleText>
      </a>
      <div v-else>
        <router-link to="/library/guide/what-is-benchmarking-in-researchai">
          <VisibleText> Learn more about benchmarking analysis </VisibleText>
        </router-link>
      </div>
    </div>
    <div class="figure__vertical--card">
      <h4><VisibleText>Text Analysis</VisibleText></h4>
      <VisibleText>Requires text questions.</VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText
            >This will help you find themes in your text.
          </VisibleText>
        </p>
      </div>
      <a
        href="javascript:void(0)"
        v-if="isUserGuide"
        @click="$emit('new-page', 'ThematicAnalysis')"
        onclick="this.style.color = '#3d0071'"
      >
        <VisibleText>
          Learn more about text / thematic / topic analysis
        </VisibleText>
      </a>
      <div v-else>
        <router-link
          to="/library/guide/what-is-thematic-analysis-in-researchai"
        >
          <VisibleText>
            Learn more about text / thematic / topic analysis
          </VisibleText>
        </router-link>
      </div>
    </div>
    <div class="figure__vertical--card">
      <h4>
        <VisibleText
          >Finding key over- or under-performing outliers</VisibleText
        >
      </h4>
      <VisibleText>
        Requires scale questions, like responses between 1 to 5, and requires
        categorical questions (segments)
      </VisibleText>
      <div class="figure__vertical--indent">
        <p>
          <VisibleText
            >This will find specific groups (based on categories like segments)
            that are significantly under- or over-performing on scale questions.
          </VisibleText>
        </p>
      </div>
      <a
        href="javascript:void(0)"
        v-if="isUserGuide"
        @click="$emit('new-page', 'OutliersAnalysis')"
        onclick="this.style.color = '#3d0071'"
      >
        <VisibleText> Learn more about outliers analysis </VisibleText>
      </a>
      <div v-else>
        <router-link
          to="/library/guide/what-is-thematic-analysis-in-researchai"
        >
          <VisibleText> Learn more about outliers analysis </VisibleText>
        </router-link>
      </div>
    </div>
    <h2 class="library__h2" ref="goto--widgets">
      <VisibleText>ResearchAI analysis widgets</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >All analysis will create an analysis 'widget'. Analysis is surrounded
        by a box, which can be opened and closed. Below is an example of a
        'closed' drivers analysis widget. These analysis widgets have various
        tools such as saving, deleting, or opening/closing. Some analysis types
        will have additional options, like modifying variables.</VisibleText
      >
    </p>
    <img
      class="screenshot"
      src="/assets/userguide_example-regression-box.png"
      alt="screenshot of project tabs with analysis circled"
    />

    <p>
      <VisibleText>
        Once an analysis has been completed, you can "save" the analysis to a
        report. To do so, you must give your report's slide a name and then
        click "Save to report". Once saved, you will see a preview of your
        report on the left side of your analysis.
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide_table-of-contents.png"
      alt="screenshot of project tabs with analysis circled"
    />

    <p>
      <VisibleText>
        You can view the report as a shareable link, or you can export to .pdf
        file format. To do this, press the "report" tab.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_click-report.png"
      alt="screenshot of drivers analysis with save to report button circled"
    />
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "basic_analysis",
  setup() {
    const siteData = reactive({
      title: "Conducting basic analysis in ResearchAI",
      description:
        "How to connect your dataset to a new project and begin your first analysis"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
