<template>
  <div
    :class="['theme__popup', { show: showDialog }]"
    v-click-outside="forceClose"
  >
    <div style="display: flex; flex-direction: row; align-self: flex-start">
      <div style="display: flex; width: 6rem">
        <button
          type="button"
          class="open-btn"
          @click="onClickOpen"
          style="font-size: 0.8rem; text-align: left; display: flex"
        >
          {{ notes.length }}
          <VisibleText>&nbsp;sentences</VisibleText>
        </button>
      </div>

      <div
        style="
          display: flex;

          overflow-x: auto;
          overflow-y: hidden;
          padding-bottom: 0.3rem;
        "
        :style="isThemeList ? 'max-width: 90%;' : 'max-width: 30rem;'"
        class="light-scroll-bar"
        v-if="showCoverageDetails == 'show'"
      >
        <div v-for="(note, index) in notes" :key="index">
          <div v-if="note.content">
            <button
              style="
                font-size: 0.8rem;
                width: max-content;
                top: 0.2rem;
                color: white;
              "
              class="keywords-input-badge pill ui-adaptable"
              :style="{ 'background-color': theme.theme_color }"
              @click.prevent="$root.$emit('search-keyword', note.content, true)"
            >
              {{ note.content.trim(30) }}
            </button>
          </div>

          <div v-if="!note.content && getResponseText(note.responseId)">
            <button
              style="
                font-size: 0.8rem;
                width: max-content;
                top: 0.2rem;
                color: white;
              "
              class="keywords-input-badge pill ui-adaptable"
              :style="{ 'background-color': theme.theme_color }"
              @click.prevent="$root.$emit('search-keyword', note.content, true)"
            >
              {{ getResponseText(note.responseId).trim(30) }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <DialogPopup
      class="theme__popup-dialog"
      header-tag="h4"
      :show="showDialog"
      @close="showDialog = false"
      style="margin-left: -30rem"
      v-if="showDialog && notes.length > 0"
      :style="isThemeList ? 'margin-left: -15rem; margin-top: 18%;' : ''"
    >
      <template #header>
        Sentences for the theme:
        <div
          class="theme__popup-dialog-name"
          style="
            font-family: Core-Sans;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
            margin-left: 0.5rem;
            text-decoration: none;
          "
        >
          &nbsp;{{ theme.name }}
        </div>
      </template>
      <template #body>
        <ul class="theme__popup-list">
          <li
            v-for="(note, index) in visibleNotes"
            :key="`${theme.name}-note-${index}`"
            class="item"
            v-show="
              selectedNoteIndex == -1 ||
              selectedNoteIndex == offsetByPage(index)
            "
          >
            <div style="display: flex; flex-direction: row">
              <button
                class="index-strength__tooltip-btn"
                type="button"
                style="
                  border: none;
                  background-color: transparent;
                  width: 1.5rem;
                "
                v-if="
                  selectedNoteIndex == -1 ||
                  selectedNoteIndex == offsetByPage(index)
                "
                @click="onClickSource(note, offsetByPage(index))"
              >
                <SvgIconMeaningful
                  style="
                    padding: 0;
                    height: 1rem;
                    margin: auto;
                    margin-top: 0.25rem;
                  "
                  class="complete-icon-svg"
                  :icon="selectedNoteIndex == -1 ? 'arrowDown' : 'arrowUp'"
                  title="show note in full response"
                />
              </button>
              <button
                class="index-strength__tooltip-btn"
                type="button"
                style="
                  border: none;
                  background-color: transparent;
                  width: 1.5rem;
                  margin-right: 0.3rem;
                "
                v-if="
                  selectedNoteIndex == -1 ||
                  (selectedNoteIndex == offsetByPage(index) && !expandedSimilar)
                "
                @click="findSimilar(note, index)"
                :disabled="!hasFetchedSentences"
              >
                <SvgIconMeaningful
                  style="padding: 0; height: 1rem; margin: auto"
                  class="complete-icon-svg"
                  icon="search"
                  title="show note in full response"
                />
              </button>
              <button
                class="index-strength__tooltip-btn"
                type="button"
                style="
                  border: none;
                  background-color: transparent;
                  width: 1.5rem;
                  margin-right: 1rem;
                "
                v-if="
                  selectedNoteIndex == -1 ||
                  selectedNoteIndex == offsetByPage(index)
                "
                @click="removeNote(note)"
              >
                <SvgIconMeaningful
                  style="padding: 0; height: 1rem; margin: auto"
                  class="complete-icon-svg"
                  icon="deleteIcon"
                  title="delete this note"
                />
              </button>

              <div style="display: flex; flex-direction: column">
                <p
                  :class="[
                    'text',
                    { selected: selectedNoteIndex === offsetByPage(index) }
                  ]"
                >
                  <span v-if="note.text && note.text.length > 0">
                    <span style="font-weight: 800"> Researcher note: </span>
                    {{ note.text }}</span
                  >
                  <span v-else>
                    <span v-if="note.content">
                      <span style="font-weight: 800">
                        Tagged sentence
                        <span v-if="note.responseId"> {{ note.row_index }}</span
                        >:
                      </span>
                      {{ note.content }}
                    </span>
                    <span
                      v-if="!note.content && getResponseText(note.responseId)"
                    >
                      <span style="font-weight: 800">
                        Tagged text {{ note.row_index }}
                      </span>
                      {{ getResponseText(note.responseId).trim(30) }}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div
          v-if="!expandedSimilar && selectedNoteIndex == -1"
          class="theme__popup-pagination"
          v-show="pagination.totalPages > 1"
        >
          <span class="details">
            {{ translate("Page", $options.name) }} {{ pagination.page }}
            {{ translate("of", $options.name) }} {{ pagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="pagination.page === 1"
              @click="onClickPrevPage"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="pagination.page === pagination.totalPages"
              @click="onClickNextPage"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
        <div
          v-if="!expandedSimilar"
          class="theme__popup-source"
          style="overflow-y: auto; max-height: 50rem"
          v-show="sourceHighlightString.length > 0"
        >
          <p v-html="sourceHighlightString" class="highlighted-content"></p>
        </div>
        <SimilarSentences
          ref="NotesSimilarSentences"
          :clientQuestionId="clientQuestionId"
          :theme="theme"
          @addNewNote="addNewNote($event)"
          @selectThisResponse="$emit('selectThisResponse', $event)"
        >
        </SimilarSentences>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"
import SimilarSentences from "./SimilarSentences.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

export default {
  name: "NotesPopup",
  components: {
    DialogPopup,
    SimilarSentences,
    SvgIconMeaningful
  },
  mixins: [textAnalysisMixin],
  props: {
    clientQuestionId: {
      default: () => "",
      type: String
    },
    allResponses: {
      default: () => [],
      type: Array
    },
    showCoverageDetails: {
      type: String,
      default: () => "show"
    },
    themesLength: {
      default: () => 0,
      type: Number
    },
    themeIndex: {
      default: () => -1,
      type: Number
    },
    theme: {
      default: () => {},
      type: Object
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    isThemeList: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      selectedNoteIndex: -1,
      showDialog: false,
      sourceHighlightString: "",
      visibleNotes: [],
      // pagination
      pagination: {
        page: 1,
        limit: 5, //limit of how many are shown in a page
        totalPages: 1,
        startIndx: 0,
        endIndx: 0
      },
      expandedSimilar: false
    }
  },
  created() {
    this.setup()
  },
  computed: {
    notes() {
      return this.theme.notes
    }
  },
  methods: {
    closeOptions(index) {
      if (this.expandedSimilar) {
        if (this.$refs["NotesSimilarSentences"]) {
          this.$refs["NotesSimilarSentences"].clearSimilar()
        }
        this.expandedSimilar = false
      }
      if (this.selectedNoteIndex === this.offsetByPage(index)) {
        this.selectedNoteIndex = -1
        this.sourceHighlightString = ""
      }
    },
    removeNote(note) {
      this.$emit("onRemoveNote", note)
    },
    addNewNote(noteDetails) {
      this.$emit("addNewNote", noteDetails)
    },
    signalStrength(similarity) {
      if (similarity >= 0.6) {
        return "signalStrong"
      } else if (similarity > 0.4 && similarity < 0.6) {
        return "signalGood"
      } else {
        return "signalFair"
      }
    },
    roundToTwo(num) {
      return Math.round(num * 100) / 100
    },
    async findSimilar(note, index) {
      if (this.$refs["NotesSimilarSentences"]) {
        this.$refs["NotesSimilarSentences"].findSimilar(note)
      }
      this.onClickSource(note, this.offsetByPage(index))
      this.expandedSimilar = true
    },
    offsetByPage(index) {
      return (this.pagination.page - 1) * this.pagination.limit + index
    },
    createHighlightedString(sourceText, start, end) {
      // Manually to entire theme don't have an end, so to prevent the last character from being dropped, doing this:
      let ending = sourceText.slice(end, -1) // default
      if (end == 0) {
        ending = sourceText // when manually selected
      }
      return (
        sourceText.slice(0, start) +
        "<mark>" +
        sourceText.slice(start, end) +
        "</mark>" +
        ending +
        "<br/>"
      )
    },
    forceClose() {
      if (this.showDialog) {
        this.showDialog = false
      }
      this.selectedNoteIndex = -1
      this.sourceHighlightString = ""
    },
    onClickOpen() {
      if (this.notes.length > 0) this.showDialog = true
    },
    onClickNextPage() {
      if (this.pagination.page === this.pagination.totalPages) return
      this.pagination.page = this.pagination.page + 1
      this.setPaginationDetails(
        this.pagination.startIndx + this.pagination.limit,
        this.pagination.endIndx + this.pagination.limit
      )
      this.selectedNoteIndex = -1
      this.sourceHighlightString = ""
    },
    onClickPrevPage() {
      if (this.pagination.page === 1) return
      this.pagination.page = this.pagination.page - 1
      this.setPaginationDetails(
        this.pagination.startIndx - this.pagination.limit,
        this.pagination.endIndx - this.pagination.limit
      )
      this.selectedNoteIndex = -1
      this.sourceHighlightString = ""
    },
    getResponseText(responseId) {
      if (!responseId || !this.allResponses) {
        return ""
      }
      for (let x = 0; x < this.allResponses.length; x++) {
        if (this.allResponses[x].id == responseId) {
          return this.allResponses[x].response
        }
      }
    },
    onClickSource(item, index = null) {
      if (this.selectedNoteIndex == -1) {
        if (index > -1 && index < this.notes.length) {
          this.selectedNoteIndex = index
        }
        if (!item.source) {
          this.sourceHighlightString = ""
          return
        }
        this.sourceHighlightString = this.createHighlightedString(
          this.getResponseText(item.responseId),
          item.startIndex,
          item.startIndex + item.content.length
        )
      } else {
        this.selectedNoteIndex = -1
        this.sourceHighlightString = ""
        if (this.$refs["NotesSimilarSentences"]) {
          this.$refs["NotesSimilarSentences"].clearSimilar()
        }
        this.expandedSimilar = false
        return
      }
    },
    setPaginationDetails(start, end) {
      this.pagination.startIndx = start
      this.pagination.endIndx = end
      this.visibleNotes = this.notes.slice(start, end)
    },
    setup() {
      this.pagination.totalPages = Math.ceil(
        this.notes.length / this.pagination.limit
      )
      this.setPaginationDetails(0, this.pagination.limit)
      this.sourceHighlightString = ""
    }
  },
  watch: {
    notes: function (val) {
      if (val) this.setup()
    },
    showDialog: function (val) {
      if (!val) {
        this.expandedSimilar = false
        this.selectedNoteIndex = -1
        this.sourceHighlightString = ""
      }
    }
  }
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 60vw;
  width: calc(100% * 3);
  left: 10%;
}
</style>
