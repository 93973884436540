import { SECTION_TRANSLATIONS } from "../../../translations.js"
import { formatBenchmarkSummaryTableData } from "./helpers.js"

const header = (lang = "en") => {
  const benchmarkSummaryIconSrc = "/files/ReportIcons/benchmarkSummary.svg"
  let headingContent = SECTION_TRANSLATIONS.benchmarkHeading[lang]
  let benchmarkSummaryHeading = `<img src="${benchmarkSummaryIconSrc}" class="report__header-icon" alt=""/>\
  ${headingContent}`
  return {
    type: "heading",
    content: benchmarkSummaryHeading,
    id: "section-title-benchmark-summary",
    meta: {
      headingLevel: "h1",
      pdfConfig: {
        style: "heading-h1"
      }
    }
  }
}

const subHeader = (dateParam, otherFilters) => {
  let headingContent = ""
  if (dateParam && !otherFilters.length) {
    headingContent = "Dropped data older than " + dateParam
  } else if (!dateParam && otherFilters && otherFilters.length) {
    headingContent = "Filtered by those who answered "
    for (let i = 0; i < otherFilters.length; i++) {
      if (i > 0) {
        headingContent = headingContent + " and "
      }
      if (otherFilters[i].valuesForComparison) {
        headingContent = headingContent + otherFilters[i].valuesForComparison
      }
      headingContent =
        headingContent + " for the question " + otherFilters[i].question_title
    }
  } else if (dateParam && otherFilters && otherFilters.length) {
    headingContent = "Filtered by those who answered "
    for (let i = 0; i < otherFilters.length; i++) {
      if (i > 0) {
        headingContent = headingContent + " and "
      }
      if (otherFilters[i].valuesForComparison) {
        headingContent = headingContent + otherFilters[i].valuesForComparison
      }
      headingContent =
        headingContent + " for the question " + otherFilters[i].question_title
    }
    headingContent =
      headingContent + " and dropped data older than " + dateParam
  }

  return {
    type: "heading",
    content: headingContent,
    id: "section-subtitle-benchmark-summary",
    meta: {
      headingLevel: "h2",
      pdfConfig: {
        style: "heading-h2"
      }
    }
  }
}

const summary = (overallSummary) => {
  return {
    type: "text",
    content: overallSummary,
    id: "benchmark-summary-overall"
  }
}

const table = (summaryResultsTable, summaryTableColNames, lang = "en") => {
  return {
    type: "table",
    content: {
      data: formatBenchmarkSummaryTableData(
        summaryResultsTable,
        summaryTableColNames,
        lang
      )
    },
    meta: {
      caption: SECTION_TRANSLATIONS.benchmarkCaption[lang],
      fixedColumnsIndx: [0, 1, 2], // repeats these colummns when tables overflow in PDFs
      lang: lang,
      pdfConfig: {
        widths: ["auto", "auto", "*", 95, 95, 95, 95]
      }
    },
    id: "benchmark-summary-table"
  }
}

export { header, subHeader, summary, table }
