<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1 class="library__h1">
      <VisibleText>
        Using drivers analysis to predict how to improve key outcomes
      </VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText> How to run drivers analysis in ResearchAI </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--what-are-drivers')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>What is drivers analysis?</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--what-can-we-predict')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>What can we predict?</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--running-your-own')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Running your own drivers analysis</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--interpretting')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Interpretting drivers analysis results</VisibleText>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click="goto('goto--saving')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText>Saving drivers analysis results</VisibleText>
        </a>
      </li>
    </ul>
    <h2 class="library__h2" ref="goto--what-are-drivers">
      <VisibleText>What is drivers analysis?</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        Drivers analysis involves using statistical techniques such as
        <a
          href="https://en.wikipedia.org/wiki/Regression_analysis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VisibleText>regression analysis</VisibleText></a
        >
        to produce a list of the top most important attributes that are
        'predicting' an important outcome.
      </VisibleText>
    </p>

    <p>
      <VisibleText>
        For example, consider the below graph. This shows 9 "top drivers of
        overall satisfaction". This means of all specific dimensions of a
        service experience, customers experience with "informed of what they
        needed to do" was the single most important attribute for driving
        satisfaction. 0.15 implies relative to all other attributes, this
        explains 15% of customer satisfaction.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--50"
      src="/assets/userguide__drivers.png"
      alt="screenshot of the first chart from a drivers analysis"
    />

    <h2 class="library__h2" ref="goto--what-can-we-predict">
      <VisibleText>What can we predict?</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >The tool may automatically find common outcome questions like 'Overall
        satisfaction' to run predictions. This is also called a 'dependent
        variable'. If it cannot find one, add your own. You can predict what
        drives any scale question. Consider the following list of the most
        common business/organizational outcomes that you can predict:
      </VisibleText>
    </p>
    <ul class="library__list">
      <li><VisibleText>Overall satisfaction</VisibleText></li>
      <li>
        <VisibleText>Willingness to recommend (Net Promoter Score)</VisibleText>
      </li>
      <li><VisibleText>Willingness to buy</VisibleText></li>
      <li>
        <VisibleText>
          Indexes which combine scores together, such as employee engagement
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2" ref="goto--running-your-own">
      Running your own analysis
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >ResearchAI makes it easy to conduct your own prediction against almost
        any question. To create a new analysis, click "Add new drivers
        analysis".
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide_add-new-drivers.png"
      alt="screenshot of adding new drivers view"
    />
    <p>
      <VisibleText>
        A new window will appear that will give you instructions. To conduct
        this analysis, you must have at least 1 "dependent variable" set (such
        as key business outcomes you want to predict). Additionally, you must
        also select a range of questions that may be "predictors" of this
        outcome, also called "independent variables". The tool should give you
        guidance on what you can add and what kind of regression analysis this
        enables. Add as many questions as you feel appropriate. Alternatively
        you can click "get suggested variables". This may or may not work
        depending on your data.
      </VisibleText>
    </p>

    <img
      class="screenshot"
      src="/assets/userguide_add-new-analysis-modal.png"
      alt="screenshot of new drivers instructions"
    />

    <p>
      <VisibleText>
        When you are ready to see the results, click "Save variables". This will
        create a new box below that will showcase the results, after loading.
      </VisibleText>
    </p>

    <h2 class="library__h2" ref="goto--interpretting">
      <VisibleText>Interpreting results</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        This will create a chart that shows the most important drivers of your
        outcome. The numbers represent how much of an impact they have, where 1
        is full impact, and therefore fully important, and where 0 is zero
        impact, or therefore will not drive improvements in your outcome. The
        higher the importance, the more a change in it either up or down will
        lead to a change in your outcome variable, up or down.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In this example, 'being informed of what I needed to do' drives 15% of
        overall satisfaction.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--50"
      src="/assets/userguide__drivers.png"
      alt="screenshot of the first chart from a drivers analysis"
    />
    <p>
      <VisibleText>
        There will also be a priority matrix. This will create a quadrant called
        ‘area to improve'. This means the item predicts increases or decreases
        in your outcome variable, but also that your performance on this score
        is low.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        In the below example, 'being informed of what I needed to do' is not
        only driving satisfaction, but the performance is less than half of
        average performance across all measures. You should focus on improving
        this if you want to increase satisfaction
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Sometimes you will not see a score in the 'improve' category. You may
        see scores in the 'protect' category, which means while they are
        important drivers, you are already doing well. You should protect your
        performance because dropping in this score will decrease satisfaction
        faster than other scores.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide__prioritymatrix.png"
      alt="screenshot of the second chart from a drivers analysis"
    />
    <h2 class="library__h2" ref="goto--saving">
      <VisibleText>Saving drivers analysis</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >Results should come in both graph and table format, allowing you to
        copy results into your preferred reporting tools. Alternatively, you can
        export to a shareable link or .pdf using ResearchAI's reporting tool. To
        do this, give a name to the analysis where it says "Please name this
        slide", and then click "Save to report".
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide_save-drivers.png"
      alt="screenshot of completed drivers widget"
    />
    <p>
      <VisibleText>
        After saving has completed, you should see a new table of contents, and
        you can now click the "Report" tab.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_click-report.png"
      alt="screenshot of analysis and report tab"
    />
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "drivers_analysis",
  setup() {
    const siteData = reactive({
      title: "Conducting drivers analysis in ResearchAI",
      description:
        "How to predict outcomes like satisfaction, engagement, expectations, purchase intent, or willingness to recommend"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
