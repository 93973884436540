<template>
  <Modal
    id="share-dataset"
    ref="share-modal"
    uid="share-dataset-modal"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <Spinner id="share-spinner" :component-style="true" :isLoading="isSaving" />

    <template #header>
      {{ currViewIdx == 0 ? "Share Dataset" : "Manage Permissions" }}
    </template>

    <div style="display: flex; flex-direction: row; height: 2rem">
      <h3 style="font-size: 1rem">
        <VisibleText>
          You can share directly to users if you know their email:
        </VisibleText>
      </h3>
      <div style="display: flex; flex-direction: row; margin-left: auto">
        <button
          v-if="modalGroups && currViewIdx == modalGroups.length - 1"
          class="share__next-btn"
          style="margin-left: auto; height: 1.5rem; margin-right: 2rem"
          @click.stop="back"
        >
          <VisibleText>Back</VisibleText>
        </button>
        <button
          class="share__next-btn"
          style="margin-left: auto; height: 1.5rem"
          @click="goToView(1)"
          v-if="modalGroups && currViewIdx == 0"
        >
          <VisibleText>Next: User Permissions</VisibleText>
        </button>
        <button
          class="share__save-changes-btn"
          :aria-disabled="isSaveBtnDisabled"
          :disabled="isSaveBtnDisabled"
          style="margin-left: auto; height: 1.5rem"
          @click="saveChanges(true)"
          v-if="modalGroups && currViewIdx == modalGroups.length - 1"
        >
          <VisibleText>Save changes</VisibleText>
        </button>
      </div>
    </div>

    <ModalGroupLayout
      :showBackBtn="false"
      @back="back"
      @changed="unsavedShareChanges = true"
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 1rem;
      "
    >
      <Sharing
        :label="'Add people to start sharing your dataset.'"
        :shared="users"
        ref="sharing"
        style="display: flex"
        @addNewUser="addNewUser($event)"
      />
    </ModalGroupLayout>

    <ModalGroupLayout @back="back" @changed="unsavedShareChanges = true">
      <ManagePermissions
        :shared="usersShared"
        :types="SHARING_KEYS"
        :perm-labels="DEFAULT_PERMS_LABELS"
      />
    </ModalGroupLayout>

    <template #footer>
      <div style="display: flex; flex-direction: column; width: 100%">
        <div v-if="teams && teams.length && localDataset">
          <h3>
            <VisibleText style="font-size: 1rem">
              Or you can change the dataset's access level:
            </VisibleText>
          </h3>
          <div style="display: flex; flex-direction: row">
            <select
              aria-label="access level"
              class="select"
              id="select-access-level"
              v-model="localDataset.access_type"
              style="width: 50%; margin-top: 1rem"
              @change="changeAccessLevel"
            >
              <option
                v-for="level in Object.keys(ACCESS_TYPE_TO_DESC)"
                :key="level"
                :value="level"
                :data-parent="$options.name"
              >
                {{ translate(ACCESS_TYPE_TO_DESC[level].name, $options.name) }}
                -
                {{ translate(ACCESS_TYPE_TO_DESC[level].desc, $options.name) }}
              </option>
            </select>
            <span
              style="
                color: var(--secondary);
                margin-left: 1rem;
                margin-top: 1.25rem;
              "
              v-if="successfullyUpdated"
            >
              <VisibleText> Successfully updated! </VisibleText>
            </span>
          </div>

          <div
            style="margin-top: 1rem"
            v-if="localDataset.access_type == 'public__team'"
          >
            <VisibleText> For this team: </VisibleText>
            <select
              aria-label="access level"
              class="select"
              id="select-access-level-team"
              style="width: 50%; margin-top: 1rem"
              v-model="selectedTeam"
              @change="changeTeamAccessLevel"
            >
              <option
                v-for="team in teams"
                :key="team.team_name"
                :value="team"
                :data-parent="$options.name"
              >
                {{ team.team_name }}
              </option>
            </select>
          </div>
          <div
            v-if="
              localDataset.access_type == 'public__team' ||
              localDataset.access_type == 'public__all'
            "
            style="display: flex; flex-direction: row"
          >
            <label
              for="set-to-readonly"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Is read only </VisibleText>:
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="set-to-readonly"
              id="set-to-readonly"
              @change="changeTeamAccessLevel"
              v-model="readOnlyProject"
              style="width: 1.3rem; height: 1.3rem; margin-top: 1rem"
            />
          </div>
        </div>
        <div style="display: flex; flex-direction: row; width: 100%">
          <div class="share-copy-url" style="margin-left: auto">
            <a
              role="button"
              ref="copy-url"
              class="share__copy-url-btn"
              @click="copy"
            >
              <VisibleText>Copy link</VisibleText>
            </a>
            <input
              aria-label="share url"
              type="text"
              id="share-url"
              class="share__copy-url"
              readonly
              :value="shareUrl"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import ModalGroupLayout from "./Base/ModalGroupLayout.vue"
import Sharing from "./Base/Sharing.vue"
import ManagePermissions from "./Base/ManagePermissions.vue"
import Spinner from "@/components/UI/Spinner.vue"

// Mixins
import ShareMixin from "./Mixins/shareMixin.js"
import DatasetMixin from "@/components/Dataset/Mixins/datasetWizardMixin.js"

export default {
  name: "ShareDataset",
  mixins: [ShareMixin, DatasetMixin],
  components: {
    Modal,
    ModalGroupLayout,
    Sharing,
    ManagePermissions,
    Spinner
  },
  data() {
    return {
      selectedTeam: {},
      localDataset: {},
      readOnlyProject: false,
      successfullyUpdated: false,
      ACCESS_TYPE_TO_DESC: {
        private: {
          name: "Private",
          desc: "only you can use this dataset"
        },
        public__all: {
          name: "Public",
          desc: "anyone can use this dataset"
        },
        public__team: {
          name: "Teams",
          desc: "only users in your team can use this dataset"
        }
      }
    }
  },
  props: {
    parentDataset: {
      default: () => null,
      type: Object
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    DEFAULT_PERMS() {
      return {
        dataset: ["read"]
      }
    },
    DEFAULT_PERMS_LABELS() {
      return {
        dataset: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ]
      }
    },
    SHARING_PK() {
      return "dataset"
    },
    shareUrl() {
      if (!this.localDataset) return
      const domain = `${window.location.protocol}//${window.location.hostname}`
      const port = window.location.port ? `:${window.location.port}` : ""
      const path = `/dataset/${this.localDataset["dataset_id"]}?project_id=${this.localDataset.id}`
      return `${domain}${port}${path}`
    },
    matchingTeam() {
      if (
        this.localDataset &&
        this.localDataset.owning_team_id &&
        this.localDataset.access_type == "public__team"
      ) {
        if (this.teams && this.teams.length) {
          let filteredTheme = this.teams.filter(
            (team) => team._id.$oid == this.localDataset.owning_team_id
          )
          if (filteredTheme.length) {
            return filteredTheme[0]
          }
        }
      }
      return false
    }
  },
  methods: {
    async changeAccessLevel() {
      if (this.localDataset.access_type != "public__team") {
        this.localDataset.owning_team_id = ""
        this.localDataset.is_read_only = this.readOnlyProject
        await this.saveDatasetProject(this.localDataset)
        this.$emit("refreshDatasets")
        this.selectedTeam = {}
        this.successfullyUpdated = true
        setTimeout(() => (this.successfullyUpdated = false), 2000)
      }
    },
    changeTeamAccessLevel() {
      if (
        this.localDataset.access_type == "public__team" &&
        this.selectedTeam
      ) {
        // If it does not exist, we need to add
        this.localDataset.owning_team_id = this.selectedTeam._id.$oid
        this.localDataset.is_read_only = this.readOnlyProject
        this.saveDatasetProject(this.localDataset)
        this.$emit("refreshDatasets")
        this.successfullyUpdated = true
        setTimeout(() => (this.successfullyUpdated = false), 2000)
      }
      if (this.localDataset.access_type == "public__all") {
        this.localDataset.owning_team_id = ""
        this.localDataset.is_read_only = this.readOnlyProject
        this.saveDatasetProject(this.localDataset)
        this.$emit("refreshDatasets")
        this.successfullyUpdated = true
        setTimeout(() => (this.successfullyUpdated = false), 2000)
      }
    }
  },
  watch: {
    parentDataset: function () {
      this.mount()
      this.localDataset = this.parentDataset
      if (this.localDataset.is_read_only) {
        this.readOnlyProject = this.localDataset.is_read_only
      }
    },
    matchingTeam: function (val) {
      if (val) {
        this.selectedTeam = val
      }
    },
    selectedTeam: function (val, oldVal) {
      if (val && Object.keys(oldVal).length) {
        if (this.localDataset.access_type == "public__team") {
          this.changeTeamAccessLevel()
        }
      }
    }
  }
}
</script>
