<template>
  <span>
    <!--------------------------------------
     | read only
    ---------------------------------------->
    <template v-if="readOnly">
      {{ $data.$_themeName }}
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-else>
      <label class="form-label">
        <input type="text" tabindex="-1" v-model="$data.$_themeName" />
      </label>
    </template>
  </span>
</template>

<script>
import _ from "lodash"

// Services
import customTopicModelService from "@/services/customTopicModelService.js"

export default {
  name: "ThemeName",
  props: {
    readOnly: {
      default: true,
      type: Boolean
    },
    themeName: {
      type: String,
      required: true
    },
    oid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      $_themeName: this.themeName
    }
  },
  created() {
    this.saveText = _.debounce(this.saveText, 500)
  },
  methods: {
    async saveText() {
      try {
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(this.oid, {
          name: this.$data.$_themeName
        })
      } catch (e) {
        throw new Error("ThemenName:saveText " + e.message)
      }
    }
  },
  watch: {
    "$data.$_themeName": function () {
      if (this.readOnly) return
      this.saveText()
    }
  }
}
</script>
