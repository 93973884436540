<template>
  <div class="performance__chart-wrapper">
    <component
      :is="type"
      :value="chartNode"
      v-if="chartNode"
      @deleteChart="$emit('deleteChart')"
    />
  </div>
</template>

<script>
// Components
import { VerticalBarChart, HorizontalBarChart } from "@pigeonline/pigeondoc"

export default {
  name: "PerformanceChart",
  components: {
    VerticalBarChart,
    HorizontalBarChart
  },
  props: {
    chartNode: { type: Object },
    type: { type: String },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
