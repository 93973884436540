<template>
  <span>
    <!--------------------------------------
     | read only
    ---------------------------------------->
    <template v-if="readOnly">
      <span>
        {{ questionText }}
      </span>
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-else>
      <div class="dataset-table__edit-wrapper">
        <label class="sr-only" :for="`edit-question-text-${clientQuestionId}`">
          <VisibleText>edit question text</VisibleText>
        </label>
        <textarea
          tabindex="-1"
          class="dataset-table__edit-textarea"
          rows="5"
          cols="18"
          name="question-text"
          :id="`edit-question-text-${clientQuestionId}`"
          v-model="text"
        ></textarea>
        <VisibleText class="dataset-table__edit-status">
          {{ savingStatus }}
        </VisibleText>
      </div>
    </template>
  </span>
</template>

<script>
import _ from "lodash"

export default {
  name: "QuestionText",
  props: {
    clientQuestionId: {
      default: () => {},
      type: String,
      required: true
    },
    questionText: {
      type: String
    },
    readOnly: {
      default: () => true,
      type: Boolean
    }
  },
  data() {
    return {
      isSaving: false,
      isSaved: false,
      text: ""
    }
  },
  created() {
    this.saveText = _.debounce(this.saveText, 1000)
  },
  computed: {
    savingStatus() {
      if (this.isSaving) return "saving..."
      if (this.isSaved) return "saved"
      return ""
    }
  },
  methods: {
    async saveText() {
      try {
        const questionText = { question_text: this.text }
        const updatedQ =
          await this.$services.DATASETS_SERVICE.updateClientQuestion(
            this.clientQuestionId,
            questionText
          )
        this.$store.dispatch(
          "datasetDetails/updateClientQuestion",
          updatedQ.client_question_object
        )
        this.isSaving = false
        this.isSaved = true

        // update dataset project status
        const datasetProject =
          this.$store.getters["datasetWizard/getDatasetProject"]
        if (datasetProject.no_codebook_matches) {
          datasetProject.no_codebook_matches = false
          const updatedProject =
            await this.$pigeonline.projects.update(datasetProject)
          this.$store.dispatch(
            "datasetWizard/setDatasetProject",
            updatedProject
          )
        }
      } catch (e) {
        throw new Error("QuestionText " + e.message)
      }

      setTimeout(() => {
        this.isSaved = false
      }, 6000)
    }
  },
  watch: {
    questionText: {
      immediate: true,
      handler(val) {
        if (val.length > 0) this.text = val
      }
    },
    text: function () {
      if (this.text === this.questionText) return
      if (this.readOnly) return
      this.isSaving = true
      this.saveText()
    }
  }
}
</script>
