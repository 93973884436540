<template>
  <div class="projects__nav">
    <LinkIconOnly
      v-if="project.status.reportCreated"
      class="projects__nav-item"
      icon="report"
      :href="`/project/${project.id}/report/${project.report || 'empty'}`"
      :id="`${project.id}-report`"
    >
      <VisibleText>View</VisibleText> {{ project.name }}
      <VisibleText> report</VisibleText>
    </LinkIconOnly>

    <LinkIconOnly
      class="projects__nav-item"
      :href="lastOpenedTab"
      :id="`${project.id}-edit`"
      icon="edit"
    >
      <span><VisibleText>Edit </VisibleText>{{ project.name }}</span>
    </LinkIconOnly>

    <LinkIconOnly
      class="projects__nav-item"
      icon="share"
      :href="`/project/${project.id}?share=true`"
      :id="`${project.id}-share`"
      v-if="isOwner"
    >
      <VisibleText>Share </VisibleText>{{ project.name }}
    </LinkIconOnly>

    <ButtonIconOnly
      class="projects__nav-item"
      icon="deleteIcon"
      style="padding-top: 0.25rem"
      :id="`${project.id}-del`"
      @click-handler="confirmDelete"
      v-if="isOwner"
    >
      <VisibleText>Delete </VisibleText>{{ project.name }}
    </ButtonIconOnly>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import LinkIconOnly from "@/components/UI/Link/LinkIconOnly.vue"
import { PigeonDocCollectionModel, PigeonDocModel } from "@pigeonline/pigeondoc"
import { fetchProject } from "@/components/Project/Utils/fetch.js"
import TextService from "@/services/textService.js"

export default {
  name: "ProjectsItemNav",
  components: {
    ButtonIconOnly,
    LinkIconOnly
  },
  data() {
    return {
      TEXT_SERVICE: new TextService(this.$pigeonline)
    }
  },
  props: {
    project: { type: Object }
  },
  computed: {
    confirmStatus() {
      return this.$store.getters["confirm/getStatus"]
    },
    datasetsParams() {
      return this.project.datasets ? `id: ${this.project.datasets[0]}` : ""
    },
    isOwner() {
      return (
        this.project.owner ==
        this.$store.getters["user/getProfile"].django_ref_id
      )
    },
    lastOpenedTab() {
      if (this.project.settings) {
        if (this.project.settings.lastOpenedTab) {
          return (
            "/project/" +
            this.project.id +
            "/analysis/" +
            this.project.settings.lastOpenedTab
          )
        }
      }
      return "/project/" + this.project.id
    }
  },
  methods: {
    confirmDelete() {
      this.$store.dispatch("confirm/setConfirmTarget", this.project.id)
      this.$store.dispatch("confirm/setConfirmIsVisible", true)
      this.$store.dispatch("confirm/setConfirmText", {
        btn: "Delete Project",
        message: "This will also delete your report.",
        title: "Delete project?"
      })
      this.$store.dispatch("confirm/setConfirmType", "delete")
      this.$store.dispatch("confirm/setConfirmSourceComponent", "projects")
    },
    async deleteProject() {
      try {
        // Check for related themes inside any textAnalysisObjects to prevent zombie themes
        if (this.project.textAnalysisObjects > 0) {
          const fullProject = await fetchProject(this.project.id)

          if (
            fullProject.textAnalysisObjects &&
            fullProject.textAnalysisObjects.length
          ) {
            for (let a = 0; a < fullProject.textAnalysisObjects.length; a++) {
              let all_themes = []
              let payload = {
                text_analysis_uuid: fullProject.textAnalysisObjects[a].uuid
              }
              const fetchedThemes =
                await this.TEXT_SERVICE.fetchTextThemes(payload)
              for (let i = 0; i < fetchedThemes.length; i++) {
                all_themes.push(fetchedThemes[i]._id.$oid)
              }
              if (all_themes.length) {
                let payload = {
                  _ids: all_themes,
                  text_analysis_uuid: fullProject.textAnalysisObjects[a].uuid
                }
                await this.TEXT_SERVICE.deleteTextThemes(payload)
              }
            }
          }
        }

        if (this.project.collection_id) {
          let collection = await this.$pigeonline.projects.getByID(
            PigeonDocCollectionModel,
            this.project.collection_id
          )
          if (collection && collection.id) {
            if (collection.docIds) {
              for (let i = 0; i < collection.docIds.length; i++) {
                let collection_report = await this.$pigeonline.projects.getByID(
                  PigeonDocModel,
                  collection.docIds[i]
                )
                if (collection_report && collection_report.id) {
                  await this.$pigeonline.projects.delete(collection.docIds[i])
                }
              }
            }
            await this.$pigeonline.projects.delete(this.project.collection_id)
          }
        }
        if (this.project.report) {
          let report = await this.$pigeonline.projects.getByID(
            BenchmarkingProject,
            this.project.report
          )
          if (report && report.id) {
            await this.$pigeonline.projects.delete(this.project.report)
          }
        }

        await this.$pigeonline.projects.delete(this.project.id)
        // TODO: reload only the past projects component
        this.$router.go()
      } catch (e) {
        throw new Error("ProjectList:del " + e.message)
      }
    }
  },
  watch: {
    confirmStatus: async function (val) {
      const targetProject = this.$store.getters["confirm/getTarget"]
      const sourceComponent = this.$store.getters["confirm/getSourceComponent"]
      if (
        val &&
        targetProject === this.project.id &&
        sourceComponent === "projects"
      ) {
        await this.deleteProject()
      }
    }
  }
}
</script>
