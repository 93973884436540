<template>
  <span class="icon-only-link tooltip">
    <a
      class="tooltip__hoverable center-align-parent"
      :href="href"
      :aria-labelledby="`${id}-label`"
    >
      <SvgIcon
        class="center-align-child"
        :aria-hidden="true"
        :focusable="false"
        :icon="icon"
      />
    </a>
    <span
      class="tooltip__text tooltip__text--compact dark bottom no-arrow hoverable icon-only-link-label"
      role="tooltip"
      :id="`${id}-label`"
    >
      <slot></slot>
    </span>
  </span>
</template>

<script>
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "LinkIconOnly",
  components: {
    SvgIcon
  },
  props: {
    href: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>
