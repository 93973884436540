<template>
  <Modal
    class="drivers__variables"
    :show="isNewTextModalVisible"
    @close="closeThisModal"
    :widerFormat="true"
  >
    <template #header>
      <VisibleText>
        {{ textAnalysisTitle }}
      </VisibleText>
    </template>
    <h2>Instructions</h2>
    <ol class="drivers__ordered-list">
      <li>
        <VisibleText
          >Text analysis only accepts "OPEN_ENDED" questions. This could be any
          table or excel file with text inside rows within a single
          column.</VisibleText
        >.
      </li>
    </ol>
    <!--------------------------------------
    | Text Questions
    ---------------------------------------->
    <SelectQuestionsList
      :toEmitUpdate="true"
      :questions="textQuestionTableQs"
      :selected-ids="selectedTextQuestionIds"
      :hide-toolbar="true"
      @update-selected-question-ids="addTextToList"
      v-if="textQuestionTableQs.length > 0"
    />
    <p class="error-msg__inline" v-else>
      <VisibleText>Please select a text question.</VisibleText>
    </p>

    <VisibleText v-if="textQuestionTableQs.length == 0">
      No scale, category or numeric questions found.
    </VisibleText>

    <template #footer>
      <div style="display: flex; width: 100%">
        <div style="display: flex; width: 20%; align-items: center">
          <button
            class="btn-default"
            style="height: 2em"
            @click="onClickSaveTextQuestion"
          >
            <VisibleText>Save Text Question</VisibleText>
          </button>

          <MessageInline :message="message.text" :message-type="message.type" />
        </div>
        <div style="display: flex; width: 80%">
          <button
            class="btn-cancel"
            style="margin-left: auto"
            @click="filterMode = true"
          >
            <div v-if="filterParams">
              <VisibleText>Edit filters</VisibleText>
            </div>
            <div v-else>
              <VisibleText>Add filter to analysis</VisibleText>
            </div>
          </button>
          <FilterPills
            v-if="isFiltered"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="filterParams"
            @clearFilters="clearFilters"
          >
          </FilterPills>
        </div>
      </div>
      <AddFilter
        :show="filterMode"
        :type="'own'"
        :data="allQuestions"
        @close-modal="filterMode = false"
        @confirmNewFilter="confirmNewFilter"
      />
    </template>
  </Modal>
</template>

<script>
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import Modal from "@/components/UI/Modal.vue"
import SelectQuestionsList from "../../Components/SelectQuestionsList.vue"
import AddFilter from "@/components/Project/ProjectAnalysis/Components/AddFilter.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

export default {
  name: "NewTextAnalysisModal",
  components: {
    MessageInline,
    Modal,
    SelectQuestionsList,
    AddFilter,
    FilterPills
  },
  props: {
    textAnalysisTitle: {
      default: () => "Create Text Analysis",
      type: String
    },
    // pre-selected dependent question ids
    textQuestions: {
      default: () => [],
      type: Array
    },
    isNewTextModalVisible: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      message: {
        text: "",
        type: ""
      },
      selectedTextQuestionIds: [],
      validTypes: ["OPEN_ENDED"],
      filterMode: false,
      currentFilters: [],
      filterParams: {}
    }
  },
  mounted() {
    // this.setData()
  },
  computed: {
    //local copy of client questions with just dependent variable
    textQuestionTableQs() {
      return this.filteredQuestions.textQuestions
    },
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    filteredQuestions() {
      return this.$store.getters["datasetDetails/getClientQuestions"].reduce(
        (allqs, q) => {
          if (!this.isInvalid(q)) allqs.textQuestions.push(q)
          return allqs
        },
        { textQuestions: [] }
      )
    },
    formatedFilters() {
      if (this.filterParams) {
        if (this.filterParams !== {}) {
          let array = this.filterParams
          let newObjects = []
          array = array.split(" or ")
          array.push(array.splice(array.length - 1).join(" or "))
          for (let i = 0; i < array.length; i++) {
            let newArray = array[i]
            newArray = newArray.split("==")
            newArray.push(newArray.splice(newArray.length - 1).join("=="))

            const questionResult = this.$store.getters[
              "datasetDetails/getClientQuestions"
            ].find((q) => q._id.$oid === newArray[0])

            // value: newArray[1],
            let newObject = {
              question: questionResult.question_title,
              oid: questionResult._id.$oid,
              values: [newArray[1]],
              content: ""
            }
            if (newObjects.length) {
              let found = false
              for (let x = 0; x < newObjects.length; x++) {
                if (newObjects[x].oid === questionResult._id.$oid) {
                  newObjects[x].values.push(newArray[1])
                  found = true
                }
              }
              if (!found) {
                newObjects.push(newObject)
              }
            } else {
              newObjects.push(newObject)
            }
          }
          if (newObjects.length) {
            let content = ""
            for (let x = 0; x < newObjects.length; x++) {
              content = newObjects[x].question + " - ["
              for (let i = 0; i < newObjects[x].values.length; i++) {
                content = content + newObjects[x].values[i]
                if (i !== newObjects[x].values.length - 1) {
                  content = content + ", "
                }
              }
              content = content + "]"
              newObjects[x].content = content
            }
          }
          return newObjects
        }
      }
      return []
    },
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    closeThisModal() {
      this.$emit("close-text-analysis")
      this.selectedTextQuestionIds = []
      ;(this.filterMode = false), (this.currentFilters = [])
      this.filterParams = {}
    },
    isInvalid(item) {
      return (
        !item.approved_data_type ||
        !this.validTypes.includes(item.approved_data_type)
      )
    },
    /************
     *
     * on click handlers
     *
     ************/
    onClickSaveTextQuestion() {
      // reset messages
      this.message = {
        text: "",
        type: ""
      }
      if (this.validate()) return
      this.saveTextQuestion()
      this.$emit("close-text-analysis")
      this.selectedTextQuestionIds = []
      this.filterMode = false
      this.currentFilters = []
      this.filterParams = {}
    },
    /************
     *
     * setters
     *
     ************/

    setTextQuestion(questionIds) {
      this.selectedTextQuestionIds.push(questionIds)
    },

    setData() {
      const setupTextQuestions = () => {
        if (Array.isArray(this.textQuestions))
          this.selectedTextQuestionIds = [...this.textQuestions]
      }

      // set "new" question as the pre-selected questions
      if (this.textQuestions.length > 0) setupTextQuestions()
      else this.selectedTextQuestionIds = []
    },
    /************
     *
     * saving and fetching
     *
     ************/
    saveVariables() {
      this.$emit("modify-vars", this.selectedTextQuestionIds, this.filterParams)
      if (this.newDriver) this.setData()
    },
    saveTextQuestion() {
      if (this.selectedTextQuestionIds.length) {
        this.$emit(
          "create-analysis",
          this.selectedTextQuestionIds[0],
          this.filterParams
        )
        // this.setData()
      }
    },
    validate() {
      if (this.selectedTextQuestionIds.length === 0) {
        this.message.text = "Please select a text question."
        this.message.type = "error"
      }
      return this.message.text.length > 0
    },
    resetSearch() {
      this.currentFilters = []
    },
    confirmSelection(question) {
      if (!question) {
        this.currentFilters = []
      }
      if (question.length) {
        for (let x = 0; x < question.length; x++) {
          let questionId = ""
          let theseQuestions = []
          if (question[x]) {
            questionId = question[x][0]
            if (question[x][2].length) {
              for (let i = 0; i < question[x][2].length; i++) {
                let thisParam = questionId + "==" + question[x][2][i]
                if (thisParam) {
                  theseQuestions.push(thisParam)
                }
              }
              if (theseQuestions.length) {
                let parsedQuestions = ""
                for (let i = 0; i < theseQuestions.length; i++) {
                  if (i === 0) {
                    parsedQuestions = theseQuestions[i]
                  } else {
                    parsedQuestions =
                      parsedQuestions + " or " + theseQuestions[i]
                  }
                }
                this.filterParams = parsedQuestions
              }
            }
          }
        }
        this.currentFilters.push(question)
      }
    },
    addTextToList(questionIds) {
      let useThisId = questionIds[questionIds.length - 1]
      this.selectedTextQuestionIds = []
      this.selectedTextQuestionIds.push(useThisId)
    },
    confirmNewFilter(selected) {
      if (selected) {
        this.filterParams = selected
      }
    },
    clearFilters() {
      this.filterMode = false
      this.filterParams = {}
    }
  }
}
</script>
