<template>
  <span class="dataset-table__values-li__item">
    <label
      class="form-label form-checkbox-label"
      :for="`checkbox-${uuid}`"
      :data-parent="$options.name"
    >
      <input
        type="checkbox"
        :checked="uniqueVal.is_valid"
        :id="`checkbox-${uuid}`"
        name="validity"
        @input="onToggle"
      />
      {{ translate("valid", $options.name) }}
    </label>
    <input
      class="input"
      type="text"
      :aria-describedby="ariaDescribedByIds"
      :aria-invalid="error.toString()"
      :id="uuid"
      name="value"
      v-model="modifiedValue"
    />
    <label class="label" :for="uuid">
      <slot></slot>
    </label>
    <span class="orig-value" v-if="uniqueVal.modified_value">
      <VisibleText>original value</VisibleText>: {{ uniqueVal.value }}
    </span>
  </span>
</template>

<script>
import _ from "lodash"
import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "EditValsInput",
  mixins: [UserMixin],
  props: {
    ariaDescribedByIds: {
      default: null,
      type: String
    },
    error: {
      default: () => false,
      type: Boolean
    },
    // initial unique_values item
    uniqueVal: {
      default: () => {},
      type: Object
    }
  },
  created() {
    this.updateValue = _.debounce(this.updateValue, 1000)
    this.isValid = Boolean(this.uniqueVal.is_valid)
  },
  data() {
    return {
      modifiedValue: null,
      isValid: false,
      uuid: this.$pigeonline.createUUID()
    }
  },
  methods: {
    onToggle($e) {
      this.isValid = $e.target.checked
    },
    updateValue() {
      this.$emit(
        "update-value",
        this.modifiedValue,
        this.uniqueVal.value,
        this.isValid
      )
    }
  },
  watch: {
    uniqueVal: {
      immediate: true,
      deep: true,
      handler(uniqueValue) {
        if (uniqueValue.value)
          this.modifiedValue = uniqueValue.modified_value || uniqueValue.value
      }
    },
    isValid: function () {
      this.updateValue()
    },
    modifiedValue: {
      immediate: false,
      handler() {
        if (
          this.permissions.includes("update-dataset") &&
          this.uniqueVal.value !== this.modifiedValue
        )
          this.updateValue()
      }
    }
  }
}
</script>
