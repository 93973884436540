<template>
  <div :class="['dataset-segments__tag-item', { disabled: isDisabled }]">
    <span class="dataset-segments__tag-item-title">
      <label
        class="form-label form-checkbox-label"
        :for="`${category}-${item.value}`"
      >
        <input
          style="margin-right: 0.5rem"
          type="checkbox"
          :checked="isChecked"
          :id="`${category}-${item.value}`"
          :name="item.value"
          :value="item.value"
          :disabled="isDisabled"
          @input.stop="onChange($event.target.checked)"
        />
        <strong :data-parent="$options.name">
          {{ translate(item.label || item.value, $options.name) }}
        </strong>
        <span
          class="dataset-segments__tag-item-count"
          v-if="item.value !== 'None' && segmentDetailsAvailable"
        >
          {{ segmentDetails[item.value] || 0 }}
          <VisibleText> benchmarks</VisibleText>
        </span>
      </label>
    </span>
    <!--------------------------------------------
    |
    | OPTIONS
    |
    --------------------------------------------->
    <span
      class="dataset-segments__tag-item-options"
      v-if="item.value !== 'None'"
    >
      <button
        type="button"
        :class="classObjectonClickAllOption"
        :disabled="isDisabled"
        @click="onClickAll"
      >
        <VisibleText> All respondents </VisibleText>
      </button>
      <template v-if="validQuestions.length > 0">
        <span style="color: #000">|</span>
        <button
          type="button"
          :class="classObjectonClickSomeOption"
          :disabled="isDisabled"
          @click="onClickSome"
        >
          <VisibleText> Some respondents </VisibleText>
        </button>
      </template>
    </span>
    <!--------------------------------------------
    |
    | DESCRIPTION
    |
    --------------------------------------------->
    <span
      class="dataset-segments__tag-item-desc"
      :data-parent="$options.name"
      v-if="item.desc"
    >
      {{ translate(item.desc, $options.name) }}
    </span>
    <!--------------------------------------------
    |
    | RESPONDENT DETAILS
    |
    --------------------------------------------->
    <div class="dataset-segments__tag-item-seg-details" v-if="isDetailsVisible">
      <div class="with-select">
        <VisibleText>Those who responded to</VisibleText>
        <select aria-label="select question" v-model="questionID">
          <option
            v-for="question in validQuestions"
            :key="question._id.$oid"
            :value="question._id.$oid"
          >
            {{ question.question_title }} - {{ question.question_text }}
          </option>
        </select>
      </div>
      <div class="with-select">
        <VisibleText>and answered</VisibleText>
        <select aria-label="select value" v-model="questionValue">
          <option :value="null">any value</option>
          <option
            v-for="value in selectedQuestionValues"
            :key="value"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
      </div>
      <div><VisibleText>are in this segment.</VisibleText></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "DatasetSegmentItem",
  props: {
    category: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    readOnly: {
      default: () => false,
      type: Boolean
    },
    segmentData: {
      default: () => {
        return null
      },
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isDetailsVisible: false,
      questionID: null,
      questionValue: ""
    }
  },
  computed: {
    ...mapGetters("datasetDetails", {
      clientQuestions: "getClientQuestions",
      dataset: "getDataset",
      segmentDetails: "getSegmentDetails"
    }),
    validQuestions() {
      return this.clientQuestions.filter((question) =>
        ["CAT", "SCALE_1_TO_5", "SCALE_1_TO_6", "OPEN_ENDED"].includes(
          question.approved_data_type
        )
      )
    },
    classObjectonClickAllOption() {
      return [
        "dataset-segments__tag-item-options-btn",
        this.isChecked &&
        !this.isDetailsVisible &&
        Object.keys(this.segmentData).length < 1
          ? "selected"
          : ""
      ]
    },
    classObjectonClickSomeOption() {
      return [
        "dataset-segments__tag-item-options-btn",
        this.isChecked &&
        (this.isDetailsVisible || Object.keys(this.segmentData).length > 0)
          ? "selected"
          : ""
      ]
    },
    isChecked() {
      return !!this.segmentData
    },
    isDisabled() {
      return (
        this.readOnly &&
        !this.dataset.segments[this.item.label || this.item.value]
      )
    },
    selectedQuestionValues() {
      if (this.questionID) {
        const uniqueVals = this.clientQuestions.find(
          (question) => question._id.$oid === this.questionID
        ).unique_values
        return uniqueVals.map((v) => v.value)
      }
      return []
    },
    segmentDetailsAvailable() {
      return (
        this.dataset.benchmark_group && this.dataset.benchmark_group.length > 0
      )
    }
  },
  methods: {
    onClickAll() {
      this.isDetailsVisible = false
      this.questionID = null
      this.questionValue = ""
      this.onChange(true)
    },
    onClickSome() {
      this.isDetailsVisible = true
    },
    onChange(checked) {
      if (this.readOnly) return
      this.$emit(
        "checked",
        checked,
        this.item.value,
        this.questionID,
        this.questionValue,
        this.category
      )
    }
  },
  watch: {
    segmentData: function () {
      if (this.segmentData && Object.keys(this.segmentData).length > 0) {
        this.questionID = this.segmentData.client_question_id
        this.questionValue = this.segmentData.value || null
      }
    },
    questionID: function () {
      this.onChange(true)
    },
    questionValue: function () {
      this.onChange(true)
    }
  }
}
</script>
