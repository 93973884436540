import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class ProfilesService extends BaseService {
  async getTeams(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/get-teams`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }

  async getTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/get-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }

  async makeAdmin(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/make-admin`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async makeTeamMember(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/make-team-member`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async renameTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/rename-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async removeTeamMember(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/remove-team-member`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async leaveTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/leave-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async addTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/add-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async deleteTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/delete-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async setIsDemoTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/set-is-demo-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async setIsResearchLicenseTeam(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/set-is-basic-team`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async acceptInvite(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/accept-invite`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async rejectInvite(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/reject-invite`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async getAllTeamMembers(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(
        `${URL}/get-all-team-members`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async sendInvites(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/send-invites`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
  async revokeInvite(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/rauth/teams"
      const response = await axios.post(`${URL}/revoke-invite`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("teamsService.js " + e.message)
    }
  }
}
