<template>
  <form @submit.prevent="searchOnSubmit" class="forms__search">
    <label :for="uuid" class="forms__search-label">
      <slot name="label">
        <SvgIconDecorative icon="search" />
        <span class="sr-only"> {{ translate("search", $options.name) }}</span>
      </slot>
    </label>
    <input
      type="text"
      name="search"
      class="forms__search-input visible-text__placeholder"
      v-model="query"
      @input="search"
      :placeholder="visiblePlaceholder()"
      :id="uuid"
    />
    <slot name="submit"></slot>
  </form>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import _ from "lodash"

export default {
  name: "SearchBar",
  components: {
    SvgIconDecorative
  },
  props: {
    placeholder: {
      default: () => "search",
      type: String,
      required: false
    },
    // do/dont run search on input change
    preventAutoSearch: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      query: null,
      uuid: this.$pigeonline.createUUID()
    }
  },
  created() {
    this.search = _.debounce(this.search, 500)
  },
  methods: {
    visiblePlaceholder() {
      return this.checkTranslationCache(
        this.placeholder,
        "searchBar__generic",
        this.$store.getters.getLanguage
      )
    },
    search() {
      if (!this.preventAutoSearch) this.$emit("search", this.query)
    },
    searchOnSubmit() {
      this.$emit("search", this.query)
      this.query = null
    }
  }
}
</script>
