<template>
  <button
    class="clickable-box"
    :key="'team-button' + team.name"
    style="
      font-weight: 800;
      font-family: Core-Sans;
      border-radius: 0.75rem;
      padding: 0;
    "
    :class="getTeamButtonClass(isSelected)"
    @click="$emit('changeSelectedTeam', team)"
  >
    <div style="display: flex; flex-direction: column">
      <div
        style="
          display: flex;
          margin: -1rem auto 0 auto;
          background-color: var(--primary-brand-ui-colour);
          color: white;
          width: 100%;
          border-radius: 1rem;
        "
        v-if="isInvitee"
      >
        <VisibleText style="width: 100%"> Invite! </VisibleText>
      </div>
      <div style="display: flex; flex-direction: row; padding: 0 0.5rem">
        <div style="display: flex; margin-right: 1rem">
          <img
            :src="
              isSelected
                ? '/assets/users-team-white.png'
                : '/assets/users-team-black.png'
            "
            style="width: 2rem; height: 1.3rem"
          />
        </div>
        <div style="display: flex">
          {{ capitalizeFirstLetter(team.team_name) }}
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import UserCardMixin from "@/components/UI/UserCards/userCardMixin"

// TeamCardSmall.vie
// ================
//
// This is a small card that shows a team name

export default {
  name: "TeamsCardSmall",
  mixins: [UserCardMixin],
  props: {
    isSelected: {
      default: false,
      type: Boolean
    },
    team: {
      default: () => {},
      type: Object
    },
    email: {
      default: () => "",
      type: String
    }
  },
  methods: {
    getTeamButtonClass(bool) {
      let activeClass = "clickable-box"
      if (bool) {
        activeClass = "clickable-box-selected"
      }
      return activeClass
    }
  }
}
</script>
