// import Vue from "vue"
import { createApp } from "vue"
import { createHead } from "@unhead/vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store/store"
import Vuex from "vuex"

import PigeonLineVue from "@pigeonline/core"
import VueThemes from "@pigeonline/vue-themes"
import VueAnalytics from "@pigeonline/vue-analytics"

import Services from "./services/services"

import styles from "./styles/global.scss"
import iccsTheme from "@/styles/themes/iccs/iccs"
import researchAiTheme from "@/styles/themes/research-ai/research-ai"

export const app = createApp(App)
app.use(store)
app.use(router)
//app.use(Vuex)

const head = createHead()
app.use(head)

app.config.globalProperties.$services = new Services(
  app.config.globalProperties.$pigeonline
)

// register PL packages
const BASE_URL = process.env.VUE_APP_BE_PORT
  ? `${process.env.VUE_APP_BE_URL}:${process.env.VUE_APP_BE_PORT}`
  : process.env.VUE_APP_BE_URL
app.use(PigeonLineVue, {
  baseUrl: BASE_URL
})

app.config.globalProperties.$base_URL = BASE_URL

// register themes
let activeTheme = researchAiTheme
if (window.location.hostname.includes("citizenfirst")) {
  activeTheme = iccsTheme
}

// let activeTheme = iccsTheme
app.use(VueThemes, {
  baseUrl: BASE_URL,
  theme: activeTheme
})

// Translation tools
import VisibleText from "@/components/Translation/VisibleText"
import translationMixin from "@/utils/mixins/translationMixin"
app.mixin(translationMixin)
app.component("VisibleText", VisibleText)

// register analytics
app.use(VueAnalytics, {
  id: "UA-LOCALHOST",
  baseUrl: BASE_URL,
  router: router
})

// register custom directive
app.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  }
})

app.mount("#app")
