/*******************************
 *
 * Generate text themes table node
 * @param {String} outlierTitle
 * @param {Array} outlierData table data
 * @returns {Object} pigeondoc outlier node
 *
 ******************************/

// const textThemesTableDescription = (textTableData, lang) => {
const outliersDescription = () => {
  const str =
    "This analysis lets you find outlier groups of entries using " +
    "z-scores. Outliers are segments that are considerably above or below " +
    "their peers. Extreme outliers are more than 5 standard deviations (" +
    "z-scores) away from the mean. Strong outliers are more than 4. Anything" +
    " less than 2 is not an outlier. This logic shapes how the below outliers" +
    " are labeled"

  return {
    type: "text",
    content: str,
    id: "text-top-details"
  }
}

const outliersHeader = () => {
  let thisTitle = "Outliers analysis"
  return {
    type: "heading",
    content: thisTitle,
    id: "section-subtitle-outliers",
    meta: {
      headingLevel: "h3",
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
}

const outliersUL = (outliersTitle, outliersData, lang) => {
  return {
    type: "outliersUL",
    content: {
      title: `<span class='outliers'>Outliers analysis</span>`,
      data: outliersData
    },
    id: "outliers-summary",
    meta: {
      state: "visible",
      lang: lang,
      caption: "Saved outliers"
    }
  }
}
export { outliersDescription, outliersHeader, outliersUL }
