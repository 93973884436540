<template>
  <div class="ui-rounded-pill">
    <div
      class="ui-rounded-pill__wrapper"
      :style="cssStyles"
      @click.stop="() => {}"
    >
      <div v-if="icon === 'ascending' || icon === 'descending'">
        <button
          style="
            width: 2em;
            padding: 0;
            background-color: transparent;
            border: 0.5px solid black;
            border-radius: 0.25em;
          "
          @click="$emit('edit-selection-order', icon)"
        >
          <SvgIcon
            v-if="icon"
            class="ui-rounded-pill__icon"
            style="margin-left: -0.5em"
            :icon="icon"
          />
        </button>
      </div>
      <div v-else>
        <div v-if="hasGenericClick">
          <button
            style="padding: 0; background-color: transparent; border: 0"
            @click="$emit('genericClick')"
          >
            <SvgIcon
              class="ui-rounded-pill__icon"
              :icon="icon"
              :aria-hidden="true"
              :focusable="false"
            />
          </button>
        </div>
        <div v-if="!hasGenericClick">
          <SvgIcon
            class="ui-rounded-pill__icon"
            :icon="icon"
            :aria-hidden="true"
            :focusable="false"
          />
        </div>
      </div>
      <div>
        <span
          class="ui-rounded-pill__content"
          style="margin-left: 1em"
          v-if="instructionLabel"
        >
          <VisibleText>{{ instructionLabel }}</VisibleText>
        </span>
        <span class="ui-rounded-pill__content" v-if="value">
          {{ value }}
        </span>
      </div>
      <div v-if="inputModel.length">
        <select
          v-model="filterSelection"
          ref="select-filter-for"
          :id="`select-filter-for-${inputId}`"
          style="
            border: none;
            background-color: transparent;
            margin-left: 2em;
            cursor: pointer;
          "
        >
          <option
            v-for="(inputOption, index) in inputModel"
            :key="index"
            :value="inputOption.value"
          >
            {{ inputOption.label }}
          </option>
        </select>
      </div>
      <ButtonIconOnly
        v-if="!hideDelete"
        class="ui-rounded-pill__close-btn"
        icon="deleteIcon"
        @click-handler="remove"
      >
        <VisibleText>remove filter</VisibleText>
      </ButtonIconOnly>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "RoundedPill",
  props: {
    value: {
      type: String,
      required: false,
      default: () => ""
    },
    icon: {
      type: String,
      required: false,
      default: () => ""
    },
    cssStyles: {
      type: Object,
      required: false,
      default: () => {}
    },
    hideDelete: {
      type: Boolean,
      required: false,
      default: () => false
    },
    inputModel: {
      type: Array,
      required: false,
      default: () => []
    },
    selected: {
      type: String,
      required: false,
      default: () => ""
    },
    inputId: {
      type: String,
      required: false,
      default: () => ""
    },
    instructionLabel: {
      type: String,
      required: false,
      default: () => ""
    },
    hasGenericClick: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    ButtonIconOnly,
    SvgIcon
  },
  data() {
    return {
      filterSelection: ""
    }
  },
  created() {
    if (this.selected) {
      this.filterSelection = this.selected
    }
  },
  methods: {
    remove: function (event) {
      event.stopPropagation()
      this.$emit("remove", true)
    }
  },
  watch: {
    filterSelection: async function (newVal, oldVal) {
      if (oldVal && newVal) {
        if (oldVal !== newVal) {
          this.$emit("edit-selection", newVal)
        }
      }
    }
  }
}
</script>
