<template>
  <div class="keywords-input-root">
    <div
      :class="{
        [wrapperClass + ' keywords-input']: true,
        active: isActive
      }"
      ref="kw-wrapper"
      style="
        border: 1px solid transparent;
        border-radius: 0.25em;
        border-color: var(--outline-colour);
        align-items: center;
        display: flex;
      "
    >
      <div style="display: flex; width: 10%; justify-content: center">
        <div
          type="button"
          class="open-btn"
          style="font-size: 0.8rem; text-align: left; display: flex"
        >
          {{ tags.length }}
          <VisibleText>&nbsp;keywords</VisibleText>
        </div>
      </div>

      <div style="width: 90%; text-align: left">
        <KeywordTappable
          class="keywords-input-badge pill"
          :themeColor="existingTheme ? existingTheme.theme_color : ''"
          :value="tag.value"
          :key="index"
          :textAnalysisObject="textAnalysisObject"
          :hasFetchedLemmas="hasFetchedLemmas"
          :also-found-ref="'also-found'"
          v-for="(tag, index) in tags"
          @banKeyword="$emit('banKeyword', $event)"
          :isBannedView="hintId == 'banned-keyword-hint' ? true : false"
          @showLoading="setShowLoading(tag.value, $event)"
        >
          <template v-slot="{ remove }">
            <ButtonIconOnly
              v-if="tag.value !== text"
              icon="remove"
              class="keywords-input-remove"
              style="color: white"
              @click-handler="remove(index)"
            >
              <VisibleText>remove </VisibleText>{{ tag.value }}
            </ButtonIconOnly>
            <SvgIconDecorative v-else icon="add" style="padding-top: 0.25rem" />
          </template>
        </KeywordTappable>
        <p class="sr-only" :id="uuid" v-if="!hintId">
          <VisibleText>Hit enter to add to list</VisibleText>
        </p>
        <input
          type="text"
          ref="taginput"
          aria-label="keyword"
          :aria-describedby="hintId || uuid"
          v-model="input"
          style="margin: 0"
          @keydown.enter.stop="tagFromInput"
          @keydown.8="removeLastTag"
          @focus="onFocus"
          @click="onClick"
          @blur="onBlur"
          @value="tags"
        />
      </div>
    </div>
    <AlsoFound
      class="ki-also-found"
      :textAnalysisObject="textAnalysisObject"
      :existingTheme="existingTheme ? existingTheme : {}"
      ref="also-found"
      @save="(keyword) => addTag({ key: '', value: keyword })"
    >
      <button
        type="button"
        class="also-found__save-all-btn"
        @click="saveAllCurrent"
      >
        <SvgIconDecorative icon="add" />
        <VisibleText>ADD ALL</VisibleText>
      </button>
    </AlsoFound>
    <div v-if="showLoading">
      <VisibleText>Searching for words similar to: </VisibleText>
      <span>{{ loading }}</span>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import KeywordTappable from "./KeywordsInputKeywordTappable.vue"
import AlsoFound from "./AlsoFound.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import _ from "lodash"

export default {
  name: "KeywordsInput",
  components: {
    ButtonIconOnly,
    KeywordTappable,
    AlsoFound,
    SvgIconDecorative
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },

    validate: {
      type: Function,
      default: () => true
    },

    deleteOnBackspace: {
      type: Boolean,
      default: false
    },

    wrapperClass: {
      type: String,
      default: "keywords-input-wrapper-default"
    },
    // if you want a custom hint message that exists outside of this component
    hintId: {
      type: String
    },
    textAnalysisObject: {
      type: Object,
      required: false,
      default: () => {}
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    existingTheme: {
      type: Object,
      required: false,
      default: () => {}
    },
    text: {
      type: String,
      required: false,
      default: () => ""
    }
  },

  data() {
    return {
      tags: [],
      input: "",
      isActive: false,
      isInitialized: false,
      uuid: Date.now(), //FIXME: when pigeon core is updated,
      showLoading: false,
      loading: ""
    }
  },

  computed: {
    $alsoFound: function () {
      return this.$refs["also-found"]
    }
  },

  created() {
    this.tagsFromValue()
  },

  mounted() {
    // Emit an event
    this.$emit("initialized")
  },

  watch: {
    tags() {
      // Updating the hidden input
      this.hiddenInput = JSON.stringify(this.tags)

      // Update the bound v-model value
      if (this.isInitialized) this.$emit("input", this.tags)
    },
    value() {
      this.tagsFromValue()
    }
  },

  methods: {
    /**
     * Add a tag whether from user input
     *
     * @returns void
     */
    tagFromInput() {
      // If we're adding an unexisting tag
      let text = this.input.trim()

      // If the new tag is not an empty string and passes validation
      if (text.length && this.validate(text)) {
        this.input = ""

        let newTag = {
          key: "",
          value: text
        }
        this.addTag(newTag)
      }
    },

    /**
     * Add/Select a tag
     *
     * @param tag
     * @returns void | Boolean
     */
    addTag(tag) {
      // Attach the tag if it hasn't been attached yet
      if (!this.tagSelected(tag)) {
        this.tags.push(tag)
        // Emit events
        if (this.isInitialized) {
          this.$emit("tag-added", tag.value)
          this.$emit("keywords-updated")
        }
      }
    },

    /**
     * Remove the last tag in the tags array.
     *
     * @returns void
     */
    removeLastTag() {
      if (!this.input.length && this.deleteOnBackspace && this.tags.length) {
        this.removeTag(this.tags.length - 1)
      }
    },

    /**
     * Remove the selected tag at the specified index.
     *
     * @param index
     * @returns void
     */
    removeTag(index) {
      let tag = this.tags[index]

      this.tags.splice(index, 1)

      // Emit events
      this.$nextTick(() => {
        this.$emit("tag-removed", tag.value)
        this.$emit("keywords-updated")
      })
    },

    /**
     * Clear the list of selected tags.
     *
     * @returns void
     */
    clearTags() {
      this.tags.splice(0, this.tags.length)
    },

    /**
     * Replace the currently selected tags with the tags from the value.
     *
     * @returns void
     */
    tagsFromValue() {
      this.isInitialized = false
      if (this.value && this.value.length) {
        if (!Array.isArray(this.value)) {
          alert("The v-model value must be an array!")
          return
        }

        let tags = this.value
        // Update only if something has changed
        if (!_.isEqual(this.tags, tags)) {
          this.clearTags()

          for (let tag of tags) {
            this.addTag(tag)
          }
        }
      } else {
        if (
          this.tags.length !== 0 &&
          (!this.hintId || !this.hintId.includes("banned"))
        ) {
          this.clearTags()
        }
      }

      this.isInitialized = true
    },

    /**
     * Check if a tag is already selected.
     *
     * @param tag
     * @returns Boolean
     */
    tagSelected(tag) {
      if (!tag) {
        return false
      }

      const searchQuery = tag.value.toLowerCase()

      for (let selectedTag of this.tags) {
        const compareable = selectedTag.value.toLowerCase()

        if (
          compareable.length == searchQuery.length &&
          compareable.search(searchQuery) > -1
        ) {
          return true
        }
      }

      return false
    },

    /**
     * Clear the input.
     *
     * @returns void
     */
    clearInput() {
      this.input = ""
    },

    /**
     * Process the onfocus event.
     *
     * @param e
     * @returns void
     */
    onFocus(e) {
      this.$emit("focus", e)

      this.isActive = true
    },

    /**
     * Process the onClick event.
     *
     * @param e
     * @returns void
     */
    onClick(e) {
      this.$emit("click", e)

      this.isActive = true
    },

    /**
     * Process the onblur event.
     *
     * @param e
     * @returns void
     */
    onBlur(e) {
      this.$emit("blur", e)
      this.isActive = false
    },

    /* Also found methods */
    saveAllCurrent() {
      for (let value of this.$alsoFound.similar) {
        this.addTag({ key: "", value: value })
      }
    },

    setShowLoading(tag, status) {
      if (status) {
        this.loading = tag
      } else {
        this.loading = ""
      }
      this.showLoading = status
    }
  }
}
</script>
