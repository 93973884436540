<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <AnalysisSettings
        ref="AnalysisSettings"
        :statusDisableActionBtns="statusDisableActionBtns"
        :index="index"
        :thisAnalysisName="'Outliers analysis'"
        :isOutliersAnalysis="true"
        :propReadOnly="propReadOnly"
        :savedNodes="this.savedNodes"
        :contentChanged="this.contentChanged"
        :hideSave="true"
        :lastModified="
          formatDateTime(
            outliersAnalysisObject.last_modified_on,
            $store.getters.getLanguage
          )
        "
        @toggled="isExpanded = !isExpanded"
        @save-to-report="saveToReport"
        @on-click-delete="onClickDelete"
        @saved-slide="updateSavedSlide"
        @filterTop="updateFilterTop"
      ></AnalysisSettings>
      <div v-if="isExpanded">
        <LoadingSpinner
          :isLoading="isLoadingData"
          :componentStyle="true"
          style="position: relative"
          :style="{ 'min-height': minHeight }"
        />
        <div v-if="!isLoadingData">
          <SavedOutliers
            :propReadOnly="propReadOnly"
            :outliersAnalysisObject="outliersAnalysisObject"
            @delete-outlier="deleteOutlier"
          ></SavedOutliers>
          <TopOutliers
            :propReadOnly="propReadOnly"
            :data="outliersAnalysisObject"
            @saveThisOutlier="saveThisOutlier"
          ></TopOutliers>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// Components
import TopOutliers from "./Components/TopOutliers.vue"
import SavedOutliers from "./Components/SavedOutliers.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"

import { PigeonDocModel } from "@pigeonline/pigeondoc"

import AnalysisSettings from "@/components/Project/ProjectAnalysis/Components/AnalysisSettings.vue"

// Helpers
import moment from "moment"

// Mixins
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "ProjectAnalysisOutliersItem",
  components: {
    TopOutliers,
    SavedOutliers,
    AnalysisSettings,
    LoadingSpinner
  },
  mixins: [ProjectMixin, ProjectReportMixin, FiltersMixin],
  props: {
    index: {
      type: Number,
      required: true
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      savedSlide: "",
      savedNodes: [],
      outliersAnalysisObject: {},
      isLoadingData: false,
      uuid: this.$pigeonline.createUUID(),
      isExpanded: false,
      contentChanged: false
    }
  },
  async created() {
    if (this.project) {
      if (this.project.outliersAnalysis.length) {
        this.outliersAnalysisObject = this.project.outliersAnalysis[this.index]
        if (this.outliersAnalysisObject.saved_node_ids) {
          this.savedNodes = this.outliersAnalysisObject.saved_node_ids
        }
        this.isLoadingData = true
        const response = await this.OUTLIERS_SERVICE.outliersAnalysis({
          data_set_id: this.project.datasets[0],
          client_questions_ids: this.outliersAnalysisObject.selectedQuestions
        })
        if (response) {
          if (response.data.summary_table) {
            if (!(response.data.summary_table instanceof Array)) {
              if (typeof response.data.summary_table !== "object") {
                response.data.summary_table = []
              } else {
                response.data.summary_table = Object.entries(
                  response.data.summary_table
                )
              }
            }
            this.outliersAnalysisObject.summary_table =
              response.data.summary_table
            for (let i = 0; i < this.project.outliersAnalysis.length; i++) {
              if (
                this.project.outliersAnalysis[i].uuid ===
                this.outliersAnalysisObject.uuid
              ) {
                this.project.outliersAnalysis[i].summary_table =
                  response.data.summary_table
                this.project.outliersAnalysis[i].last_modified_on =
                  moment().valueOf()
              }
            }
            await this.cleanAndSaveProject()
          }
        }
        this.isLoadingData = false
      }
    }
  },
  computed: {
    statusDisableActionBtns() {
      return this.isLoadingData || !this.outliersAnalysisObject.outliers.length
    },
    minHeight() {
      if (this.isLoadingData) {
        return "5em"
      } else {
        return "0"
      }
    },
    OUTLIERS_SERVICE() {
      return this.$services.OUTLIERS_SERVICE
    }
  },
  methods: {
    async updateFilterTop(number) {
      if (!number) return
      this.filterTop = parseInt(number)
      await this.saveOutliersToProject(this.outliersAnalysisObject.uuid)
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
    },
    async saveThisOutlier(outlier, viewMode) {
      if (outlier && viewMode) {
        let storedOutlier = {
          responses: outlier,
          viewType: viewMode
        }
        this.outliersAnalysisObject.outliers.push(storedOutlier)
        await this.saveOutliersToProject()
      }
    },
    async saveOutliersToProject() {
      if (this.propReadOnly) return
      const indx = this.project.outliersAnalysis.findIndex(
        (d) => d.uuid == this.outliersAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.outliersAnalysis[indx].saved_node_ids = this.savedNodes
        this.project.outliersAnalysis[indx].outliers =
          this.outliersAnalysisObject.outliers
        this.project.outliersAnalysis[indx].last_modified_on =
          moment().valueOf()
        await this.cleanAndSaveProject()
      }
    },
    async saveToReport(selectedSlide, newSlideName) {
      const uuid = await this.saveOutliersToReport(selectedSlide, newSlideName)
      // this is used to get all AnalysisSetting->SaveAnalysis to rerun collections
      if (!this.project.reportSaves) {
        this.project.reportSaves = 1
      } else {
        this.project.reportSaves = this.project.reportSaves + 1
      }
      await this.saveOutliersToProject(uuid)
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.updateCollections()
      }
      this.$emit("refresh")
    },
    async saveOutliersToReport(selectedSlide, newSlideName) {
      try {
        this.isLoadingData = true
        let createdReportID = await this.createReportIfEmpty(
          selectedSlide,
          newSlideName
        ) // the above function will do nothing under conditions of emptiness
        if (selectedSlide) {
          createdReportID = selectedSlide.id
        }
        const returnObject = await this.updateReportWithOutliers(
          this.outliersAnalysisObject.uuid,
          createdReportID,
          this.outliersAnalysisObject
        )
        this.isLoadingData = false
        await this.$emit("updateSlides")
        this.onSuccessfulSave()
        this.savedNodes = returnObject.newNodes
        return returnObject.uuid
      } catch (e) {
        throw new Error("ProjectAnalysisOutliers:saveOutliersToReport ")
      }
    },
    async deleteOutlier(outlierIndex) {
      this.outliersAnalysisObject.outliers.splice(outlierIndex, 1)
      this.saveOutliersToProject()
    },
    async deleteOutlierAnalysis() {
      // Drops from the "analysis view"
      const indx = this.project.outliersAnalysis.findIndex(
        (d) => d.uuid === this.outliersAnalysisObject.uuid
      )
      this.project.outliersAnalysis.splice(indx, 1)
      await this.cleanAndSaveProject()
      // Drops the same nodes from the saved project
      let project
      if (this.savedNodes.length) {
        project = await this.$pigeonline.projects.getByID(
          PigeonDocModel,
          this.savedSlide.id
        )
        if (project) {
          for (let i = 0; i < this.savedNodes.length; i++) {
            for (let x = 0; x < project.nodes.length; x++) {
              if (project.nodes[x].uuid === this.savedNodes[i].uuid) {
                project.nodes = project.nodes.filter(
                  (item) => item !== project.nodes[x]
                )
              }
            }
          }
        }
        await this.saveProject(project)
      }
      this.$emit("refresh")
    },
    async onClickDelete() {
      const indx = this.project.outliersAnalysis.findIndex(
        (d) => d.uuid === this.outliersAnalysisObject.uuid
      )
      if (!this.savedNodes.length) {
        this.setConfirmText({
          btn: "Delete?",
          title: "Delete outliers analysis?"
        })
      } else {
        this.setConfirmText({
          btn: "Delete",
          title:
            "Delete outliers analysis? This will also delete this analysis from your report."
        })
      }
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("outliers")
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Outliers saved",
        message: "Outliers successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("outliers")
      this.setConfirmIsVisible(true)
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  },
  watch: {
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "outliers" &&
        this.confirmType === "delete"
      ) {
        await this.deleteOutlierAnalysis()
      }
    }
  }
}
</script>
