<template>
  <div class="drivers__chart-wrapper">
    <component :is="type" :value="chartNode" v-if="chartNode" />
  </div>
</template>

<script>
// Components
import { HorizontalBarChart, PriorityMatrix } from "@pigeonline/pigeondoc"

export default {
  name: "DriversChart",
  components: {
    HorizontalBarChart,
    PriorityMatrix
  },
  props: {
    chartNode: { type: Object },
    type: { type: String },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
