<template>
  <section id="project-analysis-text-main-themes">
    <div class="themes__header">
      <ul class="themes__header-labels main__search-labels">
        <transition name="fade">
          <li v-show="isSearchMode">
            <span
              class="main__highlight search"
              style="background-color: lightgreen"
              id="themes-search-keyword"
            >
              <VisibleText>Search</VisibleText>
            </span>
          </li>
        </transition>
      </ul>
      <div class="select-wrapper themes__sort-wrapper" style="display: none">
        <label for="theme-sort-by" class="themes__sort-label">
          <VisibleText>Sort by: </VisibleText>
        </label>
        <select
          class="select themes__sort-options"
          id="theme-sort-by"
          v-model.number="sortBy"
        >
          <option value="-1" :data-parent="$options.name">
            {{ translate("Select a theme", $options.name) }}
          </option>
          <option
            :value="index"
            :data-parent="$options.name"
            :key="index"
            v-for="(theme, index) in sortedThemes"
            :disabled="
              textAnalysisObject.selectedThemeUUIDs.includes(theme._id)
            "
          >
            {{ theme.name }}
          </option>
        </select>
      </div>
    </div>
    <ResponseList
      ref="MainThemesResponseRef"
      class="themes__response-list"
      :textAnalysisObject="textAnalysisObject"
      :propReadOnly="propReadOnly"
      :client-question-id="
        convertId(textAnalysisObject.selectedTextQuestion._id)
      "
      :responses="sortedResponsesProp"
      :includeSmartCoverage="includeSmartCoverage"
      :hidden-comments="textAnalysisObject.hiddenComments"
      :get-highlight-items-method="getHighlightItems"
      :datasetIDQuestions="datasetIDQuestions"
      :hasFetchedLemmas="hasFetchedLemmas"
      :hasFetchedSentiments="hasFetchedSentiments"
      :hasFetchedSentences="hasFetchedSentences"
      :sortedResponsesLength="sortedResponsesLength"
      :sortedThemes="sortedThemes"
      @remove-note="onRemoveNote"
      @ban-response="banResponseItem"
      @toggle-response-hide="toggleResponseItemHide"
      @runSortAndSelect="$emit('runSortAndSelect')"
      @updateTextObject="$emit('updateTextObject', $event)"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      @selectThisResponse="selectThisResponse($event)"
    />
  </section>
</template>

<script>
// Components
import ResponseList from "./Components/ResponseList.vue"

// Mixins
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"

export default {
  name: "ProjectTextAnalysisMainThemes",
  mixins: [textAnalysisMixin],
  components: {
    ResponseList
  },
  props: {
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    highlightFilters: {
      type: Array,
      default: () => []
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedResponsesProp: {
      type: Array,
      required: false,
      default: () => []
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      sortBy: -1 /* sort by themes */
    }
  },
  created() {
    this.getHighlightItems = this.getHighlightItems.bind(this)
  },
  computed: {
    themeName() {
      for (let i = 0; i < this.sortedThemes.length; i++) {
        if (
          this.textAnalysisObject.selectedThemeUUIDs.includes(
            this.sortedThemes[i]._id
          )
        ) {
          return this.sortedThemes[i].name
        }
      }
      return "Theme"
    }
  },
  methods: {
    refreshThemesFromParent() {
      if (this.$refs.MainThemesResponseRef) {
        this.$refs.MainThemesResponseRef.refreshThemesFromParent()
      }
    },
    convertId(id) {
      if (id && id.$oid) {
        return id.$oid
      } else {
        return id
      }
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    findResponseScore(response) {
      if (!response.scores) {
        return 0
      }
      if (response.scores.themes[this.sortBy]) {
        return response.scores.themes[this.sortBy]
      }
      for (let i = 0; i < this.sortedThemes.length; i++) {
        if (
          this.textAnalysisObject.selectedThemeUUIDs.includes(
            this.sortedThemes[i]._id
          )
        ) {
          return response.scores.themes[i]
        }
      }

      return 0
    },
    getHighlightItems(response) {
      // This is an important function that 'highlights' responses
      if (!this.textAnalysisObject || !response || !response.id) return {}

      let keywords = []
      let themeKeywords = {}
      let allKeywords = []
      let themeNotes = {}
      let allNotes = []
      let notes = []
      for (let i = 0; i < this.sortedThemes.length; i++) {
        let color = this.sortedThemes[i].theme_color

        if (
          this.textAnalysisObject.filterParams &&
          Object.keys(this.textAnalysisObject.filterParams).length &&
          this.sortedThemes[i].filtered_keyword_matches
        ) {
          if (
            this.sortedThemes[i].filtered_keyword_matches.some((keyword) =>
              response.response.toLowerCase().includes(keyword)
            )
          ) {
            keywords = this.sortedThemes[i].filtered_keyword_matches

            themeKeywords = {
              type: "keywords",
              color: color,
              matching_reasons: keywords,
              theme_name: this.sortedThemes[i].name,
              theme_id: this.sortedThemes[i]._id
            }
            allKeywords.push(themeKeywords)
          }
        } else {
          if (
            this.sortedThemes[i].keyword_matches.some((keyword) =>
              response.response.toLowerCase().includes(keyword)
            )
          ) {
            keywords = this.sortedThemes[i].keyword_matches

            themeKeywords = {
              type: "keywords",
              color: color,
              matching_reasons: keywords,
              theme_name: this.sortedThemes[i].name,
              theme_id: this.sortedThemes[i]._id
            }
            allKeywords.push(themeKeywords)
          }
        }

        if (this.sortedThemes[i].notes) {
          // These will not match on old projects, as response ID used to be a different id. Now responses are recreated.
          notes = this.sortedThemes[i].notes.filter(
            (item) =>
              item.responseId == response.id ||
              (response.old_id && item.responseId == response.old_id)
          )
          let notes_content = notes.map((object) => object.content)
          if (notes.length) {
            themeNotes = {
              type: "notes",
              color: color,
              matching_reasons: notes_content,
              theme_name: this.sortedThemes[i].name,
              theme_id: this.sortedThemes[i]._id
            }
            allNotes.push(themeNotes)
          }
        }
      }

      let obj = {
        "theme-keyword": allKeywords,
        "theme-note": allNotes
      }
      for (let key of Object.keys(obj)) {
        if (!this.highlightFilters.includes(key)) delete obj[key]
      }

      if (
        this.textAnalysisObject &&
        this.textAnalysisObject.search &&
        this.textAnalysisObject.search.searchResults &&
        this.textAnalysisObject.search.searchResults.length
      ) {
        obj["search-keyword"] = this.textAnalysisObject.search.searchResults
      }
      if (
        this.textAnalysisObject &&
        this.textAnalysisObject.search &&
        this.textAnalysisObject.search.similarSentences &&
        this.textAnalysisObject.search.similarSentences.length
      ) {
        obj["search-keyword"] = this.textAnalysisObject.search.similarSentences
      }

      return obj
    },
    async onRemoveNote(selection) {
      if (!selection.responseId) return

      this.setShowSpinner(true)
      let themeUUID = ""
      for (let i = 0; i < this.sortedThemes.length; i++) {
        for (let x = 0; x < this.sortedThemes[i].notes.length; x++) {
          let thisTheme = this.deepCloneObj(this.sortedThemes[i])
          if (thisTheme.notes[x].themeUUID && selection.themeUUID) {
            themeUUID = selection.themeUUID

            break
          } else {
            if (
              thisTheme.notes[x].responseId == selection.responseId &&
              thisTheme.notes[x].startIndex == selection.startIndex
            ) {
              themeUUID = this.sortedThemes[i]._id
              break
            }
          }
        }
      }

      if (themeUUID !== "") {
        this.saveTextThemeNotesRemoved(themeUUID, selection)
      }
      this.setShowSpinner(false)
    },

    selectThisResponse(response_id) {
      if (response_id) {
        let these_responses = []
        these_responses.push(response_id)
        this.setSelectedResponseIds(these_responses)
      }
    }
  },
  watch: {
    search: function (val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = -1
    }
  }
}
</script>
