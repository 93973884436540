<template>
  <div class="dataset-table__values-scale">
    <!--------------------------------------------
    |
    | EDIT OPTIONS
    |
    --------------------------------------------->
    <div class="dataset-table__values-scale-row1" v-if="!hideOptions">
      <button class="table__toolbar-btn" @click.stop="onClickEditLabels">
        <SvgIconDecorative icon="tag" />
        <VisibleText>Edit Labels</VisibleText>
      </button>
      <button class="table__toolbar-btn" @click.stop="onClickEditValues">
        <SvgIconDecorative icon="value" />
        <VisibleText>Edit Values</VisibleText>
      </button>
    </div>

    <!--------------------------------------------
    |
    | VALUES
    |
    --------------------------------------------->
    <article class="dataset-table__values-scale-section">
      <h2 class="dataset-table__values-header valid-vals">
        <VisibleText>valid values</VisibleText>
      </h2>
      <ul class="dataset-table__values-ul--valid">
        <li
          v-for="(value, index) in valid"
          :key="`valid-${index}-${value.value}`"
          class="dataset-table__values-li"
        >
          <EditLabelInput
            :unique-val="value"
            @update-label="$_dataValuesMixin_updateLabel"
            v-if="isEditingLabels"
          >
            <span class="sr-only" :data-parent="$options.name">
              {{ translate("valid values", $options.name) }}
            </span>
            {{ value.modified_value || value.value }}
          </EditLabelInput>

          <EditValsInput
            :aria-describedby-ids="`msg-${clientQuestionId}`"
            :error="invalidVals.includes(value.value)"
            :unique-val="value"
            @update-value="updateValue"
            v-if="isEditingValues"
          >
            <span class="sr-only">
              {{ translate("valid value", $options.name) }}
            </span>
            {{ value.label || translate("no label", $options.name) }}
          </EditValsInput>

          <!-- read only -->
          <span
            class="dataset-table__values-li__item"
            v-show="!isEditingValues && !isEditingLabels"
          >
            <span class="value value--heavy">
              {{ value.modified_value || value.value }}
            </span>
            <span class="label">
              {{ value.label || translate("no label", $options.name) }}
            </span>
            <span class="orig-value" v-if="value.modified_value">
              <VisibleText>original value</VisibleText>: {{ value.value }}
            </span>
          </span>
        </li>
      </ul>
    </article>
    <article class="dataset-table__values-scale-section">
      <h2 class="dataset-table__values-header invalid-vals">
        <VisibleText>invalid values</VisibleText>
      </h2>
      <ul class="dataset-table__values-ul--invalid">
        <li
          v-for="(value, index) in invalid"
          :key="`invalid-${index}-${value.value}`"
          class="dataset-table__values-li"
        >
          <EditLabelInput
            :unique-val="value"
            @update-label="$_dataValuesMixin_updateLabel"
            v-if="isEditingLabels"
          >
            <span class="sr-only" :data-parent="$options.name">
              {{ translate("invalid values", $options.name) }}
            </span>
            {{ value.modified_value || value.value }}
          </EditLabelInput>

          <EditValsInput
            :aria-describedby-ids="`msg-${clientQuestionId}`"
            :error="invalidVals.includes(value.value)"
            :unique-val="value"
            @update-value="updateValue"
            v-if="isEditingValues"
          >
            <span class="sr-only">
              {{ translate("invalid value", $options.name) }}
            </span>
            {{ value.label || translate("no label", $options.name) }}
          </EditValsInput>

          <!-- read only -->
          <span
            class="dataset-table__values-li__item"
            v-show="!isEditingValues && !isEditingLabels"
          >
            <span class="value value--heavy">
              {{ value.modified_value || value.value }}
            </span>
            <span class="label">
              {{ value.label || translate("no label", $options.name) }}
            </span>
            <span class="orig-value" v-if="value.modified_value">
              <VisibleText>original value</VisibleText>: {{ value.value }}
            </span>
          </span>
        </li>
      </ul>
    </article>

    <!--------------------------------------------
    |
    | SAVE OPTIONS
    |
    --------------------------------------------->
    <div
      class="dataset-table__values-scale-row3"
      v-show="isEditingValues || isEditingLabels"
    >
      <button
        class="dataset-table__values-save-btn"
        @click.stop="$_dataValuesMixin_saveLabels"
        v-show="isEditingLabels"
      >
        <SvgIconDecorative icon="check" />
        <VisibleText>Save Labels</VisibleText>
      </button>
      <button
        class="dataset-table__values-save-btn"
        @click.stop="saveValues"
        v-show="isEditingValues"
      >
        <SvgIconDecorative icon="check" />
        <VisibleText>Save Values</VisibleText>
      </button>
      <button class="btn-cancel" @click="onClickCancel">
        <SvgIconDecorative icon="remove" />
        <VisibleText>Cancel</VisibleText>
      </button>

      <MessageInline
        :id="`msg-${clientQuestionId}`"
        :message="message.text"
        :message-type="message.type"
      />
    </div>
  </div>
</template>

<script>
// Components
import EditValsInput from "./Components/EditValsInput.vue"
import EditLabelInput from "./Components/EditLabelInput.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DataValuesMixin from "@/components/Dataset/Mixins/dataValuesMixin.js"

import _ from "lodash"

export default {
  name: "ValuesScale",
  mixins: [DataValuesMixin],
  components: {
    EditValsInput,
    EditLabelInput,
    MessageInline,
    SvgIconDecorative
  },
  props: {
    clientQuestionId: {
      default: () => {},
      type: String,
      required: true
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    },
    uniqueValues: {
      type: Array
    }
  },
  data() {
    return {
      isEditingValues: false,
      isEditingLabels: false,
      invalidVals: [],
      $_uniqueValues: {}
    }
  },
  created() {
    this.$data.$_uniqueValues = _.cloneDeep(this.uniqueValues)
  },
  computed: {
    valid() {
      return this.getValueByIsValid(this.$data.$_uniqueValues, true)
    },
    invalid() {
      return this.getValueByIsValid(this.$data.$_uniqueValues, false)
    }
  },
  methods: {
    getValueByIsValid(uniqueValues, isValid) {
      if (!uniqueValues) return []
      return uniqueValues.filter((item) => item.is_valid === isValid)
    },
    onClickCancel() {
      this.$_dataValuesMixin_resetMessage()
      this.invalidVals = []
      this.isEditingValues = false
      this.isEditingLabels = false
    },
    onClickEditLabels() {
      this.$_dataValuesMixin_resetMessage()
      this.invalidVals = []
      this.isEditingValues = false
      this.isEditingLabels = true
    },
    onClickEditValues() {
      this.$_dataValuesMixin_resetMessage()
      this.invalidVals = []
      this.isEditingLabels = false
      this.isEditingValues = true
    },
    updateValue(modifiedVal, originalVal, isValid) {
      const index = this.uniqueValues.findIndex(
        (val) => val.value === originalVal
      )
      this.$data.$_uniqueValues[index] = Object.assign(
        {},
        {
          is_valid: isValid,
          value: originalVal,
          modified_value: Number(modifiedVal)
        }
      )
    },
    validateValues() {
      this.$_dataValuesMixin_resetMessage()
      this.invalidVals = this.$data.$_uniqueValues
        .filter(
          (val) => Number.isNaN(val.modified_value) || Number.isNaN(val.value)
        )
        .map((v) => v.value)
      const allUnique = new Set(
        this.$data.$_uniqueValues.map((v) => v.modified_value || v.value)
      )

      // are there any NaNs?
      if (this.invalidVals.length > 0) {
        this.message.type = "error"
        this.message.text = "All valid values must be numbers."
      }

      // are all values unique?
      if (allUnique.size !== this.$data.$_uniqueValues.length) {
        this.message.type = "error"
        this.message.text =
          "There are duplicate values. All values must be unique."
      }

      return { error: this.message.text.length > 0 }
    },
    async saveValues() {
      this.$_dataValuesMixin_resetMessage()
      const validate = this.validateValues()
      if (validate.error) return
      try {
        const updatedQ =
          await this.$services.DATASETS_SERVICE.updateClientQuestion(
            this.clientQuestionId,
            { unique_values: this.$data.$_uniqueValues }
          )
        this.$store.dispatch(
          "datasetDetails/updateClientQuestion",
          updatedQ.client_question_object
        )
        this.$data.$_uniqueValues =
          updatedQ.client_question_object.unique_values
        this.message.type = "success"
        this.message.text = "Values saved."
      } catch (e) {
        this.message.type = "error"
        this.message.err = "Problem saving values."
        throw new Error("DatasetTableValuesScale:saveValues " + e.message)
      }
    }
  },
  watch: {
    uniqueValues: {
      deep: true,
      handler: function (uniqueValues) {
        if (uniqueValues && uniqueValues.length > 0)
          this.$data.$_uniqueValues = _.cloneDeep(uniqueValues)
      }
    }
  }
}
</script>
