<template>
  <div class="theme__list-row-sentiment-charts">
    <PositiveNegativeBarChartSmall :value="posNegData">
      <template v-slot:description="{ chartDescription }">
        <details class="chart-description__details">
          <summary>
            <span class="sr-only"
              ><VisibleText>theme</VisibleText> {{ themeName }}
            </span>
            <VisibleText>chart details</VisibleText>
          </summary>
          <p
            class="chart-description__details-text"
            v-html="chartDescription"
          ></p>
        </details>
      </template>
    </PositiveNegativeBarChartSmall>
  </div>
</template>

<script>
import {
  PigeonDocNodeModel,
  PositiveNegativeBarChartSmall
} from "@pigeonline/pigeondoc"

export default {
  name: "SentimentCharts",
  components: {
    PositiveNegativeBarChartSmall
  },
  props: {
    themeName: {
      type: String
    },
    singleStackData: {
      type: PigeonDocNodeModel
    },
    posNegData: {
      type: PigeonDocNodeModel
    }
  },
  watch: {
    themeName: function (val) {
      if (!val) return
      this.$forceUpdate()
    }
  }
}
</script>
