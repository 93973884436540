import { app } from "@/main.js"
import BenchmarkingProject from "@/models/BenchmarkingProjectModel.js"

const fetchProject = async (projectId) => {
  try {
    const response =
      await app.config.globalProperties.$pigeonline.projects.getByID(
        BenchmarkingProject,
        projectId
      )
    return response
  } catch (e) {
    throw new Error("ProjectMixin.js:fetchProject " + e.message)
  }
}

export { fetchProject }
