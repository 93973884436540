<template>
  <nav aria-label="project" class="project-tabs">
    <ul class="project-tabs__list">
      <li
        v-for="item in items"
        :key="item"
        :class="[
          'project-tabs__list-item',
          {
            'project-tabs__list-item-active': $route.name
              .toLowerCase()
              .includes(item)
          }
        ]"
      >
        <router-link :to="getPath(item)" class="project-tabs__list-item-link">
          <VisibleText class="project-tabs__list-item-text">
            {{ item }}
          </VisibleText>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"

export default {
  name: "ProjectTabs",
  props: {
    // project: { type: BenchmarkingProject },
    selectedSlide: {
      default: () => "empty",
      type: String
    },
    project: {
      defult: () => {},
      type: Object
    }
  },
  data() {
    return {
      items: ["data", "analysis", "report"],
      slides: [],
      acceptablePaths: [
        "performance",
        "drivers",
        "benchmarking",
        "text",
        "outliers",
        "tools"
      ] // Make this a const
    }
  },
  methods: {
    getPath(item) {
      let pathEnd = ""
      if (item === "analysis") {
        let useDefault = true
        // Check last saved project for last used tab
        if (this.project) {
          if (this.project.settings) {
            if (this.project.settings.lastOpenedTab) {
              if (
                this.acceptablePaths.includes(
                  this.project.settings.lastOpenedTab
                )
              ) {
                pathEnd = "analysis/" + this.project.settings.lastOpenedTab
                useDefault = false
              }
            }
          }
        }
        if (useDefault) {
          pathEnd = "analysis/tools"
        }
      } else if (item === "report") {
        if (this.selectedSlide) {
          pathEnd = `report/${this.selectedSlide}`
        } else {
          pathEnd = "report/empty"
        }
      }
      return `/project/${this.project.id}/${pathEnd}`
    }
  }
}
</script>
