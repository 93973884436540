<template>
  <div
    :class="[{ show: showDialog }]"
    class="theme__popup"
    v-click-outside="forceClose"
  >
    <button
      type="button"
      style="margin-left: 0.25rem"
      class="open-btn"
      @click="onClickOpen"
    >
      <span class="theme__list-row-coverage-percent" style="font-size: 1rem">
        Coverage
      </span>
    </button>
    <DialogPopup
      class="theme__popup-dialog"
      header-tag="h4"
      :show="showDialog"
      @close="showDialog = false"
      style="width: 20rem"
    >
      <template #header>
        <div
          style="
            display: flex;
            flex-direction: row;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
          "
        >
          Coverage
        </div>
      </template>
      <template #body>
        <div
          style="
            text-align: left;
            display: flex;
            flex-direction: column;
            text-transform: none;
            padding-left: 0.5rem;
          "
        >
          <span>
            Coverage is a percentage of total text entries that have answered at
            least one keyword match, one note, or has been manually added to a
            theme.
          </span>

          <div style="display: flex; flex-direction: column">
            <div style="display: flex; margin: 1rem 0 1rem 0">
              <strong><VisibleText>Options</VisibleText></strong>
            </div>

            <div style="display: flex; padding-left: 3rem">
              <input
                type="checkbox"
                id="input-edit-coverage-type"
                v-model="adjustedShowCoverageDetails"
                @click="updateAdjustedShowCoverageDetails"
              />
              <label for="input-edit-coverage-type" id="row-edit-coverage-type">
                <VisibleText
                  >Show coverage match details in the themes list.</VisibleText
                >
              </label>
            </div>
          </div>
        </div>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"

export default {
  name: "CoverageOptionsPopup",
  components: {
    DialogPopup
  },
  props: {
    showCoverageDetails: {
      default: () => "show",
      type: String
    }
  },
  data() {
    return {
      showDialog: false,
      adjustedShowCoverageDetails: false,
      stringedCoverageDetails: "show"
    }
  },
  created() {
    this.stringedCoverageDetails = this.showCoverageDetails
    if (this.stringedCoverageDetails == "show") {
      this.adjustedShowCoverageDetails = true
    } else {
      this.adjustedShowCoverageDetails = false
    }
  },
  methods: {
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      this.showDialog = true
    },
    updateAdjustedShowCoverageDetails() {
      this.adjustedShowCoverageDetails = !this.adjustedShowCoverageDetails
      if (this.adjustedShowCoverageDetails == false) {
        this.stringedCoverageDetails = "hide"
      }
      if (this.adjustedShowCoverageDetails == true) {
        this.stringedCoverageDetails = "show"
      }
      this.$emit("updateShowCoverageDetails", this.stringedCoverageDetails)
    }
  }
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 32rem;
  width: calc(100% * 3);
}
</style>
