<template>
  <div class="dataset-segments__custom">
    <Tagging :read-only="readOnly" @add="addTag">
      <VisibleText>{{ label }}</VisibleText>
      <template #desc v-if="description">
        <VisibleText>{{ description }}</VisibleText>
      </template>
    </Tagging>
    <TaggingList
      :key="keyTaggingList"
      :tags="customSegments[category]"
      @remove="removeTag"
    />
  </div>
</template>

<script>
import Tagging from "@/components/UI/Tagging/Tagging.vue"
import TaggingList from "@/components/UI/Tagging/TaggingList.vue"

export default {
  name: "DatasetSegmentsCustom",
  components: {
    Tagging,
    TaggingList
  },
  props: {
    category: {
      type: String
    },
    description: {
      default: () => "Enter tags",
      type: String
    },
    label: {
      type: String,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      keyTaggingList: this.$pigeonline.createUUID()
    }
  },
  methods: {
    addTag(tag) {
      this.$emit("add-tag", tag)
      this.keyTaggingList = this.$pigeonline.createUUID()
    },
    removeTag(tag) {
      this.$emit("remove-tag", tag)
      this.keyTaggingList = this.$pigeonline.createUUID()
    }
  },
  computed: {
    customSegments() {
      return this.$store.getters["datasetWizard/getDatasetProject"][
        "customSegments"
      ]
    }
  }
}
</script>
