<template>
  <div
    :class="[{ show: showDialog }]"
    class="theme__popup"
    v-click-outside="forceClose"
  >
    <button
      type="button"
      style="margin-left: 0.25rem"
      class="open-btn"
      @click="onClickOpen"
    >
      <span class="theme__list-row-coverage-percent"> {{ percentage }}% </span>
      <span v-if="!hasFilter" class="theme__list-row-coverage-num-responses">
        {{
          `(${parsedCoverage} /
          ${sortedResponsesLength})`
        }}
      </span>
      <span v-if="hasFilter" class="theme__list-row-coverage-num-responses">
        {{
          `(${parsedFilteredCoverage} /
                      ${sortedResponsesLength})`
        }}
      </span>
    </button>
    <DialogPopup
      class="theme__popup-dialog"
      :class="isThemelist == true ? 'theme__popup-dialog-left' : ''"
      header-tag="h4"
      :show="showDialog"
      @close="showDialog = false"
      v-if="Object.keys(numeratorDetails).length > 0"
      style="width: 20rem"
    >
      <template #header>
        <div
          style="
            display: flex;
            flex-direction: row;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
          "
        >
          Coverage for the theme:
        </div>
        <span
          class="theme__popup-dialog-name"
          style="
            font-family: Core-Sans;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
            margin-left: 0.5rem;
            text-decoration: none;
          "
          >&nbsp;{{ theme.name }}</span
        >
      </template>
      <template #body>
        <div v-if="theme">
          <div
            v-if="
              !theme.coverage_count ||
              (hasFilter && !theme.filtered_coverage_count)
            "
          >
            <VisibleText>No matches</VisibleText>
          </div>
          <div
            style="
              text-align: left;
              display: flex;
              flex-direction: column;
              text-transform: none;
              padding-left: 0.5rem;
            "
          >
            <div v-if="numeratorDetails.total" style="display: flex">
              The total of&nbsp;{{ numeratorDetails.total }} unique text entries
              comes from:
            </div>
            <ul style="margin-left: -1.25rem">
              <li v-if="numeratorDetails.direct_matches">
                {{ numeratorDetails.direct_matches }} &nbsp;have at least one
                keyword match
              </li>
              <li v-if="numeratorDetails.dropped">
                {{ numeratorDetails.dropped }} &nbsp;have keyword matches but
                dropped
              </li>
              <li v-if="numeratorDetails.notes">
                {{ numeratorDetails.notes }} &nbsp;have unique sentence matches
              </li>
              <li v-if="numeratorDetails.notes_overlapping">
                {{ numeratorDetails.notes_overlapping }} &nbsp;have sentence(s)
                overlapping within themes and therefore not counted
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="display: flex; padding-left: 1rem"
          v-if="hasFetchedLemmas && includeSmartCoverage == 'include'"
        >
          <VisibleText
            >Smart search on. Including keyword matches across tenses and
            plural/singular form.</VisibleText
          >
        </div>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"

export default {
  name: "CoveragePopup",
  components: {
    DialogPopup
  },
  props: {
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    isThemelist: {
      default: () => false,
      type: Boolean
    },
    index: {
      default: () => -1,
      type: Number
    },
    theme: {
      default: () => {},
      type: Object
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    }
  },
  data() {
    return {
      showDialog: false,
      numeratorDetails: {}
    }
  },
  created() {
    this.updateDetails()
  },
  computed: {
    parsedCoverage() {
      if (this.theme.coverage_count > -1) {
        return this.theme.coverage_count
      }
      return 0
    },
    parsedFilteredCoverage() {
      if (this.theme.filtered_coverage_count >= 0) {
        return this.theme.filtered_coverage_count
      }
      return -1
    },
    hasFilter() {
      if (
        this.theme.filtered_coverage &&
        Object.keys(this.theme.filtered_coverage).length
      ) {
        return true
      }
      return false
    },
    percentage() {
      let percentage = 0
      if (this.hasFilter) {
        percentage =
          (this.parsedFilteredCoverage / this.sortedResponsesLength) * 100
        return percentage.toFixed(1)
      }
      percentage = (this.parsedCoverage / this.sortedResponsesLength) * 100
      return percentage.toFixed(1)
    }
  },
  methods: {
    updateDetails() {
      if (this.hasFilter && this.theme.filtered_coverage) {
        this.numeratorDetails = this.theme.filtered_coverage
      } else {
        this.numeratorDetails = this.theme.coverage
      }
    },
    offsetByPage(index) {
      return (this.pagination.page - 1) * this.pagination.limit + index
    },
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      if (this.theme) {
        this.showDialog = true
      }
    }
  },
  watch: {
    parsedCoverage: async function () {
      this.updateDetails()
    },
    parsedFilteredCoverage: async function () {
      this.updateDetails()
    }
  }
}
</script>

<style scoped>
.theme__popup-dialog {
  max-width: 32rem;
  width: calc(100% * 3);
}
</style>
