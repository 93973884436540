<template>
  <div class="projects__warning">
    <span class="label">
      <VisibleText>warning</VisibleText>
    </span>
    <VisibleText
      >Dataset deleted. You can still access reports but cannot run any
      analysis.
    </VisibleText>
  </div>
</template>

<script>
export default {
  name: "ProjectsItemDatasetDeleted"
}
</script>
