<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <AnalysisSettings
        ref="AnalysisSettings"
        :propReadOnly="propReadOnly"
        :statusDisableActionBtns="statusDisableActionBtns"
        :index="index"
        :thisAnalysisName="textAnalysisTitle"
        :isTextAnalysis="true"
        :savedNodes="this.savedNodes"
        :contentChanged="this.contentChanged"
        :lastModified="
          formatDateTime(
            textAnalysisObject.last_modified_on,
            $store.getters.getLanguage
          )
        "
        @toggled="isExpanded = !isExpanded"
        @save-to-report="save"
        @on-click-delete="onClickDelete"
        @saved-slide="updateSavedSlide"
      ></AnalysisSettings>
      <!--------------------------------------------
      | CONTENT
      --------------------------------------------->
      <div v-if="isExpanded">
        <LoadingSpinner
          :isLoading="isLoadingData"
          :componentStyle="true"
          :loadingDescription="'Please wait while we fetch your questions and related data.'"
          style="position: relative; padding-left: 1rem"
          :style="{ 'min-height': minHeight }"
        />
      </div>
      <div v-if="showOldVersionWarning">
        <MessageBlock class="banner-msg" message-type="information">
          <p>
            <VisibleText
              >Warning, your dataset needs to be reconfigured for the latest
              version. Please wait.</VisibleText
            >
          </p>
          <p>
            <VisibleText
              >After this completes, you may need to refresh you
              browser.</VisibleText
            >
          </p>
        </MessageBlock>
      </div>
      <div v-if="!isLoadingData && !showOldVersionWarning">
        <div
          v-if="
            textAnalysisObject.showAdvancedOptions &&
            textAnalysisObject.showAdvancedOptions === 'show'
          "
        >
          <MessageBlock
            class="banner-msg"
            message-type="information"
            v-if="isExpanded"
          >
            <template v-slot:default>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    font-size: 0.8rem;
                  "
                >
                  <div style="height: 2rem">
                    <VisibleText>
                      <span>We use lemmas to support&nbsp;</span>
                      <span>
                        <button
                          type="button"
                          style="margin-left: 0.25rem 	background: none;
                          text-decoration-line: underline;
                          background-color: transparent;
                          color: inherit;
                          border: none;
                          padding: 0;
                          font: inherit;
                          cursor: pointer;
                          outline: inherit; margin-right: 1rem;"
                          class="open-btn"
                          @click="showLemmasInfo = true"
                        >
                          smart coverage
                        </button>
                      </span>
                    </VisibleText>
                    <span
                      v-if="!hasFetchedLemmas"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                      ><SvgIconDecorative icon="error" />
                      <VisibleText>&nbsp;No lemmas generated. </VisibleText>
                      <button
                        class="btn-secondary"
                        @click="fetchLemmas"
                        style="text-decoration: underline; padding: 0 1rem"
                        :disabled="isDatasetReadOnly"
                      >
                        <VisibleText v-if="!isDatasetReadOnly"
                          >Fetch</VisibleText
                        >
                        <VisibleText v-if="isDatasetReadOnly"
                          >No permission to generate</VisibleText
                        >
                      </button>
                      <VisibleText
                        class="message__block--warning"
                        style="padding: 0.1rem"
                        v-if="
                          textAnalysisObject.selectedTextQuestion.chars &&
                          textAnalysisObject.selectedTextQuestion.chars >
                            5000000
                        "
                        >Warning! This text question includes&nbsp;
                        {{ this.textAnalysisObject.selectedTextQuestion.chars }}
                        characters but we are optimized for 5,000,000.
                        Generation and performance will be slow!</VisibleText
                      >
                    </span>
                    <span
                      v-if="hasFetchedLemmas"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                    >
                      <SvgIconDecorative icon="success" />
                      <VisibleText style="padding-right: 1rem"
                        >&nbsp;Lemmas generated.
                      </VisibleText>
                    </span>

                    <span
                      v-if="
                        awaitingLemmasFetch && awaitingLemmasFetch == 'waiting'
                      "
                      class="also-found__loading-spinner spinner"
                    ></span>
                  </div>

                  <div style="height: 2rem">
                    <VisibleText>
                      <span
                        >We use pre-trained emotional states to
                        support&nbsp;</span
                      >
                      <span>
                        <button
                          type="button"
                          style="margin-left: 0.25rem 	background: none;
                          text-decoration-line: underline;
                          background-color: transparent;
                          color: inherit;
                          border: none;
                          padding: 0;
                          font: inherit;
                          cursor: pointer;
                          outline: inherit; margin-right: 1rem;"
                          class="open-btn"
                          @click="showSentimentsInfo = true"
                        >
                          sentiment analysis
                        </button></span
                      >
                    </VisibleText>
                    <span
                      v-if="!hasFetchedSentiments"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                    >
                      <SvgIconDecorative icon="error" />
                      <VisibleText>&nbsp;No sentiments generated. </VisibleText>
                      <button
                        class="btn-secondary"
                        @click="fetchSentiments"
                        style="text-decoration: underline; padding: 0 1rem"
                        :disabled="isDatasetReadOnly"
                      >
                        <VisibleText v-if="!isDatasetReadOnly"
                          >Fetch</VisibleText
                        >
                        <VisibleText v-if="isDatasetReadOnly"
                          >No permission to generate</VisibleText
                        >
                      </button>
                      <VisibleText
                        class="message__block--warning"
                        style="padding: 0.1rem"
                        v-if="
                          textAnalysisObject.selectedTextQuestion.chars &&
                          textAnalysisObject.selectedTextQuestion.chars >
                            5000000
                        "
                        >Warning! This text question includes&nbsp;
                        {{ this.textAnalysisObject.selectedTextQuestion.chars }}
                        characters but we are optimized for 5,000,000.
                        Generation and performance will be slow!</VisibleText
                      >
                    </span>
                    <span
                      v-if="hasFetchedSentiments"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                    >
                      <SvgIconDecorative icon="success" />
                      <VisibleText style="padding-right: 1rem"
                        >&nbsp;Sentiments generated.
                      </VisibleText>
                    </span>

                    <span
                      v-if="
                        awaitingSentimentsFetch &&
                        awaitingSentimentsFetch == 'waiting'
                      "
                      class="also-found__loading-spinner spinner"
                    ></span>
                  </div>

                  <div style="height: 2rem">
                    <VisibleText>
                      <span>We preprocess sentences to support&nbsp;</span>
                      <span>
                        <button
                          type="button"
                          style="margin-left: 0.25rem 	background: none;
                          text-decoration-line: underline;
                          background-color: transparent;
                          color: inherit;
                          border: none;
                          padding: 0;
                          font: inherit;
                          cursor: pointer;
                          outline: inherit; margin-right: 1rem;"
                          class="open-btn"
                          @click="showSentencesInfo = true"
                        >
                          sentence matching
                        </button></span
                      >
                    </VisibleText>
                    <span
                      v-if="!hasFetchedSentences"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                    >
                      <SvgIconDecorative icon="error" />
                      <VisibleText
                        >&nbsp;Sentences not preprocessed.
                      </VisibleText>
                      <button
                        class="btn-secondary"
                        @click="fetchSentences"
                        style="text-decoration: underline; padding: 0 1rem"
                        :disabled="isDatasetReadOnly"
                      >
                        <VisibleText v-if="!isDatasetReadOnly"
                          >Fetch</VisibleText
                        >
                        <VisibleText v-if="isDatasetReadOnly"
                          >No permission to preprocess</VisibleText
                        >
                      </button>
                      <VisibleText
                        class="message__block--warning"
                        style="padding: 0.1rem"
                        v-if="
                          textAnalysisObject.selectedTextQuestion.chars &&
                          textAnalysisObject.selectedTextQuestion.chars >
                            5000000
                        "
                        >Warning! This text question includes&nbsp;
                        {{ this.textAnalysisObject.selectedTextQuestion.chars }}
                        characters but we are optimized for 5,000,000.
                        Generation and performance will be slow!</VisibleText
                      >
                    </span>
                    <span
                      v-if="hasFetchedSentences"
                      style="
                        border-radius: 0.5em;
                        border: 1px solid transparent;
                        border-color: var(--outline-light-colour);
                        background-color: white;
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                      "
                    >
                      <SvgIconDecorative icon="success" />
                      <VisibleText style="padding-right: 1rem"
                        >&nbsp;Sentences generated.
                      </VisibleText>
                    </span>

                    <span
                      v-if="
                        awaitingSentencesFetch &&
                        awaitingSentencesFetch == 'waiting'
                      "
                      class="also-found__loading-spinner spinner"
                    ></span>
                  </div>
                </div>

                <div style="display: flex">
                  <button
                    @click="setShowAdvancedOptions('hide')"
                    style="
                      background-color: transparent;
                      border-color: transparent;
                      color: var(--primary);
                    "
                  >
                    <VisibleText
                      ><strong><SvgIconDecorative icon="remove" /></strong
                    ></VisibleText>
                  </button>
                </div>
              </div>
            </template>
          </MessageBlock>
        </div>
        <LemmasMiniGuide
          @closingGuide="showLemmasInfo = false"
          :showLemmasInfo="showLemmasInfo"
          style="transform: translateY(60%)"
        ></LemmasMiniGuide>

        <SentimentsMiniGuide
          @closingGuide="showSentimentsInfo = false"
          :showSentimentsInfo="showSentimentsInfo"
          style="transform: translateY(60%)"
        ></SentimentsMiniGuide>

        <SentencesMiniGuide
          @closingGuide="showSentencesInfo = false"
          :showSentencesInfo="showSentencesInfo"
          style="transform: translateY(60%)"
        ></SentencesMiniGuide>

        <ProjectAnalysisTextTheme
          ref="ProjectAnalysisTextThemeRef"
          v-if="sortedThemes && isExpanded && sortedResponses"
          :sortedResponsesLength="unsortedResponses.length"
          :unsortedResponses="unsortedResponses"
          :textAnalysisObject="textAnalysisObject"
          :includeSmartCoverage="textAnalysisObject.includeSmartCoverage"
          :showAdvancedOptions="showAdvancedOptions"
          :sortedThemes="sortedThemes"
          :themesReloading="themesReloading"
          @setShowAdvancedOptions="setShowAdvancedOptions($event)"
          @updateAllCharts="updateAllCharts($event)"
          :allCharts="
            textAnalysisObject.allCharts ? textAnalysisObject.allCharts : []
          "
          :hasFetchedLemmas="hasFetchedLemmas"
          :hasFetchedSentiments="hasFetchedSentiments"
          :propReadOnly="propReadOnly"
          :hasFetchedSentences="hasFetchedSentences"
          @saveTextToProject="saveTextToProject"
          @updateTextObject="updateTextObject($event)"
          @updateTheseThemes="updateTheseThemes($event)"
          @updateThisCoverage="updateThisCoverage($event)"
          @updateAllCoverages="updateAllCoverages"
          @updateSortOrder="updateThemeSortOrder($event)"
          @updateSortType="updateThemeSortType($event)"
          @removeTheme="removeTheme($event)"
        />
        <ProjectAnalysisTextMain
          ref="ProjectAnalysisTextMainRef"
          v-if="textAnalysisObject.uuid && sortedResponses && isExpanded"
          :textAnalysisObject="textAnalysisObject"
          :sortedResponsesLength="unsortedResponses.length"
          :unsortedResponses="unsortedResponses"
          :includeSmartCoverage="textAnalysisObject.includeSmartCoverage"
          :hasFetchedLemmas="hasFetchedLemmas"
          :hasFetchedSentiments="hasFetchedSentiments"
          :hasFetchedSentences="hasFetchedSentences"
          :datasetIDQuestions="datasetIDQuestions"
          :sortedResponsesProp="sortedResponses"
          :propReadOnly="propReadOnly"
          :sortedThemes="sortedThemes"
          :showResponseListLoadingSpinner="showResponseListLoadingSpinner"
          @runSortAndSelect="runSortAndSelect"
          @updateTextObject="updateTextObject($event)"
          @updateTheseThemes="updateTheseThemes($event)"
          @updateFilterParam="updateFilterParam($event)"
          @removeThisFilter="removeThisFilter($event)"
          @emitSortAndSelect="runSortAndSelect"
          @findSimilar="findSimilar"
          :searchingForWordAssociations="searchingForWordAssociations"
        />
      </div>
    </article>
  </div>
</template>

<script>
// Components
import AnalysisSettings from "@/components/Project/ProjectAnalysis/Components/AnalysisSettings.vue"
import ProjectAnalysisTextTheme from "./ProjectAnalysisTextTheme.vue"
import ProjectAnalysisTextMain from "./ProjectAnalysisTextMain/ProjectAnalysisTextMain.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import LemmasMiniGuide from "@/components/UserGuide/LemmasMiniGuide.vue"
import SentimentsMiniGuide from "@/components/UserGuide/SentimentsMiniGuide.vue"
import SentencesMiniGuide from "@/components/UserGuide/SentencesMiniGuide.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import ProjectReportMixin from "@/components/Project/Mixins/projectReportMixin.js"
import textAnalysisMixin from "./Mixins/textAnalysisMixin.js"
import themeColorsMixin from "./Mixins/themeColorsMixin.js"
import ThemeListMixin from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/ProjectAnalysisTextMain/Mixins/themeListMixin.js"
import FiltersMixin from "@/utils/mixins/userMixin.js"

// Helpers
import moment from "moment"
import { PigeonDocModel } from "@pigeonline/pigeondoc"

export default {
  name: "ProjectAnalysisTextItem",
  mixins: [
    DatasetDetailsMixin,
    ProjectMixin,
    ProjectReportMixin,
    textAnalysisMixin,
    ThemeListMixin,
    themeColorsMixin,
    FiltersMixin
  ],
  props: {
    index: {
      type: Number,
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    },
    isDatasetReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  components: {
    AnalysisSettings,
    MessageBlock,
    ProjectAnalysisTextTheme,
    ProjectAnalysisTextMain,
    LoadingSpinner,
    SvgIconDecorative,
    LemmasMiniGuide,
    SentimentsMiniGuide,
    SentencesMiniGuide
  },
  data() {
    return {
      savedSlide: "",
      savedNodes: [],
      textAnalysisObject: {},
      unsortedResponses: [],
      sortedResponses: [],
      unsortedThemes: [],
      sortedThemes: [],
      isLoadingData: false,
      isExpanded: false,
      contentChanged: false,
      awaitingSentimentsFetch: "",
      awaitingLemmasFetch: "",
      awaitingSentencesFetch: "",
      showSentimentsInfo: false,
      showSentencesInfo: false,
      showLemmasInfo: false,
      showResponseListLoadingSpinner: false,
      showOldVersionWarning: false,
      isFetchingLemmas: false,
      isFetchingSentiments: false,
      searchingForWordAssociations: "",
      themesReloading: []
    }
  },

  async created() {
    if (this.project) {
      this.isLoadingData = true
      const refreshedTextAnalysisObject = await this.refreshTextAnalysisObject()
      if (refreshedTextAnalysisObject == true) {
        if (this.textAnalysisObject) {
          // Check if it has been saved to a Report before (save_node_ids are created upon report saving)
          if (this.textAnalysisObject.saved_node_ids) {
            this.savedNodes = this.textAnalysisObject.saved_node_ids
          }
          // Successful object has a Selected Text Question
          if (this.textAnalysisObject.selectedTextQuestion) {
            //this.updateUnsortedResponses()
            let selectedTextQuestion = this.deepCloneObj(
              this.textAnalysisObject.selectedTextQuestion
            )
            if (selectedTextQuestion._id.$oid) {
              selectedTextQuestion._id = selectedTextQuestion._id.$oid
            }
            // Ensure the text question has 1.7.0 meta-data. If not, tell the user
            if (!Object.keys(selectedTextQuestion).includes("added_count")) {
              this.showOldVersionWarning = true
              await this.regenerateTextData(selectedTextQuestion)

              if (
                !this.textAnalysisObject.themes ||
                !this.textAnalysisObject.themes.length
              ) {
                this.showOldVersionWarning = false
              }
            }
            if (
              this.textAnalysisObject.themes &&
              this.textAnalysisObject.themes.length
            ) {
              this.showOldVersionWarning = true
              await this.removeOldThemes()
              this.showOldVersionWarning = false
            }
            if (!this.textAnalysisObject.adjustedSentiments) {
              this.textAnalysisObject.adjustedSentiments = []
            }

            // Add new fields or remove deprecated ones
            if (
              !this.textAnalysisObject.selectedThemeUUIDs ||
              !Array.isArray(this.textAnalysisObject.selectedThemeUUIDs)
            ) {
              this.textAnalysisObject.selectedThemeUUIDs = []
            }
            if (
              this.textAnalysisObject.themesSortOrder == "created_first" ||
              this.textAnalysisObject.themesSortOrder == "created_last"
            ) {
              this.textAnalysisObject.themesSortOrder = "descending"
            }
            if (!this.textAnalysisObject.themesSortType) {
              this.textAnalysisObject.themesSortType = "created"
            }
            await this.runFetchTextQuestionResponses()
          }
        }
      }
      this.isLoadingData = false
    }
  },
  computed: {
    showAdvancedOptions() {
      if (this.textAnalysisObject.showAdvancedOptions) {
        return this.textAnalysisObject.showAdvancedOptions
      } else {
        return "hide"
      }
    },
    hasFetchedLemmas() {
      if (this.textAnalysisObject.selectedTextQuestion) {
        if (this.textAnalysisObject.selectedTextQuestion.fetched_lemmas) {
          return true
        }
      }
      return false
    },
    hasFetchedSentiments() {
      if (this.textAnalysisObject.selectedTextQuestion) {
        if (this.textAnalysisObject.selectedTextQuestion.fetched_sentiments) {
          return true
        }
      }
      return false
    },
    hasFetchedSentences() {
      if (this.textAnalysisObject.selectedTextQuestion) {
        if (
          this.textAnalysisObject.selectedTextQuestion
            .sentence_tokenized_and_vectorized
        ) {
          return true
        }
      }
      return false
    },
    statusDisableActionBtns() {
      return this.isLoadingData || !this.unsortedThemes.length
    },
    textAnalysisTitle() {
      let textTitle = ""
      if (this.textAnalysisObject) {
        if (this.textAnalysisObject.selectedTextQuestion) {
          if (
            this.textAnalysisObject.selectedTextQuestion.question_title.length
          ) {
            textTitle =
              this.textAnalysisObject.selectedTextQuestion.question_title
          }
          if (
            this.textAnalysisObject.selectedTextQuestion.question_title
              .length &&
            this.textAnalysisObject.selectedTextQuestion.question_text.length
          ) {
            textTitle += " - "
          }
          if (
            this.textAnalysisObject.selectedTextQuestion.question_text.length
          ) {
            textTitle +=
              this.textAnalysisObject.selectedTextQuestion.question_text
          }
        }
      }
      return textTitle
    },
    selectedThemeUUIDs() {
      let uuid = []
      if (this.textAnalysisObject.selectedThemeUUIDs) {
        uuid = this.textAnalysisObject.selectedThemeUUIDs
      }
      return uuid
    },
    childChangeInContent() {
      return this.textAnalysisObject.thisContentChanged
    },
    minHeight() {
      if (this.isLoadingData) {
        return "5em"
      } else {
        return "0"
      }
    }
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    // Connects this.textAnalysisObject to its associated project
    async refreshTextAnalysisObject() {
      if (this.project.textAnalysisObjects.length) {
        // Load text analysis from the project object
        for (let i = 0; i < this.project.textAnalysisObjects.length; i++) {
          let modifiedProject = this.deepCloneObj(
            this.project.textAnalysisObjects[i]
          )
          if (modifiedProject.uuid == this.uuid) {
            this.textAnalysisObject = this.deepCloneObj(modifiedProject)
            return true
          }
        }
      }
      return false
    },
    async runFetchTextQuestionResponses() {
      this.isLoadingData = true
      let selectedTextQuestion = this.deepCloneObj(
        this.textAnalysisObject.selectedTextQuestion
      )
      if (selectedTextQuestion._id.$oid) {
        selectedTextQuestion._id = selectedTextQuestion._id.$oid
      }
      let fetchedQuestionResponses = []
      // Responses come from another API request
      fetchedQuestionResponses = await this.fetchTextQuestionResponses(
        selectedTextQuestion,
        this.textAnalysisObject.filterParams
      )

      if (fetchedQuestionResponses && fetchedQuestionResponses.length) {
        for (let i = 0; i < fetchedQuestionResponses.length; i++) {
          let id
          if (fetchedQuestionResponses[i].id) {
            id = fetchedQuestionResponses[i].id
            if (fetchedQuestionResponses[i].id.$oid) {
              id = fetchedQuestionResponses[i].id.$oid
            }
          }
          if (fetchedQuestionResponses[i]._id) {
            id = fetchedQuestionResponses[i]._id
            if (fetchedQuestionResponses[i]._id.$oid) {
              id = fetchedQuestionResponses[i]._id.$oid
            }
            delete fetchedQuestionResponses[i]._id
          }
          fetchedQuestionResponses[i].id = id
        }
        // This is an important variable, used for all response rendering and sorting
        this.unsortedResponses = fetchedQuestionResponses

        let payload = {
          text_analysis_uuid: this.textAnalysisObject.uuid
        }

        const fetchedThemes = await this.TEXT_SERVICE.fetchTextThemes(payload)

        if (fetchedThemes && fetchedThemes.length) {
          let found_colors = fetchedThemes.map((theme) => theme.theme_color)

          for (let i = 0; i < fetchedThemes.length; i++) {
            let id
            if (fetchedThemes[i]._id) {
              id = fetchedThemes[i]._id
              if (fetchedThemes[i]._id.$oid) {
                id = fetchedThemes[i]._id.$oid
              }
            }
            fetchedThemes[i]._id = id

            // Check the theme if it is missing a color, and apply
            if (!fetchedThemes[i].theme_color) {
              let new_color = await this.assignColor(found_colors)
              fetchedThemes[i].theme_color = new_color
              found_colors.push(fetchedThemes[i].theme_color)
            }
          }
        }

        this.unsortedThemes = fetchedThemes
        this.sortThemes()

        this.cleanAndSaveProject(true)

        // Apply settings (such as to legacy projects that do not have these settings)
        await this.openAdvancedOptions()
      }
      await this.runSortAndSelect()
    },
    async regenerateTextData(selectedTextQuestion) {
      await this.setActiveTab("Themes")

      await this.$services.DATASETS_SERVICE.updateClientQuestion(
        selectedTextQuestion._id,
        {
          approved_data_type: "OPEN_ENDED",
          suggested_data_type: selectedTextQuestion.suggested_data_type,
          scale_range: null,
          unique_values: []
        }
      )

      let updatedClientQuestions = await this.updateClientQuestions(
        selectedTextQuestion.data_set_id
      )
      for (let i = 0; i < updatedClientQuestions.length; i++) {
        if (updatedClientQuestions[i]._id) {
          let newID = updatedClientQuestions[i]._id
          if (newID.$oid) {
            newID = newID.$oid
          }
          if (newID == selectedTextQuestion._id) {
            this.textAnalysisObject.selectedTextQuestion =
              updatedClientQuestions[i]
            await this.saveSelectedTextQuestion({
              selectedTextQuestion:
                this.textAnalysisObject.selectedTextQuestion,
              forceCompute: false,
              uuid: this.textAnalysisObject.uuid
            })
            break
          }
        }
      }
    },
    async removeOldThemes() {
      let found_old_themes = []
      if (
        this.textAnalysisObject.themes &&
        this.textAnalysisObject.themes.length
      ) {
        for (let i = 0; i < this.textAnalysisObject.themes.length; i++) {
          let filter_params = {}
          if (this.textAnalysisObject.filterParams) {
            filter_params = this.textAnalysisObject.filterParams
          }
          let smart_coverage = false
          if (
            this.textAnalysisObject.includeSmartCoverage &&
            this.textAnalysisObject.includeSmartCoverage === "include"
          ) {
            smart_coverage = true
          }
          // These are used so the backend can detect banned comments (and will drop them from the responses loop)
          let proj_id = this.project.id
          if (this.project.id.$oid) {
            proj_id = this.project.id.$oid
          }
          let payload = {
            client_question_id: this.selectedTextQuestionId,
            keywords: this.textAnalysisObject.themes[i].keywords,
            filter_params: filter_params,
            data_set_id: this.textAnalysisObject.datasetId,
            smart_coverage: smart_coverage,
            project_id: proj_id, // used so the backend can detect banned comments
            text_analysis_uuid: this.textAnalysisObject.uuid // used so the backend can detect banned comments
          }
          let thisEdit = this.deepCloneObj(this.textAnalysisObject.themes[i])
          if (thisEdit._id) {
            if (thisEdit._id.$oid) {
              thisEdit._id = thisEdit._id.$oid
            }
            payload["theme_id"] = thisEdit._id
            payload["project_id"] = this.project.id
          }

          const thisThemeResponses = await this.TEXT_SERVICE.filterTheme(
            payload
          ).then((response) => {
            return response
          })
          if (thisThemeResponses) {
            thisEdit.coverage = thisThemeResponses.coverage
            thisEdit.coverage_count = thisThemeResponses.coverage.total
            if (
              thisThemeResponses.filtered_coverage &&
              Object.keys(thisThemeResponses.filtered_coverage).length
            ) {
              thisEdit.filtered_coverage = thisThemeResponses.filtered_coverage
              thisEdit.filtered_coverage_count =
                thisThemeResponses.filtered_coverage.total
            } else {
              thisEdit.filtered_coverage = {}
              thisEdit.filtered_coverage_count = -1
            }
            thisEdit.text_analysis_uuid = this.textAnalysisObject.uuid
            thisEdit.keyword_matches = thisThemeResponses.keyword_matches
            if (thisThemeResponses.filtered_keyword_matches) {
              thisEdit.filtered_keyword_matches =
                thisThemeResponses.filtered_keyword_matches
            }
            if (thisEdit.is_proposed) {
              delete thisEdit.is_proposed
            }
            if (thisEdit.is_autogenerated) {
              delete thisEdit.is_autogenerated
            }
            found_old_themes.push(thisEdit)
          }
        }
        if (found_old_themes.length) {
          let save_payload = {
            themes: found_old_themes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          await this.TEXT_SERVICE.saveThemes(save_payload).then((response) => {
            return response
          })
        }
        this.textAnalysisObject.themes = []
        await this.saveTextThemes({
          themes: [],
          forceCompute: false,
          uuid: this.textAnalysisObject.uuid
        })
        this.setSelectedThemeUUIDs([])
        return "removed: " + this.textAnalysisObject.themes.length
      }
      return "none"
    },
    async updateThemeSortOrder(newSortOrder) {
      if (!newSortOrder) return
      this.textAnalysisObject.themesSortOrder = newSortOrder
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.textAnalysisObjects[indx].themesSortOrder =
          this.textAnalysisObject.themesSortOrder
        this.project.textAnalysisObjects[indx].last_modified_on =
          moment().valueOf()
        await this.cleanAndSaveProject()
      }
      await this.sortThemes()
      if (this.$refs.ProjectAnalysisTextThemeRef) {
        this.$refs.ProjectAnalysisTextThemeRef.refreshThemesFromParent()
      }
      if (this.$refs.ProjectAnalysisTextMainRef) {
        this.$refs.ProjectAnalysisTextMainRef.refreshThemesFromParent()
      }
    },
    async updateThemeSortType(newSortType) {
      if (!newSortType) return
      this.textAnalysisObject.themesSortType = newSortType
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.textAnalysisObjects[indx].themesSortType =
          this.textAnalysisObject.themesSortType
        this.project.textAnalysisObjects[indx].last_modified_on =
          moment().valueOf()
        await this.cleanAndSaveProject()
      }
      await this.sortThemes()
      if (this.$refs.ProjectAnalysisTextThemeRef) {
        this.$refs.ProjectAnalysisTextThemeRef.refreshThemesFromParent()
      }
      if (this.$refs.ProjectAnalysisTextMainRef) {
        this.$refs.ProjectAnalysisTextMainRef.refreshThemesFromParent()
      }
    },
    // Lemmas are not sent to the frontend, but we can ask the backend to generate lemmas for this dataset.
    async fetchLemmas() {
      if (
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion._id
      ) {
        let questionId = this.textAnalysisObject.selectedTextQuestion._id
        if (questionId.$oid) {
          questionId = questionId.$oid
        }
        const datasetId =
          this.textAnalysisObject.selectedTextQuestion.data_set_id

        this.awaitingLemmasFetch = "waiting"
        await this.DATASETS_SERVICE.addLemmasToResponses({
          client_question_id: questionId
        })
        // update to find "hasFetchedLemmas"
        let updatedClientQuestions = await this.updateClientQuestions(datasetId)
        for (let i = 0; i < updatedClientQuestions.length; i++) {
          if (updatedClientQuestions[i]._id) {
            let newID = updatedClientQuestions[i]._id
            if (newID.$oid) {
              newID = newID.$oid
            }
            if (newID == questionId) {
              let this_selected_question = this.deepCloneObj(
                updatedClientQuestions[i]
              )
              if (
                this_selected_question._id &&
                this_selected_question._id.$oid
              ) {
                this_selected_question._id = this_selected_question._id.$oid
              }
              this.textAnalysisObject.selectedTextQuestion =
                this_selected_question
              await this.saveSelectedTextQuestion({
                selectedTextQuestion:
                  this.textAnalysisObject.selectedTextQuestion,
                forceCompute: false,
                uuid: this.textAnalysisObject.uuid
              })
              break
            }
          }
        }
        this.awaitingLemmasFetch = ""
      }
    },
    // Sentiments can be generated for this dataset and sent to the frontend
    async fetchSentiments() {
      if (
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion._id
      ) {
        let questionId = this.textAnalysisObject.selectedTextQuestion._id
        if (questionId.$oid) {
          questionId = questionId.$oid
        }
        const datasetId =
          this.textAnalysisObject.selectedTextQuestion.data_set_id

        this.awaitingSentimentsFetch = "waiting"

        await this.DATASETS_SERVICE.addSentimentsToResponses({
          client_question_id: questionId
        })

        let updatedClientQuestions = await this.updateClientQuestions(datasetId)
        for (let i = 0; i < updatedClientQuestions.length; i++) {
          if (updatedClientQuestions[i]._id) {
            let newID = updatedClientQuestions[i]._id
            if (updatedClientQuestions[i]._id.$oid) {
              newID = updatedClientQuestions[i]._id.$oid
            }
            if (newID == questionId) {
              let this_selected_question = this.deepCloneObj(
                updatedClientQuestions[i]
              )
              if (
                this_selected_question._id &&
                this_selected_question._id.$oid
              ) {
                this_selected_question._id = this_selected_question._id.$oid
              }

              // Save updated question details
              this.textAnalysisObject.selectedTextQuestion =
                this_selected_question
              await this.saveSelectedTextQuestion({
                selectedTextQuestion:
                  this.textAnalysisObject.selectedTextQuestion,
                forceCompute: false,
                uuid: this.textAnalysisObject.uuid
              })

              if (this.textAnalysisObject.selectedTextQuestion) {
                //this.updateUnsortedResponses()
                let fetchedQuestionResponses = []
                let selectedTextQuestion = this.deepCloneObj(
                  this.textAnalysisObject.selectedTextQuestion
                )
                if (selectedTextQuestion._id.$oid) {
                  selectedTextQuestion._id = selectedTextQuestion._id.$oid
                }
                // Responses come from another API request
                fetchedQuestionResponses =
                  await this.fetchTextQuestionResponses(
                    selectedTextQuestion,
                    this.textAnalysisObject.filterParams
                  )
                if (
                  fetchedQuestionResponses &&
                  fetchedQuestionResponses.length
                ) {
                  for (let i = 0; i < fetchedQuestionResponses.length; i++) {
                    let id
                    if (fetchedQuestionResponses[i].id) {
                      id = fetchedQuestionResponses[i].id
                      if (fetchedQuestionResponses[i].id.$oid) {
                        id = fetchedQuestionResponses[i].id.$oid
                      }
                    }
                    if (fetchedQuestionResponses[i]._id) {
                      id = fetchedQuestionResponses[i]._id
                      if (fetchedQuestionResponses[i]._id.$oid) {
                        id = fetchedQuestionResponses[i]._id.$oid
                      }
                      delete fetchedQuestionResponses[i]._id
                    }
                    fetchedQuestionResponses[i].id = id
                  }
                  // This is an important variable, used for all response rendering and sorting
                  this.unsortedResponses = fetchedQuestionResponses
                }
              }

              await this.includeExcludeSentiments("include")

              break
            }
          }
        }
        this.awaitingSentimentsFetch = ""
      }
    },
    // Preprocessed sentences can be generated for this dataset and sent to the frontend
    async fetchSentences() {
      if (
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion._id
      ) {
        let questionId = this.textAnalysisObject.selectedTextQuestion._id
        if (questionId.$oid) {
          questionId = questionId.$oid
        }
        const datasetId =
          this.textAnalysisObject.selectedTextQuestion.data_set_id

        this.awaitingSentencesFetch = "waiting"

        await this.DATASETS_SERVICE.addSentenceTokensToResponses({
          client_question_id: questionId
        })

        let updatedClientQuestions = await this.updateClientQuestions(datasetId)
        for (let i = 0; i < updatedClientQuestions.length; i++) {
          if (updatedClientQuestions[i]._id) {
            let newID = updatedClientQuestions[i]._id
            if (updatedClientQuestions[i]._id.$oid) {
              newID = updatedClientQuestions[i]._id.$oid
            }
            if (newID == questionId) {
              let this_selected_question = this.deepCloneObj(
                updatedClientQuestions[i]
              )
              if (
                this_selected_question._id &&
                this_selected_question._id.$oid
              ) {
                this_selected_question._id = this_selected_question._id.$oid
              }

              // Save updated question details
              this.textAnalysisObject.selectedTextQuestion =
                this_selected_question
              await this.saveSelectedTextQuestion({
                selectedTextQuestion:
                  this.textAnalysisObject.selectedTextQuestion,
                forceCompute: false,
                uuid: this.textAnalysisObject.uuid
              })

              if (this.textAnalysisObject.selectedTextQuestion) {
                //this.updateUnsortedResponses()
                let fetchedQuestionResponses = []
                let selectedTextQuestion = this.deepCloneObj(
                  this.textAnalysisObject.selectedTextQuestion
                )
                if (selectedTextQuestion._id.$oid) {
                  selectedTextQuestion._id = selectedTextQuestion._id.$oid
                }
                // Responses come from another API request
                fetchedQuestionResponses =
                  await this.fetchTextQuestionResponses(
                    selectedTextQuestion,
                    this.textAnalysisObject.filterParams
                  )
                if (
                  fetchedQuestionResponses &&
                  fetchedQuestionResponses.length
                ) {
                  for (let i = 0; i < fetchedQuestionResponses.length; i++) {
                    let id
                    if (fetchedQuestionResponses[i].id) {
                      id = fetchedQuestionResponses[i].id
                      if (fetchedQuestionResponses[i].id.$oid) {
                        id = fetchedQuestionResponses[i].id.$oid
                      }
                    }
                    if (fetchedQuestionResponses[i]._id) {
                      id = fetchedQuestionResponses[i]._id
                      if (fetchedQuestionResponses[i]._id.$oid) {
                        id = fetchedQuestionResponses[i]._id.$oid
                      }
                      delete fetchedQuestionResponses[i]._id
                    }
                    fetchedQuestionResponses[i].id = id
                  }
                  // This is an important variable, used for all response rendering and sorting
                  this.unsortedResponses = fetchedQuestionResponses
                }
              }
              break
            }
          }
        }
        this.awaitingSentencesFetch = ""
      }
    },

    async openAdvancedOptions() {
      if (!this.textAnalysisObject.showAdvancedOptions) {
        this.setShowAdvancedOptions("hide")
      }
      if (!this.textAnalysisObject.showCoverageDetails) {
        this.setShowCoverageDetails("show")
      }
    },
    async findSimilar() {
      if (
        this.textAnalysisObject.search.searchString &&
        this.textAnalysisObject.datasetId &&
        this.selectedTextQuestionId
      ) {
        this.searchingForWordAssociations =
          "Searching for words similar to: " +
          this.textAnalysisObject.search.searchString
        const alsoFound = await this.fetchWordAssociations(
          this.textAnalysisObject.search.searchString,
          this.textAnalysisObject.datasetId,
          this.selectedTextQuestionId
        )
        if (alsoFound) {
          this.textAnalysisObject.search.alsoFound = alsoFound
        }
        this.searchingForWordAssociations = ""
      }
    },
    // Some objects depend on textAnalysisObject as a prop, so we update it here when child components update their own version of it
    // This will also scroll towards the middle of the screen
    // TODO better to send textAnalysisObject instead of full project?
    async updateTextObject(object) {
      let project = object.project
      let scroll = object.scroll
      if (project) {
        if (project.textAnalysisObjects.length) {
          for (let i = 0; i < project.textAnalysisObjects.length; i++) {
            if (
              project.textAnalysisObjects[i].uuid ==
              this.textAnalysisObject.uuid
            ) {
              this.textAnalysisObject.selectedThemeUUIDs =
                project.textAnalysisObjects[i].selectedThemeUUIDs
              this.textAnalysisObject.selectedResponseIds =
                project.textAnalysisObjects[i].selectedResponseIds
              this.textAnalysisObject.search =
                project.textAnalysisObjects[i].search
              if (project.textAnalysisObjects[i].expandedResponses) {
                this.textAnalysisObject.expandedResponses =
                  project.textAnalysisObjects[i].expandedResponses
              }
              if (project.textAnalysisObjects[i].hiddenComments) {
                this.textAnalysisObject.hiddenComments =
                  project.textAnalysisObjects[i].hiddenComments
              }
              if (project.textAnalysisObjects[i].bannedComments) {
                this.textAnalysisObject.bannedComments =
                  project.textAnalysisObjects[i].bannedComments
                await this.runFetchTextQuestionResponses()
                await this.updateAllCoverages()
                await this.$ref
              }
              if (project.textAnalysisObjects[i].bannedKeywords) {
                this.textAnalysisObject.bannedKeywords =
                  project.textAnalysisObjects[i].bannedKeywords
              }
              if (project.textAnalysisObjects[i].includeSentiments) {
                this.textAnalysisObject.includeSentiments =
                  project.textAnalysisObjects[i].includeSentiments
              }
              if (project.textAnalysisObjects[i].showAdvancedOptions) {
                this.textAnalysisObject.showAdvancedOptions =
                  project.textAnalysisObjects[i].showAdvancedOptions
              }
              if (project.textAnalysisObjects[i].showCoverageDetails) {
                this.textAnalysisObject.showCoverageDetails =
                  project.textAnalysisObjects[i].showCoverageDetails
              }
              if (project.textAnalysisObjects[i].includeSmartCoverage) {
                this.textAnalysisObject.includeSmartCoverage =
                  project.textAnalysisObjects[i].includeSmartCoverage
              }
              if (project.textAnalysisObjects[i].adjustedSentiments) {
                this.textAnalysisObject.adjustedSentiments =
                  project.textAnalysisObjects[i].adjustedSentiments
              }
              if (project.textAnalysisObjects[i].activeTab) {
                this.textAnalysisObject.activeTab =
                  project.textAnalysisObjects[i].activeTab
              }
              if (project.textAnalysisObjects[i].themeSortOrder) {
                this.textAnalysisObject.themeSortOrder =
                  project.textAnalysisObjects[i].themeSortOrder
              }
              if (project.textAnalysisObjects[i].themeSortType) {
                this.textAnalysisObject.themeSortType =
                  project.textAnalysisObjects[i].themeSortType
              }
              if (project.textAnalysisObjects[i].allCharts) {
                this.textAnalysisObject.allCharts =
                  project.textAnalysisObjects[i].allCharts
              }
              if (
                project.textAnalysisObjects[i].sentimentSortOrder <= 0 ||
                project.textAnalysisObjects[i].sentimentSortOrder > 0
              ) {
                this.textAnalysisObject.sentimentSortOrder =
                  project.textAnalysisObjects[i].sentimentSortOrder
              }

              if (
                scroll == "main" &&
                document.getElementById("project-analysis-text-main")
              ) {
                document
                  .getElementById("project-analysis-text-main")
                  .scrollIntoView()
              }
              if (
                scroll == "themes" &&
                document.getElementById("project-analysis-text-theme")
              ) {
                document
                  .getElementById("project-analysis-text-theme")
                  .scrollIntoView()
              }
              break
            }
          }
        }
      }
    },
    // Some theme saves need to generate a refresh of parents
    async updateTheseThemes(themes) {
      if (themes.themes && themes.themes.length) {
        let theseThemes = this.deepCloneObj(themes.themes)
        // Set up loading graphics - Optimize by making a filter
        if (
          (themes.source && themes.source == "Themes") ||
          themes.source == "Parent" ||
          themes.source == "CreateNote" ||
          themes.source == "Container" ||
          themes.source == "ResponseList"
        ) {
          for (let i = 0; i < theseThemes.length; i++) {
            let loadingTheme = theseThemes[i]._id
            if (loadingTheme.$oid) {
              loadingTheme = loadingTheme.$oid
            }
            this.themesReloading.push(loadingTheme)
          }
          this.showResponseListLoadingSpinner = true
        }

        for (let i = 0; i < theseThemes.length; i++) {
          // Special logic for some theme types
          if (
            themes.source == "Themes" ||
            themes.source == "Parent" ||
            themes.source == "CreateNote" ||
            themes.source == "Container" ||
            themes.source == "ResponseList"
          ) {
            let filter_params = {}
            if (this.textAnalysisObject.filterParams) {
              filter_params = this.textAnalysisObject.filterParams
            }
            let smart_coverage = false
            if (
              this.textAnalysisObject.includeSmartCoverage &&
              this.textAnalysisObject.includeSmartCoverage === "include"
            ) {
              smart_coverage = true
            }
            // These are used so the backend can detect banned comments (and will drop them from the responses loop)
            let proj_id = this.project.id
            if (this.project.id.$oid) {
              proj_id = this.project.id.$oid
            }
            let payload = {
              client_question_id: this.selectedTextQuestionId,
              keywords: theseThemes[i].keywords,
              filter_params: filter_params,
              data_set_id: this.textAnalysisObject.datasetId,
              smart_coverage: smart_coverage,
              project_id: proj_id, // used so the backend can detect banned comments
              text_analysis_uuid: this.textAnalysisObject.uuid // used so the backend can detect banned comments
            }
            let thisEdit = this.deepCloneObj(theseThemes[i])
            if (thisEdit._id) {
              if (thisEdit._id.$oid) {
                thisEdit._id = thisEdit._id.$oid
              }
              payload["theme_id"] = thisEdit._id
              payload["project_id"] = this.project.id
            }

            const thisThemeResponses = await this.TEXT_SERVICE.filterTheme(
              payload
            ).then((response) => {
              return response
            })

            if (thisThemeResponses) {
              theseThemes[i].coverage = thisThemeResponses.coverage
              theseThemes[i].coverage_count = thisThemeResponses.coverage.total
              if (thisThemeResponses.filtered_keyword_matches) {
                theseThemes[i].filtered_keyword_matches =
                  thisThemeResponses.filtered_keyword_matches
              }

              if (thisThemeResponses.filtered_coverage) {
                theseThemes[i].filtered_coverage =
                  thisThemeResponses.filtered_coverage
                theseThemes[i].filtered_coverage_count =
                  thisThemeResponses.filtered_coverage.total
              } else {
                theseThemes[i].filtered_coverage = {}
                theseThemes[i].filtered_coverage_count = -1
              }

              if (theseThemes[i]._id.$oid) {
                theseThemes[i]._id = theseThemes[i]._id.$oid
              }
              if (theseThemes[i].is_proposed) {
                delete theseThemes[i].is_proposed
              }
              if (theseThemes[i].is_autogenerated) {
                delete theseThemes[i].is_autogenerated
              }
              let recoveredThemes = []
              recoveredThemes.push(theseThemes[i])
              let save_payload = {
                themes: recoveredThemes,
                text_analysis_uuid: this.textAnalysisObject.uuid
              }
              if (!this.propReadOnly) {
                await this.TEXT_SERVICE.saveThemes(save_payload).then(
                  (response) => {
                    return response
                  }
                )
              }
            }

            this.themesReloading = this.themesReloading.filter(
              (theme) => theme !== thisEdit._id
            )
          }
          let arrayedTheme = []
          arrayedTheme.push(theseThemes[i])
          await this.replacingUnsortedThemes(arrayedTheme)
        }
        this.themesReloading = []
        this.showResponseListLoadingSpinner = false
      }
    },
    async replacingUnsortedThemes(themes) {
      let clonedThemes = this.deepCloneObj(this.unsortedThemes)
      for (let i = 0; i < themes.length; i++) {
        if (themes[i]._id.$oid) {
          themes[i]._id = themes[i]._id.$oid
        }
        let found_match = false
        for (let x = 0; x < clonedThemes.length; x++) {
          if (clonedThemes[x]._id.$oid) {
            clonedThemes[x]._id = clonedThemes[x]._id.$oid
          }
          if (clonedThemes[x]._id == themes[i]._id) {
            clonedThemes[x] = themes[i]
            found_match = true
          }
        }
        if (found_match == false || !clonedThemes.length) {
          clonedThemes.unshift(themes[i])
        }
      }
      this.unsortedThemes = clonedThemes
      await this.sortThemes()
      if (this.$refs.ProjectAnalysisTextThemeRef) {
        this.$refs.ProjectAnalysisTextThemeRef.refreshThemesFromParent()
      }
      if (this.$refs.ProjectAnalysisTextMainRef) {
        this.$refs.ProjectAnalysisTextMainRef.refreshThemesFromParent()
        this.$refs.ProjectAnalysisTextMainRef.updateCounts()
      }
    },
    async updateThisCoverage(_id) {
      const copiedThemes = this.deepCloneObj(this.unsortedThemes)
      const foundEdit = copiedThemes.filter((edit) => edit._id == _id)
      let updatedThemeInfo = {
        themes: foundEdit,
        source: "Parent"
      }
      await this.updateTheseThemes(updatedThemeInfo)
    },
    async updateAllCoverages() {
      const copiedThemes = this.deepCloneObj(this.unsortedThemes)
      let updatedThemeInfo = {
        themes: copiedThemes,
        source: "Parent"
      }
      await this.updateTheseThemes(updatedThemeInfo)
    },
    async updateFilterParam(filter_param) {
      if (this.propReadOnly) return
      this.textAnalysisObject.filterParams = filter_param
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.textAnalysisObjects[indx].filterParams =
          this.textAnalysisObject.filterParams
        this.project.textAnalysisObjects[indx].last_modified_on =
          moment().valueOf()
        await this.cleanAndSaveProject()
        await this.runFetchTextQuestionResponses()
        if (this.unsortedThemes.length) {
          let updatedThemeInfo = {
            themes: this.unsortedThemes,
            source: "Parent"
          }
          await this.updateTheseThemes(updatedThemeInfo)
        }
      }
    },
    async removeThisFilter(filter_param) {
      let newFilterParams = this.deepCloneObj(
        this.textAnalysisObject.filterParams
      )

      if (newFilterParams.operands.length == 1) {
        newFilterParams = {}
      } else {
        newFilterParams.operands = newFilterParams.operands.splice(
          filter_param.filter_index,
          1
        )
      }

      this.textAnalysisObject.filterParams = newFilterParams

      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.textAnalysisObjects[indx].filterParams =
          this.textAnalysisObject.filterParams
        this.project.textAnalysisObjects[indx].last_modified_on =
          moment().valueOf()
        if (!this.propReadOnly) {
          await this.cleanAndSaveProject()
        }

        await this.runFetchTextQuestionResponses()
        if (this.unsortedThemes.length) {
          let updatedThemeInfo = {
            themes: this.unsortedThemes,
            source: "Parent"
          }
          await this.updateTheseThemes(updatedThemeInfo)
        }
      }
    },
    async removeTheme(theme_id) {
      if (theme_id) {
        await this.themeRemoval(theme_id)
        if (this.$refs.ProjectAnalysisTextThemeRef) {
          this.$refs.ProjectAnalysisTextThemeRef.refreshThemesFromParent()
        }
        if (this.$refs.ProjectAnalysisTextMainRef) {
          this.$refs.ProjectAnalysisTextMainRef.refreshThemesFromParent()
        }
      }
    },
    async themeRemoval(theme_id) {
      if (theme_id) {
        let updatedThemes = []
        let clonedThemes = this.deepCloneObj(this.unsortedThemes)
        for (let x = 0; x < clonedThemes.length; x++) {
          if (theme_id.$oid) {
            theme_id = theme_id.$oid
          }
          if (clonedThemes[x]._id.$oid) {
            clonedThemes[x]._id = clonedThemes[x]._id.$oid
          }
          if (clonedThemes[x]._id !== theme_id) {
            updatedThemes.push(clonedThemes[x])
          }
        }
        this.unsortedThemes = updatedThemes
        this.sortThemes()
      }
    },
    check_response_for_selected_themes(response, themes) {
      let themeKeywords = []
      let foundMatches = 0
      for (let i = 0; i < themes.length; i++) {
        if (
          this.textAnalysisObject.filterParams &&
          Object.keys(this.textAnalysisObject.filterParams).length
        ) {
          themeKeywords = themes[i].filtered_keyword_matches
        } else {
          themeKeywords = themes[i].keyword_matches
        }

        if (
          themeKeywords.some((x) => response.response.toLowerCase().includes(x))
        ) {
          foundMatches = foundMatches + 1
        } else {
          // Only check for note matches if not already found in keywords
          if (
            themes[i].notes &&
            themes[i].notes.filter(
              (sortedNotes) => sortedNotes.responseId == response.id
            ).length
          ) {
            foundMatches = foundMatches + 1
          }
        }
        if (foundMatches >= themes.length) {
          return true
        }
      }
      return false
    },
    async runSortAndSelect() {
      this.isLoadingData = true

      let responsesToUse = this.deepCloneObj(this.unsortedResponses)
      // Text responses have been fetched

      if (responsesToUse.length) {
        let useTheseResponses = []

        // if a response has been selected (such as from the charts)

        if (this.textAnalysisObject.selectedResponseIds.length) {
          useTheseResponses = responsesToUse.filter((response) =>
            this.textAnalysisObject.selectedResponseIds.some(
              (id) => response.id == id
            )
          )
          // no selectedResponseIds (no chart selection)
        } else {
          // theme(s) have been selected
          if (this.selectedThemeUUIDs && this.selectedThemeUUIDs.length) {
            const filteredThemes = this.sortedThemes.filter((theme) =>
              this.selectedThemeUUIDs.includes(theme._id)
            )
            useTheseResponses = responsesToUse.filter((response) =>
              this.check_response_for_selected_themes(response, filteredThemes)
            )
          } else {
            useTheseResponses = responsesToUse
          }
        }

        if (
          useTheseResponses.length &&
          this.textAnalysisObject.search &&
          this.textAnalysisObject.search.searchString.length
        ) {
          // Normal search is treated as a keyword match, this will then look for exact matches
          if (
            this.textAnalysisObject.search.searchMode &&
            this.textAnalysisObject.search.searchMode == "exact matches" &&
            this.textAnalysisObject.search.searchResults
          ) {
            useTheseResponses = useTheseResponses.filter((response) =>
              this.textAnalysisObject.search.searchResults.some((match) =>
                response.response.toLowerCase().includes(match)
              )
            )
            await this.sortTextResponses(useTheseResponses)
          }
          // Advanced search is similar but uses findSimilarSentences
          if (
            this.textAnalysisObject.search.searchMode &&
            this.textAnalysisObject.search.searchMode == "similar sentences" &&
            this.textAnalysisObject.search.similarSentences
          ) {
            useTheseResponses = useTheseResponses.filter((response) =>
              this.textAnalysisObject.search.similarSentences.some((match) =>
                response.response.toLowerCase().includes(match)
              )
            )
            await this.sortTextResponses(useTheseResponses)
          }
        } else {
          await this.sortTextResponses(useTheseResponses)
        }
      }
      this.isLoadingData = false
    },
    // Used by report to generate sentiments
    findSentiments(theme) {
      if (!theme) return

      // Get all keyword matches, regardless of dropped (as they get a dropdown menu)
      let useTheseResponses = []
      if (
        this.textAnalysisObject.filterParams &&
        Object.keys(this.textAnalysisObject.filterParams).length &&
        theme.filtered_keyword_matches
      ) {
        useTheseResponses = this.unsortedResponses.filter((response) =>
          theme.filtered_keyword_matches.some((match) =>
            response.response.toLowerCase().includes(match)
          )
        )
      } else {
        useTheseResponses = this.unsortedResponses.filter((response) =>
          theme.keyword_matches.some((match) =>
            response.response.toLowerCase().includes(match)
          )
        )
      }
      let addedResponses = []
      let droppedResponses = []
      let keywordMatchedResponseIds = useTheseResponses.map(
        (response) => response.id
      )

      // Drop all dropped
      if (theme.dropped && theme.dropped.length) {
        droppedResponses = theme.dropped.filter((theme) =>
          this.unsortedResponses.filter(
            (response) =>
              response.id == theme ||
              (response.old_id && response.old_id == theme)
          )
        )
      }

      // Find all themes that are not in the above.
      if (theme.notes && theme.notes.length) {
        // Find note matches by content
        addedResponses = theme.notes
          .filter((note) =>
            this.unsortedResponses.filter(
              (response) =>
                response.id == note.responseId ||
                (response.old_id && response.old_id == note.responseId)
            )
          )
          .map((note) => note.responseId)

        addedResponses = addedResponses.filter(
          (value, index, array) =>
            array.indexOf(value) === index &&
            !keywordMatchedResponseIds.includes(value)
        )
      }
      if (droppedResponses.length) {
        useTheseResponses = useTheseResponses.filter(
          (response) => !droppedResponses.includes(response.id)
        )
      }
      if (addedResponses.length) {
        addedResponses = useTheseResponses.concat(
          this.unsortedResponses.filter((response) =>
            addedResponses.includes(response.id)
          )
        )
      } else {
        addedResponses = useTheseResponses
      }

      let sentiments = addedResponses.map(
        (response) => response.response_sentiment.compound_en
      )
      return sentiments
    },
    async fetchWordAssociations(searchString, datasetId, questionId) {
      if (!searchString) return []
      try {
        const response = await this.TEXT_SERVICE.wordAssociations({
          user_string: searchString,
          data_set_id: datasetId,
          client_question_id: questionId
        })
        return response
      } catch (e) {
        throw new Error("AlsoFound.vue:fetchWordAssociations " + e.message)
      }
    },
    updateSavedSlide(savedSlide) {
      this.savedSlide = savedSlide
    },
    async save(selectedSlide, newSlideName) {
      if (this.propReadOnly) return
      const uuid = await this.saveTextToReport(selectedSlide, newSlideName)
      if (!this.project.reportSaves) {
        this.project.reportSaves = 1
      } else {
        this.project.reportSaves = this.project.reportSaves + 1
      }
      await this.saveTextToProject(uuid)
      if (this.$refs.AnalysisSettings) {
        this.$refs.AnalysisSettings.updateCollections()
      }
      this.$emit("refresh")
    },
    async saveTextToReport(selectedSlide, newSlideName) {
      try {
        this.isLoadingData = true
        let createdReportID = await this.createReportIfEmpty(
          selectedSlide,
          newSlideName
        ) // the above function will do nothing under conditions of emptiness
        if (selectedSlide) {
          createdReportID = selectedSlide.id
        }
        let textTableData = {
          columnNames: ["Themes", "Keywords", "Coverage (%)", "Coverage (/)"],
          rowNames: [],
          data: []
        }
        if (
          this.textAnalysisObject.includeSentiments == "include" &&
          this.hasFetchedSentiments
        ) {
          textTableData.columnNames.push("Sentiment")
        }
        let thisTitle = ""
        if (this.textAnalysisObject.selectedTextQuestion.question_title) {
          thisTitle =
            this.textAnalysisObject.selectedTextQuestion.question_title
          if (this.textAnalysisObject.selectedTextQuestion.question_text) {
            thisTitle =
              thisTitle +
              " - " +
              this.textAnalysisObject.selectedTextQuestion.question_text
          }
        } else {
          if (this.textAnalysisObject.selectedTextQuestion.question_text) {
            thisTitle =
              this.textAnalysisObject.selectedTextQuestion.question_text
          }
        }
        let hasSentiments = false
        let rowNames = []
        for (let i = 0; i < this.sortedThemes.length; i++) {
          rowNames.push(this.sortedThemes[i].name)
          let keywords = ""
          for (let x = 0; x < this.sortedThemes[i].keywords.length; x++) {
            if (x === 0) {
              keywords = this.sortedThemes[i].keywords[x]
            } else {
              keywords = keywords + ", " + this.sortedThemes[i].keywords[x]
            }
          }
          let coverage =
            this.parseCoveragePercentage(
              this.sortedThemes[i],
              this.unsortedResponses.length -
                this.textAnalysisObject.bannedComments.length
            ) + "%"
          let coverageFractions = {}
          this.sortedThemes[i].coverage_count +
            "/" +
            this.unsortedResponses.length
          if (
            this.sortedThemes[i].filtered_coverage &&
            Object.keys(this.sortedThemes[i].filtered_coverage).length
          ) {
            coverageFractions =
              this.sortedThemes[i].filtered_coverage_count +
              "/" +
              this.unsortedResponses.length
          } else {
            coverageFractions =
              this.sortedThemes[i].coverage_count +
              "/" +
              this.unsortedResponses.length
          }
          let sentiment = {
            respondent_sentiment: {}
          }
          let thisRow = []
          thisRow.push(keywords)
          thisRow.push(coverage)
          thisRow.push(coverageFractions)
          if (
            this.textAnalysisObject.includeSentiments == "include" &&
            this.hasFetchedSentiments
          ) {
            sentiment.respondent_sentiment = this.findSentiments(
              this.sortedThemes[i]
            )
            thisRow.push(sentiment)
            hasSentiments = true
          }

          textTableData.data.push(thisRow)
        }

        textTableData.rowNames = rowNames
        let textChartData = []
        if (
          this.textAnalysisObject.allCharts &&
          this.textAnalysisObject.allCharts.length
        ) {
          textChartData = this.textAnalysisObject.allCharts
        }
        const returnObject = await this.updateReportWithText(
          this.textAnalysisObject.uuid,
          createdReportID,
          textTableData,
          thisTitle,
          textChartData,
          hasSentiments
        )
        this.isLoadingData = false
        this.$emit("updateSlides")
        this.onSuccessfulSave()
        this.savedNodes = returnObject.newNodes
        return returnObject.uuid
      } catch (e) {
        throw new Error("ProjectAnalysisText:saveTextToReport ")
      }
    },
    async saveTextToProject() {
      if (this.propReadOnly) return
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (indx > -1) {
        this.project.textAnalysisObjects[indx].saved_node_ids = this.savedNodes
        this.project.textAnalysisObjects[indx].last_modified_on =
          moment().valueOf()
        await this.cleanAndSaveProject()
      }
    },
    async deleteTextAnalysis() {
      if (this.unsortedThemes) {
        this.unsortedThemes = []
      }
      // delete themes from the database to prevent zombie themes
      if (this.sortedThemes) {
        let all_themes = []
        for (let i = 0; i < this.sortedThemes.length; i++) {
          all_themes.push(this.sortedThemes[i]._id)
        }
        let payload = {
          _ids: all_themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        await this.TEXT_SERVICE.deleteTextThemes(payload)
      }

      // Drops from the "analysis view"
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid === this.textAnalysisObject.uuid
      )
      this.project.textAnalysisObjects.splice(indx, 1)
      await this.cleanAndSaveProject()
      // Drops the same nodes from the saved project
      let project
      if (this.savedNodes.length) {
        project = await this.$pigeonline.projects.getByID(
          PigeonDocModel,
          this.savedSlide.id
        )
        if (project) {
          for (let i = 0; i < this.savedNodes.length; i++) {
            for (let x = 0; x < project.nodes.length; x++) {
              if (project.nodes[x].uuid === this.savedNodes[i].uuid) {
                project.nodes = project.nodes.filter(
                  (item) => item !== project.nodes[x]
                )
              }
            }
          }
        }
        await this.saveProject(project)
      }

      this.$emit("refresh")
    },
    /************
     *
     * Confirmation modals - Not unified in use!
     *
     ***********/
    async onClickDelete() {
      const indx = this.project.textAnalysisObjects.findIndex(
        (d) => d.uuid == this.textAnalysisObject.uuid
      )
      if (!this.savedNodes.length) {
        this.setConfirmText({
          btn: "Delete?",
          title: "Delete text analysis?"
        })
      } else {
        this.setConfirmText({
          btn: "Delete",
          title:
            "Delete text analysis? This will also delete this analysis from your report."
        })
      }
      this.setConfirmType("delete")
      this.setConfirmTarget(this.textAnalysisObject.uuid)
      this.setConfirmSourceComponent("text") // double check
      this.setConfirmStatus(false)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Text analysis saved",
        message: "Text analysis successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("text")
      this.setConfirmIsVisible(true)
    },
    formatDateTime: (value, language) => {
      let currentLang = ""
      if (language) {
        currentLang = language
      }
      if (value) {
        if (currentLang === "fr") {
          return moment(value)
            .locale("fr")
            .format("MMMM D, YYYY hh:mm A")
            .toString()
        } else {
          return moment(value).format("MMMM D, YYYY hh:mm A").toString()
        }
      }
    }
  },
  watch: {
    childChangeInContent: async function (val) {
      if (!val) return
      this.contentChanged = true
    },
    sortMode: async function (newVal, oldVal) {
      // don't sort the first time
      if (oldVal >= -1 && oldVal <= 1 && newVal >= -1 && newVal <= 1) {
        this.isLoadingData = true
        if (this.unsortedResponses.length) {
          this.runSortAndSelect()
        }
        this.isLoadingData = false
      }
    },
    currentSelectedResponseIds: async function (newVal, oldVal) {
      if (oldVal && newVal) {
        this.runSortAndSelect()
      }
    },
    hasSearchResults: async function (val) {
      if (val) {
        if (this.textAnalysisObject.search.searchResultsIds.length) {
          this.runSortAndSelect()
        }
      }
    },
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "text" &&
        this.confirmType === "delete" &&
        this.confirmTarget === this.textAnalysisObject.uuid
      ) {
        await this.deleteTextAnalysis()
      }
    },
    selectedThemeUUIDs: async function () {
      this.runSortAndSelect()
    }
  }
}
</script>
