<template>
  <div class="also-found__wrapper" v-show="isVisible || showNoSimilarText">
    <div class="also-found__loading" v-if="isLoading">
      <p class="also-found__loading-text">
        <VisibleText>{{ loadingText }}</VisibleText>
        <span class="also-found__loading-spinner spinner"></span>
      </p>
    </div>
    <div v-else>
      <div class="also-found__no-similar" v-if="showNoSimilarText">
        <p class="also-found__loading-text">
          <VisibleText>No similar words found.</VisibleText>
        </p>
      </div>
      <div v-if="similar.length > 0">
        <ul class="also-found__list">
          <span class="also-found__list-label">
            <VisibleText>{{ label + ":" }}</VisibleText>
          </span>
          <li
            class="also-found__list-item"
            v-for="(keyword, index) in similar"
            :key="index"
          >
            <KeywordTappable
              class="keyword"
              :value="keyword"
              @save="$emit('save', keyword)"
            >
              <template v-slot="{ remove }">
                <ButtonIconOnly
                  icon="remove"
                  class="keyword-remove"
                  @click-handler="remove(keyword)"
                >
                  <VisibleText>remove </VisibleText>{{ keyword }}
                </ButtonIconOnly>
              </template>
            </KeywordTappable>
          </li>
        </ul>
        <div class="also-found__options">
          <slot>
            <button class="also-found__save-all-btn">
              <VisibleText>Save all</VisibleText>
            </button>
          </slot>
        </div>
      </div>
    </div>
    <ButtonIconOnly
      icon="remove"
      class="also-found__remove-btn"
      @click-handler="close"
    >
      <VisibleText>close</VisibleText>
    </ButtonIconOnly>
  </div>
</template>

<script>
import KeywordTappable from "../Components/AlsoFoundKeywordTappable.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

// Mixins
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"

export default {
  name: "AlsoFound",
  mixins: [textAnalysisMixin],
  components: {
    ButtonIconOnly,
    KeywordTappable
  },
  props: {
    label: {
      type: String,
      default: function () {
        return "Also found"
      }
    },
    loadingText: {
      type: String,
      default: function () {
        return "Finding similar words..."
      }
    },
    textAnalysisObject: {
      type: Object,
      required: false,
      default: () => {}
    },
    existingTheme: {
      type: Object,
      required: false,
      default: () => {}
    },
    isFromSearch: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      keyword: null,
      keywordRef: null,
      isLoading: false,
      showNoSimilarText: false,
      isVisible: false,
      similar: []
    }
  },
  methods: {
    async setKeyword(keyword) {
      if (!keyword) return

      this.keyword = keyword

      // fetch similar keywords
      this.setVisible(true)
      this.setLoading(true)

      this.setSimilar(this.keyword)
      this.setLoading(false)
    },
    setKeywordRef(ref) {
      if (this.keywordRef) {
        this.keywordRef.setSelected(false)
      }
      // set new ref
      this.keywordRef = ref
      this.keywordRef.setSelected(true)
    },
    setSimilar(keywords) {
      if (!Array.isArray(keywords)) return
      this.showNoSimilarText = false

      let parsedKeywords = Array.from(new Set(keywords))
      if (this.existingTheme) {
        for (var i = 0; i < parsedKeywords.length; i++) {
          for (var x = 0; x < this.existingTheme.keywords.length; x++) {
            if (parsedKeywords[i] == this.existingTheme.keywords[x]) {
              parsedKeywords.splice(i, 1)
            }
          }
        }
      }
      if (!parsedKeywords || parsedKeywords.length == 0) {
        this.showNoSimilarText = true
        this.similar = []
      } else {
        this.similar = parsedKeywords
      }
    },
    setVisible(visible) {
      this.isVisible = visible
    },
    setLoading(loading) {
      this.isLoading = loading
    },
    reset() {
      this.keyword = null
      this.keywordRef = null
      this.similar = []
      this.isVisible = false
      this.isLoading = false
      this.showNoSimilarText = false
    },
    close() {
      if (this.keywordRef) {
        this.keywordRef.setSelected(false)
      }

      this.reset()
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  },
  computed: {
    searchAlsoFound() {
      if (
        !this.textAnalysisObject ||
        !this.textAnalysisObject.search.alsoFound ||
        !this.textAnalysisObject.search.alsoFound.length
      ) {
        return false
      }
      return true
    }
  },
  watch: {
    searchAlsoFound: function (val) {
      if (!val) return

      if (this.isFromSearch) {
        this.setVisible(true)
        this.setLoading(true)
        this.setSimilar(this.textAnalysisObject.search.alsoFound)
        this.setLoading(false)
      }
    }
  }
}
</script>
