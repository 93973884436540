import logo from "@/styles/themes/research-ai/research-ai-logo.svg"
export default {
  name: "rai",
  pageTitle: "ResearchAI",
  appDescription:
    "PigeonLine's ResearchAI makes business intelligence easy, such as text analysis (thematic analysis / topic modelling).",
  images: {
    favicon: require("@/styles/themes/research-ai/favicon.png"),
    header: logo
  }
}
