<template>
  <div :class="[`message__block--${messageType}`, `message__block`]">
    <p
      :class="[
        'message__block__details',
        { 'message__block__details--noicon': !showIcon }
      ]"
    >
      <span class="icon-wrapper">
        <SvgIconMeaningful :icon="messageType" :title="messageType" />
      </span>
      <VisibleText class="title" v-if="title">
        {{ title }}
      </VisibleText>
      <span>
        <slot></slot>
      </span>
    </p>
  </div>
</template>

<script>
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
export default {
  props: {
    messageType: {
      default: "information",
      type: String
    },
    title: {
      default: null,
      type: String
    },
    showIcon: {
      default: true,
      type: Boolean
    }
  },
  components: {
    SvgIconMeaningful
  }
}
</script>
