<template>
  <div class="ui-input-editable__wrapper" style="justify-content: baseline">
    <ButtonIconOnly
      class="ui-input-editable__edit-btn"
      icon="edit"
      @click-handler="edit"
      v-show="!editMode"
    >
      <VisibleText>rename theme</VisibleText>
    </ButtonIconOnly>
    <span
      class="ui-input-editable__content"
      style="margin-left: 0.5rem"
      v-if="!editMode"
      @click="select"
    >
      {{ value }}
    </span>
    <input
      ref="input"
      :class="['ui-input-editable__input', inputClass]"
      type="text"
      :value="value"
      @blur="onBlur"
      @keydown.enter="onBlur"
      v-else
    />
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "InputEditable",
  props: ["value", "inputClass", "isFocused", "select"],
  components: {
    ButtonIconOnly
  },
  data() {
    return {
      editMode: false,
      oldValue: null
    }
  },
  mounted() {
    // if (this.$props.isFocused) {
    //   setTimeout(
    //     function () {
    //       this.edit()
    //     }.bind(this),
    //     500
    //   )
    // }
    this.oldValue = this.$props.value
    // this.oldValue = this.$props.value.trim()
  },
  methods: {
    edit() {
      this.editMode = true
      this.$nextTick(() => this.$refs.input.focus())
    },
    onBlur: function (event) {
      const newValue = event.target.value.trim()
      if (this.oldValue !== newValue) {
        this.$emit("update", newValue)
        this.oldValue = newValue
      }
      this.editMode = false
    }
  },
  watch: {
    isFocused: function (val) {
      if (val) {
        setTimeout(
          function () {
            this.edit()
          }.bind(this),
          300
        )
      }
    }
  }
}
</script>
