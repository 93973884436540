<template>
  <div>
    <DefaultTable
      class="datasets__table"
      :caption="'Datasets'"
      :hide-caption="true"
      :row-data="filteredDatasets"
      :column-headers="columnHeaders"
      :sort-config="sortConfig"
      @sort="sort"
    >
      <template #row="{ row }">
        <td class="td-dataset-select">
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="dataset"
            :id="`${row.id}`"
            :value="row.id"
            :disabled="getOwner(row) !== 'You'"
            :checked="getSelectedDatasetIndex(row.id) > -1"
            @change="onChangeSelectDataset(row, $event)"
          />
          <label :for="`${row.id}`" class="sr-only">
            <VisibleText>select </VisibleText>{{ row.name }}
          </label>
        </td>
        <td class="td-dataset-name" data-label="dataset name">
          <router-link
            class="datasets__table-name"
            :to="`/dataset/${row.dataset_id}?project_id=${row.id}`"
          >
            {{ row.name }}
          </router-link>
        </td>
        <td data-label="owner">
          {{ getOwner(row) }}
          <SvgIconDecorative v-if="getOwner(row) == 'You'" icon="star" />
        </td>
        <td data-label="file type">
          {{ row.file_type }}
        </td>
        <td data-label="last modified">
          {{ formatDate(row.last_modified_on.$date, setLanguage) }}
        </td>
        <td data-label="status">
          <VisibleText
            class="status"
            :class="row.currStatusText() || replaceSpaceWithDash"
            v-if="!row.isBenchmarksPending()"
          >
            {{ row.currStatusText() }}
          </VisibleText>
          <details class="datasets__table-status-details" v-else>
            <summary
              :class="row.currStatusText() || replaceSpaceWithDash"
              :data-parent="$options.name"
            >
              {{ translate(row.currStatusText(), $options.name) }}
            </summary>
            <p><VisibleText>Benchmarks are pending approval</VisibleText></p>
          </details>
        </td>
        <td>
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row; margin: 0 auto">
              <!-- <SvgIconDecorative
                :title="
                  row.access_type == 'public__team' ||
                  row.access_type == 'public__all'
                    ? 'not private'
                    : 'private'
                "
                :icon="
                  row.access_type == 'public__team' ||
                  row.access_type == 'public__all'
                    ? 'notLocked'
                    : 'locked'
                "
                style="margin-top: 0.25rem"
              /> -->
              <SvgIconDecorative
                v-if="row.access_type == 'private'"
                title="private"
                icon="locked"
                style="margin-top: 0.25rem"
              />
              <SvgIconMeaningful
                v-if="
                  row.is_read_only &&
                  (row.access_type == 'public__team' ||
                    row.access_type == 'public__all')
                "
                icon="eye"
                title="is read only"
                style="margin-top: 0.25rem; margin-left: -0.5rem"
              />

              <div v-if="row.access_type == 'public__all'">
                <VisibleText>Public</VisibleText>
              </div>
              <div v-if="row.access_type == 'public__team'">
                <VisibleText>Team</VisibleText>
              </div>
              <div v-if="row.access_type == 'private'">
                <VisibleText>Private</VisibleText>
              </div>

              <SvgIconMeaningful
                v-if="getDatasetSharedStatus(row).includes('Invites')"
                icon="shared"
                title="has individual invites"
                style="margin-top: 0.25rem"
              />
            </div>

            <button
              class="datasets__table-share-btn"
              style="margin-top: -0.5rem"
              @click="shareDataset(row)"
              v-if="isDatasetOwner(row)"
            >
              <SvgIconDecorative icon="share" />
              <VisibleText>Share</VisibleText>
            </button>
          </div>
        </td>
      </template>
    </DefaultTable>
    <div v-if="isLoading" style="display: flex; width: 100%">
      <span
        style="margin: 0 auto; height: 2rem; width: 2rem"
        class="also-found__loading-spinner spinner"
      ></span>
    </div>
  </div>
</template>

<script>
// Componenets
import DefaultTable from "@/components/UI/DefaultTable.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import TeamsService from "@/services/teamsService.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

// Mixins
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import UserMixin from "@/utils/mixins/userMixin"
import FiltersMixin from "@/utils/mixins/filtersMixin"

export default {
  name: "DatasetsTable",
  mixins: [SharedStatusMixin, UserMixin, FiltersMixin],
  components: {
    DefaultTable,
    SvgIconMeaningful,
    SvgIconDecorative
  },
  props: {
    queryTerm: {
      type: String,
      default: null
    },
    selected: {
      type: Array
    }
  },
  data() {
    return {
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      columnHeaders: [
        "",
        "Dataset name",
        "Owner",
        "File type",
        "Last modified",
        "Status",
        ""
      ],
      sortableOrder: {
        ascending: 1,
        descending: -1
      },
      sortConfig: {
        "Dataset name": {
          options: ["ascending", "descending"],
          selected: ""
        },
        Owner: {
          options: ["ascending", "descending"],
          selected: ""
        },
        "File type": {
          options: ["ascending", "descending"],
          selected: ""
        },
        "Last modified": {
          options: ["ascending", "descending"],
          selected: "descending"
        },
        Status: {
          options: ["ascending", "descending"],
          selected: ""
        }
      },
      team_members: [],
      $_selected: [],
      datasets: [],
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true
    this.loadData().then(async ({ datasets, team_members }) => {
      this.team_members = team_members
      this.datasets = datasets
      this.fetchBenchmarkUpdates()
      const defaultSort = { option: "descending", sortTitle: "Last modified" }
      this.sort(defaultSort)
      this.isLoading = false
    })
  },
  computed: {
    filteredDatasets() {
      const keys = ["name"]
      return this.searchInObjectValues(this.datasets, keys, this.queryTerm)
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    }
  },
  methods: {
    async loadData() {
      const [datasets, team_members] = await Promise.all([
        // TODO, we are sorting using FE, but will need to revive this. In modern JS, this is being converted away from json. Explore if we want API-based filtering.
        // await this.$pigeonline.projects.get(BenchmarkingDataset, {
        //   sort: { last_modified_on: "desc" }
        // }),
        await this.$pigeonline.projects.get(BenchmarkingDataset, {}),
        await this.TEAMS_SERVICE.getAllTeamMembers({
          include_details: true
        })
      ])
      return { datasets, team_members }
    },
    fetchBenchmarkUpdates() {
      this.datasets.forEach(async (datasetProject) => {
        // not in progress, skip it
        if (datasetProject.currStatusText() != "in progress") return
        // benchmarks are sent & its in progress, check if benchmarks were approved
        if (datasetProject.status.benchmarksSent) {
          const benchmarks =
            await this.$services.BENCHMARKING_SERVICE.benchmarking({
              client_data_set_id: datasetProject.dataset_id,
              approved: "True"
            })
          // at least one benchmark is approved, set to approved
          if (benchmarks.length > 0) {
            datasetProject.updateStatus("benchmarksApproved")
            await this.$pigeonline.projects.update(datasetProject)
          }
        }
      })
    },
    getDatasetSharedStatus(dataset) {
      const shared = dataset.shared && dataset.shared.map((r) => r.pk)
      const access = dataset.access_type
      let status = "private"
      if (access && access == "public__all") {
        status = "public"
      }
      if (access && access == "public__team") {
        status = "Team"
      }
      if (shared.length > 0) {
        status = status + "+ Invites"
      }
      return status
    },
    getSelectedDatasetIndex(projectID) {
      return this.$data.$_selected
        .map((dataset) => dataset.project_id)
        .indexOf(projectID)
    },
    getOwner(dataset) {
      if (this.isDatasetOwner(dataset)) return "You"
      let owner = "anonymous"
      if (dataset.access_type == "public__team") {
        if (dataset.owning_team_id !== "") {
          if (this.team_members.length) {
            const user = this.team_members.find(
              (user) => user.id == dataset.owner
            )
            if (user && user.fullname) {
              owner = user.fullname
            }
          }
        }
      }
      return owner
    },
    isDatasetOwner(dataset) {
      return dataset.owner === this.profile.django_ref_id
    },
    onChangeSelectDataset(row, $event) {
      if ($event.target.checked) {
        this.$data.$_selected.push({
          project_id: row.id,
          dataset_id: row.dataset_id,
          dataset_proj: row
        })
      } else if (this.getSelectedDatasetIndex(row.id) > -1) {
        let indx = this.getSelectedDatasetIndex(row.id)
        this.$data.$_selected.splice(indx, 1)
      }
      this.$emit("updateSelected", this.$data.$_selected)
    },
    shareDataset(dataset) {
      this.$emit("share-dataset", dataset)
    },

    /* Sort handlers */
    getValue(dataset, sortTitle) {
      if (sortTitle === "Dataset name") return dataset.name
      if (sortTitle === "Owner") return this.getOwner(dataset)
      if (sortTitle === "File type") return dataset.file_type
      if (sortTitle === "Last modified")
        return new Date(dataset.last_modified_on.$date)
      if (sortTitle === "Status") return dataset.currStatusText()
    },
    sort(args) {
      this.sortConfig[args.sortTitle].selected = args.option

      // default sort func
      let sortFunc = (a, b) =>
        this.getValue(a, args.sortTitle).localeCompare(
          this.getValue(b, args.sortTitle)
        )
      // special cases
      if (args.sortTitle === "Last modified") {
        sortFunc = (a, b) =>
          this.getValue(a, args.sortTitle).valueOf() -
          this.getValue(b, args.sortTitle).valueOf()
      }

      this.datasets.sort(
        (a, b) => this.sortableOrder[args.option] * sortFunc(a, b)
      )
    },
    async refreshDatasets() {
      this.datasets = []
      await this.loadData().then(async ({ datasets, team_members }) => {
        this.team_members = team_members
        this.datasets = datasets
        this.fetchBenchmarkUpdates()
      })
      const defaultSort = { option: "descending", sortTitle: "Last modified" }
      this.sort(defaultSort)
    }
  },
  watch: {
    selected: function (val) {
      if (val.length === 0) this.$data.$_selected = []
    }
  }
}
</script>
