import {
  SECTION_TRANSLATIONS,
  HELPER_TRANSLATIONS,
  LABEL_TRANSLATIONS
} from "../../../translations"
import { formatDriversChartData } from "./helpers"

/*******************************
 *
 * Generate drivers analysis chart node
 * @param {String} driversTitle of the drivers analysis chart
 * @param {Array} driversChartData drivers analysis chart data from drivers/drivers_analysis
 * @returns {Object} pigeondoc chart node
 *
 ******************************/
const driversChart = (
  driversTitle,
  driversChartData,
  includeLinks = true,
  lang
) => {
  let combinedDrivers = []
  if (driversChartData.num_drivers && driversChartData.num_drivers.length) {
    combinedDrivers = driversChartData.num_drivers
    if (driversChartData.cat_drivers.length) {
      let concated = combinedDrivers.concat(driversChartData.cat_drivers)
      combinedDrivers = concated.sort((a, b) => {
        return b[0] - a[0]
      })
    }
  } else if (
    driversChartData.cat_drivers &&
    driversChartData.cat_drivers.length
  ) {
    combinedDrivers = driversChartData.cat_drivers
  }

  const chartData = formatDriversChartData(combinedDrivers, includeLinks)
  const chart = {
    type: "horizontalBarChart",
    content: {
      title: `<span class='drivers-chart-title'>${HELPER_TRANSLATIONS.driversChatTitle[lang]} ${driversTitle}</span>`,
      data: chartData
    },
    id: "drivers-top-chart",
    meta: {
      headingLevel: "div",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: LABEL_TRANSLATIONS.importance[lang],
        y: LABEL_TRANSLATIONS.drivers[lang]
      },
      axisHoverText: {
        x: HELPER_TRANSLATIONS.driversXAxis[lang]
      }
    }
  }
  return chart
}

const driversDescription = (driversChartData, lang) => {
  const str = SECTION_TRANSLATIONS.driversDetailsText[lang]
  const str1 = ` ${
    driversChartData.estimator_name.replace(/_/g, " ") || "regression"
  }. `
  const str2 = driversChartData.dynamic_summary.drivers_summary
  const driversDetailsText = str.concat(str1).concat(str2)

  return {
    type: "text",
    content: driversDetailsText,
    id: "drivers-top-details"
  }
}

const driversHeader = (driversTitle, lang) => {
  return {
    type: "heading",
    content: `${driversTitle} ${LABEL_TRANSLATIONS.drivers[lang]}`,
    id: "section-subtitle-drivers",
    meta: {
      headingLevel: "h3",
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
}

export { driversChart, driversDescription, driversHeader }
