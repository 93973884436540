<template>
  <div class="library__article">
    <div v-if="isUserGuide">
      <p>
        <VisibleText>Go back to</VisibleText>
        <a
          href="javascript:void(0)"
          @click="$emit('new-page', 'GettingStarted')"
          onclick="this.style.color = '#3d0071'"
        >
          <VisibleText> Getting Started</VisibleText>
        </a>
      </p>
    </div>
    <div v-else>
      <p>
        <VisibleText>Go back to</VisibleText>
        <router-link to="/library">
          <VisibleText> Library</VisibleText>
        </router-link>
        ->
        <router-link to="/library/guide/getting-started-with-researchai">
          <VisibleText>Getting Started</VisibleText>
        </router-link>
      </p>
    </div>
    <h1 class="library__h1">
      <VisibleText>
        Using outliers analysis to find under- or over-performing segments
      </VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        ResearchAI has created a novel way of using outliers analysis to find
        red-flags in your data.
      </VisibleText>
    </p>
    <h2 class="library__h2" ref="goto--connect">
      <VisibleText>What is outliers analysis?</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText
        >Outliers are typically extreme values that deviate from other
        observations in the same dataset. For example, human weights would have
        a reasonable range, but if one entry was 2000kg, then it is likely a
        mistaken entry and weakens analysis quality. There are many techniques
        for identifying outliers in data, mostly for the purpose of removing
        them to improve analysis quality.
        <a
          href="https://www.mygreatlearning.com/blog/outlier-analysis-explained/"
          target="_blank"
          rel="noopener noreferrer"
        >
          This article by Great Learning covers these in good detail.
        </a>
      </VisibleText>
    </p>

    <p>
      <VisibleText>
        We use one of these techniques, called Z-scores, to help you detect
        outliers. This can be useful for removing very odd results that are
        interfering with the rest of the analysis. However, we have also
        discovered that the same technique can be used to find interesting
        groups of respondents that are "significantly" higher or lower in score
        than their peers. These may not be "bad data" but instead, strongly
        negative experiences. We help you capture these so you can answer
        detailed questions about exactly "who" is upset and "when".
      </VisibleText>
    </p>

    <h2 class="library__h2" ref="goto--connect">
      <VisibleText>What data can I use?</VisibleText>
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        This analysis requires your data to have two things.
      </VisibleText>
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          <span style="font-weight: 800">Categorical data</span> - these are the
          'segments' that are explored. If you have a category denoting age,
          gender, service type, channel used, or anything else like this, these
          will appear as specific under- or over-performing groups compared to
          their peers.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          <span style="font-weight: 800">Scale data or text data</span> - these
          are what determines 'performance' of the segments. For example, the
          tool will tell you which segments (from the above categories) are
          performing high or low based on these scale questions, or with
          negative or positive sentiment if text questions.
        </VisibleText>
      </li>
    </ul>

    <h2 class="library__h2" ref="goto--connect">
      <VisibleText>How to find outliers</VisibleText>
    </h2>
    <div class="library__heading-line"></div>

    <p>
      <VisibleText>
        To get started, click the "outliers" button under the "analysis" tab of
        a project that is connected to a dataset. Then click, "Add new outliers
        analysis".
      </VisibleText>
    </p>
    <img
      class="screenshot"
      src="/assets/userguide_starting-outliers.png"
      alt="screenshot of outliers analysis widget"
    />

    <p>
      <VisibleText
        >Next, you have to select the 'scale' or 'text' questions that you are
        looking for outliers of. For example, if you select 5-point scale
        questions like 'overal satisfaction', it may find specific segments
        (categories like demographics) that are particularly unsatisfied. Once
        you have selected at least one text or scale question, press 'confirm
        selection'.
      </VisibleText>
    </p>
    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_ new-outliers.png"
      alt="screenshot of new outliers analysis instructions"
    />
    <p>
      <VisibleText>
        You will be presented with a view that says "No outliers saved". Scroll
        lower to begin finding some. You will also see 'filters'. These help you
        find interesting outliers.
      </VisibleText>
    </p>

    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_outliers-start.png"
      alt="screenshot of empty outliers and filters"
    />

    <ul class="library__list">
      <li>
        <VisibleText>
          "Sorted by" will let you find segments that have a large number of
          respondents (sample size) or find the segemnts that have the highest
          average difference in score compared to the overal average.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          "Grouped by" will display segments by individual categories, questions
          that have multiple segment outliers, or segments that are outliers to
          multiple questions.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          "Show" lets you show only positive outliers, only negative outliers,
          or both. Negative is the most interesting if you are looking for
          problems.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          "Threshold" is sensitivity. A threshold of 1 means it is easier to
          find outliers, and a threshold of 4 means extreme outliers which could
          be very rare.
        </VisibleText>
      </li>
    </ul>

    <p>
      <VisibleText>
        Consider the below example. This is grouped by question, in this case
        'staff went the extra mile'. This question has 2 outliers. One is a
        'somewhat negative' outlier, those who answered '1' to the question 'S2
        - Service Type' are on average more upset (3.2) than the average (3.52).
        Another is a 'somewhat positive' outlier, those who answered 1 to 'S1 -
        Community size'.
      </VisibleText>
    </p>

    <img
      class="screenshot screenshot--60"
      src="/assets/userguide_outliers-example.png"
      alt="screenshot of an example question with two outliers"
    />

    <p>
      <VisibleText>
        These labels, "somewhat negative" and "somewhat positive" are set using
        the threshold. This is based on a statistical measure called a
        'Z-Score'. Z-scores are standardized measures for how much of an outlier
        a datapoint is compared to its peers. Most data are between -2 or 2
        standard deviations (z-scores) away from the mean. Scores above or below
        this become considered outliers.
      </VisibleText>
    </p>

    <ul>
      <li>
        <VisibleText> Greater or less than 2: Somewhat an outlier </VisibleText>
      </li>
      <li>
        <VisibleText>Greater or less than 3: An outlier</VisibleText>
      </li>
      <li>
        <VisibleText>Greater or less than 4: A strong outlier</VisibleText>
      </li>
      <li>
        <VisibleText> Greater or less than 5: An extreme outlier </VisibleText>
      </li>
    </ul>
    <p>
      <VisibleText>
        When you find outliers that you think are very strange, disrupting your
        results, you can go to the dataset and remove it. Other outliers
        however, can be well within reason. Patterns may make sense to you. If
        you see a segment that is consistently out-performing, and especially if
        it is to a key driver, then you can 'save' these and track them.
      </VisibleText>
    </p>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "outliers_analysis",
  setup() {
    const siteData = reactive({
      title: "What is outliers analysis in ResearchAI?",
      description:
        "Find under-performing or over-performing categories (such as segments, demographics, customer/employee journey point)"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  props: {
    isUserGuide: { type: Boolean }
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
