<template>
  <form class="new-question" novalidate @submit.prevent>
    <div style="padding: 0 1.5rem">
      <label class="form-label" for="question-code">
        <VisibleText>Question Code</VisibleText>
      </label>
      <VisibleText
        class="error-msg__inline form__err--block"
        id="question-code-err"
        v-show="errMsg.questionCode"
      >
        {{ errMsg.questionCode }}
      </VisibleText>
      <input
        aria-describedby="question-code-err"
        id="question-code"
        type="text"
        required
        ref="code"
        :aria-invalid="errMsg.questionCode ? 'true' : 'false'"
        v-model="question.question_title"
      />

      <span class="form-label">
        <VisibleText>Question Wording</VisibleText>
      </span>
      <VisibleText
        class="error-msg__inline form__err--block"
        id="question-wording-err"
        v-show="errMsg.questionWording"
      >
        {{ errMsg.questionWording }}
      </VisibleText>
      <QuestionWording
        aria-described-by="question-wording-err"
        :err="errMsg.questionWording ? 'true' : 'false'"
        :question="question"
        :required="true"
      />

      <SelectDataType
        class="new-question__datatype"
        @set-data-type="setDataType"
        :isBenchmarking="true"
      >
        <VisibleText>Data Type</VisibleText>
      </SelectDataType>
    </div>

    <div class="new-question__btns">
      <button
        class="btn-default"
        style="min-height: unset"
        type="submit"
        @click="save"
      >
        <VisibleText>create question</VisibleText>
      </button>
      <button
        class="form-btn-secondary"
        type="button"
        @click="$emit('close-new-question')"
      >
        <VisibleText>cancel</VisibleText>
      </button>
    </div>
  </form>
</template>

<script>
// Components
import SelectDataType from "@/components/Dataset/Components/DatasetSelectType.vue"
import QuestionWording from "./Components/QuestionWording.vue"

// Consts
import { LANG_NAME } from "../Utils/consts.js"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerNewQuestion",
  components: {
    SelectDataType,
    QuestionWording
  },
  props: {
    benchmarkGroup: {
      type: BenchmarkGroup,
      required: true
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      LANG_NAME: LANG_NAME,
      LANG_CODE_TO_FIELD: {
        en: "question_text",
        fr: "question_text_fr"
      },
      errMsg: {
        questionCode: null,
        questionWording: null
      },
      question: {
        question_title: null,
        question_text: null,
        question_text_fr: null,
        data_type: null,
        benchmark_group: this.$route.params.id,
        matches: {
          approved: 0,
          pending: 0
        }
      },
      localBenchmarkGroup: {}
    }
  },
  mounted() {
    this.localBenchmarkGroup = this.benchmarkGroup
  },
  methods: {
    setDataType(dataType) {
      this.question.data_type = dataType
    },
    validate() {
      let firstErr = null
      if (!this.question.question_title) {
        this.errMsg.questionCode = "Error: code is required"
        if (!firstErr) firstErr = "code"
      }
      if (!this.question.question_text && !this.question.question_text_fr) {
        this.errMsg.questionWording = "Error: wording and language is required"
        if (!firstErr) firstErr = "wording"
      }
      if (firstErr && this.$refs[firstErr]) this.$refs[firstErr].focus()
      return !(firstErr && firstErr.length > 0)
    },
    async save() {
      if (!this.validate()) return
      const newQuestion = await this.DATASETS_SERVICE.newGlobalQuestion(
        this.question
      )
      this.$emit("add-global-question", newQuestion)
      this.localBenchmarkGroup.questions += 1
      this.$pigeonline.projects.update(this.localBenchmarkGroup)
    }
  }
}
</script>
