<template>
  <DialogPopup
    class="theme__popup-dialog"
    :style="
      avoidLeftOffset
        ? 'position: absolute; width: 40vw; max-width: 40vw; left: 0;'
        : 'position: absolute; width: 40vw; max-width: 40vw'
    "
    header-tag="h4"
    :show="showSentimentsInfo"
    @close="closeGuide"
  >
    <template #header>
      <div
        style="
          display: flex;
          top: 0;
          flex-direction: row;
          text-align: left;
          font-size: 1rem;
          text-transform: none;
        "
      >
        Learn more about sentiment analysis
      </div>
    </template>
    <template #body>
      <div>
        <div v-if="sentimentsPagination.page == 1">
          <p>
            <VisibleText
              >Sentiment analysis is a process to identify emotional states from
              text. We use an NLTK package maintained by researchers called
              Vader sentiment intensity.
            </VisibleText>
          </p>

          <p>
            <VisibleText>Example:</VisibleText>
          </p>
          <ul>
            <li>
              The pre-trained emotional state of 'feel' is: 0.128 (in the
              positive direction)
            </li>
            <li>
              The pre-trained emotional state of 'abandon' is: -0.4588 (in the
              negative direction)
            </li>
            <li>
              <a href="https://en.wikipedia.org/wiki/Sentiment_analysis"
                >Learn more</a
              >
            </li>
          </ul>
        </div>
        <div v-if="sentimentsPagination.page == 2">
          <p>
            <VisibleText
              >A response like "The staff kinda left me feeling abandoned" will
              be analyzed as an entire statement. This helps identify sarcasm or
              double negatives.</VisibleText
            >
          </p>
          <p style="margin-left: 2rem">
            <VisibleText>"The staff left me feeling abandoned"</VisibleText>
            <span> has a sentiment of:<strong> -0.41</strong> </span>
          </p>
        </div>
        <div v-if="sentimentsPagination.page == 3">
          <p>
            <VisibleText
              >We then apply a simple label based on where it falls between -1
              and +1.</VisibleText
            >
          </p>
          <ul>
            <li>
              -1 to -.75 =
              <span style="padding: 0 0.5rem" :style="getSentimentStyle(-0.8)"
                >Strongly negative {{ getSentimentEmoji(-0.8) }}</span
              >
            </li>
            <li>
              -.75 to -.25 =
              <span style="padding: 0 0.5rem" :style="getSentimentStyle(-0.5)"
                >Somewhat negative {{ getSentimentEmoji(-0.5) }}</span
              >
            </li>
            <li>
              -.25 to .25 =
              <span style="padding: 0 0.5rem; background-color: lightgrey"
                >Neutral {{ getSentimentEmoji(0) }}</span
              >
            </li>
            <li>
              .25 to .75 =
              <span style="padding: 0 0.5rem" :style="getSentimentStyle(0.5)"
                >Somewhat positive {{ getSentimentEmoji(0.5) }}</span
              >
            </li>
            <li>
              .75 to 1 =
              <span style="padding: 0 0.5rem" :style="getSentimentStyle(0.8)"
                >Strongly positive {{ getSentimentEmoji(0.8) }}</span
              >
            </li>
          </ul>
        </div>
        <div v-if="sentimentsPagination.page == 4">
          <p>
            <MessageBlock
              message-type="warning"
              title="Warning: this is just an approximation."
            >
              <VisibleText
                >You should adjust this computed sentiment based on your own
                judgement.</VisibleText
              >
            </MessageBlock>
          </p>
          <p>
            <VisibleText
              >One current weakness of this tool is that long format responses
              (more than 1 or 2 sentences) tend to get very
              distorted.</VisibleText
            >
          </p>
        </div>

        <div
          class="theme__popup-pagination"
          v-show="sentimentsPagination.totalPages > 1"
        >
          <span class="details">
            {{ translate("Page", $options.name) }}
            {{ sentimentsPagination.page }}
            {{ translate("of", $options.name) }}
            {{ sentimentsPagination.totalPages }}
          </span>
          <span class="actions">
            <button
              type="button"
              class="pagination-btn"
              :disabled="sentimentsPagination.page === 1"
              @click="onClickPrevPageSentiments"
            >
              <VisibleText> Prev </VisibleText>
            </button>
            |
            <button
              type="button"
              class="pagination-btn"
              :disabled="
                sentimentsPagination.page === sentimentsPagination.totalPages
              "
              @click="onClickNextPageSentiments"
            >
              <VisibleText> Next </VisibleText>
            </button>
          </span>
        </div>
      </div>
    </template>
  </DialogPopup>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import textAnalysisMixin from "@/components/Project/ProjectAnalysis/ProjectAnalysisText/Mixins/textAnalysisMixin.js"

export default {
  name: "SentimentsMiniGuide",
  components: {
    DialogPopup,
    MessageBlock
  },
  mixins: [textAnalysisMixin],
  props: {
    showSentimentsInfo: {
      type: Boolean,
      default: () => false
    },
    avoidLeftOffset: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      localShowSentimentsInfo: false,
      sentimentsPagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 4,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  methods: {
    onClickNextPageSentiments() {
      if (
        this.sentimentsPagination.page === this.sentimentsPagination.totalPages
      )
        return
      this.sentimentsPagination.page = this.sentimentsPagination.page + 1
      this.setPaginationDetailsSentiments(
        this.sentimentsPagination.startIndx + this.sentimentsPagination.limit,
        this.sentimentsPagination.endIndx + this.sentimentsPagination.limit
      )
    },
    onClickPrevPageSentiments() {
      if (this.sentimentsPagination.page === 1) return
      this.sentimentsPagination.page = this.sentimentsPagination.page - 1
      this.setPaginationDetailsSentiments(
        this.sentimentsPagination.startIndx - this.sentimentsPagination.limit,
        this.sentimentsPagination.endIndx - this.sentimentsPagination.limit
      )
    },
    setPaginationDetailsSentiments(start, end) {
      this.sentimentsPagination.startIndx = start
      this.sentimentsPagination.endIndx = end
    },
    closeGuide() {
      this.$emit("closingGuide")
      this.localShowSentimentsInfo = false
    }
  },
  created() {
    if (this.showSentimentsInfo) {
      this.localShowSentimentsInfo = true
    }
  },
  watch: {
    showSentimentsInfo(newVal) {
      if (newVal) {
        this.localShowSentimentsInfo = true
      } else {
        this.localShowSentimentsInfo = false
      }
    }
  }
}
</script>
