<template>
  <div id="project-analysis-text-theme" style="padding: 1rem">
    <Accordion
      class="main__tabs"
      title-element-type="h3"
      icon-location="right"
      :expand="true"
      :background="'var(--base-lighter-ui-colour);'"
      :iconColor="'var(--base-lighter-ui-colour)'"
    >
      <template #heading>
        <span class="main__collapsible-chart-title">
          <h3>
            <span
              ><span>
                <SvgIconDecorative
                  icon="list"
                  class="editor-question__pending-icon"
                  style="color: black"
                /> </span
            ></span>
            <VisibleText style="margin: 0 1rem">Saved themes:</VisibleText>
            <span>{{ sortedThemes.length }}</span>
          </h3>
        </span>
      </template>
      <template #default v-if="sortedThemes">
        <div style="display: flex; flex-direction: row">
          <button
            aria-label="table view"
            :class="[
              'chart-header__display-type-btn',
              { 'selected-btn': themesTabView !== 'charts' }
            ]"
            type="button"
            @click="changeThemesTabView()"
            :disabled="!sortedThemes.length"
          >
            <SvgIconMeaningful
              style="
                cursor: pointer;
                padding: 0;
                height: 1.5rem;
                margin-left: -0.3rem;
              "
              class="complete-icon-svg"
              icon="table"
              title="table"
            />
          </button>
          <button
            aria-label="chart view"
            type="button"
            cursor="pointer"
            :class="[
              'chart-header__display-type-btn',
              { 'selected-btn': themesTabView == 'charts' }
            ]"
            @click="changeThemesTabView()"
            :disabled="!sortedThemes.length"
          >
            <SvgIconMeaningful
              style="cursor: pointer; height: 1.5rem; margin-left: -0.3rem"
              class="complete-icon-svg"
              icon="chart"
              title="chart"
            />
          </button>
          <!-- TODO add more functionality and options to text table generation -->
          <!-- <div v-if="themesTabView == 'themes'">
            <div @click="showReportSettings = !showReportSettings"
              style="text-decoration: underline; margin-left: 1rem; margin-top: 0.5rem; cursor: pointer; color: var(--primary); font-size: 0.9rem;">
              <VisibleText>
                Report settings</VisibleText>
            </div>

            <div style="
                  z-index: 10;
                  background-color: white;
                  border-top: 0;
                  border-radius: 0 0 4px 4px;
                  color: var(--base-colour);
                  position: absolute;
                  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                  border: 1px solid var(--outline-colour);
                  border-radius: 8px;
                  margin-left: 8rem;
                  margin-top:-2rem;
                  width: 15rem;
                  padding: 0.5rem;
                " v-if="showReportSettings
                ">

              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row">
                  <VisibleText>Include table in report:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>

                <VisibleText>Include in table:</VisibleText>

                <div style="display: flex; flex-direction: row">
                  <VisibleText style="width: 70%; margin-left: 1rem;">Coverage percent:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>
                <div style="display: flex; flex-direction: row">
                  <VisibleText style="width: 70%; margin-left: 1rem;">Coverage fractions:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>
                <div style="display: flex; flex-direction: row">
                  <VisibleText style="width: 70%; margin-left: 1rem;">Keywords:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>
                <div style="display: flex; flex-direction: row">
                  <VisibleText style="width: 70%; margin-left: 1rem;">Experts/notes:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>
                <div style="display: flex; flex-direction: row">
                  <VisibleText style="width: 70%; margin-left: 1rem;">Sentiment chart:</VisibleText>
                  <input style="margin-left:1rem;" type="checkbox" />
                </div>
              </div>


            </div>
          </div> -->
        </div>
        <div
          v-if="themesTabView == 'charts'"
          style="display: flex; flex-direction: column"
        >
          <div style="width: 100%; position: relative" v-if="isAddingCharts">
            <span
              class="also-found__loading-spinner spinner"
              style="
                position: absolute;
                height: 2rem;
                width: 2rem;
                top: -2rem;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
              "
            ></span>
          </div>

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              margin-left: auto;
            "
            v-if="sortedThemes.length && !isAddingCharts"
          >
            <MessageBlock
              message-type="information"
              title="No charts created"
              v-if="!localAllCharts.length"
            >
              Generate a chart based on your themes
            </MessageBlock>
            <button
              style="margin-left: 2rem"
              class="theme__auto-generate"
              @click="showCreateThemeChartModal"
              :disabled="!sortedThemes.length"
            >
              <VisibleText>Add theme chart</VisibleText>
            </button>
          </div>
          <div>
            <div
              v-for="(chartData, index) in localAllCharts"
              :key="index"
              style="
                border: 1px solid var(--outline-colour);
                border-radius: 4px;
                margin: 2rem;
              "
            >
              <PerformanceCharts
                v-if="chartData.chart_data && chartData.chart_data.length"
                :chart-data="chartData.chart_data"
                :chartType="'HorizontalBarChart'"
                :savedThreshold="-1"
                :isLoading="false"
                @deleteChart="deleteChart(index)"
                :filtered_by="chartData.filtered_by"
                :isThematic="true"
                :isSegmented="chartData.segmented_by"
                :alternative_x="'thematicScore'"
                :alternative_y="'thematicLabel'"
                :alternative_x_hover="'thematicScoreHover'"
              />
            </div>
            <MessageBlock
              message-type="information"
              title="No themes found"
              v-if="!sortedThemes.length"
            >
              Auto-generate a theme or create a new one.
            </MessageBlock>
            <CreateNewThemeChart
              v-if="isCreateThemeChartModal"
              :widerFormat="true"
              :show="isCreateThemeChartModal"
              :sortedThemes="sortedThemes"
              :filterParams="
                textAnalysisObject.filterParams
                  ? textAnalysisObject.filterParams
                  : {}
              "
              @confirmSelection="addNewChart"
              @close-modal="closeCreateThemeChartModal"
            />
          </div>
        </div>
        <div v-if="themesTabView == 'themes'">
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              margin-left: auto;
            "
          >
            <div style="display: flex">
              <button
                :disabled="propReadOnly"
                style="
                  background-color: transparent;
                  border-color: transparent;
                  color: var(--primary);
                  margin-bottom: 0.5rem;
                  text-decoration: underline;
                "
                @click="setShowAdvancedOptions"
              >
                <svg
                  class="svg-icon icon"
                  focusable="false"
                  role="img"
                  style="height: 1.5rem"
                >
                  <title>Information</title>
                  <use :xlink:href="`#information`" aria-hidden="true" />
                </svg>
                <VisibleText v-if="showAdvancedOptions == 'show'"
                  >Hide AI options</VisibleText
                >
                <VisibleText v-if="showAdvancedOptions == 'hide'"
                  >AI options</VisibleText
                >
              </button>
            </div>
            <button
              :disabled="propReadOnly"
              v-if="hasFetchedLemmas && includeSmartCoverage == 'include'"
              class="theme__auto-generate--alt"
              style="margin-left: 1rem"
              @click="updateIncludeSmartCoverage"
            >
              <VisibleText>
                <SvgIconDecorative icon="check" /> Smart search is on
              </VisibleText>
            </button>
            <button
              :disabled="propReadOnly"
              v-if="
                hasFetchedLemmas &&
                (!includeSmartCoverage || includeSmartCoverage == 'exclude')
              "
              class="theme__auto-generate--alt"
              style="margin-left: 1rem"
              @click="updateIncludeSmartCoverage"
            >
              <VisibleText>
                <SvgIconDecorative icon="check" /> Smart search is off
              </VisibleText>
            </button>
            <button
              :disabled="propReadOnly"
              v-if="
                textAnalysisObject.includeSentiments == 'include' &&
                hasFetchedSentiments
              "
              class="theme__auto-generate--alt"
              @click="includeExcludeSentiments('exclude')"
              style="margin-left: 2rem"
            >
              <VisibleText>
                <SvgIconDecorative icon="check" /> Including sentiments
              </VisibleText>
            </button>
            <button
              :disabled="propReadOnly"
              v-if="
                hasFetchedSentiments &&
                (!textAnalysisObject.includeSentiments ||
                  textAnalysisObject.includeSentiments == 'exclude')
              "
              class="theme__auto-generate--alt"
              style="margin-left: 2rem"
              @click="includeExcludeSentiments('include')"
            >
              <SvgIconDecorative icon="remove" />
              <VisibleText>Hiding sentiments</VisibleText>
            </button>

            <button
              style="margin-left: 2rem"
              class="theme__auto-generate"
              @click="isAutoGenerateModalVisible = true"
            >
              <VisibleText>Generate themes</VisibleText>
            </button>
          </div>

          <!----- NO SAVED THEMES MESSAGE ---->
          <MessageBlock
            message-type="information"
            title="No themes found"
            v-if="isThemeListEmpty || !localThemes.length"
          >
            Generate a theme or create a new one.
          </MessageBlock>

          <!----- THEME LIST ---->
          <div
            class="theme__list"
            v-else
            style="margin-left: 2rem; margin-top: 1rem"
          >
            <div
              class="theme__list-row-wrapper"
              :style="
                !hasFetchedSentiments ||
                textAnalysisObject.includeSentiments == 'exclude'
                  ? 'margin-left: 7rem; margin-right: auto; max-width: 98%;'
                  : ''
              "
            >
              <div class="theme__list-row" :style="headingStyle">
                <div style="display: flex; flex-direction: row">
                  <div style="margin: auto">
                    <div style="display: flex; flex-direction: row">
                      <div style="margin: auto; margin-right: 1rem">Themes</div>

                      <div style="display: flex; flex-direction: column">
                        <span style="font-size: 0.8rem"> Sort </span>
                        <div style="display: flex; flex-direction: row">
                          <ButtonIconOnly
                            :smaller="true"
                            style="
                              border: 1px solid var(--outline-colour);
                              background: var(--secondary-ui-colour);
                              border-radius: 4px;
                              margin: 0.5rem;
                            "
                            :icon="sortIcon"
                            @click-handler="showSortType = !showSortType"
                          >
                            <VisibleText style="font-weight: 800"
                              >sorting by:
                              {{
                                textAnalysisObject.themesSortType
                              }}</VisibleText
                            >
                          </ButtonIconOnly>
                          <ButtonIconOnly
                            :smaller="true"
                            style="
                              border: 1px solid var(--outline-colour);
                              background: var(--secondary-ui-colour);
                              border-radius: 4px;
                              margin: 0.5rem;
                            "
                            :icon="textAnalysisObject.themesSortOrder"
                            @click-handler="switchSortOrder"
                          >
                            <VisibleText style="font-weight: 800"
                              >sort order:
                              {{
                                textAnalysisObject.themesSortOrder
                              }}</VisibleText
                            >
                          </ButtonIconOnly>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="showSortType"
                    style="
                      z-index: 10;
                      background-color: white;
                      border-top: 0;
                      border-radius: 0 0 4px 4px;
                      color: var(--base-colour);
                      position: absolute;
                      margin-top: -2rem;
                      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                      border: 1px solid var(--outline-colour);
                      border-radius: 8px;
                      margin-left: 12rem;
                      width: 13rem;
                    "
                  >
                    <div
                      style="
                        margin-left: 0.5rem;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      <div style="margin-top: 0.5rem; font-weight: 800">
                        <VisibleText> Select a sort type</VisibleText>:
                      </div>
                      <button
                        style="
                          border: 1px solid var(--outline-colour);
                          background: var(--secondary-ui-colour);
                          border-radius: 4px;
                          margin: 0.3rem 0.3rem 0 auto;
                          width: 1.5rem;
                          height: 1.5rem;
                        "
                        @click="showSortType = false"
                      >
                        <SvgIconMeaningful
                          style="
                            cursor: pointer;
                            width: 1rem;
                            margin-left: -0.05rem;
                          "
                          class="complete-icon-svg"
                          icon="remove"
                          title="close"
                        />
                      </button>
                    </div>

                    <div
                      class="theme-options-btn"
                      @click="switchSort('coverage')"
                    >
                      <span style="margin-left: 0.5rem; margin-right: 0.5rem"
                        >%</span
                      >
                      <VisibleText>Coverage</VisibleText>
                    </div>
                    <div
                      class="theme-options-btn"
                      @click="switchSort('alphabetical')"
                    >
                      <SvgIconDecorative icon="alphabet" />
                      <VisibleText>Alphabetical</VisibleText>
                    </div>
                    <div
                      class="theme-options-btn"
                      @click="switchSort('created')"
                    >
                      <SvgIconDecorative icon="timeAdd" />
                      <VisibleText>Created</VisibleText>
                    </div>
                    <!-- <div class="theme-options-btn" @click="switchSort('modified')">
                        <SvgIconDecorative icon="timeCheck" />
                        <VisibleText>Modified</VisibleText>
                      </div> -->
                  </div>

                  <div
                    style="
                      border-left: 0.15rem solid var(--primary);
                      height: 2rem;
                      margin-top: 0.5rem;
                    "
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <CoverageOptionsPopup
                    :showCoverageDetails="
                      textAnalysisObject.showCoverageDetails
                    "
                    @updateShowCoverageDetails="updateShowCoverageDetails"
                  ></CoverageOptionsPopup>

                  <button
                    style="
                      border: none;
                      background: rgba(0, 0, 0, 0);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    @click="updateAllCoverages()"
                  >
                    <SvgIconDecorative
                      style="height: 0.8rem; width: 0.8rem"
                      icon="refresh"
                    />
                  </button>
                </div>

                <div
                  v-if="
                    hasFetchedSentiments &&
                    textAnalysisObject.includeSentiments == 'include'
                  "
                >
                  Sentiments
                </div>
              </div>
            </div>
            <div
              v-for="(theme, index) in localThemes"
              :key="index"
              style="
                display: flex;
                flex-direction: row;
                margin-bottom: 1.5rem;
                align-items: center;
              "
              :style="
                !hasFetchedSentiments ||
                textAnalysisObject.includeSentiments == 'exclude'
                  ? 'margin-left: auto; margin-right: auto; max-width: 90%; position: relative;'
                  : ''
              "
            >
              <div
                style="
                  z-index: 10;
                  background-color: white;
                  border-top: 0;
                  border-radius: 0 0 4px 4px;
                  color: var(--base-colour);
                  position: absolute;
                  margin-top: 2rem;
                  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                  border: 1px solid var(--outline-colour);
                  border-radius: 8px;
                  margin-left: -14rem;
                  width: 13rem;
                "
                v-if="
                  showThemeCheckOptions.length &&
                  showThemeCheckOptions[showThemeCheckOptions.length - 1]._id ==
                    theme._id
                "
              >
                <div style="margin-left: 0.5rem">
                  <div v-if="checkedThemes.length == 1">
                    <VisibleText> 1 theme selected</VisibleText>
                  </div>
                  <div v-if="checkedThemes.length > 1">
                    <span>{{ checkedThemes.length }}</span>
                    <VisibleText> themes selected</VisibleText>
                  </div>
                </div>

                <div @click="toggleTheme" class="theme-options-btn">
                  <SvgIconDecorative icon="search" />
                  <VisibleText>Filter</VisibleText>
                </div>
                <div
                  @click="duplicateTheme"
                  class="theme-options-btn"
                  :style="
                    propReadOnly ? 'cursor: not-allowed' : 'cursor:pointer'
                  "
                >
                  <SvgIconDecorative icon="duplicate" />
                  <VisibleText>Duplicate</VisibleText>
                </div>
                <div
                  @click="onClickMergeAndDelete"
                  v-if="checkedThemes.length > 1"
                  class="theme-options-btn"
                  :style="propReadOnly ? 'cursor: not-allowed' : ''"
                >
                  <SvgIconDecorative icon="merge" />
                  <VisibleText>Merge</VisibleText>
                </div>
                <div
                  @click="copyAndMerge(false)"
                  v-if="checkedThemes.length > 1"
                  class="theme-options-btn"
                  :style="propReadOnly ? 'cursor: not-allowed' : ''"
                >
                  <SvgIconDecorative icon="copyMerge" />
                  <VisibleText>Copy and merge</VisibleText>
                </div>

                <div
                  @click="openEditSomeThemes"
                  class="theme-options-btn"
                  :style="propReadOnly ? 'cursor: not-allowed' : ''"
                >
                  <SvgIconDecorative icon="edit" />
                  <VisibleText>Edit</VisibleText>
                </div>
                <div
                  @click="openSaveCTM"
                  v-if="checkedThemes.length == 1"
                  class="theme-options-btn"
                  :style="propReadOnly ? 'cursor: not-allowed' : ''"
                >
                  <SvgIconDecorative icon="copy" />
                  <VisibleText>Save</VisibleText>
                </div>
                <div
                  @click="onClickDelete"
                  class="theme-options-btn"
                  :style="propReadOnly ? 'cursor: not-allowed' : ''"
                >
                  <SvgIconDecorative icon="deleteIcon" />
                  <VisibleText>Delete</VisibleText>
                </div>
              </div>
              <div
                style="
                  z-index: 10;
                  background-color: white;
                  border-top: 0;
                  border-radius: 0 0 4px 4px;
                  color: var(--base-colour);
                  position: absolute;
                  margin-top: 4rem;
                  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                  border: 1px solid var(--outline-colour);
                  border-radius: 8px;
                  margin-left: -1rem;
                  width: 40rem;
                "
                v-if="
                  showThemeCheckOptions.length &&
                  showThemeCheckOptions[showThemeCheckOptions.length - 1]._id ==
                    theme._id &&
                  isSaveToCTMVisible
                "
              >
                <div
                  style="display: flex; flex-direction: column; padding: 1rem"
                >
                  <!-- Heading  -->
                  <div style="display: flex; flex-direction: row">
                    <VisibleText style="font-weight: 800">
                      Saving theme to a custom text model
                    </VisibleText>
                    <div style="margin-left: auto">
                      <ButtonIconOnly
                        icon="remove"
                        class="modal-close-btn"
                        @click-handler="isSaveToCTMVisible = false"
                      >
                        <VisibleText>close</VisibleText>
                      </ButtonIconOnly>
                    </div>
                  </div>

                  <!-- This theme source -->
                  <div
                    style="display: flex; flex-direction: column; width: 95%; margin-top: 0;'"
                  >
                    <div>
                      <VisibleText>
                        This theme: {{ checkedThemes[0].name }}
                      </VisibleText>
                    </div>
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <VisibleText>
                          Theme source: {{ themeTypeDescription }}
                        </VisibleText>
                      </div>
                    </div>
                    <div
                      v-if="
                        themeType == 'Custom text model' &&
                        checkedThemes[0].source.manually_unlinked
                      "
                      style="margin: 0rem; margin-left: 1rem; padding: 0.5rem"
                      class="message__block message__block--warning"
                    >
                      You have unlinked this theme from its original custom text
                      model.
                      <div style="display: flex; flex-direction: row">
                        <button
                          @click="relinkThisCTM"
                          class="btn-save btn-default"
                          style="
                            padding: 0 0.5rem;
                            min-height: 1.5rem;
                            height: 1.5rem;
                          "
                        >
                          <SvgIconDecorative
                            style="margin-left: -0.6rem; margin-top: -0.3rem"
                            icon="add"
                          />
                          <VisibleText style="font-size: 0.9rem"
                            >Relink theme</VisibleText
                          >
                        </button>
                        <div style="margin-left: 1rem">
                          This will unlink you from any manually connected
                          models.
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 1rem">
                      <VisibleText> Linked model and theme: </VisibleText>
                    </div>
                  </div>

                  <!-- Status banner -->
                  <div
                    style="display: flex; flex-direction: column; width: 95%; margin-top: 0;'"
                    class="message__block message__block--information"
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        padding: 0 1rem;
                      "
                    >
                      <div
                        style="
                          margin: 0.5rem 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        <div
                          style="display: flex; flex-direction: row"
                          v-if="isLinked"
                        >
                          <span
                            style="
                              color: var(--tom-thumb-green);
                              background: var(--pixie-green);
                              border-radius: 999px;
                              height: 1.25rem;
                              display: flex;
                            "
                          >
                            <SvgIconMeaningful
                              style="
                                margin: auto;
                                width: 1.25rem;
                                height: 1.25rem;
                              "
                              icon="check"
                              title="complete"
                            />
                          </span>
                        </div>

                        <div
                          style="display: flex; flex-direction: row"
                          v-if="!isLinked"
                        >
                          <span
                            style="
                              color: var(--totem-pole-red);
                              background: var(--linen-red);
                              border-radius: 999px;
                              height: 1.25rem;
                              display: flex;
                            "
                          >
                            <SvgIconMeaningful
                              style="
                                margin: auto;
                                width: 1.25rem;
                                height: 1.25rem;
                              "
                              icon="remove"
                              title="not complete"
                            />
                          </span>
                        </div>

                        <div style="margin-left: 0.5rem">
                          <VisibleText>Linked model</VisibleText>:
                        </div>

                        <!-- Found a matched CTM but no manual unlink -->
                        <!-- todo add 'relink' to CTM -->
                        <div
                          v-if="
                            checkedThemes[0].source.source &&
                            !this.checkedThemes[0].source.manually_unlinked
                          "
                          style="margin-left: 0.5rem"
                        >
                          <router-link
                            :to="
                              '/custom-text-model/' +
                              checkedThemes[0].source.source
                                .topic_model_group_id
                            "
                            target="_blank"
                          >
                            {{
                              checkedThemes[0].source.source
                                .topic_model_group_name
                            }}
                            <SvgIconDecorative
                              style="margin-left: -0.6rem; margin-top: -0.3rem"
                              icon="externalLink"
                            />
                          </router-link>
                        </div>
                        <div
                          v-if="checkedThemes[0].source.manually_linked"
                          style="margin-left: 0.5rem"
                        >
                          <router-link
                            :to="
                              '/custom-text-model/' +
                              checkedThemes[0].source.manually_linked
                            "
                            target="_blank"
                          >
                            {{
                              checkedThemes[0].source.manually_linked_model_name
                            }}
                            <SvgIconDecorative
                              style="margin-left: -0.6rem; margin-top: -0.3rem"
                              icon="externalLink"
                            />
                          </router-link>
                        </div>
                        <div v-if="isLinked">
                          <button
                            class="btn-save btn-default"
                            style="
                              background-color: var(--error-bg-colour);
                              color: var(--error-text-colour);
                              padding: 0 0.5rem;
                              min-height: 1.5rem;
                              height: 1.5rem;
                              border: 1px solid var(--shilo-red);
                            "
                            @click="removeThisCTM(checkedThemes[0])"
                          >
                            <SvgIconDecorative
                              style="margin-left: -0.6rem; margin-top: -0.3rem"
                              icon="minus"
                            />
                            <VisibleText style="font-size: 0.9rem"
                              >Remove link</VisibleText
                            >
                          </button>
                        </div>

                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            margin-left: 0.5rem;
                          "
                          v-if="!isLinked"
                        >
                          <VisibleText
                            style="margin-left: 1rem; font-weight: 800"
                          >
                            Not linked to a custom text model
                          </VisibleText>
                        </div>
                      </div>

                      <div
                        style="
                          margin: 0.5rem 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        <div v-if="!isLinkedtoTheme">
                          <span
                            style="
                              color: var(--totem-pole-red);
                              background: var(--linen-red);
                              border-radius: 999px;
                              height: 1.25rem;
                              display: flex;
                            "
                          >
                            <SvgIconMeaningful
                              style="
                                margin: auto;
                                width: 1.25rem;
                                height: 1.25rem;
                              "
                              icon="remove"
                              title="not complete"
                            />
                          </span>
                        </div>
                        <div v-if="isLinkedtoTheme">
                          <span
                            style="
                              color: var(--tom-thumb-green);
                              background: var(--pixie-green);
                              border-radius: 999px;
                              height: 1.25rem;
                              display: flex;
                            "
                          >
                            <SvgIconMeaningful
                              style="
                                margin: auto;
                                width: 1.25rem;
                                height: 1.25rem;
                              "
                              icon="check"
                              title="complete"
                            />
                          </span>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            margin-left: 0.5rem;
                          "
                        >
                          <VisibleText>Linked model theme </VisibleText>:
                        </div>
                        <div v-if="isLinkedtoTheme">
                          <VisibleText
                            style="margin-left: 0.5rem; font-weight: 800"
                          >
                            {{ checkedThemeSource.name }}
                          </VisibleText>
                        </div>
                        <div v-if="!isLinkedtoTheme">
                          <VisibleText
                            style="margin-left: 0.5rem; font-weight: 800"
                          >
                            Not linked to a custom text model theme
                          </VisibleText>
                        </div>
                      </div>

                      <div style="margin: 0.5rem 0" v-if="isLinkedtoTheme">
                        <VisibleText> Current keywords: </VisibleText>
                        <VisibleText
                          style="margin-left: 0.5rem"
                          v-if="!Object.keys(checkedThemeSource).length"
                        >
                          None
                        </VisibleText>
                      </div>

                      <div
                        v-if="Object.keys(checkedThemeSource).length"
                        style="margin: 0.5rem 0"
                      >
                        <div
                          style="
                            display: flex;
                            max-width: 30rem;
                            overflow-x: auto;
                            overflow-y: hidden;
                          "
                          class="light-scroll-bar"
                          v-if="Object.keys(checkedThemeSource).length"
                        >
                          <div
                            v-for="(
                              keyword, index
                            ) in checkedThemeSource.keywords"
                            :key="index"
                          >
                            <div
                              style="
                                font-size: 0.8rem;
                                width: max-content;
                                color: white;
                              "
                              class="keywords-input-badge pill ui-adaptable"
                              :style="{
                                'background-color': checkedThemes[0].theme_color
                              }"
                            >
                              {{ keyword }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End of banner -->

                  <!-- If not linked, fetch CTMs -->
                  <div v-if="!isLinked">
                    <div style="margin-left: 1rem">
                      <VisibleText>
                        Please select a custom text model to link your theme to:
                      </VisibleText>
                    </div>
                    <div style="max-height: 20rem; overflow-y: auto">
                      <div
                        v-for="(model, index) in fetchedCTMs"
                        :key="index"
                        class="hoverable-background"
                        :style="
                          model.access_type == 'public__all'
                            ? 'background-color: var(--outline-colour); color: var(--base-colour); cursor: not-allowed'
                            : ''
                        "
                        @click="
                          setThisCTM(
                            model,
                            checkedThemes[0],
                            model.access_type == 'public__all'
                          )
                        "
                      >
                        <div style="color: var(--primary); font-weight: 800">
                          {{ model.name }}
                        </div>
                        <div style="margin-left: 1rem">
                          <span>
                            <VisibleText> Owner: </VisibleText>
                          </span>
                          <span v-if="model.created_by.organization">
                            Team:
                          </span>
                          <span>
                            {{ model.created_by.name }}
                          </span>
                          <span
                            v-if="model.created_by.name == profile.fullname"
                          >
                            <SvgIconMeaningful
                              icon="star"
                              title="this is you"
                            />
                          </span>
                        </div>
                        <div v-if="model.access_type == 'public__all'">
                          <VisibleText style="margin-left: 1rem">
                            Read only
                          </VisibleText>
                        </div>
                      </div>
                    </div>

                    <label
                      for="create-theme-from-row"
                      style="width: 80%; padding-left: 1rem"
                    >
                      <VisibleText>
                        Or create a new Custom Text Model
                      </VisibleText>
                    </label>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        margin-bottom: -0.5rem;
                        width: 80%;
                        padding: 1rem;
                      "
                    >
                      <SvgIconMeaningful
                        class="complete-icon-svg"
                        icon="add"
                        title="Add new model"
                        style="margin-top: 1rem"
                        v-if="!modelAdd.modelName.length"
                      />
                      <input
                        id="create-model-from-row"
                        type="text"
                        name="New model name"
                        v-model="modelAdd.modelName"
                        placeholder="New model name"
                        style="margin: auto 0"
                      />
                      <CreateProject
                        v-if="modelAdd.modelName.length"
                        class="btn-default"
                        style="
                          font-size: 0.8rem;
                          height: 1rem;
                          margin: auto 2rem;
                          padding: 0.25rem;
                          min-height: 2rem;
                        "
                        :project="modifiedNewGroup"
                        @projectCreated="newGroupCreated($event)"
                        @projectCreationError="newGroupCreationError"
                      >
                        <VisibleText>Create Custom Text Model</VisibleText>
                      </CreateProject>
                    </div>
                  </div>

                  <!-- If has no theme link, assign themes -->
                  <div v-if="isLinked && !isLinkedtoTheme">
                    <div
                      v-if="
                        checkedThemes[0].source.manually_linked &&
                        !checkedThemes[0].source.manually_linked_theme
                      "
                    >
                      <div style="margin-left: 0.5rem">
                        <VisibleText>
                          This project theme has not been linked to an existing
                          custom model theme. Please select a model theme or
                          create a new one.
                        </VisibleText>
                      </div>

                      <div
                        style="
                          margin-left: 0.5rem;
                          margin-top: 1rem;
                          font-weight: 800;
                        "
                      >
                        <VisibleText> Themes in </VisibleText>
                        <router-link
                          :to="
                            '/custom-text-model/' +
                            checkedThemes[0].source.manually_linked
                          "
                          target="_blank"
                        >
                          {{
                            checkedThemes[0].source.manually_linked_model_name
                          }}:
                          <SvgIconDecorative
                            style="margin-left: -0.6rem; margin-top: -0.3rem"
                            icon="externalLink"
                          />
                        </router-link>
                      </div>
                      <div>
                        <VisibleText v-if="!fetchedCTMThemes.length">
                          This custom text model has no themes.
                        </VisibleText>
                      </div>
                      <div style="max-height: 30rem; overflow-y: auto">
                        <div
                          v-for="(model_theme, index) in fetchedCTMThemes"
                          :key="index"
                          class="hoverable-background"
                          @click="setThisCTMTheme(model_theme._id.$oid, theme)"
                        >
                          <div style="color: var(--primary); font-weight: 800">
                            {{ model_theme.name }}
                          </div>
                        </div>
                      </div>

                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          margin-top: 0;
                        "
                        class="hoverable-background"
                        @click="setNewCTMTheme"
                      >
                        <VisibleText
                          >or create a new custom text model theme</VisibleText
                        >
                        <div style="margin: 0 1rem">
                          <button
                            class="btn-save btn-default"
                            style="
                              padding: 0 0.5rem;
                              min-height: 2rem;
                              height: 2rem;
                            "
                          >
                            <VisibleText>Create theme</VisibleText>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- If is linked and linked to theme, (or linked to original theme), can apply edits -->
                  <div v-if="isLinked && isLinkedtoTheme">
                    <!-- New keywords section -->
                    <div
                      v-if="Object.keys(checkedThemeSource).length"
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;
                      "
                    >
                      <div v-if="Object.keys(checkedThemeSource).length">
                        <div>
                          <VisibleText> New keywords: </VisibleText>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div
                            style="
                              display: flex;
                              max-width: 26rem;
                              overflow-x: auto;
                              overflow-y: hidden;
                            "
                            class="light-scroll-bar"
                            v-if="newKeywordsForTheme.length"
                          >
                            <div
                              v-for="(keyword, index) in newKeywordsForTheme"
                              :key="index"
                            >
                              <div
                                style="
                                  font-size: 0.8rem;
                                  width: max-content;
                                  color: white;
                                "
                                class="keywords-input-badge pill ui-adaptable"
                                :style="{
                                  'background-color':
                                    checkedThemes[0].theme_color
                                }"
                              >
                                {{ keyword }}
                              </div>
                            </div>
                          </div>
                          <div
                            style="margin-left: 1.5rem"
                            v-if="newKeywordsForTheme.length"
                          >
                            <button
                              v-if="!isCTMReadOnly"
                              class="btn-save btn-default"
                              style="
                                color: var(--tom-thumb-green);
                                background: var(--pixie-green);
                                padding: 0 0.5rem;
                                min-height: 2rem;
                                height: 2rem;
                              "
                              @click="saveNewKeywords"
                            >
                              <SvgIconDecorative
                                style="
                                  margin-left: -0.6rem;
                                  margin-top: -0.3rem;
                                "
                                icon="add"
                              />
                              <VisibleText>Save keywords</VisibleText>
                            </button>
                            <button
                              v-if="isCTMReadOnly"
                              class="btn-save btn-default"
                              style="
                                color: var(--base-colour);
                                background: var(--outline-colour);
                                padding: 0 0.5rem;
                                min-height: 2rem;
                                height: 2rem;
                              "
                              @click="saveNewKeywords"
                              :disabled="isCTMReadOnly"
                            >
                              <SvgIconDecorative
                                style="
                                  margin-left: -0.6rem;
                                  margin-top: -0.3rem;
                                "
                                icon="add"
                              />
                              <VisibleText>Save keywords</VisibleText>
                            </button>
                            <div v-if="isCTMReadOnly">
                              <VisibleText>
                                This is a Read-Only custom text model
                              </VisibleText>
                            </div>
                          </div>
                        </div>
                        <div v-if="!newKeywordsForTheme.length">
                          <VisibleText>
                            You have not added new keywords to be saved to this
                            Custom Model.
                          </VisibleText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input
                type="checkbox"
                class="datasets__table-checkbox"
                name="theme-checkbox"
                :id="'theme-checkbox-' + theme._id + theme.created_on"
                style="
                  width: 1.3rem;
                  height: 1.3rem;
                  margin-top: 0.5rem;
                  cursor: pointer;
                  margin-right: 1rem;
                "
                @click="addToShowThemeCheckOptions(theme)"
                v-model="selectedBoxes[index]"
              />

              <div
                v-if="themesReloading.includes(theme._id)"
                style="position: relative"
              >
                <span
                  class="also-found__loading-spinner spinner"
                  style="
                    position: absolute;
                    left: 5rem;
                    top: -1rem;
                    height: 2rem;
                    width: 2rem;
                    margin: 0 auto;
                  "
                ></span>
              </div>

              <div style="display: flex"></div>
              <div
                class="theme__list-row"
                :class="
                  !textAnalysisObject.selectedThemeUUIDs.length ||
                  isChecked(theme)
                    ? 'theme__list-row-selected'
                    : ''
                "
                style="width: 90%"
                :style="gridStyle"
              >
                <span
                  :class="[
                    'theme__list-row-title',
                    {
                      selected: textAnalysisObject.selectedThemeUUIDs.includes(
                        theme._id
                      )
                    }
                  ]"
                >
                  <div style="display: flex; flex-direction: row">
                    <div v-if="theme.theme_color">
                      <button
                        @click="showChangeColor(index)"
                        style="
                          border-radius: 1rem;
                          border: 1px solid var(--outline-colour);
                          height: 1.5rem;
                          width: 1.5rem;
                          margin-right: 0.5rem;
                        "
                        :style="{ 'background-color': theme.theme_color }"
                      ></button>
                    </div>

                    <div v-if="!propReadOnly">
                      <InputEditable
                        :value="theme.name"
                        :inputClass="'theme__list-row-title-input'"
                        :uuid="`saved-themes-${index}`"
                        :select="() => toggleTheme()"
                        @update="updateExistingThemeTitle(theme, $event)"
                      />
                    </div>
                    <div v-else>
                      {{ theme.name }}
                    </div>
                  </div>

                  <ColorPopup
                    :id="
                      'themeColor' +
                      theme._id +
                      textAnalysisObject.last_modified_on
                    "
                    :themeId="theme._id"
                    @changeThemeColor="changeThemeColor"
                    @close="showChangeColor(index)"
                    v-if="changingColor.includes(index)"
                  ></ColorPopup>

                  <div
                    v-if="duplicateIndex == index"
                    style="
                      font-size: 0.7rem;
                      color: var(--state-invalid-colour);
                    "
                  >
                    <VisibleText
                      >No duplicate titles allowed. Please try
                      another.</VisibleText
                    >
                  </div>
                  <SourcePopup
                    :themeName="theme.name"
                    :themeSource="theme.source"
                  />
                </span>
                <div
                  style="border-left: 0.2rem solid var(--primary); height: 3rem"
                ></div>
                <div class="theme__list-row-coverage" style="margin: 0">
                  <div
                    class="theme__list-row-coverage-title"
                    style="display: flex; flex-direction: row"
                  >
                    <CoveragePopup
                      :theme="theme"
                      :hasFetchedLemmas="hasFetchedLemmas"
                      :includeSmartCoverage="includeSmartCoverage"
                      :sortedResponsesLength="sortedResponsesLength"
                    />
                    <button
                      style="
                        border: none;
                        background: rgba(0, 0, 0, 0);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      @click="updateThisCoverage(theme._id)"
                    >
                      <SvgIconDecorative
                        style="height: 0.8rem; width: 0.8rem"
                        icon="refresh"
                      />
                    </button>
                  </div>
                </div>
                <div class="theme__list-row-info">
                  <KeywordsPopup
                    :id="theme._id + textAnalysisObject.last_modified_on"
                    :themesLength="sortedThemes.length"
                    :theme-name="theme.name"
                    :themeIndex="index"
                    :keywords="theme.keywords"
                    :showCoverageDetails="
                      textAnalysisObject.showCoverageDetails
                    "
                    :themeColor="theme.theme_color"
                    :textAnalysisObject="textAnalysisObject"
                  />
                  <NotesPopup
                    :clientQuestionId="
                      textAnalysisObject.selectedTextQuestion._id
                    "
                    :themesLength="sortedThemes.length"
                    :theme="theme"
                    :themeIndex="index"
                    :allResponses="unsortedResponses"
                    :hasFetchedSentences="hasFetchedSentences"
                    @onRemoveNote="onRemoveNote($event)"
                    @selectThisResponse="selectThisResponse($event)"
                    @addNewNote="addNewNote($event)"
                    :showCoverageDetails="
                      textAnalysisObject.showCoverageDetails
                    "
                  />
                </div>
                <SentimentCharts
                  v-if="
                    hasFetchedSentiments &&
                    textAnalysisObject.includeSentiments == 'include'
                  "
                  :pos-neg-data="
                    generatePosNegData(findResponses(theme), theme.name)
                  "
                />
              </div>
            </div>
          </div>

          <!----- THEME ACTIONS ---->
          <div class="theme__helper-container">
            <p class="theme__helper-text"></p>
            <div class="theme__helper-options">
              <button
                type="button"
                class="theme__helper-options-btn"
                @click="isEditModalVisible = true"
                :disabled="propReadOnly"
              >
                <SvgIconDecorative icon="editAlt" />
                <VisibleText>Edit themes</VisibleText>
              </button>
            </div>
          </div>
        </div>
      </template>
    </Accordion>

    <!----- MODALS---->
    <ThemesEditModal
      ref="ThemesEditModalRef"
      :show="isEditModalVisible"
      :isEditThemes="true"
      :textAnalysisObject="textAnalysisObject"
      :sortedResponsesLength="sortedResponsesLength"
      :propReadOnly="propReadOnly"
      :sortedThemes="sortedThemes"
      :hasFetchedLemmas="hasFetchedLemmas"
      :unsortedResponses="unsortedResponses"
      :hasFetchedSentences="hasFetchedSentences"
      :includeSmartCoverage="includeSmartCoverage"
      @updateTextObject="$emit('updateTextObject', $event)"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      @removeTheme="$emit('removeTheme', $event)"
      @bannedKeywordsModalIsVisible="isBannedKeywordsModalVisible = true"
      @closeModal="closeModalEditThemes"
    />
    <div v-if="isEditSomeModalVisible">
      <ThemesEditModal
        ref="ThemesEditModalRef"
        :show="isEditSomeModalVisible"
        :propReadOnly="propReadOnly"
        :isEditThemes="true"
        :textAnalysisObject="textAnalysisObject"
        :sortedResponsesLength="sortedResponsesLength"
        :sortedThemes="someSortedThemes"
        :hasFetchedLemmas="hasFetchedLemmas"
        :includeSmartCoverage="includeSmartCoverage"
        :unsortedResponses="unsortedResponses"
        :hasFetchedSentences="hasFetchedSentences"
        @updateTextObject="$emit('updateTextObject', $event)"
        @updateTheseThemes="$emit('updateTheseThemes', $event)"
        @removeTheme="$emit('removeTheme', $event)"
        @bannedKeywordsModalIsVisible="isBannedKeywordsModalVisible = true"
        @closeModal="closeModalEditSomeThemes"
      />
    </div>
    <ThemesAutoGenerateModal
      v-if="textAnalysisObject"
      :hasFetchedLemmas="hasFetchedLemmas"
      :hasFetchedSentences="hasFetchedSentences"
      :propReadOnly="propReadOnly"
      :includeSmartCoverage="includeSmartCoverage"
      :show="isAutoGenerateModalVisible"
      :textAnalysisObject="textAnalysisObject"
      :sortedResponsesLength="sortedResponsesLength"
      :sortedThemes="sortedThemes"
      :unsortedResponses="unsortedResponses"
      @updateTheseThemes="$emit('updateTheseThemes', $event)"
      @updateTextObject="$emit('updateTextObject', $event)"
      @bannedKeywordsModalIsVisible="isBannedKeywordsModalVisible = true"
      @closeModal="closeModalAutoGenerate"
    />
    <BannedKeywordsModal
      v-if="textAnalysisObject"
      :textAnalysisObject="textAnalysisObject"
      :propReadOnly="propReadOnly"
      :hasFetchedLemmas="hasFetchedLemmas"
      :show="isBannedKeywordsModalVisible"
      @closeModal="closeModalBannedKeywords"
    />
  </div>
</template>

<script>
// Components
import { CreateProject } from "@pigeonline/core"

import InputEditable from "./ProjectAnalysisTextMain/UI/InputEditable.vue"
import SentimentCharts from "./ProjectAnalysisTextMain/Components/SentimentCharts.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import { PigeonDocNodeModel } from "@pigeonline/pigeondoc"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

import ColorPopup from "./Components/ColorPopup.vue"
import SourcePopup from "./Components/SourcePopup.vue"
import CoveragePopup from "./Components/CoveragePopup.vue"
import CoverageOptionsPopup from "./Components/CoverageOptionsPopup.vue"
import KeywordsPopup from "./Components/KeywordsPopup.vue"
import NotesPopup from "./Components/NotesPopup.vue"

import Accordion from "@/components/UI/Accordion.vue"

import PerformanceCharts from "@/components/Project/ProjectAnalysis/ProjectAnalysisPerformance/Components/PerformanceCharts.vue"

// Modal
import CreateNewThemeChart from "./Modals/CreateNewThemeChart.vue"
import ThemesAutoGenerateModal from "./ProjectAnalysisTextMain/Modals/ThemesAutoGenerate.vue"
import ThemesEditModal from "./ProjectAnalysisTextMain/Modals/ThemesEdit.vue"
import BannedKeywordsModal from "./ProjectAnalysisTextMain/Modals/BannedKeywords.vue"

import {
  fetchTopicModels,
  fetchGroups
} from "@/components/CustomTopicModel/Utils/fetch.js"

import CustomTopicModelGroup from "@/components/CustomTopicModel/Models/CustomTopicModelGroup.js"
import CustomTopicModel from "@/components/CustomTopicModel/Models/CustomTopicModel.js"
// Utils
import moment from "moment"

// Mixins
import textAnalysisMixin from "./Mixins/textAnalysisMixin.js"
import themeColorsMixin from "./Mixins/themeColorsMixin.js"
import customTopicModelService from "@/services/customTopicModelService.js"

export default {
  name: "ProjectAnalysisTextTheme",
  mixins: [textAnalysisMixin, themeColorsMixin],
  components: {
    CreateProject,
    InputEditable,
    CreateNewThemeChart,
    ThemesAutoGenerateModal,
    ThemesEditModal,
    BannedKeywordsModal,
    SentimentCharts,
    PerformanceCharts,
    SvgIconDecorative,
    MessageBlock,
    // popups
    ColorPopup,
    KeywordsPopup,
    NotesPopup,
    CoveragePopup,
    CoverageOptionsPopup,
    SourcePopup,
    Accordion,
    ButtonIconOnly,
    SvgIconMeaningful
  },
  props: {
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    allCharts: {
      type: Array,
      default: () => []
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    unsortedResponses: {
      type: Array,
      required: false,
      default: () => []
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    showAdvancedOptions: {
      type: String,
      default: () => "hide"
    },
    themesReloading: {
      themesReloading: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      CUSTOM_TOPIC_MODEL_SERVICE: new customTopicModelService(this.$pigeonline),
      newModel: new CustomTopicModel(),
      newGroup: new CustomTopicModelGroup(),
      themesTabView: "themes",
      isCreateThemeChartModal: false,
      isEditModalVisible: false,
      isSaveToCTMVisible: false,
      isAutoGenerateModalVisible: false,
      isBannedKeywordsModalVisible: false,
      isAddingCharts: false,
      localIncludeSmartCoverage: "exclude",
      localShowCoverageDetails: "show",
      localAllCharts: [],
      duplicateIndex: -1,
      showThemeCheckOptions: [],
      isEditSomeModalVisible: false,
      checkedThemes: [],
      checkedThemeSource: {},
      checkedThemeKeywords: [],
      someSortedThemes: [],
      newKeywordsForTheme: [],
      fetchedCTMs: [],
      fetchedCTMThemes: [],
      modelAdd: {
        modelName: ""
      },
      selectedBoxes: [],
      localThemes: [],
      changingColor: [],
      showSortType: false,
      showReportSettings: false,
      headingStyles: {
        showSentimentsHideCoverage: {
          "grid-template-columns": "30% 30% 40%"
        },
        showSentimentsShowCoverage: {
          "grid-template-columns": "20% 50% 30%"
        },
        hideSentiments: {
          "grid-template-columns": "29% 71%"
        }
      },
      rowStyles: {
        showSentimentsandCoverageDetailsStyle: {
          "grid-template-columns": "20% 1% 10% 25% 30%"
        },
        showSentimentsStyle: {
          "grid-template-columns": "25% 1% 8% 10% 41%"
        },
        hideSentimentsShowCoverageStyle: {
          "grid-template-columns": "25% 1% 8% 57%"
        },
        hideAllStyle: {
          "grid-template-columns": "25% 1% 32% 32%"
        }
      }
    }
  },
  async mounted() {
    this.refreshThemesFromParent()
  },
  async created() {
    this.updateLocalIncludeSmartCoverage()
    this.updateLocalShowCoverageDetails()
    this.updateAllCharts()
  },
  computed: {
    DATASETS_SERVICE() {
      return this.$services.DATASETS_SERVICE
    },
    modifiedNewGroup() {
      let modifiedGroup = this.newGroup
      modifiedGroup.name = this.modelAdd.modelName
      modifiedGroup.owner = this.profile.django_ref_id
      modifiedGroup.created_by = {
        name: this.profile.fullname,
        organization: ""
      }
      return modifiedGroup
    },
    themeType() {
      let type = null
      if (
        !this.checkedThemes[0].source ||
        !this.checkedThemes[0].source.source
      ) {
        return "Manual"
      }
      if (
        this.checkedThemes[0].source.source &&
        this.checkedThemes[0].source.source.topic_model_group_id &&
        this.checkedThemes[0].source.source.topic_model_group_id.length
      ) {
        type = "Custom text model"
      }
      if (
        this.checkedThemes[0].source.source_type &&
        this.checkedThemes[0].source.source_type == "lda"
      ) {
        type = "LDA"
      }
      if (
        this.checkedThemes[0].source.source_type &&
        this.checkedThemes[0].source.source_type == "lsi"
      ) {
        type = "LSI"
      }
      return type
    },
    themeTypeDescription() {
      if (
        this.checkedThemes[0].source.source &&
        this.checkedThemes[0].source.source.topic_model_group_id &&
        this.checkedThemes[0].source.source.topic_model_group_id.length
      ) {
        return "Generated from a custom text model"
      }
      if (
        this.checkedThemes[0].source.source_type &&
        this.checkedThemes[0].source.source_type == "lda"
      ) {
        return "Generated from LDA"
      }
      if (
        this.checkedThemes[0].source.source_type &&
        this.checkedThemes[0].source.source_type == "lsi"
      ) {
        return "Generated from LSI"
      }
      if (
        !this.checkedThemes[0].source ||
        !this.checkedThemes[0].source.source
      ) {
        return "Manually created"
      }
      return null
    },
    isLinked() {
      let isLinked = false
      if (
        this.checkedThemes.length == 1 &&
        this.checkedThemes[0].source &&
        (this.checkedThemes[0].source.manually_linked ||
          (this.checkedThemes[0].source.source &&
            !this.checkedThemes[0].source.manually_unlinked))
      ) {
        isLinked = true
      }
      return isLinked
    },
    isLinkedtoTheme() {
      let isLinkedtoTheme = false
      if (
        this.checkedThemes.length == 1 &&
        this.checkedThemes[0].source &&
        (this.checkedThemes[0].source.manually_linked_theme ||
          (this.checkedThemes[0].source.source &&
            !this.checkedThemes[0].source.manually_unlinked))
      ) {
        isLinkedtoTheme = true
      }
      return isLinkedtoTheme
    },
    isCTMReadOnly() {
      let status = false
      if (
        Object.keys(this.checkedThemeSource).length &&
        this.fetchedCTMs.length
      ) {
        let filteredCTM = this.fetchedCTMs.filter(
          (ctm) => ctm.id == this.checkedThemeSource.group_id
        )
        if (filteredCTM.length) {
          if (
            filteredCTM[0].access_type &&
            filteredCTM[0].access_type == "public__all"
          ) {
            status = true
          }
        }
      }
      return status
    },
    selectedThemes: function () {
      if (this.textAnalysisObject.selectedThemeUUIDs.length) {
        return this.sortedThemes.filter((theme) =>
          this.textAnalysisObject.selectedThemeUUIDs.includes(theme._id)
        )
      }
      return []
    },
    headingStyle() {
      if (this.hasFetchedSentiments) {
        if (this.textAnalysisObject.includeSentiments == "exclude") {
          return this.headingStyles.hideSentiments
        }
      }
      if (
        this.localShowCoverageDetails == "show" &&
        this.textAnalysisObject.includeSentiments == "include"
      ) {
        return this.headingStyles.showSentimentsShowCoverage
      }
      if (
        this.localShowCoverageDetails == "hide" &&
        this.textAnalysisObject.includeSentiments == "include"
      ) {
        return this.headingStyles.showSentimentsHideCoverage
      }
      return this.headingStyles.hideSentiments
    },
    gridStyle() {
      if (this.hasFetchedSentiments) {
        if (
          this.localShowCoverageDetails == "show" &&
          this.textAnalysisObject.includeSentiments == "include"
        ) {
          return this.rowStyles.showSentimentsandCoverageDetailsStyle
        }
        if (
          this.localShowCoverageDetails == "hide" &&
          this.textAnalysisObject.includeSentiments == "include"
        ) {
          return this.rowStyles.showSentimentsStyle
        }
      }
      if (
        this.localShowCoverageDetails == "show" &&
        this.textAnalysisObject.includeSentiments == "exclude"
      ) {
        return this.rowStyles.hideSentimentsShowCoverageStyle
      }
      if (
        this.localShowCoverageDetails == "hide" &&
        this.textAnalysisObject.includeSentiments == "exclude"
      ) {
        return this.rowStyles.hideAllStyle
      }
      return this.rowStyles.hideSentimentsShowCoverageStyle
    },

    // replace
    isActive() {
      return this.currentState
    },
    // replace
    checkedValue: {
      get() {
        return this.defaultState
      },
      set(newValue) {
        this.currentState = newValue
      }
    },
    isThemeListEmpty: function () {
      return (
        this.localThemes &&
        Array.isArray(this.localThemes) &&
        this.localThemes.length === 0
      )
    },
    themesLength() {
      let length = 0
      if (this.sortedThemes) {
        length = this.sortedThemes.length
      }
      return length
    },
    sortIcon() {
      let icon = "percentage"
      if (this.textAnalysisObject.themesSortType == "coverage") {
        icon = "percentage"
      }
      if (this.textAnalysisObject.themesSortType == "alphabetical") {
        icon = "alphabet"
      }
      if (this.textAnalysisObject.themesSortType == "created") {
        icon = "timeAdd"
      }
      // if (this.textAnalysisObject.themesSortType == 'modified') {
      //   icon = "timeCheck"
      // }
      return icon
    }
  },
  methods: {
    changeThemesTabView() {
      if (this.themesTabView == "charts") {
        this.themesTabView = "themes"
      } else {
        this.themesTabView = "charts"
      }
    },
    showCreateThemeChartModal() {
      this.isCreateThemeChartModal = true
    },
    closeCreateThemeChartModal() {
      this.isCreateThemeChartModal = false
    },
    async addNewChart(selectedThemes, filter_name, segment_param) {
      let all_charts = this.deepCloneObj(this.localAllCharts)
      let chart_data = []
      this.isAddingCharts = true

      if (segment_param) {
        // get segment param question, to get values
        const segmentedQuestion = this.$store.getters[
          "datasetDetails/getClientQuestions"
        ].find((q) => q._id.$oid === segment_param)
        if (segmentedQuestion) {
          let all_chart_data = []
          // Every theme is its own chart object in the segmented chart data array
          for (let i = 0; i < selectedThemes.length; i++) {
            // Start by filtering the theme by every unique value
            let unique_values = segmentedQuestion.unique_values
              .filter((value) => value.is_valid)
              .map((value) => value.value)
            let value_scores = []
            for (let a = 0; a < unique_values.length; a++) {
              let new_filter_param = {
                global_combine_action: "and",
                operands: [
                  {
                    client_question_id: segment_param,
                    operator: "equal",
                    values: [unique_values[a]]
                  }
                ]
              }
              let smart_coverage = false
              if (
                this.textAnalysisObject.includeSmartCoverage &&
                this.textAnalysisObject.includeSmartCoverage === "include"
              ) {
                smart_coverage = true
              }
              // These are used so the backend can detect banned comments (and will drop them from the responses loop)
              let proj_id = this.project.id
              if (this.project.id.$oid) {
                proj_id = this.project.id.$oid
              }
              let payload = {
                client_question_id: this.selectedTextQuestionId,
                keywords: selectedThemes[i].keywords,
                filter_params: new_filter_param,
                data_set_id: this.textAnalysisObject.datasetId,
                smart_coverage: smart_coverage,
                project_id: proj_id, // used so the backend can detect banned comments
                text_analysis_uuid: this.textAnalysisObject.uuid // used so the backend can detect banned comments
              }
              let thisEdit = this.deepCloneObj(selectedThemes[i])
              if (thisEdit._id) {
                if (thisEdit._id.$oid) {
                  thisEdit._id = thisEdit._id.$oid
                }
                payload["theme_id"] = thisEdit._id
                payload["project_id"] = this.project.id
              }

              const thisThemeResponse = await this.TEXT_SERVICE.filterTheme(
                payload
              ).then((response) => {
                return response
              })

              let formatedID = {
                _id: this.selectedTextQuestionId
              }
              const denominator = await this.fetchTextQuestionResponses(
                formatedID,
                new_filter_param
              )

              let this_segment_count_dict = {
                overall_mean: this.roundToTwo(
                  (thisThemeResponse.filtered_coverage.total /
                    denominator.length) *
                    100
                ),
                segment_value: unique_values[a],
                question_title: segmentedQuestion.question_title,
                question_text: segmentedQuestion.question_text,
                client_question_title: selectedThemes[i].name
              }
              value_scores.push(this_segment_count_dict)
            }
            let this_sub_chart = {}
            this_sub_chart["_id"] = selectedThemes[i]._id
            this_sub_chart["client_question_analysis"] = {
              question_title: selectedThemes[i].name,
              unique_segments_count_dict: value_scores
            }
            all_chart_data.push(this_sub_chart)
          }

          let this_chart = {
            segmented_by: segmentedQuestion.question_title,
            filtered_by: "",
            chart_data: all_chart_data
          }
          all_charts.push(this_chart)
          this.localAllCharts = all_charts
          this.$emit("updateAllCharts", all_charts)
        }
      }

      if (!segment_param) {
        for (let i = 0; i < selectedThemes.length; i++) {
          let coverage = this.parseCoveragePercentage(
            selectedThemes[i],
            this.sortedResponsesLength
          )
          let chartObject = {
            value: coverage,
            label: selectedThemes[i].name,
            unique_numbers_count_dict: selectedThemes[i].coverage_count,
            theme_color: selectedThemes[i].theme_color
          }
          chart_data.push(chartObject)
        }
        let chart_filter_name = ""
        if (filter_name) {
          chart_filter_name = filter_name
        }
        let this_chart = {
          segmented_by: "",
          filtered_by: chart_filter_name,
          chart_data: chart_data
        }
        all_charts.push(this_chart)
        this.localAllCharts = all_charts
        this.$emit("updateAllCharts", all_charts)
      }
      this.isAddingCharts = false
    },
    deleteChart(index) {
      let all_charts = this.deepCloneObj(this.localAllCharts)
      all_charts.splice(index, 1)
      this.localAllCharts = all_charts
      this.$emit("updateAllCharts", all_charts)
    },
    updateLocalIncludeSmartCoverage() {
      this.localIncludeSmartCoverage =
        this.textAnalysisObject.includeSmartCoverage
    },
    updateLocalShowCoverageDetails() {
      this.localShowCoverageDetails =
        this.textAnalysisObject.showCoverageDetails
    },
    updateAllCharts() {
      if (this.textAnalysisObject.allCharts) {
        this.localAllCharts = this.textAnalysisObject.allCharts
      }
    },
    switchSort(sort_type) {
      this.$emit("updateSortType", sort_type)
      this.showSortType = false
    },
    switchSortOrder() {
      if (this.textAnalysisObject.themesSortOrder == "descending") {
        this.$emit("updateSortOrder", "ascending")
      } else {
        this.$emit("updateSortOrder", "descending")
      }
    },
    openEditSomeThemes() {
      if (!this.propReadOnly) {
        this.someSortedThemes = this.checkedThemes
        this.isEditSomeModalVisible = true
      }
    },
    openSaveCTM() {
      if (!this.propReadOnly) {
        this.isSaveToCTMVisible = !this.isSaveToCTMVisible
      }
    },
    addToShowThemeCheckOptions(checked_theme) {
      let checkedThemes = this.deepCloneObj(this.checkedThemes)
      let checkedThemesFilter = []
      checkedThemesFilter = checkedThemes.filter(
        (theme) => theme._id == checked_theme._id
      )
      if (checkedThemesFilter.length) {
        this.checkedThemes = checkedThemes.filter(
          (theme) => theme._id != checked_theme._id
        )
      } else {
        this.checkedThemes.push(checked_theme)
      }

      let showThemeCheckOptions = this.deepCloneObj(this.showThemeCheckOptions)
      let opennedOption = []
      opennedOption = showThemeCheckOptions.filter(
        (theme) => theme._id == checked_theme._id
      )
      if (opennedOption.length) {
        this.showThemeCheckOptions = showThemeCheckOptions.filter(
          (theme) => theme._id != checked_theme._id
        )
      } else {
        this.showThemeCheckOptions.push(checked_theme)
      }
      if (this.checkedThemes.length > 1) {
        this.isSaveToCTMVisible = false
      }
    },
    async addToSavedThemesinTextTheme(themes) {
      if (themes.length) {
        let payload = {
          themes: themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (theseThemes.length) {
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "Container"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
        }
      }
    },
    newGroupCreated(model) {
      this.setThisCTM(model, this.checkedThemes[0])
    },
    async saveNewKeywords() {
      if (
        this.checkedThemes.length == 1 &&
        Object.keys(this.checkedThemeSource).length
      ) {
        // get existing keywords, add new keywords
        let newKeywords = []
        if (Object.keys(this.checkedThemeSource)) {
          newKeywords = this.checkedThemeSource.keywords
        }
        newKeywords = newKeywords.concat(this.newKeywordsForTheme)
        await this.CUSTOM_TOPIC_MODEL_SERVICE.update(
          this.checkedThemeSource._id.$oid,
          {
            keywords: newKeywords
          }
        )
        this.checkedThemeSource.keywords = newKeywords
        this.newKeywordsForTheme = []
      }
    },
    async fetchCTMGroups() {
      this.fetchedCTMs = await fetchGroups()
    },
    async setThisCTM(model, theme, skip) {
      if (this.localThemes && !skip) {
        let themes = this.deepCloneObj(this.localThemes)
        let filteredThemes = themes.filter(
          (filteredTheme) => filteredTheme._id == theme._id
        )

        if (filteredThemes.length) {
          filteredThemes[0].source.manually_linked = model.id
          filteredThemes[0].source.manually_linked_theme = ""
          filteredThemes[0].source.manually_linked_model_name = model.name
          let payload = {
            themes: filteredThemes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (theseThemes.length) {
            let updatedThemeInfo = {
              themes: theseThemes,
              source: "Container"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
            this.checkedThemes[0] = filteredThemes[0]
            this.setFetchedCTMThemes()
          }
        }
      }
    },
    async setThisCTMTheme(model_theme_id, theme) {
      if (this.localThemes) {
        let themes = this.deepCloneObj(this.localThemes)
        let use_this_id = theme._id
        if (use_this_id.$oid) {
          use_this_id = use_this_id.$oid
        }
        let filteredThemes = themes.filter(
          (filteredTheme) => filteredTheme._id == use_this_id
        )
        if (filteredThemes.length) {
          filteredThemes[0].source.manually_linked_theme = model_theme_id
          let payload = {
            themes: filteredThemes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (theseThemes.length) {
            let updatedThemeInfo = {
              themes: theseThemes,
              source: "Container"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
            this.checkedThemes[0] = filteredThemes[0]
            this.fetchCTMKeywords()
          }
        }
      }
    },
    // This can only happen when manually matching, as otherwise the source.source already defines the theme id
    async setNewCTMTheme() {
      let newModel = this.newModel
      newModel.name = this.checkedThemes[0].name
      newModel.keywords = this.checkedThemes[0].keywords
      newModel.source = {
        name: "",
        url: ""
      }
      if (this.checkedThemes[0].source.manually_linked) {
        newModel.group_id = this.checkedThemes[0].source.manually_linked
        const newTheme = await this.CUSTOM_TOPIC_MODEL_SERVICE.create(
          this.newModel
        )
        if (newTheme) {
          await this.setThisCTMTheme(newTheme._id.$oid, this.checkedThemes[0])
        }
      }
    },
    async removeThisCTM(theme) {
      let themes = this.deepCloneObj(this.localThemes)
      let filteredThemes = themes.filter(
        (filteredTheme) => filteredTheme._id == theme._id
      )

      if (filteredThemes.length) {
        filteredThemes[0].source.manually_linked = ""
        filteredThemes[0].source.manually_linked_theme = ""
        filteredThemes[0].source.manually_linked_model_name = ""
        if (filteredThemes[0].source.source) {
          filteredThemes[0].source.manually_unlinked = true
        }
        let payload = {
          themes: filteredThemes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (theseThemes.length) {
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "Container"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.checkedThemes[0] = filteredThemes[0]
          this.fetchCTMGroups()
          this.fetchedCTMThemes = []
          this.checkedThemeSource = {}
        }
      }
    },
    async relinkThisCTM() {
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.manually_unlinked
      ) {
        if (this.localThemes) {
          let themes = this.deepCloneObj(this.localThemes)
          let filteredThemes = themes.filter(
            (filteredTheme) => filteredTheme._id == this.checkedThemes[0]._id
          )

          if (filteredThemes.length) {
            filteredThemes[0].source.manually_linked = ""
            filteredThemes[0].source.manually_linked_theme = ""
            filteredThemes[0].source.manually_linked_model_name = ""
            filteredThemes[0].source.manually_unlinked = false
            let payload = {
              themes: filteredThemes,
              text_analysis_uuid: this.textAnalysisObject.uuid
            }
            const theseThemes = await this.TEXT_SERVICE.saveThemes(
              payload
            ).then((response) => {
              return response
            })
            if (theseThemes.length) {
              let updatedThemeInfo = {
                themes: theseThemes,
                source: "Container"
              }
              this.$emit("updateTheseThemes", updatedThemeInfo)
              this.checkedThemes[0] = filteredThemes[0]
              this.fetchCTMKeywords()
              this.setFetchedCTMThemes()
            }
          }
        }
      }
    },
    async setFetchedCTMThemes() {
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.manually_linked
      ) {
        let fetchedCTMThemes = await fetchTopicModels(
          this.checkedThemes[0].source.manually_linked
        )
        this.fetchedCTMThemes = fetchedCTMThemes
      }
    },
    async fetchCTMKeywords() {
      let topic_model_group_id = ""
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.source &&
        this.checkedThemes[0].source.source.topic_model_group_id
      ) {
        topic_model_group_id =
          this.checkedThemes[0].source.source.topic_model_group_id
      }
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.manually_linked
      ) {
        topic_model_group_id = this.checkedThemes[0].source.manually_linked
      }
      let topic_model_theme_id = ""
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.source &&
        this.checkedThemes[0].source.source.theme_id &&
        !this.checkedThemes[0].source.manually_unlinked
      ) {
        topic_model_theme_id = this.checkedThemes[0].source.source.theme_id
      }
      if (
        this.checkedThemes[0].source &&
        this.checkedThemes[0].source.manually_linked &&
        this.checkedThemes[0].source.manually_linked &&
        this.checkedThemes[0].source.manually_linked_theme
      ) {
        topic_model_theme_id =
          this.checkedThemes[0].source.manually_linked_theme
      }
      if (topic_model_group_id && topic_model_theme_id) {
        let fetchedModelGroups = await fetchTopicModels(topic_model_group_id)
        if (fetchedModelGroups && fetchedModelGroups.length) {
          let filteredModelGroup = fetchedModelGroups.filter(
            (theme_group) => theme_group._id.$oid == topic_model_theme_id
          )
          if (filteredModelGroup && filteredModelGroup.length) {
            this.checkedThemeSource = filteredModelGroup[0]
            if (filteredModelGroup[0].keywords.length) {
              this.newKeywordsForTheme = this.checkedThemes[0].keywords
                .filter(
                  (keyword) =>
                    !filteredModelGroup[0].keywords.includes(
                      keyword.toLowerCase()
                    )
                )
                .map((keyword) => keyword.toLowerCase())
            }
          }
        }
      }
    },
    isChecked(theme) {
      const allThemes = this.textAnalysisObject.selectedThemeUUIDs
      if (allThemes.includes(theme._id)) {
        return true
      }
      return false
    },
    updateThisCoverage(theme) {
      this.$emit("updateThisCoverage", theme)
    },
    updateAllCoverages() {
      this.$emit("updateAllCoverages")
    },
    async duplicateTheme() {
      if (!this.propReadOnly) {
        const sortedThemes = this.deepCloneObj(this.sortedThemes)
        const checkedThemes = this.deepCloneObj(this.checkedThemes)
        let found_colors = sortedThemes.map((theme) => theme.theme_color)
        for (let i = 0; i < checkedThemes.length; i++) {
          let newTheme = checkedThemes[i]

          let newColor = "#6a88aa"
          let assignedColor = await this.assignColor(found_colors)
          if (assignedColor) {
            newColor = assignedColor
          }
          // Strip out id, dates,
          newTheme.created_on = moment().valueOf()
          delete newTheme._id
          newTheme.name = newTheme.name + "_copy"
          newTheme.theme_color = newColor
          let themes = []
          themes.unshift(newTheme)
          let payload = {
            themes: themes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "Themes"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.showThemeCheckOptions = []
          found_colors.push(newColor)
        }
        this.selectedBoxes = []
        this.checkedThemes = []
      }
    },

    async copyAndMerge(also_delete) {
      if (!this.propReadOnly) {
        const sortedThemes = this.deepCloneObj(this.sortedThemes)
        const checkedThemes = this.deepCloneObj(this.checkedThemes)
        let found_colors = sortedThemes.map((theme) => theme.theme_color)
        let newColor = "#6a88aa"
        let assignedColor = await this.assignColor(found_colors)
        if (assignedColor) {
          newColor = assignedColor
        }
        let newTheme = {}
        newTheme.theme_color = newColor
        newTheme.created_on = moment().valueOf()
        newTheme.added = []
        newTheme.dropped = []
        newTheme.keywords = []
        newTheme.keyword_matches = []
        newTheme.filtered_keyword_matches = []
        newTheme.notes = []
        newTheme.coverage = {}
        newTheme.coverage_count = 0
        newTheme.coverage_queue = 0
        newTheme.filtered_coverage = {}
        newTheme.filtered_coverage_count = 0
        newTheme.text_analysis_uuid = this.textAnalysisObject.uuid
        for (let i = 0; i < checkedThemes.length; i++) {
          if (checkedThemes[i].keywords.length) {
            newTheme.keywords = newTheme.keywords.concat(
              checkedThemes[i].keywords
            )
          }
          if (checkedThemes[i].filtered_keyword_matches.length) {
            newTheme.filtered_keyword_matches =
              newTheme.filtered_keyword_matches.concat(
                checkedThemes[i].filtered_keyword_matches
              )
          }
          if (checkedThemes[i].keyword_matches.length) {
            newTheme.keyword_matches = newTheme.keyword_matches.concat(
              checkedThemes[i].keyword_matches
            )
          }
          if (checkedThemes[i].added.length) {
            newTheme.added = newTheme.added.concat(checkedThemes[i].added)
          }
          if (checkedThemes[i].dropped.length) {
            newTheme.dropped = newTheme.dropped.concat(checkedThemes[i].dropped)
          }
          if (checkedThemes[i].notes.length) {
            newTheme.notes = newTheme.notes.concat(checkedThemes[i].notes)
          }
          if (i == 0) {
            newTheme.name = checkedThemes[i].name
          }
          if (i > 0) {
            newTheme.name = newTheme.name + checkedThemes[i].name
          }

          if (i < checkedThemes.length - 1) {
            newTheme.name = newTheme.name + " + "
          }
        }
        let found_names = sortedThemes.map((theme) => theme.name)
        if (found_names.includes(newTheme.name)) {
          for (let x = 0; x < 100; x++) {
            if (!found_names.includes(newTheme.name + x)) {
              newTheme.name = newTheme.name + x
              break
            }
          }
        }

        let themes = []
        themes.unshift(newTheme)
        let payload = {
          themes: themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        let updatedThemeInfo = {
          themes: theseThemes,
          source: "Themes"
        }
        this.$emit("updateTheseThemes", updatedThemeInfo)

        if (also_delete) {
          this.confirmedDelete()
        } else {
          this.showThemeCheckOptions = []
          this.selectedBoxes = []
          this.checkedThemes = []
        }
      }
    },
    onClickMergeAndDelete() {
      if (!this.propReadOnly) {
        this.setConfirmText({
          btn: "Yes",
          title: "Confirm Merge and Delete",
          message:
            "This will combine themes and delete the originals. Are you sure you want to delete these themes?"
        })
        this.setConfirmSourceComponent("themes")
        this.setConfirmType("merge-delete")
        this.setConfirmIsVisible(true)
        this.setConfirmStatus(false)
      }
    },

    onClickDelete() {
      if (!this.propReadOnly) {
        this.setConfirmText({
          btn: "Yes",
          title: "Confirm Delete",
          message: "Are you sure you want to delete this theme?"
        })
        this.setConfirmSourceComponent("themes")
        this.setConfirmType("delete")
        this.setConfirmIsVisible(true)
        this.setConfirmStatus(false)
      }
    },
    async confirmedDelete() {
      let allThemesToDelete = this.deepCloneObj(this.checkedThemes)
      for (let i = 0; i < allThemesToDelete.length; i++) {
        await this.saveDeletedTheme(allThemesToDelete[i]._id)
        this.$emit("removeTheme", allThemesToDelete[i]._id)
      }
      this.selectedBoxes = []
      this.checkedThemes = []
      this.showThemeCheckOptions = []
    },
    setShowAdvancedOptions() {
      let option = "hide"
      if (this.showAdvancedOptions && this.showAdvancedOptions == "hide") {
        option = "show"
      }
      this.$emit("setShowAdvancedOptions", option)
    },
    showChangeColor(index) {
      let filteredThemes = []
      filteredThemes = this.changingColor.filter((theme) => theme == index)
      if (filteredThemes.length) {
        this.changingColor = this.changingColor.filter(
          (theme) => theme != index
        )
      } else {
        this.changingColor = []
        this.changingColor.push(index)
      }
    },
    changeThemeColor(color, index) {
      let allThemes = this.deepCloneObj(this.localThemes)
      let foundTheme = allThemes.filter((theme) => theme._id == index)
      foundTheme[0].theme_color = color
      let updatedThemeInfo = {
        themes: foundTheme,
        source: "Container"
      }
      this.$emit("updateTheseThemes", updatedThemeInfo)
      this.changingColor = []
    },
    updateShowCoverageDetails(option) {
      this.localShowCoverageDetails = option
      this.setShowCoverageDetails(option)
    },
    updateIncludeSmartCoverage() {
      let option = "exclude"
      if (
        !this.localIncludeSmartCoverage ||
        this.localIncludeSmartCoverage == "exclude"
      ) {
        option = "include"
      }
      this.localIncludeSmartCoverage = option
      this.setIncludeSmartCoverage(option)
    },
    async onRemoveNote(selection) {
      if (!selection || !selection.responseId) return

      this.setShowSpinner(true)
      let themeUUID = ""
      for (let i = 0; i < this.sortedThemes.length; i++) {
        for (let x = 0; x < this.sortedThemes[i].notes.length; x++) {
          let thisTheme = this.deepCloneObj(this.sortedThemes[i])

          if (thisTheme.notes[x].themeUUID && selection.themeUUID) {
            themeUUID = selection.themeUUID

            break
          } else {
            if (
              thisTheme.notes[x].responseId == selection.responseId &&
              thisTheme.notes[x].startIndex == selection.startIndex
            ) {
              themeUUID = this.sortedThemes[i]._id
              break
            }
          }
        }
      }

      if (themeUUID !== "") {
        this.saveTextThemeNotesRemoved(themeUUID, selection)
      }
      this.setShowSpinner(false)
    },
    findResponses(theme) {
      let themeResponses = []
      let idsToIgnore = []
      if (theme.dropped) {
        idsToIgnore = theme.dropped
      }
      let keywordIdsToFetch = []
      if (
        this.textAnalysisObject.filterParams &&
        Object.keys(this.textAnalysisObject.filterParams).length &&
        theme.filtered_keyword_matches
      ) {
        if (
          theme.filtered_keyword_matches &&
          theme.filtered_keyword_matches.length > 0
        ) {
          keywordIdsToFetch = this.unsortedResponses
            .filter((response) =>
              theme.filtered_keyword_matches.some((match) =>
                response.response.toLowerCase().includes(match.toLowerCase())
              )
            )
            .map((response) => response._id || response.id)
        }
      } else {
        if (theme.keyword_matches && theme.keyword_matches.length > 0) {
          keywordIdsToFetch = this.unsortedResponses
            .filter((response) =>
              theme.keyword_matches.some((match) =>
                response.response.toLowerCase().includes(match.toLowerCase())
              )
            )
            .map((response) => response._id || response.id)
        }
      }

      keywordIdsToFetch = keywordIdsToFetch.filter(
        (id) => !idsToIgnore.includes(id)
      )
      let noteIdsToFetch = []
      if (theme.notes && theme.notes.length > 0) {
        noteIdsToFetch = theme.notes
          .filter(
            (el) =>
              !idsToIgnore.includes(el.responseId) &&
              !noteIdsToFetch.includes(el.responseId)
          )
          .map((el) => el.responseId)
      }
      if (noteIdsToFetch.length || keywordIdsToFetch.length) {
        if (this.unsortedResponses) {
          themeResponses = this.unsortedResponses.filter(
            (response) =>
              noteIdsToFetch.includes(response.id) ||
              keywordIdsToFetch.includes(response.id)
          )
        }
      }
      return themeResponses
    },
    getIdsToFetch(theme) {
      let idsToIgnore = []
      if (theme.dropped) {
        idsToIgnore = theme.dropped
      }
      let idsToFetch = []

      if (
        this.textAnalysisObject.filterParams &&
        Object.keys(this.textAnalysisObject.filterParams).length &&
        theme.filtered_keyword_matches
      ) {
        if (
          theme.filtered_keyword_matches &&
          theme.filtered_keyword_matches.length > 0
        ) {
          idsToFetch = theme.filtered_keyword_matches
            .filter((el) => !idsToIgnore.includes(el.responseId))
            .map((el) => el.responseId)
        }
      } else {
        if (theme.keyword_matches && theme.keyword_matches.length > 0) {
          idsToFetch = theme.keyword_matches
            .filter((el) => !idsToIgnore.includes(el.responseId))
            .map((el) => el.responseId)
        }
      }

      if (theme.notes && theme.notes.length > 0) {
        idsToFetch = theme.notes
          .filter(
            (el) =>
              !idsToIgnore.includes(el.responseId) &&
              !idsToFetch.includes(el.responseId)
          )
          .map((el) => el.responseId)
      }
      return idsToFetch
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "themes"
      }
      this.$emit("updateTextObject", object)
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    refreshThemesFromParent() {
      this.localThemes = this.sortedThemes
      if (this.$refs.ThemesEditModalRef) {
        this.$refs.ThemesEditModalRef.refreshThemesFromParent()
      }
    },
    toggleTheme() {
      let filteredThemes = this.checkedThemes.filter(
        (theme) => theme.coverage_count > 0
      )
      let themeIds = filteredThemes.map((theme) => theme._id)
      if (themeIds.length) {
        this.setSelectedThemeUUIDs(themeIds)
      }
    },
    selectThisResponse(response_id) {
      if (response_id) {
        let these_responses = []
        these_responses.push(response_id)
        this.setSelectedResponseIds(these_responses)
      }
    },
    async addNewNote(note_details) {
      let theseThemes = this.deepCloneObj(this.sortedThemes)
      for (let i = 0; i < theseThemes.length; i++) {
        if (theseThemes[i]._id == note_details.theme._id) {
          let note = {
            content: note_details.sentence.text,
            length:
              note_details.sentence.end_idx - note_details.sentence.start_idx,
            responseId: note_details.sentence.response_id,
            row_index: note_details.sentence.row_index,
            source: {
              questionId: this.textAnalysisObject.selectedTextQuestion._id,
              responseText: ""
            },
            startIndex: note_details.sentence.start_idx,
            text: ""
          }
          theseThemes[i].notes.push(note)
          let themes = []

          if (theseThemes[i]._id) {
            if (theseThemes[i]._id.$oid) {
              theseThemes[i]._id = theseThemes[i]._id.$oid
            }
          }
          if (theseThemes[i].is_proposed) {
            delete theseThemes[i].is_proposed
            delete theseThemes[i]._id
          }
          if (theseThemes[i].is_autogenerated) {
            delete theseThemes[i].is_autogenerated
            delete theseThemes[i]._id
          }

          themes.push(theseThemes[i])
          let payload = {
            themes: themes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const savedThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (savedThemes.length) {
            let updatedThemeInfo = {
              themes: savedThemes,
              source: "Themes"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
          }
        }
      }
    },
    showThemeToggleButton(theme) {
      if (theme.length) {
        return true
      }
      return false
    },
    closeModalBannedKeywords() {
      this.isBannedKeywordsModalVisible = false
    },
    closeModalAutoGenerate() {
      this.isAutoGenerateModalVisible = false
    },
    closeModalEditThemes() {
      this.isEditModalVisible = false
    },
    closeModalEditSomeThemes() {
      this.selectedBoxes = []
      this.checkedThemes = []
      this.showThemeCheckOptions = []
      this.isEditSomeModalVisible = false
    },
    calcRespondentSentimentTotals(data) {
      return data.reduce(
        (totals, data) => {
          if (data > -0.05 && data < 0.05) totals.neu++
          if (data >= 0.05) totals.pos++
          if (data <= -0.05) totals.neg++
          return totals
        },
        { neu: 0, pos: 0, neg: 0 }
      )
    },
    generatePosNegData: function (data, themeName) {
      if (!data || !Array.isArray(data)) data = []
      const SCALE_DOWN_FACTOR = Math.ceil(Math.max(data.length) / 30)
      let filteredData = []
      for (let i = 0; i < data.length; i++) {
        let default_score = data[i].response_sentiment.compound_en
        if (this.textAnalysisObject.adjustedSentiments) {
          const adjustedSentiment =
            this.textAnalysisObject.adjustedSentiments.filter(
              (val) => val.responseId == data[i].id
            )

          if (adjustedSentiment != null && adjustedSentiment.length) {
            default_score = adjustedSentiment[0].adjustedSentiment
          }
        }

        filteredData.push(default_score)
      }

      const totals = this.calcRespondentSentimentTotals(filteredData)
      const description =
        `The chart shows sentiments for each text entry ` +
        `in theme <em>${themeName}</em>. It shows ${this.roundToTwo(
          totals.neg
        )} ` +
        `responses have a negative sentiment, ${this.roundToTwo(totals.neu)} ` +
        `are netural, and ${this.roundToTwo(totals.pos)} are positive.`

      let shortenedData = filteredData
      if (filteredData.length > 1000) {
        shortenedData = filteredData.filter(
          (_, index) => index % SCALE_DOWN_FACTOR === 0
        )
      }
      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChartSmall",
        content: {
          data: shortenedData,
          meta: {
            veryPositiveColour: "var(--very-positive-soft)",
            positiveColour: "var(--somewhat-positive-soft)",
            neutralColour: "var(--neutral-soft)",
            negativeColour: "var(--somewhat-negative-soft)",
            veryNegativeColour: "var(--very-negative-soft)"
          }
        },
        meta: {
          description: description
        }
      })
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2")
    }
  },
  watch: {
    confirmStatus: async function (val) {
      if (
        val &&
        this.confirmSourceComponent === "themes" &&
        this.confirmType === "delete"
      ) {
        await this.confirmedDelete()
        this.setConfirmStatus(false)
      }

      if (
        val &&
        this.confirmSourceComponent === "themes" &&
        this.confirmType === "merge-delete"
      ) {
        await this.copyAndMerge(true)
        this.setConfirmStatus(false)
      }
    },
    allCharts: async function (val) {
      if (val && val.length) {
        this.updateAllCharts()
      }
    },
    isSaveToCTMVisible: async function (val) {
      if (val) {
        this.fetchCTMGroups()
        if (this.isLinked && this.isLinkedtoTheme) {
          this.fetchCTMKeywords()
        }
        if (!this.isLinked) {
          if (!this.checkedThemes[0].source) {
            this.checkedThemes[0].source = {}
          }
        }
        if (this.isLinked && !this.isLinkedtoTheme) {
          this.setFetchedCTMThemes()
        }
      }
      if (!val) {
        this.newKeywordsForTheme = []
        this.checkedThemeSource = {}
        this.fetchedCTMs = []
        this.fetchedCTMThemes = []
      }
    }
  }
}
</script>
