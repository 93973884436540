<template>
  <Modal
    class="project-analysis-text-modal modal-themes-add"
    header-tag="h3"
    :isEditThemes="true"
    :ref="modalRef"
    :show="show"
    :reset-listener="resetListener"
    v-if="textAnalysisObject"
    @close="closeModal"
  >
    <template #header>
      <VisibleText>Edit themes: Add </VisibleText>
      {{ type }}
    </template>
    <ThemeList
      :ref="themeListRef"
      :type="type"
      :propReadOnly="propReadOnly"
      :unsortedResponses="unsortedResponses"
      :selection="selection"
      :selectedNewThemeUUID="selectedNewThemeUUID"
      :themes="themeList"
      :textAnalysisObject="textAnalysisObject"
      :should-focus-first-theme="shouldFocusFirstTheme"
      :key="updateKey"
      :selectedTextModalResponseId="selectedTextModalResponseId"
      :hasFetchedLemmas="hasFetchedLemmas"
      :duplicateIndex="duplicateIndex"
      :includeSmartCoverage="includeSmartCoverage"
      :sortedResponsesLength="sortedResponsesLength"
      :sortedThemes="sortedThemes"
      :hasFetchedSentences="hasFetchedSentences"
      @selectedRespondent="updateSelectedRespondent"
      @updatingSpinner="updateSpinner"
      @update-theme-title="
        renameThemeTitle(themeList[$event.index], $event.title)
      "
      @updated="unsavedChanges = true"
    />
    <template #footer>
      <div class="modal-footer__options">
        <button
          class="btn-default"
          role="button"
          @click.stop="addToSavedThemes"
          :disabled="
            !unsavedChanges ||
            textAnalysisObject.showSpinner ||
            awaitingLoading ||
            propReadOnly
          "
        >
          <div v-if="!awaitingLoading">
            <VisibleText>Save changes</VisibleText>
          </div>
          <div v-else>
            <VisibleText>Wait for coverage to complete</VisibleText>
          </div>
        </button>
        <button
          class="btn-create btn-secondary"
          role="button"
          @click.stop="createTheme"
          :disabled="propReadOnly"
        >
          <VisibleText>Create new theme</VisibleText>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"

export default {
  name: "ThemesAdd",
  mixins: [ThemeListMixin, textAnalysisMixin, themeColorsMixin],
  props: {
    type: {
      default: () => "keyword",
      type: String
    },
    selection: {
      type: Object,
      required: false
    },
    autoCreateTheme: {
      default: () => false,
      type: Boolean
    },
    autoCreateThemeTitle: {
      default: () => "unnamed theme",
      type: String
    },
    autoCreateThemeKeywords: {
      default: () => [],
      type: Array
    },
    show: {
      default: () => false,
      type: Boolean
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    unsortedResponses: {
      default: () => [],
      type: Array
    },
    selectedTextModalResponseId: {
      type: String,
      required: false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false
    },
    hasFetchedSentences: {
      type: Boolean,
      default: () => false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isAutoCreateEnabled: true,
      selectedNewThemeUUID: "",
      duplicateIndex: -1,
      spinnerQueue: []
    }
  },
  created() {
    if (this.textAnalysisObject) {
      if (this.sortedThemes.length) {
        this.themeList = this.deepCloneObj(this.sortedThemes)
      }
    }
  },
  computed: {
    awaitingLoading() {
      if (this.spinnerQueue.length) {
        return true
      }
      return false
    },
    parentThemesLength() {
      let length = 0
      if (this.textAnalysisObject) {
        if (this.sortedThemes) {
          length = this.sortedThemes.length
        }
      }
      return length
    },
    foundChanges() {
      let changedThemes = []
      if (!this.themeList || !this.themeList.length) return changedThemes
      let themes = this.deepCloneObj(this.themeList)
      if (!this.sortedThemes.length && themes.length) return themes

      // -1 means a change has been made but update all themes regardless of index
      // if (!this.sortedThemes.length && themes.length) return [-1]
      // if (this.sortedThemes.length !== this.themesList.length) return [-1]
      if (this.sortedThemes) {
        for (let x = 0; x < themes.length; x++) {
          // Each theme has to find at least one matching UUID
          let foundMatchingUUID = false
          for (let i = 0; i < this.sortedThemes.length; i++) {
            if (themes[x]._id === this.sortedThemes[i]._id) {
              foundMatchingUUID = true
              // name changed
              if (themes[x].name !== this.sortedThemes[i].name) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new coverage
              if (
                themes[x].coverage_count != this.sortedThemes[i].coverage_count
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new filtered coverage
              if (
                themes[x].filtered_coverage_count !=
                this.sortedThemes[i].filtered_coverage_count
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              // new keyword length
              if (
                themes[x].keywords.length !==
                this.sortedThemes[i].keywords.length
              ) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
              let foundMatch = []
              // new keyword found
              for (let y = 0; y < themes[x].keywords.length; y++) {
                if (
                  this.sortedThemes[i].keywords.includes(themes[x].keywords[y])
                ) {
                  foundMatch.push(1)
                } else {
                  foundMatch.push(0)
                }
              }
              if (foundMatch.includes(0)) {
                if (!changedThemes.includes(i)) {
                  changedThemes.push(i)
                }
              }
            }
          }
          // entirely new theme, refresh all to be safe, can be optimized later
          if (!foundMatchingUUID) {
            changedThemes.push(themes[x])
          }
        }
      }
      return changedThemes
    }
  },
  methods: {
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    updateSpinner(val) {
      this.spinnerQueue = val
    },
    refreshThemesFromParent() {
      this.themeList = this.deepCloneObj(this.sortedThemes)
    },
    updateSelectedRespondent(selectedID, selection) {
      if (selectedID.$oid) {
        selectedID = selectedID.$oid
      }
      this.selectedNewThemeUUID = selectedID
      if (selection && Object.keys(selection).length && selectedID) {
        let newList = this.deepCloneObj(this.themeList)
        for (let i = 0; i < newList.length; i++) {
          if (newList[i]._id == selectedID) {
            newList[i].keywords.push(selection.content)
          } else {
            for (let x = newList[i].keywords.length - 1; x >= 0; x--) {
              if (newList[i].keywords[x] === selection.content) {
                newList[i].keywords.splice(x, 1)
              }
            }
          }
        }
        this.themeList = newList
      }
    },
    async addToSavedThemes() {
      if (this.foundChanges.length) {
        let these_themes = []
        let this_theme
        for (let x = 0; x < this.foundChanges.length; x++) {
          if (typeof this.foundChanges[x] == "object") {
            this_theme = this.foundChanges[x]
          } else {
            this_theme = this.themeList[this.foundChanges[x]]
          }

          if (this_theme) {
            if (this_theme.is_proposed) {
              delete this_theme["is_proposed"]
              delete this_theme["_id"]
            }
            if (this_theme._id && this_theme._id.$oid) {
              this_theme._id = this_theme._id.$oid
            }
            these_themes.push(this_theme)
          }
        }

        let payload = {
          themes: these_themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const themesForSaving = await this.TEXT_SERVICE.saveThemes(
          payload
        ).then((response) => {
          return response
        })
        if (themesForSaving.length) {
          let updatedThemeInfo = {
            themes: themesForSaving,
            source: "ThemesAdd"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.closeModal()
          this.selectedNewThemeUUID = ""
        }
      }
    },
    // async addToSavedThemes() {
    //   this.isSaving = true
    //   try {
    //     await this.saveTextThemes({
    //       themes: [...this.themeList],
    //       forceCompute: false,
    //       uuid: this.textAnalysisObject.uuid
    //     })
    //     // success
    //     // this.showModalMessage("success", "Themes added successfully.")
    //     this.closeModal()
    //     // from mixin
    //   } catch (e) {
    //     // error
    //     this.showModalMessage(
    //       "error",
    //       "An error occured processing your request. Please try again later."
    //     )
    //     console.error("ThemesAdd.vue:addToSavedThemes: " + e)
    //   } finally {
    //     this.isSaving = false
    //   }
    // },
    async renameThemeTitle(theme, title) {
      if (this.themeList) {
        let themes = this.deepCloneObj(this.themeList)
        if (typeof title !== "string" || title.trim() === "") {
          title = "unnamed theme"
        }
        let foundIndex = -1
        let foundExisting = false
        for (let i = 0; i < themes.length; i++) {
          if (themes[i]._id === theme._id) {
            foundIndex = i
          }
          if (
            themes[i].name &&
            themes[i].name.toLowerCase() == title.toLowerCase()
          ) {
            foundExisting = true
          }
        }
        if (foundIndex > -1 && !foundExisting) {
          themes[foundIndex].name = title
          this.themeList = themes
        }
        if (foundIndex > -1 && foundExisting) {
          this.duplicateIndex = foundIndex
          setTimeout(() => {
            this.duplicateIndex = -1
          }, 6000)
        }
      }
    }
  },
  watch: {
    updateKey: function (val) {
      if (!val) return

      // auto create a theme
      // should be called only when auto create enabled
      // -- usually once per component update when autoCreateTheme = True
      if (this.isAutoCreateEnabled && this.autoCreateTheme) {
        // disable auto create to prevent re-creating the same theme again
        // when the component updates
        this.isAutoCreateEnabled = false
        this.createTheme(
          this.autoCreateThemeTitle,
          this.filterBannedKeywords(this.autoCreateThemeKeywords)
        )
      }
    },
    show: function (val) {
      if (!val) {
        this.isAutoCreateEnabled = true
        this.themeList = []
      } else {
        if (this.sortedThemes.length) {
          this.themeList = this.deepCloneObj(this.sortedThemes)
        }
      }
    },
    parentThemesLength: async function (val) {
      if (!val) return
      if (this.textAnalysisObject) {
        if (this.sortedThemes.length) {
          this.themeList = this.deepCloneObj(this.sortedThemes)
        }
      }
    }
  }
}
</script>
