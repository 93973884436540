import { HELPER_TRANSLATIONS } from "../../../translations"
import { formatMethodologyTableData } from "./helpers"

/*******************************
 *
 * Generate table for Survey Methodology section
 * @param {Object} methodologyData data_set_meta_data field from
 * benchmarking/detailed_results API
 * @param {Array} orgs list of unique organization ids
 * @param {String} lang current report language
 * @returns pigeondoc table node
 *
 *******************************/
const methodologyTable = (methodologyData, orgs, lang) => {
  const tableData = formatMethodologyTableData(methodologyData, orgs, lang)
  const table = {
    type: "table",
    content: { data: tableData },
    meta: {
      caption: HELPER_TRANSLATIONS.methodologyTableCaption[lang],
      lang: lang
    }
  }

  return table
}

export { methodologyTable }
