<template>
  <nav aria-label="translations" class="project-tabs">
    <ul class="project-tabs__list">
      <li
        v-for="item in items"
        :key="item"
        :class="[
          'project-tabs__list-item',
          {
            'project-tabs__list-item-active': activeView === item
          }
        ]"
        style="cursor: pointer"
        @click="setView(item)"
      >
        <div class="project-tabs__list-item-link">
          <span class="project-tabs__list-item-text"> {{ item }} </span>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TranslationTabs",
  data() {
    return {
      activeView: "local",
      items: ["local", "backend", "cache"]
    }
  },
  methods: {
    setView(item) {
      this.activeView = item
      this.$emit("update-activeView", item)
    }
  }
}
</script>
