import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class CustomTopicModelService extends BaseService {
  /**
   * Create new topic model
   * @param {CutomTopicModel} data payload
   *  "name": <str>
      "keywords": <list>,
      "lang": <str>,
      "tags": <list>,
      "source": <dict>
      "optional_fields": <<custom fields>>
    * @returns {CutomTopicModel} 
   */
  async create(data) {
    try {
      const URL =
        app.config.globalProperties.$base_URL +
        "/analysis/text/custom_topic_model"
      const response = await axios.post(`${URL}`, data)
      return response.data
    } catch (e) {
      throw new Error("customTopicModelService.js:create " + e.message)
    }
  }

  /**
   * Delete topic model
   * @param {String} id of topic model
   * @returns {String} status message
   */
  async delete(id) {
    try {
      const URL =
        app.config.globalProperties.$base_URL +
        "/analysis/text/custom_topic_model"
      const response = await axios.delete(`${URL}/${id}`)
      return response.data
    } catch (e) {
      throw new Error("customTopicModelService.js:create " + e.message)
    }
  }

  /**
   * Edit topic model
   * @param {String} id of topic model
   * @param {Object} data topic model object to edit
   * @returns {CutomTopicModel} edited topic model
   */
  async edit(id, data = {}) {
    try {
      const URL =
        app.config.globalProperties.$base_URL +
        "/analysis/text/custom_topic_model"
      const response = await axios.put(`${URL}/${id}`, data)
      return response.data
    } catch (e) {
      throw new Error("customTopicModelService.js:edit " + e.message)
    }
  }

  /**
   * Get one or more custom topic models
   * @param {String} [id] of topic model
   * @param {Object} [params] filters
   * @returns {(CustomTopicMod|Array)} CustomTopicModel with id or an array
   * of CutomTopicModels based on params or if params and id are not included,
   * fetch all
   */
  async get(id, params = {}) {
    try {
      const URL =
        app.config.globalProperties.$base_URL +
        "/analysis/text/custom_topic_model"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      let url = `${URL}${id ? `/${id}` : ""}`
      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("customTopicModelService.js:get " + e.message)
    }
  }

  /**
   * Updates one or more fields of a topic modal
   * @param {String} id of topic model to update
   * @param {Object} data fields to modify
   * @returns {CutomTopicModel} updated topic model
   */
  async update(id, data = {}) {
    try {
      const URL =
        app.config.globalProperties.$base_URL +
        "/analysis/text/custom_topic_model"
      const response = await axios.patch(`${URL}/${id}`, data)
      return response.data
    } catch (e) {
      throw new Error("customTopicModelService.js:update " + e.message)
    }
  }
}
