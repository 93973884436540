<template>
  <div class="response-list-item__respondent-info">
    <!--------------------------------------------
    |
    | FETCHING SPINNER
    |
    --------------------------------------------->
    <Spinner
      :is-loading="isFetching"
      :componentStyle="true"
      message="fetching row info..."
      v-if="isFetching"
    />

    <!--------------------------------------------
    |
    | MAIN CONTENT
    |
    --------------------------------------------->
    <template v-if="!isFetching && data">
      <MessageBlock
        class="response-list-item__respondent-info-empty"
        message-type="information"
        title="No row info"
        v-if="isDataEmpty"
      >
        <VisibleText
          >Sorry, we could find no information for this row. Are you sure
          categories are included in your data and properly defined in the data
          uploading process?</VisibleText
        >
      </MessageBlock>
      <div class="response-list-item__respondent-info-content" v-else>
        <div class="categories-data" v-if="dataCategories">
          <span class="title">
            <VisibleText>Categories</VisibleText>
          </span>
          <ul>
            <li :key="index" v-for="(cat, index) in dataCategories">
              <span class="label"
                >{{ cat.question_title || truncate(40) }}:
              </span>
              <span class="value">{{ cat.value }}</span>
            </li>
          </ul>
        </div>
        <div class="outliers-data" v-if="dataOutliers">
          <span class="title">
            <VisibleText>Outliers</VisibleText>
          </span>
          <ul>
            <li :key="index" v-for="(out, index) in dataOutliers">
              <span class="label"
                >{{ out.question_title || truncate(40) }}:
              </span>
              <span class="value">{{ out.value }}</span>
            </li>
          </ul>
        </div>
        <div class="performance-data" v-if="dataPerformance">
          <span class="title">
            <VisibleText>Performance</VisibleText>
          </span>
          <ul>
            <li :key="key" v-for="(value, key) in dataPerformance">
              <span class="label">{{ key }}: </span>
              <span class="value">{{ value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// Components
import Spinner from "@/components/UI/Spinner.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Services
import TextService from "@/services/textService.js"

// Vuex
import { mapGetters } from "vuex"

// Libraries
import _ from "lodash"

export default {
  name: "ResponseListItemRespondentInfo",
  components: {
    Spinner,
    MessageBlock
  },
  props: {
    responseId: {
      type: String
    },
    clientQuestionId: {
      type: String,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      TEXT_SERVICE: new TextService(this.$pigeonline),
      isFetching: false,
      data: null
    }
  },
  computed: {
    ...mapGetters("project", {
      project: "getProject"
    }),
    dataCategories: function () {
      if (
        !this.data ||
        !this.data.categorical_questions ||
        _.isEmpty(this.data.categorical_questions)
      )
        return null
      return Object.values(this.data.categorical_questions).filter(Boolean)
    },
    dataOutliers: function () {
      if (
        !this.data ||
        !this.data.scale_1_5_outliers ||
        _.isEmpty(this.data.scale_1_5_outliers)
      )
        return null
      return Object.values(this.data.scale_1_5_outliers).filter(Boolean)
    },
    dataPerformance: function () {
      if (
        !this.data ||
        !this.data.performance ||
        _.isEmpty(this.data.performance) ||
        this.data.performance.score == ""
      )
        return null
      return this.data.performance
    },
    isDataEmpty: function () {
      return !this.dataCategories && !this.dataOutliers && !this.dataPerformance
    }
  },
  mounted() {
    if (this.show && !this.isFetching) this.fetchRespondentInfo()
  },
  methods: {
    fetchRespondentInfo: async function () {
      if (!this.data) this.isFetching = true
      try {
        this.data = await this.TEXT_SERVICE.respondentInfo({
          project_id: this.project.id,
          response_id: this.responseId,
          client_question_id: this.clientQuestionId
        })
      } catch (e) {
        console.error(
          "ResponseListItemRespondentInfo.vue:fetchRespondentInfo:: " + e
        )
      } finally {
        this.isFetching = false
      }
    }
  },
  watch: {
    show: function (val) {
      if (!val) return
      if (this.data) return
      this.fetchRespondentInfo()
    },
    "project.settings": function (val) {
      if (!val) return
      if (this.show) this.fetchRespondentInfo()
    }
  }
}
</script>
