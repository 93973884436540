<template>
  <div class="project__select-questions-list">
    <div class="flex" v-if="!hideToolbar">
      <!--------------------------------------
      | search bar
      ---------------------------------------->
      <DatasetTableSearchAndFilter
        :refresh-filter="isRefreshFilter"
        :questionsWithValid="questionsWithValid"
        :invalidTypes="invalidTypes"
        @results="filteredResults"
        @reset="resetFilters"
      />
      <!--------------------------------------
      | select box
      ---------------------------------------->
      <div
        class="table__toolbar table__toolbar--nonsticky"
        v-if="
          !isPerformance ||
          (isPerformance && clientQuestionsFilteredResults.length)
        "
      >
        <label
          class="form-label form-checkbox-label table__toolbar-checkbox-select"
          :data-parent="$options.name"
        >
          <input
            class="table__toolbar-checkbox-select-input"
            type="checkbox"
            name="select-all-questions"
            v-model="selectAll"
          />
          {{ translate("Select All", $options.name) }}
        </label>
      </div>
    </div>

    <!--------------------------------------------
    |
    | FORM - QUESTION CHECKBOXES
    |
    --------------------------------------------->
    <form
      class="dataset__select-questions-form"
      @submit.prevent
      style="margin-top: -0.3em"
    >
      <fieldset class="form-fieldset-group light-scroll-bar">
        <legend class="sr-only">
          {{ translate("select questions", $options.name) }}
        </legend>
        <div
          class="dataset__select-questions-form__checkbox"
          :class="{ smallPadding: smallerUI }"
          style="display: flex"
          v-for="question in filteredQuestions"
          :key="`select-${question._id.$oid}`"
        >
          <div class="col-1" :class="{ 'smallPadding extendedRow': smallerUI }">
            <label class="form-checkbox-label">
              <input
                v-if="isRadio"
                class="form-checkbox-input"
                type="radio"
                name="question"
                :disabled="isInvalid(question)"
                :value="question._id.$oid"
                v-model="selectedQuestionId"
                @change="onChange(question._id.$oid)"
              />
              <input
                v-else
                class="form-checkbox-input"
                type="checkbox"
                name="question"
                :disabled="isInvalid(question)"
                :value="question._id.$oid"
                v-model="selectedQuestionIds"
                @change="onChange(question._id.$oid)"
              />
              <span class="question-title" :class="{ smallFont: smallerUI }">
                {{ question.question_title }}
              </span>
              <span class="question-text" :class="{ smallFont: smallerUI }">
                {{ question.question_text }}
              </span>
            </label>
            <p
              style="padding-left: 1.75rem"
              :class="{ 'smallMargin smallFont': smallerUI }"
            >
              <VisibleText
                class="text-smallcaps"
                :class="{ smallFont: smallerUI }"
                >Data Type</VisibleText
              >:
              {{
                translate(
                  DATA_TYPE_TO_READABlE[question.approved_data_type],
                  $options.name
                )
              }}
            </p>
          </div>
          <slot
            v-if="question._id"
            name="custom-action"
            :question="{ question }"
            :foundObject="findMatchingObject(question)"
          ></slot>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"
import FiltersMixin from "@/utils/mixins/filtersMixin"

import DatasetTableSearchAndFilter from "@/components/Dataset/Components/DatasetTable/DatasetTableSearchAndFilter.vue"

export default {
  name: "SelectQuestionsList",
  mixins: [FiltersMixin],
  props: {
    // a function to check for any questions you want to disable
    toEmitUpdate: {
      default: () => false,
      type: Boolean
    },
    disableInput: {
      default: () => null,
      type: Function
    },
    hideToolbar: {
      default: false,
      type: Boolean
    },
    questions: {
      default: () => [],
      type: Array
    },
    selectedIds: {
      default: () => [],
      type: Array
    },
    selectedId: {
      default: () => "",
      type: String
    },
    invalidTypes: {
      default: () => [],
      type: Array,
      required: false
    },
    smallerUI: {
      default: () => false,
      type: Boolean
    },
    explicitlyExclude: {
      default: () => [],
      type: Array
    },
    isPerformance: {
      default: () => true,
      type: Boolean
    },
    areInvalid: {
      default: () => [],
      type: Array
    },
    isRadio: {
      default: () => false,
      type: Boolean
    }
  },
  components: {
    DatasetTableSearchAndFilter
  },
  data() {
    return {
      DATA_TYPE_TO_READABlE: Object.freeze(DATA_TYPE_TO_READABlE),
      selectAll: false,
      selectedQuestionIds: [],
      selectedQuestionId: "",
      clientQuestionsFilteredResults: [],
      isActiveFilter: false,
      isRefreshFilter: false
    }
  },
  created() {
    this.selectedQuestionIds = this.selectedIds
    this.selectedQuestionId = this.selectedId
  },
  computed: {
    clientQuestionItems() {
      // if questions were passed, use those
      if (this.questions.length > 0) {
        return this.questions
      }
      // no questions passed so use all client questions
      else return this.$store.getters["datasetDetails/clientQuestions"]
    },
    filteredQuestions() {
      const keys = ["question_title", "question_text"]
      let items
      if (this.isActiveFilter) {
        items = this.searchedResultsWithExclusion
      } else {
        items = this.clientQuestionItems
      }

      return this.searchInObjectValues(items, keys)
    },
    searchedResultsWithExclusion() {
      let newResults = this.deepCloneObj(this.clientQuestionsFilteredResults)
      if (this.explicitlyExclude.length) {
        for (let i = 0; i < newResults.length; i++) {
          if (newResults[i]._id) {
            if (newResults[i]._id.$oid) {
              if (this.explicitlyExclude.includes(newResults[i]._id.$oid)) {
                newResults.splice(i, 1)
              }
            }
          }
        }
      }
      return newResults
    },
    questionsWithValid() {
      let uniqueTypes = []
      if (this.clientQuestionItems) {
        for (let i = 0; i < this.clientQuestionItems.length; i++) {
          if (
            this.clientQuestionItems[i].approved_data_type &&
            !uniqueTypes.includes(
              this.clientQuestionItems[i].approved_data_type
            )
          ) {
            uniqueTypes.push(this.clientQuestionItems[i].approved_data_type)
          }
        }
      }
      return uniqueTypes
    }
  },
  methods: {
    // test hiding this
    // isDisabled(item) {
    //   if (this.disableInput) {
    //     return this.disableInput(item)
    //   }
    //   else  {
    //     return this.isInvalid(item)
    //   }
    // },
    isInvalid(item) {
      if (item._id) {
        if (this.areInvalid.includes(item._id.$oid)) {
          return true
        }
      }
      return false
    },
    onChange() {
      if (this.toEmitUpdate) {
        if (this.isRadio) {
          this.$emit("update-selected-question-id", this.selectedQuestionId)
        } else {
          this.$emit("update-selected-question-ids", this.selectedQuestionIds)
        }
      }
    },
    filteredResults(results) {
      this.clientQuestionsFilteredResults = results
      this.isActiveFilter = true
    },
    resetFilters() {
      this.isActiveFilter = false
      this.clientQuestionsFilteredResults = []
    },
    findMatchingObject(question) {
      if (question._id) {
        if (question._id.$oid) {
          for (let i = 0; i < this.clientQuestionItems.length; i++) {
            if (this.clientQuestionItems[i]._id) {
              if (this.clientQuestionItems[i]._id.$oid) {
                if (
                  this.clientQuestionItems[i]._id.$oid === question._id.$oid
                ) {
                  return this.clientQuestionItems[i]
                }
              }
            }
          }
        }
      }
      return {}
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    }
  },
  watch: {
    selectAll: function (select) {
      if (select) {
        if (this.isActiveFilter) {
          this.selectedQuestionIds = this.clientQuestionsFilteredResults.map(
            (q) => q._id.$oid
          )
        } else {
          this.selectedQuestionIds = this.clientQuestionItems.map(
            (q) => q._id.$oid
          )
        }
        this.$emit("update-selected-question-ids", this.selectedQuestionIds)
      } else {
        this.selectedQuestionIds = []
        this.$emit("update-selected-question-ids", [])
      }
    },
    selectedIds: function (ids) {
      this.selectedQuestionIds = ids
    },
    selectedId: function (id) {
      this.selectedQuestionId = id
    }
  }
}
</script>
<style scoped>
.smallFont {
  font-size: 0.8em;
}
.smallMargin {
  margin: 0.2em;
}
.smallPadding {
  padding: 0.2em;
}
.extendedRow {
  width: 60vw;
}
</style>
