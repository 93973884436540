<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText>Top ResearchAI use-cases:</VisibleText>
    </h1>
    <div class="library__heading-line"></div>
    <h2 class="library__h2">
      <VisibleText>Supporting many sectors and verticals</VisibleText>
    </h2>
    <p>
      <VisibleText>
        ResearchAI is a platform for leading industry models. Rather than trying
        to reinvent the wheel, we incorporate best practices in data analysis
        and reporting across a growing portfolio of sectors. If you can collect
        data, ResearchAI can help you find the right insights.
      </VisibleText>
    </p>
    <h2 class="library__h2">Top use cases</h2>
    <ol class="library__list">
      <li>
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/article__toolbox.png"
          alt=""
        />
        <h3>
          <VisibleText>
            Easy-to-deploy 'data science beginners' tool-box for SMEs
          </VisibleText>
        </h3>
        <ul>
          <li>
            <h4>
              <VisibleText>
                Instant survey analysis for service evaluation
              </VisibleText>
            </h4>
            <p>
              <VisibleText>
                This department manages logistic services for a central Canadian
                province and related crown corporations. They wanted to get a
                sense of how a specific service center was performing. A survey
                was produced with some assistance from the ResearchAI team, and
                then deployed using
                <a href="https://forms.office.com/">Microsoft Forms</a>
                which was already available to them through their central IT
                tools. After collecting feedback from users of this center, this
                department was able to find
                <a
                  href="https://research-ai.io/library/guide/getting-started-with-researchai"
                >
                  immediate actions</a
                >: performance was high compared to peers, satisfaction could be
                maintained through a focus on accessibility, and a planned
                capital investment was deemed not impactful for customers thus
                saving thousands instantly.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>Real-time tracking of red-flags</VisibleText>
            </h4>
            <p>
              One of the USAs busiest airports collects diverse data about
              passenger experiences including what airline, gate number,
              restaurant visited, goods purchased, and stated measures of their
              overall experience. However, this data could only be made sense of
              in quarterly reports which are slow and expensive. Hungry for
              faster insights about delighted or angry customer segments, this
              rich data was converted into actionable reports in minutes. Rather
              than aggregating data, they used ResearchAI's analysis to find
              which specific segments of customers experienced which specific
              pain-points, in real-time. This can be checked at any time, rather
              than waiting weeks.
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>Evaluating COVID-19 response</VisibleText>
            </h4>
            <p>
              <VisibleText>
                In the wake of the pandemic, this municipality of 1.5 million
                people had to roll out new emergency services, channels and
                programs for their citizens. Using ResearchAI they were able to
                identify pain points and track actions for improvement in a
                real-time manner. This took advantage of diverse data from
                live-chats, to emails, to surveys.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>Benchmarking to peers</VisibleText>
            </h4>
            <p>
              This Service Center department manages tens of thousands of
              customer interactions per month. As part of their digital
              transformation agenda, they have been comparing their department's
              performance to governments and industry in annual
              <a href="https://citizenfirst.ca/measure-and-benchmark">
                benchmarking reports</a
              >. For the first time ever, they were able to
              <a
                href="https://research-ai.io/library/guide/what-is-benchmarking-in-researchai"
              >
                self-service their benchmarking directly</a
              >, and have begun customizing their reporting in a way that lets
              them compare and manage not only their department as a whole, but
              right down to individual service types and service locations.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/article__transform.png"
          alt=""
        />
        <h3>
          <VisibleText>
            Supporting more advanced strategic digital transformation
          </VisibleText>
        </h3>
        <ul>
          <li>
            <h4>
              <VisibleText>In-sourcing research capabilities</VisibleText>
            </h4>
            <p>
              <VisibleText>
                This government has a budget for the collection of data and its
                distillation into insights that drive improvement. Typically
                much of this budget goes to third-party market research vendors.
                Conducting this research internally was traditionally expensive
                and required technical experts. However, using ResearchAI this
                government has stated they are able to signficantly reduce their
                dependency on third-party vendors and can instead scale the
                management of this research internally. This 'in-sourcing' is
                made possible by the fact ResearchAI brings industry-leading
                models into an accessible interface that can bring flexible and
                real-time insights to managers without statistical or
                data-science experienced required.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>
                White-labeling tools to add value to your end-users
              </VisibleText>
            </h4>
            <p>
              <VisibleText>
                This
                <a href="https://citizenfirst.ca/">
                  Not-for-profit organization</a
                >
                has produced an industry-leading benchmarking service for public
                sector organizations. Governments and related industries can
                compare very granular aspects of their service delivery to peers
                through a detailed report. However, this report traditionally
                took up to 6 weeks to develop. Powered by ResearchAI, this
                Institute was able to produce the same reports in minutes, and
                has created a new value stream by letting their end-users
                self-service their report in their own
                <a href="https://analytics.citizenfirst.ca/">
                  white-labelled environment</a
                >.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>
                Interactive workshops for advancing digital literacy
              </VisibleText>
            </h4>
            <p>
              <VisibleText>
                The
                <a href="https://www.lse.ac.uk/">London School of Economics </a>
                is a leading research university that has built a reputation
                training the world's industry leaders in finance, consulting and
                law. Seeing the importance of digital skills, an e-course was
                developed that allows professionals and social scientists to
                learn about transformative technologies like
                <a
                  href="https://en.wikipedia.org/wiki/Natural_language_processing"
                >
                  Natural Language Processing</a
                >
                in a hands-on way that let's them experience their power without
                statistical or data-science training required. Using ResearchAI
                as an interactive learning tool, the future generation of
                leaders are unlocking insights hidden in text. Increasingly,
                this is being extended into actionable workshops for business
                managers.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>
                Hypothesis testing and evidence-based improvement
              </VisibleText>
            </h4>
            <p>
              <VisibleText>
                This Center of Excellence in a regional municipality has
                developed a sophisticated understanding of their most common
                drivers of citizen satisfaction or dissatisfiaction based off of
                years worth of data collection. They had developed their own
                theories about problems and challenges unique to their context
                and wanted a robust tool for testing the success of their
                interventions. By processing data longitudinally, they were able
                to follow the rise and fall of key drivers in a much more
                immediate and self-service way using ResearchAI. Similarly, they
                begun testing theories about how well drivers could be found in
                text data instead of just traditional survey data.
              </VisibleText>
            </p>
          </li>
        </ul>
      </li>
      <li>
        <img
          class="library__headingIcon__image library__headingIcon"
          src="/assets/article__voice.png"
          alt=""
        />
        <h3>
          <VisibleText>
            Automatically find insights in voice and text - the new frontier
          </VisibleText>
        </h3>
        <ul>
          <li>
            <h4>
              <VisibleText>Massive time savings in text analysis</VisibleText>
            </h4>
            <p>
              <VisibleText>
                This market research company generates $1,000,000's a year in
                business conducting qualitative research studies. After
                conducting surveys and interviews, it used to take weeks to data
                cleaning and
                <a
                  href="https://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
                  >coding for themes</a
                >. They were able to cut down analysis for a project involving
                500 rich comments from 3 weeks to a single afternoon.
              </VisibleText>
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>Manage complaints</VisibleText>
            </h4>
            <p>
              This Center of Excellence oversees the complaints management
              process of an organization that delivers thousands of unique
              services to their customers. Using ResearchAI, they were able to
              build their own custom models of common complaints and create
              notification reports about whether or not interventions aiming to
              reduce their occurances have been successful. This also unlocked
              the ability to track complaints across numerous channels, from
              live-chats, to a complaints email, to surveys.
            </p>
          </li>
          <li>
            <h4>
              <VisibleText>
                Digitizing interviews and transcript analysis
              </VisibleText>
            </h4>
            <p>
              <VisibleText>
                This highly reputable international research institute based in
                Ottawa, Canada collects much of their data through detailed
                interviews with participants, often lasting over an hour and
                involving a range of complicated dimensions. Lacking the
                structure of surveys, analyzing this data is usually challenging
                and expensive to scale. In a successful experiment, interviews
                conducted over tools like
                <a
                  href="https://www.microsoft.com/en-ca/microsoft-teams/group-chat-software"
                  >Microsoft Teams</a
                >
                and <a href="https://zoom.us/">Zoom</a>
                were recorded and transcriped at almost no cost with readily
                available tools. Then, these transcripts were imported into
                ResearchAI automatically, allowing for this research firm to
                begin streamlining their interview data collection and analysis.
              </VisibleText>
            </p>
          </li>
        </ul>
      </li>
    </ol>
    <div
      class="figure__horizontal"
      style="padding-top: 2em; padding-bottom: 2em"
    >
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Customer_experience">
          <VisibleText>Customer experience</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Employee_engagement">
          <VisibleText>Employee engagement and Digital HRM</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <div>
          <VisibleText>Academic research</VisibleText>
        </div>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Program_evaluation">
          <VisibleText>Program evaluation</VisibleText>
        </a>
      </div>
      <div class="figure__horizontal__outside-square">
        <div>
          <VisibleText>Compliance</VisibleText>
        </div>
      </div>
      <div class="figure__horizontal__outside-square">
        <a href="https://en.wikipedia.org/wiki/Brand_strength_analysis">
          <VisibleText>Brand/Product testing</VisibleText>
        </a>
      </div>
    </div>
    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new.
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
              We will be in touch to set you up for success.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
export default {
  name: "top_use_cases",
  setup() {
    const siteData = reactive({
      title: "Top use cases using ResearchAI",
      description:
        "What are some of the best examples of how ResearchAI brought value to an organization?"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  }
}
</script>
