<template>
  <div class="library__article">
    <p>
      <VisibleText>Go to </VisibleText>
      <router-link to="/library">
        <VisibleText>Library</VisibleText>
      </router-link>
    </p>
    <h1 class="library__h1">
      <VisibleText
        >Generative AI, sentence matching, and thematic analysis:</VisibleText
      >
    </h1>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>2024-04-29</VisibleText>
    </p>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%"
      src="/assets/landing/BERT.png"
      alt=""
    />
    <p>
      <VisibleText>
        1.9 is now out. We re-wired our 3 year old platform with the latest
        engines (Python 10 and now Vue3). This improves speed and security. The
        main reason we did this though is so we can enable the latest Generative
        AI tools but in a privacy-first and ethics-first way. Too many AI tools
        gobble up your data and/or force all interactions into a chatbot. Using
        our own self-hosted
        <a href="https://en.wikipedia.org/wiki/BERT_(language_model)"
          >BERT-based</a
        >
        algorithms built into our human-first architecture, we give you the
        power of GenAI in our secure, encrypted environment to analyze your
        data, but in a way that keeps you, the researcher, in full control, with
        no prying third-party eyes.
      </VisibleText>
    </p>
    <h2 class="library__h2">
      <VisibleText>Generative AI powered sentence matching</VisibleText>
    </h2>
    <p>
      <VisibleText>
        Thematic analysis involves a human researcher coding or tagging themes
        that exist within text data. Traditionally, this is done manually and
        that makes qualitative research slow and tedious. Advances in Generative
        AI (GenAI) are giving us new tools to support researchers as they seek
        out evidence of themes from text.
      </VisibleText>
    </p>
    <p>
      <VisibleText>
        Imagine you are tasked with finding pain points in client journeys from
        1000s of client interactions. It is often the case that in your data,
        consistently, month over month, wait times may be a source of concern.
        With ResearchAI and our newest release you can now tag a sentence such
        as 'wait times are totally unacceptable' to a new theme called 'wait
        time complaints', like the below.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of a sentence being tagged to a theme:
      </VisibleText>
    </div>

    <img
      class="library__image"
      style="max-height: 12rem; max-width: 85%; border: 2px dashed #f69c55"
      src="/assets/landing/poor_wait_times.png"
      alt=""
    />

    <p>
      <VisibleText>
        Then if you go to the 'notes' section of that theme, you can then find
        your tag and select 'find similar'. This uses a BERT-based tool that
        converts sentences into complex mathematical relationships that allow us
        to find similar patterns in other sentences. In the below example, we
        found 42 similar sentences in seconds.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of a similar sentences to a specific tagged note:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 30rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/similar_to_poor_wait_times.png"
      alt=""
    />
    <p>
      This techique is similar to the transformer-based architecture behind
      ChatGPT. So why didn't we just use ChatGPT?
    </p>
    <ul class="library__list">
      <li>
        <VisibleText>
          ChatGPT does not give you privacy. We installed BERT-based tools into
          our own encrypted environment so no one can see your work (no
          third-party cloud services). BERT was originally created by
          researchers at Google but is now an open-source set of tools
          maintained by the open-source community.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          ChatGPT forces queries to be in a 'chat' format, like a conversation.
          We instead take individual sentences and directly feed them into
          sentence matching algorithms. This reduces imprecision that comes from
          'prompt engineering'.
        </VisibleText>
      </li>
      <li>
        <VisibleText>
          ChatGPT gives you results that you have to trust. We use custom
          implementations of BERT so that we can give suggestions to
          researchers, but keep researchers at the center of interpreting and
          approving tags.
        </VisibleText>
      </li>
    </ul>
    <h2 class="library__h2">
      <VisibleText
        >Other new text analysis tool updates and features</VisibleText
      >
    </h2>
    <div class="library__heading-line"></div>
    <p>
      <VisibleText>
        You may have noticed many new visual changes to our text analytics
        engine.</VisibleText
      >
    </p>
    <h3>
      <VisibleText>Sortable themes</VisibleText>
    </h3>
    <p>
      <VisibleText>
        You can now sort themes by name, time modified, or very valuably:
        overall coverage. In the early days use cases were smaller-scale, but
        now some organizations or researchers are analyzing million-word
        projects with up to 100 themes. This sortability has become a regularly
        requested feature.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new 'theme sort' button:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 25rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/theme_sort_option.jpg"
      alt=""
    />
    <h3>
      <VisibleText> Apply a filter on the fly </VisibleText>
    </h3>
    <p>
      <VisibleText>
        You can now add a filter to any text analysis which will show you your
        same themes and coverage scores, but updated to the selected filter. For
        example, say you analyzed data across an entire country. Later, you may
        want to see if themes change across a specific region, like let's say
        'Ontario' in a Canadian dataset.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new 'apply a filter' button and visualizing
        when a filter is applied:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 12rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/text_filters.png"
      alt=""
    />
    <h3>
      <VisibleText> Add a new theme in a click </VisibleText>
    </h3>
    <p>
      <VisibleText>
        You can now add themes in a more compact and interactive window that
        saves clicks.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new 'add theme' interactive window:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 30rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/add_new_themes.png"
      alt=""
    />
    <h3>
      <VisibleText> New theme context window </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Clicking on a theme tagged to a row of text data now reveals extra
        information, like, the source of the match. You can also now remove or
        drop matches in the same window.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new 'theme matching reasons' context
        window:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 15rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/theme_context.png"
      alt=""
    />
    <h3>
      <VisibleText> New theme options </VisibleText>
    </h3>
    <p>
      There are a range of new options when you select a theme. See below for
      more information.
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new options available when selecting one or
        more themes:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 20rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/new_theme_options.png"
      alt=""
    />
    <h3>
      <VisibleText> Filter by more than one theme at a time </VisibleText>
    </h3>
    <p>
      <VisibleText>
        This has been a widely requested feature. Previously, you could only
        select one theme at a time. You can now select as many as you'd like,
        and it will filter the responses down to those matching all of the
        themes selected. This helps researchers pinpoint subthemes, like, how
        many are talking about staff and are complaining at the same time?
      </VisibleText>
    </p>
    <h3>
      <VisibleText> Duplicate themes </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Copy an existing theme including all of its keywords and notes.
      </VisibleText>
    </p>
    <h3>
      <VisibleText> Merge themes </VisibleText>
    </h3>
    <p>
      <VisibleText>
        Merge two themes and all of their keywords and notes together. You can
        also 'copy and merge' if you want to keep the original themes.
      </VisibleText>
    </p>
    <h3>
      <VisibleText> Save themes to a custom text model </VisibleText>
    </h3>
    <p>
      <VisibleText>
        In the previous version, you could manage custom text models by going to
        the custom text model view. You can now save themes directly from a
        project, and can 'update' existing custom text models to match your
        latest tags.
      </VisibleText>
    </p>
    <div style="margin: 0 auto; text-align: center; font-size: 0.9rem">
      <VisibleText>
        ResearchAI screenshot of the new 'save theme to custom text model'
        interface:
      </VisibleText>
    </div>
    <img
      class="library__image"
      style="max-height: 27rem; max-width: 95%; border: 2px dashed #f69c55"
      src="/assets/landing/saving_custom_text_model.png"
      alt=""
    />
    <p>
      <VisibleText>
        Many new quality of life features are on the way. Thanks to all our
        users and partners who share their thoughts on what works well or what
        more they'd like to see from ResearchAI. Stay tuned for our next update
        which will expand sentence simiarity work into topic modelling and more.
      </VisibleText>
    </p>
    <div class="library__textbox" style="padding: 0.5em">
      <div class="library__textbox__col">
        <img
          class="library__image library__image--nonresponsive"
          style="max-width: 4rem"
          src="/assets/landing/analyze_icon.png"
          alt=""
        />
      </div>
      <div class="library__textbox__col">
        <ul>
          <li>
            <VisibleText>
              If you are an existing user,
              <router-link to="/login"> login to ResearchAI</router-link>
              and try something new. Or reach out to us at:
              <a href="mailto:hello@researchai.io">hello@researchai.io</a>
            </VisibleText>
          </li>
          <li>
            <VisibleText>
              If you are finding us for the first time,
              <router-link to="/register">
                register for a free ResearchAI account.
              </router-link>
              We will be in touch to set you up for success.
            </VisibleText>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"

export default {
  name: "gen_ai_sentence_matching",
  setup() {
    const siteData = reactive({
      title: "GenAI, sentence matching, and thematic analysis",
      description:
        "GenerativeAI can power sentence matching, which when used right, can transform thematic analysis"
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  methods: {
    goto(refName) {
      this.$refs[refName].scrollIntoView()
    }
  }
}
</script>
