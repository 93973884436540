<template>
  <Modal
    class="drivers__variables"
    :show="showDriversVariables"
    @close="$emit('close-drivers-variables')"
  >
    <template #header>
      <VisibleText>
        {{ dependentVariableTitle }} {{ newDriver ? "" : "Variables" }}
      </VisibleText>
    </template>
    <h2>Instructions</h2>
    <ol class="drivers__ordered-list">
      <li>
        <VisibleText>
          Drivers analysis only accepts questions with scale, category or
          numeric data types</VisibleText
        >.
      </li>
      <li>
        You must select a dependent variable. This is what you want to predict.
      </li>
      <li>
        You must select independent variables. These are what you think could be
        predicting your dependent variable.
      </li>
      <li>
        Your dependent variable can include multiple questions, but they cannot
        include categorical variables.
      </li>
      <li>
        Multiple models will be tested based on the questions you have selected.
        The best fits will be returned.
      </li>
    </ol>
    <div
      class="info-msg__inline"
      v-if="
        selectedDependentQuestionIds.length &&
        selectedIndependentQuestionIds.length
      "
    >
      <VisibleText>
        Based on your current selection the following models are available:
      </VisibleText>
      <ul
        v-for="(model, key) in viableModels"
        :key="key"
        style="line-height: 0.25em"
      >
        <li>
          <VisibleText>
            {{ model }}
          </VisibleText>
        </li>
      </ul>
    </div>
    <p class="info-msg__inline" v-if="isProjectCompositeIndex">
      <VisibleText>
        TIP: to modify this composite index go to "Project Settings" menu and
        select "Performance Measures"
      </VisibleText>
    </p>
    <!--------------------------------------
    | dependent variable
    ---------------------------------------->
    <span class="drivers__variables-dependent-var">
      <h2 class="text-smallcaps">
        <VisibleText>Dependent Variable</VisibleText>
      </h2>
      <button
        class="btn-inverted"
        style="margin-left: auto"
        type="button"
        @click="onClickGetSuggestedVars"
        v-if="newDriver"
      >
        <VisibleText> Get suggested variables </VisibleText>
      </button>
    </span>
    <SelectQuestionsList
      :questions="dependentVariableTableQs"
      :selected-ids="selectedDependentQuestionIds"
      :hide-toolbar="true"
      :smallerUI="true"
      :toEmitUpdate="true"
      @update-selected-question-ids="setDependentVariables($event)"
      v-if="dependentVariableTableQs.length > 0"
    />
    <p class="error-msg__inline" v-else>
      <VisibleText>Please select a dependent variable.</VisibleText>
    </p>
    <!--------------------------------------
    | independent variables
    ---------------------------------------->
    <h2 class="text-smallcaps" style="margin-top: 2rem">
      <VisibleText>Independent Variables</VisibleText>
    </h2>
    <SelectQuestionsList
      :questions="independentVariableTableQs"
      :selected-ids="selectedIndependentQuestionIds"
      :invalidTypes="invalidTypes"
      :smallerUI="true"
      :toEmitUpdate="true"
      :explicitlyExclude="selectedDependentQuestionIds"
      @update-selected-question-ids="setIndependentVariable($event)"
    >
      <template v-slot:custom-action="{ question }">
        <div
          v-if="
            !isDVSelected ||
            (isDVSelected &&
              !isDVCategorical &&
              question.question.approved_data_type !== 'CAT')
          "
        >
          <span class="col-2" style="align-self: start">
            <button
              class="btn-nobg--secondary"
              style="font-size: 0.8em"
              type="button"
              @click="setDependentVariable(question.question._id.$oid)"
            >
              <VisibleText> Set as dependent variable </VisibleText>
            </button>
          </span>
        </div>
      </template>
    </SelectQuestionsList>

    <VisibleText v-if="independentVariableTableQs.length == 0">
      No scale, category or numeric questions found.
    </VisibleText>

    <template #footer>
      <div style="display: flex; width: 100%">
        <div style="display: flex; width: 20%; align-items: center">
          <button
            v-if="viableModels.length"
            class="btn-default"
            style="height: 2em"
            @click="onClickSaveVariables"
          >
            <VisibleText>Save Variables</VisibleText>
          </button>
          <button
            v-else
            class="btn-default"
            style="
              height: 2em;
              cursor: not-allowed;
              background: #999;
              opacity: 0.6;
            "
          >
            <VisibleText>Save Variables</VisibleText>
          </button>
          <MessageInline :message="message.text" :message-type="message.type" />
        </div>
        <div style="display: flex; width: 80%">
          <button
            class="btn-cancel"
            style="margin-left: auto"
            @click="filterMode = true"
          >
            <div v-if="filterParams">
              <VisibleText>Edit filters</VisibleText>
            </div>
            <div v-else>
              <VisibleText>Add filter to analysis</VisibleText>
            </div>
          </button>
          <FilterPills
            v-if="isFiltered"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="filterParams"
            @clearFilters="clearFilters"
          >
          </FilterPills>
        </div>
      </div>
      <AddFilter
        :show="filterMode"
        :type="'own'"
        :data="allQuestions"
        @close-modal="filterMode = false"
        @confirmNewFilter="confirmNewFilter"
      />
    </template>
  </Modal>
</template>

<script>
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import Modal from "@/components/UI/Modal.vue"
import SelectQuestionsList from "../../Components/SelectQuestionsList.vue"
import AddFilter from "@/components/Project/ProjectAnalysis/Components/AddFilter.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

export default {
  name: "VariablesModal",
  components: {
    MessageInline,
    Modal,
    SelectQuestionsList,
    AddFilter,
    FilterPills
  },
  props: {
    dependentVariableTitle: {
      default: () => "Drivers",
      type: String
    },
    // pre-selected dependent question ids
    dependentQuestions: {
      default: () => [],
      type: [Array, String]
    },
    // pre-selected independent question ids
    independentQuestions: {
      default: () => [],
      type: Array
    },
    newDriver: {
      defualt: () => false,
      type: Boolean
    },
    showDriversVariables: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      message: {
        text: "",
        type: ""
      },
      selectedDependentQuestionIds: [],
      selectedIndependentQuestionIds: [],
      invalidTypes: [
        "OPEN_ENDED",
        "ID",
        "DATE",
        "OTHER",
        "none",
        "SURVEY_WEIGHT"
      ],
      filterMode: false,
      filterParams: {}
    }
  },
  mounted() {
    this.setData()
  },
  computed: {
    //local copy of client questions with just dependent variable
    dependentVariableTableQs() {
      return this.filteredQuestions.dependent
    },
    // local copy of client questions without dependent variable
    independentVariableTableQs() {
      return this.filteredQuestions.independent
    },
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    filteredQuestions() {
      return this.allQuestions.reduce(
        (allqs, q) => {
          if (this.selectedDependentQuestionIds.includes(q._id.$oid)) {
            allqs.dependent.push(q)
          } else if (!this.isInvalid(q)) {
            allqs.independent.push(q)
          }
          return allqs
        },
        { dependent: [], independent: [] }
      )
    },
    /**
     * is the composite index based on the project settings performance mesure
     * this only applies to when we create new drivers analysis
     */
    isProjectCompositeIndex() {
      return this.newDriver && this.dependentQuestions.length > 0
    },
    isDVSelected() {
      return this.selectedDependentQuestionIds.length > 0
    },
    isDVCategorical() {
      if (this.selectedDependentQuestionIds.length > 0) {
        let thisDV = this.$store.getters[
          "datasetDetails/getClientQuestions"
        ].reduce(
          (allqs, q) => {
            if (this.selectedDependentQuestionIds.includes(q._id.$oid))
              if (q.approved_data_type === "CAT") allqs.dependent.push(q)
            return allqs
          },
          { dependent: [] }
        )
        if (thisDV.dependent.length) {
          return true
        } else {
          return false
        }
      } else return false
    },
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    },
    viableModels() {
      let models = []
      let dvTypes = []
      let ivTypes = []
      if (this.selectedDependentQuestionIds.length) {
        for (let x = 0; x < this.selectedDependentQuestionIds.length; x++) {
          for (let i = 0; i < this.allQuestions.length; i++) {
            if (this.allQuestions[i]._id) {
              if (this.allQuestions[i]._id.$oid) {
                if (
                  this.allQuestions[i]._id.$oid ===
                  this.selectedDependentQuestionIds[x]
                ) {
                  if (this.allQuestions[i].approved_data_type) {
                    // Check if categorical, then check if binary or multiclass
                    if (this.allQuestions[i].approved_data_type === "CAT") {
                      if (this.allQuestions[i].unique_values.length === 2) {
                        dvTypes.push("CAT--binary")
                      } else {
                        dvTypes.push("CAT--multiclass")
                      }
                    } else {
                      dvTypes.push(this.allQuestions[i].approved_data_type)
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (this.selectedIndependentQuestionIds.length) {
        for (let x = 0; x < this.selectedIndependentQuestionIds.length; x++) {
          for (let i = 0; i < this.allQuestions.length; i++) {
            if (this.allQuestions[i]._id) {
              if (this.allQuestions[i]._id.$oid) {
                if (
                  this.allQuestions[i]._id.$oid ===
                  this.selectedIndependentQuestionIds[x]
                ) {
                  if (this.allQuestions[i].approved_data_type) {
                    ivTypes.push(this.allQuestions[i].approved_data_type)
                  }
                }
              }
            }
          }
        }
      }
      if (ivTypes.length && dvTypes.length) {
        if (dvTypes.length === 1) {
          if (dvTypes.includes("CAT--binary")) {
            models.push("Random forest classification")
            models.push("Logistic regression")
          }
          if (dvTypes.includes("CAT--multiclass")) {
            models.push("Random forest classification")
          }
        }
        if (
          !dvTypes.includes("CAT--binary") &&
          !dvTypes.includes("CAT--multiclass")
        ) {
          models.push("Random forest regression")
          models.push("Elastic net")
        }
      }
      return models
    }
  },
  methods: {
    /**
     * Invalid if question is non-scale
     * - do not set invalid if inputs that were previously selected / questions that
     * are in in this.independentQuestions array
     */
    isInvalid(item) {
      return (
        !item.approved_data_type ||
        this.invalidTypes.includes(item.approved_data_type)
      )
    },
    /************
     *
     * on click handlers
     *
     ************/
    async onClickGetSuggestedVars() {
      const suggestedVariables = await this.fetchSuggestedVariables()
      this.setDependentVariable(suggestedVariables.dependent_variable_id)
      this.setIndependentVariable(suggestedVariables.independent_variables_ids)
    },
    onClickSaveVariables() {
      // reset messages
      this.message = {
        text: "",
        type: ""
      }
      if (this.validate()) return
      this.saveVariables()
      this.$emit("close-drivers-variables")
    },
    /************
     *
     * setters
     *
     ************/
    setDependentVariable(questionId) {
      this.selectedDependentQuestionIds.push(questionId)
      this.setIndependentVariable(this.selectedIndependentQuestionIds)
    },
    setDependentVariables(questionIds) {
      if (!questionIds.length) {
        this.selectedDependentQuestionIds = []
      } else {
        if (this.selectedDependentQuestionIds.length) {
          for (let i = 0; i < questionIds.length; i++) {
            let found = false
            for (let x = 0; x < this.selectedDependentQuestionIds.length; x++) {
              if (questionIds[i] === this.selectedDependentQuestionIds[x]) {
                this.selectedDependentQuestionIds.splice(i, 1)
                found = true
              }
            }
            if (!found) {
              this.selectedDependentQuestionIds.push(questionIds[i])
            }
          }
        } else {
          this.selectedDependentQuestionIds = questionIds
        }
        this.setIndependentVariable(this.selectedIndependentQuestionIds)
      }
    },
    setIndependentVariable(questionIds) {
      for (let i = 0; i < this.selectedDependentQuestionIds.length; i++) {
        questionIds = questionIds.filter(
          (e) => e !== this.selectedDependentQuestionIds[i]
        )
      }
      this.selectedIndependentQuestionIds = [...questionIds]
    },
    setData() {
      const setupDependentVars = () => {
        if (Array.isArray(this.dependentQuestions))
          this.selectedDependentQuestionIds = [...this.dependentQuestions]
        else this.selectedDependentQuestionIds.push(this.dependentQuestions)
      }

      // set "new" question as the pre-selected questions
      if (this.dependentQuestions.length > 0) setupDependentVars()
      else this.selectedDependentQuestionIds = []

      if (this.independentQuestions.length > 0)
        this.selectedIndependentQuestionIds = [...this.independentQuestions]
      else this.selectedIndependentQuestionIds = []
    },
    /************
     *
     * saving and fetching
     *
     ************/
    saveVariables() {
      // Below will send a blank model to prevent an error with previously
      // user-selected models
      this.$emit(
        "modify-vars",
        this.selectedDependentQuestionIds,
        this.selectedIndependentQuestionIds,
        ""
      )
      if (this.newDriver) this.setData()
    },
    validate() {
      if (this.selectedDependentQuestionIds.length === 0) {
        this.message.text = "Please select a dependent variable."
        this.message.type = "error"
      } else if (this.selectedIndependentQuestionIds.length === 0) {
        this.message.text = "Please select independent variables."
        this.message.type = "error"
      }
      return this.message.text.length > 0
    },
    async fetchSuggestedVariables() {
      try {
        const datasetId =
          this.$store.getters["datasetDetails/getDataset"]._id.$oid
        const response =
          await this.$services.DRIVERS_SERVICE.suggestedVariables({
            data_set_id: datasetId
          })
        return response
      } catch (e) {
        throw new Error("DatasetVariables:fetchSuggestedVariables" + e.message)
      }
    },
    confirmNewFilter(selected) {
      if (selected) {
        this.filterParams = selected
      }
    },
    clearFilters() {
      this.filterMode = false
      this.filterParams = {}
    }
  }
}
</script>
