<template>
  <div>
    <div v-if="isInvitee">
      <h2 class="account-profile__organization-title title">
        <VisibleText>Invited!</VisibleText>
      </h2>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex">
          <VisibleText> You have been invited to join this team! </VisibleText>
        </div>

        <div style="display: flex; flex-direction: row; margin-bottom: 2rem">
          <button
            class="benchmark-manager__editor-add"
            @click="acceptInvite(team, teamIndex)"
          >
            Join
          </button>
          <button
            class="benchmark-manager__editor-add"
            @click="rejectInvite(team, teamIndex)"
          >
            Reject
          </button>
        </div>
      </div>
    </div>

    <div
      style="display: flex; flex-direction: row"
      v-if="teamLicenses.length || isTeamAdmin"
    >
      <div style="display: flex; flex: row; margin-bottom: 1rem">
        <VisibleText
          style="margin-top: -0.3rem; margin-right: 0.5rem; font-size: 0.8rem"
          >Team licenses:</VisibleText
        >
        <div
          v-for="(team_license, license_index) in teamLicenses"
          :key="'license_index' + license_index"
          style="
            display: flex;
            padding: 0.3rem;
            font-size: 0.9rem;
            border-radius: 0.75rem;
            height: 1.5rem;
            width: 6rem;
            background-color: white;
            margin-left: auto;
            margin-top: -0.7rem;
            margin-right: 1rem;
            border: 1px solid var(--outline-colour);
          "
        >
          <div>
            {{ capitalizeFirstLetter(team_license.name) }}
          </div>

          <ButtonIconOnly
            v-if="isPigeon && $route.path == '/pigeon-settings'"
            @click-handler="removeLicense(team_license._id.$oid, teamIndex)"
            icon="minus"
            :smaller="true"
            class="inline-edit-text__action-btn"
            style="
              color: black;
              margin-left: auto;
              display: flex;
              margin-top: -0.1rem;
            "
          >
            <VisibleText>Remove team license</VisibleText>
          </ButtonIconOnly>
        </div>
        <div style="margin-top: -0.6rem">
          <ButtonIconOnly
            v-if="
              isPigeon &&
              !showThisAddLicense &&
              $route.path == '/pigeon-settings'
            "
            @click-handler="showAddTeamLicense(true)"
            icon="add"
            class="inline-edit-text__action-btn"
            style="color: black; margin-left: auto; display: flex"
            :smaller="true"
          >
            <VisibleText>Add team license</VisibleText>
          </ButtonIconOnly>
          <ButtonIconOnly
            v-if="isPigeon && showThisAddLicense"
            @click-handler="showAddTeamLicense(false)"
            icon="remove"
            class="inline-edit-text__action-btn"
            style="color: black; margin-left: auto; display: flex"
          >
            <VisibleText>Cancel adding license</VisibleText>
          </ButtonIconOnly>
        </div>
      </div>
      <div
        v-if="isTeamAdmin"
        style="
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-top: -0.5rem;
        "
      >
        <SvgIconDecorative icon="star" style="height: 1.25rem" />
        <VisibleText>Admin view</VisibleText>
      </div>
    </div>

    <div v-if="showThisAddLicense && isPigeon" style="margin-bottom: 1rem">
      <h3>
        <VisibleText> Choose a license to add </VisibleText>
      </h3>
      <div style="display: flex; flex-direction: row">
        <select
          aria-label="license"
          class="select"
          id="select-license"
          v-model="addingLicense"
          style="height: 2.2rem; width: 10rem"
        >
          <option
            v-for="license in filteredLicenses"
            :key="license"
            :value="license"
          >
            {{ license }}
          </option>
        </select>
        <button
          v-if="addingLicense"
          @click="addLicense(addingLicense, team, teamIndex)"
          class="inline-edit-text__action-btn"
          style="width: 6rem; color: black; margin-left: 1rem"
        >
          <VisibleText>Add license</VisibleText>
        </button>
      </div>
    </div>

    <div style="display: flex; flex-direction: row">
      <form
        class="account-profile__form"
        style="display: flex"
        novalidate
        v-on:submit.prevent="saveTeamNameEdit(teamIndex)"
        v-if="teamName"
      >
        <label
          for="teamname"
          style="width: 15rem; margin-top: 1rem"
          class="form-label"
        >
          <h3><VisibleText>Team name </VisibleText></h3>
        </label>
        <input
          aria-describedby="full-name-err"
          :id="'teamname' + teamIndex"
          v-model="teamName.new"
          type="text"
          class="account-profile__form-input"
          required
          ref="team-name"
          style="background-color: white"
          :disabled="isTeamAdmin == false"
        />
      </form>
      <div
        style="display: flex; margin: 0.5rem 0 0 1rem"
        v-if="showTeamNameEditSaveButton"
      >
        <ButtonIconOnly
          icon="check"
          class="inline-edit-text__action-btn"
          @click-handler="
            saveThisTeamNameEdit(this.localOid, teamName.new, teamIndex)
          "
        >
          <VisibleText>save change</VisibleText>
        </ButtonIconOnly>
        <ButtonIconOnly
          @click-handler="cancelTeamNameEdit()"
          icon="remove"
          class="inline-edit-text__action-btn"
        >
          <VisibleText>cancel change</VisibleText>
        </ButtonIconOnly>
      </div>
      <div style="margin-left: auto; display: flex" v-if="!isInvitee">
        <ButtonIconOnly
          v-if="adminListWithoutSelf.length > 0 && isInTeam"
          @click-handler="leaveTeam(team, teamIndex)"
          icon="exit"
          class="inline-edit-text__action-btn"
          style="color: black; margin-left: auto; display: flex"
        >
          <VisibleText>Leave team</VisibleText>
        </ButtonIconOnly>
        <ButtonIconOnly
          @click-handler="deleteTeam(team, teamIndex)"
          icon="deleteIcon"
          class="inline-edit-text__action-btn"
          style="color: black; margin-left: auto; display: flex"
          v-if="isTeamAdmin"
        >
          <VisibleText>Delete team</VisibleText>
        </ButtonIconOnly>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; width: 100%">
      <div style="display: flex; flex-direction: column; width: 100%">
        <div
          style="display: flex; flex-direction: row; width: 100%"
          class="account-profile__organization-title title"
          v-if="(isTeaching && isTeamAdmin) || isPigeon"
        >
          <h3>
            <VisibleText>Teacher options</VisibleText>
          </h3>
          <button
            @click="openTeacherOptions = !openTeacherOptions"
            style="background-color: transparent; border: none"
          >
            <SvgIconMeaningful
              :icon="!openTeacherOptions ? 'chevronDown' : 'chevronUp'"
              title="show teaching options"
            />
          </button>
        </div>
        <div
          style="display: flex; flex-direction: column"
          v-if="openTeacherOptions"
        >
          <p v-if="(isTeaching && isTeamAdmin) || isPigeon">
            <VisibleText>
              Teachers can create demo teams, which let participants join
              without using a real email. They gain access to any datasets you
              share to this team.
            </VisibleText>
          </p>
          <div
            v-if="(isTeaching && isTeamAdmin) || isPigeon"
            style="display: flex; flex-direction: row"
          >
            <label
              for="team-is-demo"
              style="
                margin-top: 1rem;
                text-align: right;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.8rem;
              "
              class="form-label"
            >
              <VisibleText>Set as Demo Team</VisibleText>
            </label>
            <input
              type="checkbox"
              class="datasets__table-checkbox"
              name="team-is-demo"
              id="team-is-demo"
              v-model="isDemoTeam"
              @click="setIsDemo(team, teamIndex)"
              style="
                width: 1.3rem;
                height: 1.3rem;
                margin-top: 0.5rem;
                margin-right: 1rem;
              "
            />
          </div>
          <p v-if="(isTeaching && isTeamAdmin) || isPigeon">
            <VisibleText>
              Share the invite code and direct your participants to
              <a target="_blank" href="https://research-ai.io/register/demo"
                >https://research-ai.io/register/demo</a
              >.
            </VisibleText>
          </p>

          <div
            v-if="isDemoTeam"
            style="display: flex; flex-direction: row; width: 75%"
          >
            <label
              for="team-demo-id"
              style="width: 15rem; margin-top: 1rem"
              class="form-label"
            >
              <VisibleText>Invite code</VisibleText>
            </label>
            <input
              aria-describedby="full-name-err"
              type="text"
              class="account-profile__form-input"
              v-model="this.localOid"
              ref="team-demo-id"
              name="team-demo-id"
              style="background-color: white"
              :disabled="true"
            />
          </div>
        </div>

        <div style="display: flex; flex-direction: column; width: 100%">
          <div
            style="display: flex; flex-direction: row; width: 100%"
            class="account-profile__organization-title title"
            v-if="(isResearchLicense && isTeamAdmin) || isPigeon"
          >
            <h3>
              <VisibleText>Research team options</VisibleText>
            </h3>
            <button
              @click="openResearchOptions = !openResearchOptions"
              style="background-color: transparent; border: none"
            >
              <SvgIconMeaningful
                :icon="!openResearchOptions ? 'chevronDown' : 'chevronUp'"
                title="show research team options"
              />
            </button>
          </div>

          <div
            style="display: flex; flex-direction: column"
            v-if="openResearchOptions"
          >
            <p v-if="(isResearchLicense && isTeamAdmin) || isPigeon">
              <VisibleText>
                Researchers can create basic research teams and invite members
                with ease. Team members get access to full analytical and team
                sharing features.
              </VisibleText>
            </p>
            <div
              v-if="(isResearchLicense && isTeamAdmin) || isPigeon"
              style="display: flex"
            >
              <label
                for="team-is-research-license"
                style="
                  margin-top: 1rem;
                  text-align: right;
                  margin-right: 1rem;
                  margin-left: 1rem;
                  font-size: 0.8rem;
                "
                class="form-label"
              >
                <VisibleText>Set as Research Team</VisibleText>
              </label>
              <input
                type="checkbox"
                class="datasets__table-checkbox"
                name="team-is-research-license"
                id="team-is-research-license"
                v-model="isResearchLicenseTeam"
                @click="setIsResearchLicense(team, teamIndex)"
                style="
                  width: 1.3rem;
                  height: 1.3rem;
                  margin-top: 0.5rem;
                  margin-right: 1rem;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isInvitee">
      <h3 class="account-profile__organization-title title">
        <VisibleText>Admins</VisibleText>
      </h3>
      <div
        v-for="(admin, index) in team.admins"
        :key="'admin-card' + index"
        class="form-label"
        style="
          border: 1px solid var(--outline-colour);
          padding: 1rem;
          width: 90%;
          border-radius: 0.5em;
          background-color: white;
          margin: 0 auto;
        "
      >
        <div style="display: flex; flex-direction: row">
          <img
            src="/assets/users-individual-black.png"
            style="width: 2rem; height: 1.4rem"
          />
          <span> {{ admin.email }}</span>
          <span v-if="admin.email == email">
            <SvgIconMeaningful icon="star" title="this is you" />
          </span>
          <div style="display: flex; margin-left: auto">
            <ButtonIconOnly
              icon="thickArrowDown"
              class="inline-edit-text__action-btn"
              @click-handler="makeTeamMember(admin, team, teamIndex)"
              v-if="
                isTeamAdmin &&
                adminListWithoutSelf.length > 0 &&
                team.admins.length > 1
              "
            >
              <VisibleText>demote to team member</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              v-if="
                isTeamAdmin &&
                admin.email != email &&
                adminListWithoutSelf.length > 0 &&
                team.admins.length > 1
              "
              @click-handler="dropFromTeam(admin, team, teamIndex)"
              icon="deleteIcon"
              class="inline-edit-text__action-btn"
              style="color: black"
            >
              <VisibleText>remove from team</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
      </div>
      <h3 class="account-profile__organization-title title">
        <VisibleText>Team members</VisibleText>
      </h3>
      <div
        v-for="(team_member, index) in team['team_members']"
        :key="'team-member-card' + index"
        class="form-label"
        style="
          border: 1px solid var(--outline-colour);
          padding: 1rem;
          width: 90%;
          border-radius: 0.5em;
          background-color: white;
          margin: 0 auto;
        "
      >
        <div style="display: flex">
          <img
            src="/assets/users-individual-black.png"
            style="width: 2rem; height: 1.3rem"
          />
          <span> {{ team_member.email }}</span>
          <span v-if="team_member.email == email">
            <SvgIconMeaningful icon="star" title="this is you" />
          </span>

          <div style="display: flex; margin-left: auto">
            <ButtonIconOnly
              v-if="isTeamAdmin"
              icon="star"
              class="inline-edit-text__action-btn"
              @click-handler="makeAdmin(team_member, team, teamIndex)"
            >
              <VisibleText>promote to admin</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              v-if="isTeamAdmin"
              @click-handler="dropFromTeam(team_member, team, teamIndex)"
              icon="deleteIcon"
              class="inline-edit-text__action-btn"
              style="color: black"
            >
              <VisibleText>remove from team</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
      </div>
      <p v-if="isICCSTeam && !isICCSAdmin">
        <VisibleText> Note: Other members are anonymized. </VisibleText>
      </p>
      <p v-if="isICCSTeam && isICCSAdmin">
        <VisibleText>
          Note: Members are anonymized for non-admins.
        </VisibleText>
      </p>

      <div v-if="isTeamAdmin" style="margin-top: 1rem">
        <h3
          class="account-profile__organization-title title"
          style="display: flex"
        >
          <VisibleText>Invitees</VisibleText>
        </h3>
        <div style="display: flex; flex-direction: row; width: 100%">
          <p>
            <VisibleText>
              Admins can invite new users to this team.
            </VisibleText>
          </p>

          <div
            style="display: flex; margin-left: 1rem; margin-top: 0.5rem"
            v-if="isTeamAdmin && openInvitation"
          >
            <ButtonIconOnly
              @click-handler="hideThisInvitation()"
              icon="remove"
              class="inline-edit-text__action-btn"
              style="color: black; margin-left: auto; display: flex"
            >
              <VisibleText>Cancel invitation</VisibleText>
            </ButtonIconOnly>
          </div>

          <div
            v-if="!openInvitation && isTeamAdmin"
            style="display: flex; margin-left: 1rem; margin-top: 0.5rem"
          >
            <ButtonIconOnly
              @click-handler="showThisInvitation()"
              icon="add"
              class="inline-edit-text__action-btn"
              style="color: black; display: flex"
            >
              <VisibleText>Invite to team</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
        <h3
          v-if="openInvitation"
          class="account-profile__organization-title title"
        >
          <VisibleText>Inviting to this team</VisibleText>
        </h3>
        <div v-if="openInvitation" style="display: flex; flex-direction: row">
          <form
            class="account-profile__form"
            style="display: flex"
            novalidate
            v-on:submit.prevent="addToTeamInvitees(teamIndex)"
          >
            <label
              for="teamname"
              style="width: 10rem; margin-top: 1rem"
              class="form-label"
            >
              <VisibleText>Enter email </VisibleText>:
            </label>
            <input
              aria-describedby="full-name-err"
              :id="'team_invitation' + teamIndex"
              type="text"
              v-model="newInvitee.input"
              class="account-profile__form-input"
              ref="team-invitations"
              style="background-color: white"
            />
          </form>
          <div
            style="display: flex; margin: 0.5rem 0 0 1rem"
            v-if="showTeamInviteesAdd"
          >
            <ButtonIconOnly
              icon="add"
              class="inline-edit-text__action-btn"
              @click-handler="addToTeamInvitees()"
            >
              <VisibleText>add email to invitees</VisibleText>
            </ButtonIconOnly>
          </div>
        </div>
        <div>
          <div v-if="newInvitee.new.length">
            <VisibleText>Invitees</VisibleText>:
            <button
              class="inline-edit-text__action-btn"
              style="width: 6rem; color: black"
              @click="sendThisInvitation(team, teamIndex)"
              :disabled="showInviteeLoading"
            >
              <VisibleText>Send invites</VisibleText>
            </button>
            <span
              v-if="showInviteeLoading"
              class="also-found__loading-spinner spinner"
            ></span>
          </div>
          <div
            v-for="(invitee, invitee_index) in newInvitee.new"
            :key="'team-invitee' + invitee_index"
            style="
              display: flex;
              flex-direction: row;
              max-width: 48ch;
              margin-left: 1rem;
            "
          >
            <input
              type="text"
              :value="invitee"
              class="account-profile__form-input"
              ref="team-invitations"
              style="background-color: white; width: 60%"
              disabled
            />
            <div style="display: flex; margin: 0.5rem 0 0 1rem">
              <ButtonIconOnly
                icon="remove"
                class="inline-edit-text__action-btn"
                @click-handler="CancelAddToTeamInvitees(invitee)"
              >
                <VisibleText>remove email</VisibleText>
              </ButtonIconOnly>
            </div>
          </div>
        </div>

        <div v-if="!openInvitation">
          <div
            v-for="(invitee, index) in team['invitees']"
            :key="'invitee-card' + index"
            class="form-label"
            style="
              border: 1px solid var(--outline-colour);
              padding: 1rem;
              width: 90%;
              border-radius: 0.5em;
              background-color: white;
              margin: 0 auto;
            "
          >
            <div style="display: flex">
              <img
                src="/assets/users-individual-black.png"
                style="width: 2rem; height: 1.3rem"
              />
              <span> {{ invitee.email }}</span>

              <div style="display: flex; margin-left: auto">
                <ButtonIconOnly
                  v-if="isTeamAdmin"
                  @click-handler="revokeInvite(invitee.email, team, teamIndex)"
                  icon="remove"
                  class="inline-edit-text__action-btn"
                  style="color: black"
                >
                  <VisibleText>revoke invitation</VisibleText>
                </ButtonIconOnly>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import UserCardMixin from "@/components/UI/UserCards/userCardMixin"
import UserMixin from "@/utils/mixins/userMixin"
import ConfirmMixin from "/src/utils/mixins/confirmMixin.js"
import TeamsService from "@/services/teamsService.js"
import AdminService from "@/services/adminService.js"
import ProfilesService from "@/services/profilesService.js"

// TeamCardLarge.vue
// ================
//
// This is an expanded team that provides many overview and admin commands.

export default {
  name: "TeamsCardLarge",
  mixins: [UserCardMixin, UserMixin, ConfirmMixin],
  components: {
    SvgIconDecorative,
    SvgIconMeaningful,
    ButtonIconOnly
  },
  props: {
    team: {
      default: () => {},
      type: Object
    },
    teamIndex: {
      default: () => 0,
      type: Number
    },
    email: {
      default: () => "",
      type: String
    }
  },
  data() {
    return {
      isICCSTeam: false,
      isDemoTeam: false,
      isResearchLicenseTeam: false,
      openInvitation: false, // opens the window allowing you to add new invitations to this team
      showInviteeLoading: false, // Shows the loading icon when inviting new users
      openTeacherOptions: false,
      openResearchOptions: false,
      teamName: {}, // resetTeamNames adds old and new fields to track changes to team names
      showThisAddLicense: false, // opens the window allowing you to add new licenses to this team
      addingLicense: "", // a string that names the license type an admin is adding to a team
      newInvitee: {
        // an object is created when an admin is typing an invitee to join the team
        original: [],
        new: [],
        input: ""
      },
      localOid: "",
      teamLicenses: [],
      ADMIN_SERVICE: new AdminService(this.$pigeonline),
      TEAMS_SERVICE: new TeamsService(this.$pigeonline),
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline)
    }
  },
  created() {
    this.resetTeamName()
    this.resetTeamLicenses(this.team)
    let localOid = this.team._id
    if (this.team._id.$oid) {
      localOid = this.team._id.$oid
    }
    this.localOid = localOid
  },

  computed: {
    // Shows the "save" button when adding a new email
    showTeamInviteesAdd() {
      let status = false
      if (this.isValidEmail(this.newInvitee.input) == true) {
        status = true
      }
      return status
    },

    // shows the "save" button when new text is entered into the name field
    showTeamNameEditSaveButton: function () {
      let show = false
      if (
        this.teamName.original !== this.teamName.new &&
        this.teamName.new.length
      ) {
        show = true
      }
      return show
    },

    // removes licenses already in a team, to prevent duplicates
    // Prevent teams from having the 'research' or 'teacher' license added, as that will cause confusion. These are best given to individuals.
    filteredLicenses() {
      let filteredLicenses = this.licenseTypes
      if (this.thisTeamLicenseNames) {
        filteredLicenses = this.licenseTypes.filter(
          (license) =>
            !this.thisTeamLicenseNames.includes(license) &&
            license != "research" &&
            license != "teaching" &&
            license != "iccs-admin"
        )
      }
      return filteredLicenses
    },
    thisTeamLicenseNames() {
      let teamLicenses = []
      if (this.teamLicenses) {
        for (let i = 0; i < this.teamLicenses.length; i++) {
          teamLicenses.push(this.teamLicenses[i].name)
        }
      }
      return teamLicenses
    },
    adminListWithoutSelf() {
      let filteredAdmins = this.team.admins.filter(
        (admins) => admins.email != this.email
      )
      return filteredAdmins
    },
    isInTeam() {
      let isInTeam = false
      let filtered_team_members = this.team.team_members.filter(
        (team_member) => team_member.email == this.email
      )
      let filtered_admins = this.team.admins.filter(
        (admin) => admin.email == this.email
      )
      if (filtered_team_members.length || filtered_admins.length) {
        isInTeam = true
      }
      return isInTeam
    }
  },
  methods: {
    // ================================
    // === ADMIN or Pigeon COMMANDS ===
    // ================================

    // === Admins or Team members ===
    async makeAdmin(team_member, team, index) {
      await this.TEAMS_SERVICE.makeAdmin({
        profile_id: team_member["profile_id"],
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", index)
    },
    // used for demoting an admin to a team member
    async makeTeamMember(team_member, team, index) {
      await this.TEAMS_SERVICE.makeTeamMember({
        profile_id: team_member["profile_id"],
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", index)
    },

    // === Team licenses ===
    async addLicense(license, team, index) {
      await this.ADMIN_SERVICE.addLicense({
        name: license,
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", index)
      this.resetTeamLicenses(this.team)
      this.showAddTeamLicense(false)
    },

    // Explore: move to an "are you sure"?
    async removeLicense(license_id, index) {
      await this.ADMIN_SERVICE.deleteLicense({
        license_id: license_id
      })
      this.$emit("resetTheseTeams", index)
      this.resetTeamLicenses(this.team)
    },

    async resetTeamLicenses(team) {
      this.teamLicenses = []
      let licenses = await this.PROFILES_SERVICE.getTeamOrUserLicenses({
        team_id: team._id.$oid
      })
      if (licenses) {
        this.teamLicenses = licenses
        let demo_licenses = licenses.filter(
          (licenses) => licenses.name == "demo"
        )
        if (demo_licenses.length) {
          this.isDemoTeam = true
        }
        let research_licenses = licenses.filter(
          (licenses) => licenses.name == "basic"
        )
        if (research_licenses.length) {
          this.isResearchLicenseTeam = true
        }
        let iccs_licenses = licenses.filter(
          (licenses) => licenses.name == "iccs"
        )
        if (iccs_licenses.length) {
          this.isICCSTeam = true
        }
      }
    },

    // === Team name ===
    cancelTeamNameEdit() {
      this.newTeamName = {
        original: this.team.team_name,
        new: this.team.team_name,
        input: "",
        team_id: this.localOid
      }
    },

    async saveThisTeamNameEdit(id, string, index) {
      let saveParam = {
        team_name: string,
        team_id: id
      }
      await this.TEAMS_SERVICE.renameTeam(saveParam)
      this.$emit("resetTheseTeams", index)
      this.resetTeamName(string)
    },

    async resetTeamName(name) {
      let this_name = this.team.team_name
      if (name) {
        this_name = name
      }
      this.teamName = {}
      let newTeamName = {
        original: this_name,
        new: this_name,
        input: "",
        team_id: this.localOid
      }
      this.teamName = newTeamName
    },

    // === Invitations ===
    async sendThisInvitation(team, team_index) {
      this.setShowInviteeLoading(true)
      await this.TEAMS_SERVICE.sendInvites({
        invitees: this.newInvitee.new,
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", team_index)
      this.newInvitee = {
        original: [],
        new: [],
        input: ""
      }
      this.setShowInviteeLoading(false)
      this.hideThisInvitation()
    },
    addToTeamInvitees() {
      if (this.newInvitee) {
        this.newInvitee.new.push(this.newInvitee.input)
        this.newInvitee.input = ""
      }
    },
    CancelAddToTeamInvitees(email) {
      if (this.newInvitee) {
        const filteredNewInivtees = this.newInvitee.new.filter(
          (filter) => filter !== email
        )
        this.newInvitee.new = filteredNewInivtees
        this.newInvitee.input = ""
      }
    },
    revokeInvite(invitee, team, index) {
      this.confirmRevokeInviteResponse(invitee, team, index)
    },
    confirmRevokeInviteResponse: function (invitee, team, index) {
      //if (this.propReadOnly) return
      this.setConfirmText({
        btn: "Yes",
        title: "Confirm revoke invite?",
        message: "Are you sure you want to revoke this invite?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("AccountProfile")
      this.setConfirmStatus(false)
      this.setConfirmTarget({ invitee, team, index })
      this.setConfirmIsVisible(true)
    },

    // === Delete team ===
    deleteTeam(team, index) {
      this.confirmDeleteTeamResponse(team, index)
    },
    confirmDeleteTeamResponse: function (team, index) {
      // if (this.propReadOnly) return
      this.setConfirmText({
        btn: "Yes",
        title: "Deleting team!",
        message:
          "Are you sure you want to leave this team? This cannot be reversed!"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("AccountProfile")
      this.setConfirmStatus(false)
      this.setConfirmTarget({ team, index })
      this.setConfirmIsVisible(true)
    },

    // === Kick from team ===
    dropFromTeam(team_member, team, index) {
      this.confirmDropFromTeamResponse(team_member, team, index)
    },
    confirmDropFromTeamResponse: function (team_member, team, index) {
      if (this.propReadOnly) return
      this.setConfirmText({
        btn: "Yes",
        title: "Confirm drop from team?",
        message: "Are you sure you want to drop this user from this team?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("AccountProfile")
      this.setConfirmStatus(false)
      this.setConfirmTarget({ team_member, team, index })
      this.setConfirmIsVisible(true)
    },

    // ================================
    // ===  Teacher user COMMANDS  ===
    // ================================

    async setIsDemo(team, index) {
      await this.TEAMS_SERVICE.setIsDemoTeam({
        team_id: team["_id"]["$oid"],
        is_demo: !this.isDemoTeam
      })
      this.$emit("resetTheseTeams", index)
      this.resetTeamLicenses(this.team) // TODO not responsive, almost no license actions are.
    },

    // ================================
    // ===  Research license COMMANDS  ===
    // ================================

    async setIsResearchLicense(team, index) {
      await this.TEAMS_SERVICE.setIsResearchLicenseTeam({
        team_id: team["_id"]["$oid"],
        is_research_license: !this.isResearchLicenseTeam
      })
      this.$emit("resetTheseTeams", index)
      this.resetTeamLicenses(this.team) // TODO not responsive, almost no license actions are.
    },

    // ================================
    // ===  Regular user COMMANDS  ===
    // ================================

    async acceptInvite(team, index) {
      await this.TEAMS_SERVICE.acceptInvite({
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", index)
      await this.setUserDetails()
    },
    async rejectInvite(team, index) {
      await this.TEAMS_SERVICE.rejectInvite({
        team_id: team["_id"]["$oid"]
      })
      this.$emit("resetTheseTeams", index)
      await this.setUserDetails()
    },
    leaveTeam(team, index) {
      this.confirmLeaveTeamResponse(team, index)
    },
    confirmLeaveTeamResponse: function (team, index) {
      //if (this.propReadOnly) return
      this.setConfirmText({
        btn: "Yes",
        title: "Leaving team!",
        message: "Are you sure you want to leave this team?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("AccountProfile")
      this.setConfirmStatus(false)
      this.setConfirmTarget({ team, index })
      this.setConfirmIsVisible(true)
    },

    // ================================
    // ===  Show or Hide UI ===
    // ================================

    showAddTeamLicense(status) {
      let show = false
      if (status == true) {
        show = true
      }
      this.showThisAddLicense = show
    },
    hideThisInvitation() {
      this.openInvitation = false
      this.newInvitee = {
        original: [],
        new: [],
        input: ""
      }
    },
    showThisInvitation() {
      this.openInvitation = true
    },
    setShowInviteeLoading(status) {
      let showIsLoading = false
      if (status == true) {
        showIsLoading = true
      }
      this.showInviteeLoading = showIsLoading
    },
    // ================================
    // ===  Utils  ===
    // ================================

    isValidEmail(email) {
      const _email = this.cleanEmail(email)
      if (_email == "") {
        return false
      }
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(_email)
    },

    cleanEmail(email) {
      return String(email).toLowerCase().trim()
    }
  },
  watch: {
    confirmStatus: async function (val) {
      // only delete if status is true
      if (
        val &&
        this.confirmSourceComponent === "AccountProfile" &&
        this.confirmType === "delete"
      ) {
        if (this.confirmText.title == "Confirm revoke invite?") {
          let param = {
            team_id: this.confirmTarget.team["_id"]["$oid"],
            email: this.confirmTarget.invitee
          }
          await this.TEAMS_SERVICE.revokeInvite(param)
          this.$emit("resetTheseTeams", this.confirmTarget.index)
        } else if (this.confirmText.title == "Leaving team!") {
          await this.TEAMS_SERVICE.leaveTeam({
            team_id: this.confirmTarget.team["_id"]["$oid"]
          })
          this.$emit("resetTheseTeams")
          await this.setUserDetails()
        } else if (this.confirmText.title == "Deleting team!") {
          let param = {
            team_id: this.confirmTarget.team["_id"]["$oid"]
          }
          await this.TEAMS_SERVICE.deleteTeam(param)
          this.$emit("deleteThisTeam", this.confirmTarget.index)
          await this.setUserDetails()
        } else {
          await this.TEAMS_SERVICE.removeTeamMember({
            profile_id: this.confirmTarget.team_member["profile_id"],
            team_id: this.confirmTarget.team["_id"]["$oid"]
          })
          this.$emit("resetTheseTeams", this.confirmTarget.index)
        }
      }
    }
  }
}
</script>
