<template>
  <div class="accordion-wrapper">
    <div
      :class="['accordion-header', { expanded: isExpanded }]"
      style="background-color: var(--base-lighter-ui-colour)"
    >
      <component class="accordion-title" :is="titleElementType">
        <div
          class="accordion-title-btn"
          style="display: flex; flex-direction: row; padding: 0"
          :style="{ 'background-color': svgBackground }"
          type="button"
          :aria-expanded="isExpanded ? 'true' : 'false'"
          v-if="!isPublicReadOnly"
        >
          <slot name="heading" :isExpanded="isExpanded"></slot>
          <button
            style="
              border: 1px solid transparent;
              border-radius: 4px;
              margin-left: auto;
              margin-right: 2rem;
              width: 3rem;
              height: 3rem;
            "
            :style="{ 'background-color': svgBackground }"
            @click.stop="toggleContent"
          >
            <SvgIconMeaningful
              style="cursor: pointer; width: 1rem; margin-left: -0.05rem"
              class="complete-icon-svg"
              :icon="isExpanded ? icon.expanded : icon.collapsed"
              title="close"
            />
          </button>
        </div>

        <div v-if="isPublicReadOnly">
          <slot name="heading" :isExpanded="isExpanded"></slot>
        </div>
      </component>
      <slot name="additionalDetails"></slot>
    </div>
    <div
      class="accordion-content"
      v-if="isExpanded"
      :style="background ? 'background-color:' + background : ''"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

export default {
  components: { SvgIconMeaningful },
  name: "Accordion",
  props: {
    expand: {
      default: false,
      type: Boolean
    },
    titleElementType: {
      default: () => "h2",
      type: String
    },
    icon: {
      default: () => {
        return {
          expanded: "arrowUp",
          collapsed: "arrowDown"
        }
      },
      type: Object
    },
    iconLocation: {
      default: () => "left",
      type: String
    },
    isPublicReadOnly: {
      default: () => false,
      type: Boolean
    },
    background: {
      default: () => "",
      type: String
    },
    iconColor: {
      default: () => "",
      type: String
    },
    expandedIconColor: {
      default: () => "",
      type: String
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    svgBackground: function () {
      let color = "transparent"
      if (this.iconColor) {
        color = this.iconColor
        if (this.isExpanded && this.expandedIconColor) {
          color = this.expandedIconColor
        }
      }
      return color
    }
  },
  methods: {
    toggleContent() {
      this.isExpanded = !this.isExpanded
      this.$emit("toggled", this.isExpanded)
    }
  },
  watch: {
    expand: {
      immediate: true,
      handler: function (val) {
        this.isExpanded = val
      }
    }
  }
}
</script>
