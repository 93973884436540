import UserMixin from "@/utils/mixins/userMixin"

// userCardMixin.js
// ================
//
// This mixin handles common functions used by TeamCardLarge or Small, or LicenseCardLarge or Small. These are moslty simple commands, as the more sophisicated functions are found in those respective components

export default {
  data() {
    return {
      licenseTypes: [
        "free",
        "demo",
        "basic",
        "teaching",
        "research",
        "iccs",
        "iccs-admin",
        "pigeon"
      ]
    }
  },
  mixins: [UserMixin],
  async mounted() {},
  methods: {
    capitalizeFirstLetter(string) {
      if (string == "iccs") {
        return string.toUpperCase()
      }
      if (string == "iccs-admin") {
        return "ICCS Admin"
      }
      if (string == "research") {
        return "Research"
      }
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getLicenseType(license) {
      if (license["team_id"]) {
        return "Team"
      } else {
        return "Individual"
      }
    },
    getLicenseColor(license) {
      if (license.name == "free") {
        return "var(--state-inactive-colour)"
      }
      if (license.name == "demo") {
        return "#98a8b6"
      }
      if (license.name == "basic") {
        return "#98a8b6"
      }
      if (license.name == "teaching") {
        return "#98a8b6"
      }
      if (license.name == "research") {
        return "#98a8b6"
      }
      if (license.name == "iccs") {
        return "var(--tequila-orange)"
      }
      if (license.name == "iccs-admin") {
        return "var(--tequila-orange)"
      }
      if (license.name == "pigeon") {
        return "#c4bdd9"
      }

      return "var(--primary-lighter)"
    }
  },
  computed: {
    isInvitee() {
      let isInvitee = false
      const isAdminList = this.team.admins.filter(
        (admin) => admin.email == this.email
      )
      const isTeamMemberList = this.team.team_members.filter(
        (team_member) => team_member.email == this.email
      )
      let isInviteeList = []
      if (this.team["invitees"]) {
        isInviteeList = this.team.invitees.filter(
          (invitee) => invitee.email == this.email
        )
      }

      if (
        isAdminList.length == 0 &&
        isTeamMemberList.length == 0 &&
        isInviteeList.length > 0
      ) {
        isInvitee = true
      }
      return isInvitee
    },
    isTeamAdmin() {
      let isAdmin = false
      const isAdminList = this.team.admins.filter(
        (admin) => admin.profile_id == this.profile._id.$oid
      )
      if (isAdminList.length) {
        isAdmin = true
      }
      if (this.isPigeon) {
        isAdmin = true
      }
      return isAdmin
    }
  }
}
