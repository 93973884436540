/*******************************
 *
 * Generate text themes table node
 * @param {String} textTitle
 * @param {Array} textTableData table data
 * @returns {Object} pigeondoc chart node
 *
 ******************************/
const textThemesTable = (textTitle, textTableData, lang) => {
  const table = {
    type: "textThemeTable",
    content: {
      title: `<span class='text-themes-table-title'>${textTitle}</span>`,
      data: textTableData
    },
    id: "text-themes-table",
    meta: {
      state: "visible",
      lang: lang,
      caption: "Themes found in: " + textTitle
    }
  }
  return table
}

const textThemesTableDescription = (hasSentiment) => {
  let str = "Thematic analysis lets you sort text data by what was said. "
  if (hasSentiment) {
    str =
      str +
      "Sentiment analysis lets you quickly see the range of positive or " +
      "negative emotions of your text data."
  }
  return {
    type: "text",
    content: str,
    id: "text-top-details"
  }
}

const textThemesTableHeader = (tableTitle) => {
  let thisTitle = "Open-text: Thematic analysis"
  if (tableTitle) {
    thisTitle = tableTitle
  }
  return {
    type: "heading",
    content: thisTitle,
    id: "section-subtitle-text",
    meta: {
      headingLevel: "h1",
      pdfConfig: {
        style: "heading-h1"
      }
    }
  }
}

export { textThemesTable, textThemesTableDescription, textThemesTableHeader }
