<template>
  <div class="theme-source" :class="dynamicClass" v-if="theme">
    <VisibleText>Source:</VisibleText>
    <div class="theme-source__content">
      <div v-if="!theme.source">
        <SvgIconDecorative style="margin-top: -0.4rem" icon="add" />
        <VisibleText>Manually created</VisibleText>
      </div>
      <div v-if="theme.source">
        <div v-if="theme.source.source_type">
          <div v-if="theme.source.source_type === 'lda'">
            <SvgIconDecorative
              style="margin-top: -0.4rem"
              icon="statisticsGraph"
            />
            <VisibleText>Keyword topic</VisibleText> -
            <a
              href="https://en.wikipedia.org/wiki/Latent_Dirichlet_allocation"
              target="_blank"
            >
              LDA
              <SvgIconDecorative
                style="margin-left: -0.5rem; margin-top: -0.4rem"
                icon="externalLink"
              />
            </a>
          </div>
          <div v-if="theme.source.source_type === 'lsi'">
            <SvgIconDecorative
              style="margin-top: -0.4rem"
              icon="statisticsGraph"
            />
            <VisibleText>Keyword topic</VisibleText> -
            <a
              href="https://en.wikipedia.org/wiki/Latent_semantic_analysis#Latent_semantic_indexing"
              target="_blank"
              >LSI
              <SvgIconDecorative
                style="margin-left: -0.5rem; margin-top: -0.4rem"
                icon="externalLink"
              />
            </a>
          </div>
          <div v-if="theme.source.source_type === 'bert_sentence'">
            <SvgIconDecorative style="margin-top: -0.4rem" icon="brain" />
            <VisibleText>GenAI topic</VisibleText>
          </div>
        </div>
        <div v-if="theme.source.hasOwnProperty('source')">
          <div
            v-if="theme.source.source.hasOwnProperty('topic_model_group_id')"
          >
            <SvgIconDecorative
              style="margin-top: -0.4rem"
              icon="customWrench"
            />
            <VisibleText>Custom model</VisibleText> -
            <router-link
              :to="
                '/custom-text-model/' + theme.source.source.topic_model_group_id
              "
              target="_blank"
            >
              {{ theme.source.source.topic_model_group_name }}
              <SvgIconDecorative
                style="margin-left: -0.5rem; margin-top: -0.4rem"
                icon="externalLink"
              />
            </router-link>
          </div>
        </div>
        <div v-if="theme.source.manually_linked">
          <SvgIconDecorative style="margin-top: -0.4rem" icon="customWrench" />
          <VisibleText>Linked model</VisibleText> -
          <router-link
            :to="'/custom-text-model/' + theme.source.manually_linked"
            target="_blank"
          >
            {{ theme.source.manually_linked_model_name }}
            <SvgIconDecorative
              style="margin-left: -0.5rem; margin-top: -0.4rem"
              icon="externalLink"
            />
          </router-link>
        </div>
        <div
          v-if="
            typeof theme.source.manually_linked === 'string' &&
            theme.source.manually_linked.length === 0
          "
        >
          <SvgIconDecorative style="margin-top: -0.4rem" icon="add" />
          <VisibleText>Manually created</VisibleText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "ThemeSource",
  props: {
    theme: {
      type: Object
    },
    parent: {
      type: String,
      default: () => ""
    }
  },
  components: {
    SvgIconDecorative
  },
  computed: {
    dynamicClass() {
      if (this.parent === "generated") {
        return "theme-source--darken"
      } else {
        return ""
      }
    }
  }
}
</script>
