import { mapActions, mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("confirm", {
      confirmClickedClose: "getClickedClose",
      confirmIsVisible: "getIsVisible",
      confirmSourceComponent: "getSourceComponent",
      confirmStatus: "getStatus",
      confirmTarget: "getTarget",
      confirmType: "getType",
      confirmText: "getText"
    })
  },
  methods: {
    ...mapActions("confirm", [
      "setConfirmClickedClose",
      "setConfirmIsVisible",
      "setConfirmStatus",
      "setConfirmSourceComponent",
      "setConfirmTarget",
      "setConfirmText",
      "setConfirmType",
      "resetConfirm"
    ])
  }
}
