<template>
  <div
    :class="['theme__popup', { show: showDialog }]"
    v-click-outside="forceClose"
  >
    <div style="display: flex; flex-direction: row">
      <div style="display: flex; width: 6rem">
        <button
          type="button"
          class="open-btn"
          @click="onClickOpen"
          style="font-size: 0.8rem; text-align: left; display: flex"
        >
          {{ keywords.length }}
          <VisibleText>&nbsp;keywords</VisibleText>
        </button>
      </div>

      <div
        style="
          display: flex;
          max-width: 30rem;
          overflow-x: auto;
          overflow-y: hidden;
        "
        class="light-scroll-bar"
        v-if="showCoverageDetails == 'show'"
      >
        <div v-for="(keyword, index) in keywords" :key="index">
          <div
            style="font-size: 0.8rem; width: max-content; color: white"
            class="keywords-input-badge pill ui-adaptable"
            :style="{ 'background-color': themeColor }"
          >
            {{ keyword }}
          </div>
        </div>
      </div>
    </div>

    <DialogPopup
      class="theme__popup-dialog"
      header-tag="h4"
      :show="showDialog"
      @close="showDialog = false"
      v-if="keywords.length > 0"
    >
      <template #header>
        Keywords for the theme:
        <div
          class="theme__popup-dialog-name"
          style="
            font-family: Core-Sans;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
            margin-left: 0.5rem;
            text-decoration: none;
          "
        >
          &nbsp;{{ themeName }}
        </div>
      </template>
      <template #body>
        <ul class="theme__popup-list theme__popup-list--inline">
          <li
            v-for="keyword in keywords"
            :key="keyword"
            class="item keyword-pill"
          >
            <button
              class="keyword-pill__btn"
              @click.prevent="$root.$emit('search-keyword', keyword, true)"
            >
              {{ keyword }}
            </button>
          </li>
        </ul>
        <p class="theme__popup-hint">
          <VisibleText>Click a keyword to perform search.</VisibleText>
        </p>
      </template>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "@/components/UI/DialogPopup.vue"
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"

export default {
  name: "KeywordsPopup",
  components: {
    DialogPopup
  },
  mixins: [textAnalysisMixin],
  props: {
    themeName: {
      default: () => "theme",
      type: String
    },
    keywords: {
      default: () => [],
      type: Array
    },
    showCoverageDetails: {
      type: String,
      default: () => "show"
    },
    themesLength: {
      default: () => 0,
      type: Number
    },
    themeIndex: {
      default: () => -1,
      type: Number
    },
    themeColor: {
      default: () => "",
      type: String
    },
    textAnalysisObject: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    forceClose() {
      if (this.showDialog) this.showDialog = false
    },
    onClickOpen() {
      if (this.keywords.length > 0) this.showDialog = true
    }
  }
}
</script>
