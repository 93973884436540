<template>
  <Modal
    class="drivers__variables"
    :show="isNewBMModalVisible"
    @close="closeThisModal"
    :widerFormat="true"
  >
    <template #header>
      <VisibleText>
        {{ bmAnalysisTitle }}
      </VisibleText>
    </template>
    <h2>Instructions</h2>
    <p>
      <VisibleText
        >Benchmarking can only be done to questions that have been matched and
        approved to a benchmarking database. They are listed below. You can
        choose to remove questions by unselecting them. You can also filter the
        benchmarking by values from other data in your dataset</VisibleText
      >.
    </p>
    <!--------------------------------------
    | BM Questions
    ---------------------------------------->
    <SelectQuestionsList
      :toEmitUpdate="true"
      :questions="bmQuestions"
      :smallerUI="true"
      :selected-ids="selectedBMQuestionIds"
      :hide-toolbar="false"
      @update-selected-question-ids="setBMQuestion"
      v-if="bmQuestions.length > 0"
    />
    <p class="error-msg__inline" v-else>
      <VisibleText
        >Please select at least one benchmarking question.</VisibleText
      >
    </p>

    <VisibleText v-if="bmQuestions.length == 0">
      No benchmarking questions found
    </VisibleText>

    <template #footer>
      <div style="display: flex; width: 100%">
        <div style="display: flex; width: 20%; align-items: center">
          <button
            class="btn-default"
            style="height: 2em"
            @click="onClickCreateBenchmarking"
          >
            <VisibleText>Create benchmarking</VisibleText>
          </button>

          <MessageInline :message="message.text" :message-type="message.type" />
        </div>
        <div style="display: flex; width: 80%">
          <button
            class="btn-cancel"
            style="margin-left: auto"
            @click="filterMode = true"
          >
            <div v-if="filterParams">
              <VisibleText>Edit filters</VisibleText>
            </div>
            <div v-else>
              <VisibleText>Add filter to analysis</VisibleText>
            </div>
          </button>
          <FilterPills
            v-if="isFiltered"
            :isInAnalysisView="true"
            :filterParamFromSelectedQuestions="filterParams"
            @clearFilters="clearFilters"
          >
          </FilterPills>
        </div>
      </div>
      <AddFilter
        :show="filterMode"
        :type="'own'"
        :data="allQuestions"
        @close-modal="filterMode = false"
        @confirmNewFilter="confirmNewFilter"
      />
    </template>
  </Modal>
</template>

<script>
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import Modal from "@/components/UI/Modal.vue"
import SelectQuestionsList from "../../Components/SelectQuestionsList.vue"
import AddFilter from "@/components/Project/ProjectAnalysis/Components/AddFilter.vue"
import FilterPills from "@/components/Project/ProjectAnalysis/Components/FilterPills.vue"

export default {
  name: "NewTextAnalysisModal",
  components: {
    MessageInline,
    Modal,
    SelectQuestionsList,
    AddFilter,
    FilterPills
  },
  props: {
    bmAnalysisTitle: {
      default: () => "Create Benchmarking Analysis",
      type: String
    },
    bmQuestions: {
      default: () => [],
      type: Array
    },
    isNewBMModalVisible: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      message: {
        text: "",
        type: ""
      },
      selectedBMQuestionIds: [],
      filterMode: false,
      currentFilters: [],
      filterParams: {}
    }
  },
  mounted() {
    this.autoSelectAll()
  },
  computed: {
    allQuestions() {
      const allQuestions =
        this.$store.getters["datasetDetails/getClientQuestions"]
      let objects = {
        selected: false,
        disabled: false
      }
      for (let i = 0; i < allQuestions.length; i++) {
        allQuestions[i].options = objects
      }
      return allQuestions
    },
    formatedFilters() {
      if (this.filterParams) {
        if (this.filterParams !== {}) {
          let array = this.filterParams
          let newObjects = []
          array = array.split(" or ")
          array.push(array.splice(array.length - 1).join(" or "))
          for (let i = 0; i < array.length; i++) {
            let newArray = array[i]
            newArray = newArray.split("==")
            newArray.push(newArray.splice(newArray.length - 1).join("=="))

            const questionResult = this.$store.getters[
              "datasetDetails/getClientQuestions"
            ].find((q) => q._id.$oid === newArray[0])

            // value: newArray[1],
            let newObject = {
              question: questionResult.question_title,
              oid: questionResult._id.$oid,
              values: [newArray[1]],
              content: ""
            }
            if (newObjects.length) {
              let found = false
              for (let x = 0; x < newObjects.length; x++) {
                if (newObjects[x].oid === questionResult._id.$oid) {
                  newObjects[x].values.push(newArray[1])
                  found = true
                }
              }
              if (!found) {
                newObjects.push(newObject)
              }
            } else {
              newObjects.push(newObject)
            }
          }
          if (newObjects.length) {
            let content = ""
            for (let x = 0; x < newObjects.length; x++) {
              content = newObjects[x].question + " - ["
              for (let i = 0; i < newObjects[x].values.length; i++) {
                content = content + newObjects[x].values[i]
                if (i !== newObjects[x].values.length - 1) {
                  content = content + ", "
                }
              }
              content = content + "]"
              newObjects[x].content = content
            }
          }
          return newObjects
        }
      }
      return []
    },
    isFiltered() {
      if (this.filterParams) {
        if (this.filterParams.operands) {
          if (this.filterParams.operands.length) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    autoSelectAll() {
      let allIds = []
      for (let x = 0; x < this.bmQuestions.length; x++) {
        allIds.push(this.bmQuestions[x]._id.$oid)
      }
      this.selectedBMQuestionIds = allIds
    },
    closeThisModal() {
      this.$emit("close-new-BM-analysis")
      this.selectedBMQuestionIds = []
      this.filterMode = false
      this.currentFilters = []
      this.filterParams = {}
    },
    /************
     *
     * on click handlers
     *
     ************/
    onClickCreateBenchmarking() {
      // reset messages
      this.message = {
        text: "",
        type: ""
      }
      if (this.validate()) return
      this.saveBMQuestion()
      this.$emit("close-new-BM-analysis")
      this.selectedBMQuestionIds = []
      ;(this.filterMode = false), (this.currentFilters = [])
      this.filterParams = {}
    },
    /************
     *
     * setters
     *
     ************/

    setBMQuestion(question) {
      this.selectedBMQuestionIds = question
    },

    /************
     *
     * saving and fetching
     *
     ************/
    saveBMQuestion() {
      if (this.selectedBMQuestionIds.length) {
        this.$emit(
          "create-analysis",
          this.selectedBMQuestionIds,
          this.filterParams
        )
      }
    },
    validate() {
      if (this.selectedBMQuestionIds.length === 0) {
        this.message.text = "Please select a benchmarking question."
        this.message.type = "error"
      }
      return this.message.text.length > 0
    },
    resetSearch() {
      this.currentFilters = []
    },
    confirmSelection(question) {
      if (!question) {
        this.currentFilters = []
      }
      if (question.length) {
        for (let x = 0; x < question.length; x++) {
          let questionId = ""
          let theseQuestions = []
          if (question[x]) {
            questionId = question[x][0]
            if (question[x][2].length) {
              for (let i = 0; i < question[x][2].length; i++) {
                let thisParam = questionId + "==" + question[x][2][i]
                if (thisParam) {
                  theseQuestions.push(thisParam)
                }
              }
              if (theseQuestions.length) {
                let parsedQuestions = ""
                for (let i = 0; i < theseQuestions.length; i++) {
                  if (i === 0) {
                    parsedQuestions = theseQuestions[i]
                  } else {
                    parsedQuestions =
                      parsedQuestions + " or " + theseQuestions[i]
                  }
                }
                this.filterParams = parsedQuestions
              }
            }
          }
        }
        this.currentFilters.push(question)
      }
    },
    confirmNewFilter(selected) {
      if (selected) {
        this.filterParams = selected
      }
    },
    clearFilters() {
      this.filterMode = false
      this.filterParams = {}
    }
  }
}
</script>
