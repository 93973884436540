const LANG_NAME = {
  en: "english",
  fr: "french"
}

const ACCESS_TYPE_TO_DESC = {
  private: {
    name: "Private",
    desc: "only you can use this group"
  },
  public__all: {
    name: "Public",
    desc: "anyone can use this group"
  },
  public__team: {
    name: "Team",
    desc: "only users in your team can use this group"
  }
}

export { LANG_NAME, ACCESS_TYPE_TO_DESC }
