<template>
  <div class="landing-page__wrapper">
    <header
      class="landing-page__hero-section"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/hero.png')})`
      }"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <div class="landing-page__head">
        <img
          class="landing-page__image landing-page__image--left landing-page__image--logo landing-page__image--padded"
          style="cursor: pointer"
          src="/assets/landing/RAI_logo.png"
          alt="Research AI"
          @click="returnToHome"
        />
        <nav aria-label="primary" class="landing-page__nav">
          <ul class="landing-page__nav-inner">
            <li v-if="!$pigeonline.userIsAuthenticated()">
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/register"
              >
                <VisibleText>Register for free</VisibleText>
              </router-link>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/library"
              >
                <VisibleText>Library</VisibleText>
              </router-link>
            </li>
            <li
              class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
              v-show="false"
            >
              <label for="lang-select" class="label">
                <SvgIconDecorative icon="translate" />
                <span class="sr-only"><VisibleText>language</VisibleText></span>
              </label>
              <select
                class="landing-page__select landing-page__font--white landing-page__font--bold"
                id="lang-select"
                @change="changeLanguage($event)"
              >
                <option
                  value="en"
                  :data-parent="$options.name"
                  :selected="currentLang === 'en'"
                >
                  {{ translate("English", $options.name) }}
                </option>
                <option
                  value="fr"
                  :data-parent="$options.name"
                  :selected="currentLang === 'fn'"
                >
                  {{ translate("French", $options.name) }}
                </option>
              </select>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark landing-page__nav__button"
                to="/login"
              >
                <VisibleText v-if="$pigeonline.userIsAuthenticated()">
                  Return to app
                </VisibleText>
                <VisibleText v-else>Log in</VisibleText>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="landing__content-box">
        <div
          class="landing-page__content-box landing-page__content-box--padded-bottom"
        >
          <div class="landing-page__seven landing-page__column-widen">
            <h1
              class="landing-page__font--h1 landing-page__font--white landing-page__font--outline"
            >
              <VisibleText>
                Are you in academia and looking for text analytics tools for
                students or faculty?
              </VisibleText>
            </h1>
            <div
              class="landing-page__font landing-page__font--dark-blue landing-page__font--right-padding landing-page__font--large"
            >
              <VisibleText>
                ResearchAI was founded out of the London School of Economics to
                make data science accessible. We partner with academic
                institutions to support digital literacy, especially around text
                analysis.
              </VisibleText>
            </div>
          </div>
          <div class="landing-page__five">
            <img
              style="height: 7em; width: auto"
              class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
              src="/assets/landing/LSE-generate.png"
              alt=""
            />
            <img
              style="height: 4em; margin-top: 2em; width: auto"
              class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
              src="/assets/landing/UT-Austin.png"
              alt=""
            />
            <img
              style="height: 7em; width: auto"
              class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
              src="/assets/landing/NYU-AD.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      tabindex="-1"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <section>
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText>What we built</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              1.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/collect-data_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">Easy data cleaning</h3>
            <p>
              <VisibleText
                >This platform starts with data. Uploading social media,
                interviews, surveys, case-notes, and more is done in clicks.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              2.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/analyze_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Accessible data science</VisibleText>
            </h3>
            <p>
              <VisibleText
                >With data connected, we suggest common analytical models used
                by industry, like prediction, benchmarking and segmentation.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
          >
            <div class="landing-page__font--bold landing-page__font--right">
              3.
            </div>
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/article__voice.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Text analysis</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Most interesting for social scientists is our text analytics.
                We help researchers find themes, track sentiment, and get
                hands-on experience with Natural Language Processing.
              </VisibleText>
            </p>
          </div>
        </div>
      </section>
      <div class="landing-page__grey_skew"></div>
      <section class="landing-page__grey-section">
        <h2 class="landing-page__font--h2 landing-page__font--center">
          <VisibleText
            >Our vision: continue to bridge academia and industry</VisibleText
          >
        </h2>
        <p style="text-align: center; padding: 0 3em">
          <VisibleText>
            We have no commercial plans in mind for academia. Instead, our
            vision is to be a bridge between academia and industry, and hope to
            support digital literacy across social science.
          </VisibleText>
        </p>
        <div
          class="landing-page__content-box landing-page__content-box--mobile-flex"
          role="tablist"
          aria-label="Examples of using ResearchAI for social research"
        >
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-drivers"
            role="tab"
            :aria-selected="tab_active === 0"
            :class="[
              tab_active === 0
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 0"
          >
            <VisibleText>Faster analysis of text</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-benchmarking"
            role="tab"
            :aria-selected="tab_active === 1"
            :class="[
              tab_active === 1
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 1"
          >
            <VisibleText>Advancing qualitative research</VisibleText>
          </button>
          <button
            class="landing-page__button landing-page__button--mobile-shrink"
            id="tab-performance"
            role="tab"
            :aria-selected="tab_active === 2"
            :class="[
              tab_active === 2
                ? 'landing-page__button--light-blue--active'
                : 'landing-page__button--light-blue'
            ]"
            v-on:click="tab_active = 2"
          >
            <VisibleText>Workshops on data literacy</VisibleText>
          </button>
        </div>
        <div
          v-show="tab_active === 0"
          aria-labelledby="tab-drivers"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 0"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <div style="display: flex; flex-direction: row">
              <h3 class="landing-page__font--h3--large">
                <VisibleText>Text analysis</VisibleText>
              </h3>
              <span>
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openVideo('text-analysis')"
                >
                  <SvgIconDecorative icon="video" />
                  <VisibleText style="font-weight: 500"
                    >Watch text analysis video</VisibleText
                  >
                </button>
              </span>
            </div>
            <p>
              <VisibleText>
                Natural Language Processing offers new ways for us to interact
                with text data. Many social studies are held back by just how
                painful it is to conduct text analysis. We have built a platform
                that brings common NLP tools into one place. We would like to
                make these tools available to your students or researchers at no
                cost. Register for free and give it a try.
              </VisibleText>
            </p>
            <h4>
              <VisibleText>
                Get suggested themes from a large number of
                comments</VisibleText
              >
            </h4>

            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Using the most common text AI (Natural Langauge Processing),
                    get suggested themes based on interesting keywords.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Build your own text models or find themes built from text
                    models built and managed by leading HR and management
                    researchers.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_suggested-themes')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_suggested-themes.png"
                    alt="Screenshot of auto-generation thematic analysis"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <h4>
              <VisibleText> Sort and arrange themes </VisibleText>
            </h4>
            <div class="landing-page__row-to-col">
              <ul class="landing-page__seven landing-page--less-widen">
                <li>
                  <VisibleText>
                    Coverage scores tell you precisely how often a theme is
                    mentioned.
                  </VisibleText>
                </li>
                <li>
                  <VisibleText>
                    Sentiment scores suggest how negative or positive
                    respondents are across your themes.
                  </VisibleText>
                </li>
              </ul>
              <div
                class="landing-page__four landing-page__horizontal-center landing-page--remove-width"
              >
                <button
                  class="landing-page__button landing-page__button--small"
                  @click="openScreenshot('screenshot_text-theme-sentiment')"
                >
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/screenshot_text-theme-sentiment.png"
                    alt="Screenshot of text analysis coverage and sentiment"
                  />
                  <SvgIconDecorative icon="externalLink" />
                  <VisibleText style="font-weight: 500">Learn more</VisibleText>
                </button>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; width: 95%"
              class="message__block message__block--information"
            >
              <div style="display: flex; flex-direction: row">
                <div
                  class="project-analysis-item__header-wrapper-btn"
                  style="display: flex; font-weight: 200"
                >
                  <p>
                    <VisibleText style="font-weight: 800">
                      Read more:
                    </VisibleText>
                    <SvgIconDecorative icon="externalLink" />
                    <a
                      href="http://research-ai.io/library/guide/what-is-thematic-analysis-in-researchai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Our guide to text analysis, sentiment analysis, and
                      building your own text models.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tab_active === 1"
          aria-labelledby="tab-benchmarking"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 1"
        >
          <div class="landing-page__font landing-page__bottom-padding">
            <h3 class="landing-page__font--h3--large">
              <VisibleText>
                Create text models out of your subject matter expertise
              </VisibleText>
            </h3>
            <p>
              <VisibleText>
                The platform was built around 'thematic analysis', a technique
                done by qualitative researchers to 'code' quotes as reflecting
                some concept or idea. These are called 'themes'. Popular tools
                like NVivo help you do this, but we hoped to make it even
                easier.
              </VisibleText>
            </p>
            <p>
              <VisibleText>
                For example, we help researchers build their own thematic text
                models based on their expertise so that these themes can be
                found automatically, over and over, at scale. Here is a list of
                some of the ways we are testing and validating these research
                advances:
              </VisibleText>
            </p>
            <ul>
              <li>
                <span style="font-weight: 800"
                  >Finding drivers of customer experience (CX) from leading
                  models.</span
                >
                Cody Dodd, PhD (LSE), lead architect of ResearchAI, originally
                built the custom text modeller to test how well industry-leading
                quantitative drivers of customer experience can be found in text
                data.
              </li>
              <li>
                <span style="font-weight: 800"
                  >Tracking early signs of workplace burnout.</span
                >
                Sacha Dray (LSE) took the leading indicators of burnout from
                quantitative studies and built models to help researchers find
                early signs in voice of the employee data.
              </li>
              <li>
                <span style="font-weight: 800"
                  >(Healthcare) Patient experience.</span
                >
                Alex Hawly (University of Winnipeg) took the Canadian Patient
                Experiences Survey on Inpatient Care and created a text model
                that lets public health researchers baseline and track patient
                care from text data like complaints, feedback forms, and
                surveys.
              </li>
              <li>
                <span style="font-weight: 800"
                  >Tracking diversity and inclusion issues.</span
                >
                Guodong Ju (LSE) combined the most important indicators of
                diversity and inclusion from multiple studies into a single text
                model. This has been used by an NGO to set their first diversity
                and inclusion baselines so they can track improvement over time.
              </li>
              <li>
                <span style="font-weight: 800"
                  >Scaling analysis of social media.</span
                >
                Fabian Beigang (LSE) created a text model for workplace
                fairness, and used that to audit hundreds of publicly available
                Google Reviews to highlight in a very real way which
                institutions may have workplace fairness issues.
              </li>
            </ul>
            <p>
              <VisibleText>
                Do you think building your own text models will help you advance
                research in your own field as well? The above researchers were
                able to build these models with zero coding and no prior Natural
                Language Processing experience. You can too.
              </VisibleText>
            </p>
          </div>
        </div>
        <div
          v-show="tab_active === 2"
          aria-labelledby="tab-performance"
          class="landing-page__content-box landing-page__content-box--large-padding"
          role="tabpanel"
          :aria-hidden="tab_active !== 2"
        >
          <div
            class="landing-page__font landing-page__bottom-padding"
            style="display: flex; flex-direction: column"
          >
            <div style="display: flex; flex-direction: column">
              <h3 class="landing-page__font--h3--large">
                <VisibleText>Finding evidence of burn-out</VisibleText>
              </h3>
              <div style="display: flex; flex-direction: row">
                <div class="landing-page__eight">
                  <p>
                    <VisibleText>
                      In partnership with the LSE Digital Skills Lab, we built
                      an online course that covers:
                    </VisibleText>
                  </p>
                  <ul>
                    <li>
                      Digital literacy: recognizing the anatomy of Artificial
                      Intelligence (for non-coders).
                    </li>
                    <li>
                      Understanding how machines handle text: An introduction to
                      Natural Language Processing.
                    </li>
                    <li>
                      Appreciating the sources of bias: A practical exercise in
                      using NLP to run thematic analysis and recognize how it
                      introduces bias.
                    </li>
                  </ul>
                </div>
                <div class="landing-page__four">
                  <img
                    class="landing-page__image landing-page__image--inherit-width"
                    src="/assets/landing/ai_for_social_sciences_LSE.png"
                    alt="Screenshot of text analysis"
                  />
                </div>
              </div>
              <p>
                <VisibleText>
                  We create free workshops for academia based on this accredited
                  course, incuding the following:
                </VisibleText>
              </p>
              <ul>
                <li>Customize the focus across the above three themes</li>
                <li>Enable anonymous access to ResearchAI to conduct NLP.</li>
                <li>
                  We will customize the models and data that participants will
                  engage with to match your subject matter context.
                </li>
                <li>
                  Conclude with engaging assessments of learning outcomes.
                </li>
              </ul>
            </div>
            <div style="display: flex">
              <div
                style="display: flex; flex-direction: column; width: 95%"
                class="message__block message__block--information"
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    class="project-analysis-item__header-wrapper-btn"
                    style="display: flex; font-weight: 400"
                  >
                    <p>
                      <VisibleText>
                        We have helped organizations big and small in developing
                        a data strategy. Set up a no-cost workshop dedicated to
                        digital literacy for social science researchers.
                      </VisibleText>
                    </p>
                    <button
                      class="landing-page__button landing-page__button--light-blue--active"
                      @click="
                        isBookAWorkshopExpanded = !isBookAWorkshopExpanded
                      "
                    >
                      <VisibleText>Book a workshop</VisibleText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--mobile-fit-height"
        :style="{
          backgroundImage: `url(${require('@/assets/landing/blue_section.png')})`
        }"
      >
        <h2
          class="landing-page__font--h2 landing-page__font--center landing-page__font--white landing-page__font--outline"
        >
          <VisibleText>Why we're different</VisibleText>
        </h2>
        <div
          class="landing-page__content-box landing-page__font landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
        >
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
          >
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/leaders_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Quick text analysis with no training</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Most text analytics tools like NVivo or Atlas take patience and
                training before being able to run meaningful analysis.
                ResearchAI makes it easy to upload data and beginning text
                analysis.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
          >
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/research_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Automating thematic analysis</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Most existing tools have uninutive cluster analysis to produce
                suggested themes. ResearchAI suggests meaningful themes found
                from leading data science tools as well as custom models
                nurtured by academic leaders.
              </VisibleText>
            </p>
          </div>
          <div
            class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
          >
            <img
              class="landing-page__image landing-page__image--inherit-width--large-pad"
              src="/assets/landing/corporate_icon.png"
              alt=""
            />
            <h3 class="landing-page__font--h3">
              <VisibleText>Tracking sentiment</VisibleText>
            </h3>
            <p>
              <VisibleText
                >Sentiment analysis is a new way to understand text data. Not
                only should we understand what was said, but whether this is a
                positive or negative experience. ResearchAI automatically
                suggests the sentiment of text data and lets you filter, modify,
                and expand your research.
              </VisibleText>
            </p>
          </div>
        </div>
      </section>
    </main>
    <footer :aria-hidden="modalOpen" :inert="modalOpen">
      <div
        class="landing-page__content-center landing-page__content-box--adjusted-padding"
      >
        <h2
          class="landing-page__font--h2"
          style="margin-top: 2em; margin-bottom: -1em"
        >
          <VisibleText>Contact us</VisibleText>
        </h2>
      </div>
      <div class="landing-page__content-box landing-page__content-box--center">
        <div>
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/mail_icon.png"
            alt=""
          />
          <div style="display: inline-block" class="landing-page__font">
            <div class="landing-page__font--blue">
              <VisibleText>Email</VisibleText>
            </div>
            <div>hello@researchai.io</div>
          </div>
        </div>
        <a href="https://www.linkedin.com/company/25166569/" target="_blank">
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/linkedin.png"
            alt=""
          />
          <span style="display: inline-block" class="landing-page__font">
            <span
              class="landing-page__footer-text-block landing-page__font--blue"
            >
              LinkedIn
            </span>
            <span
              class="landing-page__footer-text-block landing-page__font--black landing-page__font--underline"
            >
              <VisibleText>Follow us!</VisibleText>
              <SvgIconDecorative icon="externalLink" style="width: 1rem" />
            </span>
          </span>
          <span class="sr-only">
            <VisibleText>(opens in a new tab)</VisibleText>
          </span>
        </a>
      </div>
      <div
        class="landing-page__content-box landing-page__font landing-page__padded-footer"
      >
        <VisibleText>Copyright © 2024 PigeonLine Inc.</VisibleText>
        <div
          class="landing-page__six landing-page__font landing-page__font--right landing-page__font--margined-top"
        >
          <router-link to="/library/privacy-policy">
            <VisibleText>PRIVACY POLICY</VisibleText>
          </router-link>
          |
          <router-link to="/library/ethics-policy">
            <VisibleText>ETHICS POLICY</VisibleText>
          </router-link>
        </div>
      </div>
    </footer>
    <ContactUs
      v-bind:status="isContactUsExpanded"
      @close="isContactUsExpanded = false"
    ></ContactUs>
    <VideoClip
      v-bind:status="isVideoClipExpanded"
      :videoShortURL="videoShortURL"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
    <BookAWorkshop
      v-bind:status="isBookAWorkshopExpanded"
      @close="isBookAWorkshopExpanded = false"
    ></BookAWorkshop>
  </div>
</template>
<script>
import { app } from "@/main.js"
import ContactUs from "@/components/ContactUs/ContactUs.vue"
import BookAWorkshop from "@/components/ContactUs/BookAWorkshop.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import { mapActions } from "vuex"

export default {
  name: "academia",
  components: {
    ContactUs,
    SvgIconDecorative,
    BookAWorkshop,
    VideoClip
  },
  data() {
    return {
      tab_active: 0,
      carousel_active: 1,
      carousel_transform: null,
      carousel_curr_item: null,
      transition_class: null,
      isContactUsExpanded: false,
      isVideoClipExpanded: false,
      isBookAWorkshopExpanded: false,
      videoShortURL: ""
    }
  },
  setup() {
    const siteData = reactive({
      title: app.config.globalProperties.$theme.theme.pageTitle + " | Academia",
      description:
        "ResearchAI works closely with academia, from collaborative research to course design to student learning activities. Especially around text analysis."
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: "description",
          content: computed(() => siteData.description)
        }
      ]
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    if (window.localStorage.getItem("apiLanguage"))
      this.setLanguage(window.localStorage.getItem("apiLanguage"))
    this.activeTitle = this.$theme.theme.pageTitle + " | Academia"
    this.activeDescription =
      "ResearchAI works closely with academia, from collaborative research to course design to student learning activities. Especially around text analysis."
  },
  computed: {
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    }
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    changeLanguage(language) {
      this.setLanguage(language.target.value)
      window.localStorage.setItem("apiLanguage", language.target.value)
    },
    currentLang() {
      return (
        this.$store.getters["getLanguage"] ||
        window.localStorage.getItem("apiLanguage")
      )
    },
    onCarouselFocus() {
      // set to currently active carousel item
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    nextCarousel() {
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      if (this.carousel_active !== length) {
        let shift_amount = 130 * this.carousel_active
        this.carousel_transform =
          "transform: translateX(-" + shift_amount + "%);"
        this.carousel_active = this.carousel_active + 1
      } else {
        this.carousel_active = 1
        this.carousel_transform = "transform: translateX(0);"
      }

      // set tabindex of ICCS link
      if (this.carousel_active === 3) {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "0")
      } else {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "-1")
      }

      // curr item text for sr
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    openVideo(shortUrl) {
      this.videoShortURL = shortUrl
      this.isVideoClipExpanded = !this.isVideoClipExpanded
    },
    returnToHome() {
      this.$router.push({ path: "/" })
    }
  }
}
</script>
