// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"

// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Helpers
import moment from "moment"

const DEFAULTS = {
  apiErrorMessage: "An error occured while processing your request."
}

function CancelledPerfAnalysisException(uuid) {
  this.uuid = uuid
  this.code = 499
}

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  mixins: [DatasetDetailsMixin, ProjectMixin],
  components: {
    SvgIconDecorative
  },
  data() {
    return {
      DEFAULTS: DEFAULTS
    }
  },
  methods: {
    /* Utils */
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    $_performanceAnalysisMixin_getCurrTimestamp() {
      // returns current UNIX timestamp in milliseconds
      return moment().valueOf()
    },
    /**
     * Fetch performance analysis data to generate performance analysis charts.
     * @param {String} datasetID dataset ID
     * @param {Array} clientQuestionIds array of client question ids
     * @param {Array} selectedSegments array of segments
     * @param {Object} filterParam
     * @param {String} uuid unique identifier for the performance analysis
     * @returns {Object}
     */
    async $_performanceAnalysisMixin_fetchAnalysis(
      datasetID,
      clientQuestionIds,
      selectedSegments,
      filterParam,
      uuid
    ) {
      try {
        const request = {
          data_set_id: datasetID,
          client_questions_ids: clientQuestionIds,
          selected_segments: selectedSegments,
          filter_params: filterParam
        }
        const response =
          await this.$services.PERFORMANCE_SERVICE.performanceAnalysisSingleScale(
            request,
            uuid
          )
        return response.data
      } catch (e) {
        if (e === 499) {
          throw new CancelledPerfAnalysisException(uuid)
        } else {
          throw new Error(
            "performanceAnalysisMixin.js:$_performanceAnalysisMixin_fetchAnalysis:: " +
              e.message
          )
        }
      }
    }
  }
}
