<template>
  <main
    class="library__wrapper"
    :style="activeArticle != null ? 'background-color: white;' : ''"
  >
    <div v-if="activeArticle === null">
      <h1 style="margin: 1.25rem">
        <VisibleText>PigeonLine's ResearchAI library</VisibleText>
      </h1>
      <div class="translation-manager__search-bar center-align-parent">
        <SearchBar
          class="center-align-child"
          placeholder="Filter articles by name"
          @search="search"
        />
      </div>
      <form class="library__article-filters">
        <div style="display: flex">
          <VisibleText>Filter by</VisibleText>
          :
        </div>
        <div class="library__article-filters__filter">
          <input
            style="display: flex; margin-left: 0"
            type="checkbox"
            id="typeFeatured"
            name="typeFeatured"
            v-model="filterOptions.showOnlyFeatured"
            aria-label="Show only featured articles"
          />
          <label for="typeFeatured">
            <VisibleText class="library__article-filters__filter__green"
              >Featured</VisibleText
            >
          </label>
        </div>
        <div class="library__article-filters__filter">
          <input
            type="checkbox"
            id="typeGuide"
            name="typeGuide"
            v-model="filterOptions.showOnlyGuide"
            aria-label="Show only guides"
          />
          <label for="typeGuide">
            <VisibleText class="library__article-filters__filter__bisque"
              >Guides</VisibleText
            >
          </label>
        </div>
      </form>
      <ul class="library__articles">
        <li
          v-for="article in filteredArticles"
          :key="article.title"
          class="library__articles__card"
        >
          <router-link :to="article.url" style="text-decoration: none">
            <div style="display: flex; flex-direction: column">
              <div v-if="article.image">
                <img
                  class="library__image"
                  style="width: 100%; height: auto"
                  :src="article.image"
                  alt=""
                />
              </div>
              <div style="display: flex; flex-direction: row">
                <div class="library__articles__card__content">
                  <VisibleText>{{ article.title }}</VisibleText>
                </div>
                <div v-for="(tag, key) in article.tag" :key="key">
                  <div
                    class="library__articles__card__content library__articles__card__content__tag"
                    :class="
                      tag == 'featured'
                        ? 'library__articles__card__content library__articles__card__content__tag__green'
                        : ''
                    "
                  >
                    <VisibleText>{{ tag }}</VisibleText>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              class="library__articles__card__content library__articles__card__content__lang"
            >
              <SvgIconDecorative icon="translate" style="height: 1rem" />
              <div v-for="(lang, key) in article.lang" :key="key">
                {{ lang }}
              </div>
            </div> -->
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <keep-alive>
      <component :is="activeArticle"></component>
    </keep-alive> -->
    <component :is="activeArticle"></component>
  </main>
</template>

<script>
import SearchBar from "@/components/UI/SearchBar.vue"

// Articles
import PrivacyPolicy from "@/components/Library/PrivacyPolicy.vue"
import EthicsPolicy from "@/components/Library/EthicsPolicy.vue"
import GettingStarted from "@/components/Library/Guide/GettingStarted.vue"
import UploadingFiles from "@/components/Library/Guide/UploadingFiles.vue"
import BasicAnalysis from "@/components/Library/Guide/BasicAnalysis.vue"
import DriversAnalysis from "@/components/Library/Guide/DriversAnalysis.vue"
import BenchmarkingAnalysis from "@/components/Library/Guide/BenchmarkingAnalysis.vue"
import ThematicAnalysis from "@/components/Library/Guide/ThematicAnalysis.vue"
import OutliersAnalysis from "@/components/Library/Guide/OutliersAnalysis.vue"
import CurrentVersion from "@/components/Library/Feature/CurrentVersion.vue"
import TopUseCases from "@/components/Library/Feature/TopUseCases.vue"
import USExecutiveOrder from "@/components/Library/Feature/USExecutiveOrder.vue"
import AIPoweredTextAnalysisOurRecentEnhancements from "@/components/Library/Feature/TextAnalysisEnhancements.vue"
import GenAISentenceMatching from "@/components/Library/Feature/GenAISentenceMatching.vue"
import RevolutionizingAirportOperations from "@/components/Library/Feature/RevolutionizingAirportOperations.vue"
import EmergingPractices from "@/components/Library/Feature/EmergingPractices.vue"

import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "library",
  components: {
    SearchBar,
    PrivacyPolicy,
    EthicsPolicy,
    GettingStarted,
    UploadingFiles,
    BasicAnalysis,
    DriversAnalysis,
    BenchmarkingAnalysis,
    ThematicAnalysis,
    OutliersAnalysis,
    USExecutiveOrder,
    CurrentVersion,
    TopUseCases,
    AIPoweredTextAnalysisOurRecentEnhancements,
    RevolutionizingAirportOperations,
    GenAISentenceMatching,
    EmergingPractices,
    SvgIconDecorative
  },
  data() {
    return {
      articles: [
        {
          title: "Current version and change log",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/current-version-of-research-ai",
          componentName: "CurrentVersion",
          isActive: false,
          image: "/assets/landing/update-scrabble.jpg"
        },
        {
          title: "Emerging best practices in UX and CX research",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/emerging-best-practices-in-ux-and-cx-research",
          componentName: "EmergingPractices",
          isActive: false,
          image: "/assets/landing/ux-cx-fire-use-case.jpeg"
        },
        {
          title: "GenAI, sentence matching, and thematic analysis",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/gen-ai-powered-sentence-matching-and-thematic-analysis",
          componentName: "GenAISentenceMatching",
          isActive: false,
          image: "/assets/landing/BERT.png"
        },
        {
          title: "Revolutionizing airport operations with Research AI",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/revolutionizing-airport-operations-using-researchai",
          componentName: "RevolutionizingAirportOperations",
          isActive: false,
          image: "/assets/landing/revolutionizing-airport-cx.jpg"
        },
        {
          title: "AI-powered text analysis - Our recent enhancements",
          tag: [],
          lang: ["en"],
          url: "/library/feature/ai-powered-text-analysis-our-recent-enhancements",
          componentName: "AIPoweredTextAnalysisOurRecentEnhancements",
          isActive: false,
          image: "/assets/landing/rocketship.jpg"
        },
        {
          title: "PigeonLine's ResearchAI | Privacy policy",
          tag: [],
          lang: ["en"],
          url: "/library/privacy-policy",
          componentName: "PrivacyPolicy",
          isActive: false,
          image: "/assets/landing/data-privacy.jpg"
        },
        {
          title: "PigeonLine's ResearchAI | Ethics policy",
          tag: [],
          lang: ["en"],
          url: "/library/ethics-policy",
          componentName: "EthicsPolicy",
          isActive: false,
          image: "/assets/landing/research-and-ai-ethics.jpg"
        },
        {
          title: "Top use cases using ResearchAI",
          tag: ["featured"],
          lang: ["en"],
          url: "/library/feature/top-researchai-use-cases",
          componentName: "TopUseCases",
          isActive: false,
          image: "/assets/landing/brilliant-idea-worth-protecting.jpg"
        },
        {
          title: "Getting started with ResearchAI",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/getting-started-with-researchai",
          componentName: "GettingStarted",
          isActive: false,
          image: "/assets/landing/getting-started-with-researchai.jpg"
        },
        {
          title: "Uploading and cleaning your data in ResearchAI",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/uploading-files-into-researchai",
          componentName: "UploadingFiles",
          isActive: false,
          image: "/assets/landing/data-cleaning.jpg"
        },
        {
          title: "Conducting basic analysis in ResearchAI",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/basic-analysis-in-researchai",
          componentName: "BasicAnalysis",
          isActive: false,
          image: "/assets/landing/basic-analysis.jpg"
        },
        {
          title: "Conducting drivers analysis in ResearchAI",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/drivers-analysis-in-researchai",
          componentName: "DriversAnalysis",
          isActive: false,
          image: "/assets/landing/regression.jpg"
        },
        {
          title: "What is benchmarking analysis in ResearchAI?",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/what-is-benchmarking-in-researchai",
          componentName: "BenchmarkingAnalysis",
          isActive: false,
          image: "/assets/landing/performance-driven-business.jpg"
        },
        {
          title: "What is thematic analysis in ResearchAI?",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/what-is-thematic-analysis-in-researchai",
          componentName: "ThematicAnalysis",
          isActive: false,
          image: "/assets/landing/text-analysis.jpg"
        },
        {
          title: "What is outliers analysis in ResearchAI?",
          tag: ["guide"],
          lang: ["en"],
          url: "/library/guide/outliers-analysis-in-researchai",
          componentName: "OutliersAnalysis",
          isActive: false,
          image: "/assets/landing/outliers.jpg"
        },
        {
          title: "US customer service experience Executive Order",
          tag: [],
          lang: ["en"],
          url: "/library/feature/executive-order-customer-service",
          componentName: "USExecutiveOrder",
          isActive: false,
          image: "/assets/landing/whitehouse-executive-order.jpeg"
        }
      ],
      activeArticle: null,
      activeTitle: "PigeonLine's ResearchAI library",
      query: {
        term: ""
      },
      filterOptions: {
        showOnlyGuide: false,
        showOnlyFeatured: false
      }
    }
  },
  computed: {
    filteredArticles() {
      let filtered = this.articles
      if (filtered) {
        let taglist = []
        let y = 0
        if (this.filterOptions.showOnlyGuide) {
          for (y = 0; y < filtered.length; y++) {
            if (filtered[y].tag) {
              if (filtered[y].tag.includes("guide")) {
                taglist.push(filtered[y])
              }
            }
          }
          filtered = taglist
        }
        if (this.filterOptions.showOnlyFeatured) {
          y = 0
          for (y = 0; y < filtered.length; y++) {
            if (filtered[y].tag) {
              if (filtered[y].tag.includes("featured")) {
                taglist.push(filtered[y])
              }
            }
          }
          filtered = taglist
        }
      }
      if (this.query.term.length > 0) {
        let searchlist = []
        for (var i = 0; i < filtered.length; i++) {
          if (
            filtered[i].title
              .toLowerCase()
              .includes(this.query.term.toLowerCase())
          ) {
            searchlist.push(filtered[i])
          }
        }
        filtered = searchlist
      }
      return filtered
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.checkURL()
  },
  watch: {
    $route() {
      this.checkURL()
    }
  },
  methods: {
    checkURL() {
      this.activeArticle = null
      let matches = 0
      for (let i = 0; i < this.articles.length; i++) {
        if (window.location.href.includes(this.articles[i].url)) {
          this.articles[i].isActive = true
          this.activeTitle = this.articles[i].title
          this.activeArticle = this.articles[i].componentName
          matches = matches + 1
        } else {
          this.articles[i].isActive = false
        }
      }
      if (!matches > 0) {
        this.activeTitle = "PigeonLine's ResearchAI library"
      }
      window.scrollTo(0, 0)
    },
    search(query) {
      this.query.term = query
    }
  }
}
</script>
