<template>
  <main id="main-content" tabindex="-1">
    <div v-if="hasNoLicenses">You have no registered licenses</div>
    <div v-if="!hasNoLicenses" style="display: flex; flex-direction: row">
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 4rem;
        "
      >
        <h1 class="section__top-bar-title">
          <router-link to="/custom-text-model">
            <VisibleText>Custom Text Model</VisibleText>
          </router-link>
        </h1>
        <div>
          <p>
            <VisibleText
              >When you run text analysis in ResearchAI you can press, 'generate
              themes'. This looks for similar themes across all custom text
              models that you have access to.</VisibleText
            >
          </p>
        </div>
      </div>

      <div
        class="section__top-bar-actions-wrapper"
        style="margin: 4rem; width: 20rem"
      >
        <CreateProject
          class="btn-default"
          :project="newGroup"
          @projectCreated="newGroupCreated"
          @projectCreationError="newGroupCreationError"
          v-if="!this.$route.path.replace('/custom-text-model', '').length"
        >
          <VisibleText>Create Custom Text Model</VisibleText>
        </CreateProject>
      </div>
    </div>
    <router-view v-if="!hasNoLicenses"></router-view>
  </main>
</template>

<script>
// Components
import { CreateProject } from "@pigeonline/core"

// Models
import CustomTopicModelGroup from "@/components/CustomTopicModel/Models/CustomTopicModelGroup.js"

import UserMixin from "@/utils/mixins/userMixin.js"

export default {
  name: "CustomTopicModel",
  mixins: [UserMixin],
  components: {
    CreateProject
  },
  data() {
    return {
      newGroup: new CustomTopicModelGroup()
    }
  },
  created() {
    document.title = `Custom Text Model || ${this.$theme.theme.pageTitle}`
    // if (this.userInformation) {
    //   this.setOwnerInfo()
    // }
  },
  computed: {
    // userInformation() {
    //   return this.$store.getters["user/getProfile"]
    // }
  },
  methods: {
    async newGroupCreated(group) {
      this.$router.push({
        name: "customTopicModelEdit",
        params: { id: group.id }
      })
    },
    newGroupCreationError() {
      this.setConfirmText({
        btn: "okay",
        title: "Error Occured",
        message:
          "Sorry, there was a problem creating a group. Please try again."
      })
      this.setConfirmSourceComponent("topicModel")
      this.setConfirmType("error")
      this.setConfirmIsVisible(true)
    }
  }
}
</script>
