/**
 * Format chart label.
 * use question text or question title.
 * chartItem[2] = question text
 * chartItem[1] = question title
 * @param {Object} chartItem drivers analysis chart data point
 * @returns {String} chart label
 */
const formatChartLabel = (chartItem) => {
  if (chartItem[2].length > 0) {
    return chartItem[2]
  } else {
    return chartItem[1]
  }
}

/**
 * Create drivers chart data for pigeondoc chart
 * @param {Object} driversChartData drivers analysis chart data
 * @param {Boolean} includeLinks to question by question section
 * @returns drivers chart data
 */
const formatDriversChartData = (driversChartData, includeLinks) => {
  return driversChartData.reduce((chartItems, item) => {
    if (!item[0]) return chartItems
    const chartLabel = formatChartLabel(item)
    let obj = {}
    obj["label"] = chartLabel
    obj["value"] = parseFloat(roundToTwo(item[0]))
    // if we want to include links & there is a global match
    if (includeLinks && item[3].length > 0)
      obj["labelLink"] = `#q-group-heading-${item[3].replace(/\s/g, "-")}`
    chartItems.push(obj)
    return chartItems
  }, [])
}

/**
 * Create primority matrix chart data for pigeondoc chart
 * @param {Object} matrixChartData priority matrix chart data
 * @param {Boolean} includeLinks to question by question section
 * @returns priority matrix chart data
 */
const formatPriorityMatrixChartData = (matrixChartData, includeLinks) => {
  return matrixChartData.reduce((chartItems, item) => {
    if (!item[4] || !item[0]) return chartItems
    const matrixLabel = formatChartLabel(item)
    let obj = {}
    obj["label"] = matrixLabel
    obj["x"] = parseFloat(roundToTwo(item[4]))
    obj["y"] = parseFloat(roundToTwo(item[0]))
    // if we want to include links & there is a global match
    if (includeLinks && item[3].length > 0)
      obj["link"] = `#q-group-heading-${item[3].replace(/\s/g, "-")}`
    chartItems.push(obj)
    return chartItems
  }, [])
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}

export {
  formatChartLabel,
  formatDriversChartData,
  formatPriorityMatrixChartData
}
