<template>
  <section class="manage-permissions__wrapper" v-if="shared">
    <DefaultTable
      class="manage-permissions__table"
      :caption="'Manage permissions'"
      :hide-caption="true"
      :row-data="users"
      :column-headers="columnHeaders"
    >
      <template #row="{ row }">
        <td data-label="">
          <p class="row-label">
            {{ row.label }}
            <span class="new-badge" v-if="row.type == 'selected'">
              <VisibleText>New</VisibleText>
            </span>
          </p>
          <p class="row-value">{{ row.value }}</p>
        </td>
        <td
          :data-label="columnHeaderLabels[index]"
          :key="type"
          v-for="(type, index) in types"
        >
          <Checkboxes
            :labels="permLabels[type]"
            :values="row.permissions[type]"
            :key="type + '#' + row.value"
            :groupID="type + '#' + row.value"
            @updateCheckboxVals="updateCheckboxVals"
          />
        </td>
      </template>
    </DefaultTable>
  </section>
</template>

<script>
// Componenets
import DefaultTable from "@/components/UI/DefaultTable.vue"
import Checkboxes from "./Checkboxes.vue"

export default {
  name: "BaseManagePermissions",
  components: {
    DefaultTable,
    Checkboxes
  },
  props: {
    shared: {
      type: Array
    },
    types: {
      type: Array
    },
    permLabels: {
      type: Object
    }
  },
  data() {
    return {
      users: [],
      usersCached: []
    }
  },
  computed: {
    columnHeaderLabels() {
      return this.types.map(
        (type) =>
          type.charAt(0).toUpperCase() +
          type.substr(1).toLowerCase() +
          " Permissions"
      )
    },
    columnHeaders() {
      return [""].concat(this.columnHeaderLabels)
    }
  },
  mounted() {
    this.$watch(
      "shared",
      function (val) {
        if (val) {
          this.users = [...val].sort((a, b) => a.type.localeCompare(b.type))
          this.usersCached = this.deepCloneObj(this.users)
        }
      },
      { deep: false, immediate: true }
    )
  },
  methods: {
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    arrayEquals(array1, array2) {
      return (
        JSON.stringify([...array1].sort()) ===
        JSON.stringify([...array2].sort())
      )
    },
    updateCheckboxVals(groupID, values) {
      let [type, userId] = groupID.split("#")
      let updatedUser = this.users.filter((row) => row.value == userId)[0]
      updatedUser.permissions[type] = values

      let updatedUserCached = this.usersCached.filter(
        (row) => row.value == userId
      )[0]
      if (!this.arrayEquals(updatedUserCached.permissions[type], [...values])) {
        this.$parent.$emit("changed", true)
      }
    }
  }
}
</script>
